import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Input, Typography, Form, Button, Modal, message } from 'antd';

import Meta from '../../../components/Meta';
import Terms from '../../../components/Terms';
import LoginCards from '../../../components/LoginCards';
import {
	Container,
	Cover,
	CoverText,
	FormContainer,
	FormContainerInner,
	Copyright,
	CoverArea,
} from './styles';

import AuthAPI from '../../../services/sdks/auth';
import UseTermsAPI from '../../../services/sdks/useTerms';

import * as UserActions from '../../../store/actions/user';

import logoSrc from '../../../assets/images/logos/logo-secondary-white.svg';
import coverSrc from '../../../assets/images/login/cover.jpg';
import userTypes from '../../../constants/userTypes';

const Login = ({ setUser }) => {
	const history = useHistory();

	const [fallback, setFallback] = useState({ authenticating: false });
	const [credentials, setCredentials] = useState({ email: '', password: '', rememberMe: false });
	const [errors, setErrors] = useState({});
	const [tmpAuth, setTmpAuth] = useState(null);
	const [term, setTerm] = useState(null);
	const [showModals, setShowModals] = useState({
		intranet: false,
		term: false,
	});

	const openModal = useCallback((modal) => {
		setShowModals((prev) => ({ ...prev, [modal]: true }));
	}, []);

	const closeModal = useCallback((modal) => {
		setShowModals((prev) => ({ ...prev, [modal]: false }));
	}, []);

	const handleChangeCredentials = useCallback((key, value) => {
		setCredentials((prev) => ({ ...prev, [key]: value }));
	}, []);

	const handleAuthenticate = useCallback(
		(user, token) => {
			setUser({ user, token });

			localStorage.setItem('@auth', token);
			const redirectPath = [
				userTypes.ADMIN,
				userTypes.ELEMENTS_MANAGER,
				userTypes.SIGNATURES_MANAGER,
				userTypes.SELLER_MANAGER,
			].includes(user.type)
				? '/admin'
				: '/commom';

			console.log({ redirectPath });
			history.push(redirectPath);
		},
		[setUser, history]
	);

	const handleAcceptTerms = useCallback(() => {
		closeModal('term');
		return handleAuthenticate(tmpAuth.user, tmpAuth.token);
	}, [closeModal, handleAuthenticate, tmpAuth]);

	const handleAuthError = useCallback(
		(error) => {
			if (error instanceof Yup.ValidationError) {
				const errors = {};

				error.inner.forEach(({ path, message }) => (errors[path] = message));
				return setErrors(errors);
			}

			if (error?.response?.status) {
				const { status } = error.response;

				switch (status) {
					case 404:
						return setErrors({ email: 'Usuário não encontrado' });
					case 400:
						return setErrors({ password: 'Senha incorreta' });
					case 401:
						return message.error('Seu acesso ao sistema foi bloqueado!');
					case 402:
						return openModal('intranet');
					default:
						return message.error('Houve um erro no servidor');
				}
			}
		},
		[openModal]
	);

	const handleSignIn = useCallback(
		async (event) => {
			setErrors({});
			event.preventDefault();
			setFallback((prev) => ({ ...prev, authenticating: true }));

			try {
				const validationSchema = Yup.object().shape({
					email: Yup.string().email('Informe um email válido').required('Informe o email'),
					password: Yup.string().required('Informe sua senha'),
				});

				await validationSchema.validate(credentials, { abortEarly: false });

				const {
					data: { user, token },
				} = await AuthAPI.post(credentials);

				if (user?.type === userTypes.COMMON && !user?.termsAgreed) {
					const {
						data: { term },
					} = await UseTermsAPI.getCurrentTerm();

					setTmpAuth({ user, token });
					setFallback((prev) => ({ ...prev, authenticating: false }));
					setTerm(term);

					return openModal('term');
				}

				return handleAuthenticate(user, token);
			} catch (error) {
				setErrors({});
				setFallback((prev) => ({ ...prev, authenticating: false }));
				handleAuthError(error);
			}

			setFallback((prev) => ({ ...prev, authenticating: false }));
		},
		[credentials, handleAuthError, handleAuthenticate, openModal]
	);

	return (
		<>
			<Meta title='Entrar' />

			<Container>
				<Cover>
					<img src={coverSrc} alt='talkplay' />
					<div className='overlay'></div>
				</Cover>

				<CoverArea>
					<span></span>

					<CoverText>
						<img className='logo' src={logoSrc} alt='TalkPlay Logo' />

						<Typography.Title>
							Atualize a sua estação de rádio com conteúdos <i>Ao Vivo</i>
						</Typography.Title>

						<p>
							A Talk Play dá a você controle total sobre todas as peças de imagem dos conteúdos.
							Edite, reorganize ou reimagine qualquer um dos projetos existentes ou crie o seu
							próprio em segundos
						</p>
					</CoverText>

					<LoginCards />
				</CoverArea>

				<FormContainer>
					<span></span>
					<FormContainerInner>
						<Typography.Title level={2} style={{ marginBottom: 24 }}>
							Log In
						</Typography.Title>

						<form onSubmit={handleSignIn}>
							<Form.Item
								help={errors['email']}
								style={{ marginBottom: 16, width: '100%' }}
								validateStatus={errors['email'] && 'error'}>
								<Input
									size='large'
									placeholder='Informe seu email'
									value={credentials?.email}
									onChange={({ target: { value } }) => {
										handleChangeCredentials('email', value);
									}}
								/>
							</Form.Item>

							<Form.Item
								help={errors['password']}
								style={{ marginBottom: 12, width: '100%' }}
								validateStatus={errors['password'] && 'error'}>
								<Input.Password
									size='large'
									placeholder='Informe sua senha'
									value={credentials?.password}
									onChange={({ target: { value } }) => {
										handleChangeCredentials('password', value);
									}}
								/>
							</Form.Item>

							<div
								style={{
									width: '100%',
									marginBottom: 24,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-end',
								}}>
								<Link className='__link' to='/forgot-password'>
									Esqueceu a senha?
								</Link>
							</div>

							<Button
								style={{ width: '100%' }}
								type='primary'
								icon='login'
								size='large'
								htmlType='submit'
								loading={fallback.authenticating}>
								Entrar
							</Button>
						</form>
					</FormContainerInner>

					<Copyright>
						Copyright &trade; {new Date().getFullYear()}{' '}
						<a
							className='__link'
							href='https://talkradio.com.br'
							target='_blank'
							rel='noopener noreferrer'>
							TalkRadio
						</a>
					</Copyright>
				</FormContainer>
			</Container>

			<Terms
				user={tmpAuth?.user}
				visible={showModals.term}
				onAccept={handleAcceptTerms}
				onClose={() => closeModal('term')}
				term={term}
			/>

			<Modal
				centered={true}
				width={768}
				title='Pagamento não efetuado'
				visible={showModals.intranet}
				onCancel={() => closeModal('intranet')}
				footer={null}>
				<p>
					Olá querido cliente, para regularizar sua mensalidade acesse{' '}
					<a
						className='__link'
						href='https://www.talkradio.com.br/segunda-via-boletos'
						target='_blank'
						rel='noopener noreferrer'>
						www.talkradio.com.br/segunda-via-boletos
					</a>
					, na sequencia digite o CNPJ e retire as faturas de sua mensalidade. Na sequencia por
					gentileza nos encaminhe o comprovante para providenciar a baixa no sistema.
					<br />
					<br />
					Atenciosamente <strong>Grupo Talk Radio de Comunicação.</strong>
				</p>
			</Modal>
		</>
	);
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
