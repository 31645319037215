import React, { useCallback } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

import { LinkContainer } from './styles';
import { FiMusic, FiAlignCenter } from 'react-icons/fi';

const TalkNavigation = ({ collapsed }) => {
	const renderMenuTitle = useCallback(
		(title, Icon, to) =>
			to ? (
				<Link to={to}>
					{collapsed ? (
						<>
							<Icon style={{ fontSize: 18, marginBottom: -4 }} />
						</>
					) : (
						<LinkContainer>
							<Icon /> {title}
						</LinkContainer>
					)}
				</Link>
			) : collapsed ? (
				<>
					<Icon style={{ fontSize: 18, marginBottom: -4 }} />
				</>
			) : (
				<LinkContainer>
					<Icon /> {title}
				</LinkContainer>
			),
		[collapsed]
	);

	return (
		<Menu theme='dark' mode='inline' style={{ border: 'none' }}>
			<Menu.Item key='tracks' title='Banco musical'>
				{renderMenuTitle('Banco musical', FiMusic, '/commom/tracks')}
			</Menu.Item>

			<Menu.Item key='categories' title='Categorias Musicais'>
				{renderMenuTitle('Categorias Musicais', FiAlignCenter, '/commom/categories')}
			</Menu.Item>
		</Menu>
	);
};

export default TalkNavigation;
