import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 10vh;
	background-color: #000000;
	color: #cfcfcf;
	padding: 6px;
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-width: 1200px;
	width: 100%;

	@media (min-width: 768px) {
		flex-direction: row;
	}
`;

export const LeftColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	flex: 1;
`;

export const RightColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	flex: 1;
`;

export const Section = styled.div`
	background-color: #17181e;
	border-radius: 16px;
	padding: 24px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h2`
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 16px;
`;

export const ListItem = styled.li`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
`;

export const IconWrapper = styled.div`
	margin-right: 8px;
`;

export const GetStartedWrapper = styled.div`
	background-color: #17181e;
	border-radius: 16px;
	padding: 24px;
	text-align: center;
`;

export const GetStartedTitle = styled.h2`
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 16px;
`;

export const GetStartedText = styled.p`
	margin-bottom: 24px;
    color: #82828A;
`;

export const PrimaryButton = styled.button`
	background-color: #102B9C;
	color: white;
	padding: 12px 24px;
	font-size: 1rem;
	font-weight: 700;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover {
		background-color: #0a1f6f;
	}
`;

export const SecundaryButton = styled.button`
	background-color:#102B9C;
    padding: 0.5rem 2rem;
	color: white;
	padding: 12px 24px;
	font-size: 1rem;
	font-weight: 700;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover {
		background-color: #7F10EE;
	}
`;
export const TercearyButton = styled.button`
	background-color:#AB4A95;
    padding: 0.5rem 2rem;
	color: white;
	padding: 12px 24px;
	font-size: 1rem;
	font-weight: 700;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover {
		background-color: #7F10EE;
	}
`;
export const QuartryButton = styled.button`
	background-color:#A3AB49;
    padding: 0.5rem 2rem;
	color: white;
	padding: 12px 24px;
	font-size: 1rem;
	font-weight: 700;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover {
		background-color: #7F10EE;
	}
`;

export const QuintryButton = styled.button`
	background-color:#474EB2;
    padding: 0.5rem 2rem;
	color: white;
	padding: 12px 24px;
	font-size: 1rem;
	font-weight: 700;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover {
		background-color: #7F10EE;
	}
`;