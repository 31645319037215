import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	Input,
	Button,
	Card,
	Icon,
	message,
	Statistic,
	Divider,
	Tabs,
	Empty,
	Breadcrumb,
	Typography,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import ProgramCard from '../../../../components/ProgramCard';
import { PageHeader, CardContainer, Grid, StatisticsContainer } from './styles';

import ProgramsAPI from '../../../../services/sdks/programs';
import SharingsAPI from '../../../../services/sdks/share';
import { FiPlusCircle } from 'react-icons/fi';
import { getResource } from '../../../../helpers/getResource';
import resourcesKeys from '../../../../constants/resourcesKeys';

const ManagePrograms = ({ user }) => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [programs, setPrograms] = useState([]);
	const [filteredPrograms, setFilteredPrograms] = useState([]);

	const updatePrograms = useCallback(
		(data, programId) => {
			setPrograms(
				programs.map((program) => {
					if (program._id === programId) {
						return { ...program, ...data };
					}

					return program;
				})
			);

			setFilteredPrograms(
				filteredPrograms.map((program) => {
					if (program._id === programId) {
						return { ...program, ...data };
					}

					return program;
				})
			);
		},
		[filteredPrograms, programs]
	);

	const handleSearch = useCallback(
		(search, category) => {
			if (!programs[category]) {
				return null;
			}

			if (!search) {
				return setFilteredPrograms((prev) => ({
					...prev,
					[category]: programs[category],
				}));
			}

			setFilteredPrograms((prev) => ({
				...prev,
				[category]: programs[category].filter((program) => {
					return program.name?.match(new RegExp(search, 'i'));
				}),
			}));
		},
		[programs]
	);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { sharings },
				} = await SharingsAPI.index(`user=${user?._id}`);
				const {
					data: { programs: personalPrograms },
				} = await ProgramsAPI.index(`userId=${user?._id}&isDeleted=false`);

				const programs = {
					personal: personalPrograms,
					shareds: sharings.map(({ program, isActive, isFavorited }) => {
						return { ...program, isFavorited, isActive };
					}),
				};

				setPrograms(programs);
				setFilteredPrograms(programs);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (err) {
				console.error(err);
				message.error('Não foi possível buscar os programas');
			}
		};

		fetchInitialData();
	}, [user]);

	const programLimit = useMemo(() => getResource(user, resourcesKeys.PROGRAMS_LIMIT), [user]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Gerenciar programas' />

			<PageHeader>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'INÍCIO' },
						{ breadcrumbName: 'CONTEÚDOS' },
						{ breadcrumbName: 'GERENCIAR PROGRAMAS' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Gerenciar Programas</Typography.Title>

					{programs.personal?.length >= programLimit ? (
						<Button disabled type='primary' size='large'>
							<FiPlusCircle /> Novo programa
						</Button>
					) : (
						<Link to='/commom/programs/create'>
							<Button type='primary'>
								<FiPlusCircle /> Novo programa
							</Button>
						</Link>
					)}
				</header>

				<Divider />

				<StatisticsContainer>
					<Statistic
						title='Meus programas'
						prefix={<Icon style={{ marginRight: 8 }} type='play-circle' />}
						value={`${programs.personal?.length}/${programLimit} programas`}
					/>
					<Statistic
						title='Compartilhados comigo'
						prefix={<Icon style={{ marginRight: 8 }} type='share-alt' />}
						value={programs?.shareds?.length ? `${programs.shareds?.length} programas` : 'N/A'}
					/>
					<Statistic
						title='Programas inativos'
						prefix={<Icon style={{ marginRight: 8 }} type='stop' />}
						value={programs.inactives?.length ? `${programs.inactives?.length} programas` : 'N/A'}
					/>
				</StatisticsContainer>
			</PageHeader>

			<CardContainer>
				<Card className='_card'>
					<Tabs size='large'>
						<Tabs.TabPane
							key='personal'
							tab={
								<>
									<Icon type='play-circle' /> Meus conteúdos
								</>
							}>
							{programs?.personal.length !== 0 ? (
								<Grid>
									<header>
										<Input.Search
											size='large'
											className='search'
											placeholder='Buscar por nome'
											onSearch={(value) => handleSearch(value, 'personal')}
										/>
									</header>

									{filteredPrograms?.personal.map((program) => (
										<ProgramCard
											key={program._id}
											data={program}
											user={user}
											updatePrograms={updatePrograms}
										/>
									))}
								</Grid>
							) : (
								<Empty description='Você ainda não possui conteúdos criados' />
							)}
						</Tabs.TabPane>

						<Tabs.TabPane
							key='shareds'
							tab={
								<>
									<Icon type='share-alt' /> Compartilhados comigo
								</>
							}>
							{programs?.shareds.length !== 0 ? (
								<Grid>
									<header>
										<Input.Search
											size='large'
											className='search'
											placeholder='Buscar por nome'
											onSearch={(value) => handleSearch(value, 'shareds')}
										/>
									</header>

									{filteredPrograms?.shareds.map((program) => (
										<ProgramCard
											key={program._id}
											data={program}
											user={user}
											updatePrograms={updatePrograms}
										/>
									))}
								</Grid>
							) : (
								<Empty description='Nenhum conteúdo foi compartilhado com você ainda' />
							)}
						</Tabs.TabPane>
					</Tabs>
				</Card>
			</CardContainer>
		</>
	);
};

export default connect(({ user }) => ({ user }))(ManagePrograms);
