import React, { useState, useEffect } from 'react';
import { Container, Content, Timestamps, ProgressBar, Controls, Button } from './styles';

const AudioButton = ({ label, isActive, onClick }) => (
	<Button variant={isActive ? 'default' : 'outline'} onClick={onClick}>
		{label}
	</Button>
);
export default function Component() {
	const [audio, setAudio] = useState(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		if (audio) {
			audio.play();
			audio.ontimeupdate = () => {
				if (audio) {
					setProgress((audio.currentTime / audio.duration) * 100);
				}
			};
		}
	}, [audio]);

	return (
		<Container>
			<Content>
				<Timestamps>
					<span>88</span>
					<span>90</span>
					<span>92</span>
					<span>96</span>
					<span>98</span>
					<span>100</span>
					<span>102</span>
					<span>106</span>
					<span>108</span>
				</Timestamps>
				<ProgressBar progress={progress} />
				<Controls>
					<div className='flex space-x-2' style={{ display: 'flex', gap: '12px' }}>
						<AudioButton
							isActive={isPlaying}
							onClick={() => {
								setIsPlaying(true);
								setAudio(new Audio('/audio/audioia/audio-demo.mp3'));
							}}
							label='Ligar'
						/>
						<AudioButton
							isActive={audio === null}
							onClick={() => {
								audio?.pause();
								setIsPlaying(false);
								setAudio(null);
							}}
							label='Parar'
						/>
					</div>
				</Controls>
			</Content>
		</Container>
	);
}
