import { useCallback } from 'react';
import {
	RadioVignetteTypesColors,
	RadioVignetteTypesTranslation,
} from '../enums/radio-vignette-types';
import { VignetteSpeechModesTranslation } from '../enums/vignette-speech-modes';
import { VignetteExecutionModesTranslation } from '../enums/vignette-execution-modes';

const useVignette = () => {
	const getColor = useCallback((vignette) => {
		if (vignette.config) {
			return vignette.config.color;
		}

		return RadioVignetteTypesColors[vignette.type];
	}, []);

	const translateType = useCallback((vignette) => {
		if (vignette.config) {
			return vignette.config.name;
		}

		return RadioVignetteTypesTranslation[vignette.type];
	}, []);

	const translateSpeechMode = useCallback((speechMode) => {
		return VignetteSpeechModesTranslation[speechMode];
	}, []);

	const translateExecutionMode = useCallback((executionMode) => {
		return VignetteExecutionModesTranslation[executionMode];
	}, []);

	return {
		getColor,
		translateType,
		translateSpeechMode,
		translateExecutionMode,
	};
};

export default useVignette;
