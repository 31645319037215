import api from '../api';

const BASE_URL = '/api/v2/sharings';

const ShareAPI = {
	index: (query = '') => api.get(`${BASE_URL}?${query}`),
	show: (sharingId) => api.get(`${BASE_URL}/${sharingId}`),
	post: (payload) => api.post(BASE_URL, payload),
	modify: (sharingId, payload) => api.patch(`${BASE_URL}/${sharingId}`, payload),
	destroy: {
		byId: (sharingId) => {
			return api.delete(BASE_URL, { data: { sharingId }, headers: { 'by-id': 'true' } });
		},
		byProgramAndUser: (payload) => {
			return api.delete(BASE_URL, { data: payload, headers: { 'by-id': 'false' } });
		},
	},
};

export default ShareAPI;
