import { useCallback, useMemo, useRef } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';

export const FileValidationStatusMessages = {
	VALIDATING: 'Validando arquivo, por favor aguarde',
	INVALID_TYPE: 'Formato inválido. Por favor, selecione um arquivo com formato aceito',
	CORRUPTED_FILE: 'Arquivo corrompido. Por favor, selecione outro arquivo',
	VALID_FILE: 'Integridade do arquivo validada com sucesso',
};
export const FileValidationStatusCodes = {
	VALID: 'VALID',
	INVALID: 'INVALID',
	VALIDATING: 'VALIDATING',
	UPLOADING: 'UPLOADING',
};

export default function useFilesValidator({ validTypes, customValidations } = { validTypes: [] }) {
	const ffmpeg = useRef(new FFmpeg());
	const acceptedTypes = useMemo(() => validTypes.map((t) => t.toUpperCase()), [validTypes]);

	const getFileMetadata = useCallback((file) => {
		const [name, ext] = file.name.split('.');
		const size = file?.size ? file.size : 0;
		return { name, size, ext: `.${ext}`.toLowerCase() };
	}, []);

	const validateFile = useCallback(
		(file, index) => {
			return new Promise(async (resolve) => {
				if (file.status.code !== FileValidationStatusCodes.VALIDATING) {
					return resolve({
						code: file.status.code,
						message: file.status.message,
					});
				}

				const isValidExt = acceptedTypes.length
					? acceptedTypes.map((type) => type.toLowerCase()).includes(file.metadata.ext)
					: true;

				if (!isValidExt) {
					return resolve({
						code: FileValidationStatusCodes.INVALID,
						message: FileValidationStatusMessages.INVALID_TYPE,
					});
				}

				if (Array.isArray(customValidations)) {
					for (const validationFn of customValidations) {
						try {
							await validationFn(file);
						} catch (error) {
							return resolve({
								code: FileValidationStatusCodes.INVALID,
								message: error.message,
							});
						}
					}
				}

				const input = `[input-${index}]${file.metadata.ext}`;
				const FFmpeg = ffmpeg.current;

				try {
					if (!FFmpeg.loaded) {
						await FFmpeg.load();
					}

					FFmpeg.on('log', async ({ message }) => {
						if (message.includes('Error while decoding stream')) {
							FFmpeg.terminate();
							return resolve({
								code: FileValidationStatusCodes.INVALID,
								message: FileValidationStatusMessages.CORRUPTED_FILE,
							});
						}
					});

					await FFmpeg.writeFile(input, await fetchFile(file.data));
					await FFmpeg.exec(['-i', input, '-f', 'null', '/dev/null']);
					FFmpeg.terminate();

					return resolve({
						code: FileValidationStatusCodes.VALID,
						message: FileValidationStatusMessages.VALID_FILE,
					});
				} catch (err) {
					FFmpeg.terminate();
					return resolve({
						code: FileValidationStatusCodes.INVALID,
						message: FileValidationStatusMessages.CORRUPTED_FILE,
					});
				}
			});
		},
		[acceptedTypes, customValidations]
	);

	const hasValidationError = useCallback((files) => {
		return files.some((file) => file.status.code === FileValidationStatusCodes.INVALID);
	}, []);

	return {
		validateFile,
		getFileMetadata,
		hasValidationError,
	};
}
