import styled, { css } from 'styled-components';

export const WeekGrid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 12px;
`;

export const DroppableZoneContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4px;
	height: 100%;
	border-radius: var(--border-radius-base);
	border: 1px solid var(--primary-opacity-25);
	gap: 3px;
	transition: 200ms;

	${({ isOver }) =>
		isOver &&
		css`
			border: 1px dashed var(--primary-opacity-25);
			background: var(--primary-opacity-25);
		`}
`;

export const DraggableDayContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 4px;
	border-radius: var(--border-radius-base);
	background: var(--primary-opacity-25);
	color: var(--primary-opacity-85);
	font-size: 13px;
	font-weight: 600;
	text-transform: uppercase;
	cursor: grab;
`;

export const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;

	& > header {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 12px;
		border-radius: var(--border-radius-base);
		background: var(--primary-opacity-85);
		margin-bottom: 4px;
		color: #fff;
		font-weight: 500;
		text-transform: uppercase;
	}
`;

export const EmptyColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	color: var(--primary-opacity-65);
	margin: 16px 0;
	opacity: calc(0.6);

	& > svg {
		font-size: 20px;
		margin-bottom: 8px;
	}

	& > span {
		text-transform: uppercase;
		font-weight: 500;
		text-align: center;
		font-size: 13px;
		margin-bottom: 8px;
	}

	& > small {
		text-align: center;
		width: 80%;
		font-size: 11px;
	}
`;

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 24px;
	gap: 8px;
`;

export const AllDaysSetInfo = styled.span`
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #dedede;
`;