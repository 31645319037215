import React, { useCallback, useMemo } from 'react';
import {
	FiList,
	FiGlobe,
	FiVoicemail,
	FiMusic,
	FiStopCircle,
	FiMic,
	FiActivity,
	FiMinusSquare,
	FiDollarSign,
	FiZap,
	FiUser,
	FiBarChart2,
	FiPlay,
	FiMoreVertical,
	FiRadio,
	FiTag,
	FiFastForward,
} from 'react-icons/fi';
import { BsRobot } from 'react-icons/bs';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { tkPlayTypes } from '../constants/elementTypes/tkPlay';
import { interviewTypes } from '../constants/elementTypes/interviews';
import { wppTypes } from '../constants/elementTypes/wpp';
import { blitzTypes } from '../constants/elementTypes/blitz';
import { newsTypes } from '../constants/elementTypes/news';
import { signatureTypes } from '../constants/elementTypes/signatures';
import { recordedOffsTypes } from '../constants/elementTypes/recordedOffs';
import { merchanTypes } from '../constants/elementTypes/merchans';
import { localOffsTypes } from '../constants/elementTypes/localOffs';
import { defaultElementsTypes } from '../constants/elementTypes/default';
import { programVignettesTypes } from '../constants/elementTypes/programVignettes';
import { callsTypes } from '../constants/elementTypes/calls';
import { postsTypes } from '../constants/elementTypes/posts';
import {
	RadioVignetteTypesColors,
	RadioVignetteTypesTranslation,
} from '../enums/radio-vignette-types';

import useConfigs from './useConfigs';

const useElement = () => {
	const { tkPlayConfig, tkVoiceConfig, radioVignetteConfigs, populatedRadioVignettes } =
		useConfigs();
	const types = useMemo(() => {
		return [
			...defaultElementsTypes,
			...programVignettesTypes,
			...localOffsTypes,
			...merchanTypes,
			...recordedOffsTypes,
			...callsTypes,
			...postsTypes,
			...tkPlayTypes,
		];
	}, []);

	const radioVignettesMap = useMemo(() => {
		return {
			staticTypes: populatedRadioVignettes.staticTypes.map((item) => ({
				value: item.value,
				color: RadioVignetteTypesColors[item.value],
				label: RadioVignetteTypesTranslation[item.value],
				executionMode: item.executionMode,
				speechMode: item.speechMode,
			})),
			scheduledTypes: populatedRadioVignettes.scheduledTypes
				.map((item) => {
					const config = radioVignetteConfigs.find((c) => c._id === item.value);

					if (!config) {
						return null;
					}

					return {
						value: item.value,
						color: config?.color ?? '#F00',
						label: config?.name ?? item.value,
						executionMode: item.executionMode,
						speechMode: item.speechMode,
					};
				})
				.filter((e) => e !== null),
		};
	}, [populatedRadioVignettes, radioVignetteConfigs]);

	const categorizedTypes = useMemo(() => {
		return {
			defaultElements: defaultElementsTypes,
			programVignettes: programVignettesTypes,
			localOffs: localOffsTypes,
			merchanElements: merchanTypes,
			recordedOffs: recordedOffsTypes,
			signatures: signatureTypes,
			news: newsTypes,
			blitz: blitzTypes,
			wpps: wppTypes,
			interviews: interviewTypes,
			tkPlay: tkPlayTypes,
			calls: callsTypes,
			posts: postsTypes,
		};
	}, []);

	const isTKPlay = useCallback((type) => {
		return type?.startsWith('TK-PLAY');
	}, []);

	const isTKVoice = useCallback((type) => {
		return type?.startsWith('TK-VOICE');
	}, []);

	const parseElementName = useCallback(
		({ type }) => {
			if (isTKPlay(type)) {
				if (!tkPlayConfig) {
					return 'Carregando...';
				}

				const item = tkPlayConfig?.names?.find((n) => n.key === type);
				return `TKPLAY - ${item.label.toUpperCase()}`;
			}

			if (isTKVoice(type)) {
				if (!tkVoiceConfig) {
					return 'Carregando...';
				}

				const item = tkVoiceConfig?.names?.find((n) => n.key === type);

				if (!item || !item?.label) {
					return 'IA DELETADA (REMOVER DO MODELO)';
				}

				return `${item.label.toUpperCase()}`;
			}

			return {
				/** Elementos padrão */
				'TAG': 'ETIQUETA',
				'NEW-BLOCK': 'BLOCO',
				'VIGNETTE-PROGRAM-INTRO': 'VINHETA DO PROGRAMA - INTRODUÇÃO DE PROGRAMA',
				'VIGNETTE-BREAK': 'VINHETA DO PROGRAMA - PASSAGEM',
				'VIGNETTE-PROGRAM-OUTRO': 'VINHETA DO PROGRAMA - ENCERRAMENTO DE PROGRAMA',
				'VIGNETTE-BLOCK-INTRO': 'VINHETA DO PROGRAMA - INTRODUÇÃO DE BLOCO',
				'VIGNETTE-BLOCK-OUTRO': 'VINHETA DO PROGRAMA - ENCERRAMENTO DE BLOCO',
				'OFF-MAIN': 'OFF REDE',
				TRACK: 'MÚSICA',
				'TRACK-CALL': 'CHAMADA DE MÚSICA',
				'TRACK-POST': 'SAÍDA DE MÚSICA',
				/** Offs locais */
				'OFF-LOCAL-PROGRAM-INTRO': 'OFF LOCAL - INTRODUÇÃO DE PROGRAMA',
				'OFF-LOCAL-PROGRAM-OUTRO': 'OFF LOCAL - ENCERRAMENTO DE PROGRAMA',
				'OFF-LOCAL-BLOCK-INTRO': 'OFF LOCAL - INTRODUÇÃO DE BLOCO',
				'OFF-LOCAL-BLOCK-OUTRO': 'OFF LOCAL - ENCERRAMENTO DE BLOCO',
				'OFF-LOCAL-BLOCK-MID': 'OFF LOCAL - MEIO DE BLOCO',
				/** Publicidades */
				CUSTOMS: 'CUSTOMIZADO',
				SPONSOR: 'PATROCÍNIO',
				OFFERING: 'OFERECIMENTO',
				TESTIMONIAL: 'TESTEMUNHAL',
				'TRACK-AD': 'PROPAGANDA CURTA',
				/** Cashtracks */
				CASHTRACK: 'MÚSICA-CASH',
				'CASHTRACK-CALL': 'CHAMADA PARA MÚSICA-CASH',
				'CASHTRACK-POST': 'SAÍDA DE MÚSICA-CASH',
				/** Assinaturas */
				'SIGNATURE-RADIO': 'ASSINATURA - RÁDIO',
				'SIGNATURE-PROGRAM': 'ASSINATURA - PROGRAMA',
				/** Offs gravados (TKVOX) */
				'OFF-RECORDED-PROGRAM-INTRO': 'TKVOX ID - NEURAL - AP',
				'OFF-RECORDED-PROGRAM-OUTRO': 'TKVOX ID - NEURAL - EP',
				'OFF-RECORDED-BLOCK-INTRO': 'TKVOX ID - NEURAL - AB',
				'OFF-RECORDED-BLOCK-OUTRO': 'TKVOX ID - NEURAL - EB',
				'OFF-RECORDED-BLOCK-MID-1': 'TKVOX ID - NEURAL - MB 1',
				'OFF-RECORDED-BLOCK-MID-2': 'TKVOX ID - NEURAL - MB 2',
				'OFF-RECORDED-BLOCK-MID-3': 'TKVOX ID - NEURAL - MB 3',
				'OFF-RECORDED-BLOCK-MID-4': 'TKVOX ID - NEURAL - MB 4',
				'OFF-RECORDED-BLOCK-MID-5': 'TKVOX ID - NEURAL - MB 5',
				/** Categorias de notícias */
				GENERIC: 'GERAL',
				INTERNATIONAL: 'INTERNACIONAL',
				HEALTH: 'SAÚDE E BEM ESTAR',
				MONEY: 'DINHEIRO',
				EDUCATION: 'EDUCAÇÃO',
				POLICY: 'POLÍTICA',
				ECONOMY: 'ECONOMIA',
				ELECTIONS: 'ELEIÇÕES',
				FARMING: 'AGROPECUÁRIA',
				ENTERTAINMENT: 'ENTRETENIMENTO',
				TECHNOLOGY: 'GAMES & TECNOLOGIA',
				CINE: 'CINEMA E PIPOCA',
				SPORTS: 'ESPORTES',
				GOSSIP: 'FOFOCAS & BASTIDORES',
				MUSIC: 'MÚSICA',
				PET: 'PET',
				CARS: 'CARROS',
				WOMAN: 'MULHERES',
				HOROSCOPE: 'HOROSCOPO',
				/** Chamadas de notícia */
				'NEWS-CALL': 'CHAMADA PARA NOTÍCIA',
				'NEWS-POST': 'SAÍDA DE NOTÍCIA',
				/** Chamadas de blitz */
				'BLITZ-CALL': 'CHAMADA PARA BLITZ',
				'BLITZ-POST': 'SAÍDA DE BLITZ',
				/** Chamadas para wpps */
				'WPP-CALL': 'CHAMADA PARA WPP',
				'WPP-POST': 'SAÍDA DE WPP',
				/** Chamadas para entrevistas */
				'INTERVIEW-CALL': 'CHAMADA PARA ENTREVISTA',
				'INTERVIEW-POST': 'SAÍDA DE ENTREVISTA',
				/** Vinhetas da Rádio */
				'RADIO-VIGNETTE-BLOCK-INTRO': 'VINHETA DA RÁDIO - ENTRADA DE BLOCO',
				'RADIO-VIGNETTE-BLOCK-OUTRO': 'VINHETA DA RÁDIO - SAÍDA DE BLOCO',
				'RADIO-VIGNETTE-BLOCK-COMEBACK-SHORT': 'VINHETA DA RÁDIO - VOLTA DE BLOCO CURTA',
				'RADIO-VIGNETTE-BLOCK-COMEBACK-MID': 'VINHETA DA RÁDIO - VOLTA DE BLOCO MÉDIA',
				'RADIO-VIGNETTE-BLOCK-COMEBACK-LONG': 'VINHETA DA RÁDIO - VOLTA DE BLOCO LONGA',
				'RADIO-VIGNETTE-TIME-CHECK': 'VINHETA DA RÁDIO - ABRE HORA',
				'RADIO-VIGNETTE-PASS': 'VINHETA DA RÁDIO - PASSAGEM',
				'RADIO-VIGNETTE-BRIDGE': 'VINHETA DA RÁDIO - PONTE',
			}[type];
		},
		[isTKPlay, isTKVoice, tkPlayConfig, tkVoiceConfig]
	);

	const getElementColor = useCallback(
		({ type, isLocal }) => {
			if (isTKPlay(type)) {
				return '#33BEFF'; // Azul cacdene espacial
			}

			if (isTKVoice(type)) {
				return isLocal ? '#1C7098' : '#7088D2'; // Azul Oxfot
			}

			return {
				'TAG': '#FFFFFF',
				'NEW-BLOCK': '#FFFFFF', // Manter branco
				'VIGNETTE-PROGRAM-INTRO': '#F34F42', // laranja acinzentado
				'VIGNETTE-BREAK': '#FF8900', // laranja escuro
				'VIGNETTE-PROGRAM-OUTRO': '#F34F42', // Azul acinzentado
				'VIGNETTE-BLOCK-INTRO': '#F34F42', // laranja acinzentado
				'VIGNETTE-BLOCK-OUTRO': '#F34F42', // laranja acinzentado
				TRACK: '#E7A62C', // Amarelo ouro mais suave
				'TRACK-CALL': '#FFFF00', // amarelo ouro mais suave
				'TRACK-POST': '#FFFF00', // amerelo ouro mais suave
				'OFF-MAIN': '#806380', // violeta
				'OFF-LOCAL-PROGRAM-INTRO': '#806380', // violeta
				'OFF-LOCAL-PROGRAM-OUTRO': '#806380', // violeta
				'OFF-LOCAL-BLOCK-INTRO': '#806380', // violeta
				'OFF-LOCAL-BLOCK-OUTRO': '#806380', // violeta
				'OFF-LOCAL-BLOCK-MID': '#C08340', // broze
				TESTIMONIAL: '#25AD61', // indigo
				'SIGNATURE-RADIO': '#67F300', // indigo
				'SIGNATURE-PROGRAM': '#67E9BC', // indigo
				CASHTRACK: '#FF2A2A', // Vermelho cmyk
				'OFF-RECORDED-PROGRAM-INTRO': '#801515', // falu vermelho
				'OFF-RECORDED-PROGRAM-OUTRO': '#801515', // falu vermelho
				'OFF-RECORDED-BLOCK-INTRO': '#801515', // falu vermelho
				'OFF-RECORDED-BLOCK-OUTRO': '#801515', // falu vermelho
				'OFF-RECORDED-BLOCK-MID-1': '#400B0B', // feijao preto
				'OFF-RECORDED-BLOCK-MID-2': '#400B0B', // feijao preto
				'OFF-RECORDED-BLOCK-MID-3': '#400B0B', // feijao preto
				'OFF-RECORDED-BLOCK-MID-4': '#400B0B', // feijao preto
				'OFF-RECORDED-BLOCK-MID-5': '#400B0B', // feijao preto
				/** Notícias */
				'NEWS-MAIN': '#8A0000', // Vermelho
				'NEWS-LOCAL': '#83001F', // bogonha
				'NEWS-GLOBAL': '#17101F', // Passas Pretas
				/** Blitz */
				'BLITZ-MAIN': '#390099', // duque
				'BLITZ-LOCAL': '#390099', // duque
				/** Wpps */
				'WPP-MAIN': '#0F0E10', // Verde noite blue
				'WPP-LOCAL': '#111410', // Verde noite
				'WPP-GLOBAL': '#689F38', // Verde oliva
				/** Entrevistas */
				'INTERVIEW-MAIN': '#4A2D08', // Café
				'INTERVIEW-LOCAL': '#4A2D08', // Café
				'INTERVIEW-GLOBAL': '#4A2D08', // Café
				/** Vinhetas da Rádio */
				'RADIO-VIGNETTE-BLOCK-INTRO': '#FF0000',
				'RADIO-VIGNETTE-BLOCK-OUTRO': '#FF3300',
				'RADIO-VIGNETTE-BLOCK-COMEBACK-SHORT': '#FF6600',
				'RADIO-VIGNETTE-BLOCK-COMEBACK-MID': '#FF9900',
				'RADIO-VIGNETTE-BLOCK-COMEBACK-LONG': '#FFCC00',
				'RADIO-VIGNETTE-TIME-CHECK': '#e1b400',
				'RADIO-VIGNETTE-PASS': '#b59100',
				'RADIO-VIGNETTE-BRIDGE': '#917400',
			}[type];
		},
		[isTKPlay, isTKVoice]
	);

	const getElementCategory = useCallback(
		({ type }) => {
			if (isTKPlay(type)) return 'OFF';
			if (isTKVoice(type)) return 'OFF';
			if (type.startsWith('RADIO-VIGNETTE-')) return 'VIGNETTE';
			if (type.startsWith('VIGNETTE-')) return 'VIGNETTE';
			if (type.startsWith('SIGNATURE-')) return 'VIGNETTE';
			if (type.startsWith('OFF-')) return 'OFF';
			if (type.startsWith('NEWS-')) return 'OFF';
			if (type.startsWith('BLITZ-')) return 'OFF';
			if (type.startsWith('WPP-')) return 'OFF';
			if (type.startsWith('INTERVIEW-')) return 'OFF';
			if (type.includes('-CALL')) return 'OFF';
			if (type.includes('-POST')) return 'OFF';

			return 'CUSTOM';
		},
		[isTKPlay, isTKVoice]
	);

	const getElementIcon = useCallback(
		({ type }) => {
			if (!type) {
				return <FiList />;
			}

			if (type === 'TAG') return <FiTag/>
			if (['TRACK-CALL', 'TRACK-POST'].includes(type)) return <FiMoreVertical />;
			if (type.startsWith('RADIO-VIGNETTE-')) return <FiRadio />;
			if (type === 'VIGNETTE-TEMPLATE') return <FiFastForward />;
			if (type === 'TRACK-AD') return <FiDollarSign />;
			if (type === 'NEW-BLOCK') return <FiMinusSquare />;
			if (type.startsWith('OFF-')) return <FiMic />;
			if (type.startsWith('BLITZ-')) return <FiZap />;
			if (type === 'CUSTOM') return <FiStopCircle />;
			if (type.includes('TRACK')) return <FiMusic />;
			if (type.startsWith('VIGNETTE')) return <FiActivity />;
			if (type.startsWith('SIGNATURE')) return <FiActivity />;
			if (type.startsWith('STR')) return <FiBarChart2 />;
			if (type.startsWith('NEWS-')) return <FiGlobe />;
			if (type.startsWith('WPP-')) return <AiOutlineWhatsApp />;
			if (type.startsWith('INTERVIEW-')) return <FiUser />;
			if (isTKPlay(type)) return <FiPlay />;
			if (isTKVoice(type)) return <BsRobot style={{ fontSize: 18 }} />;
			if (categorizedTypes.merchanElements.includes(type)) return <FiDollarSign />;
			if (categorizedTypes.recordedOffs.includes(type)) return <FiVoicemail />;

			return <FiList />;
		},
		[categorizedTypes.merchanElements, categorizedTypes.recordedOffs, isTKPlay, isTKVoice]
	);

	return {
		loadingTkPlayConfig: tkPlayConfig === null,
		loadingTkVoiceConfig: tkVoiceConfig === null,
		types,
		isTKPlay,
		isTKVoice,
		categorizedTypes,
		parseElementName,
		getElementColor,
		getElementCategory,
		getElementIcon,
		radioVignettesMap
	};
};

export default useElement;
