import styled from 'styled-components';

export const Container = styled.div`
	background: #fff;
	padding: 16px 16px;

	.ant-calendar-year-select {
		display: none !important;
	}

	& .ant-breadcrumb > span:last-child {
		font-weight: 600 !important;
		opacity: 0.8;
	}

	& .ant-breadcrumb-link {
		font-size: 12px;
	}

	& > header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 32px;

		& > h2 {
			color: rgba(0, 0, 0, 0.75) !important;
			display: flex;
			align-items: center;
			margin: 0;
			margin-bottom: -10px;
		}
	}
`;

export const PaginationContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

export const ConfigsContainer = styled.ul`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 32px;
`;

export const ConfigItem = styled.li`
	list-style: none;
	padding: 12px;
	border-radius: var(--border-radius-base);
	border: 1px solid #dedede;
	display: flex;
	flex-direction: column;

	border-left: 10px solid ${({ color }) => color};

	& > header {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 8px;

		& > div.name-container {
			display: flex;
			align-items: center;

			& > strong {
				font-size: 15px;
				font-weight: 600;
			}
		}

		& > div.status-container {
			margin-top: 10px;
			display: flex;
			align-items: center;

			& > small {
				font-size: 13px;
			}
		}
	}
`;

export const ConfigItemSchedule = styled.div`
	display: flex;
	flex-direction: column;

	& > div {
		display: flex;
		align-items: center;

		& > span {
			font-size: 13px;
			font-weight: 300;
			margin-right: 4px;
		}

		& > strong {
			font-size: 13px;
			font-weight: 500;

			& > span {
				text-transform: capitalize;
			}
		}
	}
`;

export const ConfigItemActions = styled.footer`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 12px;
	margin-top: 8px;
	padding-top: 12px;
	border-top: 1px solid #f1f1f1;

	& > button {
		width: 100%;
	}
`;

export const DateInputsContainer = styled.div`
	width: calc(100% - 12px);
	display: grid;
	grid-template-columns: 65% 35%;
	gap: 12px;
`;
