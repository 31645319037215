import api from '../api';

const BASE_URL = '/api/v2/program-generation-schedules';

const GenerationSchedulesAPI = {
	getAll: (page, limit, params) => api.get(`${BASE_URL}?page=${page}&limit=${limit}`, { params }),
	create: (payload) => api.post(BASE_URL, payload),
	update: (id, payload) => api.put(`${BASE_URL}/${id}`, payload),
};

export default GenerationSchedulesAPI;
