const resourceGroupsKeys = {
	ADS: 'ads',
	TRACKS: 'tracks',
	NEWS: 'news',
	WPPS: 'wpps',
	INTERVIEWS: 'interviews',
	OFFS: 'offs',
	BLITZ: 'blitz',
	VIGNETTES: 'vignettes',
	OTHER: 'other',
	CONTENT_GENERATION: 'contentGeneration',
	CONTENT_CREATION: 'contentCreation',
};

export default resourceGroupsKeys;
