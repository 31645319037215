import React from 'react';
import { Typography, Divider, Alert } from 'antd';
import { Link } from 'react-router-dom';

import Meta from '../../../../components/Meta';
import HelpButtons from '../../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../../assets/images/tutorials/programs/shared-programs-details/img-01.png';

const { Title, Paragraph } = Typography;

const SharedProgramDetails = () => (
	<Container>
		<Meta title='Ajuda - Detalhes do Programa Compartilhado' />

		<Title>Detalhes do Programa Compartilhado</Title>

		<Divider />

		<Alert
			showIcon
			type='warning'
			description={
				<>
					Este produto é cobrado à parte. Verifique a Disponibilidade
				</>
			}
		/>
		<Paragraph></Paragraph>
		<Paragraph>
			Chegou a hora de sabermos o que é um{' '}
			<strong>
				<i>programa compartilhado</i>
			</strong>
			.
		</Paragraph>

		<Paragraph>
			Um programa compartilhado, nada mais é do que um programa que outra emissora ou a própria
			equipe <strong>TalkRadio</strong> disponibilizou para você, daí o nome. O escopo geral do
			programa será definido pelo proprietário do conteúdo, porém, como já aprendemos no{' '}
			<Link to='/help/programs/program-details/more-details'>tutorial passado</Link> você poderá
			incluir alguns elementos(<i>elementos locais</i>), e até mesmo alterar parcialmente os modelos
			do programa, deixando assim o conteúdo mais atrativo para seu público.
		</Paragraph>

		<Typography>
			Vale salientar que você também consegue compartilhar seus <strong>programas próprios</strong>{' '}
			com outras emissoras caso possua permissão para isso.
		</Typography>

		<Typography>
			Caso possua programas compartilhados, o controle de tudo poderá ser feito na aba{' '}
			<i>
				<strong>Minha rede</strong>
			</i>{' '}
			do menu lateral.
		</Typography>

		<ImgContainer>
			<img src={img01} alt='Minha rede' />
		</ImgContainer>

		<Divider />

		<Alert
			showIcon
			type='warning'
			description='Informações, vinhetas, elementos padrão do modelo, elementos customizados e TKVOX, não podem ser editados no caso do programa ser compartilhado.'
		/>

		<Divider />

		<HelpButtons
			prev={{
				label: 'Conhecendo melhor o programa',
				to: '/help/programs/program-details/more-details',
			}}
			next={{ label: 'Assinaturas', to: '/help/signatures' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default SharedProgramDetails;
