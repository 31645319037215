import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import axios from 'axios';
import JSZip from 'jszip';
import * as Yup from 'yup';
import path from 'path';
import { connect } from 'react-redux';

import {
	Table,
	Menu,
	Dropdown,
	Button,
	Icon,
	Select,
	Modal,
	Typography,
	DatePicker,
	message,
} from 'antd';

import Fallback from '../Fallback';
import FileExt from '../FileExt';
import FileDuration from '../FileDuration';
import PageSizeHandler from '../PageSizeHandle';
import Progress from '../Progress';
import PlayCell from '../PlayCell';
import Form from '../Form';
import { FiltersContainer, TableActions } from './styles';

import NewsAPI from '../../services/sdks/news';
import { useElement, usePlayer, useDownload, useFilesValidator } from '../../hooks';
import {
	FiDownload,
	FiPlus,
	FiPlusCircle,
	FiTrash2,
	FiUpload,
	FiXCircle,
} from 'react-icons/fi';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';
import FilesUploader from '../FilesUploader';

const LocalNews = ({ user, program }) => {
	const player = usePlayer();
	const download = useDownload();

	const {
		categorizedTypes: { news: categories },
		parseElementName,
	} = useElement();

	const [fallback, setFallback] = useState({ initialData: false });
	const [news, setNews] = useState([]);
	const [selectedNews, setSelectedNews] = useState([]);
	const [visibleModals, setVisibleModals] = useState({ addNews: false });
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [progress, setProgress] = useState(null);
	const [filters, setFilters] = useState({ category: undefined, releaseDate: null });
	const [isValidating, setIsValidating] = useState(false);
	const { hasValidationError } = useFilesValidator();
	const [newNews, setNewNews] = useState({
		category: undefined,
		releaseDate: null,
		files: [],
	});

	const columns = [
		{
			title: 'Categoria',
			key: 'category',
			render: (news) => (
				<PlayCell
					meta={{ primary: parseElementName({ type: news?.category }) }}
					isPlaying={player?.ref === news?._id && player?.isPlaying}
					onPause={player.resume}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: news.filename }),
							ref: news?._id,
							meta: {
								name: `NOTÍCIA LOCAL - ${parseElementName({ type: news?.category })}`,
								artist: news?.program?.name,
							},
						});
					}}
				/>
			),
		},
		{
			title: 'Lançamento',
			dataIndex: 'releaseDate',
			key: 'date',
		},
		{
			title: 'Duração',
			align: 'center',
			key: 'duration',
			render: (news) => <FileDuration src={resolveFileSrc({ fileName: news.filename })} />,
		},
		{
			title: 'Formato',
			align: 'center',
			key: 'ext',
			render: (news) => <FileExt src={news?.filename} />,
		},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					10
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'options',
			render: (_, news, index) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									key='play'
									onClick={() => {
										player.start({
											src: resolveFileSrc({ fileName: news.filename }),
											ref: news?._id,
											meta: {
												artist: 'Notícia',
												name: `NOTÍCIA LOCAL - ${parseElementName({ type: news?.category })} #${
													index + 1 + pagination?.pageSize * (pagination?.current - 1)
												} - ${news?.program?.name}`,
											},
										});
									}}>
									<Icon type='play-circle' /> Reproduzir
								</Menu.Item>
								<Menu.Item key='download' onClick={() => handleDownload({ news })}>
									<Icon type='download' /> Fazer download
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item
									key='delete'
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: 'Excluir off?',
											icon: 'exclamation-circle',
											content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											okText: 'Excluir',
											onOk: () => handleDelete({ newsId: news?._id }),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<Icon type='delete' /> Excluir
								</Menu.Item>
							</Menu>
						}>
						<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const handleDownload = useCallback(
		async ({ news }) => {
			try {
				const name = `NOTÍCIA LOCAL - ${parseElementName({ type: news?.category })}`;

				return download({ filename: news?.filename, name });
			} catch (error) {
				console.error(error);
				message.error('Não foi possível fazer o download.');
			}
		},
		[download, parseElementName]
	);

	const handleDownloadAsZIP = useCallback(async () => {
		setFallback((prev) => ({ ...prev, multiDownload: true }));

		const zip = new JSZip();

		for (let index = 0; index < selectedNews.length; index++) {
			const news = selectedNews[index];
			const filePath = resolveFileSrc({ fileName: news.filename });
			const blob = await axios.get(filePath, { responseType: 'blob' });
			const name = `NOTÍCIA LOCAL - ${parseElementName({ type: news?.category })}`;
			const ext = path.extname(news?.filename);
			const fName = `${news?.program?.name} - ${name}${ext}`;

			zip.file(fName, blob.data, { binary: true });
		}

		const zipContent = await zip.generateAsync({ type: 'blob' });

		let downloadName = `NOTÌCIAS LOCAIS - ${program?.name}`;

		filters?.releaseDate && (downloadName = `${downloadName} - ${filters.releaseDate}`);

		saveAs(zipContent, downloadName);
		setFallback((prev) => ({ ...prev, multiDownload: false }));
	}, [program, selectedNews, parseElementName, filters]);

	const handleDelete = useCallback(async ({ newsId }) => {
		try {
			setFallback((prev) => ({ ...prev, deleting: true }));

			await NewsAPI.destroy({ newsId });

			setNews((news) => news.filter(({ _id }) => _id !== newsId));
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao deletar a notícia');
		} finally {
			setFallback((prev) => ({ ...prev, deleting: false }));
		}
	}, []);

	const handleMultiDelete = useCallback(async () => {
		for (let index = 0; index < selectedNews.length; index++) {
			const { _id: newsId } = selectedNews[index];
			await handleDelete({ newsId });
		}

		setSelectedNews([]);
	}, [selectedNews, handleDelete]);

	const handleCreateNews = useCallback(async () => {
		try {
			const { category, releaseDate, files } = newNews;
			const data = {
				category,
				releaseDate,
				files,
				isLocal: true,
				user: user?._id,
				program: program?._id,
			};

			const validationSchema = Yup.object().shape({
				category: Yup.string().required('Informe a categoria'),
				program: Yup.string().required('Informe o programa'),
				user: Yup.string().required('Usuário inválido'),
				releaseDate: Yup.string().required('Informe a data de lançamento'),
				files: Yup.array().required('Selecione no mínimo 1 arquivo'),
			});

			await validationSchema.validate(data);

			if (hasValidationError(newNews.files)) {
				return message.warning(
					'Alguns arquivos selecionados estão corrompidos. Por favor, substitua-os.'
				);
			}

			setFallback((prev) => ({ ...prev, uploadingNews: true }));
			setVisibleModals((prev) => ({ ...prev, uploadProgress: true }));

			const payload = new FormData();

			for (const key in data) {
				if (key === 'files') {
					data[key].forEach((file) => payload.append('file', file.data));
				} else {
					payload.append(key, data[key]);
				}
			}

			const {
				data: { news },
			} = await NewsAPI.store({
				payload,
				onUploadProgress: ({ total, loaded }) => {
					setProgress(Math.floor((loaded * 100) / total));
				},
			});

			setNews((prev) => [
				...prev,
				...news.map((n) => ({ ...n, program: { name: program?.name } })),
			]);

			setNewNews({ category: undefined, releaseDate: null, files: [] });
			setVisibleModals((prev) => ({ ...prev, addNews: false }));

			/** Aguarda 2 segundos para fechar a mensagem de sucesso do Progress */
			await new Promise((resolve) => setTimeout(() => resolve(), 2000));
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			console.error(error);
			message.error('Houve um erro, tente novamente');
		} finally {
			setProgress(null);
			setFallback((prev) => ({ ...prev, uploadingNews: false }));
		}
	}, [user, newNews, program, hasValidationError]);

	useEffect(() => {
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters]);

	useEffect(() => {
		const fetchNews = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingNews: true }));

				let query = `isLocal=true&page=${pagination?.current - 1}&limit=${
					pagination?.pageSize
				}&program=${program?._id}&user=${user?._id}&`;

				filters?.releaseDate && (query = `${query}releaseDate=${filters.releaseDate}&`);
				filters?.category && (query = `${query}category=${filters.category}&`);

				const {
					data: { news, total },
				} = await NewsAPI.index({ query });

				setPagination((prev) => ({ ...prev, total }));

				setNews(news);
				setFallback((prev) => ({ ...prev, fetchingNews: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as notícias');
			}
		};

		fetchNews();
	}, [filters, pagination.current, pagination.pageSize, user]); //eslint-disable-line

	const isUploadButtonDisabled = useMemo(() => {
		return isValidating || !newNews.category || !newNews.releaseDate || !newNews.files.length;
	}, [isValidating, newNews]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			{/* <Container> */}

			<FiltersContainer>
				<div className='filters'>
					<Select
						showSearch
						value={filters.category}
						placeholder='Categoria'
						style={{ width: '200px', marginRight: '12px' }}
						onChange={(category) => setFilters({ ...filters, category })}
						filterOption={(input, { props: { _search } }) => {
							return _search.match(new RegExp(input, 'i'));
						}}>
						<Select.Option key='all' value={undefined} _search='TODOS'>
							TODOS
						</Select.Option>

						{categories.map((category) => (
							<Select.Option value={category.replace('NEWS-MAIN-', '')} key={category}>
								{parseElementName({ type: category }).replace('NOTÍCIA LOCAL - ', '')}
							</Select.Option>
						))}
					</Select>

					<DatePicker
						placeholder='Data de lançamento'
						format='DD/MM/yyyy'
						style={{ width: '200px' }}
						value={filters?.releaseDate && moment(filters.releaseDate, 'DD/MM/yyyy')}
						onChange={(_, releaseDate) => setFilters({ ...filters, releaseDate })}
					/>
				</div>

				<Button onClick={() => setVisibleModals({ ...visibleModals, addNews: true })}>
					<FiPlus /> Novas notícias
				</Button>
			</FiltersContainer>

			<TableActions>
				<div className='actions'>
					<span>
						Quantidade: <strong>{pagination?.total}</strong>
					</span>
					<div>
						<Button
							size='small'
							disabled={!selectedNews.length}
							type='ghost'
							onClick={handleDownloadAsZIP}
							loading={fallback?.multiDownload}>
							<FiDownload /> Baixar selecionados{' '}
							{selectedNews.length !== 0 && `(${selectedNews.length})`}
						</Button>

						<Button
							size='small'
							disabled={!selectedNews.length}
							type='danger'
							onClick={() => {
								Modal.confirm({
									title: 'Deletar notícias selecionadas?',
									type: 'danger',
									content:
										'Todos as notícias selecionados serão excluídos e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
									okText: 'Deletar',
									onOk: handleMultiDelete,
									okButtonProps: {
										icon: 'delete',
										type: 'danger',
									},
									cancelText: 'Cancelar',
									cancelButtonProps: {
										icon: 'close-circle',
									},
								});
							}}>
							<FiTrash2 /> Deletar selecionados{' '}
							{selectedNews.length !== 0 && `(${selectedNews.length})`}
						</Button>
					</div>
				</div>

				<PageSizeHandler pagination={pagination} setPagination={setPagination} />
			</TableActions>

			<Table
				loading={fallback?.fetchingNews}
				size='middle'
				rowKey='_id'
				columns={columns}
				dataSource={news}
				style={{ border: 'none' }}
				pagination={{
					...pagination,
					size: 'large',
					onChange: (current) => setPagination({ ...pagination, current }),
				}}
				rowSelection={{
					onChange: (_, selectedRows) => setSelectedNews(selectedRows),
				}}
			/>

			<Modal
				width={600}
				visible={visibleModals?.addNews}
				onCancel={() => setVisibleModals({ ...visibleModals, addNews: false })}
				onOk={handleCreateNews}
				cancelButtonProps={{ disabled: fallback?.uploadingNews }}
				okButtonProps={{ loading: fallback?.uploadingNews, disabled: isUploadButtonDisabled }}
				okText={
					<>
						{!isValidating && <FiUpload />}
						{isValidating ? 'Validando Arquivos' : 'Fazer upload'}
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				title={
					<>
						<FiPlusCircle /> Adicionar notícias
					</>
				}>
				<Form.Container>
					<Form.Item label='Categoria'>
						<Select
							showSearch
							value={newNews?.category}
							placeholder='Categoria'
							style={{ marginBottom: 8, width: '100%' }}
							onChange={(category) => setNewNews({ ...newNews, category })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							{categories.map((category) => (
								<Select.Option
									key={category}
									value={category.replace('NEWS-MAIN-', '')}
									_search={parseElementName({ type: category })}>
									{parseElementName({ type: category }).replace('NOTÍCIA REDE - ', '')}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Data de lançamento'>
						<DatePicker
							placeholder='Data de lançamento'
							format='DD/MM/yyyy'
							style={{ width: '100%' }}
							value={newNews?.releaseDate && moment(newNews.releaseDate, 'DD/MM/yyyy')}
							onChange={(_, releaseDate) => setNewNews({ ...newNews, releaseDate })}
						/>
					</Form.Item>

					<FilesUploader
						multiple
						onChange={(files) => setNewNews((prev) => ({ ...prev, files }))}
						onStartValidation={() => setIsValidating(true)}
						onEndValidation={() => setIsValidating(false)}
						validTypes={['.mp3', '.wav']}
					/>
				</Form.Container>
			</Modal>

			<Progress
				progress={progress}
				succesTitle='Notícias enviadas com sucesso'
				title='Enviando notícias, por favor aguarde...'
			/>
		</>
	);
};

export default connect(({ user }) => ({ user }))(LocalNews);
