import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { FiEdit, FiX } from 'react-icons/fi';
import {
	Table,
	Button,
	Icon,
	Select,
	Modal,
	Typography,
	Input,
	Divider,
	message,
	Dropdown,
	Menu,
	Breadcrumb,
} from 'antd';

import Meta from '../../../components/Meta';
import Fallback from '../../../components/Fallback';
import PlayCell from '../../../components/PlayCell';
import FileExt from '../../../components/FileExt';
import FileDuration from '../../../components/FileDuration';
import Form from '../../../components/Form';
import { useFilesValidator, useSoundtracks } from '../../../hooks';
import { Container, ModalContent, TableActions } from './styles';

import SoundtracksAPI from '../../../services/sdks/soundtracks';
import ProgramsAPI from '../../../services/sdks/programs';
import { resolveFileSrc } from '../../../helpers/fileSrcResolver';
import { FiPlusCircle } from 'react-icons/fi';
import { usePlayer, useDownload } from '../../../hooks';
import FilesUploader from '../../../components/FilesUploader';

const ManageSoundtracks = ({ user }) => {
	const player = usePlayer();
	const download = useDownload(null);
	const uploaderRef = useRef();
	const { hasValidationError } = useFilesValidator();
	const { parseSoundtrackCategory, categories: newsCategories } = useSoundtracks();
	const [fallback, setFallback] = useState('initial-data');
	const [soundtracks, setSoundtracks] = useState([]);
	const [filteredSoundtracks, setFilteredSoundtracks] = useState([]);
	const [selectedSoundtracks, setSelectedSoundtracks] = useState([]);
	const [programs, setPrograms] = useState([]);
	const [showModal, setShowModal] = useState(null);
	const [strId, setStrId] = useState(null);
	const [category, setCategory] = useState(undefined);
	const [program, setProgram] = useState(undefined);
	const [name, setName] = useState(undefined);
	const [soundtrackFiles, setSoundtrackFiles] = useState([]);

	const columns = [
		{
			title: 'Trilha sonora',
			key: 'name',
			render: (_, soundtrack) => (
				<PlayCell
					meta={{
						primary: soundtrack?.name,
						secondary: null,
					}}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: soundtrack.filename }),
							ref: soundtrack?._id,
							meta: {
								name: soundtrack?.name,
								artist: null,
							},
						});
					}}
					onPause={player.resume}
					isPlaying={player?.ref === soundtrack?._id && player?.isPlaying}
				/>
			),
		},
		{
			title: 'Programa',
			dataIndex: 'programId',
			key: 'program',
			render: ({ name }) => name,
		},
		{
			title: 'Categoria',
			dataIndex: 'category',
			key: 'category',
			render: (category) => parseSoundtrackCategory(category),
		},
		{
			title: 'Duração',
			dataIndex: 'filename',
			key: 'duration',
			align: 'center',
			render: (filename) => <FileDuration src={resolveFileSrc({ fileName: filename })} />,
		},
		{
			title: 'Formato',
			dataIndex: 'filename',
			key: 'ext',
			align: 'center',
			render: (filename) => <FileExt src={resolveFileSrc({ fileName: filename })} />,
		},
		{
			title: 'ID',
			dataIndex: '_id',
			align: 'center',
			key: 'id',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					5
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'center',
			key: 'options',
			render: (str) => (
				<Dropdown
					placement='bottomLeft'
					overlay={
						<Menu>
							<Menu.Item
								onClick={() => {
									player.start({
										src: resolveFileSrc({ fileName: str.filename }),
										ref: str?._id,
										meta: {
											name: str?.name,
											artist: null,
										},
									});
								}}>
								<Icon type='play-circle' /> Reproduzir
							</Menu.Item>

							<Menu.Item
								onClick={() => {
									setShowModal('update-str');
									setStrId(str._id);
									setName(str.name);
									setCategory(str.category);
								}}>
								<Icon type='edit' /> Editar
							</Menu.Item>

							<Menu.Item
								disabled={fallback?.includes('downloading-str')}
								onClick={() => download({ filename: str?.filename, name: str?.name })}>
								<Icon type='download' /> Fazer download
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								key='delete'
								className='ant-dropdown-menu-item-danger'
								onClick={() => {
									Modal.confirm({
										title: 'Excluir trilha sonora?',
										icon: 'exclamation-circle',
										content:
											'Essa ação não poderá ser revertida, realmente deseja continuar mesmo assim?',
										okText: 'Excluir',
										onOk: () => handleDelete(str, true),
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<Icon type='delete' /> Excluir
							</Menu.Item>
						</Menu>
					}>
					<Icon style={{ cursor: 'pointer', fontSize: 20 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleSearch = useCallback(
		(search) => {
			if (!search) {
				return setFilteredSoundtracks(soundtracks);
			}

			setFilteredSoundtracks(
				soundtracks.filter((str) => {
					return str?.programId?.name.toUpperCase().includes(search.toUpperCase());
				})
			);
		},
		[soundtracks]
	);

	const handleDelete = useCallback(async (soundtrack, showMessage) => {
		try {
			setFallback(`deleting-str-${soundtrack?._id}`);

			await SoundtracksAPI.destroy(soundtrack?._id);

			setFallback(null);
			setSoundtracks((soundtracks) => {
				return soundtracks.filter(({ _id }) => {
					return _id !== soundtrack?._id;
				});
			});
			setFilteredSoundtracks((soundtracks) => {
				return soundtracks.filter(({ _id }) => {
					return _id !== soundtrack?._id;
				});
			});

			if (showMessage) {
				return message.success('Trilha deletada com sucesso');
			}
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao deletar o str');
		}
	}, []);

	const handleUpadteCategory = useCallback(async () => {
		if (!name) return message.error('Informe o name');
		if (!category) return message.error('Informe a categoria');

		setFallback(`updating-str`);

		const payload = { name, category };

		try {
			await SoundtracksAPI.update(strId, payload);

			setSoundtracks((strs) =>
				strs.map((str) => {
					return str._id === strId ? { ...str, name, category } : str;
				})
			);

			setFilteredSoundtracks((strs) =>
				strs.map((str) => {
					return str._id === strId ? { ...str, name, category } : str;
				})
			);

			setShowModal(null);
			setStrId(null);
			setName(undefined);
			setCategory(undefined);
			setFallback(null);

			return message.success('Trilha atualizada com sucesso.');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao atualizar a trilha sonora');
		}

		setFallback(null);
	}, [strId, name, category]);

	const handleMultiDelete = useCallback(async () => {
		try {
			for (let index = 0; index < selectedSoundtracks.length; index++) {
				await handleDelete(selectedSoundtracks[index]);
			}

			setSelectedSoundtracks([]);

			return message.success('As trilhas foram excluídas com sucesso');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro');
		}
	}, [selectedSoundtracks, handleDelete]);

	const handleUploadStr = useCallback(async () => {
		try {
			if (!name) return message.error('Informe o name');
			if (!program) return message.error('Informe o programa');
			if (!category) return message.error('Informe a categoria');
			if (!soundtrackFiles.length) return message.error('Informe o arquivo de áudio');
			if (hasValidationError(soundtrackFiles)) {
				return message.warning(
					'Alguns dos arquivos selecionados estão corrompidos. Por favor, substitua-os por arquivos válidos.'
				);
			}

			let newSoundtracks = [];
			setFallback('creating-soundtracks');

			await Promise.all(
				soundtrackFiles.map(async (file) => {
					const payload = new FormData();

					payload.append('name', name);
					payload.append('userId', user?._id);
					payload.append('category', category);
					payload.append('programId', program);
					payload.append('file', file.data);

					const {
						data: { soundtracks },
					} = await SoundtracksAPI.store(payload, ({ total, loaded }) => {
						file.upload.update(Math.floor((loaded * 100) / total));
					});

					newSoundtracks.push(...soundtracks);
				})
			);

			const selectedProgram = programs.find(({ _id }) => _id === program);
			newSoundtracks = newSoundtracks.map((str) => ({
				...str,
				programId: selectedProgram,
			}));

			setName('');
			setProgram(undefined);
			setShowModal(null);
			setSoundtrackFiles([]);
			setFallback(null);
			setSoundtracks((strs) => [...newSoundtracks, ...strs]);
			setFilteredSoundtracks((strs) => [...newSoundtracks, ...strs]);

			return message.success('Trilha sonora cadastrada com sucesso');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro');
		}
	}, [name, program, category, soundtrackFiles, hasValidationError, programs, user?._id]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				let {
					data: { soundtracks },
				} = await SoundtracksAPI.index(`userId=${user?._id}`);

				soundtracks = soundtracks.sort((a, b) => {
					return a?.programId?.name > b.programId?.name ? 1 : -1;
				});

				setSoundtracks(soundtracks);
				setFilteredSoundtracks(soundtracks);
				setFallback(null);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os programas');
			}
		};

		fetchInitialData();
	}, [user]);

	useEffect(() => {
		const fetchPrograms = async () => {
			try {
				const {
					data: { programs },
				} = await ProgramsAPI.index(`userId=${user?._id}&isDeleted=false`);

				setPrograms(
					programs.sort((x, y) => {
						return x?.isFavorited === y?.isFavorited ? 0 : x?.isFavorited ? -1 : 1;
					})
				);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os programas');
			}
		};

		if (showModal === 'new-str' && !programs.length) {
			fetchPrograms();
		}
	}, [showModal, programs, user]);

	if (fallback === 'initial-data') {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Trilhas sonoras' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[{ breadcrumbName: 'INÍCIO' }, { breadcrumbName: 'TRILHAS SONORAS' }]}
				/>

				<header>
					<Typography.Title level={2}>Trilhas Sonoras</Typography.Title>
					<Button type='primary' onClick={() => setShowModal('new-str')}>
						<FiPlusCircle /> Nova Trilha Sonora
					</Button>
				</header>

				<Table
					loading={fallback === 'fetching-soundtracks'}
					size='middle'
					rowKey='_id'
					columns={columns}
					dataSource={filteredSoundtracks}
					pagination={{ size: 'large', pageSize: 10, hideOnSinglePage: true }}
					style={{ border: 'none' }}
					rowSelection={{
						onChange: (_, selectedRows) => setSelectedSoundtracks(selectedRows),
					}}
					title={() => (
						<TableActions>
							<Input.Search
								allowClear
								onSearch={handleSearch}
								style={{ width: '300px' }}
								placeholder='Buscar por nome do programa'
							/>

							<Button
								onClick={() => {
									Modal.confirm({
										title: 'Deletar várias trilhas',
										icon: 'exclamation-circle',
										content: `Realmente deseja deletar as ${selectedSoundtracks.length} trilhas sonoras selecionadas?`,
										okText: 'Deletar trilhas',
										onOk: handleMultiDelete,
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}
								disabled={!selectedSoundtracks.length}
								type='danger'>
								Deletar selecionados <Icon type='delete' />
							</Button>
						</TableActions>
					)}
				/>
			</Container>

			<Modal
				width={580}
				closable={fallback !== 'creating-soundtracks'}
				title={
					<>
						<FiPlusCircle /> Nova trilha sonora
					</>
				}
				onOk={handleUploadStr}
				okButtonProps={{
					loading: fallback === 'creating-soundtracks',
					disabled: !name || !program || !category || !soundtrackFiles.length,
				}}
				cancelButtonProps={{
					disabled: fallback === 'creating-soundtracks',
				}}
				visible={showModal === 'new-str'}
				okText='Subir Trilha'
				onCancel={() => {
					setSoundtrackFiles([]);
					setProgram(undefined);
					setShowModal(null);
				}}>
				<ModalContent>
					<Typography.Text>
						Informe o programa, nome das trilhas e os arquivos de áudio. Para fazer o upload de mais
						de uma trilha sonora, basta selecionar a quantidade desejada de arquivos.{' '}
						<i>
							Os arquivos devem ser do formato <strong>MP3</strong> ou <strong>WAV</strong>
						</i>
					</Typography.Text>

					<Divider />

					<Form.Container>
						<Form.Item label='Nome'>
							<Input
								value={name}
								placeholder='Nome da trilha'
								onChange={({ target: { value } }) => setName(value)}
							/>
						</Form.Item>

						<Form.Item label='Programa'>
							<Select
								showSearch
								placeholder='Selecione o programa'
								optionFilterProp='children'
								value={program}
								onChange={(value) => setProgram(value)}
								filterOption={(input, option) => {
									return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
								}}>
								{programs.map((program) => (
									<Select.Option key={program._id}>{program?.name}</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Categoria'>
							<Select
								showSearch
								placeholder='Selecione a categoria'
								optionFilterProp='children'
								value={category}
								onChange={(value) => setCategory(value)}
								filterOption={(input, option) => {
									return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
								}}>
								{newsCategories.map((category) => (
									<Select.Option key={category}>{parseSoundtrackCategory(category)}</Select.Option>
								))}
							</Select>
						</Form.Item>

						<FilesUploader
							multiple
							ref={uploaderRef}
							onChange={(files) => setSoundtrackFiles(files)}
							validTypes={['.mp3', '.wav']}
						/>
					</Form.Container>
				</ModalContent>
			</Modal>

			<Modal
				width={580}
				closable={fallback !== 'updating-str'}
				okButtonProps={{ loading: fallback === 'updating-str' }}
				cancelButtonProps={{ disabled: fallback === 'updating-str' }}
				title={
					<>
						<FiEdit /> Editar trilha sonora
					</>
				}
				okText={
					<>
						<FiEdit /> Salvar
					</>
				}
				cancelText={
					<>
						<FiX /> Cancelar
					</>
				}
				onOk={handleUpadteCategory}
				visible={showModal === 'update-str'}
				onCancel={() => {
					setShowModal(null);
					setStrId(null);
					setName(undefined);
					setCategory(undefined);
				}}>
				<ModalContent>
					<Form.Container>
						<Form.Item label='Nome'>
							<Input
								value={name}
								disabled={fallback === 'updating-str'}
								placeholder='Nome da trilha'
								onChange={({ target: { value } }) => setName(value)}
							/>
						</Form.Item>

						<Form.Item label='Categoria'>
							<Select
								showSearch
								disabled={fallback === 'updating-str'}
								placeholder='Selecione a categoria'
								optionFilterProp='children'
								value={category}
								onChange={(value) => setCategory(value)}
								filterOption={(input, option) => {
									return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
								}}>
								{newsCategories.map((category) => (
									<Select.Option key={category}>{parseSoundtrackCategory(category)}</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Form.Container>
				</ModalContent>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(ManageSoundtracks);
