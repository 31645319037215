import React from 'react';
import { Container, Title, Subtitle } from './styles';

export default function Component() {
	return (
		<Container>
			<div id='funcionalidades'></div>
			<Title>O produtor de áudio colaborativo para todos</Title>
			<Subtitle>Crie áudio com uma ferramenta moderna e incrivelmente intuitiva</Subtitle>
			<div className='flex'></div>
		</Container>
	);
}
