import React from 'react';

import { TutorialsNavigation } from '../Navigation';
import { Container, Header, MenuContainer, Docs } from './styles';
import { FiHelpCircle } from 'react-icons/fi';
import { Typography } from 'antd';

const TutorialsLayout = ({ children }) => {
	return (
		<Container>
			<Header>
				<Typography.Title level={2}>
					<FiHelpCircle /> Central de Ajuda
				</Typography.Title>
			</Header>
			<MenuContainer>
				<TutorialsNavigation />
			</MenuContainer>
			<Docs>{children}</Docs>
		</Container>
	);
};

export default TutorialsLayout;
