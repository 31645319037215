import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import {
	Select,
	Button,
	Dropdown,
	Table,
	Menu,
	Divider,
	Modal,
	Icon,
	message,
	Typography,
	Input,
	Breadcrumb,
	Tag,
	Radio,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import Form from '../../../../components/Form';

import PageSizeHandler from '../../../../components/PageSizeHandle';
import { Container, TableHeader } from './styles';

import UsersAPI from '../../../../services/sdks/user';
import GenresAPI from '../../../../services/sdks/genres';
import ProgramsAPI from '../../../../services/sdks/programs';

import { FiEdit2, FiPlusCircle, FiSave, FiXCircle } from 'react-icons/fi';
import CastersApi from '../../../../services/sdks/caster';

const ManagePrograms = () => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [visibleModals, setVisibleModals] = useState({ editProgram: false });
	const [handlingProgram, setHandlingProgram] = useState(null);

	const [users, setUsers] = useState([]);
	const [casters, setCasters] = useState([]);
	const [genres, setGenres] = useState([]);
	const [programs, setPrograms] = useState([]);
	const [selectedPrograms, setSelectedPrograms] = useState([]);

	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [filters, setFilters] = useState({ name: '', userId: undefined, isDeleted: false });

	const columns = [
		{
			title: 'Nome',
			key: 'name',
			render: (program) => program?.name,
		},
		{
			title: 'Rádio',
			key: 'radio',
			dataIndex: 'userId',
			render: (user) => (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{user?.radioName}</span>
					<i style={{ fontSize: 11 }}>
						<i>{user?.city}</i>, <strong>{user?.state}</strong>
					</i>
				</div>
			),
		},
		{
			title: 'Edição',
			key: 'editable',
			dataIndex: 'isEditable',
			align: 'center',
			render: (isEditable) =>
				isEditable ? <Tag color='green'>Editável</Tag> : <Tag color='orange'>Não editável</Tag>,
		},
		{
			title: 'Status de Exclusão',
			key: 'isDeleted',
			dataIndex: 'isDeleted',
			align: 'center',
			render: (isDeleted) =>
				isDeleted ? <Tag color='red'>Excluído</Tag> : <Tag color='green'>Não excluído</Tag>,
		},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					12
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'actions',
			render: (program) => (
				<Dropdown
					placement='bottomRight'
					overlay={
						<Menu>
							<Menu.Item
								onClick={() => {
									const c = program?.caster;
									const casterId = typeof c === 'string' ? c : c?._id;
									setHandlingProgram({ ...program, caster: casterId });
									setVisibleModals({ ...visibleModals, editProgram: true });
								}}>
								<Icon type='edit' /> Editar
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								className='ant-dropdown-menu-item-danger'
								disabled={!program?.isDeleted}
								onClick={() => {
									Modal.confirm({
										title: 'Reverter exclusão?',
										icon: 'exclamation-circle',
										content: 'O programa será adicionado à grade do usuário, deseja continuar?',
										onOk: () => handleRevertExclusion(program),
										okText: 'Reverter',
										okButtonProps: {
											icon: 'retweet',
											type: 'primary',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<Icon type='retweet' /> Reverter exclusão
							</Menu.Item>
						</Menu>
					}>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 12 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleUpdateProgram = useCallback(async (payload) => {
		try {
			const validationSchema = Yup.object().shape({
				name: Yup.string().required('Informe o nome'),
				description: Yup.string().required('Informe a descrição'),
				filenameTemplate: Yup.string().required('Informe o padrão do nome de arquivo'),
			});

			await validationSchema.validate(payload);
			setFallback((prev) => ({ ...prev, editing: true }));

			const response = await ProgramsAPI.update(payload?._id, payload);
			const updatedProgram = response.data.program;

			setPrograms((prev) =>
				prev.map((program) => {
					if (program?._id === updatedProgram?._id) {
						return updatedProgram;
					}

					return program;
				})
			);

			setVisibleModals((prev) => ({ ...prev, editProgram: false }));
			setHandlingProgram(null);
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			console.error(error);
			message.error('Houve um erro ao editar o programa');
		} finally {
			setFallback((prev) => ({ ...prev, editing: false }));
		}
	}, []);

	const handleRevertExclusion = useCallback(
		async (program) => {
			try {
				await handleUpdateProgram({
					...program,
					deleteRequested: false,
					deleteReason: null,
					isDeleted: false,
				});

				message.success('A exclusão foi revertida e o programa retornou a grade do usuário');
			} catch (error) {
				console.error(error);
				message.error('Houve um erro, tente novamente');
			}
		},
		[handleUpdateProgram]
	);

	useEffect(() => {
		async function getUsers() {
			const res = await UsersAPI.index(`active=true`);
			return res.data.users;
		}

		async function getCasters() {
			const res = await CastersApi.index(`active=true`);
			return res.data.casters;
		}

		async function getGenres() {
			const res = await GenresAPI.index();
			return res.data.genres;
		}

		const fetchInitialData = async () => {
			try {
				const [users, casters, genres] = await Promise.all([getUsers(), getCasters(), getGenres()]);

				setUsers(users);
				setCasters(casters);
				setGenres(genres);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os usuários');
			} finally {
				setFallback((prev) => ({ ...prev, initialData: false }));
			}
		};

		fetchInitialData();
	}, []);

	useEffect(() => {
		const fetchPrograms = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingPrograms: true }));

				let query = `isDeleted=${filters.isDeleted}&page=${pagination.current - 1}&limit=${
					pagination.pageSize
				}&`;

				filters.name.length >= 3 && (query = `${query}name=__REGEX__${filters.name}&`);
				filters.userId && (query = `${query}userId=${filters.userId}&`);

				const {
					data: { programs, total },
				} = await ProgramsAPI.index(query);

				setPrograms(
					programs.map((p) => {
						return {
							...p,
							genreId: p?.genreId?._id ? p?.genreId?._id : undefined,
						};
					})
				);
				setPagination((prev) => ({ ...prev, total }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os programas');
			} finally {
				setFallback((prev) => ({ ...prev, fetchingPrograms: false }));
			}
		};

		fetchPrograms();
	}, [filters, pagination.current, pagination.pageSize]); //eslint-disable-line

	useEffect(() => {
		/**
		 * Disparado quando alguma filtragem é realizada
		 * Reseta a página atual para 1
		 * */
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Gerenciar programas' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'PAINEL ADMINISTRATIVO' },
						{ breadcrumbName: 'CONTEÚDOS' },
						{ breadcrumbName: 'GERENCIAR PROGRAMAS' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Gerenciar programas</Typography.Title>
					<Link to='/admin/contents/programs/create-program'>
						<Button type='primary'>
							<FiPlusCircle /> Novo programa
						</Button>
					</Link>
				</header>

				<Form.Container layout='30% 30% 30%'>
					<Form.Item label='Filtrar por usuário'>
						<Select
							showSearch
							optionFilterProp='children'
							placeholder='Selecione o usuário'
							value={filters?.userId}
							onChange={(value) => {
								setFilters({ ...filters, userId: value });
							}}
							filterOption={(input, { props: { _search } }) => {
								const regex = new RegExp(input, 'i');

								return _search.match(regex);
							}}>
							{users.map((user) => {
								const { radioName, city, state, email } = user;

								return (
									<Select.Option
										key={user?._id}
										value={user?._id}
										_search={`${radioName}${city}${state}${email}`}>
										{radioName} - {city}/{state} ({email})
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>

					<Form.Item label='Buscar por nome' help='Forneça no mínimo 3 caracteres'>
						<Input.Search
							allowClear
							onChange={({ target: { value } }) => !value && setFilters({ ...filters, name: '' })}
							onSearch={(value) => setFilters({ ...filters, name: value })}
							placeholder='Informe o nome do programa'
						/>
					</Form.Item>

					<Form.Item label='Filtrar por status de exclusão'>
						<Radio.Group
							size='small'
							buttonStyle='solid'
							value={filters?.isDeleted}
							onChange={({ target: { value } }) => setFilters({ ...filters, isDeleted: value })}>
							<Radio.Button value={false}>Não Excluídos</Radio.Button>
							<Radio.Button value={true}>Somente Excluídos</Radio.Button>
						</Radio.Group>
					</Form.Item>
				</Form.Container>

				<Divider />

				<TableHeader>
					<div className='actions'>
						<span>
							Quantidade: <strong>{pagination?.total}</strong>
						</span>
					</div>

					<PageSizeHandler pagination={pagination} setPagination={setPagination} />
				</TableHeader>

				<Table
					rowKey='_id'
					size='middle'
					columns={columns}
					dataSource={programs}
					loading={fallback?.fetchingPrograms}
					style={{ border: 'none' }}
					pagination={{
						...pagination,
						size: 'large',
						onChange: (current) => setPagination({ ...pagination, current }),
					}}
					rowSelection={{
						selectedRowKeys: selectedPrograms,
						onChange: (_, selectedRows) => {
							setSelectedPrograms(selectedRows.map(({ _id }) => _id));
						},
					}}
				/>

				<Modal
					visible={visibleModals?.editProgram}
					title={
						<>
							<FiEdit2 /> Editar programa
						</>
					}
					okText={
						<>
							<FiSave /> Salvar alterações
						</>
					}
					cancelText={
						<>
							<FiXCircle /> Cancelar
						</>
					}
					closable={!fallback?.editing}
					okButtonProps={{ loading: fallback?.editing }}
					cancelButtonProps={{ disabled: fallback?.editing }}
					onOk={() => handleUpdateProgram(handlingProgram)}
					onCancel={() => {
						setVisibleModals({ ...visibleModals, editProgram: false });
						setHandlingProgram(null);
					}}>
					<Form.Container>
						<Form.Item label='Nome'>
							<Input
								placeholder='Nome do programa'
								value={handlingProgram?.name}
								onChange={({ target: { value } }) => {
									setHandlingProgram({ ...handlingProgram, name: value });
								}}
							/>
						</Form.Item>

						<Form.Item label='Descrição'>
							<Input.TextArea
								placeholder='Descrição do programa'
								value={handlingProgram?.description}
								onChange={({ target: { value } }) => {
									setHandlingProgram({ ...handlingProgram, description: value });
								}}
							/>
						</Form.Item>

						<Form.Item label='Gênero'>
							<Select
								showSearch
								optionFilterProp='children'
								placeholder='Selecione o gênero'
								value={handlingProgram?.genreId}
								onChange={(value) => {
									setHandlingProgram({ ...handlingProgram, genreId: value });
								}}>
								{genres.map((genre) => (
									<Select.Option value={genre?._id} key={genre?._id}>
										{genre.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Locutor (Opcional)'>
							<Select
								showSearch
								optionFilterProp='children'
								placeholder='Selecione o locutor'
								value={handlingProgram?.caster || undefined}
								onChange={(value) => {
									setHandlingProgram({ ...handlingProgram, caster: value });
								}}>
								{casters.map((caster) => (
									<Select.Option value={caster?._id} key={caster?._id}>
										{caster.name} {caster.surname}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Padrão do nome dos arquivos'>
							<Input
								placeholder='Informe o prefixo dos arquivos baixados'
								value={handlingProgram?.filenameTemplate}
								onChange={({ target: { value } }) => {
									setHandlingProgram({ ...handlingProgram, filenameTemplate: value });
								}}
							/>
						</Form.Item>

						<Form.Item label='Edição'>
							<Radio.Group
								value={handlingProgram?.isEditable}
								onChange={({ target: { value } }) =>
									setHandlingProgram({ ...handlingProgram, isEditable: value })
								}>
								<Radio value={true}>Edição permitida</Radio>
								<Radio value={false}>
									Edição <strong>não</strong> permitida
								</Radio>
							</Radio.Group>
						</Form.Item>
					</Form.Container>
				</Modal>
			</Container>
		</>
	);
};

export default ManagePrograms;
