import React from 'react';
import { Typography, Divider } from 'antd';

import Meta from '../../../components/Meta';
import HelpButtons from '../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../assets/images/tutorials/soundtracks/img-01.png';
import img02 from '../../../assets/images/tutorials/soundtracks/img-02.png';
import img03 from '../../../assets/images/tutorials/soundtracks/img-03.png';
import img04 from '../../../assets/images/tutorials/soundtracks/img-04.png';
import img05 from '../../../assets/images/tutorials/soundtracks/img-05.png';
import img06 from '../../../assets/images/tutorials/soundtracks/img-06.png';

const { Title, Paragraph } = Typography;

const Soundtracks = () => (
	<Container>
		<Meta title='Ajuda - Trilhas Sonoras' />

		<Title>Trilhas Sonoras</Title>

		<Paragraph>
			São arquivos de áudio usados na mixagem das vozes do seu conteúdo, são literalmente trilhas
			sonoras que são inseridas como música de fundo enquanto o locutor fala.
		</Paragraph>

		<Paragraph>
			Para acessar, basta ir no item <i>Trilhas Sonoras</i>, no menu lateral.
		</Paragraph>

		<ImgContainer>
			<img src={img01} alt='Trilhas Sonoras' />
		</ImgContainer>

		<Paragraph>
			Uma trilha sonora sempre será anexada a um programa, este por sua vez pode possuir quantas
			trilhas sonras desejar.
		</Paragraph>

		<Paragraph>
			Para cadastrar novas trilhas sonoras, clique no botão <i>Nova Trilha Sonora</i>, na parte
			superior direita da página:
		</Paragraph>

		<ImgContainer>
			<img src={img02} alt='Trilhas Sonoras' />
		</ImgContainer>

		<Paragraph>
			Após isso, forneça um nome, informe o programa ao qual pertencerá as trilhas sonoras
			cadastradas e selecione os arquivos de áudio. Após isso clique em <i>Subir Trilhas</i>.
			Aguarde o processo ser finalizado.
		</Paragraph>

		<Divider />

		<Title level={3}>Configurando Trilhas Sonoras</Title>

		<Paragraph>
			Por padrão, as trilhas sonoras são inseridas em todas as vozes do seu programa(<i>OFF's</i>,{' '}
			<i>Notícias</i>, <i>Entrevistas</i>, etc) de forma aleatória, porém haverão casos em que, por
			exemplo, você fez o upload de um arquivo de voz já foi produzido com trilhas sonora. Nesse
			caso, você pode desligar essa função no sistema, evitando assim a dupla inserção de trilha no
			áudio.
		</Paragraph>

		<Paragraph>
			Isso pode ser feito indo até o modelo do programa e clicando em{' '}
			<i>Configurar trilhas sonoras</i>.
		</Paragraph>

		<ImgContainer>
			<img src={img03} alt='Trilhas Sonoras' />
		</ImgContainer>

		<Paragraph>
			Na janela recém aberta, desative a trilha sonora dos arquivos que desejar clicando na chave ao
			lado de cada elemento <i>VOZ</i>.
		</Paragraph>

		<ImgContainer>
			<img src={img04} alt='Trilhas Sonoras' />
		</ImgContainer>

		<Paragraph>Após isso, salve o modelo.</Paragraph>

		<ImgContainer>
			<img src={img05} alt='Trilhas Sonoras' />
		</ImgContainer>

		<Paragraph>
			Vale lembrar que os elementos que não possuem trilha sonora podem ser identificados no modelo
			pelo seguinte texto:
		</Paragraph>

		<ImgContainer>
			<img src={img06} alt='Trilhas Sonoras' />
		</ImgContainer>

		<Divider />

		<HelpButtons
			prev={{
				label: 'Assinaturas',
				to: '/help/signatures',
			}}
			next={{ label: 'Narrações Rede', to: '/help/main-voices' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default Soundtracks;
