import React, { useCallback, useState } from 'react';

import PlayerContext from './context';

const PlayerProvider = ({ children }) => {
	const [_meta, setMeta] = useState(null);
	const [visibility, setVisibility] = useState('hidden');
	const [isPlaying, setIsPlaying] = useState(false);
	const [audioSrc, setAudioSrc] = useState('');
	const [ref, setRef] = useState(null);

	const start = useCallback(
		({ src, meta, ref }) => {
			if (!src) {
				if (!audioSrc) {
					return;
				}

				if (meta) {
					setMeta(meta);
				}

				setRef(ref);
				setVisibility(visibility === 'hidden' ? 'full' : visibility);
				setIsPlaying(true);
			} else {
				if (meta) {
					setMeta(meta);
				}

				setRef(ref);
				setAudioSrc(src);
				setVisibility(visibility === 'hidden' ? 'full' : visibility);
				setIsPlaying(true);
			}
		},
		[audioSrc, visibility]
	);

	const resume = useCallback(() => {
		setIsPlaying(false);
	}, []);

	const stop = useCallback(() => {
		setRef(null);
		setAudioSrc(null);
		setVisibility('hidden');
		setIsPlaying(false);
	}, []);

	const minimize = useCallback(() => {
		setVisibility('minimized');
	}, []);

	const maximize = useCallback(() => {
		setVisibility('full');
	}, []);

	return (
		<PlayerContext.Provider
			value={{
				ref,
				visibility,
				isPlaying,
				audioSrc,
				meta: _meta,
				start,
				resume,
				stop,
				minimize,
				maximize,
			}}
		>
			{children}
		</PlayerContext.Provider>
	);
};

export default PlayerProvider;
