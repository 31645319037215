import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Settings from '../pages/Commom/Settings';
import Tracks from '../pages/Commom/Tracks';
import Categories from '../pages/Commom/Categories';

import NotFound from '../pages/Exceptions/_404';

import AppLayout from '../components/Layout';
import { TracksDbManagerNavigation } from '../components/Navigation';

const CommomStack = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path='/'>
				<Redirect to='/commom/tracks' />
			</Route>

			<Route exact path='/login'>
				<Redirect to='/commom/tracks' />
			</Route>

			<Route exact path='/commom'>
				<Redirect to='/commom/tracks' />
			</Route>

			<Route exact path='/commom/settings'>
				<AppLayout navigation={<TracksDbManagerNavigation />}>
					<Settings />
				</AppLayout>
			</Route>

			<Route exact path='/commom/tracks'>
				<AppLayout navigation={<TracksDbManagerNavigation />}>
					<Tracks />
				</AppLayout>
			</Route>
			<Route exact path='/commom/categories'>
				<AppLayout navigation={<TracksDbManagerNavigation />}>
					<Categories />
				</AppLayout>
			</Route>

			<Route exact path='*'>
				<NotFound />
			</Route>
		</Switch>
	</BrowserRouter>
);

export default CommomStack;
