import { useContext } from 'react';

import { ConfigsContext } from '../contexts/ConfigsContext';

const useConfigs = () => {
	const context = useContext(ConfigsContext);
	return context;
};

export default useConfigs;
