import styled from 'styled-components';

export const Container = styled.div`
	padding: 16px;

	& > .ant-card {
		margin-bottom: 16px;
		width: 100%;
		height: 100%;
	}

	.ant-collapse-borderless > .ant-collapse-item {
		border: none !important;
		background: transparent !important;
	}

	.ant-card-body {
		padding: 0 !important;
		width: 100%;
		height: 600px;
		display: flex;
		flex-direction: column;

		& div.__body-content {
			padding: 15px 0;
			overflow: auto;
			flex: 1;
		}

		& footer.__actions {
			width: 100%;
			display: flex;
			padding: 15px 25px;
			justify-content: flex-end;
			border-top: 1px solid #dedede;
		}
	}
`;

export const NamesContainer = styled.ul`
	width: 100%;
	margin: 0;
	padding: 0;
`;

export const NamesConfigRow = styled.li`
	list-style: none;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;

	& > div.label {
		width: 300px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		& > span {
			font-size: 18px;
			font-weight: 600;
		}
	}

	& > div.divider {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 25px;
	}

	& input {
		width: 300px;
	}
`;
