import React, { useMemo } from 'react';
import { Avatar, Typography } from 'antd';
import { connect } from 'react-redux';

import { Container } from './styles';

const LoggedUser = ({ user }) => {
	const initials = useMemo(() => {
		if (!user) return null;

		const letters = [];
		const words = user?.radioName.split(' ');

		words[0] && letters.push(words[0][0]);
		words[1] && letters.push(words[1][0]);

		return letters.join().replace(/,/g, '').toUpperCase();
	}, [user]);

	return (
		<Container>
			<Avatar style={{ background: 'var(--primary)' }} size='default'>
				{initials}
			</Avatar>
			<div>
				<Typography.Text className='name'>{user?.radioName}</Typography.Text>
				<Typography.Text className='email'>{user?.email}</Typography.Text>
			</div>
		</Container>
	);
};

export default connect(({ user }) => ({ user }))(LoggedUser);
