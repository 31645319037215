import React from 'react';
import { BarLoader } from 'react-spinners';

import { Container } from './styles';

const Fallback = ({ title, message, ...rest }) => {
	return (
		<Container {...rest}>
			{title && <p className='title'>{title}</p>}
			{message && <p className='message'>{message}</p>}
			<BarLoader color='#102b9c' height={8} width={400} />
		</Container>
	);
};

export default Fallback;
