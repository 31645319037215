import api from '../api';

const BASE_URL = '/api/v2/generation-requests';

const GenerationRequestsApi = {
	index: (query) => api.get(`${BASE_URL}?${query}`),
	store: (data) => api.post(BASE_URL, data),
	update: (data, id) => api.patch(`${BASE_URL}/${id}`, data),
};

export default GenerationRequestsApi;
