import { checkResource, checkResourcesGroup } from './checkResource';

export function getNavigationByResources(navigation, user) {
	return navigation
		.map((menuItem) => {
			if (menuItem.type === 'MENU') {
				const options = menuItem.options || { strict: true };
				return checkResource(user, menuItem.resources, options) ? menuItem : null;
			}

			if (menuItem.type === 'SUB-MENU') {
				const options = menuItem.options || { strict: true };

				return (
					menuItem.resources
						? checkResource(user, menuItem.resources, options)
						: checkResourcesGroup(user, menuItem.resourcesGroups)
				)
					? {
							...menuItem,
							items: menuItem.items.map((subItem) =>
								checkResource(user, subItem.resources, options) ? subItem : null
							),
					  }
					: null;
			}

			return menuItem;
		})
		.filter((menuItem) => menuItem !== null)
		.map((menuItem) =>
			menuItem.type === 'MENU'
				? menuItem
				: { ...menuItem, items: menuItem.items.filter((subItem) => subItem !== null) }
		);
}
