import api from '../api';

const BASE_URL = 'api/v2/terms';

const useTermsApi = {
	getCurrentTerm: () => api.get(`${BASE_URL}/current`),
	update: (termId, payload) => api.put(`${BASE_URL}/${termId}`, payload),
	create: (payload) => api.post(`${BASE_URL}`, payload),
	agree: (userId) => api.patch(`${BASE_URL}/${userId}/agree`),
};

export default useTermsApi;
