import api from '../api';

const BASE_URL = '/api/v2/users';

const UsersAPI = {
	index: (query) => api.get(`${BASE_URL}${query ? `?${query}` : ''}`),
	getOne: (userId) => api.get(`${BASE_URL}/${userId}`),
	create: (payload) => api.post(`${BASE_URL}`, payload),
	update: (userId, payload) => api.patch(`${BASE_URL}/${userId}`, payload),
	updatePassword: (userId, payload) => api.patch(`${BASE_URL}/${userId}/password`, payload),
	toggleActive: (userId) => api.delete(`${BASE_URL}/${userId}/toggle-active`),
	delete: (userId) => api.delete(`${BASE_URL}/${userId}`),
};

export default UsersAPI;
