import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import path from 'path';
import axios from 'axios';
import JSZip from 'jszip';
import {
	Table,
	Button,
	Icon,
	Modal,
	Tag,
	Select,
	message,
	Menu,
	Dropdown,
	Typography,
	Breadcrumb,
	Divider,
	Tabs,
	Card,
	Empty,
	Tooltip,
} from 'antd';

import Meta from '../../../components/Meta';
import Form from '../../../components/Form';
import Fallback from '../../../components/Fallback';
import PageSizeHandler from '../../../components/PageSizeHandle';
import PlayCell from '../../../components/PlayCell';
import FileExt from '../../../components/FileExt';
import FileDuration from '../../../components/FileDuration';
import { PageHeader, CardContainer, TableHeader, TrackSignature } from './styles';

import GenresAPI from '../../../services/sdks/genres';
import SignaturesAPI from '../../../services/sdks/signatures';
import TrackSignaturesAPI from '../../../services/sdks/trackSignatures';
import { resolveFileSrc } from '../../../helpers/fileSrcResolver';

import {
	FiPlusCircle,
	FiDownload,
	FiEdit2,
	FiSave,
	FiXCircle,
	FiMusic,
	FiActivity,
	FiTrash2,
	FiRefreshCw,
} from 'react-icons/fi';

import { usePlayer, useDownload, useFilesValidator } from '../../../hooks';
import FilesUploader from '../../../components/FilesUploader';
import { getResource } from '../../../helpers/getResource';
import resourcesKeys from '../../../constants/resourcesKeys';

const ManageSignatures = ({ user }) => {
	const player = usePlayer();
	const download = useDownload();
	const { hasValidationError } = useFilesValidator();

	const [fallback, setFallback] = useState({ initialData: true });
	const [visibleModals, setVisibleModals] = useState({});
	const [selectedSignatures, setSelectedSignatures] = useState([]);
	const [signatures, setSignatures] = useState([]);
	const [signature, setSignature] = useState(null);
	const [genres, setGenres] = useState([]);
	const [trackSignature, setTrackSignature] = useState(null);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [filters, setFilters] = useState({ genre: null, verified: null });
	const [newSignatureTab, setNewSignatureTab] = useState('vignette-signatures');

	const columns = [
		{
			title: 'Assinatura',
			key: 'signature',
			render: (_, signature, index) => (
				<PlayCell
					meta={{
						primary: `VINHETA DE ASSINATURA#${index + 1}`,
						secondary: null,
					}}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: signature.filename }),
							ref: signature?._id,
							meta: {
								name: `VINHETA DE ASSINATURA#${index + 1}`,
								artist: null,
							},
						});
					}}
					onPause={player.resume}
					isPlaying={player?.ref === signature?._id && player?.isPlaying}
				/>
			),
		},
		{
			title: 'Gênero',
			dataIndex: 'genreId',
			key: 'genre',
			render: ({ name }) => name,
		},
		{
			title: 'Duração',
			dataIndex: 'filename',
			key: 'duration',
			align: 'center',
			render: (filename) => <FileDuration src={resolveFileSrc({ fileName: filename })} />,
		},
		{
			title: 'Formato',
			dataIndex: 'filename',
			key: 'ext',
			align: 'center',
			render: (filename) => <FileExt src={resolveFileSrc({ fileName: filename })} />,
		},
		{
			title: 'Status',
			dataIndex: 'verified',
			key: 'verified',
			align: 'center',
			render: (verified) => (
				<Tag color={verified === 1 ? 'green' : verified === 0 ? 'gold' : 'red'}>
					<Icon
						style={{ marginRight: 4 }}
						type={
							verified === 1 ? 'check-circle' : verified === 0 ? 'clock-circle' : 'close-circle'
						}
					/>
					{verified === 1 ? 'Ativa' : verified === 0 ? 'Aprovação Pendente' : 'Reprovada'}
				</Tag>
			),
		},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					8
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'center',
			key: 'options',
			render: (signature) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									key='play'
									onClick={() => {
										player.start({
											src: resolveFileSrc({ fileName: signature.filename }),
											ref: signature?._id,
											meta: {
												name: 'VINHETA DE ASSINATURA',
												artist: null,
											},
										});
									}}>
									<Icon type='play-circle' /> Reproduzir
								</Menu.Item>

								<Menu.Item
									key='play'
									onClick={() => {
										download({ filename: signature?.filename, name: 'VINHETA DE ASSINATURA' });
									}}>
									<Icon type='download' /> Baixar
								</Menu.Item>

								<Menu.Item
									key='edit-signature'
									onClick={() => {
										setSignature(signature);
										setVisibleModals({ ...visibleModals, updateVignetteSignature: true });
									}}>
									<Icon type='edit' /> Alterar gênero
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item
									key='delete'
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: 'Excluir assinatura?',
											icon: 'exclamation-circle',
											content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											onOk: () => {
												handleDeleteVignetteSignature({ signatureId: signature?._id });
											},
											okButtonProps: { type: 'danger' },
											okText: (
												<>
													<FiTrash2 /> Excluir
												</>
											),
											cancelText: (
												<>
													<FiXCircle /> Cancelar
												</>
											),
										});
									}}>
									<Icon type='delete' /> Excluir
								</Menu.Item>
							</Menu>
						}>
						<Icon style={{ cursor: 'pointer', fontSize: 20 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const handleCreateVignetteSignatures = useCallback(async () => {
		try {
			if (!signature?.genreId) return message.error('Selecione o gênero');
			if (!signature?.files?.length) return message.error('Selecione no mínimo um arquivo');
			if (hasValidationError(signature.files)) {
				return message.warning(
					'Alguns dos arquivos selecionados estão corrompidos. Por favor, substitua-os por arquivos válidos.'
				);
			}

			setFallback((prev) => ({ ...prev, creating: true }));

			let newSignatures = [];

			await Promise.all(
				signature.files.map(async (file) => {
					const payload = new FormData();
					payload.append('file', file.data);
					payload.append('userId', user?._id);
					payload.append('genreId', signature.genreId);

					const {
						data: { signatures },
					} = await SignaturesAPI.store({
						payload,
						onUploadProgress: ({ total, loaded }) => {
							file.upload.update(Math.floor((loaded * 100) / total));
						},
					});

					newSignatures.push(...signatures);
				})
			);

			const newGenre = genres.find(({ _id }) => _id === signature?.genreId);
			newSignatures = newSignatures.map((s) => ({ ...s, genreId: newGenre }));

			setSignatures((prev) => [...prev, ...newSignatures]);
			setVisibleModals((prev) => ({ ...prev, create: false }));
			setPagination((prev) => ({ ...prev, total: prev.total + newSignatures.length }));

			message.success('Assinaturas cadastradas com sucesso', 3);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		} finally {
			setSignature(null);
			setNewSignatureTab('vignette-signatures');
			setFallback((prev) => ({ ...prev, creating: false }));
		}
	}, [signature, hasValidationError, genres, user]);

	const handleUpdateVignetteSignature = useCallback(async () => {
		try {
			setFallback((prev) => ({ ...prev, updatingVignetteSignature: true }));

			const payload = { genreId: signature.genreId?._id };

			await SignaturesAPI.modify({ signatureId: signature?._id, payload });

			const newGenre = genres.find(({ _id }) => _id === signature?.genreId?._id);

			setSignatures((signatures) =>
				signatures.map((s) => {
					if (s?._id === signature?._id) {
						return { ...s, genreId: newGenre };
					}

					return s;
				})
			);

			setVisibleModals((prev) => ({ ...prev, updateVignetteSignature: false }));
			setSignature(null);

			return message.success('Assinatura foi editada com sucesso');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao editar a assinatura');
		} finally {
			setFallback((prev) => ({ ...prev, updatingVignetteSignature: false }));
		}
	}, [signature, genres]);

	const handleDeleteVignetteSignature = useCallback(async ({ signatureId }) => {
		try {
			await SignaturesAPI.destroy({ signatureId });

			setSignatures((signatures) => signatures.filter((s) => s._id !== signatureId));

			return message.success('Assinatura foi excluída com sucesso');
		} catch (error) {
			if (error?.isHttpError) {
				console.error(error?.message);
				message.error(error?.message);
			} else {
				console.error(error);
				message.error('Houve um erro ao excluir a assinatura');
			}
		}
	}, []);

	const handleCreateTrackSignature = useCallback(async () => {
		try {
			if (!getResource(user, resourcesKeys.TRACK_SIGNATURE)) {
				return message.warn('Você não pode criar assinaturas de músicas');
			}

			if (!signature?.files?.length) return message.error('Selecione um arquivo de áudio');
			if (hasValidationError(signature.files)) {
				return message.warning(
					'Alguns dos arquivos selecionados estão corrompidos. Por favor, substitua-os por arquivos válidos.'
				);
			}

			setFallback((prev) => ({ ...prev, creating: true }));
			const payload = new FormData();
			const file = signature?.files[0];

			payload.append('user', user?._id);
			payload.append('file', file.data);

			const {
				data: { trackSignature },
			} = await TrackSignaturesAPI.store({
				payload,
				onUploadProgress: ({ total, loaded }) => {
					file.upload.update(Math.floor((loaded * 100) / total));
				},
			});

			setTrackSignature(trackSignature);
			setVisibleModals((prev) => ({ ...prev, create: false }));

			message.success('Assinatura enviada com sucesso');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro');
		} finally {
			setSignature(null);
			setNewSignatureTab('vignette-signatures');
			setFallback((prev) => ({ ...prev, creating: false }));
		}
	}, [hasValidationError, signature, user]);

	const handleUpdateTrackSignature = useCallback(async () => {
		try {
			if (!getResource(user, resourcesKeys.TRACK_SIGNATURE)) {
				return message.warn('Você não pode criar/alterar assinaturas de músicas');
			}

			if (!signature?.files?.length) return message.error('Selecione um arquivo de áudio');
			if (hasValidationError(signature.files)) {
				return message.warning(
					'Alguns dos arquivos selecionados estão corrompidos. Por favor, substitua-os por arquivos válidos.'
				);
			}

			setFallback((prev) => ({ ...prev, updatingTrackSignature: true }));

			const payload = new FormData();
			const file = signature?.files[0];

			payload.append('user', user?._id);
			payload.append('files', file.data);

			await TrackSignaturesAPI.destroy({ signatureId: trackSignature?._id });

			const {
				data: { trackSignature: newTrackSignature },
			} = await TrackSignaturesAPI.store({
				payload,
				onUploadProgress: ({ total, loaded }) => {
					file.upload.update(Math.floor((loaded * 100) / total));
				},
			});

			setTrackSignature(newTrackSignature);
			setVisibleModals((prev) => ({ ...prev, updateTrackSignature: false }));

			message.success('Assinatura atualizada com sucesso');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro');
		} finally {
			setSignature(null);
			setFallback((prev) => ({ ...prev, updatingTrackSignature: false }));
		}
	}, [signature, user, trackSignature, hasValidationError]);

	const handleDeleteTrackSignature = useCallback(async () => {
		try {
			await TrackSignaturesAPI.destroy({ signatureId: trackSignature?._id });

			message.success('Assinatura de músicas excluída com sucesso');

			return setTrackSignature(null);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao excluir a assinatura');
		}
	}, [trackSignature]);

	const handleDownloadAsZIP = useCallback(async () => {
		setFallback((prev) => ({ ...prev, multiDownload: true }));

		const zip = new JSZip();

		for (let index = 0; index < selectedSignatures.length; index++) {
			const signature = selectedSignatures[index];
			const filePath = resolveFileSrc({ fileName: signature.filename });
			const blob = await axios.get(filePath, { responseType: 'blob' });
			const name = `VINHETA DE ASSINATURA`;
			const ext = path.extname(signature?.filename);
			const fName = `${name}${ext}`;

			zip.file(fName, blob.data, { binary: true });
		}

		const zipContent = await zip.generateAsync({ type: 'blob' });

		let downloadName = 'VINHETAS DE ASSINATURA';

		saveAs(zipContent, downloadName);
		setFallback((prev) => ({ ...prev, multiDownload: false }));
	}, [selectedSignatures]);

	useEffect(() => {
		const fetchGenres = async () => {
			try {
				const {
					data: { genres },
				} = await GenresAPI.index();

				setGenres(genres);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os gêneros');
			}
		};

		const fetchTrackSignature = async () => {
			try {
				const query = `user=${user?._id}`;
				const {
					data: { trackSignatures },
				} = await TrackSignaturesAPI.index({ query });

				setTrackSignature(trackSignatures[0]);
			} catch (error) {
				if (error?.isHttpError && error?.code === 404) {
					/** Não há uma assinatura de músicas cadastrada */
					setTrackSignature(null);
				} else {
					console.error(error);
					message.error('Houve um erro ao buscar a assinatura de músicas');
				}
			}
		};

		const fetchInitialData = async () => {
			try {
				await fetchGenres();
				await fetchTrackSignature();
			} catch (error) {
				console.error(error);
			} finally {
				setFallback((prev) => ({ ...prev, initialData: false }));
			}
		};

		fetchInitialData();
	}, [user]);

	useEffect(() => {
		/** Reseta a página atual para 1 sempre que os filtros mudal */
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters]);

	useEffect(() => {
		const fetchSignatures = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingSignatures: true }));

				let query = `page=${pagination?.current - 1}&limit=${pagination?.pageSize}&userId=${
					user?._id
				}&`;

				filters.genre && (query = `${query}&genreId=${filters.genre}`);
				filters.verified !== null && (query = `${query}&verified=${filters.verified}`);

				const {
					data: { signatures, total },
				} = await SignaturesAPI.index({ query });

				setSignatures(signatures);
				setPagination((prev) => ({ ...prev, total }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as assinaturas');
			} finally {
				setFallback((prev) => ({ ...prev, fetchingSignatures: false }));
			}
		};

		fetchSignatures();
	}, [user, filters, pagination.current, pagination.pageSize]); //eslint-disable-line

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Assinaturas' />

			<PageHeader>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[{ breadcrumbName: 'INÍCIO' }, { breadcrumbName: 'ASSINATURAS' }]}
				/>

				<header>
					<Typography.Title level={2}>Assinaturas</Typography.Title>

					<div>
						<Button
							type='primary'
							onClick={() => setVisibleModals((prev) => ({ ...prev, create: true }))}>
							<FiPlusCircle /> Criar Assinaturas
						</Button>
					</div>
				</header>
			</PageHeader>

			<CardContainer>
				<Card className='_card'>
					<Tabs size='large'>
						<Tabs.TabPane
							key='vignette-signatures'
							tab={
								<>
									<FiActivity /> Vinhetas de Assinatura
								</>
							}>
							<div className='tab-content'>
								<Form.Container layout='30% 30% auto'>
									<Form.Item label='Filtrar por gênero'>
										<Select
											showSearch
											value={filters?.genre}
											placeholder='Selecione um gênero'
											onChange={(genre) => setFilters({ ...filters, genre })}
											filterOption={(input, { props: { children } }) => {
												return String(children).match(new RegExp(input, 'i'));
											}}>
											<Select.Option value={null}>TODOS</Select.Option>

											{genres.map((genre) => (
												<Select.Option key={genre?._id} value={genre?._id}>
													{genre?.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>

									<Form.Item label='Filtrar por status'>
										<Select
											showSearch
											value={filters?.verified}
											onChange={(verified) => setFilters({ ...filters, verified })}>
											<Select.Option value={null}>TODOS</Select.Option>
											<Select.Option value={0}>SOMENTE PENDENTES</Select.Option>
											<Select.Option value={1}>SOMENTE ATIVAS</Select.Option>
											<Select.Option value={2}>SOMENTE REPROVADAS</Select.Option>
										</Select>
									</Form.Item>
								</Form.Container>

								<Divider />

								<TableHeader>
									<div className='actions'>
										<span>
											Quantidade: <strong>{pagination?.total}</strong>
										</span>
										<div>
											<Button
												size='small'
												disabled={!selectedSignatures.length}
												type='ghost'
												onClick={() => handleDownloadAsZIP()}
												loading={fallback?.multiDownload}>
												<FiDownload /> Baixar selecionados{' '}
												{selectedSignatures.length !== 0 && `(${selectedSignatures.length})`}
											</Button>
										</div>
									</div>

									<PageSizeHandler pagination={pagination} setPagination={setPagination} />
								</TableHeader>

								<Table
									rowKey='_id'
									size='middle'
									columns={columns}
									dataSource={signatures}
									style={{ border: 'none' }}
									loading={fallback?.fetchingSignatures}
									pagination={{
										...pagination,
										size: 'large',
										onChange: (current) => setPagination({ ...pagination, current }),
									}}
									rowSelection={{
										onChange: (_, selectedRows) => setSelectedSignatures(selectedRows),
									}}
								/>
							</div>
						</Tabs.TabPane>

						<Tabs.TabPane
							key='track-signatures'
							disabled={!getResource(user, resourcesKeys.TRACK_SIGNATURE)}
							tab={
								<>
									<FiMusic /> Assinatura de Músicas
								</>
							}>
							<div className='tab-content'>
								{trackSignature ? (
									<TrackSignature>
										<div className='main'>
											<PlayCell
												meta={{
													primary: `ASSINATURA DE MÚSICAS`,
													secondary:
														'Essa assinatura é utilizada para carimbar as músicas e tornar seus conteúdos ainda mais dinâmicos',
												}}
												onPlay={() => {
													player.start({
														src: resolveFileSrc({ fileName: trackSignature.filename }),
														ref: trackSignature?._id,
														meta: {
															name: `ASSINATURA DE MÚSICAS`,
															artist: null,
														},
													});
												}}
												onPause={player.resume}
												isPlaying={player?.ref === trackSignature?._id && player?.isPlaying}
											/>

											<div className='buttons'>
												<Button
													type='danger'
													onClick={() => {
														return Modal.confirm({
															title: 'Excluir assinatura de músicas?',
															icon: 'exclamation-circle',
															content:
																'Suas músicas não serão mais carimbadas, deseja realmente continuar',
															onOk: () => handleDeleteTrackSignature(),
															okButtonProps: { type: 'danger' },
															okText: (
																<>
																	<FiTrash2 /> Excluir
																</>
															),
															cancelText: (
																<>
																	<FiXCircle /> Cancelar
																</>
															),
														});
													}}>
													<FiTrash2 /> Excluir
												</Button>

												<Button
													onClick={() => {
														setVisibleModals({ ...visibleModals, updateTrackSignature: true });
													}}>
													<FiRefreshCw /> Substituir
												</Button>

												<Button
													onClick={() => {
														download({
															filename: trackSignature?.filename,
															name: 'ASSINATURA DE MÚSICAS',
														});
													}}>
													<FiDownload /> Baixar
												</Button>
											</div>
										</div>

										<Divider style={{ margin: '16px 0' }} />

										<div className='infos'>
											<div>
												<strong>Formato: </strong>
												<FileExt src={resolveFileSrc({ fileName: trackSignature.filename })} />
											</div>

											<div>
												<strong>Duração: </strong>
												<FileDuration src={resolveFileSrc({ fileName: trackSignature.filename })} />
											</div>
										</div>
									</TrackSignature>
								) : (
									<Empty
										description={
											<Typography.Text>
												Você ainda não possui uma assinatura de música cadastrada
											</Typography.Text>
										}>
										<Button
											type='primary'
											size='large'
											onClick={() => {
												setVisibleModals({ ...visibleModals, create: true });
												setNewSignatureTab('track-signature');
											}}>
											<FiPlusCircle /> Criar Assinatura de Música
										</Button>
									</Empty>
								)}
							</div>
						</Tabs.TabPane>
					</Tabs>
				</Card>
			</CardContainer>

			<Modal
				destroyOnClose={true}
				closable={!fallback?.creating}
				visible={visibleModals?.create}
				title={
					<>
						<FiPlusCircle /> Criar assinaturas
					</>
				}
				okButtonProps={{
					loading: fallback?.creating,
					disabled:
						newSignatureTab === 'vignette-signatures'
							? !signature?.genreId || !signature?.files?.length
							: !signature?.files?.length,
				}}
				cancelButtonProps={{ disabled: fallback?.creating }}
				okText={
					<>
						<FiSave /> Salvar
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				onOk={() => {
					return newSignatureTab === 'vignette-signatures'
						? handleCreateVignetteSignatures()
						: handleCreateTrackSignature();
				}}
				onCancel={() => {
					setSignature(null);
					setNewSignatureTab('vignette-signatures');
					setVisibleModals((prev) => ({ ...prev, create: false }));
				}}>
				<Tabs
					activeKey={newSignatureTab}
					size='small'
					onChange={(tab) => {
						setNewSignatureTab(tab);
						setSignature(null);
					}}>
					<Tabs.TabPane
						key='vignette-signatures'
						tab={
							<>
								<FiPlusCircle /> Vinhetas de Assinatura
							</>
						}>
						<Form.Container>
							<Form.Item label='Gênero'>
								<Select
									placeholder='Selecione o gênero'
									value={signature?.genreId || undefined}
									onChange={(value) => setSignature({ ...signature, genreId: value })}>
									{genres.map((genre) => (
										<Select.Option key={genre?._id}>{genre?.name}</Select.Option>
									))}
								</Select>
							</Form.Item>

							<FilesUploader
								multiple={newSignatureTab === 'vignette-signatures'}
								onChange={(files) => setSignature((prev) => ({ ...prev, files }))}
								validTypes={['.mp3', '.wav']}
							/>
						</Form.Container>
					</Tabs.TabPane>

					<Tabs.TabPane
						key='track-signature'
						tab={
							trackSignature ? (
								<Tooltip title='Só é permitida a criação de uma assinatura de músicas por rádio'>
									<FiPlusCircle /> Assinatura de Músicas
								</Tooltip>
							) : (
								<>
									<FiPlusCircle /> Assinatura de Músicas
								</>
							)
						}
						disabled={!!trackSignature || !getResource(user, resourcesKeys.TRACK_SIGNATURE)}>
						<Form.Container>
							<FilesUploader
								multiple={false}
								onChange={(files) => setSignature((prev) => ({ ...prev, files }))}
								validTypes={['.mp3', '.wav']}
							/>
						</Form.Container>
					</Tabs.TabPane>
				</Tabs>
			</Modal>

			<Modal
				visible={visibleModals?.updateVignetteSignature}
				title={
					<>
						<FiEdit2 /> Alterar gênero
					</>
				}
				okText={
					<>
						<FiSave /> Salvar
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				onOk={handleUpdateVignetteSignature}
				okButtonProps={{
					loading: fallback?.updatingVignetteSignature,
					disabled: !signature?.files?.length,
				}}
				cancelButtonProps={{ disabled: fallback?.updatingVignetteSignature }}
				onCancel={() => {
					setSignature(null);
					setVisibleModals({ ...visibleModals, updateVignetteSignature: false });
				}}>
				<Form.Container>
					<Form.Item label='Gênero'>
						<Select
							style={{ width: '100%' }}
							placeholder='Selecione o novo gênero'
							value={signature?.genreId?._id || undefined}
							onChange={(value) =>
								setSignature({
									...signature,
									genreId: { ...signature?.genreId, _id: value },
								})
							}>
							{genres.map((genre) => (
								<Select.Option key={genre?._id}>{genre?.name}</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Form.Container>
			</Modal>

			<Modal
				visible={visibleModals?.updateTrackSignature}
				title={
					<>
						<FiRefreshCw /> Substituir vinheta de músicas
					</>
				}
				okText={
					<>
						<FiRefreshCw /> Substituir
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				onOk={handleUpdateTrackSignature}
				okButtonProps={{ loading: fallback?.updatingTrackSignature }}
				cancelButtonProps={{ disabled: fallback?.updatingTrackSignature }}
				onCancel={() => {
					setSignature(null);
					setVisibleModals({ ...visibleModals, updateTrackSignature: false });
				}}>
				<Form.Container>
					<FilesUploader
						multiple={false}
						onChange={(files) => setSignature((prev) => ({ ...prev, files }))}
						validTypes={['.mp3', '.wav']}
					/>
				</Form.Container>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(ManageSignatures);
