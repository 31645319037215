import React from 'react';
import { Radio } from 'antd';

import { Container, Header, Label, Descriptions } from './styles';

const RadioCard = ({ label, icon: Icon, checked, onClick, descriptions, disabled, ...rest }) => {
	return (
		<Container
			disabled={disabled}
			onClick={() => !disabled && onClick()}
			checked={checked}
			{...rest}>
			<Header>
				{(Icon || label) && (
					<Label>
						{Icon && <Icon />} {label}
					</Label>
				)}
				<Radio checked={checked} />
			</Header>

			{descriptions && (
				<Descriptions>
					{descriptions.map((item, index) => (
						<li key={index} style={{ fontWeight: item?.options?.strong ? 'bold' : 'normal' }}>
							{item.text}
						</li>
					))}
				</Descriptions>
			)}
		</Container>
	);
};

export default RadioCard;
