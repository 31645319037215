export const recordedOffsTypes = [
	'OFF-RECORDED-PROGRAM-INTRO',
	'OFF-RECORDED-PROGRAM-OUTRO',
	'OFF-RECORDED-BLOCK-INTRO',
	'OFF-RECORDED-BLOCK-OUTRO',
	'OFF-RECORDED-BLOCK-MID-1',
	'OFF-RECORDED-BLOCK-MID-2',
	'OFF-RECORDED-BLOCK-MID-3',
	'OFF-RECORDED-BLOCK-MID-4',
	'OFF-RECORDED-BLOCK-MID-5',
];
