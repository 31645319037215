import api from '../api';

const BASE_URL = '/api/v2/offs';

const OffsAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	show: ({ offId }) => api.get(`${BASE_URL}/${offId}`),
	store: ({ payload, onUploadProgress }) => api.post(BASE_URL, payload, { onUploadProgress }),
	modify: ({ offId, payload }) => api.patch(`${BASE_URL}/${offId}`, payload),
	destroy: ({ offId }) => api.delete(`${BASE_URL}/${offId}`),
};

export default OffsAPI;
