import styled from 'styled-components';

export const Container = styled.div`
	background: #fff;
	padding: 16px 16px;

	& .ant-breadcrumb > span:last-child {
		font-weight: 600 !important;
		opacity: 0.8;
	}

	& .ant-breadcrumb-link {
		font-size: 12px;
	}

	& > header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 32px;

		& > h2 {
			color: rgba(0, 0, 0, 0.75) !important;
			display: flex;
			align-items: center;
			margin: 0;
			margin-bottom: -10px;
		}
	}
`;

export const TableActions = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	& > * {
		margin-left: 12px;
	}
`;

export const ModalContent = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	gap: 8px;

	& > div.progress {
		padding: 32px 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
