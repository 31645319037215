import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import BannerContext from './context';
import BannersApi from '../../services/sdks/banners';
import userTypes from '../../constants/userTypes';

const BannerProvider = ({ children, user }) => {
	const [banners, setBanners] = useState([]);

	useEffect(() => {
		async function getBanners() {
			try {
				const {
					data: { banners },
				} = await BannersApi.getUnreadBanners();

				setBanners(banners);
			} catch (error) {
				setBanners([]);
			}
		}

		if (user && user?.type === userTypes.COMMON) {
			getBanners();
		}
	}, [user]);

	return <BannerContext.Provider value={{ banners }}>{children}</BannerContext.Provider>;
};

export default connect(({ user }) => ({ user }))(BannerProvider);
