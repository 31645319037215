import { useContext } from 'react';
import UpsertUserContext from './context';

const useUpsertUser = () => {
	return useContext(UpsertUserContext);
};

export { useUpsertUser };
export { default as UpsertUserContext } from './context';
export { default as UpsertUserProvider } from './provider';
