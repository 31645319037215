import api from '../api';

const BASE_URL = '/api/v2/track-presentations';

const TrackPresentationsAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	show: (trackPresentationId) => api.get(`${BASE_URL}/${trackPresentationId}`),
	store: ({ payload, onUploadProgress }) => api.post(BASE_URL, payload, { onUploadProgress }),
	delete: (trackPresentationId) => api.delete(`${BASE_URL}/delete-one/${trackPresentationId}`),
	deleteMany: (trackPresentationIds) =>
		api.post(`${BASE_URL}/delete-many`, { ids: trackPresentationIds }),
	removeProgram: (trackPresentationId, programId) =>
		api.patch(`${BASE_URL}/${trackPresentationId}/remove-program`, { programId }),
	removeTrack: (trackPresentationId, trackId) =>
		api.patch(`${BASE_URL}/${trackPresentationId}/remove-track`, { trackId }),
};

export default TrackPresentationsAPI;
