import styled from 'styled-components';

export const Container = styled.li`
	& > .ant-card {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		& > .ant-card-body {
			flex: 1;
		}
	}
`;

export const ActionButton = styled.button`
	background: none;
	border: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
	opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

	&:hover {
		color: ${({ isDanger, isDisabled }) => {
			if (isDisabled) {
				return '#c2c2c2';
			}

			return isDanger ? 'var(--danger)' : 'var(--primary)';
		}};
	}

	& > i {
		margin-bottom: 4px;
		font-size: 17px;
	}
`;

export const TitleContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	& i.favorite-icon {
		margin: 0 8px 0 16px;
		transition: 25ms;

		&:active {
			transform: scale(1.2);
		}
	}
`;

export const ProgramInfos = styled.ul`
	width: 100%;
`;

export const ProgramInfo = styled.li`
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 4px;
	margin-bottom: 8px;
	border-bottom: 1px dashed #f1f1f1;

	& .key {
		font-weight: 300;
	}

	& .value {
		font-weight: 500;
	}

	@media (max-width: 1100px) {
		flex-direction: column;

		& .key {
			margin-bottom: 4px;
		}
	}
`;
