import React from 'react';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

const _404 = () => (
	<Result
		status='404'
		title='404'
		subTitle='Desculpe, a página que você tentou acessar não existe'
		extra={
			<Link to='/commom/programs'>
				<Button type='primary' size='large' icon='home'>
					Voltar para o início
				</Button>
			</Link>
		}
	/>
);

export default _404;
