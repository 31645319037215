import React from 'react';
import { Container, Item } from './styles';

export const HeaderDropdown = ({ items }) => {
	return (
		<Container>
			{items.map((item, index) => (
				<a className='ancor' href={item.href} key={index}>
					<Item>
						<div className='icon'>
							<item.Icon color='#C13935' size={20} style={{ marginRight: 12 }} />
						</div>
						<div className='text'>
							<strong>{item.title}</strong>
							<span>{item.desc}</span>
						</div>
					</Item>
				</a>
			))}
		</Container>
	);
};
