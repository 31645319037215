import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

import { LinkContainer } from './styles';
import { FiMoreHorizontal } from 'react-icons/fi';

const SignaturesManagerNavigation = ({ collapsed }) => {
	const renderMenuTitle = useCallback(
		(title, Icon, to) =>
			to ? (
				<Link to={to}>
					{collapsed ? (
						<>
							<Icon style={{ fontSize: 18, marginBottom: -4 }} />
						</>
					) : (
						<LinkContainer>
							<Icon /> {title}
						</LinkContainer>
					)}
				</Link>
			) : collapsed ? (
				<>
					<Icon style={{ fontSize: 18, marginBottom: -4 }} />
				</>
			) : (
				<LinkContainer>
					<Icon /> {title}
				</LinkContainer>
			),
		[collapsed]
	);

	return (
		<Menu theme='dark' mode='inline' style={{ border: 'none' }}>
			<Menu.Item key='manage-signatures' title='Gerenciar Assinaturas'>
				{renderMenuTitle('Gerenciar Assinaturas', FiMoreHorizontal, '/admin/contents/signatures')}
			</Menu.Item>
		</Menu>
	);
};

export default SignaturesManagerNavigation;
