import React from 'react';
import {
	Container,
	Header,
	ContentContainer,
	ContentItem,
	IconWrapper,
	Description,
	ArrowIcon,

} from './styles';
import {
	AirplayIcon,
	ArrowRightIcon,
	BrainIcon,
	ClockIcon,
	FileMusicIcon,
	FilePenIcon,
	UploadIcon,
} from './icons';

export default function Component() {
	return (
		<Container>
			<div id="salve-audio"></div>
			<Header>Solução TalkPlay que cria programas em 7 minutos</Header>
			<ContentContainer>
				<ContentItem>
					<IconWrapper>
						<UploadIcon className='icon' />
					</IconWrapper>
					<Description>Prompt de Programa</Description>
				</ContentItem>
				<ArrowIcon className='icon'>
					<ArrowRightIcon />
				</ArrowIcon>
				<ContentItem>
					<IconWrapper>
						<BrainIcon className='icon' />
					</IconWrapper>
					<Description>Inteligência Artificial</Description>
				</ContentItem>
				<ArrowIcon className='icon'>
					<ArrowRightIcon />
				</ArrowIcon>
				<ContentItem>
					<IconWrapper>
						<FilePenIcon className='icon' />
					</IconWrapper>
					<Description>Organização e Processamento</Description>
				</ContentItem>
				<ArrowIcon className='icon'>
					<ArrowRightIcon />
				</ArrowIcon>
				<ContentItem>
					<IconWrapper>
						<FileMusicIcon className='icon' />
					</IconWrapper>
					<Description>Produção e Masterização</Description>
				</ContentItem>
				<ArrowIcon className='icon'>
					<ArrowRightIcon />
				</ArrowIcon>
				<ContentItem>
					<IconWrapper>
						<AirplayIcon className='icon' />
					</IconWrapper>
					<Description>Audio .MP3 ou .WAV</Description>
				</ContentItem>
				<ArrowIcon className='icon'>
					<ArrowRightIcon />
				</ArrowIcon>
				<ContentItem>
					<IconWrapper>
						<ClockIcon className='icon' />
					</IconWrapper>
					<Description>Execução Na Rádio</Description>
				</ContentItem>
			</ContentContainer>
		
		</Container>
	);
}
