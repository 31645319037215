import 'react-h5-audio-player/lib/styles.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip, Spin } from 'antd';

import { Container, StyledPlayer, Meta, PlayerHeader, HeaderMeta } from './styles';

import { VscChromeMinimize, VscChromeMaximize } from 'react-icons/vsc';
import { FiX, FiDownload } from 'react-icons/fi';
import {
	MdPauseCircleFilled,
	MdPlayCircleFilled,
	MdForward5,
	MdReplay5,
	MdVolumeUp,
	MdVolumeMute,
} from 'react-icons/md';

import { usePlayer, useDownload } from '../../hooks';
import env from '../../config/env';

const Player = () => {
	const h5PlayerRef = useRef(null);
	const download = useDownload();
	const { visibility, isPlaying, start, resume, stop, minimize, maximize, ref, audioSrc, meta } =
		usePlayer();

	const [fallback, setFallback] = useState({ downloading: false });

	const handleDownload = useCallback(async () => {
		try {
			setFallback((prev) => ({ ...prev, downloading: true }));

			const filename = audioSrc
				.replace(env.episodesS3Bucket, '')
				.replace(env.miscS3Bucket, '')
				.replace(env.playlistsS3Bucket, '');

			await download({
				filename,
				name: `${meta?.artist} - ${meta?.name}`,
			});

			setFallback((prev) => ({ ...prev, downloading: false }));
		} catch (error) {
			console.error(error);
		}
	}, [download, audioSrc, meta]);

	useEffect(() => {
		if (!isPlaying) {
			h5PlayerRef.current.audio.pause();
		} else {
			if (audioSrc) {
				h5PlayerRef.current.audio.play();
			}
		}
	}, [isPlaying, audioSrc]);

	return createPortal(
		<Container visibility={visibility}>
			{visibility === 'full' && (
				<Meta>
					<div>
						<p>{meta?.name}</p>
						<span>{meta?.artist}</span>
					</div>
					<Tooltip title='Baixar'>
						<Spin size='small' spinning={fallback?.downloading ? true : false}>
							<FiDownload onClick={handleDownload} />
						</Spin>
					</Tooltip>
				</Meta>
			)}

			<StyledPlayer
				autoPlay
				footer={
					<PlayerHeader visibility={visibility}>
						<HeaderMeta>
							{visibility === 'minimized' && (
								<p>
									<span>Reproduzindo</span> <strong>{meta?.name}</strong> <span>de</span>{' '}
									<strong>{meta?.artist}</strong>
								</p>
							)}
						</HeaderMeta>

						<div className='actions'>
							{visibility === 'full' ? (
								<Tooltip title='Minimizar'>
									<VscChromeMinimize style={{ marginBottom: -6 }} onClick={minimize} />
								</Tooltip>
							) : (
								<Tooltip title='Maximizar'>
									<VscChromeMaximize onClick={maximize} />
								</Tooltip>
							)}

							<Tooltip title='Parar e fechar'>
								<FiX onClick={stop} />
							</Tooltip>
						</div>
					</PlayerHeader>
				}
				src={audioSrc}
				ref={h5PlayerRef}
				onPlay={() => start({ ref })}
				onEnded={stop}
				onPause={resume}
				showLoopControl={false}
				customIcons={{
					volumeMute: (
						<MdVolumeMute
							style={{
								color: 'var(--primary)',
								transform: 'translateY(2px)',
								fontSize: 22,
							}}
						/>
					),
					volume: (
						<MdVolumeUp
							style={{
								color: 'var(--primary)',
								transform: 'translateY(2px)',
								fontSize: 22,
							}}
						/>
					),
					play: <MdPlayCircleFilled style={{ color: 'var(--primary)', fontSize: 35 }} />,
					pause: <MdPauseCircleFilled style={{ color: 'var(--primary)', fontSize: 35 }} />,
					forward: (
						<Tooltip title='Avançar 5 segundos'>
							<MdForward5
								style={{
									color: 'var(--primary)',
									fontSize: 22,
									transform: 'translateY(-4px)',
								}}
							/>
						</Tooltip>
					),
					rewind: (
						<Tooltip title='Voltar 5 segundos'>
							<MdReplay5
								style={{
									color: 'var(--primary)',
									fontSize: 22,
									transform: 'translateY(-4px)',
								}}
							/>
						</Tooltip>
					),
				}}
			/>
		</Container>,
		document.querySelector('#player-root')
	);
};

export default Player;
