import styled from 'styled-components';

export const TabActions = styled.header`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	& > button:last-child {
		margin-left: 8px;
	}
`;

export const Blocks = styled.ul`
	width: 100%;
	border-radius: var(--border-radius-base);
`;

export const Block = styled.li`
	margin: 8px 0;
	border: 1px solid var(--primary);
	border-radius: var(--border-radius-base);

	& > header {
		border-radius: 6px 6px 0 0;
		padding: 8px 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--primary);
		background: var(--primary);

		& > p {
			color: #fff;
			font-size: 16px;
			margin: 0;
		}
	}
`;

export const Categories = styled.ul`
	width: 100%;
`;

export const Category = styled.li`
	padding: 4px 16px;
	border-bottom: 1px solid var(--primary-opacity-25);
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 0.2fr;
	gap: 0 12px;

	& > div {
		display: flex;
		align-items: center;

		&:nth-child(1) {
			justify-content: flex-start;
		}

		&:nth-child(2) {
			justify-content: center;
			font-size: 12px;
			text-transform: uppercase;

			& > span {
				margin-right: 4px;
			}
		}

		&:nth-child(3) {
			justify-content: center;
			border-radius: var(--border-radius-base);
			font-size: 12px;
			text-transform: uppercase;

			& > span {
				margin-right: 4px;
			}

			& strong {
				padding: 2px 4px;
				border-radius: 4px;
			}

			& strong.is-danger {
				background: var(--danger);
				color: #fff;

				& i.icon {
					margin-left: 4px;
				}
			}
		}

		&:nth-child(4) {
			justify-content: flex-end;
		}
	}

	&:nth-child(even) {
		background: #fbf6ff;
	}

	&:nth-child(odd) {
		background: #f8eeff;
	}
`;

export const NewBlock = styled.div`
	width: 100%;
	padding: 6px;

	& > button {
		padding: 12px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border-radius: var(--border-radius-base);
		background: transparent;
		cursor: pointer;
		transition: 150ms;
		border: 1px dashed var(--primary-opacity-85);
		width: 100%;

		& > i {
			font-size: 26px;
			margin-bottom: 2px;
		}

		& > p {
			font-size: 16px;
			font-weight: 500;
			margin: 0;
		}

		& > span {
			font-size: 13px;
			font-weight: 300;
		}

		&:hover {
			color: var(--primary) !important;
			border-color: var(--primary);
			background: var(--primary-opacity-10);
		}
	}
`;

export const NewCategory = styled.div`
	width: 100%;
	padding: 6px;

	& > button:hover {
		background: var(--primary-opacity-10);
	}
`;

export const InactiveDay = styled.div`
	margin: 12px 0;
	padding: 16px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: var(--border-radius-base);
	background: transparent;
	border: 1px solid var(--danger-opacity-85);
	width: 100%;
	background: var(--danger-opacity-10);

	& > i {
		font-size: 26px;
		margin-bottom: 2px;
		color: var(--danger);
	}

	& > p {
		font-size: 16px;
		font-weight: 500;
		margin: 0;
		color: var(--danger);
	}

	& > span {
		font-size: 13px;
		font-weight: 300;
		color: var(--danger);
	}
`;

export const ShareModalContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	gap: 12px 0;

	& > label {
		margin: 0 !important;
	}
`;
