import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Import dos componentes de páginas
import CreatingProgram from '../pages/Tutorials/Programs/CreatingProgram';
import ProgramsManagement from '../pages/Tutorials/Programs/ProgramsManagement';
import OwnProgramDetails from '../pages/Tutorials/Programs/OwnProgramDetails';
import ProgramTabsDetails from '../pages/Tutorials/Programs/ProgramTabsDetails';
import SharedProgramDetails from '../pages/Tutorials/Programs/SharedProgramDetails';
import SignaturesRadio from '../pages/Tutorials/Signatures/SignaturesRadio';
import SignatureMusicas from '../pages/Tutorials/Signatures/SignatureMusicas';
import SignaturesProgram from '../pages/Tutorials/SignaturesProgram';
import Soundtracks from '../pages/Tutorials/Soundtracks';
import MainVoices from '../pages/Tutorials/MainVoices';
import ConfigIA from '../pages/Tutorials/IAVoicer/ConfigIA';
import Casters from '../pages/Tutorials/Casters';
import MTalk from '../pages/Tutorials/BMusic/MTalk';
import MRadio from '../pages/Tutorials/BMusic/MRadio';
import PlaylistsDetails from '../pages/Tutorials/Playlists/PlaylistsDetails';
import PlaylistsManagement from '../pages/Tutorials/Playlists/PlaylistsManagement';
import Programs from '../pages/Tutorials/Programs';

const TutorialsRoutes = () => (
  <>
    <Route exact path='/help'>
      <Redirect to='/help/programs' />
      <Programs />
    </Route>

    <Route exact path='/help/programs/creating-a-program'>
      <CreatingProgram />
    </Route>

    <Route exact path='/help/programs/programs-management'>
      <ProgramsManagement />
    </Route>

    <Route exact path='/help/programs/program-details/own'>
      <OwnProgramDetails />
    </Route>

    <Route exact path='/help/programs/program-details/more-details'>
      <ProgramTabsDetails />
    </Route>
    
    <Route exact path='/help/programs/program-details/shared'>
      <SharedProgramDetails />
    </Route>

    <Route exact path='/help/signatures'>
      <SignaturesRadio />
    </Route>
    <Route exact path='/help/signaturesmusicas-scripts'>
      <SignatureMusicas />
    </Route>

    <Route exact path='/help/soundtracksradio'>
      <SignaturesProgram />
    </Route>

    <Route exact path='/help/soundtracks'>
      <Soundtracks />
    </Route>

    <Route exact path='/help/voices-ia'>
      <ConfigIA/>
    </Route>

    <Route exact path='/help/main-voices'>
      <MainVoices />
    </Route>

    <Route exact path='/help/casters'>
      <Casters />
    </Route>

    <Route exact path='/help/playlists'>
      <PlaylistsDetails />
    </Route>
    <Route exact path='/help/playlists/playlists-scripts'>
      <PlaylistsManagement />
    </Route>

    <Route exact path='/help/bancomusical'>
      <MTalk />
    </Route>
	<Route exact path='/help/bancomusical/bancomusical-scripts'>
  <MRadio />
</Route>

  </>
);

export default TutorialsRoutes;
