import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import {
	PageHeader,
	Table,
	Card,
	Menu,
	Dropdown,
	Tag,
	Button,
	Icon,
	Select,
	Modal,
	Input,
	message,
	Form,
	Checkbox,
	Typography,
	Divider,
	Radio,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import {
	Container,
	TabContainer,
	Actions,
	highlightStyle,
	ExportFormContainer,
	DownloadAncor,
} from './styles';

import UsersAPI from '../../../../services/sdks/user';
import StatisticsAPI from '../../../../services/sdks/statistics';
import ExportsAPI from '../../../../services/sdks/exports';

import { states as brStates } from '../../../../constants';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel do Administrador' },
		{ breadcrumbName: 'Usuários' },
		{ breadcrumbName: 'Usuários ativos' },
	],
	style: { marginBottom: 12 },
};

const ActiveUsers = () => {
	const downloadRef = useRef();

	const [fallback, setFallback] = useState('initial-data');
	const [totalUsers, setTotalUsers] = useState(null);
	const [users, setUsers] = useState([]);
	const [search, setSearch] = useState({
		radioName: '',
		email: '',
		city: '',
		state: undefined,
		withLiveContent: '',
	});
	const [showSearchModal, setShowSearchModal] = useState(false);
	const [showChangePassword, setShowChangePassword] = useState({ show: false, userId: null });
	const [showExportModal, setShowExportModal] = useState(false);
	const [pInput, setPInput] = useState({ p: 'password', p2: 'password' });
	const [passwords, setPasswors] = useState({ password: '', password2: '' });
	const [exportFields, setExportFields] = useState({
		name: { active: false, label: 'Nome' },
		surname: { active: false, label: 'Sobrenome' },
		email: { active: false, label: 'Email' },
		radioName: { active: false, label: 'Nome da radio' },
		city: { active: false, label: 'Cidade' },
		state: { active: false, label: 'Estado' },
		subscriptionId: { active: false, label: 'Pagamento' },
	});

	const columns = [
		{
			title: 'Nome da Rádio',
			key: 'name',
			render: (user) => (
				<Link to={`/admin/users/${user?._id}/details`}>
					<Highlighter
						highlightStyle={highlightStyle}
						searchWords={[search.radioName]}
						textToHighlight={user?.radioName}
					/>
				</Link>
			),
		},
		{
			title: 'Cidade',
			key: 'city',
			render: (user) => (
				<>
					<Highlighter
						highlightStyle={highlightStyle}
						searchWords={[search.city]}
						textToHighlight={user?.city}
					/>
					<span>/</span>
					<Highlighter
						highlightStyle={highlightStyle}
						searchWords={[search.state]}
						textToHighlight={user?.state}
					/>
				</>
			),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (email) => (
				<Highlighter
					highlightStyle={highlightStyle}
					searchWords={[search.email]}
					textToHighlight={email}
				/>
			),
		},
		{
			title: 'Pagamento',
			dataIndex: 'subscriptionId',
			key: 'payment',
			align: 'center',

			render: (subscriptionId) => {
				return subscriptionId ? (
					<TabContainer>
						<Tag color='gold'>Via Cartão</Tag>
					</TabContainer>
				) : (
					<TabContainer>
						<Tag color='green'>Via Boleto</Tag>
					</TabContainer>
				);
			},
		},
		{
			title: 'Conteúdo',
			align: 'center',
			render: (user) => {
				return user?.withLiveContent ? (
					<TabContainer>
						<Tag color='green'>Ao-Vivo</Tag>
					</TabContainer>
				) : (
					<TabContainer>
						<Tag color='gold'>Enlatado</Tag>
					</TabContainer>
				);
			},
		},
		{
			title: 'Ações',
			key: 'actions',
			align: 'center',
			render: (user) => (
				<Dropdown
					overlay={
						<Menu>
							<Menu.Item key='details'>
								<Link style={{ width: '100%' }} to={`/admin/users/${user?._id}/details`}>
									<Icon type='eye' style={{ marginRight: 6 }} />
									Detalhes
								</Link>
							</Menu.Item>
							<Menu.Item key='edit'>
								<Link style={{ width: '100%' }} to={`/admin/users/${user?._id}/edit`}>
									<Icon type='edit' style={{ marginRight: 6 }} />
									Editar
								</Link>
							</Menu.Item>

							<Menu.Item
								key='change-pass'
								onClick={() => {
									setShowChangePassword({ show: true, userId: user?._id });
								}}>
								<Icon type='lock' />
								Alterar senha
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								key='unlock-generations'
								onClick={() => {
									Modal.confirm({
										title: 'Liberar gerações?',
										icon: 'exclamation-circle',
										content: 'Deseja continuar?',
										okText: 'Liberar',
										onOk: () => handleDeleteGenStatistics({ userId: user?._id }),
										okButtonProps: {
											icon: 'unlock',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<Icon type='unlock' />
								Liberar todas as gerações travadas
							</Menu.Item>

							<Menu.Item
								key='delete'
								onClick={() => {
									Modal.confirm({
										title: 'Desativar usuário?',
										icon: 'exclamation-circle',
										content:
											'O usuário não poderá acessar o sistema, deseja continuar mesmo assim?',
										okText: 'Desativar',
										onOk: () => handleInactiveUser(user?._id),
										okButtonProps: {
											icon: 'close-circle',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<Icon type='close-circle' />
								Remover Acesso Temporariamente
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								key='4'
								className='ant-dropdown-menu-item-danger'
								onClick={() => {
									Modal.confirm({
										title: 'Deletar usuário?',
										type: 'danger',
										content:
											'O usuário será excluído permanemtemente e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
										okText: 'Deletar',
										onOk: () => handleDeleteUser(user?._id),
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: { icon: 'close-circle' },
									});
								}}>
								<Icon type='delete' />
								Deletar Conta Permanentemente
							</Menu.Item>
						</Menu>
					}>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 12 }} type='more' />
				</Dropdown>
			),
		},
	];

	const fetchUsers = useCallback(async (search) => {
		try {
			const {
				data: { users, totalUsers },
			} = await UsersAPI.index(`active=true${search}`);

			setTotalUsers(totalUsers);
			setUsers(users);
			setFallback(null);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao buscar os usuários');
		}
	}, []);

	const handleSearchFieldChange = useCallback(({ target: { name: key, value } }) => {
		setSearch((search) => ({ ...search, [key]: value }));
	}, []);

	const handleSearch = useCallback(async () => {
		try {
			setFallback('searching');
			let s = '';

			for (const key in search) {
				const value = search[key];

				if (value) s = s.concat(`&${key}=${value}`);
			}

			await fetchUsers(s);

			setFallback(null);
			setShowSearchModal(false);
		} catch (error) {
			console.error(error);
		}
	}, [search, fetchUsers]);

	const handleResetSearch = useCallback(async () => {
		try {
			setFallback('reseting');
			setSearch({ radioName: '', email: '', city: '', state: undefined });

			await fetchUsers('');

			setFallback(null);
			setShowSearchModal(false);
		} catch (error) {
			console.error(error);
		}
	}, [fetchUsers]);

	const handleDeleteUser = useCallback(async (userId) => {
		try {
			await UsersAPI.destroy(userId);

			setUsers((users) => users.filter(({ _id }) => _id !== userId));

			message.success('Usuário foi excluído com sucesso!');
		} catch (error) {
			console.error(error);
		}
	}, []);

	const handleInactiveUser = useCallback(async (userId) => {
		try {
			await UsersAPI.toggleActive(userId);

			setUsers((users) => users.filter(({ _id }) => _id !== userId));

			message.success(
				'O acesso do usuário foi removido e o mesmo foi movido para a lista de usuários inativos!',
				4
			);
		} catch (error) {
			console.error(error);
		}
	}, []);

	const handleExport = useCallback(async () => {
		try {
			const fields = Object.entries(exportFields)
				.filter(([, { active }]) => active === true)
				.map(([key, { label }]) => {
					return { key, label };
				});

			if (!fields.length) {
				return message.error('Selecione no mínimo um dos campos');
			}

			setFallback('exporting');

			const { data } = await ExportsAPI.post({
				worksheetName: 'usuários ativos',
				data: users.map((user) => ({
					...user,
					subscriptionId: user?.subscriptionId === 0 ? 'Boleto' : 'Cartão',
				})),
				fields,
			});

			const BLOB = new Blob([data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			const downloadLink = window.URL.createObjectURL(BLOB);

			downloadRef.current.href = downloadLink;
			downloadRef.current.download = 'usuários ativos';
			downloadRef.current.click();

			setFallback(null);
		} catch (error) {
			console.error(error);
		}
	}, [users, exportFields]);

	const handleChangePassword = useCallback(async () => {
		try {
			if (!passwords.password) {
				return message.error('Informe a senha');
			}

			if (passwords.password !== passwords.password2) {
				return message.error('Senhas não são iguais');
			}

			setFallback('changing-password');

			await UsersAPI.updatePassword(showChangePassword.userId, {
				password: passwords.password,
				passwordConfirmation: passwords.password2,
			});

			setFallback(null);
			setShowChangePassword({ show: false, userId: null });

			message.success('A senha do usuário foi alterada!');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, [passwords, showChangePassword]);

	const handleDeleteGenStatistics = useCallback(async ({ userId }) => {
		try {
			const query = `type=REAL_TIME_GENERATION&user=${userId}`;
			const {
				data: { statistics },
			} = await StatisticsAPI.index({ query });

			for (let index = 0; index < statistics.length; index++) {
				const statistic = statistics[index];
				await StatisticsAPI.destroy({ statisticId: statistic._id });
			}

			message.success('Gerações de conteúdo foram liberadas');
		} catch (error) {
			message.error('Houve um error');
			console.error(error);
		}
	}, []);

	const handleChangeExportField = useCallback((key, value) => {
		setExportFields((exportFields) => ({
			...exportFields,
			[key]: { ...exportFields[key], active: value },
		}));
	}, []);

	const handleChangeContentType = useCallback((value) => {
		setSearch((search) => ({ ...search, withLiveContent: value }));
	}, []);

	useEffect(() => {
		fetchUsers('');
	}, [fetchUsers]);

	if (fallback === 'initial-data') {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Usuários ativos' />

			<DownloadAncor ref={downloadRef} />

			<PageHeader
				title='Usuários Ativos'
				breadcrumb={breadcrumb}
				extra={[
					<Link key='create-user' to='/admin/users/create-user'>
						<Button icon='plus-circle' type='ghost' size='large'>
							Criar Usuário
						</Button>
					</Link>,
				]}>
				<Typography.Text>Usuário que possuem acesso à plataforma</Typography.Text>
			</PageHeader>

			<Container>
				<Card style={{ marginTop: 16 }}>
					<Table
						columns={columns}
						dataSource={users}
						size='middle'
						pagination={{ size: 'large' }}
						rowKey='_id'
						style={{ border: 'none' }}
						title={() => (
							<Actions>
								<span>
									Total de usuários ativos: <strong>{totalUsers.active}</strong>
								</span>

								<div>
									<Button icon='search' onClick={() => setShowSearchModal(true)}>
										Pesquisar usuários
									</Button>

									<Button
										type='primary'
										icon='file-excel'
										onClick={() => setShowExportModal(true)}
										style={{ marginLeft: 4 }}>
										Baixar Planilha
									</Button>
								</div>
							</Actions>
						)}
					/>
				</Card>
			</Container>

			<Modal
				width={700}
				title='Pesquisar usuários'
				visible={showSearchModal}
				onCancel={() => setShowSearchModal(false)}
				footer={
					<>
						<Button
							onClick={() => setShowSearchModal(false)}
							disabled={fallback === 'searching' || fallback === 'reseting'}>
							Fechar <Icon type='close-circle' />
						</Button>

						<Button
							onClick={handleResetSearch}
							loading={fallback === 'reseting'}
							disabled={fallback === 'searching'}>
							Reiniciar <Icon type='reload' />
						</Button>

						<Button
							type='primary'
							onClick={handleSearch}
							disabled={fallback === 'reseting'}
							loading={fallback === 'searching'}>
							Buscar <Icon type='search' />
						</Button>
					</>
				}>
				<Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					<Form.Item label='Nome da Rádio'>
						<Input
							placeholder='Buscar pelo nome da rádio'
							value={search.radioName}
							name='radioName'
							onChange={handleSearchFieldChange}
						/>
					</Form.Item>

					<Form.Item label='Email'>
						<Input
							placeholder='Buscar por email'
							name='email'
							value={search.email}
							onChange={handleSearchFieldChange}
						/>
					</Form.Item>

					<Form.Item label='Cidade'>
						<Input
							placeholder='Buscar pela cidade'
							name='city'
							value={search.city}
							onChange={handleSearchFieldChange}
						/>
					</Form.Item>

					<Form.Item label='Estado'>
						<Select
							showSearch
							placeholder='Selecione o estado'
							value={search.state}
							onChange={(value) => {
								return handleSearchFieldChange({
									target: { name: 'state', value },
								});
							}}
							filterOption={(input, { props: { children } }) => {
								return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
							}}>
							{brStates.map((state) => (
								<Select.Option key={state.value}>{state.label}</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Conteúdo'>
						<Radio.Group
							value={search?.withLiveContent}
							buttonStyle='solid'
							size='small'
							onChange={(event) => handleChangeContentType(event.target.value)}>
							<Radio.Button value=''>Todos</Radio.Button>
							<Radio.Button value='false'>Somente enlatado</Radio.Button>
							<Radio.Button value='true'>Somente ao-vivo</Radio.Button>
						</Radio.Group>
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				title='Alterar senha do usuário'
				width={600}
				visible={showChangePassword.show}
				onCancel={() => setShowChangePassword({ show: false, userId: null })}
				okText='Alterar senha do usuário'
				onOk={handleChangePassword}
				okButtonProps={{ icon: 'save', loading: fallback === 'changing-password' }}
				cancelButtonProps={{
					icon: 'close-circle',
					disabled: fallback === 'changing-password',
				}}>
				<Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					<Form.Item required label='Senha'>
						<Input
							placeholder='Informe a senha do usuário'
							type={pInput.p}
							suffix={
								pInput.p === 'password' ? (
									<Icon
										type='eye-invisible'
										onClick={() => {
											setPInput({ ...pInput, p: 'text' });
										}}
									/>
								) : (
									<Icon
										type='eye'
										onClick={() => {
											setPInput({ ...pInput, p: 'password' });
										}}
									/>
								)
							}
							value={passwords?.password}
							onChange={({ target: { value } }) => {
								return setPasswors({ ...passwords, password: value });
							}}
						/>
					</Form.Item>

					<Form.Item required label='Confirmar senha'>
						<Input
							placeholder='Confirme a senha'
							type={pInput.p2}
							suffix={
								pInput.p2 === 'password' ? (
									<Icon
										type='eye-invisible'
										onClick={() => {
											setPInput({ ...pInput, p2: 'text' });
										}}
									/>
								) : (
									<Icon
										type='eye'
										onClick={() => {
											setPInput({ ...pInput, p2: 'password' });
										}}
									/>
								)
							}
							value={passwords?.password2}
							onChange={({ target: { value } }) => {
								return setPasswors({ ...passwords, password2: value });
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				title='Baixar planilha'
				visible={showExportModal}
				onCancel={() => setShowExportModal(false)}
				okText='Baixar'
				onOk={handleExport}
				okButtonProps={{ icon: 'download', loading: fallback === 'exporting' }}
				cancelButtonProps={{
					icon: 'close-circle',
					disabled: fallback === 'exporting',
				}}>
				<Form>
					<Typography.Paragraph>
						Selecione os campos que deseja exibir na planilha
					</Typography.Paragraph>

					<Divider />

					<ExportFormContainer>
						<Checkbox
							checked={exportFields.name.active}
							onChange={({ target: { checked } }) => {
								handleChangeExportField('name', checked);
							}}>
							Nome
						</Checkbox>

						<Checkbox
							checked={exportFields.surname.active}
							onChange={({ target: { checked } }) => {
								handleChangeExportField('surname', checked);
							}}>
							Sobrenome
						</Checkbox>

						<Checkbox
							checked={exportFields.email.active}
							onChange={({ target: { checked } }) => {
								handleChangeExportField('email', checked);
							}}>
							Email
						</Checkbox>

						<Checkbox
							checked={exportFields.radioName.active}
							onChange={({ target: { checked } }) => {
								handleChangeExportField('radioName', checked);
							}}>
							Nome da rádio
						</Checkbox>

						<Checkbox
							checked={exportFields.city.active}
							onChange={({ target: { checked } }) => {
								handleChangeExportField('city', checked);
							}}>
							Cidade
						</Checkbox>

						<Checkbox
							checked={exportFields.state.active}
							onChange={({ target: { checked } }) => {
								handleChangeExportField('state', checked);
							}}>
							Estado
						</Checkbox>

						<Checkbox
							checked={exportFields.subscriptionId.active}
							onChange={({ target: { checked } }) => {
								handleChangeExportField('subscriptionId', checked);
							}}>
							Pagamento
						</Checkbox>
					</ExportFormContainer>
				</Form>
			</Modal>
		</>
	);
};

export default ActiveUsers;
