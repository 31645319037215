import React from 'react';
import { Title ,GetStartedWrapper, GetStartedText, PrimaryButton } from './styles';

export default function GetStartedSection() {
	return (
		<GetStartedWrapper>
            			<Title style={{ color: '#7F10EE' }}>Disponibiliza Para Baixa </Title>


			<GetStartedText>
				Entrega o programa pronto para ser executado na Estação de Radio.{' '}
			</GetStartedText>
			<PrimaryButton>Programa_Radio_Bloco_01.mp3</PrimaryButton>
			<p></p>
			<PrimaryButton>Programa_Radio_Bloco_02.mp3</PrimaryButton>
		</GetStartedWrapper>
	);
}
