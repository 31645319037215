import React from 'react';
import { Typography, Divider, Alert } from 'antd';
import { Link } from 'react-router-dom';

import Meta from '../../../components/Meta';
import HelpButtons from '../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../assets/images/tutorials/main-voices/img-01.png';
import img02 from '../../../assets/images/tutorials/main-voices/img-02.png';
import img03 from '../../../assets/images/tutorials/main-voices/img-03.png';

const { Title, Paragraph } = Typography;

const MainVoices = () => (
	<Container>
		<Meta title='Ajuda - Narrações Rede' />

		<Title>Narrações Rede</Title>

		<Paragraph>
			São nada mais, nada menos, que os elementos que possuem as falas do locutor. Existem 5 tipos
			de Narrações Rede:
		</Paragraph>

		<ul>
			<li>
				<i>Offs Padrão;</i>
			</li>
			<li>
				<i>Notícias;</i>
			</li>
			<li>
				<i>Blitz;</i>
			</li>
			<li>
				<i>Wpps;</i>
			</li>
			<li>
				<i>Entrevistas;</i>
			</li>
		</ul>

		<Paragraph>
			Como você deve se lembrar, no tutorial{' '}
			<Link to='/help/programs/program-details/more-details'>Conhecendo Melhor o Programa</Link>{' '}
			você aprendeu sobre alguns tipos de narrações. A diferença entre as <i>Narrações Rede</i> e as
			estudadas previamente é simplesmente sobre quando e onde utlizá-las.
		</Paragraph>

		<Paragraph>
			As narrações rede são as narrações principais dos seus <i>Programas próprios</i> e você{' '}
			<strong>não</strong> pode utilizá-las em <i>Programas compartilhados</i> pois fazem parte do
			escopo principal do programa e não podem ser alteradas por rádios que possuem este como
			compartilhado.
		</Paragraph>

		<Paragraph>
			Para acessar, no menu lateral navegue até <i>Narrações Rede</i> `{`->`}`{' '}
			<i>Tipo de narração desejada</i>.
		</Paragraph>

		<ImgContainer>
			<img src={img01} alt='Narrações Rede' />
		</ImgContainer>

		<Paragraph>
			Todos os tipos possuem o mesmo funcionamento e características, portanto cabe a você organizar
			seu material e decidir como montar seu modelo e quando utlizar cada tipos de narração.
		</Paragraph>

		<Paragraph>
			Para cadastrar novas narrações, clique no botão <i>Adicionar</i>, na parte superior direita da
			página:
		</Paragraph>

		<ImgContainer>
			<img src={img02} alt='Trilhas Sonoras' />
		</ImgContainer>

		<Paragraph>
			Após isso, informe o <i>programa</i>, a <i>data de lançamento</i> e em alguns caso a{' '}
			<i>categoria</i>. Após isso clique em <i>Enviar</i>. Aguarde o processo ser finalizado.
		</Paragraph>

		<ImgContainer>
			<img src={img03} alt='Trilhas Sonoras' />
		</ImgContainer>

		<Alert
			showIcon
			type='warning'
			description='Vale salientar que esses elementos só serão usados na produção de episódios da data de lançamento.'
		/>

		<Divider />

		<HelpButtons
			prev={{ label: 'Trilhas Sonoras', to: '/help/soundtracks' }}
			next={{ label: 'Locutores', to: '/help/casters' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default MainVoices;
