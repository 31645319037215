import api from '../api';

const BASE_URL = '/api/v2/tkvoice-configs';

const TKVoiceConfigAPI = {
	show: () => api.get(`${BASE_URL}`),
	update: (payload) => api.put(`${BASE_URL}`, payload),
	getPopulatedTypes: (programId, userId) => {
		const query = programId && userId ? `?programId=${programId}&userId=${userId}` : '';
		return api.get(`${BASE_URL}/populated-types${query}`);
	},
};

export default TKVoiceConfigAPI;
