import React from 'react';
import {
	Section,
	Container,
	TitleContainer,
	Badge,
	Title,
	Description,
	Grid,
	Card,
	CardIcon,
	CardTitle,
	CardDescription,
} from './styles';
import { ActivityIcon, CodeIcon, GlobeIcon, PencilIcon, SettingsIcon, ShieldIcon } from './icons';

const Component = () => {
	return (
		<Section>
			<div id="features"></div>
			<Container>
				
				<TitleContainer>
					<Badge>Recursos Principais</Badge>
					<Title>Potencialize seus Programas</Title>
					<Description>
						Nossa plataforma de IA produtora oferece recursos avançados para ajudar você a criar, personalizar
						e distribuir como nunca antes.
					</Description>
				</TitleContainer>
				<Grid>
					<Card>
						<CardIcon as={CodeIcon} />
						<CardTitle>Criação Automática de Programas</CardTitle>
						<CardDescription>
							Gerencie os episódios do seu programa de rádio com até 5 horas diárias em menos de 7
							minutos.{' '}
						</CardDescription>
					</Card>
					<Card>
						<CardIcon as={PencilIcon} />
						<CardTitle>Personalização de Conteúdo</CardTitle>
						<CardDescription>
							Acesse modelos pré-definidos para diferentes tipos de programas ou segmentos,
							facilitando a criação e padronização de conteúdo. Crie e gerencie vinhetas
							personalizadas específicas para a sua estação ou programa.{' '}
						</CardDescription>
					</Card>
					<Card>
						<CardIcon as={ActivityIcon} />
						<CardTitle>Publicidades</CardTitle>
						<CardDescription>
							Administre os spots, patrocínios, textemunhais e até a música paga, incluindo a
							inserção desntro dos seus programas.{' '}
						</CardDescription>
					</Card>
					<Card>
						<CardIcon as={GlobeIcon} />
						<CardTitle>Locução Humana Offs</CardTitle>
						<CardDescription>
							Gerencie gravações de offs disponíveis para uso em programas com seus locutores locais
							ou nacionais.
						</CardDescription>
					</Card>
					<Card>
						<CardIcon as={ShieldIcon} />
						<CardTitle>Banco Musical & Playlists</CardTitle>
						<CardDescription>
							Administre seu banco de músicas, incluindo adições, remoções e categorizações. Crie e
							modifique playlists para serem utilizadas em diferentes programas.{' '}
						</CardDescription>
					</Card>
					<Card>
						<CardIcon as={SettingsIcon} />
						<CardTitle>Personalização Avançada</CardTitle>
						<CardDescription>
							Você pode adicionar avatares de voz de IA treinados para lidar com contextos
							específicos dentro do programa. Isso permite uma locução que se adapta ao estilo e
							necessidades do conteúdo do programa.
						</CardDescription>
					</Card>
				</Grid>
			</Container>
		</Section>
	);
};

export default Component;
