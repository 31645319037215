const VignetteExecutionModes = {
	SLOW: 'SLOW',
	MID: 'MID',
	FAST: 'FAST',
};

const VignetteExecutionModesTranslation = {
	SLOW: 'Lenta',
	MID: 'Média',
	FAST: 'Rápida',
};

module.exports = { VignetteExecutionModes, VignetteExecutionModesTranslation };
