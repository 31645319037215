import React, { useCallback, useEffect, useState } from 'react';
import path from 'path';
import JSZip from 'jszip';
import axios from 'axios';
import { saveAs } from 'file-saver';
import {
	Select,
	Spin,
	Button,
	Dropdown,
	Table,
	Menu,
	Divider,
	Modal,
	Icon,
	message,
	Typography,
	Input,
	Breadcrumb,
	Tag,
	Tooltip,
	Alert,
	DatePicker,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import Form from '../../../../components/Form';
import PlayCell from '../../../../components/PlayCell';
import FileExt from '../../../../components/FileExt';
import FileDuration from '../../../../components/FileDuration';
import PageSizeHandler from '../../../../components/PageSizeHandle';
import Progress from '../../../../components/Progress';
import { Container, Infos, TableHeader } from './styles';

import UsersAPI from '../../../../services/sdks/user';
import ShareAPI from '../../../../services/sdks/share';

import ProgramsAPI from '../../../../services/sdks/programs';
import ElementsAPI from '../../../../services/sdks/elements';

import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';
import { useDownload, useElement, useFilesValidator, usePlayer } from '../../../../hooks';
import { FiDownload, FiPlusCircle, FiTrash2, FiUpload, FiXCircle } from 'react-icons/fi';
import FilesUploader from '../../../../components/FilesUploader';

const NEW_ELEMENT = {
	userId: undefined,
	programId: undefined,
	type: undefined,
	category: undefined,
	color: '#F00',
	files: [],
	name: '',
	artist: '',
	expirationDate: null,
};

const ManageElements = () => {
	const download = useDownload();
	const player = usePlayer();
	const { types, categorizedTypes, getElementCategory, parseElementName } = useElement();

	const [fallback, setFallback] = useState({ initialData: true });
	const [visibleModals, setVisibleModals] = useState({});
	const [progress, setProgress] = useState(null);

	const [users, setUsers] = useState([]);
	const [programs, setPrograms] = useState([]);

	const [elements, setElements] = useState([]);
	const [selectedElements, setSelectedElements] = useState([]);
	const [isSharedProgram, setIsSharedProgram] = useState(undefined);
	const [newElement, setNewElement] = useState(NEW_ELEMENT);
	const { hasValidationError } = useFilesValidator();
	const [isValidating, setIsValidating] = useState(false);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [filters, setFilters] = useState({
		userId: undefined,
		program: undefined,
		type: undefined,
	});

	const columns = [
		{
			title: 'Elemento',
			key: 'type',
			render: (element) => {
				const elementName = element?.name
					? element?.name
					: parseElementName({ type: element?.type });

				return (
					<PlayCell
						meta={{ primary: elementName }}
						isPlaying={player?.ref === element?._id && player?.isPlaying}
						onPause={player.resume}
						onPlay={() => {
							player.start({
								src: resolveFileSrc({ fileName: element?.filename }),
								ref: element?._id,
								meta: { name: elementName },
							});
						}}
					/>
				);
			},
		},
		{
			title: 'Programa',
			key: 'program',
			dataIndex: 'program',
			render: (program) => program?.name,
		},
		{
			title: 'Duração',
			align: 'center',
			key: 'duration',
			render: (element) => <FileDuration src={resolveFileSrc({ fileName: element?.filename })} />,
		},
		{
			title: 'Formato',
			align: 'center',
			key: 'ext',
			render: (element) => <FileExt src={resolveFileSrc({ fileName: element?.filename })} />,
		},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					12
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'actions',
			render: (element) => (
				<Dropdown
					placement='bottomRight'
					overlay={
						<Menu>
							<Menu.Item
								onClick={() => {
									player.start({
										src: resolveFileSrc({ fileName: element?.filename }),
										ref: element?._id,
										meta: {
											name: element?.name
												? element?.name
												: parseElementName({ type: element?.type }),
										},
									});
								}}>
								<Icon type='play-circle' /> Reproduzir
							</Menu.Item>
							<Menu.Item onClick={() => handleDownloadElement(element.filename, element.name)}>
								<Icon type='download' /> Baixar arquivo
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								className='ant-dropdown-menu-item-danger'
								onClick={() => {
									Modal.confirm({
										title: 'Deletar elemento?',
										icon: 'exclamation-circle',
										content: 'Essa ação não poderá ser revertida, deseja continuar?',
										onOk: () => handleDeleteElement(element),
										okText: 'Deletar',
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<Icon type='delete' /> Deletar
							</Menu.Item>
						</Menu>
					}>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 12 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleDownloadElement = useCallback(
		async (filename, name) => {
			try {
				const ext = path.extname(filename);

				await download({ filename, name: `${name}${ext}` });
			} catch (error) {
				console.error(error);
			}
		},
		[download]
	);

	const handleDeleteElement = useCallback(async (element) => {
		try {
			await ElementsAPI.destroy({ elementId: element._id });

			return setElements((elements) => elements.filter(({ _id }) => _id !== element._id));
		} catch (error) {
			console.error(error);
		}
	}, []);

	const handleDownloadAsZIP = useCallback(async () => {
		setFallback((prev) => ({ ...prev, multiDownload: true }));

		const zip = new JSZip();

		for (let index = 0; index < selectedElements.length; index++) {
			const elementId = selectedElements[index];
			const element = elements.find((e) => e?._id === elementId);
			const elementName = element?.name ? element?.name : parseElementName({ type: element?.type });
			const filePath = resolveFileSrc({ fileName: element?.filename });
			const blob = await axios.get(filePath, { responseType: 'blob' });
			const ext = path.extname(element?.filename);
			const fileName = `${elementName}${ext}`;

			zip.file(fileName, blob.data, { binary: true });
		}

		const zipContent = await zip.generateAsync({ type: 'blob' });
		const zipName = `ELEMENTOS`;

		saveAs(zipContent, zipName);
		setFallback((prev) => ({ ...prev, multiDownload: false }));
	}, [selectedElements, elements, parseElementName]);

	const handleMultiDelete = useCallback(async () => {
		setFallback((prev) => ({ ...prev, multiDelete: true }));

		for (let index = 0; index < selectedElements.length; index++) {
			const elementId = selectedElements[index];
			const element = elements.find(({ _id }) => _id === elementId);

			await handleDeleteElement(element);
		}

		setSelectedElements([]);
		setFallback((prev) => ({ ...prev, multiDelete: false }));
	}, [elements, selectedElements, handleDeleteElement]);

	const handleCreateElements = useCallback(async () => {
		try {
			if (hasValidationError(newElement.files)) {
				return message.warning(
					'Alguns dos arquivos selecionados estão corrompidos. Por favor, substitua-os por arquivos válidos.'
				);
			}

			const payload = new FormData();

			if (newElement?.type === 'CASHTRACK') {
				if (!newElement?.artist) return message.error('Informe o artista');
				if (!newElement?.name) return message.error('Informe o nome da música');
				if (!newElement?.expirationDate) return message.error('Informe a data');

				payload.append('elementName', `${newElement.artist} - ${newElement.name}`);
				payload.append('expirationDate', newElement.expirationDate);
			} else if (['CUSTOM', 'SPONSOR', 'OFFERING', 'TESTIMONIAL'].includes(newElement?.type)) {
				if (!newElement?.name) {
					return message.error('Informe o nome do elemento');
				}

				payload.append('elementName', newElement?.name);
			}

			if (!newElement?.files.length) return message.error('Informe o(s) arquivo(s)');

			payload.append('userId', newElement?.userId);
			payload.append('programId', newElement?.programId);
			payload.append('type', newElement?.type);
			payload.append('category', getElementCategory({ type: newElement?.type }));
			newElement.files.forEach((file, i) => payload.append(`file-${i}`, file.data));

			setFallback((prev) => ({ ...prev, uploadingElements: true }));

			let newElements = null;

			if (['CUSTOM', 'SPONSOR', 'OFFERING', 'CASHTRACK'].includes(newElement?.type)) {
				newElement?.type !== 'CASHTRACK' && payload.append('color', newElement?.color);

				const {
					data: { element },
				} = await ElementsAPI.custom.store({
					payload,
					onUploadProgress: ({ total, loaded }) => {
						const percentage = Math.floor((loaded * 100) / total);

						setProgress(percentage);
					},
				});

				newElements = [element];
			} else {
				const {
					data: { elements },
				} = await ElementsAPI.store({
					payload,
					onUploadProgress: ({ total, loaded }) => {
						const percentage = Math.floor((loaded * 100) / total);

						setProgress(percentage);
					},
				});

				newElements = elements;
			}

			const selectedProgram = programs.find((p) => p._id === newElement.programId);

			newElements = newElements.map((e) => {
				return { ...e, program: { ...e.program, name: selectedProgram.name } };
			});

			if (newElement?.type === filters?.type) {
				setElements((prev) => [...newElements, ...prev]);
			}

			const { userId, programId } = newElement;

			setNewElement({ ...NEW_ELEMENT, userId, programId });
			setFallback((prev) => ({ ...prev, uploadingElements: false }));

			setTimeout(() => {
				setVisibleModals((prev) => ({ ...prev, addElements: false }));
				setProgress(null);

				return message.success('Elementos criados com sucesso');
			}, 1500);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, [getElementCategory, programs, filters, newElement, hasValidationError]);

	const isThisDisabled = useCallback(
		(type) => {
			if (!isSharedProgram) {
				return false;
			}

			const isLocalOff = categorizedTypes.localOffs.includes(type);
			const isMerchan = categorizedTypes.merchanElements.includes(type);
			const isProgramSignatures = type === 'SIGNATURE-PROGRAM';
			const isTkPlay = type.startsWith('TK-PLAY');

			if (isLocalOff || isMerchan || isProgramSignatures || isTkPlay) {
				return false;
			}

			return true;
		},
		[isSharedProgram, categorizedTypes]
	);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { users },
				} = await UsersAPI.index('active=true');

				setUsers(users);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os usuários');
			}
		};

		fetchInitialData();
	}, []);

	useEffect(() => {
		const fetchElements = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchElements: true }));

				let query = `page=${pagination?.current - 1}&limit=${pagination?.pageSize}`;

				for (const key in filters) {
					if (filters[key]) {
						query = `${query}&${key}=${filters[key]}`;
					}
				}

				const {
					data: { elements, total },
				} = await ElementsAPI.index({ query });

				setElements(elements);
				setPagination((prev) => ({ ...prev, total }));
				setFallback((prev) => ({ ...prev, fetchElements: false }));
			} catch (error) {
				setFallback((prev) => ({ ...prev, fetchElements: false }));

				console.error(error);
			}
		};

		fetchElements();
	}, [filters, pagination.current, pagination.pageSize]); //eslint-disable-line

	useEffect(() => {
		const fetchUserPrograms = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingPrograms: true }));

				const {
					data: { programs },
				} = await ProgramsAPI.index(`userId=${filters?.userId}&isDeleted=false`);

				const {
					data: { sharings },
				} = await ShareAPI.index(`user=${filters?.userId}`);

				setPrograms([
					...programs.map((p) => ({ ...p, userId: p?.userId?._id })),
					...sharings.map(({ program }) => ({ ...program, isShared: true })),
				]);
				setFallback((prev) => ({ ...prev, fetchingPrograms: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os programas');
			}
		};

		if (filters?.userId) {
			fetchUserPrograms();
		}
	}, [filters.userId]); //eslint-disable-line

	useEffect(() => {
		/**
		 * Disparado ao seleciona o programa
		 * Verifica se o programa selecionado é com compartilhado
		 * */

		const selectedProgram = programs.find((p) => p?._id === filters?.program);

		if (!selectedProgram) {
			setIsSharedProgram(false);
		}

		setIsSharedProgram(selectedProgram?.userId !== filters?.userId);
	}, [filters, programs]);

	useEffect(() => {
		/**
		 * Disparado quando alguma filtragem é realizada
		 * Reseta a página atual para 1
		 * */
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Gerenciar elementos' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'PAINEL ADMINISTRATIVO' },
						{ breadcrumbName: 'CONTEÚDOS' },
						{ breadcrumbName: 'GERENCIAR ELEMENTOS' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Gerenciar elementos</Typography.Title>
					{filters?.userId && filters?.program ? (
						<Button
							type='primary'
							onClick={() => setVisibleModals({ ...visibleModals, addElements: true })}>
							<FiPlusCircle /> Adicionar elementos
						</Button>
					) : (
						<Tooltip title='Selecione o usuário e o programa' placement='left'>
							<Button disabled type='primary'>
								<FiPlusCircle /> Adicionar elementos
							</Button>
						</Tooltip>
					)}
				</header>

				<Form.Container layout='30% 30% 30%'>
					<Form.Item label='Filtrar por usuário'>
						<Select
							showSearch
							optionFilterProp='children'
							placeholder='Selecione o usuário'
							value={filters?.userId}
							onChange={(value) => {
								setFilters({ ...filters, program: undefined, userId: value });
								setNewElement({ ...newElement, userId: value });
							}}
							filterOption={(input, { props: { _search } }) => {
								const regex = new RegExp(input, 'i');

								return _search.match(regex);
							}}>
							{users.map((user) => {
								const { radioName, city, state, email } = user;

								return (
									<Select.Option
										key={user?._id}
										value={user?._id}
										_search={`${radioName}${city}${state}${email}`}>
										{radioName} - {city}/{state} ({email})
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por programa'>
						<Spin spinning={fallback?.fetchingPrograms ? true : false} tip='Buscando programas...'>
							<Select
								showSearch
								disabled={!filters?.userId}
								optionFilterProp='children'
								placeholder='Selecione o programa'
								value={filters?.program}
								onChange={(value) => {
									setFilters({ ...filters, program: value });
									setNewElement({ ...newElement, programId: value });
								}}
								filterOption={(input, { props: { _search } }) => {
									const regex = new RegExp(input, 'i');

									return _search.match(regex);
								}}>
								{programs.map((program) => (
									<Select.Option _search={program?.name} key={program._id} value={program?._id}>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
											}}>
											{program.name}{' '}
											{program?.isShared && (
												<Tag style={{ marginLeft: 4 }} color='gold'>
													Compartilhado
												</Tag>
											)}
										</div>
									</Select.Option>
								))}
							</Select>
						</Spin>
					</Form.Item>

					<Form.Item label='Filtrar por tipo'>
						<Select
							showSearch
							placeholder='Selecione um tipo'
							value={filters?.type}
							onChange={(value) => setFilters({ ...filters, type: value })}
							filterOption={(input, { props: { _search } }) => {
								const regex = new RegExp(input, 'i');

								return _search.match(regex);
							}}>
							{types
								.filter((type) => type !== 'NEW-BLOCK')
								.map((type) => (
									<Select.Option
										value={type}
										key={type}
										disabled={isThisDisabled(type)}
										_search={parseElementName({ type })}>
										{parseElementName({ type })}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
				</Form.Container>

				<Divider />

				<TableHeader>
					<div className='actions'>
						<span>
							Quantidade: <strong>{pagination?.total}</strong>
						</span>
						<div>
							<Button
								size='small'
								disabled={!selectedElements.length}
								type='ghost'
								onClick={handleDownloadAsZIP}
								loading={fallback?.multiDownload}>
								<FiDownload /> Baixar selecionados{' '}
								{selectedElements.length !== 0 && `(${selectedElements.length})`}
							</Button>

							<Button
								size='small'
								disabled={!selectedElements.length}
								type='danger'
								onClick={() => {
									Modal.confirm({
										title: 'Deletar elementos selecionados?',
										type: 'danger',
										content:
											'Todos os elementos selecionados serão excluídos e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
										okText: 'Deletar',
										onOk: handleMultiDelete,
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<FiTrash2 /> Deletar selecionados{' '}
								{selectedElements.length !== 0 && `(${selectedElements.length})`}
							</Button>
						</div>
					</div>

					<PageSizeHandler pagination={pagination} setPagination={setPagination} />
				</TableHeader>

				<Table
					rowKey='_id'
					size='middle'
					columns={columns}
					dataSource={elements}
					loading={fallback?.fetchElements}
					style={{ border: 'none' }}
					pagination={{
						...pagination,
						size: 'large',
						onChange: (current) => setPagination({ ...pagination, current }),
					}}
					rowSelection={{
						selectedRowKeys: selectedElements,
						onChange: (_, selectedRows) => {
							setSelectedElements(selectedRows.map(({ _id }) => _id));
						},
					}}
				/>
			</Container>

			<Modal
				destroyOnClose
				title={
					<>
						<Icon style={{ marginRight: 8 }} type='plus-circle' /> Novo elemento
					</>
				}
				visible={visibleModals?.addElements}
				okText={
					isValidating ? (
						'Validando Arquivos'
					) : (
						<>
							<FiUpload /> Fazer upload
						</>
					)
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				okButtonProps={{ loading: fallback?.uploadingElements, disabled: isValidating }}
				cancelButtonProps={{ disabled: fallback?.uploadingElements }}
				onCancel={() => {
					setNewElement(NEW_ELEMENT);
					setVisibleModals({ ...visibleModals, addElements: false });
				}}
				onOk={handleCreateElements}>
				<Infos>
					<li>
						<span>Usuário selecionado</span>
						<p>{users.find((user) => user?._id === filters?.userId)?.radioName}</p>
					</li>
					<li>
						<span>Programa selecionado</span>
						<p>{programs.find((program) => program?._id === filters?.program)?.name}</p>
					</li>
				</Infos>

				{isSharedProgram && (
					<Alert
						showIcon
						closable
						type='warning'
						style={{ marginBottom: 16 }}
						description='O programa selecionado é compartilhado, somente elementos locais podem ser enviados'
					/>
				)}

				<Form.Container>
					<Form.Item label='Tipo do elemento' style={{ marginBottom: 12, width: '100%' }}>
						<Select
							showSearch
							placeholder='Selecione um tipo'
							value={newElement?.type}
							onChange={(value) => setNewElement({ ...newElement, type: value })}
							filterOption={(input, { props: { _search } }) => {
								const regex = new RegExp(input, 'i');

								return _search.match(regex);
							}}>
							{types
								.filter((type) => type !== 'NEW-BLOCK')
								.map((type) => (
									<Select.Option
										value={type}
										key={type}
										disabled={isThisDisabled(type)}
										_search={parseElementName({ type })}>
										{parseElementName({ type })}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
				</Form.Container>

				{newElement?.type === 'CASHTRACK' && (
					<>
						<Form.Item label='Artista' style={{ marginBottom: 12, width: '100%' }}>
							<Input
								value={newElement?.artist}
								onChange={({ target: { value } }) =>
									setNewElement({ ...newElement, artist: value })
								}
								placeholder='Informe o nome do artisa'
							/>
						</Form.Item>

						<Form.Item label='Música' style={{ marginBottom: 12, width: '100%' }}>
							<Input
								value={newElement?.name}
								onChange={({ target: { value } }) => setNewElement({ ...newElement, name: value })}
								placeholder='Informe o nome da música'
							/>
						</Form.Item>

						<Form.Item label='Data de expiração' style={{ marginBottom: 12, width: '100%' }}>
							<DatePicker
								value={newElement?.expirationDate}
								format='DD/MM/YYYY'
								style={{ marginBottom: 8, width: '100%' }}
								placeholder='Informe até quando a música deve tocar'
								onChange={(expirationDate) => setNewElement({ ...newElement, expirationDate })}
							/>
						</Form.Item>
					</>
				)}

				{['CUSTOM', 'SPONSOR', 'OFFERING', 'TESTIMONIAL'].includes(newElement?.type) && (
					<Form.Item label='Nome do elemento' style={{ marginBottom: 12, width: '100%' }}>
						<Input
							value={newElement?.name}
							onChange={({ target: { value } }) => setNewElement({ ...newElement, name: value })}
							placeholder='Informe o nome do elemento'
						/>
					</Form.Item>
				)}

				{['CUSTOM', 'SPONSOR', 'OFFERING'].includes(newElement?.type) && (
					<Form.Item label='Cor do elemento' style={{ marginBottom: 12, width: '100%' }}>
						<Input
							value={newElement?.color}
							onChange={({ target: { value } }) => setNewElement({ ...newElement, color: value })}
							type='color'
						/>
					</Form.Item>
				)}

				<FilesUploader
					multiple={
						['CUSTOM', 'SPONSOR', 'OFFERING', 'TESTIMONIAL'].includes(newElement?.type)
							? false
							: true
					}
					onChange={(files) => setNewElement((prev) => ({ ...prev, files }))}
					onStartValidation={() => setIsValidating(true)}
					onEndValidation={() => setIsValidating(false)}
					validTypes={['.mp3', '.wav']}
				/>
			</Modal>

			<Progress
				progress={progress}
				succesTitle='Elementos enviados com sucesso'
				title={
					<Typography.Paragraph>Enviando elementos, por favor aguarde...</Typography.Paragraph>
				}
			/>
		</>
	);
};

export default ManageElements;
