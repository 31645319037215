import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-height: 10vh;
	background-color: #000;
	color: #fff;
	padding: 1rem;
`;

export const Header = styled.header`
	text-align: center;
	margin-bottom: 2rem;
`;

export const Title = styled.h1`
	font-size: 3rem;
	font-weight: 700;
	color: #CFCFCF;
`;

export const Main = styled.main`
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	width: 100%;
	max-width: 1200px;

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

export const Footer = styled.footer`
	text-align: center;
	margin-top: 2rem;
`;

export const Button = styled.button`
	padding: 1rem 2rem;
	border: none;
	border-radius: 9px;
	background-color: #102B9C;
	color: white;
	font-size: 1rem;
	font-weight: bold;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;

	&:hover {
		background-color: #e91e63;
	}
`;

export const Section = styled.div`
	text-align: center;
`;

export const SectionTitle = styled.h2`
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 1rem;
    color: #102B9C;
`;

export const Badge = styled.span`
	display: inline-block;
	padding: 0.4rem 1rem;
	border-radius: 9999px;
	background-color: ${(props) => props.color || '#333'};
	color: white;
	font-size: 0.875rem;
	font-weight: bold;
`;

export const Text = styled.p`
	font-size: 1rem;
	color: #82828A;
`;
