import React from 'react';
import {
	GetStartedText,
	Section,
	Title,
	ListItem,
	GetStartedTitle,
	SecundaryButton,
	TercearyButton,
	QuartryButton,
	QuintryButton,
	GetStartedWrapper,
} from './styles';
import { MicIcon } from './icons';

export default function CreatePromptSection() {
	return (
		<Section>
			<Title style={{ color: '#7F10EE' }}>2. Criar Prompt</Title>
			<ul>
            <GetStartedTitle>CONFIGURAÇÃO</GetStartedTitle>
				<GetStartedText>
					<ListItem>
						Nesta fase, você ensina ao algoritmo como deseja que seu programa seja produzido,
						definindo a sequência e ordem dos elementos:{' '}
					</ListItem>
				</GetStartedText>
				<GetStartedWrapper>
					<ListItem>
						<SecundaryButton>Vinheta Abre Hora</SecundaryButton>
						<p></p>
						<TercearyButton>
							{' '}
							Locutor Local <p></p>Locutor Nacional <p></p> Avatar IA <p></p> TTS{' '}
						</TercearyButton>
						<p></p>
						<QuartryButton> Música </QuartryButton>
						<p></p>
						<QuintryButton> Vinheta Pass </QuintryButton>
					</ListItem>
				</GetStartedWrapper>
				<ListItem>
					<GetStartedText>
						O algorituimo pode se comportar para criar o programa tendo 3 tipos de entrada de voz
						sendo:{' '}
					</GetStartedText>
				</ListItem>
				<GetStartedText>
					<ListItem>
						<MicIcon className='w-5 h-5 mr-2 inline-block' />
						TTS (Texto para Fala): Inovação que utiliza Chat GPT + ElevenLabs para gerar áudio a
						partir de texto.{' '}
					</ListItem>
					<ListItem>
						<MicIcon className='w-5 h-5 mr-2 inline-block' />
						Locutor de IA Avatar: Algoritmo treinado para falar na rádio.{' '}
					</ListItem>
					<ListItem>
						<MicIcon className='w-5 h-5 mr-2 inline-block' />
						Locutor Local ou Nacional: A rádio usa a gravação de voz de locutores humanos.
					</ListItem>
				</GetStartedText>
			</ul>
		</Section>
	);
}
