import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
	PageHeader,
	Table,
	Card,
	message,
	Typography,
	Tag,
	Dropdown,
	Menu,
	Icon,
	DatePicker,
	Tooltip,
} from 'antd';

import Meta from '../../../components/Meta';
import { Container, FiltersContainer } from './styles';

import { format } from 'date-fns';
import GenerationRequestsApi from '../../../services/sdks/generationRequests';
import Form from '../../../components/Form';
import moment from 'moment';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel do Administrador' },
		{ breadcrumbName: 'Requisições de Geração' },
	],
	style: { marginBottom: 12 },
};

const GenerationRequests = ({ user }) => {
	const initialDate = format(new Date(), 'dd/MM/yyyy');

	const [fallback, setFallback] = useState({ initialData: true });
	const [requests, setRequests] = useState([]);
	const [filters, setFilters] = useState({
		date: initialDate,
	});

	const columns = [
		{
			key: 'id',
			title: 'ID',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					5
				)}...`}</Typography.Text>
			),
		},
		{
			key: 'user',
			title: 'Usuário',
			render: (data) => (
				<strong>
					{data.generationRecord.user?.radioName} -{' '}
					<span>
						{data.generationRecord.user?.city}/<span>{data.generationRecord.user?.state}</span>
					</span>
				</strong>
			),
		},
		{
			key: 'date',
			dataIndex: 'date',
			title: 'Data da Geração',
		},
		{
			key: 'reason',
			dataIndex: 'reason',
			title: 'Motivo',
			align: 'center',
			render: (reason) => (
				<Tooltip title={reason}>
					<span>{reason.slice(0, 25)}...</span>
				</Tooltip>
			),
		},
		{
			key: 'createdAt',
			dataIndex: 'createdAt',
			title: 'Data da Requisição',
			render: (createdAt) => format(new Date(createdAt), 'dd/MM/yyyy HH:mm'),
		},
		{
			key: 'status',
			dataIndex: 'status',
			title: 'Status',
			render: (status) => {
				return status === 'WAITING-REVIEW' ? (
					<Tag color='gold'>Pendente</Tag>
				) : status === 'APPROVED' ? (
					<Tag color='green'>Aprovada</Tag>
				) : (
					<Tag color='red'>Rejeitada</Tag>
				);
			},
		},
		{
			title: 'Ações',
			key: 'actions',
			align: 'center',
			render: (request) => (
				<Dropdown
					disabled={request.status !== 'WAITING-REVIEW'}
					overlay={
						<Menu>
							{request.status === 'WAITING-REVIEW' && (
								<Menu.Item
									key='success'
									onClick={() => handleUpdateStatus('APPROVED', request._id)}
								>
									<Icon type='check-circle' style={{ marginRight: 6 }} />
									Aprovar Solicitação
								</Menu.Item>
							)}
							{request.status === 'WAITING-REVIEW' && (
								<Menu.Item key='reject' onClick={() => handleUpdateStatus('REJECTED', request._id)}>
									<Icon type='close-circle' style={{ marginRight: 6 }} />
									Recusar Solicitação
								</Menu.Item>
							)}
						</Menu>
					}
				>
					<Icon
						style={{
							fontSize: 20,
							marginRight: 12,
							opacity: request.status === 'WAITING-REVIEW' ? 1 : 0.6,
							cursor: request.status === 'WAITING-REVIEW' ? 'pointer' : 'default',
						}}
						type='more'
					/>
				</Dropdown>
			),
		},
	];

	const handleUpdateStatus = useCallback(async (status, id) => {
		try {
			await GenerationRequestsApi.update({ status }, id);

			setRequests((requests) =>
				requests.map((request) => (request._id === id ? { ...request, status } : request))
			);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, []);

	useEffect(() => {
		const fetchRequests = async () => {
			setFallback((fallback) => ({ ...fallback, fetchingRequets: true }));

			try {
				const query = `date=${filters.date}`;
				const {
					data: { generationRequests },
				} = await GenerationRequestsApi.index(query);

				setRequests(generationRequests);
			} catch (error) {
				console.error(error);
			}

			setFallback((fallback) => ({ ...fallback, fetchingRequets: false }));
		};

		fetchRequests();
	}, [filters]);

	return (
		<>
			<Meta title='Requisições de Geração' />

			<PageHeader title='Requisições de Geração' breadcrumb={breadcrumb} />

			<Container>
				<Card style={{ marginTop: 16 }}>
					<FiltersContainer>
						<Form.Item label='Data da Geração' style={{ width: 350 }}>
							<DatePicker
								style={{ width: 350 }}
								value={moment(filters.date, 'DD/MM/yyyy')}
								format='DD/MM/YYYY'
								placeholder='Filtrar por data de geração'
								onChange={(_, date) => setFilters({ ...filters, date })}
							/>
						</Form.Item>
					</FiltersContainer>

					<Table
						loading={fallback.fetchingRequets}
						columns={columns}
						dataSource={requests}
						size='middle'
						pagination={{ size: 'large' }}
						rowKey='_id'
						style={{ border: 'none' }}
					/>
				</Card>
			</Container>
		</>
	);
};

export default connect(({ user }) => ({ user }))(GenerationRequests);
