import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Card, Divider, message, Button, Modal, Input } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import Meta from '../../../../../components/Meta';
import Fallback from '../../../../../components/Fallback';
import { ButtonContainer, Container, ScriptConfigContainer, InputContainer } from './styles';

import PlaylistScriptTemplatesAPI from '../../../../../services/sdks/playlistScriptTemplates';
import CategoriesAPI from '../../../../../services/sdks/categories';
import PlaylistScriptTabs from '../../../../../components/PlaylistScriptTabs';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel Administrativo' },
		{ breadcrumbName: 'Templates' },
		{ breadcrumbName: 'Templates de Playlists' },
		{ breadcrumbName: 'Atualizar Template de Playlist' },
	],
	style: { marginBottom: 12 },
};

const initialWeek = {
	seg: { key: 'seg', title: 'Segunda-Feira', active: undefined, blocks: [] },
	ter: { key: 'ter', title: 'Terça-Feira', active: undefined, blocks: [] },
	qua: { key: 'qua', title: 'Quarta-Feira', active: undefined, blocks: [] },
	qui: { key: 'qui', title: 'Quinta-Feira', active: undefined, blocks: [] },
	sex: { key: 'sex', title: 'Sexta-Feira', active: undefined, blocks: [] },
	sab: { key: 'sab', title: 'Sábado', active: undefined, blocks: [] },
	dom: { key: 'dom', title: 'Domingo', active: undefined, blocks: [] },
};

const UpdatePlaylistScriptTemplate = ({ user }) => {
	const history = useHistory();
	const { templateId } = useParams();
	const [fallback, setFallback] = useState({ initialData: true });
	const [categories, setCategories] = useState([]);
	const [sharingDayScript, setSharingDayScript] = useState(null);
	const [selectedDaysToShare, setSelectedDaysToShare] = useState([]);
	const [week, setWeek] = useState(initialWeek);
	const [name, setName] = useState('');

	const handleToggleDayActive = useCallback((day, active) => {
		setWeek((prev) => ({ ...prev, [day]: { ...prev[day], active } }));
	}, []);

	const handleAddBlock = useCallback((day) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: [...prev[day].blocks, []],
			},
		}));
	}, []);

	const handleRemoveBlock = useCallback((day, blockIndex) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.filter((_, i) => i !== blockIndex),
			},
		}));

		message.success(`O bloco #${blockIndex + 1} foi removido`);
	}, []);

	const handleClearBlock = useCallback((day, blockIndex) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.map((block, i) => (i === blockIndex ? [] : block)),
			},
		}));

		message.success(`As categorias do bloco #${blockIndex + 1} foram removidas`);
	}, []);

	const handleCloneBlock = useCallback(
		(day, blockIndex) => {
			const dayBlocks = week[day].blocks;
			const clone = dayBlocks[blockIndex];

			dayBlocks.splice(blockIndex + 1, 0, clone);

			setWeek((prev) => ({
				...prev,
				[day]: {
					...prev[day],
					blocks: dayBlocks,
				},
			}));

			message.success(`O bloco #${blockIndex + 1} foi cloando com sucesso`);
		},
		[week]
	);

	const handleAddCategories = useCallback((day, blockIndex, category, inserts) => {
		const newCategories = Array.from({ length: inserts }).fill(category);

		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.map((block, i) => {
					return i === blockIndex ? [...block, ...newCategories] : block;
				}),
			},
		}));

		message.success(`Aa categorias foram inseridas no bloco`);
	}, []);

	const handleReplaceCategory = useCallback((day, blockIndex, newCategory, categoryIndex) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.map((block, bI) => {
					return blockIndex === bI
						? block.map((category, i) => (i === categoryIndex ? newCategory : category))
						: block;
				}),
			},
		}));

		message.success(`A categoria foi substiruída`);
	}, []);

	const handleRemoveCategory = useCallback((day, blockIndex, categoryIndex) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.map((block, bI) => {
					return blockIndex === bI ? block.filter((_, i) => i !== categoryIndex) : block;
				}),
			},
		}));

		message.success(`A categoria foi removida`);
	}, []);

	const handleClearDay = useCallback((day) => {
		setWeek((prev) => ({
			...prev,
			[day]: { ...prev[day], blocks: [] },
		}));

		message.success(`A grade do dia foi limpa`);
	}, []);

	const handleShareDay = useCallback(() => {
		const { active, blocks } = sharingDayScript;

		selectedDaysToShare.forEach((targetDay) => {
			setWeek((prev) => ({
				...prev,
				[targetDay]: { ...week[targetDay], active, blocks },
			}));
		});
	}, [sharingDayScript, selectedDaysToShare, week]);

	const calculateCategoryCount = useCallback(
		(categoryId) => {
			if (!categoryId) {
				return 'Categoria removida';
			}

			const category = categories.find(({ _id }) => _id === categoryId);
			const dbTracks = category.tracksCount;
			let scriptTracks = 0;

			/** Sem essa função, o eslint vai exibir um warning */
			const incrementScriptTracks = () => (scriptTracks += 1);

			for (const key in week) {
				if (Object.hasOwnProperty.call(week, key)) {
					const day = week[key];

					day.blocks.forEach((block) => {
						block.forEach(({ id }) => {
							if (id === categoryId) {
								incrementScriptTracks();
							}
						});
					});
				}
			}

			return { dbTracks, scriptTracks, isDanger: scriptTracks >= dbTracks };
		},
		[week, categories]
	);

	const handleUpdateTemplate = useCallback(
		async (ignoreDanger = false) => {
			try {
				let categoriesCount = 0;
				let isDanger = undefined;

				const modifyDangerousStatus = () => (isDanger = true);
				const incrementCategoriesCount = (increment) => (categoriesCount += increment);
				const data = { name };

				for (const key in week) {
					if (Object.hasOwnProperty.call(week, key)) {
						const day = week[key];

						if (day?.active) {
							data[key] = day.blocks.filter((block) => block.length >= 1);
							day.blocks.forEach((block) => {
								incrementCategoriesCount(block?.length);

								block.forEach((category) => {
									if (calculateCategoryCount(category?.id).isDanger) {
										modifyDangerousStatus();
									}
								});
							});
						} else {
							data[key] = 'unactive';
						}
					}
				}

				if (categoriesCount === 0) {
					return message.warn('Não é possível criar um modelo sem categorias');
				}

				setFallback((prev) => ({ ...prev, creating: true }));

				if (isDanger && !ignoreDanger) {
					return Modal.confirm({
						title: `Atenção`,
						icon: 'exclamation-circle',
						width: 500,
						content: (
							<>
								Algumas categorias no template excedem a quandidade de músicas no banco músical.
								<br />
								Criar um modelo nessas condições, pode ocasionar em longos períodos de espera
								durante a geração das playlists e em repetições de algumas músicas durante a semana.
								<br />É aconselhado não prosseguir e reorganizar seu modelo.
							</>
						),
						okText: 'Continuar mesmo assim',
						onOk: () => handleUpdateTemplate(true),
						okButtonProps: {
							icon: 'exclamation-circle',
						},
						cancelText: 'Cancelar',
						cancelButtonProps: {
							icon: 'close-circle',
						},
					});
				}

				await PlaylistScriptTemplatesAPI.update(templateId, data);

				message.success('Template atualizado com sucesso', 3, () =>
					history.push(`/admin/templates/playlist-script-templates`)
				);
			} catch (error) {
				setFallback((prev) => ({ ...prev, creating: false }));
				console.error(error);
				message.error('Houve um erro, tente novamente');
			}
		},
		[name, week, calculateCategoryCount, history, templateId]
	);

	const initWeekAndName = useCallback((template) => {
		const _week = {
			seg: { key: 'seg', title: 'Segunda-Feira' },
			ter: { key: 'ter', title: 'Terça-Feira' },
			qua: { key: 'qua', title: 'Quarta-Feira' },
			qui: { key: 'qui', title: 'Quinta-Feira' },
			sex: { key: 'sex', title: 'Sexta-Feira' },
			sab: { key: 'sab', title: 'Sábado' },
			dom: { key: 'dom', title: 'Domingo' },
		};

		for (const key in template.week) {
			if (Object.hasOwnProperty.call(template.week, key)) {
				const day = template.week[key];
				const blocks = [];

				if (day[0] === 'unactive') {
					_week[key] = { ..._week[key], active: false, blocks };
				} else {
					day.forEach(({ value: block }) => {
						blocks.push(block);
					});

					_week[key] = { ..._week[key], active: true, blocks };
				}
			}
		}

		setName(template.name);
		setWeek(_week);
	}, []);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const [
					{
						data: { template },
					},
					{
						data: { categories },
					},
				] = await Promise.all([
					PlaylistScriptTemplatesAPI.getOne(templateId),
					CategoriesAPI.index({ 'count-tracks': 'true' }),
				]);

				initWeekAndName(template);
				setCategories(
					categories.sort((x, y) => {
						return x?.name?.toUpperCase() >= y?.name.toUpperCase() ? 1 : -1;
					})
				);

				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os dados');
			}
		};

		fetchInitialData();
	}, [initWeekAndName, templateId, user]);

	if (fallback.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Atualizar Template de Playlist' />

			<PageHeader title='Atualizar Template de Playlist' breadcrumb={breadcrumb} />

			<Container>
				<Card>
					<InputContainer>
						<Input
							placeholder='Nome do template'
							value={name}
							size='large'
							onChange={(e) => setName(e.target.value)}
						/>
					</InputContainer>

					<Divider />

					<ScriptConfigContainer>
						<PlaylistScriptTabs
							week={week}
							categories={categories}
							actions={{
								handleToggleDayActive,
								handleAddBlock,
								handleRemoveBlock,
								handleClearBlock,
								handleCloneBlock,
								handleAddCategories,
								handleReplaceCategory,
								handleRemoveCategory,
								handleClearDay,
								handleShareDay,
								calculateCategoryCount,
							}}
							share={{
								sharingDayScript,
								setSharingDayScript,
								selectedDaysToShare,
								setSelectedDaysToShare,
							}}
						/>

						<Divider />

						<ButtonContainer>
							<Button
								type='primary'
								disabled={!name}
								onClick={() => handleUpdateTemplate(false)}
								icon='check-circle'
								loading={fallback?.creating}
								size='large'>
								Salvar Template
							</Button>
						</ButtonContainer>
					</ScriptConfigContainer>
				</Card>
			</Container>
		</>
	);
};

export default connect(({ user }) => ({ user }))(UpdatePlaylistScriptTemplate);
