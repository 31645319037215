const RadioVignetteTypes = {
	RADIO_VIGNETTE_BLOCK_INTRO: 'RADIO-VIGNETTE-BLOCK-INTRO',
	RADIO_VIGNETTE_BLOCK_OUTRO: 'RADIO-VIGNETTE-BLOCK-OUTRO',
	RADIO_VIGNETTE_BLOCK_COMEBACK_SHORT: 'RADIO-VIGNETTE-BLOCK-COMEBACK-SHORT',
	RADIO_VIGNETTE_BLOCK_COMEBACK_MID: 'RADIO-VIGNETTE-BLOCK-COMEBACK-MID',
	RADIO_VIGNETTE_BLOCK_COMEBACK_LONG: 'RADIO-VIGNETTE-BLOCK-COMEBACK-LONG',
	RADIO_VIGNETTE_TIME_CHECK: 'RADIO-VIGNETTE-TIME-CHECK',
	RADIO_VIGNETTE_PASS: 'RADIO-VIGNETTE-PASS',
	RADIO_VIGNETTE_BRIDGE: 'RADIO-VIGNETTE-BRIDGE',
};

const RadioVignetteTypesTranslation = {
	'RADIO-VIGNETTE-BLOCK-INTRO': 'ENTRADA DE BLOCO',
	'RADIO-VIGNETTE-BLOCK-OUTRO': 'SAÍDA DE BLOCO',
	'RADIO-VIGNETTE-BLOCK-COMEBACK-SHORT': 'VOLTA DE BLOCO CURTA',
	'RADIO-VIGNETTE-BLOCK-COMEBACK-MID': 'VOLTA DE BLOCO MÉDIA',
	'RADIO-VIGNETTE-BLOCK-COMEBACK-LONG': 'VOLTA DE BLOCO LONGA',
	'RADIO-VIGNETTE-TIME-CHECK': 'ABRE HORA',
	'RADIO-VIGNETTE-PASS': 'PASSAGEM',
	'RADIO-VIGNETTE-BRIDGE': 'PONTE',
};

const RadioVignetteTypesColors = {
	'RADIO-VIGNETTE-BLOCK-INTRO': '#FF0000',
	'RADIO-VIGNETTE-BLOCK-OUTRO': '#FF3300',
	'RADIO-VIGNETTE-BLOCK-COMEBACK-SHORT': '#FF6600',
	'RADIO-VIGNETTE-BLOCK-COMEBACK-MID': '#FF9900',
	'RADIO-VIGNETTE-BLOCK-COMEBACK-LONG': '#FFCC00',
	'RADIO-VIGNETTE-TIME-CHECK': '#e1b400',
	'RADIO-VIGNETTE-PASS': '#b59100',
	'RADIO-VIGNETTE-BRIDGE': '#917400',
};

module.exports = { RadioVignetteTypes, RadioVignetteTypesTranslation, RadioVignetteTypesColors };
