import styled from 'styled-components';

export const Footer = styled.footer`
	
	background-color: black;
`;

export const Container = styled.div`
	max-width: 1280px;
	margin: 0 auto;
	padding: 3rem 1rem;
	background-color: black;
	@media (min-width: 768px) {
		padding: 4rem 1rem;
	}

	@media (min-width: 1024px) {
		padding: 5rem 1rem;
	}
`;

export const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2rem;

	@media (min-width: 768px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (min-width: 1024px) {
		grid-template-columns: repeat(5, 1fr);
	}
`;

export const Column = styled.div``;

export const Heading = styled.h3`
	margin-bottom: 1.5rem;
	font-size: 0.875rem;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	color: #9ca3af;
`;

export const LinkItem = styled.a`
	display: block;
	margin-bottom: 0.75rem;
	font-size: 0.875rem;
	font-weight: 500;
	color: #d1d5db;
	transition: color 0.3s;

	&:hover {
		color: #ffffff;
	}
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const EmailInput = styled.input`
	width: 100%;
	border-radius: 0.375rem;
	background-color: #374151;
	padding: 0.5rem 1rem;
	font-size: 0.875rem;
	color: #d1d5db;
	transition: background-color 0.3s;

	&:focus {
		outline: none;
		ring: 2px solid #4b5563;
	}
`;

export const SubscribeButton = styled.button`
	width: 100%;
	border-radius: 0.375rem;
	background-color: #4f46e5;
	padding: 0.5rem 1rem;
	font-size: 0.875rem;
	font-weight: 500;
	color: #ffffff;
	transition: background-color 0.3s;

	&:hover {
		background-color: #4338ca;
	}
`;

export const Border = styled.div`
	margin-top: 3rem;
	border-top: 1px solid #374151;
	padding-top: 2rem;
`;

export const GridItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	@media (min-width: 768px) {
		justify-content: flex-start;
	}
`;

export const Brand = styled.div`
	display: flex;
	align-items: center;
`;

export const Copy = styled.span`
	font-size: 0.875rem;
	color: #9ca3af;
`;

export const SocialLinks = styled.div`
	display: flex;
	gap: 1rem;
`;

export const SocialLink = styled.a`
	color: #9ca3af;
	transition: color 0.3s;

	&:hover {
		color: #ffffff;
	}
`;
