import api from '../api';

const BASE_URL = '/api/v2/categories';

const CategoriesAPI = {
	index: (headers) => api.get(BASE_URL, { headers }),
	show: (categoryId) => api.get(`${BASE_URL}/${categoryId}`),
	post: (payload) => api.post(BASE_URL, payload),
	update: (categoryId, payload) => api.put(`${BASE_URL}/${categoryId}`, payload),
	delete: (categoryId) => api.delete(`${BASE_URL}/${categoryId}`),
};

export default CategoriesAPI;
