import styled from 'styled-components';
import Color from 'color-js';

export const Container = styled.div`
	width: 100%;
	border-radius: var(--border-radius-base);
	padding: 6px;
	border: 1px solid rgb(204, 204, 204);
	background: rgb(249, 249, 249);
`;

export const ElementList = styled.div`
	width: 100%;
	max-width: 100%;
	border-radius: var(--border-radius-base);
	border: 1px solid rgb(204, 204, 204);
	background: rgb(247, 247, 247);
	padding: 4px;
	height: 312px;
	display: flex;
	gap: 12px;
	flex-direction: column;
	
	& > ul {
		overflow: auto;
		margin: 0;
	}
`;

export const TabContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	& > svg {
		font-size: 20px;
	}

	& > small {
		margin-top: 5px;
	}
`;

export const Element = styled.li`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0 12px;
	border-radius: var(--border-radius-base);
	height: 50px;
	background: ${({ color }) => color};
	border: 1px solid ${({ color }) => (color?.startsWith('#FFF') ? '#b7b7b7' : color)};
	margin-bottom: 2px;
	cursor: pointer;
	gap: 18px;

	& > svg {
		font-size: 18px;
	}

	& > span {
		font-weight: 600;
		font-size: 13px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	& > span > small {
		opacity: 0.8;
		/* margin-left: 20px; */
	}

	& * {
		color: ${({ color }) => (color?.startsWith('#FFF') ? '#040404' : '#FFF')};
	}

	&:hover {
		background: ${({ color }) =>
			color?.startsWith('#FFF')
				? Color(color).darkenByRatio(0.1).toCSS()
				: Color(color).lightenByRatio(0.3).toCSS()};
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

export const RadioVignetteLabelContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	& > span {
		font-weight: 600;
		font-size: 13px;
		width: 100%;
	}

	& > div {
		width: 100%;
		display: flex;
		gap: 18px;
		
		& > small {
			font-weight: 500;
			font-size: 13px;
			opacity: .8;

			& > span {
				font-weight: 600;
			}
		}
	}
`;
