import React from 'react';
import { Typography, Divider } from 'antd';
import { Link } from 'react-router-dom';

import Meta from '../../../../components/Meta';
import HelpButtons from '../../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../../assets/images/tutorials/playlists/modelos/img-01.png';
import img02 from '../../../../assets/images/tutorials/playlists/modelos/img-02.png';


const { Title, Paragraph } = Typography;

const PlaylistsManagement = () => (
	<Container>
		<Meta title='Ajuda - PLaylists Modelos' />

		<Title>Playlists Modelos</Title>

		<Paragraph>
			Na TalkPlay, a criação de um programa de rádio bem-sucedido envolve a sincronização precisa
			entre o modelo do programa e a playlist correspondente. Aqui está um guia passo a passo sobre
			como configurar isso:
		</Paragraph>		<Paragraph>
			1 - <strong>Configuração do Modelo da Playlist</strong>: Selecione o{' '}
			<strong>'Programa'</strong> e <strong>'Abra o Botão do Dia'</strong> abaixo da{' '}
			<strong>'Configuração de Insidências'</strong> da interface de gerenciamento da playlist da
			TalkPlay. Crie um bloco dentro da Playlist clicando em <strong>'Adicionar Bloco'</strong>,
			clique em <strong>'Inserir Categoria' </strong>para delinear o segmento musical do bloco.
		</Paragraph>		<Paragraph>
			2 -<strong> Correspondência Entre Bloco e Playlist</strong> : É fundamental que o número de
			músicas em cada bloco corresponda ao configurado no modelo semanal da playlist. Por exemplo,
			se um programa é composto por 4 blocos, cada um com 4 músicas, a playlist semanal deve
			espelhar essa configuração.
		</Paragraph>		<Paragraph>
			3 - <strong>Seleção e Configuração de Músicas</strong> : Ao configurar um bloco, você pode
			escolher uma categoria musical específica, como sertanejo ou pagode, ou uma pasta com vários
			estilos musicais. Se uma pasta específica de gênero é escolhida, como sertanejo, a TalkPlay
			buscará todas as músicas sertanejas dessa pasta para esse bloco. Se a pasta contém vários
			estilos, a TalkPlay pode inserir diferentes gêneros de músicas, tornando o programa mais
			dinâmico.
		</Paragraph>		<Paragraph>
			4 - <strong>Criação da Playlist</strong> : Ao configurar os blocos, defina quantas músicas de
			uma pasta serão tocadas em cada bloco. Se você tem um bloco de 4 músicas, por exemplo, pode
			escolher o número 4 para que a TalkPlay complete o bloco com 4 músicas dessa categoria.
		</Paragraph>		<Paragraph>
			5 -<strong> Gerenciamento de Playlists Diárias</strong> : Com os modelos, você pode criar
			playlists detalhadas para cada bloco e cada dia ou replicar uma playlist para todos os dias da
			semana. A primeira opção permite um detalhamento maior, variando o ritmo do programa ao longo
			da semana.
		</Paragraph>		<Paragraph>
			6 - <strong>Atualização do Modelo</strong> : Lembre-se de que sempre que realizar alterações,
			é necessário atualizar o modelo na TalkPlay para que a plataforma reconheça as modificações
			feitas.
		</Paragraph>		<Paragraph>
			O TalkPlay consegue gerar automaticamente playlists com base nas músicas presentes no{' '}
			<Link to='/help/tracks'>Banco Músical</Link>, sendo assim você também fica livre dessa
			responsabilidade, mas para isso é necessário que seja criado um <i>Modelo de playslis</i> para
			cada programa, irémos aprender como fazer tudo isso em seguida.
		</Paragraph>
		<Paragraph>Detalhes do Módulo de Criação:</Paragraph>

		<ImgContainer>
			<img src={img01} alt='Playlist' />
		</ImgContainer>		
		<Paragraph> Se uma pasta musical é usada em todos os 'Blocos' a playlist irá mostrar o seu uso musical semanalmente para o programa. Isso irá te dar uma visão se é o suficiente, se precisa de mais ou se quer ter um número menor de músicas na pasta para ter uma maior repetição musical:</Paragraph>
		<ImgContainer>
			<img src={img02} alt='Playlist' />
		</ImgContainer>	
			<Divider />
		<HelpButtons
			prev={{ label: 'Banco Musical', to: '/help/bancomusical' }}
			next={{ label: 'Criando um modelo de playlist', to: '/help/playlists/playlist-scripts' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default PlaylistsManagement;
