import styled, { css } from 'styled-components';

export const Container = styled.div`
	background: #fff;
	padding: 16px 16px;
	height: 100%;

	& .ant-breadcrumb > span:last-child {
		font-weight: 600 !important;
		opacity: 0.8;
	}

	& .ant-breadcrumb-link {
		font-size: 12px;
	}

	& > header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 32px;

		& > h2 {
			color: rgba(0, 0, 0, 0.75) !important;
			display: flex;
			align-items: center;
			margin: 0;
			margin-bottom: -10px;
		}
	}
`;

export const FormWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	& form {
		width: 650px;
	}
`;

export const ConfigItem = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: var(--border-radius-base);
	border: 1px solid transparent;
	padding: 8px;

	& label {
		font-size: 14px;
		margin-bottom: 2px;
		height: 18px;
		display: flex;
		align-items: center;
		color: rgba(0, 0, 0, 0.6);

		& > svg {
			margin-right: 8px;
			color: var(--primary);
			opacity: 0.7;
		}
	}

	& > div {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		& svg {
			margin-left: 10px;
			font-size: 15px;
			color: rgba(0, 0, 0, 0.5);
			cursor: pointer;
		}
	}

	&:hover {
		background: #f9f9f9;
	}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none !important;
			opacity: 0.75 !important;

			* {
				pointer-events: none !important;
			}
		`}
`;

export const CollapseHeader = styled.div`
	display: flex;
	align-items: center;

	& > svg {
		font-size: 18px;
		margin-right: 8px;
	}
`;

export const ButtonsContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: ${({ masterization }) =>
		masterization === 'OPTIONAL' ? '1fr 1fr' : '1fr'};
	gap: 12px;
`;

export const ReasonButtonContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const ConfigSectionLabel = styled.p`
	font-size: 12px;
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.45);
	margin-bottom: 12px;
`;

export const GeneratingEpisodeFallback = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px 24px;
	border-radius: var(--border-radius-base);
	border-bottom: 5px solid var(--primary);
	background-color: var(--primary-opacity-10);
`;

export const Clock = styled.div`
	display: flex;
	max-width: max-content;

	& > p {
		color: rgba(0, 0, 0, 0.65);
		margin: 0;
		margin-right: 6px;
	}

	& > span {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.65);
		font-weight: 600;
		font-weight: 500;
		font-style: italic;
		margin-right: 2px;
	}
`;

export const Errors = styled.div`
	width: 650px;
	display: grid;
	gap: 8px 0;
`;

export const OptionContent = styled.span`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > span {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
`;
