import api from '../api';

const BASE_URL = '/api/v2/track-signatures';

const TrackSignaturesAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	show: ({ signatureId }) => api.get(`${BASE_URL}/${signatureId}`),
	store: ({ payload, onUploadProgress }) => api.post(BASE_URL, payload, { onUploadProgress }),
	update: ({ signatureId, payload }) => api.patch(`${BASE_URL}/${signatureId}`, payload),
	destroy: ({ signatureId }) => api.delete(`${BASE_URL}/${signatureId}`),
};

export default TrackSignaturesAPI;
