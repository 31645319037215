import React, { useState, useEffect, useCallback } from 'react';

import {
	PageHeader,
	Table,
	Card,
	Icon,
	Modal,
	Typography,
	Input,
	Divider,
	message,
	Avatar,
	Button,
} from 'antd';

import Meta from '../../../components/Meta';
import Player from '../../../components/Player';
import Fallback from '../../../components/Fallback';
import { Container, SearchContainer, AvatarCell, DetailsContainer } from './styles';

import CastersAPI from '../../../services/sdks/caster';
import { resolveFileSrc } from '../../../helpers/fileSrcResolver';

import { IoMdMicrophone } from 'react-icons/io';

const breadcrumb = {
	routes: [{ breadcrumbName: 'Início' }, { breadcrumbName: 'Locutores' }],
	style: { marginBottom: 12 },
};

const Casters = () => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [caster, setCaster] = useState(null);
	const [casters, setCasters] = useState([]);
	const [filteredCasters, setFilteredCasters] = useState([]);
	const [showModals, setShowModals] = useState({ details: false });

	const columns = [
		{
			title: 'ID',
			dataIndex: '_id',
			key: 'id',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					5
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Nome',
			key: 'name',
			render: (caster) => (
				<AvatarCell>
					<Avatar
						style={{ background: 'var(--primary)' }}
						src={resolveFileSrc({ fileName: caster?.profilePic, folder: ['profile_pics'] })}>
						{caster?.name[0] || '-'} {caster?.surname[0] || '-'}
					</Avatar>
					<Typography.Text className='name'>
						{caster?.name} {caster?.surname}
					</Typography.Text>
				</AvatarCell>
			),
		},
		{
			title: 'Microfone',
			dataIndex: 'mic',
			key: 'mic',
		},
		{
			title: 'Placa de Som',
			dataIndex: 'soundcard',
			key: 'soundcard',
		},
		{
			title: 'Detalhes',
			key: 'details',
			align: 'right',
			render: (caster) => (
				<Button
					icon='eye'
					size='small'
					type='ghost'
					onClick={() => {
						setCaster(caster);
						setShowModals({ ...showModals, details: true });
					}}>
					Detalhes
				</Button>
			),
		},
	];

	const handleSearch = useCallback(
		(search) => {
			if (!search) {
				return setFilteredCasters(casters);
			}

			setFilteredCasters(
				casters.filter((caster) => {
					const casterName = `${caster?.name} ${caster?.surname}`.toLocaleLowerCase();

					return casterName.includes(search.toLocaleLowerCase());
				})
			);
		},
		[casters]
	);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { casters },
				} = await CastersAPI.index('active=true');

				setCasters(casters);
				setFilteredCasters(casters);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os locutores, tente novamente');
			}
		};

		fetchInitialData();
	}, []);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Locutores' />

			<PageHeader title='Locutores' breadcrumb={breadcrumb}>
				<Typography.Text>Esses são os locutores cadastrados no TalkPLay</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<SearchContainer>
						<Input.Search
							allowClear
							size='large'
							onSearch={handleSearch}
							style={{ width: '300px' }}
							placeholder='Buscar por nome ou email'
						/>
					</SearchContainer>

					<Table
						size='middle'
						rowKey='_id'
						columns={columns}
						dataSource={filteredCasters}
						pagination={{ size: 'large', pageSize: 10, hideOnSinglePage: true }}
						style={{ border: 'none' }}
					/>
				</Card>
			</Container>

			<Modal
				visible={showModals?.details}
				title={
					<>
						<Icon type='file-text' style={{ marginRight: 8 }} /> Detalhes
					</>
				}
				footer={null}
				width={600}
				onCancel={() => setShowModals((prev) => ({ ...prev, details: false }))}>
				<DetailsContainer>
					<div className='desc'>
						{caster && (
							<AvatarCell>
								<Avatar
									size='large'
									style={{ background: 'var(--primary)' }}
									src={resolveFileSrc({ fileName: caster?.profilePic })}>
									{caster?.name[0] || '-'} {caster?.surname[0] || '-'}
								</Avatar>
								<Typography.Text className='name'>
									{caster?.name} {caster?.surname}
								</Typography.Text>
							</AvatarCell>
						)}

						<Typography.Text style={{ marginTop: 16 }}>{caster?.bio}</Typography.Text>
					</div>

					<Divider />

					<Button
						type='ghost'
						icon='play-circle'
						size='large'
						style={{ width: '100%' }}
						onClick={() => {
							setShowModals({ ...showModals, audioDemo: true });
						}}>
						Reproduzir demo de voz
					</Button>

					<Divider />

					<ul>
						<li style={{ border: 'none' }}></li>
						<li>
							<span className='label'>Microfone</span>
							<strong>{caster?.mic}</strong>
						</li>
						<li>
							<span className='label'>Placa de Som</span>
							<strong>{caster?.soundcard}</strong>
						</li>
					</ul>
				</DetailsContainer>
			</Modal>

			<Modal
				destroyOnClose
				title='Audio demo'
				footer={null}
				visible={showModals.audioDemo}
				onCancel={() => setShowModals({ ...showModals, audioDemo: false })}>
				<Player
					title='Audio demo'
					src={resolveFileSrc({ fileName: caster?.audioDemo, folder: ['casters_demo'] })}
					icon={IoMdMicrophone}
				/>
			</Modal>
		</>
	);
};

export default Casters;
