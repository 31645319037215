import api from '../api';

const BASE_URL = '/api/v2/demos';

const DemosAPI = {
	index: (type) => api.get(`${BASE_URL}?type=${type}`),
	store: ({ payload }) => api.post(BASE_URL, payload),
	update: ({ payload, demoId }) => api.patch(`${BASE_URL}/${demoId}`, payload),
	destroy: ({ demoId }) => api.delete(`${BASE_URL}/${demoId}`),
};

export default DemosAPI;
