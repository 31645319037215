import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px;

	& > .ant-card {
		margin: 12px 0;

		& .ant-card-body {
			padding: 0;
		}
	}

	.ant-tabs-tabpane {
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 16px;
	}

	span.del {
		font-style: italic;
		opacity: 0.8;
	}
`;

export const CollapseTitleContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 0 12px;

	& > div.info {
		border-right: 1px solid #dedede;
	}

	& > div.info:last-child {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		border: none;

		& > strong {
			margin: 0 4px;
			font-size: 18px;
		}
	}
`;

export const SearchContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: ${({ inner }) => (inner ? 'flex-end' : 'center')};
	margin-bottom: 16px;
`;

export const ShargingWrapper = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr;
	gap: 0 32px;
	flex: 1;
`;

export const UserContainer = styled.div`
	display: flex;
	flex-direction: column;

	& > span:last-child {
		font-size: 14px;
		padding-left: 24px;
		opacity: 0.7;
	}
`;

export const highlightStyle = {
	background: '#7bff90',
	color: '#3a3a3a',
};
