import React from 'react';
import { Section, SectionTitle, Badge, Text } from './styles';

export default function Producers() {
	return (
		<Section>
			<SectionTitle>PRODUTORES</SectionTitle>
			<div className='flex justify-center space-x-2 mb-4'>
				<Badge variant='default' className='bg-[#e91e63]'>
					VINHETA
				</Badge>
				<Badge variant='default' className='bg-[#00bcd4]'>
					OFF 
				</Badge>
				<Badge variant='default' className='bg-[#9c27b0]'>
					MÚSICA
				</Badge>
			</div>
			<Text>
            Nunca mais fique preso à criatividade! Crie programas exclusivos com apenas um clique. Ensine a IA TalkPlay a fazer o que você deseja e deixe que ela produza diariamente para você.
			</Text>
		</Section>
	);
}
