import React, { useState, useEffect, useCallback } from 'react';
import readXlsxFile from 'read-excel-file';
import { connect } from 'react-redux';
import { startOfWeek, format, addDays, getDay } from 'date-fns';
import { saveAs } from 'file-saver';
import moment from 'moment';
import path from 'path';
import axios from 'axios';
import JSZip from 'jszip';
import {
	Button,
	Select,
	DatePicker,
	message,
	Modal,
	Icon,
	Divider,
	Typography,
	Tag,
	Breadcrumb,
	Menu,
	Table,
	Dropdown,
	Radio,
	Spin,
	Input,
	Alert,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import Form from '../../../../components/Form';
import PageSizeHandler from '../../../../components/PageSizeHandle';
import PlaylistView from '../../../../components/PlaylistView';
import UploadZone from '../../../../components/UploadZone';
import RadioCard from '../../../../components/RadioCard';
import {
	Container,
	OptionContent,
	TableHeader,
	GenerationWeekGrid,
	GenerationDayItem,
	GeneratedPlaylists,
	RadioCardsContainer,
} from './styles';

import ProgramsAPI from '../../../../services/sdks/programs';
import SharingsAPI from '../../../../services/sdks/share';
import PlaylistsAPI from '../../../../services/sdks/playlists';
import PlaylistScriptAPI from '../../../../services/sdks/playlistScripts';
import env from '../../../../config/env';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';

import { usePlayer } from '../../../../hooks';

import {
	FiCheck,
	FiDownload,
	FiLock,
	FiSliders,
	FiUpload,
	FiUploadCloud,
	FiXCircle,
} from 'react-icons/fi';

const LOCK_PASS = '9#$AJc9#$%&*AchhjNBA*S&#';

const PlaylistDetails = ({ user }) => {
	const player = usePlayer();

	const [fallback, setFallback] = useState({ initialData: true });
	const [programs, setPrograms] = useState([]);
	const [playlists, setPlaylists] = useState([]);
	const [selectedPlaylists, setSelectedPlaylists] = useState([]);
	const [rows, setRows] = useState(null);
	const [dateType, setDateType] = useState('week');
	const [playlistScript, setPlaylistScript] = useState(null);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [filters, setFilters] = useState({ program: undefined, weekDays: [] });
	const [weekMessage, setWeekMessage] = useState('');
	const [visibleModals, setVisibleModals] = useState(null);
	const [generation, setGeneration] = useState({
		week: [],
		program: undefined,
		playlists: null,
		type: 'full',
	});

	const [upload, setUpload] = useState({
		date: null,
		program: undefined,
		file: null,
		exists: false,
	});

	const [pass, setPass] = useState('');
	const [lockPlCreation, setLockPlCreation] = useState(false);
	const [lockedVerified, setLockVerified] = useState(false);

	const columns = [
		{
			title: 'Data de lançamento',
			key: 'playlist',
			render: (playlist) => `PLAYLIST - ${playlist?.date}`,
		},
		{
			title: 'Programa',
			dataIndex: 'program',
			key: 'program',
			render: (program) => program?.name,
		},
		{
			title: 'Dia da semana',
			key: 'week-day',
			render: (playlist) => {
				return {
					1: 'Segunda-Feira',
					2: 'Terça-Feira',
					3: 'Quarta-Feira',
					4: 'Quinta-Feira',
					5: 'Sexta-Feira',
					6: 'Sábado',
					7: 'Domingo',
				}[playlist.weekDay];
			},
		},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					8
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'options',
			render: (playlist) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item key='play' onClick={() => handleReadPlaylist(playlist.filename)}>
									<Icon type='eye' /> Visualizar
								</Menu.Item>
								<Menu.Item key='download'>
									<a
										href={resolveFileSrc({
											customS3Bucket: env.playlistsS3Bucket,
											fileName: playlist.filename,
										})}
										style={{ width: '100%' }}
									>
										<Icon type='download' /> Fazer download
									</a>
								</Menu.Item>
							</Menu>
						}
					>
						<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const handleReadPlaylist = useCallback(async (filename) => {
		const uri = resolveFileSrc({
			customS3Bucket: env.playlistsS3Bucket,
			fileName: filename,
		});

		const response = await fetch(uri);
		const BLOB = await response.blob();
		const rows = await readXlsxFile(BLOB);

		setRows(rows);
		setVisibleModals((prev) => ({ ...prev, view: true }));
	}, []);

	const onFilterByWeek = useCallback((d, stringfiedDate) => {
		if (!d) {
			setWeekMessage('');
			setFilters((prev) => ({ ...prev, weekDays: [] }));

			return;
		}

		let [yearNumber, weekNumber] = stringfiedDate.split('-');

		weekNumber = `${weekNumber[0]}${weekNumber[1]}`;

		setWeekMessage(`Exibindo playlists da ${weekNumber}ª semana de ${yearNumber}`);

		const formatedDate = moment(d).format('DD/MM/yyyy');
		const [day, month, year] = formatedDate.split('/');
		const _date = new Date(year, Number(month) - 1, day);

		const weekDays = [];
		const firstWeekDay = startOfWeek(_date);

		for (let index = 0; index < 7; index++) {
			weekDays.push(format(addDays(firstWeekDay, index + 1), 'dd/MM/yyyy'));
		}

		setFilters((prev) => ({ ...prev, weekDays }));
	}, []);

	const handleDownloadAsZIP = useCallback(
		async (data) => {
			setFallback((prev) => ({ ...prev, multiDownload: true }));

			const zip = new JSZip();

			for (let index = 0; index < data.length; index++) {
				const playlist = data[index];
				const filePath = resolveFileSrc({
					customS3Bucket: env.playlistsS3Bucket,
					fileName: playlist.filename,
				});
				const blob = await axios.get(filePath, { responseType: 'blob' });
				const name = playlist?.date.replace(/\//g, '-');
				const ext = path.extname(playlist?.filename);

				const fileName = `${name}${ext}`;

				zip.file(fileName, blob.data, { binary: true });
			}

			const zipContent = await zip.generateAsync({ type: 'blob' });
			const selectedProgram = programs.find(({ _id }) => _id === filters?.program);

			let zipFileName = 'PLAYLISTS';

			selectedProgram && (zipFileName = `${zipFileName} - ${selectedProgram.name}`);

			saveAs(zipContent, zipFileName);
			setFallback((prev) => ({ ...prev, multiDownload: false }));
		},
		[programs, filters]
	);

	const handleGeneratePlaylists = useCallback(async () => {
		try {
			const start = generation.week[0];
			const end = generation.week[6];
			const programId = generation.program;
			const type = generation.type;

			setFallback((fallback) => ({ ...fallback, generating: true }));

			const payload = { programId, week: [start.date, end.date], type };
			const { data: playlists } = await PlaylistsAPI.generate(payload);

			setGeneration({ program: undefined, week: [], playlists, type: 'full' });

			message.success('Playlists geradas com sucesso');
		} catch (error) {
			console.error(error);
			message.error(error.message || 'Houve um erro ao gerar as playlists');
		} finally {
			setLockPlCreation(false);
			setFallback((fallback) => ({ ...fallback, generating: false }));
		}
	}, [generation]);

	const handleUploadPlaylist = useCallback(async () => {
		try {
			setFallback((fallback) => ({ ...fallback, uploading: true }));

			const payload = new FormData();

			payload.append('date', upload.date);
			payload.append('program', upload.program);
			payload.append('file', upload.file);

			await PlaylistsAPI.upload(payload);

			setUpload({ date: null, program: undefined, file: null, exists: false });
			setVisibleModals((prev) => ({ ...prev, upload: false }));
			setFallback((fallback) => ({ ...fallback, uploading: false }));

			message.success('Playlist enviada com sucesso');
		} catch (error) {
			console.error(error);

			message.error('Houve um erro ao subir a playlist');
		}
	}, [upload]);

	const verifyPlaylistValidation = useCallback(async (playlistFile) => {
		try {
			if (!playlistFile) {
				return setUpload((prev) => ({ ...prev, file: null }));
			}

			const rows = await readXlsxFile(playlistFile);

			if (!rows[6][2]?.startsWith('BLOCO')) {
				return message.error('Playlist em formato inválido!');
			}

			const musicCell = 2;
			const artistCell = 3;
			const firstTrackRow = 7;

			const playlist = [];

			for (let index = firstTrackRow; index < rows.length; index++) {
				const row = rows[index];

				if (row[musicCell] && !row[musicCell]?.startsWith('BLOCO')) {
					const music = row[musicCell];
					const artist = row[artistCell];

					if (typeof music === 'string' && typeof artist === 'string') {
						playlist.push(`${music.toUpperCase()}--${artist.toUpperCase()}`);
					}
				}
			}

			setUpload((prev) => ({ ...prev, file: playlistFile }));
		} catch (error) {
			console.error(error);

			return message.error('Playlist inválida');
		}
	}, []);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				let {
					data: { programs },
				} = await ProgramsAPI.index(`userId=${user?._id}&isDeleted=false`);

				let {
					data: { sharings },
				} = await SharingsAPI.index(`user=${user?._id}`);

				sharings = sharings.map(({ program, isActive, isFavorited }) => {
					return { ...program, isFavorited, isShared: true, isActive };
				});

				programs = [...programs, ...sharings].sort((x, y) => {
					return x?.isFavorited === y?.isFavorited ? 0 : x?.isFavorited ? -1 : 1;
				});

				let firstActiveProgram = null;

				programs.every((p) => {
					if (p?.isActive) {
						firstActiveProgram = p;

						return false;
					}

					return true;
				});

				setPrograms(programs);

				if (firstActiveProgram) {
					setFilters((prev) => ({ ...prev, program: firstActiveProgram._id }));
					message.info(`O programa ${firstActiveProgram.name} foi selecionado`);
				}
			} catch (error) {
				console.error(error);
				message.error('Houve um erro, tente novamente');
			} finally {
				setFallback((prev) => ({ ...prev, initialData: false }));
			}
		};

		fetchInitialData();
	}, [user]);

	useEffect(() => {
		const fetchPlaylists = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingPlaylists: true }));

				let query = `page=${pagination?.current - 1}&limit=${pagination?.pageSize}&`;

				filters.program && (query = `${query}&program=${filters.program}`);
				filters.weekDays.forEach((d) => (query = `${query}&date=${d}`));

				const {
					data: { playlists, total },
				} = await PlaylistsAPI.index({ query });

				setPlaylists(
					playlists
						.map((p) => {
							const [day, month, year] = p.date.split('/');
							const _date = new Date(year, Number(month) - 1, day);
							const weekDay = getDay(_date);

							return { ...p, weekDay: weekDay === 0 ? 7 : weekDay };
						})
						.sort((x, y) => (x.weekDay > y.weekDay ? 1 : -1))
				);

				setPagination((prev) => ({ ...prev, total }));
				setFallback((prev) => ({ ...prev, fetchingPlaylists: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as playlists');

				setFallback((prev) => ({ ...prev, fetchingPlaylists: false }));
			}
		};

		filters?.program && fetchPlaylists();
	}, [filters, pagination.current, pagination.pageSize]); //eslint-disable-line

	useEffect(() => {
		const fetchPlaylistScript = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingScript: true }));

				const {
					data: { playlistScript },
				} = await PlaylistScriptAPI.show(generation.program);

				if (!playlistScript) {
					message.warn('Esse programa não possui um modelo semana de playlist');
				}

				setFallback((prev) => ({ ...prev, fetchingScript: false }));
				setPlaylistScript(playlistScript);
			} catch (error) {
				console.error(error);

				setFallback((prev) => ({ ...prev, fetchingScript: false }));
			}
		};

		if (generation.program) {
			fetchPlaylistScript();
		}
	}, [generation.program]);

	useEffect(() => {
		const fetchPlaylist = async () => {
			try {
				setFallback((fallback) => ({ ...fallback, fetchingPlaylist: true }));

				const query = `date=${upload?.date}&program=${upload?.program}`;
				const {
					data: { playlists },
				} = await PlaylistsAPI.index({ query });

				setUpload((prev) => ({ ...prev, exists: playlists.length !== 0 }));
				setFallback((fallback) => ({ ...fallback, fetchingPlaylist: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao verificar a existência da playlist nesse dia');
			}
		};

		if (upload.date && upload.program) {
			fetchPlaylist();
		}
	}, [upload.date, upload.program]); //eslint-disable-line

	useEffect(() => {
		const verifyPlaylistsInSelectedWeek = async () => {
			try {
				setFallback((prev) => ({ ...prev, verifyingPlaylistsExistance: true }));

				let query = `page=0&limit=7&program=${generation.program}`;

				generation.week.forEach(({ date }) => {
					return (query = `${query}&date=${format(date, 'dd/MM/yyyy')}`);
				});

				const {
					data: { total: playlistAmount },
				} = await PlaylistsAPI.index({ query });

				setLockPlCreation(playlistAmount === 0 ? false : true);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao verificar as playlists dessa semana');

				setLockPlCreation(true);
			} finally {
				setFallback((prev) => ({ ...prev, verifyingPlaylistsExistance: false }));
			}
		};

		const selectedProgram = programs.find((p) => p?._id === generation?.program);
		const isTalkProgram = selectedProgram?.userId?._id === env?.talkId;

		if (isTalkProgram && !lockedVerified && generation?.week.length) {
			verifyPlaylistsInSelectedWeek();
		}
	}, [generation, lockedVerified, programs]);

	if (fallback.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Playlists' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[{ breadcrumbName: 'INÍCIO' }, { breadcrumbName: 'PLAYLISTS' }]}
				/>

				<header>
					<Typography.Title level={2}>Playlists</Typography.Title>

					<div>
						<Button
							type='primary'
							onClick={() => setVisibleModals({ ...visibleModals, generate: true })}
						>
							<FiSliders /> Gerar playlists
						</Button>

						<Button
							style={{ marginLeft: 4 }}
							onClick={() => setVisibleModals({ ...visibleModals, upload: true })}
						>
							<FiUpload /> Subir playlists
						</Button>
					</div>
				</header>

				<Form.Container layout='30% 30% 15% 45%'>
					<Form.Item label='Filtrar por programa'>
						<Select
							showSearch
							style={{ width: '100%' }}
							placeholder='Selecione o programa'
							optionFilterProp='children'
							value={filters?.program}
							onChange={(value) => setFilters({ ...filters, program: value })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}
						>
							{programs.map((program) => (
								<Select.Option
									key={program._id}
									disabled={!program.isActive}
									_search={program?.name}
								>
									<OptionContent>
										<span>
											<Icon
												theme='filled'
												type='heart'
												style={{
													color: 'var(--danger)',
													marginRight: 8,
													opacity: program?.isFavorited ? 1 : 0,
													pointerEvents: 'none',
												}}
											/>
											{program?.name}
										</span>

										{program?.isActive && program?.isShared && (
											<Tag color='gold' style={{ marginRight: 4 }}>
												Compartilhado
											</Tag>
										)}

										{!program?.isActive && <Tag color='red'>Inadimplente</Tag>}
									</OptionContent>
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					{dateType === 'week' ? (
						<Form.Item label='Filtrar por semana' help={weekMessage}>
							<DatePicker.WeekPicker
								style={{ width: '100%' }}
								placeholder='Selecione a semana'
								onChange={onFilterByWeek}
							/>
						</Form.Item>
					) : (
						<Form.Item label='Buscar por dia'>
							<DatePicker
								format='DD/MM/yyyy'
								style={{ width: '100%' }}
								placeholder='Selecione o dia'
								onChange={(_, _date) => setFilters({ ...filters, weekDays: [_date] })}
							/>
						</Form.Item>
					)}

					<Form.Item>
						<Radio.Group
							value={dateType}
							onChange={({ target: { value } }) => {
								setDateType(value);
								setWeekMessage('');
							}}
						>
							<Radio value='week'>
								<span style={{ fontSize: 12 }}>Filtrar por semana</span>
							</Radio>
							<Radio value='day'>
								<span style={{ fontSize: 12 }}>Filtrar por dia</span>
							</Radio>
						</Radio.Group>
					</Form.Item>
				</Form.Container>

				<Divider />

				<TableHeader>
					<div className='actions'>
						<span>
							Quantidade: <strong>{pagination?.total}</strong>
						</span>
						<div>
							<Button
								size='small'
								disabled={!selectedPlaylists.length}
								type='ghost'
								onClick={() => handleDownloadAsZIP(selectedPlaylists)}
								loading={fallback?.multiDownload}
							>
								<FiDownload /> Baixar selecionados{' '}
								{selectedPlaylists.length !== 0 && `(${selectedPlaylists.length})`}
							</Button>
						</div>
					</div>

					<PageSizeHandler pagination={pagination} setPagination={setPagination} />
				</TableHeader>

				<Table
					loading={fallback?.fetchingPlaylists}
					size='middle'
					rowKey='_id'
					columns={columns}
					dataSource={playlists}
					style={{ border: 'none' }}
					pagination={{
						...pagination,
						size: 'large',
						onChange: (current) => setPagination({ ...pagination, current }),
					}}
					rowSelection={{
						onChange: (_, selectedRows) => setSelectedPlaylists(selectedRows),
					}}
				/>
			</Container>

			<Modal
				width='80vw'
				visible={visibleModals?.view}
				onCancel={() => {
					setRows(null);
					setVisibleModals({ ...visibleModals, view: false });

					return player.stop();
				}}
				footer={null}
			>
				<PlaylistView data={rows} />
			</Modal>

			<Modal
				destroyOnClose
				visible={visibleModals?.generate}
				width={700}
				closable={!fallback?.generating}
				onCancel={() => {
					setVisibleModals({ ...visibleModals, generate: false });
					setGeneration({
						program: undefined,
						week: [],
						playlists: null,
						type: 'full',
					});
				}}
				footer={
					generation?.playlists ? null : (
						<>
							<Button
								disabled={fallback?.generating || fallback?.verifyingPlaylistsExistance}
								onClick={() => {
									setLockPlCreation(false);
									setVisibleModals({ ...visibleModals, generate: false });
									setGeneration({
										program: undefined,
										week: [],
										playlists: null,
										type: 'full',
									});
								}}
							>
								<FiXCircle /> Cancelar
							</Button>
							<Button
								type='primary'
								loading={fallback?.generating}
								disabled={
									!generation.program ||
									!generation.week.length ||
									!playlistScript ||
									fallback?.verifyingPlaylistsExistance
								}
								onClick={() => {
									lockPlCreation
										? setVisibleModals({ ...visibleModals, lockPlCreation: true })
										: handleGeneratePlaylists();
								}}
							>
								<FiSliders />
								{fallback?.generating ? 'As playlists estão sendo geradas' : 'Gerar playlists'}
							</Button>
						</>
					)
				}
				title={
					<>
						<FiSliders /> Gerar playlists
					</>
				}
			>
				{generation.playlists ? (
					<GeneratedPlaylists>
						<Button
							type='primary'
							loading={fallback?.multiDownload}
							onClick={() =>
								handleDownloadAsZIP(
									generation.playlists.filter((playlist) => {
										return playlist !== 'unactive';
									})
								)
							}
						>
							<FiDownload /> Baixar todas
						</Button>

						<Divider />

						<ul>
							{generation.playlists.map((playlist, i) => (
								<li key={i}>
									<div>
										{playlist === 'unactive' ? (
											<i style={{ marginBottom: 6 }}>Não vai ao ar</i>
										) : (
											<>
												<div className='meta'>
													<span>Data: </span>
													<strong>{playlist?.date}</strong>
												</div>

												<div className='meta'>
													<span>ID: </span>
													<strong style={{ opacity: 0.5 }}>{playlist?._id}</strong>
												</div>
											</>
										)}
									</div>

									<Button
										size='small'
										disabled={playlist === 'unactive'}
										href={resolveFileSrc({
											customS3Bucket: env.playlistsS3Bucket,
											fileName: playlist.filename,
										})}
									>
										<FiDownload />
										Baixar
									</Button>
								</li>
							))}
						</ul>
					</GeneratedPlaylists>
				) : (
					<Spin
						spinning={
							fallback?.fetchingScript || fallback?.verifyingPlaylistsExistance ? true : false
						}
					>
						<Form.Container>
							<Form.Item label='Programa'>
								<Select
									showSearch
									style={{ width: '100%' }}
									placeholder='Selecione o programa'
									optionFilterProp='children'
									value={generation?.program}
									onChange={(value) => setGeneration({ ...generation, program: value })}
									filterOption={(input, { props: { _search } }) => {
										return _search.match(new RegExp(input, 'i'));
									}}
								>
									{programs
										.filter((p) => !p.isShared)
										.map((program) => (
											<Select.Option
												key={program._id}
												disabled={!program.isActive}
												_search={program?.name}
											>
												<OptionContent>
													<span>
														<Icon
															theme='filled'
															type='heart'
															style={{
																color: 'var(--danger)',
																marginRight: 8,
																opacity: program?.isFavorited ? 1 : 0,
																pointerEvents: 'none',
															}}
														/>
														{program?.name}
													</span>

													{!program?.isActive && <Tag color='red'>Inadimplente</Tag>}
												</OptionContent>
											</Select.Option>
										))}
								</Select>
							</Form.Item>

							<Form.Item label='Semana'>
								<DatePicker.WeekPicker
									style={{ width: '100%' }}
									placeholder='Selecione a semana'
									onChange={(date) => {
										const formatedDate = moment(date).format('DD/MM/yyyy');
										const [day, month, year] = formatedDate.split('/');
										const _date = new Date(year, Number(month) - 1, day);

										const week = [];
										const firstWeekDay = startOfWeek(_date);
										const labels = ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'];

										for (let index = 0; index < 7; index++) {
											week.push({
												label: labels[index],
												stringfiedDate: format(addDays(firstWeekDay, index + 1), 'dd/MM'),
												date: addDays(firstWeekDay, index + 1),
											});
										}

										setGeneration({ ...generation, week });
									}}
								/>
							</Form.Item>

							<Form.Item label='Formato de geração'>
								<RadioCardsContainer>
									<RadioCard
										label='Formato Padrão'
										checked={generation.type === 'full'}
										onClick={() => setGeneration({ ...generation, type: 'full' })}
										descriptions={[
											{
												text: '- Ideal para programas com muitas músicas no banco;',
												options: { strong: true },
											},
											{
												text: '- O sistema tentará evitar repetições de artistas de acordo com a incidência do modelo;',
											},
										]}
									/>

									<RadioCard
										label='Programas lançamento'
										checked={generation.type === 'stepped'}
										onClick={() => setGeneration({ ...generation, type: 'stepped' })}
										descriptions={[
											{
												text: '- Ideal para programas onde a quantidade de músicas no modelo é maior que no banco;',
												options: { strong: true },
											},
											{ text: '- O sistema tentará evitar a repetição da mesma música em um dia;' },
										]}
									/>
								</RadioCardsContainer>
							</Form.Item>
						</Form.Container>

						{playlistScript && (
							<GenerationWeekGrid>
								{generation.week.map(({ label, stringfiedDate }) => (
									<GenerationDayItem
										key={label}
										active={playlistScript?.week[label][0] !== 'unactive'}
									>
										<span className='label'>{label.toUpperCase()}</span>
										<span className='date'>{stringfiedDate || '-'}</span>
									</GenerationDayItem>
								))}
							</GenerationWeekGrid>
						)}
					</Spin>
				)}
			</Modal>

			<Modal
				destroyOnClose
				closable={!fallback?.uploading}
				visible={visibleModals?.upload}
				cancelButtonProps={{ disabled: fallback?.uploading }}
				onCancel={() => setVisibleModals({ ...visibleModals, upload: false })}
				okText={
					<>
						<FiUploadCloud /> Enviar playlist
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				okButtonProps={{
					loading: fallback?.uploading,
					disabled: !upload?.program || !upload?.date || !upload?.file,
				}}
				onOk={() => {
					upload?.exists
						? programs.find((p) => p?._id === upload?.program).userId?._id === env.talkId
							? lockedVerified
								? handleUploadPlaylist()
								: setVisibleModals({ ...visibleModals, lockPlCreation: true })
							: Modal.confirm({
									centered: true,
									okText: 'Enviar e substituir',
									okButtonProps: {
										type: 'primary',
										icon: 'upload',
									},
									cancelButtonProps: { icon: 'close-circle' },
									title: 'Atenção!',
									onOk: handleUploadPlaylist,
									content: (
										<p>
											Já existe uma playlist cadastrada na data selecionada, caso opte por enviar
											uma nova playlist, a atualmente cadastrada será substituída.
											<br />
											Deseja continuar?
										</p>
									),
								})
						: handleUploadPlaylist();
				}}
				title={
					<>
						<FiUploadCloud /> Subir playlist
					</>
				}
			>
				<Spin spinning={fallback?.fetchingPlaylist ? true : false}>
					<Form.Container>
						<Form.Item label='Programa'>
							<Select
								showSearch
								style={{ width: '100%' }}
								placeholder='Selecione o programa'
								optionFilterProp='children'
								value={upload?.program}
								onChange={(value) => setUpload({ ...upload, program: value })}
								filterOption={(input, { props: { _search } }) => {
									return _search.match(new RegExp(input, 'i'));
								}}
							>
								{programs
									.filter((p) => !p.isShared)
									.map((program) => (
										<Select.Option
											key={program._id}
											disabled={!program.isActive}
											_search={program?.name}
										>
											<OptionContent>
												<span>
													<Icon
														theme='filled'
														type='heart'
														style={{
															color: 'var(--danger)',
															marginRight: 8,
															opacity: program?.isFavorited ? 1 : 0,
															pointerEvents: 'none',
														}}
													/>
													{program?.name}
												</span>

												{!program?.isActive && <Tag color='red'>Inadimplente</Tag>}
											</OptionContent>
										</Select.Option>
									))}
							</Select>
						</Form.Item>

						<Form.Item label='Data'>
							<DatePicker
								format='DD/MM/yyyy'
								style={{ width: '100%' }}
								placeholder='Selecione a data'
								onChange={(_, date) => setUpload({ ...upload, date })}
							/>
						</Form.Item>

						<Form.Item>
							<UploadZone
								id='pick-offs'
								disabled={!upload?.program || !upload?.date}
								multiple={true}
								icon='cloud-upload'
								inputProps={{ accept: '.xls, .xlsx' }}
								uploadIcon='file-excel'
								uploads={upload?.file ? [upload?.file] : []}
								onChange={({ target: { files } }) => {
									verifyPlaylistValidation(files[0]);
								}}
								onRemoveItem={() => setUpload({ ...upload, file: null })}
								label='Clique para selecionar o arquivo da playlist'
								secondaryLabel={
									upload?.program && upload?.date ? (
										<p>
											O arquivo deve ser do tipo <strong>xls</strong> ou <strong>xlsx</strong>
										</p>
									) : (
										<p>Por favor, informe o programa e a data</p>
									)
								}
							/>
						</Form.Item>
					</Form.Container>
				</Spin>
			</Modal>

			<Modal
				centered
				visible={visibleModals?.lockPlCreation}
				onOk={() => {
					if (pass === LOCK_PASS) {
						setPass('');
						setLockVerified(true);
						setLockPlCreation(false);
						setVisibleModals({ ...visibleModals, lockPlCreation: false });

						message.success('Acesso concedido');
					} else {
						message.error('Senha inválida');
					}
				}}
				onCancel={() => {
					setPass('');
					setLockPlCreation(false);
					setGeneration({ program: undefined, week: [], playlists: null, type: 'full' });
					setUpload({ date: null, program: undefined, file: null, exists: false });
					setVisibleModals({
						...visibleModals,
						lockPlCreation: false,
						upload: false,
						generate: false,
					});
				}}
				okText={
					<>
						<FiCheck /> Verificar senha
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				title={
					<>
						<FiLock /> Forneça a senha para prosseguir
					</>
				}
			>
				<Alert
					showIcon
					message='Atenção'
					type='warning'
					description={
						<>
							Algumas playlists na(s) data(s) selecionada(s) já foram geradas. Para previnir
							conflitos com episódios já produzidos, somente pessoal autorizado consegue gerar
							novamente playlists já existentes.
							<br />
							<br />
							Forneça a senha abaixo para prosseguir.
						</>
					}
				/>

				<Divider />

				<Form.Container>
					<Form.Item label='Senha'>
						<Input.Password
							placeholder='Forneça a senha'
							value={pass}
							onChange={({ target: { value } }) => setPass(value)}
						/>
					</Form.Item>
				</Form.Container>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(PlaylistDetails);
