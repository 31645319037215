import React, { useState, useEffect, useCallback } from 'react';
import {
	Table,
	Tag,
	Dropdown,
	Menu,
	Icon,
	Select,
	Modal,
	Divider,
	Typography,
	message,
	Breadcrumb,
	Button,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import Form from '../../../../components/Form';
import Progress from '../../../../components/Progress';
import PageSizeHandler from '../../../../components/PageSizeHandle';
import UploadZone from '../../../../components/UploadZone';
import FileDuration from '../../../../components/FileDuration';
import FileExt from '../../../../components/FileExt';
import PlayCell from '../../../../components/PlayCell';
import { Container, TableHeader, Infos } from './styles';

import UsersAPI from '../../../../services/sdks/user';
import GenresAPI from '../../../../services/sdks/genres';
import SignaturesAPI from '../../../../services/sdks/signatures';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';

import { usePlayer } from '../../../../hooks';
import { FiPlusCircle, FiTrash2, FiUpload, FiXCircle } from 'react-icons/fi';

const ManageSignatures = () => {
	const player = usePlayer();

	const [fallback, setFallback] = useState({ initialData: true });
	const [visibleModals, setVisibleModals] = useState({ addSignature: false });
	const [newSignature, setNewSignature] = useState({
		files: [],
		userId: undefined,
		genreId: undefined,
	});

	const [genres, setGenres] = useState([]);
	const [users, setUsers] = useState([]);
	const [selectedSignatures, setSelectedSignatures] = useState([]);
	const [signatures, setSignatures] = useState([]);
	const [progress, setProgress] = useState(null);

	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [filters, setFilters] = useState({
		userId: undefined,
		verified: undefined,
	});

	const columns = [
		{
			title: 'Assinatura',
			key: 'category',
			render: (signature) => (
				<PlayCell
					meta={{
						primary: 'ASSINATURA DA RÁDIO',
						secondary: `${signature?.userId?.radioName} - ${signature?.userId?.city}/${signature?.userId?.state}`,
					}}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: signature.filename }),
							ref: signature?._id,
							meta: {
								name: `ASSIANTURA DA RÁDIO`,
								artist: `${signature?.userId?.radioName} - ${signature?.userId?.city}/${signature?.userId?.state}`,
							},
						});
					}}
					onPause={player.resume}
					isPlaying={player?.ref === signature?._id && player?.isPlaying}
				/>
			),
		},
		{
			title: 'Gênero',
			dataIndex: 'genreId',
			key: 'genre',
			render: (genre) => genre?.name,
		},
		{
			title: 'Duração',
			dataIndex: 'filename',
			align: 'center',
			key: 'duration',
			render: (filename) => <FileDuration src={resolveFileSrc({ fileName: filename })} />,
		},
		{
			title: 'Formato',
			dataIndex: 'filename',
			align: 'center',
			key: 'ext',
			render: (filename) => <FileExt src={resolveFileSrc({ fileName: filename })} />,
		},
		{
			title: 'Status',
			dataIndex: 'verified',
			key: 'verified',
			align: 'center',
			render: (verified) => {
				switch (Number(verified)) {
					case 0:
						return <Tag color='gold'>Em análise</Tag>;
					case 1:
						return <Tag color='green'>Aprovada</Tag>;
					case 2:
						return <Tag color='red'>Reprovada</Tag>;
					default:
						return null;
				}
			},
		},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					12
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			key: 'actions',
			align: 'right',
			render: (signature) => (
				<Dropdown
					placement='bottomRight'
					overlay={
						<Menu>
							<Menu.Item
								onClick={() => {
									player.start({
										src: resolveFileSrc({ fileName: signature.filename }),
										ref: signature?._id,
										meta: {
											signature: 'ASSINATURA DA RÁDIO',
											artist: signature.userId.radioName,
										},
									});
								}}
							>
								<Icon type='play-circle' /> Reproduzir
							</Menu.Item>
							<Menu.Item
								disabled={signature.verified === 1}
								onClick={() => handleModify({ signatureId: signature._id, verified: 1 })}
							>
								<Icon type='check-circle' /> Aprovar assinatura
							</Menu.Item>
							<Menu.Item
								disabled={signature.verified === 2}
								onClick={() => handleModify({ signatureId: signature._id, verified: 2 })}
							>
								<Icon type='close-circle' /> Reprovar assinatura
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								className='ant-dropdown-menu-item-danger'
								onClick={() => {
									Modal.confirm({
										title: 'Deletar assinatura?',
										icon: 'exclamation-circle',
										content: 'Essa ação não poderá ser revertida, deseja continuar?',
										onOk: () => handleSingleDelete({ signatureId: signature._id }),
										okText: 'Deletar',
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}
							>
								<Icon type='delete' /> Deletar
							</Menu.Item>
						</Menu>
					}
				>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 12 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleSingleDelete = useCallback(async ({ signatureId }) => {
		try {
			await SignaturesAPI.destroy({ signatureId });

			setSignatures((signatures) => signatures.filter(({ _id }) => _id !== signatureId));
		} catch (error) {
			console.error(error);
		}
	}, []);

	const handleModify = useCallback(async ({ signatureId, verified }) => {
		try {
			await SignaturesAPI.modify({ signatureId, payload: { verified } });
			const m = verified === 1 ? 'aceita' : 'rejeitada';

			setSignatures((signatures) =>
				signatures.map((s) => {
					if (s._id === signatureId) {
						return { ...s, verified };
					}

					return s;
				})
			);

			return message.success(`Assinatura foi ${m}!`);
		} catch (error) {
			console.error(error);
		}
	}, []);

	const handleMultiDelete = useCallback(async () => {
		try {
			for (let index = 0; index < selectedSignatures.length; index++) {
				await handleSingleDelete({ signatureId: selectedSignatures[index]._id });
			}

			setSelectedSignatures([]);
			setPagination((prev) => ({ ...prev, total: prev.total - selectedSignatures.length }));
		} catch (error) {
			console.error(error);
		}
	}, [selectedSignatures, handleSingleDelete]);

	const handleCreateSignatures = useCallback(async () => {
		try {
			const payload = new FormData();

			if (!newSignature.userId) return message.error('Informe o usuário');
			if (!newSignature.genreId) return message.error('Informe o gênero');
			if (!newSignature.files.length) return message.error('Informe os arquivos');

			setFallback((prev) => ({ ...prev, creatingSignatures: true }));

			payload.append('userId', newSignature.userId);
			payload.append('genreId', newSignature.genreId);

			newSignature.files.forEach((file, i) => payload.append(`file-${i}`, file));

			const {
				data: { signatures: newSignatures },
			} = await SignaturesAPI.store({
				payload,
				onUploadProgress: ({ total, loaded }) => {
					const percentage = Math.floor((loaded * 100) / total);

					setProgress(percentage);
				},
			});

			const selectedUser = users.find((u) => u._id === newSignature.userId);
			const selectedGenre = genres.find((g) => g._id === newSignature.genreId);

			setSignatures((prev) => [
				...prev,
				...newSignatures.map((s) => {
					return { ...s, userId: selectedUser, genreId: selectedGenre };
				}),
			]);

			setTimeout(() => {
				setProgress(null);
				setFallback((prev) => ({ ...prev, creatingSignatures: false }));
				setVisibleModals((prev) => ({ ...prev, addSignature: false }));
			}, 1500);
		} catch (error) {
			console.error(error);
		}
	}, [newSignature, users, genres]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { users },
				} = await UsersAPI.index('active=true');

				const {
					data: { genres },
				} = await GenresAPI.index();

				setUsers(users);
				setGenres(genres);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
			}
		};

		fetchInitialData();
	}, []);

	useEffect(() => {
		const fetchSignatures = async () => {
			try {
				setFallback((fallback) => ({ ...fallback, fetchingSignatures: true }));

				let query = `page=${pagination?.current - 1}&limit=${pagination?.pageSize}&`;

				for (const key in filters) {
					const value = filters[key];

					if (value) {
						query = `${query}&${key}=${value}&`;
					}
				}

				const {
					data: { signatures, total },
				} = await SignaturesAPI.index({ query });

				setSignatures(signatures);
				setPagination((prev) => ({ ...prev, total }));
				setFallback((fallback) => ({ ...fallback, fetchingSignatures: false }));
			} catch (error) {
				console.error(error);
			}
		};

		fetchSignatures();
	}, [filters, pagination.current, pagination.pageSize]); //eslint-disable-line

	useEffect(() => {
		/**
		 * Disparado quando alguma filtragem é realizada
		 * Reseta a página atual para 1
		 * */
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Gerenciar assinaturas' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'PAINEL ADMINISTRATIVO' },
						{ breadcrumbName: 'CONTEÚDOS' },
						{ breadcrumbName: 'GERENCIAR ASSINATURAS' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Assinaturas da rádio</Typography.Title>
					<Button
						type='primary'
						disabled={!filters.userId}
						onClick={() => setVisibleModals({ ...visibleModals, addSignature: true })}
					>
						<FiPlusCircle /> Nova assinatura
					</Button>
				</header>

				<Form.Container layout='30% 30% 40%'>
					<Form.Item label='Filtrar por usuário'>
						<Select
							showSearch
							optionFilterProp='children'
							placeholder='Selecione o usuário'
							value={filters?.userId}
							onChange={(value) => {
								setFilters({ ...filters, userId: value });
								setNewSignature({ ...newSignature, userId: value });
							}}
							filterOption={(input, { props: { _search } }) => {
								const regex = new RegExp(input, 'i');

								return _search.match(regex);
							}}
						>
							{users.map((user) => {
								const { radioName, city, state, email } = user;

								return (
									<Select.Option
										key={user?._id}
										value={user?._id}
										_search={`${radioName}${city}${state}${email}`}
									>
										{radioName} - {city}/{state} ({email})
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>

					<Form.Item label='Status'>
						<Select
							value={filters?.verified}
							placeholder='Selecionar status'
							onChange={(value) => setFilters({ ...filters, verified: value })}
						>
							<Select.Option value={undefined}>Exibir tudo</Select.Option>
							<Select.Option value='0'>Somente pendentes</Select.Option>
							<Select.Option value='1'>Somente aprovados</Select.Option>
							<Select.Option value='2'>Somente negadas</Select.Option>
						</Select>
					</Form.Item>
				</Form.Container>

				<Divider />

				<TableHeader>
					<div className='actions'>
						<span>
							Quantidade: <strong>{pagination?.total}</strong>
						</span>
						<div>
							<Button
								size='small'
								disabled={!selectedSignatures.length}
								type='danger'
								onClick={() => {
									Modal.confirm({
										title: 'Deletar assinaturas selecionadas?',
										type: 'danger',
										content:
											'Todas as assinaturas selecionados serão excluídos e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
										okText: 'Deletar',
										onOk: handleMultiDelete,
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}
							>
								<FiTrash2 /> Deletar selecionados{' '}
								{selectedSignatures.length !== 0 && `(${selectedSignatures.length})`}
							</Button>
						</div>
					</div>

					<PageSizeHandler pagination={pagination} setPagination={setPagination} />
				</TableHeader>

				<Table
					rowKey='_id'
					columns={columns}
					dataSource={signatures}
					loading={fallback?.fetchingSignatures}
					size='middle'
					pagination={{
						...pagination,
						size: 'large',
						onChange: (current) => setPagination({ ...pagination, current }),
					}}
					rowSelection={{
						onChange: (_, selectedRows) => setSelectedSignatures(selectedRows),
					}}
				/>
			</Container>

			<Modal
				destroyOnClose
				title={
					<>
						<Icon style={{ marginRight: 8 }} type='plus-circle' /> Nova assinatura
					</>
				}
				visible={visibleModals?.addSignature}
				okText={
					<>
						<FiUpload /> Fazer upload
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				okButtonProps={{ loading: fallback?.creatingSignatures }}
				cancelButtonProps={{ disabled: fallback?.creatingSignatures }}
				onCancel={() => {
					setNewSignature({ files: [], userId: null });
					setVisibleModals({ ...visibleModals, addElements: false });
				}}
				onOk={handleCreateSignatures}
			>
				<Infos>
					<li>
						<span>Usuário selecionado</span>
						<p>{users.find((user) => user?._id === filters?.userId)?.radioName}</p>
					</li>
				</Infos>

				<Form.Container>
					<Form.Item label='Gênero' style={{ marginBottom: 12 }}>
						<Select
							placeholder='Selecione o gênero'
							value={newSignature.genreId}
							onChange={(value) => setNewSignature({ ...newSignature, genreId: value })}
						>
							{genres.map((genre) => (
								<Select.Option value={genre._id} key={genre._id}>
									{genre.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Form.Container>

				<UploadZone
					id='upload-element'
					label='Clique para selecionar os arquivos'
					icon='paper-clip'
					uploadIcon='customer-service'
					secondaryLabel={
						<p>
							Selecione arquivos com a extensão <strong>MP3</strong> ou <strong>WAV</strong>
						</p>
					}
					inputProps={{
						accept: 'audio/mp3, audio/wav',
						multiple: true,
					}}
					uploads={newSignature?.files}
					onChange={({ target: { files } }) =>
						setNewSignature({ ...newSignature, files: [...files] })
					}
					onRemoveItem={(index) => {
						return setNewSignature({
							...newSignature,
							files: newSignature.files.filter((_, i) => i !== index),
						});
					}}
				/>
			</Modal>

			<Progress
				progress={progress}
				succesTitle='Elementos enviados com sucesso'
				title={
					<Typography.Paragraph>Enviando elementos, por favor aguarde...</Typography.Paragraph>
				}
			/>
		</>
	);
};

export default ManageSignatures;
