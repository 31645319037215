import 'react-h5-audio-player/lib/styles.css';

import styled from 'styled-components';
import ReactAudioPlayer from 'react-h5-audio-player';

export const Container = styled(ReactAudioPlayer)`
	background: var(--primary) !important;
	border-radius: 2px;
	box-shadow: none;

	& .rhap_controls-section {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.rhap_additional-controls {
		display: none;
	}

	button {
		color: #fff;
	}

	button.rhap_repeat-button {
		display: none;
	}

	.rhap_progress-indicator,
	.rhap_volume-indicator {
		color: #fff;
	}

	.rhap_volume-indicator,
	.rhap_volume-bar,
	.rhap_progress-indicator,
	.rhap_progress-bar,
	.rhap_download-progress {
		background: #fff !important;
	}

	.rhap_current-time {
		font-weight: 500;
		color: #fff;
	}

	.rhap_time {
		font-weight: 500;
		color: #fff;
	}
`;

export const Header = styled.div`
	padding: 12px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > svg {
		color: #fff;
		font-size: 35px;
	}

	& > span {
		font-weight: 500;
		color: #fff;
		font-size: 25px;
	}
`;
