import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Button, Input, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { FiArrowRight } from 'react-icons/fi';

import Form from '../../../../../components/Form';
import { states as brStates } from '../../../../../constants';
import { ButtonsContainer, CreateUserOptContainer } from '../styles';
import { useUpsertUser } from '../../../../../contexts/UserUpsert';
import { validateCNPJ, validateCPF, validatePhone } from '../../../../../helpers/validators';

const PersonalInfos = ({ setCurrentStep, isEmailTaken, action }) => {
	const { personalInfos, handleChangePersonalInfo } = useUpsertUser();
	const [errors, setErrors] = useState({ email: isEmailTaken });

	const nextStep = useCallback(async () => {
		try {
			const validationSchema = Yup.object().shape({
				name: Yup.string().required(),
				surname: Yup.string().required(),
				radioName: Yup.string().required(),
				email: Yup.string().email().required(),
				cpf: Yup.string()
					.nullable(true)
					.test('cpf', (v) => validateCPF(v).isValid)
					.transform((v) => validateCPF(v).value),
				cnpj: Yup.string()
					.nullable(true)
					.test('cnpj', (v) => validateCNPJ(v).isValid)
					.transform((v) => validateCNPJ(v).value),
				phone: Yup.string()
					.nullable(true)
					.test('phone', (v) => validatePhone(v).isValid)
					.transform((v) => validatePhone(v).value),
				city: Yup.string().required(),
				state: Yup.string().required(),
			});

			await validationSchema.validate(personalInfos, { abortEarly: false });
			setErrors({});
			setCurrentStep((step) => step + 1);
		} catch (error) {
			setErrors(
				Object.values(error.inner).reduce((err, { path }) => ({ ...err, [path]: true }), {})
			);
		}
	}, [personalInfos, setCurrentStep]);

	return (
		<>
			<Form.Container layout='1fr 1fr 1fr 1fr 1fr 1fr'>
				<Form.Item size={2} label='Nome' error={errors?.name}>
					<Input
						name='name'
						value={personalInfos.name}
						size='large'
						placeholder='Nome do usuário'
						onChange={handleChangePersonalInfo}
					/>
				</Form.Item>
				<Form.Item size={2} label='Sobrenome' error={errors?.surname}>
					<Input
						name='surname'
						value={personalInfos.surname}
						size='large'
						placeholder='Sobrenome do usuário'
						onChange={handleChangePersonalInfo}
					/>
				</Form.Item>
				<Form.Item size={2} label='Nome da Rádio' error={errors?.radioName}>
					<Input
						name='radioName'
						value={personalInfos.radioName}
						size='large'
						placeholder='Nome da rádio'
						onChange={handleChangePersonalInfo}
					/>
				</Form.Item>
				<Form.Item
					size={2}
					label='Email'
					error={errors?.email}
					errorMessage={isEmailTaken ? 'Email já está em uso' : 'Email inválido'}>
					<Input
						name='email'
						value={personalInfos.email}
						size='large'
						placeholder='Email do usuário'
						onChange={handleChangePersonalInfo}
					/>
				</Form.Item>
				<Form.Item optional size={2} label='CPF' error={errors?.cpf}>
					<MaskedInput
						name='cpf'
						value={personalInfos.cpf}
						size='large'
						autoComplete='off'
						mask='000.000.000-00'
						placeholder='___.___.___-__'
						onChange={(e) => handleChangePersonalInfo(e)}
					/>
				</Form.Item>
				<Form.Item optional size={2} label='CNPJ' error={errors?.cnpj}>
					<MaskedInput
						name='cnpj'
						value={personalInfos.cnpj}
						size='large'
						autoComplete='off'
						mask='00.000.000/0000-00'
						placeholder='__.___.___/____-__'
						onChange={handleChangePersonalInfo}
					/>
				</Form.Item>
				<Form.Item optional size={2} label='Telefone' error={errors?.phone}>
					<MaskedInput
						name='phone'
						value={personalInfos.phone}
						size='large'
						autoComplete='off'
						mask='(00) 0 0000-0000'
						placeholder='(__) _ ____-____'
						onChange={handleChangePersonalInfo}
					/>
				</Form.Item>
				<Form.Item size={2} label='Cidade' error={errors?.city}>
					<Input
						name='city'
						value={personalInfos.city}
						size='large'
						placeholder='Cidade do usuário'
						onChange={handleChangePersonalInfo}
					/>
				</Form.Item>
				<Form.Item size={2} label='Estado' error={errors?.state}>
					<Select
						showSearch
						size='large'
						value={personalInfos.state}
						onChange={(value) => handleChangePersonalInfo({ target: { name: 'state', value } })}
						placeholder='Selecione o estado'
						filterOption={(input, { props: { children } }) => {
							return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
						}}>
						{brStates.map(({ label, value }) => (
							<Select.Option key={value} value={value}>
								<CreateUserOptContainer>
									<strong>{value}</strong>
									<small>{label}</small>
								</CreateUserOptContainer>
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Form.Container>

			{action === 'CREATE' && (
				<ButtonsContainer>
					<Button type='primary' size='large' onClick={nextStep}>
						<FiArrowRight />
						Continuar
					</Button>
				</ButtonsContainer>
			)}
		</>
	);
};

export { PersonalInfos };
