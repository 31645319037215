import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const TabContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

export const Actions = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > h4 {
		margin: 0;
	}

	& > div {
		display: flex;
		align-items: center;

		& > button:last-child {
			margin-left: 12px;
		}
	}
`;

export const ExportFormContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	gap: 8px 0;

	& > label {
		margin: 0 !important;
	}
`;

export const DownloadAncor = styled.a`
	width: 0;
	height: 0;
	display: none;
`;

export const highlightStyle = {
	background: '#7bff90',
	color: '#3a3a3a',
};
