import api from '../api';

const BASE_URL = '/api/v2/generation';

const GenerationApi = {
	manual: ({ payload }) => api.post(`${BASE_URL}/manual`, payload),
	nightly: () => api.post(`${BASE_URL}/nightly`), // TODO: Remover
};

export default GenerationApi;
