import api from '../api';

const BASE_URL = 'api/v2/tracks';

const TracksAPI = {
	index: ({ query }) => api.get(`${BASE_URL}?${query}`),
	show: ({ trackId }) => api.get(`${BASE_URL}/${trackId}`),
	store: ({ payload, onUploadProgress }) => api.post(BASE_URL, payload, { onUploadProgress }),
	update: ({ trackId, payload }) => api.put(`${BASE_URL}/${trackId}`, payload),
	modify: ({ trackId, payload }) => api.patch(`${BASE_URL}/${trackId}`, payload),
	destroy: ({ trackId }) => api.delete(`${BASE_URL}/${trackId}`),
	deleteMany: (ids) => api.post(`${BASE_URL}/delete-many`, { ids }),
};

export default TracksAPI;
