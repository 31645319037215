import api from '../api';

const BASE_URL = '/api/v2/elements';
const BASE_URL_CUSTOM = '/api/v2/custom-elements';
const headers = { 'Content-Type': 'multipart/form-data' };

const ElementsAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	show: ({ programId }) => api.get(`${BASE_URL}/${programId}`),
	store: ({ payload, onUploadProgress }) => {
		return api.post(BASE_URL, payload, { headers, onUploadProgress });
	},
	destroy: ({ elementId }) => api.delete(`${BASE_URL}/${elementId}`),
	custom: {
		index: ({ query = '' } = {}) => api.get(`${BASE_URL_CUSTOM}?${query}`),
		show: ({ elementId }) => api.get(`${BASE_URL_CUSTOM}/${elementId}`),
		store: ({ payload, onUploadProgress }) => {
			return api.post(BASE_URL_CUSTOM, payload, { onUploadProgress });
		},
		update: (elementId, payload) => api.put(`${BASE_URL_CUSTOM}/${elementId}`, payload),
		destroy: (elementId) => api.delete(`${BASE_URL_CUSTOM}/${elementId}`),
	},
};

export default ElementsAPI;
