import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
	PageHeader,
	Table,
	Card,
	message,
	Typography,
	Tag,
	Dropdown,
	Menu,
	Icon,
	Form,
	Divider,
	Modal,
	Checkbox,
	Button,
	Select,
} from 'antd';

import Meta from '../../../components/Meta';
import Fallback from '../../../components/Fallback';
import { Container, ExportFormContainer, DownloadAncor } from './styles';

import ContactRequestsAPI from '../../../services/sdks/contactRequest';
import { format } from 'date-fns';
import ExportsAPI from '../../../services/sdks/exports';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel do Administrador' },
		{ breadcrumbName: 'Requisições de Contato' },
	],
	style: { marginBottom: 12 },
};

const ContactRequests = () => {
	const downloadRef = useRef();

	const [fallback, setFallback] = useState({ initialData: true });
	const [requests, setRequests] = useState([]);
	const [showExportModal, setShowExportModal] = useState(false);
	const [exportStatus, setExportStatus] = useState('ALL');
	const [exportFields, setExportFields] = useState({
		origin: { active: false, label: 'Origem' },
		createdAt: { active: false, label: 'Data' },
		status: { active: false, label: 'Status' },
		phone: { active: false, label: 'Whatsapp' },
		email: { active: false, label: 'Email' },
	});

	const columns = [
		{
			key: 'id',
			title: 'ID',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					5
				)}...`}</Typography.Text>
			),
		},
		{
			key: 'origin',
			dataIndex: 'origin',
			title: 'Origem',
			render: (o) => (o === 'TALK-PLAY' ? 'Página da TalkPlay' : 'Página da TalkRádio'),
		},
		{
			key: 'createdAt',
			dataIndex: 'createdAt',
			title: 'Data',
			render: (d) => format(new Date(d), 'dd/MM/yyyy HH:mm'),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => <Tag color={parseStatus(status).color}>{parseStatus(status).label}</Tag>,
		},
		{
			key: 'phone',
			dataIndex: 'phone',
			title: 'Whatsapp',
		},
		{
			key: 'email',
			dataIndex: 'email',
			title: 'Email',
		},
		{
			title: 'Ações',
			key: 'actions',
			align: 'center',
			render: (request) => (
				<Dropdown
					overlay={
						<Menu>
							{request.status !== 'SUCCESS' && (
								<Menu.Item key='success' onClick={() => handleUpdateStatus('SUCCESS', request._id)}>
									<Icon type='check-circle' style={{ marginRight: 6 }} />
									Marcar como <strong style={{ marginLeft: 5 }}>sucesso</strong>
								</Menu.Item>
							)}
							{request.status !== 'IN_PROGRESS' && (
								<Menu.Item
									key='in-progress'
									onClick={() => handleUpdateStatus('IN_PROGRESS', request._id)}>
									<Icon type='info-circle' style={{ marginRight: 6 }} />
									Marcar como <strong style={{ marginLeft: 5 }}>em progresso</strong>
								</Menu.Item>
							)}
							{request.status !== 'FAILED' && (
								<Menu.Item key='failed' onClick={() => handleUpdateStatus('FAILED', request._id)}>
									<Icon type='close-circle' style={{ marginRight: 6 }} />
									Marcar como <strong style={{ marginLeft: 5 }}>falhou</strong>
								</Menu.Item>
							)}
							{request.status !== 'PENDING' && (
								<Menu.Item key='pending' onClick={() => handleUpdateStatus('PENDING', request._id)}>
									<Icon type='minus-circle' style={{ marginRight: 6 }} />
									Marcar como <strong style={{ marginLeft: 5 }}>pendente</strong>
								</Menu.Item>
							)}
						</Menu>
					}>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 12 }} type='more' />
				</Dropdown>
			),
		},
	];

	const parseStatus = useCallback((status) => {
		switch (status) {
			case 'PENDING':
				return { color: 'gold', label: 'Pendente' };
			case 'SUCCESS':
				return { color: 'green', label: 'Sucesso' };
			case 'IN_PROGRESS':
				return { color: 'blue', label: 'Em Conversação' };
			default:
				return { color: 'red', label: 'Falhou' };
		}
	}, []);

	const handleExport = useCallback(async () => {
		try {
			const fields = Object.entries(exportFields)
				.filter(([, { active }]) => active === true)
				.map(([key, { label }]) => {
					return { key, label };
				});

			if (!fields.length) {
				return message.error('Selecione no mínimo um dos campos');
			}

			setFallback('exporting');

			const exportData =
				exportStatus === 'ALL' ? requests : requests.filter((r) => r.status === exportStatus);

			const { data } = await ExportsAPI.post({
				worksheetName: 'Requisições de Contato',
				data: exportData.map((request) => ({
					...request,
					createdAt: format(new Date(request.createdAt), 'dd/MM/yyyy HH:mm'),
					status: parseStatus(request.status).label,
					origin: request.origin === 'TALK-PLAY' ? 'Site TalkPlay' : 'Site Talk Rádio',
				})),
				fields,
			});

			const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
			const BLOB = new Blob([data], { type });
			const downloadLink = window.URL.createObjectURL(BLOB);

			downloadRef.current.href = downloadLink;
			downloadRef.current.download = 'Requisições de Contato';
			downloadRef.current.click();

			setFallback(null);
		} catch (error) {
			console.error(error);
		}
	}, [requests, exportFields, parseStatus, exportStatus]);

	const handleUpdateStatus = useCallback(async (status, contactRequestId) => {
		try {
			await ContactRequestsAPI.modify({ status }, contactRequestId);
			setRequests((requests) =>
				requests.map((request) =>
					request._id === contactRequestId ? { ...request, status } : request
				)
			);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, []);

	const handleChangeExportField = useCallback((key, value) => {
		setExportFields((exportFields) => ({
			...exportFields,
			[key]: { ...exportFields[key], active: value },
		}));
	}, []);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { contactRequests },
				} = await ContactRequestsAPI.index();

				setRequests(contactRequests);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro, tente novamente');
			}
		};

		fetchInitialData();
	}, []);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor, aguarde' />;
	}

	return (
		<>
			<DownloadAncor ref={downloadRef} />

			<Meta title='Requisições de Contato' />

			<PageHeader title='Requisições de Contato' breadcrumb={breadcrumb} />

			<Container>
				<Card style={{ marginTop: 16 }}>
					<Table
						title={() => (
							<div className='export-container'>
								<Button onClick={() => setShowExportModal(true)} icon='file-excel' type='primary'>
									Exportar
								</Button>
							</div>
						)}
						columns={columns}
						dataSource={requests}
						size='middle'
						pagination={{ size: 'large' }}
						rowKey='_id'
						style={{ border: 'none' }}
					/>
				</Card>
			</Container>

			<Modal
				title='Exportar'
				visible={showExportModal}
				onCancel={() => setShowExportModal(false)}
				okText='Exportar'
				onOk={handleExport}
				okButtonProps={{ icon: 'download', loading: fallback === 'exporting' }}
				cancelButtonProps={{
					icon: 'close-circle',
					disabled: fallback === 'exporting',
				}}>
				<Form>
					<Form.Item label='Exportar por Status'>
						<Select
							showSearch
							placeholder='Selecione o status'
							value={exportStatus}
							onChange={(value) => setExportStatus(value)}>
							<Select.Option key='ALL'>Todos</Select.Option>
							<Select.Option key='IN_PROGRESS'>Em Conversação</Select.Option>
							<Select.Option key='SUCCESS'>Com Sucesso</Select.Option>
							<Select.Option key='PENDING'>Pendentes</Select.Option>
						</Select>
					</Form.Item>

					<Typography.Paragraph>
						Selecione os campos que deseja exibir na planilha
					</Typography.Paragraph>

					<Divider />

					<ExportFormContainer>
						<Checkbox
							checked={exportFields.origin.active}
							onChange={({ target: { checked } }) => {
								handleChangeExportField('origin', checked);
							}}>
							Origem
						</Checkbox>

						<Checkbox
							checked={exportFields.status.active}
							onChange={({ target: { checked } }) => {
								handleChangeExportField('status', checked);
							}}>
							Status
						</Checkbox>

						<Checkbox
							checked={exportFields.createdAt.active}
							onChange={({ target: { checked } }) => {
								handleChangeExportField('createdAt', checked);
							}}>
							Data
						</Checkbox>

						<Checkbox
							checked={exportFields.phone.active}
							onChange={({ target: { checked } }) => {
								handleChangeExportField('phone', checked);
							}}>
							Whatsapp
						</Checkbox>

						<Checkbox
							checked={exportFields.email.active}
							onChange={({ target: { checked } }) => {
								handleChangeExportField('email', checked);
							}}>
							Email
						</Checkbox>
					</ExportFormContainer>
				</Form>
			</Modal>
		</>
	);
};

export default ContactRequests;
