import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
`;

export const Badge = styled.div`
	width: 5px;
	min-width: 5px;
	height: ${({ badgeHeight }) => badgeHeight ? `${badgeHeight}px` : '16px'};
	border-radius: 4px;
	margin-right: 8px;
	background: ${({ color }) => color};
`;
