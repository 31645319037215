import styled, { css } from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const StepsContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 28% auto;
	gap: 12px;
`;

export const StepContentContainer = styled.div`
	width: 100%;
	height: 100%;
`;

export const CreateUserOptContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 8px;

	& > strong {
		font-weight: 500;
		font-size: 15px;
	}

	& > small {
		font-size: 12px;
	}
`;

export const PermissionsContainer = styled.ul`
	width: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const ModulesContainer = styled.ul`
	width: 100%;
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 24px 32px;
`;

export const ButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	margin-top: 12px;
`;

export const PermissionItemDescription = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0;

	& > strong {
		font-weight: 500;
		font-size: 15px;
	}

	& > span {
		font-size: 12px;
		opacity: 0.8;
	}
`;

export const PermissionItem = styled.li`
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 4px;
	padding: 12px;
	cursor: ${({ hideHover }) => (hideHover ? 'default' : 'pointer')};

	&:hover {
		background: ${({ hideHover }) => (hideHover ? 'transparent' : '#f5f5f5')};
	}
`;

export const ModuleItem = styled.li`
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const ModuleSubitems = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-left: 32px;

	& > label {
		margin: 0 !important;
	}
`;

export const NetworkContainer = styled.div`
	width: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: flex-end;
	padding: 0 12px;
	max-height: 500px;
	overflow-y: auto;
`;

export const NetworkHeader = styled.header`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 12px;
`;

export const NetworkSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;

	& header {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 12px;
		background: #fff;
		z-index: 20;

		& > h2 {
			margin: 0;
		}
	}
`;

export const NetworkUsersList = styled.ul`
	width: 100%;
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	flex-direction: column;
	gap: 8px;
`;

export const NetworkItem = styled.li`
	width: 100%;
	margin: 0;
	padding: 8px 16px;
	display: flex;
	gap: 16px;
	border: 1px solid #dedede;
	border-radius: var(--border-radius-base);
	align-items: center;
	cursor: pointer;

	&:hover {
		background: #f9f9f9;
	}

	& > div {
		display: flex;
		flex-direction: column;

		& > strong {
			font-weight: 500;
			font-size: 14px;
		}

		& > span {
			margin-top: -4px;
			font-size: 12px;
			opacity: 0.8;
		}
	}

	${({ checked }) =>
		checked &&
		css`
			background: var(--primary);

			&:hover {
				background: var(--primary-opacity-85);
			}

			& * {
				color: white;
			}
		`}
`;
