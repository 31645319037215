import React from 'react';
import { Typography, Divider, Alert } from 'antd';

import Meta from '../../../../components/Meta';
import HelpButtons from '../../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../../assets/images/tutorials/bancomusicalradio/img-01.png';
import img02 from '../../../../assets/images/tutorials/bancomusicalradio/img-02.png';
import img03 from '../../../../assets/images/tutorials/bancomusicalradio/img-03.png';


const { Title, Paragraph } = Typography;

const MRadio= () => (
	<Container>
		<Meta title='Ajuda - Baanco Musical Rádio' />

		<Title>Banco Musical Rádio</Title>

		<Paragraph>
		Na TalkPlay, entendemos a importância de uma programação musical que reflita a identidade única de cada estação de rádio. É por isso que oferecemos a você a flexibilidade de enviar suas próprias músicas para a plataforma, permitindo que seu programa seja fiel ao que você deseja transmitir. Isso é particularmente útil para rádios com uma programação segmentada e local.		</Paragraph>
		<Paragraph>
		No entanto, é importante notar que, embora você possa enviar suas músicas, a TalkPlay retém o controle sobre em qual bloco ou posição elas serão executadas. A seleção e a ordem das músicas em cada programa são determinadas autonomamente pela IA da TalkPlay.		</Paragraph>
		<Paragraph>
		A incidência de suas músicas na programação depende diretamente da quantidade de músicas que você enviar para a sua pasta musical. Por exemplo, se você tem um programa de uma hora com três blocos e deseja que as músicas sejam executadas todos os dias, sua pasta musical deve conter apenas três músicas. Mesmo assim, a TalkPlay decidirá em qual bloco inserir cada música para cada programa.		</Paragraph>
		<Paragraph>
		Para pastas musicais com muitas músicas, também é possível definir a incidência na TalkPlay. A incidência controla o intervalo de dias em que uma mesma música pode aparecer na programação semanal, com opções que variam de 1 a 7 dias. Esse controle é ajustado na configuração de Modelos Semanais de Playlists, oferecendo a você uma maneira de personalizar a repetição de músicas na sua programação.		</Paragraph>
		<Paragraph>
			Para acessar, navegue até <i><strong>Banco Musical</strong></i> no menu lateral:
		</Paragraph>

		<ImgContainer>
			<img src={img01} alt='Banco Musical' />
		</ImgContainer>

		<Paragraph>
			Para enviar as músicas, selecione <i> Categoria</i> escolha a sua pasta:
		</Paragraph>

		<ImgContainer>
			<img src={img02} alt='Banco Musical' />
		</ImgContainer>

		<Paragraph>
		Para facilitar a gestão de músicas, especialmente lançamentos, na TalkPlay, você pode utilizar a funcionalidade de troca automática de músicas entre pastas. 
		</Paragraph>
		
		<Paragraph>
		1 - Seleção de Pastas e Configuração de Tempo: Primeiro, selecione a pasta na qual deseja adicionar suas músicas. Ao fazer isso, defina o parâmetro "<i>O tempo para trocar automaticamente em meses</i>" para cada pasta. Este tempo determina após quantos meses as músicas enviadas para uma pasta inicial serão automaticamente movidas para a próxima pasta.		</Paragraph>
		<Paragraph>
		2 - Envio de Músicas: Após definir o tempo, envie suas músicas para a pasta selecionada. Esta etapa é crucial, especialmente para músicas de lançamento que você deseja que sejam executadas mais frequentemente por um período determinado.		</Paragraph>
		<Paragraph>
		3 - Automatização do Processo: Uma vez que o tempo definido é atingido, a TalkPlay automaticamente move as músicas da pasta inicial para a próxima pasta. Isso é útil para programas que incluem músicas de lançamento, permitindo que elas sejam destacadas na programação por um tempo limitado antes de serem movidas para outra categoria.		</Paragraph>
		
		
		
		<ImgContainer>
			<img src={img03} alt='Banco Musical' />
		</ImgContainer>

		<Paragraph>
		Essa funcionalidade é particularmente valiosa para rádios que desejam destacar novos lançamentos ou manter uma programação musical atualizada e dinâmica. Com a troca automática de música, a TalkPlay ajuda a manter sua programação musical fresca e alinhada com as tendências atuais, sem a necessidade de intervenção manual constante​​.		</Paragraph>

		<Alert
			showIcon
			type='warning'
			description='Agora vamos aprender como enviar suas músicas para a TalkPlay.'
		/>

		<HelpButtons
			prev={{
				label: 'Banco Musical TalkPlay',
				to: '/help/bancomusical',
			}}
			next={{ label: 'Playlists', to: '/help/programs/playlists' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 05/02/2024</Paragraph>
	</Container>
);

export default MRadio;
