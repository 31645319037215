import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Badge,
	Breadcrumb,
	Button,
	Divider,
	Empty,
	Input,
	Modal,
	Pagination,
	Select,
	Typography,
	message,
} from 'antd';
import { connect } from 'react-redux';
import { format } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import Form from '../../../../components/Form';
import RadioVignetteConfigsAPI from '../../../../services/sdks/radio-vignette-configs';
import {
	Container,
	ConfigItem,
	ConfigsContainer,
	ConfigItemSchedule,
	ConfigItemActions,
	DateInputsContainer,
	PaginationContainer,
} from './styles';
import { FiPlusCircle } from 'react-icons/fi';

const breadcrumbs = [
	{ breadcrumbName: 'INÍCIO' },
	{ breadcrumbName: 'VINHETAS DA RÁDIO' },
	{ breadcrumbName: 'CONFIGURAR CRONOGRAMAS' },
];

const defaultFallback = {
	fetchingConfigs: true,
	creatingConfig: false,
	updatingConfig: false,
	deletingConfig: false,
};

const defaultHandlingConfig = {
	name: '',
	color: '',
	startsAt: { day: undefined, month: undefined },
	endsAt: { day: undefined, month: undefined },
};

const RadioVignettesConfigs = ({ user }) => {
	const [configs, setConfigs] = useState([]);
	const [totalConfigs, setTotalConfigs] = useState(0);
	const [handlingConfig, setHandlingConfig] = useState(defaultHandlingConfig);
	const [fallback, setFallback] = useState(defaultFallback);
	const [showUpsertModal, setShowUpsertModal] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);

	const hasNameError = useMemo(() => {
		return (
			handlingConfig.name &&
			configs.some((c) => {
				return (
					c.name.toUpperCase() === handlingConfig.name.toUpperCase() && c._id !== handlingConfig._id
				);
			})
		);
	}, [configs, handlingConfig]);

	const isFormValid = useMemo(() => {
		if (hasNameError) {
			return false;
		}

		const { startsAt, endsAt, color, name } = handlingConfig;

		return ![startsAt.day, startsAt.month, endsAt.day, endsAt.month, name, color].some((v) =>
			[undefined, ''].includes(v)
		);
	}, [handlingConfig, hasNameError]);

	useEffect(() => {
		const fetchConfigs = async () => {
			setFallback((prev) => ({ ...prev, fetchingConfigs: true }));

			try {
				const response = await RadioVignetteConfigsAPI.list(user?._id, pageNumber, 10);
				setConfigs(response.data.radioVignetteConfigs);
				setTotalConfigs(response.data.total);
				setFallback((prev) => ({ ...prev, fetchingConfigs: false }));
			} catch (error) {
				message.error('Houve um erro ao carregar as configurações de cronograma');
			}
		};

		fetchConfigs();
	}, [user, pageNumber]);

	const handleDeleteConfig = useCallback(async (configId) => {
		setFallback((prev) => ({ ...prev, deletingConfig: true }));

		try {
			await RadioVignetteConfigsAPI.delete([configId]);
			setConfigs((prev) => prev.filter((c) => c._id !== configId));
			message.success('O cronograma foi excluído com sucesso');
		} catch (error) {
			message.error('Houve um erro ao excluir a configuração de cronograma');
		}

		setFallback((prev) => ({ ...prev, deletingConfig: false }));
	}, []);

	const handleUpdateConfig = useCallback(async () => {
		setFallback((prev) => ({ ...prev, updatingConfig: true }));

		try {
			const { _id: configId, ...rest } = handlingConfig;
			await RadioVignetteConfigsAPI.update(configId, rest);

			setConfigs((prev) =>
				prev.map((c) => {
					return c._id === configId ? { ...c, ...handlingConfig } : c;
				})
			);
			setHandlingConfig(defaultHandlingConfig);
			setShowUpsertModal(false);
			message.success('O cronograma foi atualizado com sucesso');
		} catch (error) {
			message.error('Houve um erro ao atualizar a configuração de cronograma');
		}

		setFallback((prev) => ({ ...prev, updatingConfig: false }));
	}, [handlingConfig]);

	const handleCreateConfig = useCallback(async () => {
		setFallback((prev) => ({ ...prev, creatingConfig: true }));

		try {
			const response = await RadioVignetteConfigsAPI.create({
				...handlingConfig,
				userId: user?._id,
			});

			setConfigs((prev) => [...prev, response.data.radioVignetteConfig]);
			setHandlingConfig(defaultHandlingConfig);
			setShowUpsertModal(false);
			message.success('O cronograma foi criado com sucesso');
		} catch (error) {
			message.error('Houve um erro ao criar a configuração de cronograma');
		}

		setFallback((prev) => ({ ...prev, creatingConfig: false }));
	}, [handlingConfig, user]);

	const getScheduleDayAndMonth = (scheduleConfig) => {
		const date = new Date().setMonth(Number(scheduleConfig.month));

		return {
			day: String(scheduleConfig.day).padStart(2, '0'),
			month: format(date, 'MMMM', { locale: ptBR }),
		};
	};

	const renderMonths = () => {
		const months = new Array(12).fill(null).map((_, index) => ({
			month: index,
			date: new Date().setMonth(index),
		}));

		return months.map(({ date, month }) => (
			<Select.Option key={month} value={month}>
				<span style={{ textTransform: 'capitalize' }}>
					{format(date, 'MMMM', { locale: ptBR })}
				</span>
			</Select.Option>
		));
	};

	const getDaysInMonth = (month) => {
		const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12];
		const monthsWith30Days = [4, 6, 9, 11];

		if (monthsWith31Days.includes(month + 1)) {
			return 31;
		} else if (monthsWith30Days.includes(month + 1)) {
			return 30;
		} else {
			return 28;
		}
	};

	const renderMonthDays = (month) => {
		const days = new Array(getDaysInMonth(month)).fill(null).map((_, index) => ({
			value: index + 1,
			label: `Dia ${String(index + 1).padStart(2, '0')}`,
		}));

		return days.map(({ label, value }) => (
			<Select.Option key={value} value={value}>
				{label}
			</Select.Option>
		));
	};

	if (fallback.fetchingConfigs) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Configurar Cronogramas' />

			<Container>
				<Breadcrumb style={{ marginBottom: 12 }} separator='>' routes={breadcrumbs} />

				<header>
					<Typography.Title level={2}>Configurar Cronogramas</Typography.Title>
					<Button
						type='primary'
						onClick={() => {
							setHandlingConfig(defaultHandlingConfig);
							setShowUpsertModal(true);
						}}>
						<FiPlusCircle />
						Novo Cronograma
					</Button>
				</header>

				<Divider />

				{configs.length ? (
					<>
						<ConfigsContainer>
							{configs.map((config) => {
								const startsAt = getScheduleDayAndMonth(config.startsAt);
								const endsAt = getScheduleDayAndMonth(config.endsAt);

								return (
									<ConfigItem key={config._id} color={config.color}>
										<header>
											<div className='name-container'>
												{/* <ConfigItemColor color={config.color} /> */}
												<strong>{config.name}</strong>
											</div>

											<div className='status-container'>
												<Badge status={config.isActive ? 'success' : 'default'} />
												<small>
													{config.isActive
														? 'Vinhetas sendo transmitidas'
														: 'Vinhetas não estão no ar'}
												</small>
											</div>
										</header>

										<ConfigItemSchedule>
											<div>
												<span>Inicia em</span>
												<strong>
													{startsAt.day} de <span>{startsAt.month}</span>
												</strong>
											</div>

											<div>
												<span>Termina em</span>
												<strong>
													{endsAt.day} de <span>{endsAt.month}</span>
												</strong>
											</div>

											<div style={{ marginTop: 4 }}>
												<span>Duração: </span>
												<strong>{config.duration} dias</strong>
											</div>
										</ConfigItemSchedule>

										<ConfigItemActions>
											<Button
												type='danger'
												size='small'
												onClick={() => {
													Modal.confirm({
														title: `Excluir Cronograma?`,
														icon: 'exclamation-circle',
														content:
															'Este cronograma e todas as vinhetas associadas serão excluídas e removidas de todos os seus modelos. Você tem certeza de que deseja prosseguir?',
														okText: 'Confirmar Exclusão',
														onOk: () => handleDeleteConfig(config._id),
														okButtonProps: {
															icon: 'delete',
															type: 'danger',
															loading: fallback?.deletingConfig,
														},
														cancelText: 'Cancelar',
														cancelButtonProps: {
															icon: 'close-circle',
															disabled: fallback?.deletingConfig,
														},
													});
												}}>
												Excluir
											</Button>
											<Button
												type='ghost'
												size='small'
												onClick={() => {
													setHandlingConfig(config);
													setShowUpsertModal(true);
												}}>
												Editar
											</Button>
										</ConfigItemActions>
									</ConfigItem>
								);
							})}
						</ConfigsContainer>

						<PaginationContainer>
							<Pagination
								hideOnSinglePage
								defaultCurrent={pageNumber}
								onChange={(page) => setPageNumber(page)}
								total={totalConfigs}
							/>
						</PaginationContainer>
					</>
				) : (
					<Empty
						description='Você ainda não possui cronogramas criados'
						children={
							<Button
								type='primary'
								onClick={() => {
									setHandlingConfig(defaultHandlingConfig);
									setShowUpsertModal(true);
								}}>
								<FiPlusCircle />
								Criar Cronograma
							</Button>
						}
					/>
				)}
			</Container>

			<Modal
				onCancel={() => {
					setHandlingConfig(defaultHandlingConfig);
					setShowUpsertModal(false);
				}}
				okText={handlingConfig?._id ? 'Atualizar Cronograma' : 'Criar Cronograma'}
				onOk={() => (handlingConfig?._id ? handleUpdateConfig() : handleCreateConfig())}
				visible={showUpsertModal}
				closable={!(fallback.updatingConfig || fallback.creatingConfig)}
				okButtonProps={{
					disabled: !isFormValid,
					loading: fallback.updatingConfig || fallback.creatingConfig,
				}}
				cancelButtonProps={{ disabled: fallback.updatingConfig || fallback.creatingConfig }}
				title={handlingConfig?._id ? 'Editar Cronograma' : 'Novo Cronograma'}>
				<Form.Container>
					<Form.Item
						label='Nome'
						help={hasNameError ? 'Já existe um cronograma cadastrado com esse nome' : null}
						style={{ marginBottom: hasNameError ? 16 : 0 }}>
						<Input
							type='text'
							placeholder='Vinhetas de Natal, Aniversário da Rádio...'
							value={handlingConfig.name}
							onChange={(e) => setHandlingConfig({ ...handlingConfig, name: e.target.value })}
						/>
					</Form.Item>

					<Form.Item label='Cor' help='Isso ajudará a identificar as vinhetas no modelo'>
						<Input
							type='color'
							placeholder='Selecione uma cor para identificar o cronograma'
							value={handlingConfig.color}
							onChange={(e) => setHandlingConfig({ ...handlingConfig, color: e.target.value })}
						/>
					</Form.Item>

					<Form.Item
						style={{ marginTop: 16 }}
						label='Início'
						help='Indica o dia e mês em que as vinhetas começarão à ir ao ar'>
						<DateInputsContainer>
							<Select
								value={handlingConfig.startsAt.month}
								placeholder='Mês'
								onChange={(month) =>
									setHandlingConfig({ ...handlingConfig, startsAt: { month, day: undefined } })
								}>
								{renderMonths()}
							</Select>
							<Select
								value={handlingConfig.startsAt.day}
								onChange={(day) =>
									setHandlingConfig({
										...handlingConfig,
										startsAt: { ...handlingConfig.startsAt, day },
									})
								}
								disabled={handlingConfig.startsAt.month === undefined}
								placeholder='Dia'>
								{renderMonthDays(handlingConfig.startsAt.month)}
							</Select>
						</DateInputsContainer>
					</Form.Item>

					<Form.Item
						style={{ marginTop: 16 }}
						label='Fim'
						help='Indica o dia e mês em que as vinhetas pararão de ir ao ar'>
						<DateInputsContainer>
							<Select
								value={handlingConfig.endsAt.month}
								placeholder='Mês'
								onChange={(month) =>
									setHandlingConfig({ ...handlingConfig, endsAt: { month, day: undefined } })
								}>
								{renderMonths()}
							</Select>
							<Select
								value={handlingConfig.endsAt.day}
								onChange={(day) =>
									setHandlingConfig({
										...handlingConfig,
										endsAt: { ...handlingConfig.endsAt, day },
									})
								}
								disabled={handlingConfig.endsAt.month === undefined}
								placeholder='Dia'>
								{renderMonthDays(handlingConfig.endsAt.month)}
							</Select>
						</DateInputsContainer>
					</Form.Item>
				</Form.Container>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(RadioVignettesConfigs);
