import React, { useState, useEffect, useCallback } from 'react';
import { getHours, getDay, format, parseISO, differenceInMinutes } from 'date-fns';
import { Card, Icon, Tooltip, List, Progress, Typography } from 'antd';
import { ResponsiveContainer, BarChart, YAxis, Bar, Tooltip as ReTooltip } from 'recharts';

import Meta from '../../../../components/Meta';
import {
	Container,
	FlexHeader,
	ChartFooter,
	RealtimeGenerationContainer,
	ListWrapper,
	TooltipContainer,
	AutoEpsProgressContainer,
} from './styles';

import StatisticsAPI from '../../../../services/sdks/statistics';

const JOB_PERIOD = [19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6];

const Statistics = () => {
	const [isJobPeriod, setIsJobPeriod] = useState(() => {
		return JOB_PERIOD.includes(getHours(new Date()));
	});

	const [realtimeAutoGen, setRealtimeAutoGen] = useState({
		percentCompleted: 0,
		finish: null,
		generatedEps: [],
	});

	const [realtimeGen, setRealtimeGen] = useState({
		amount: 0,
		history: Array.from({ length: 20 }).fill({ value: 0.1 }),
		currentList: [],
	});

	const fetchManualGenerations = useCallback(async () => {
		try {
			const statistics = [];
			const { data } = await StatisticsAPI.index({ query: 'type=REAL_TIME_GENERATION' });

			for (let index = 0; index < data.statistics.length; index++) {
				const statistic = data.statistics[index];

				if (statistic?.data?.start) {
					const start = parseISO(statistic?.data?.start);
					const diff = differenceInMinutes(new Date(), start);

					if (diff >= 45) {
						await StatisticsAPI.destroy({ statisticId: statistic?._id });
					} else {
						statistics.push(statistic);
					}
				}
			}

			setRealtimeGen((prev) => {
				const { history } = prev;

				if (history.length === 20) {
					history.shift();
				}

				return {
					amount: statistics.length,
					history: [...history, { value: statistics.length }],
					currentList: statistics.map((s, index) => ({
						index,
						program: s?.data?.programName,
						user: s?.user?.radioName,
						start: format(new Date(s.data.start), 'HH:mm'),
					})),
				};
			});
		} catch (error) {
			console.error(error);
		}
	}, []);

	const fetchNightlyGeneration = useCallback(async () => {
		try {
			const hoursNow = getHours(new Date());

			if (!JOB_PERIOD.includes(hoursNow)) {
				return setIsJobPeriod(false);
			}

			setIsJobPeriod(true);

			const {
				data: { statistics },
			} = await StatisticsAPI.index({ query: 'type=REAL_TIME_AUTO_GENERATION' });

			const lastNumber = statistics.find((s) => {
				return s?.data?.number === statistics?.length;
			})?.data?.number;

			const lastStatistic = statistics[lastNumber - 1];
			const { generatedEps, percentCompleted, finish } = lastStatistic?.data;

			setRealtimeAutoGen({
				generatedEps,
				finish,
				percentCompleted: Math.floor(percentCompleted),
			});
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		fetchManualGenerations();
		fetchNightlyGeneration();

		const interval = setInterval(() => {
			fetchManualGenerations();
			fetchNightlyGeneration();
		}, 20000);

		return () => clearInterval(interval);
	}, [fetchManualGenerations, fetchNightlyGeneration]);

	const CustomTooltip = ({ active, payload, label }) => {
		label = Number(label);

		if (isNaN(label)) {
			return null;
		}

		label = Math.abs(label - 19) * 20;
		label = `Qtd sendo gerada ${label} segundos atrás`;

		if (active && payload && payload.length) {
			return (
				<TooltipContainer>
					<Icon type='exclamation-circle' />
					<span>{label}</span>
					<strong>{payload[0].value <= 0.1 ? 'N/A' : `${payload[0].value} episódios`}</strong>
				</TooltipContainer>
			);
		}

		return null;
	};

	return (
		<Container>
			<Meta title='Painel de monitoramento de gerações' />

			<RealtimeGenerationContainer>
				<Card
					style={{ flex: 1.2 }}
					title={
						<FlexHeader>
							<span>
								<Icon style={{ marginRight: 8 }} type='play-square' /> Histórico de geração
							</span>
							<Tooltip title='Exibe a quantidade recente de programas sendo gerados'>
								<Icon type='question-circle' style={{ cursor: 'pointer' }} />
							</Tooltip>
						</FlexHeader>
					}
				>
					<div className='chart-wrapper'>
						<ResponsiveContainer width='100%' height={250}>
							<BarChart data={realtimeGen?.history}>
								<YAxis type='number' domain={[0, 50]} hide />
								<ReTooltip content={<CustomTooltip />} />
								<Bar dataKey='value' fill='#8884d8' />
							</BarChart>
						</ResponsiveContainer>
						<ChartFooter>
							<span>
								Episódios sendo gerados nesse momento
								<strong>{realtimeGen?.amount}</strong>
							</span>
						</ChartFooter>
					</div>
				</Card>
				<Card
					style={{ flex: 0.8 }}
					title={
						<>
							<FlexHeader>
								<span>
									<Icon style={{ marginRight: 8 }} type='ordered-list' /> Lista de programas
								</span>
								<Tooltip
									placement='left'
									title='Lista dos programas que estão sendo gerados nesse momento'
								>
									<Icon type='question-circle' style={{ cursor: 'pointer' }} />
								</Tooltip>
							</FlexHeader>
						</>
					}
				>
					<ListWrapper>
						<List
							bordered
							dataSource={realtimeGen?.currentList}
							renderItem={(item, i) => (
								<List.Item key={i}>
									<List.Item.Meta
										title={item?.program}
										description={
											<>
												Sendo gerado por <strong>{item?.user}</strong> desde às {item?.start}
											</>
										}
									/>
								</List.Item>
							)}
						/>
					</ListWrapper>
				</Card>
			</RealtimeGenerationContainer>

			<Card
				title={
					<>
						<Icon type='setting' style={{ marginRight: 8 }} /> Geração de epidódios automática
					</>
				}
			>
				{isJobPeriod ? (
					<AutoEpsProgressContainer>
						{realtimeAutoGen?.percentCompleted !== 100 ? (
							<Typography.Title level={4}>
								<Icon className='icon' type='setting' spin /> Geração em andamento...
							</Typography.Title>
						) : (
							<Typography.Title level={4}>
								<Icon className='icon icon-success' type='check-circle' /> Geração concluída
							</Typography.Title>
						)}
						{realtimeAutoGen?.percentCompleted !== 100 ? (
							<Typography.Text>
								Produzindo episódio do programa{' '}
								{realtimeAutoGen?.generatedEps[realtimeAutoGen?.generatedEps.length - 1]?.program} -{' '}
								{realtimeAutoGen?.generatedEps[realtimeAutoGen?.generatedEps.length - 1]?.date}
							</Typography.Text>
						) : (
							<Typography.Text>
								Geração finalizada às {format(parseISO(realtimeAutoGen?.finish), 'HH:mm:ss')}.
							</Typography.Text>
						)}
						<Progress percent={realtimeAutoGen?.percentCompleted} />
					</AutoEpsProgressContainer>
				) : (
					<AutoEpsProgressContainer>
						<Typography.Title level={4}>
							<Icon className='icon' type='exclamation-circle' />
							{[5, 6].includes(getDay(new Date()))
								? 'Geração não contecerá hoje'
								: 'Fora do horário'}
						</Typography.Title>
						<Typography.Text>
							{![5, 6].includes(getDay(new Date())) &&
								'A geração automática dos episódios irá iniciar às 19:00'}
						</Typography.Text>
					</AutoEpsProgressContainer>
				)}
			</Card>
		</Container>
	);
};

export default Statistics;
