import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const SearchContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 16px;
`;

export const ReasonCell = styled.span`
	cursor: pointer;
	text-align: center;
	font-size: 12px;
	text-transform: uppercase;
	opacity: 0.9;
`;
