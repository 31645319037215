import React, { memo, useState, useEffect } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Empty } from 'antd';

import { useScript } from '../../hooks';
import RadioVignetteConfigsAPI from '../../services/sdks/radio-vignette-configs';
import BlockTemplatesAPI from '../../services/sdks/blockTemplates';

import { ScriptBody } from './styles';
import { Element } from './Element';
import { getResource } from '../../helpers/getResource';
import resourcesKeys from '../../constants/resourcesKeys';

const Script = SortableContainer(({ isAdmin, loggedUser, showInactiveElements, tkVoiceTypes }) => {
	const [blockTemplates, setBlockTemplates] = useState([]);
	const [radioVignetteConfigs, setRadioVignetteConfigs] = useState([]);
	const {
		body,
		compact,
		program,
		script: { isShared: isSharedProgram },
	} = useScript();

	useEffect(() => {
		const fetchBlockScripts = async () => {
			try {
				const res = await BlockTemplatesAPI.getAll();
				setBlockTemplates(res.data.templates);
			} catch (error) {
				setBlockTemplates([]);
			}
		};

		fetchBlockScripts();
	}, []);

	useEffect(() => {
		const fetchRadioVignetteConfigs = async (userId) => {
			try {
				const res = await RadioVignetteConfigsAPI.list(userId, 0, 100);
				setRadioVignetteConfigs(res.data.radioVignetteConfigs);
			} catch (error) {
				setRadioVignetteConfigs([]);
			}
		};

		const programId = program?._id;
		const userId = typeof program?.userId === 'string' ? program?.userId : program?.userId?._id;

		if (programId && userId) {
			fetchRadioVignetteConfigs(userId);
		}
	}, [program]);

	const isElementDisabled = ({ element }) => {
		if (isAdmin) {
			return false;
		}

		if (['TRACK-CALL', 'TRACK-POST'].includes(element.type)) {
			return true;
		}

		if (element.type === 'VIGNETTE-BREAK') {
			if (
				body[element.index + 1]?.type === 'TRACK-AD' &&
				body[element.index + 2]?.type === 'TRACK'
			) {
				return true;
			}

			if (
				body[element.index - 1]?.type === 'TRACK-AD' &&
				body[element.index - 2]?.type === 'TRACK'
			) {
				return true;
			}
		}

		if (element.type === 'TRACK') {
			if (
				body[element.index - 1]?.type === 'TRACK-CALL' ||
				body[element.index + 1]?.type === 'TRACK-POST'
			) {
				return true;
			}

			if (
				body[element.index + 1]?.type === 'TRACK-AD' &&
				body[element.index + 2]?.type === 'VIGNETTE-BREAK'
			) {
				return true;
			}

			if (
				body[element.index + 1]?.type === 'VIGNETTE-BREAK' &&
				body[element.index + 2]?.type === 'TRACK-AD'
			) {
				return true;
			}

			if (
				body[element.index - 1]?.type === 'TRACK-AD' &&
				body[element.index - 2]?.type === 'VIGNETTE-BREAK'
			) {
				return true;
			}

			if (
				body[element.index - 1]?.type === 'VIGNETTE-BREAK' &&
				body[element.index - 2]?.type === 'TRACK-AD'
			) {
				return true;
			}
		}

		if (element.type === 'TRACK-AD') {
			return true;
		}

		if (isSharedProgram) {
			if (program?.isTalkProgram) {
				if (element.type.includes('TK-VOICE')) {
					return false;
				}

				if (['CASHTRACK', 'TESTIMONIAL'].includes(element.type)) {
					return false;
				}

				if (element.type.includes('-LOCAL') && !element.type.includes('OFF-')) {
					return false;
				}

				return true;
			} else {
				if (['OFFERING', 'SPONSOR', 'CASHTRACK', 'TESTIMONIAL'].includes(element.type)) {
					return false;
				}

				if (element.type.includes('TK-VOICE')) {
					return false;
				}

				return true;
			}
		} else {
			if (program?.isTalkProgram) {
				if (['OFFERING', 'SPONSOR'].includes(element.type)) return true;

				return false;
			} else {
				return !program?.isEditable;
			}
		}
	};

	const shouldDisplayTrackAddMenu = ({ element }) => {
		if (element?.type !== 'VIGNETTE-BREAK' || !getResource(loggedUser, resourcesKeys.TRACK_AD)) {
			return false;
		}

		if (body[element.index - 1]?.type === 'TRACK' && body[element.index + 1]?.type === 'TRACK') {
			return true;
		}

		return false;
	};

	const shouldDisplayTrackMenu = ({ element }) => {
		return element.type === 'TRACK';
	};

	const shouldDisplayOffOptionsMenu = ({ element }) => {
		return (
			element?.type.startsWith('OFF-') ||
			element?.type.startsWith('NEWS-') ||
			element?.type.startsWith('WPP-') ||
			element?.type.startsWith('BLITZ-') ||
			element?.type.startsWith('INTERVIEW-')
		);
	};

	if (!body.length) {
		return (
			<ScriptBody>
				<Empty description='Clique nos elemento ao lado para adicioná-los ao modelo' />
			</ScriptBody>
		);
	}

	let blockIndex = -1;
	let elementIndexLabel = 0;

	return (
		<ScriptBody>
			{body.map((element, index) => {
				elementIndexLabel++;

				if (element.type === 'NEW-BLOCK') {
					blockIndex++;
					elementIndexLabel = 0;
				}

				element.index = index;
				element.indexLabel =
					elementIndexLabel === 0 ? null : String(elementIndexLabel).padStart(2, '0');

				return (
					<Element
						compact={compact}
						user={loggedUser}
						showInactiveElements={showInactiveElements}
						key={index}
						index={index}
						element={element}
						isAdmin={isAdmin}
						tkVoiceTypes={tkVoiceTypes}
						disabled={isElementDisabled({ element })}
						_disabled={isElementDisabled({ element })}
						blockIndex={blockIndex}
						nextElement={body[index + 1]}
						radioVignetteConfigs={radioVignetteConfigs}
						shouldDisplayTrackAddMenu={shouldDisplayTrackAddMenu({ element })}
						shouldDisplayOffOptionsMenu={shouldDisplayOffOptionsMenu({ element })}
						shouldDisplayTrackMenu={shouldDisplayTrackMenu({ element })}
						allowGlobalNews={getResource(loggedUser, resourcesKeys.NEWS_GLOBAL)}
						blockTemplates={blockTemplates}
					/>
				);
			})}
		</ScriptBody>
	);
});

export default memo(Script);
