import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px;

	& > .ant-card {
		margin: 12px 0;
	}

	.export-container {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
`;

export const ExportFormContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	gap: 8px 0;

	& > label {
		margin: 0 !important;
	}
`;

export const DownloadAncor = styled.a`
	width: 0;
	height: 0;
	display: none;
`;
