import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Typography, Divider, message, Tooltip, Modal, Breadcrumb, Switch } from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import Script from '../../../../components/Script';
import ConfigScriptStrs from '../../../../components/ConfigScriptStrs';
import ElementsTabs from '../../../../components/ElementsTabs';
import { Container, Grid, ScriptWrapper, ButtonContainer, OptionsContainer } from './styles';

import ScriptsAPI from '../../../../services/sdks/scripts';
import ProgramsAPI from '../../../../services/sdks/programs';
import ElementsAPI from '../../../../services/sdks/elements';

import { FiList, FiMaximize, FiMinimize, FiSave, FiSettings, FiXSquare } from 'react-icons/fi';

import { useScript } from '../../../../hooks';

import env from '../../../../config/env';
import SoundtracksAPI from '../../../../services/sdks/soundtracks';
import TemplatesModal from '../../../../components/TemplatesModal';
import ScriptTemplatesAPI from '../../../../services/sdks/scriptTemplates';
import TKVoiceConfigAPI from '../../../../services/sdks/tkVoiceConfig';
import { checkResource } from '../../../../helpers/checkResource';
import resourcesKeys from '../../../../constants/resourcesKeys';

const EditScript = ({ user }) => {
	const history = useHistory();
	const { scriptId } = useParams();
	const [templates, setTemplates] = useState([]);
	const [fallback, setFallback] = useState({ initialData: true });
	const [visibleModals, setVisibleModals] = useState({});
	const [showInactiveElements, setShowInactiveElements] = useState(false);
	const [tkVoiceTypes, setTkVoiceTypes] = useState({ caster: [], local: [] });

	const {
		cleanUp,
		setElements,
		script,
		setScript,
		program,
		setProgram,
		isSorting,
		setIsSorting,
		onElementMove,
		compact,
		setCompact,
		deletedSoundtracks,
	} = useScript();

	const handleUpdateScript = useCallback(async () => {
		try {
			setFallback((prev) => ({ ...prev, updatingScript: true }));
			const { body, name, weekDay } = script;

			await ScriptsAPI.modify({ scriptId, payload: { body, name, weekDay } });

			for (const deletedSoundtrackId of deletedSoundtracks) {
				await SoundtracksAPI.destroy(deletedSoundtrackId);
			}

			message.success('Modelo criado com sucesso');
			setFallback((prev) => ({ ...prev, updatingScript: false }));
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
			message.error(error?.response?.data?.message);

			setFallback((prev) => ({ ...prev, updatingScript: false }));
		}
	}, [script, scriptId, deletedSoundtracks]);

	const handleLoadTemplate = useCallback(
		(template) => {
			setScript((prev) => ({ ...prev, body: template.body }));
			setVisibleModals((prev) => ({ ...prev, loadTemplate: false }));
		},
		[setScript]
	);

	useEffect(() => cleanUp(), [cleanUp]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { script },
				} = await ScriptsAPI.show({ scriptId });

				const [
					{
						data: { program },
					},
					{
						data: { templates },
					},
					{
						data: { elements },
					},
				] = await Promise.all([
					await ProgramsAPI.show(script.program),
					await ScriptTemplatesAPI.getAll(),
					await ElementsAPI.index({ query: `userId=${user?._id}&program=${script.program}` }),
				]);

				const cashTracks = elements.filter(({ type }) => type === 'CASHTRACK');
				const customs = elements.filter(({ type }) => type === 'CUSTOM');
				const merchans = elements.filter(({ type }) => ['SPONSOR', 'OFFERING'].includes(type));
				const trackAds = elements.filter(({ type }) => type === 'TRACK-AD');

				setScript(script);
				setTemplates(templates);
				setProgram({ ...program, isTalkProgram: program?.userId?._id === env.talkId });
				setElements({ cashTracks, customs, merchans, trackAds });
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
			}
		};

		scriptId && fetchInitialData();
	}, [scriptId, user, setElements, setProgram, setScript]);

	useEffect(() => {
		const fetchTkVoiceTypes = async () => {
			try {
				const res = await TKVoiceConfigAPI.getPopulatedTypes(program._id, user?._id);
				setTkVoiceTypes(res.data.types);
			} catch (error) {
				setTkVoiceTypes({ caster: [], local: [] });
			}
		};

		if (program && user) {
			fetchTkVoiceTypes();
		}
	}, [program, user]);

	useEffect(() => {
		if (program && script) {
			if (!program?.isEditable && !script?.isShared) {
				/** Modelos originais não podem ser editados */
				return history.push(`/commom/programs/${program?._id}/details`);
			}

			if (script?.isShared && !script?.isVisible) {
				/** Modelos compartilhados não visíveis não podem ser editados */
				return history.push(`/commom/programs/${program?._id}/details`);
			}
		}
	}, [program, script, history]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Editar modelo' />

			<Container isSorting={isSorting}>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'INÍCIO' },
						{ breadcrumbName: 'PROGRAMAS' },
						{ breadcrumbName: 'DETALHES DO PROGRAMA' },
						{ breadcrumbName: 'EDITAR MODELO' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Editar modelo</Typography.Title>
				</header>

				<Divider />

				<Grid>
					<ElementsTabs isAdmin={false} user={user} tkVoiceTypes={tkVoiceTypes} />
					<ScriptWrapper>
						<header>
							<div className='title-container'>
								<Typography.Title level={4}>{script?.name}</Typography.Title>
								{checkResource(
									user,
									[
										resourcesKeys.VIGNETTES_RADIO_CONTINUOUS,
										resourcesKeys.VIGNETTES_RADIO_SCHEDULED,
									],
									{ strict: false }
								) && (
									<div
										className='switch-container'
										onClick={() => setShowInactiveElements(!showInactiveElements)}>
										<Switch checked={showInactiveElements} style={{ pointerEvents: 'none' }} />
										<span>Mostrar vinhetas inativas</span>
									</div>
								)}
							</div>
							{checkResource(user, resourcesKeys.TAG) && (
								<Button
									size='small'
									onClick={() => setVisibleModals({ ...visibleModals, loadTemplate: true })}>
									<FiList />
									Carregar Template
								</Button>
							)}
						</header>

						<Divider style={{ margin: '8px 0' }} />

						<OptionsContainer>
							{checkResource(user, resourcesKeys.SOUNDTRACK) && (
								<Button
									style={{ flex: 1 }}
									size='small'
									disabled={script?.body?.length === 0 || script?.isShared}
									onClick={() => setVisibleModals({ ...visibleModals, configStrs: true })}>
									<FiSettings /> Configurar trilhas sonoras
								</Button>
							)}

							<div className='divider'></div>

							<Tooltip title={compact ? 'Maximizar' : 'Compactar'}>
								<Button
									size='small'
									onClick={() => setCompact(!compact)}
									disabled={script?.body?.length === 0}>
									{compact ? <FiMaximize /> : <FiMinimize />}
								</Button>
							</Tooltip>

							<Tooltip title={script?.body?.length !== 0 && !script?.isShared && 'Limpar modelo'}>
								<Button
									disabled={script?.body?.length === 0 || script?.isShared}
									type='danger'
									size='small'
									onClick={() => {
										Modal.confirm({
											title: 'Limpar modelo?',
											icon: 'exclamation-circle',
											content: 'Todos os blocos e elementos serão removidos, deseja continuar?',
											okText: 'Limpar modelo',
											onOk: () => setScript({ ...script, body: [] }),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<FiXSquare />
								</Button>
							</Tooltip>
						</OptionsContainer>

						<Divider style={{ margin: '8px 0' }} />

						<Script
							showInactiveElements={showInactiveElements}
							onSortEnd={onElementMove}
							onSortStart={() => setIsSorting(true)}
							loggedUser={user}
							tkVoiceTypes={tkVoiceTypes}
						/>
					</ScriptWrapper>
				</Grid>

				<ButtonContainer>
					<Button
						type='primary'
						disabled={script.body.length === 0}
						onClick={handleUpdateScript}
						loading={fallback?.updatingScript}>
						<FiSave /> Atualizar modelo
					</Button>
				</ButtonContainer>
			</Container>

			<ConfigScriptStrs
				visible={visibleModals?.configStrs}
				onConfirm={() => setVisibleModals({ ...visibleModals, configStrs: false })}
			/>

			<TemplatesModal
				visible={visibleModals.loadTemplate}
				onConfirm={handleLoadTemplate}
				onCancel={() => setVisibleModals({ ...visibleModals, loadTemplate: false })}
				templates={templates}
			/>
		</>
	);
};

export default connect(({ user }) => ({ user }))(EditScript);
