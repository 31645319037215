import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Divider, Alert } from 'antd';

import Meta from '../../../../components/Meta';
import HelpButtons from '../../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../../assets/images/tutorials/programs/program-more-details/img-01.png';
import img02 from '../../../../assets/images/tutorials/programs/program-more-details/img-02.png';
import img03 from '../../../../assets/images/tutorials/programs/program-more-details/img-03.png';
import img04 from '../../../../assets/images/tutorials/programs/program-more-details/img-04.png';
import img05 from '../../../../assets/images/tutorials/programs/program-more-details/img-05.png';
import img06 from '../../../../assets/images/tutorials/programs/program-more-details/img-06.png';
import img07 from '../../../../assets/images/tutorials/programs/program-more-details/img-07.png';

const { Title, Paragraph } = Typography;

const ProgramTabsDetails = () => (
	<Container>
		<Meta title='Ajuda - Conhecendo Melhor o Programa' />

		<Title>Conhecendo Melhor a TalkPlay</Title>

		<Divider />

		<Paragraph>
			Como você deve ter notado no{' '}
			<Link to='/help/programs/program-details/own'>tutorial anterior</Link>, nos detalhes do
			programa existem diversas abas. Vamos conhecê-las nesse tutorial.
		</Paragraph>

		<Divider />

		<Title level={3}>Modelos</Title>

		<Paragraph>
			Um modelo é a representação do roteiro do programa. Nele são definidos os blocos e a ordem dos
			elementos, ou seja, é através dele que você definie exatamente como quer o seu conteúdo.
		</Paragraph>

		<Paragraph>
			Na aba de modelos são exibidos todos os modelos do programa. Vale lembrar que um programa pode
			possuir mais de um modelo.
		</Paragraph>

		<ImgContainer>
			<img src={img01} alt='Modelos' />
		</ImgContainer>

		<Paragraph>
			Quando um programa é criado, ele não possui nenhum modelo. Para criar um, clique em{' '}
			<i>Novo modelo</i>
		</Paragraph>

		<ImgContainer>
			<img src={img02} alt='Novo modelo' />
		</ImgContainer>

		<Paragraph>
			Após isso, será levado até a página de criação de modelos. Nela existem duas colunas.
		</Paragraph>

		<Paragraph>
			Na coluna da esquerda estão presentes todos os elementos que você pode adicionar ao modelo:{' '}
			<i>Blocos</i>, <i>Vinhetas</i>, <i>Offs</i>, <i>Músicas</i> e etc. A coluna da direito, é onde
			você irá visualizar o modelo montado.
		</Paragraph>

		<ImgContainer>
			<img src={img03} alt='Criando Modelo' />
		</ImgContainer>

		<Paragraph>
			Para adicionar um elemento ao modelo é muito simples, basta clicar no elemento que deseja
			incluir e ele será inserido no final do modelo. Também é possível arrastar os elementos já
			presentes no modelo e organizá-los da forma que desejar.
		</Paragraph>

		<Paragraph>
			Haverão situações em que seu modelo estará muito grande e inserir elementos no final do mesmo
			e depois arrastá-los não será muito eficaz, para isso você pode optar por inserir um elemento
			em um bloco específico. Para fazer isso, clique nos 3 pontinhos ao lado do bloco e serão
			mostradas todas as opções de elementos para inserção. Aqui também é possível remover um bloco
			e duplicá-lo.
		</Paragraph>

		<ImgContainer>
			<img src={img04} alt='Opções do bloco' />
		</ImgContainer>

		<Paragraph>
			Após terminar a configuração do seu modelo, basta clicar em <i>Criar modelo</i>.
		</Paragraph>

		<ImgContainer>
			<img src={img05} alt='Salvar modelo' />
		</ImgContainer>

		<Paragraph>
			Pronto, você acabou de criar um modelo para o seu programa, futuramente iremos aprender como
			utilizá-lo para gerar o conteúdo final.
		</Paragraph>

		<Paragraph>
			Para editar um modelo existente basta clicar em seu nome, ou ir no menu de opções e depois em{' '}
			<i>Editar roteiro</i>.
		</Paragraph>

		<ImgContainer>
			<img src={img06} alt='Editar modelo' />
		</ImgContainer>

		<Divider />

		<Title level={3}>Vinhetas</Title>

		<Paragraph>
			Não existe segredo aqui, é onde pode-se gerenciar as vinhetas do seu programa. Vinhetas só
			podem ser criadas e inseridas em modelos de <strong>programas próprios</strong>.
		</Paragraph>

		<Paragraph>Os tipos de vinheta são:</Paragraph>

		<ul>
			<li>
				<i>Introdução de programa;</i>
			</li>
			<li>
				<i>Encerramento de programa;</i>
			</li>
			<li>
				<i>Introdução de bloco;</i>
			</li>
			<li>
				<i>Encerramento de bloco;</i>
			</li>
			<li>
				<i>Passagem;</i>
			</li>
		</ul>

		<Paragraph>
			Para criar vinhetas, clique em <i>Nova vinheta</i>. Após isso você será levado até a página de
			criação das mesmas.
		</Paragraph>

		<Paragraph>
			Uma vez na página, alterne estre os tipos de vinheta nas abas da esquerda, e selecione os
			arquivos de áudio que deseja subir no campo da direita.
		</Paragraph>

		<Paragraph>
			Anexando mais de um arquivo por tipo de vinheta, o sistema irá inserí-las de forma aleatória
			durante a geração do conteúdo. Para facilitar o entendimento, imagine que quando você insere
			uma <i>VINHETA DE INTRODUÇÃO DE BLOCO</i> no modelo, está dizendo ao sistema que naquela
			posição sempre deverá ser inserida uma <i>VINHETA DE INTRODUÇÃO DE BLOCO</i>, porém de forma
			dinâmica e aleatória, isso é o que torna, em partes, um bloco diferente do outro.
		</Paragraph>

		<ImgContainer>
			<img src={img07} alt='Editar modelo' />
		</ImgContainer>

		<Paragraph>
			Depois de selecionar tudo, clique em <i>Fazer upload das vinhetas</i>. Pronto, as vinhetas já
			estão prontas para uso.
		</Paragraph>

		<Title level={3}>Elementos customizados</Title>

		<Paragraph>
			Elementos customizados podem ser usados para incluir qualquer áudio que queria no seu
			programa. Como não são inseridos de forma automática e aleatória durante o programa, podem ser
			usados para um áudio que você quer que seja sempre executado, sem variações.
		</Paragraph>

		<Paragraph>
			O gerenciamento de elementos customizados segue o mesmo padrão das vinhetas.
		</Paragraph>

		<Title level={3}>Publicidades</Title>

		<Paragraph>
			São elementos que podem ser utilizados para customizar{' '}
			<strong>programas compartilhados</strong>, e isso será abordado em breve.
		</Paragraph>

		<Paragraph>Existem 4 tipos de publicidades:</Paragraph>

		<ul>
			<li>
				<i>Patrocínios;</i>
			</li>
			<li>
				<i>Oferecimentos;</i>
			</li>
			<li>
				<i>Músicas-Cash;</i>
			</li>
			<li>
				<i>Testemunhais;</i>
			</li>
		</ul>

		<Paragraph>
			O funcionamento dos três primeiros é similar aos <i>elementos customizados</i>. Você irá criar
			os elementos, adicioná-los no modelo e esse áudio sempre será executado na posição inserida.
		</Paragraph>

		<Paragraph>
			Vale salientar que para criar músicas-cash é necessário informar uma data de expiração. Após
			essa data a música será excluída e removida automaticamente do modelo. É altamente aconselhado
			informar corretamente nome da música e artista, para que o sistema evite repetições em caso da
			música já existir na programação.
		</Paragraph>

		<Paragraph>
			Já os testemunhais funcionam como as vinhetas, ou seja, haverá uma alternância na reprodução
			dos áudios com base na quantidade de testemunhais cadastrados para cada programa.
		</Paragraph>

		<Divider />

		<Title level={3}>Offs Locais</Title>

		<Paragraph>
			Offs locais possuem a finalidade principal de serem usados em{' '}
			<strong>programas compartilhados</strong>. São elementos que podem, e vão, deixar um programa
			compartilhado com a cara da sua emissora.
		</Paragraph>

		<Paragraph>
			Assemelham-se bastante com as vinhetas, pois são inseridos de forma randômica durante a
			geração do conteúdo. Possuem as seguintes categorias:
		</Paragraph>

		<ul>
			<li>
				<i>Off Local de Introdução de programa;</i>
			</li>
			<li>
				<i>Off Local de Encerramento de programa;</i>
			</li>
			<li>
				<i>Off Local de Introdução de bloco;</i>
			</li>
			<li>
				<i>Off Local de Encerramento de bloco;</i>
			</li>
			<li>
				<i>Off Local de Meio de Bloco;</i>
			</li>
		</ul>

		<Paragraph type='secondary'>
			<i>
				Os tipos servem somente para facilitar a separação e organização dos arquivos, você possui
				total controle de onde e quando utilizá-los.
			</i>
		</Paragraph>

		<Title level={3}>TKVOX-REMOTE</Title>

		<Paragraph>
			Este módulo, se assemelham a IA Locutora, sendo capaz de automatizar o seu locutor Local <strong> nos programas próprios</strong>. Podem ser usados para substituir{' '}
			<i>GRAVAÇÃO DE OFF DIÁRIA DE SEU LOCUTOR</i> no modelo, logo mais iremos saber mais sobre esses.
		</Paragraph>

		<Paragraph>
			Como são inseridos de forma cíclica e randômica, é ideal que se tenha uma grande quantidade de
			offs gravados de cada tipo cadastrada, evitando assim a repetição constante do conteúdo
			gerado.
		</Paragraph>

		<Title level={3}>Notícias Locais</Title>

		<Paragraph>
			São divididas em diversas categorias, como <i>CINEMA</i>, <i>POLÍTICA</i>, etc. São destinadas
			para a inserção de notícias da sua região em <strong>programas compartilhados</strong>. Deve ser alimentado com suas notícias se este elemento for colocado no programa.
		</Paragraph>

		
		<Title level={3}>Notícias Globais</Title>

		<Paragraph>
			São divididas em diversas categorias, como <i>CINEMA</i>, <i>POLÍTICA</i>, etc. São destinadas
			para a inserção de notícias do <strong>Brasil e do Mundo</strong>. Se inserido no programa, as noticias serão inserias pela IA automaticamente.
		</Paragraph>

		<Paragraph>
			A novidade nesse tipo de elemento é que ele possui uma{' '}
			<i>
				<strong>data de lançamanto</strong>
			</i>
			, sendo assim, esses elementos tem uma data específica para ir ao ar e <strong>não</strong>{' '}
			são inseridos de forma aleatória, mas sim de acordo com a sequência em que foi feito o upload
			dos arquivos.
		</Paragraph>

		<Alert
			showIcon
			type='warning'
			description={
				<>
					Os elementos <strong>Blitz Locais</strong>, <strong>Entrevistas Locais</strong> e{' '}
					<strong>Wpp's Locais</strong> possuem funcionamento idêntico às{' '}
					<strong>Notícias Locais</strong>, portanto cabe a você organizar e distribuir seus
					materiais.
				</>
			}
		/>

		<Divider />

		<Paragraph type='secondary'>
			Caso não consiga editar o programa ou os modelos, significa que esse programa não é
			editável(isso é definido pela equipe TalkRadio).
		</Paragraph>

		<HelpButtons
			prev={{ label: 'Detalhes do Programa Próprio', to: '/help/programs/program-details/own' }}
			next={{
				label: 'Detalhes do Programa Compartilhado',
				to: '/help/programs/program-details/shared',
			}}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default ProgramTabsDetails;
