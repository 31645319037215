import styled from 'styled-components';
import { Layout, Button } from 'antd';

export const Container = styled(Layout)`
	min-height: 100vh !important;
	transition: all 0.2s !important;
	position: relative;

	& aside.ant-layout-sider {
		background: ${({ isadmin }) => (isadmin === 'true' ? '#05113f' : `var(--primary)`)};

		.ant-layout-sider-children {
			padding-bottom: 48px;
		}

		& ul.ant-menu {
			background: transparent;

			& li.ant-menu-item {
				width: 92%;
				border-radius: 100px 100px 100px 100px;
				margin-left: 4%;
				font-weight: 500;
				border-radius: var(--border-radius-base);

				&:hover {
					background: var(--primary-strong);
				}
			}

			& li.ant-menu-item a {
				color: #ebccef;
				border-radius: var(--border-radius-base);

				&:after {
					display: none;
				}
			}

			& li.ant-menu-submenu {
				color: #ebccef;
				font-weight: 500;
				width: 92%;
				border-radius: 100px 100px 100px 100px;
				margin-left: 4%;
			}

			& li.ant-menu-item-selected {
				background: var(--primary-strong);
				border-radius: var(--border-radius-base);

				& a {
					color: #fff;
				}
			}
		}
	}

	& aside.ant-layout-sider-collapsed {
		& li.ant-menu-item a,
		.ant-menu-submenu-title {
			margin-left: -4px !important;
		}
	}

	& .ant-menu-dark .ant-menu-inline.ant-menu-sub {
		box-shadow: none;
		background: transparent;

		& li {
			height: 36px !important;
			line-height: 36px !important;
			margin-left: 10% !important;
			padding-left: 24px !important;
			width: 100% !important;
			border-radius: 100px 0 0 100px;
			/* width: 85% !important;
			margin-left: 15% !important; */
		}
	}
`;

export const PaymentAlertContainer = styled.div`
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000000000;
	background: rgba(0, 0, 0, 0.1);
`;

export const NavContainer = styled(Layout.Sider)`
	overflow: auto !important;
	height: 100vh !important;
	position: fixed !important;
	padding-top: 6px;
	left: 0 !important;
	/* border-right: 2px solid
		linear-gradient(180deg, hsla(275, 97%, 52%, 1) 0%, hsla(282, 100%, 35%, 1) 100%) !important; */

	& .ant-layout-sider-children {
		margin-bottom: 70px;
		overflow: hidden;
		height: max-content;
	}

	& .ant-layout-sider-trigger {
		border-right: ${({ isadmin }) =>
			isadmin === 'true' ? '1px solid #05113f' : '1px solid #4d006d'};
		background: ${({ isadmin }) => (isadmin === 'true' ? '#05113f' : 'var(--primary)')} !important;
		color: #fff !important;
	}

	::-webkit-scrollbar {
		width: 0px;
	}
`;

export const Header = styled.header`
	background: #fff !important;
	z-index: 100 !important;
	height: 48px !important;
	padding: 0px !important;
	border-bottom: 1px solid rgb(0 21 41 / 8%);
	position: fixed;
	top: 0%;
	transition: 0.2s;
`;

export const HeaderInner = styled.div`
	width: 100%;
	height: 100%;
	padding: 0 24px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const AlertMessage = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 20px;

	& > strong {
		font-size: 14px;
		font-weight: 500;
	}

	& > span {
		font-size: 12px;
		font-style: italic;
		font-weight: 300;
		margin-top: -3px;
	}
`;

export const NamedLogo = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 24px 24px 0 24px;

	& > img {
		width: 150px;
	}
`;

export const NoNamedLogo = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 24px 24px 0 24px;

	& > img {
		width: 25px;
	}
`;

export const ActionsContainer = styled.div`
	display: flex;
	margin: 0 38px 0 40px;
	padding-left: 38px;
	border-left: 1px solid #dedede;
	height: 60%;
	align-items: center;

	& a {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& svg {
		color: rgba(0, 0, 0, 0.65) !important;
	}

	& svg:hover {
		color: var(--primary) !important;
	}

	& svg:first-child {
		margin-right: 14px;
	}

	& svg:last-child {
		margin-left: 14px;
	}
`;

export const PlayButton = styled(Button)`
	transition: 500ms;
	position: relative;
	width: 200px;

	& > i {
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translateY(-45%);
		transition: 350ms !important;
		transition-delay: 150ms;
		transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.675) !important;
	}

	&:hover {
		box-shadow: 0 0 10px var(--primary-opacity-85), 0 0 40px var(--primary-opacity-85),
			0 0 80px var(--primary-opacity-85);

		& > i {
			transform: translateY(-45%) scale(1.55);
		}
	}
`;

export const AdminLabel = styled.div`
	padding-left: 24px;
	margin-top: 5px;
	margin-bottom: 24px;

	& > span {
		font-weight: 300;
		color: #fff;
		opacity: 0.4;
		font-size: 13px;
	}
`;

export const TutorialVideo = styled.iframe`
	width: 100%;
	border-radius: var(--border-radius-base);
`;

export const Banners = styled.div`
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	margin-top: 48px;
	/* padding: 0 8px; */
	z-index: 1000;
	background: #fff;
`;
