import { useCallback } from 'react';

const useFilenameTemplate = () => {
	return useCallback(({ template, index }) => {
		if (template[template.length - 1] === '0') {
			template = template.substring(0, template.length - 1);
		}

		index = index + 1 <= 9 ? `0${index + 1}` : index + 1;

		return `${template}${index}`;
	}, []);
};

export default useFilenameTemplate;
