import React from 'react';
import { Typography, Divider } from 'antd';

import Meta from '../../../../components/Meta';
import HelpButtons from '../../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../../assets/images/tutorials/programs/creating-program/img-01.png';
import img02 from '../../../../assets/images/tutorials/programs/creating-program/img-02.png';
import img03 from '../../../../assets/images/tutorials/programs/creating-program/img-03.png';
import img04 from '../../../../assets/images/tutorials/programs/creating-program/img-04.png';

const { Title, Paragraph } = Typography;

const CreatingProgram = () => (
	<Container>
		<Meta title='Ajuda - Criando um programa' />

		<Title>Criando um Programa</Title>

		<Divider />

		<Paragraph>
			Antes de mais nada, para utilizar o TalkPlay é necessário possuir no mínimo um programa.
		</Paragraph>
		<Paragraph>
			É através de um programa que você poderá montar seus roteiros, organizar suas trilhas sonoras,
			vinhetas, offs e etc. Mas não se preocupe, tudo isso será abordado logo mais nesse tutorial.
		</Paragraph>
		<Paragraph>Existem dois tipos de programa no TalkPlay:</Paragraph>
		<ul>
			<li>Programas próprios;</li>
			<li>Programas compartilhados;</li>
		</ul>
		<Paragraph>
			Nesse tutorial vamos aprender como criar um <strong>programa próprio</strong>
		</Paragraph>

		<Divider />

		<Title level={3}>Criando um programa próprio.</Title>

		<Paragraph>
			Como o nome sugere, um programa próprio é um programa criado por você do zero, no qual terá
			total e absoluto controle sobre todos os seus elementos.
		</Paragraph>

		<Paragraph>Após efeuar o login, você irá se deparar com a seguinte tela:</Paragraph>

		<ImgContainer>
			<img src={img01} alt='Tela inicial' />
		</ImgContainer>

		<Paragraph>
			Aqui basta clicar no botão <strong>Novo Programa</strong>, no lado direito superior da página.
		</Paragraph>

		<ImgContainer>
			<img src={img02} alt='Indo para a criação' />
		</ImgContainer>

		<Paragraph>Você será redirecionado até a página de criação do programa:</Paragraph>

		<ImgContainer>
			<img src={img03} alt='Criando o programa' />
		</ImgContainer>

		<Paragraph>Existe duas abas na página:</Paragraph>
		<ul>
			<li>Inserir informações;</li>
			<li>Adicionar vinhetas;</li>
		</ul>

		<Divider />

		<Title level={4}>Cadastro de informações do programa</Title>

		<Paragraph>
			Na primeira é onde serão inseridas as informações do programa: <i>Nome</i>, <i>Descrição</i>,{' '}
			<i>Gênero</i> e <i>Nome padrão dos arquivos.</i>
		</Paragraph>
		<p className='ps'>
			<strong>PS:</strong> O nome padrão dos arquivo é referente ao nome dos arquivos de áudio dos
			blocos que serão baixados após a geração do conteúdo. Ex: BLOCO_01, BLOCO_02, BLOCO_03...
		</p>

		<Divider />

		<Title level={4}>Opcional: Inserção de vinhetas do programa</Title>

		<Paragraph>
			Na segunda é onde serão anexados os arquivos de áudio das vinhetas. Essa etapa é opcional
			durante a criação do programa, portanto sinta-se livre para adicionar as vinhetas depois caso
			deseje.
		</Paragraph>

		<ImgContainer>
			<img src={img04} alt='Criando o programa' />
		</ImgContainer>

		<Paragraph>
			Após inserir todas as informações, clique em <strong>Finalizar criação do programa</strong>.
			Em seguida você será levado até a página de detalhes do programa que acabou de criar.
		</Paragraph>

		<Divider />

		<Paragraph>
			Pronto! Você acabou de adicionar um novo conteúdo à sua grade de programação.
		</Paragraph>

		<HelpButtons
			next={{ label: 'Gerenciamento de Programas', to: '/help/programs/programs-management' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default CreatingProgram;
