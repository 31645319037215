import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import * as Yup from 'yup';
import path from 'path';
import {
	Tabs,
	Button,
	Select,
	Input,
	DatePicker,
	Modal,
	Typography,
	Alert,
	Menu,
	Badge,
	Dropdown,
	Icon,
	message,
	Table,
} from 'antd';

import FileExt from '../FileExt';
import FileDuration from '../FileDuration';
import Form from '../Form';
import { TabWrapper } from './styles';

import { useElement, usePlayer, useDownload, useFile, useFilesValidator } from '../../hooks';
import ElementsAPI from '../../services/sdks/elements';
import { FiUpload, FiXCircle, FiPlusCircle, FiPlus } from 'react-icons/fi';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';
import FilesUploader from '../FilesUploader';
import { getResource } from '../../helpers/getResource';
import resourcesKeys from '../../constants/resourcesKeys';

const AdsElements = ({ program, user }) => {
	const player = usePlayer();
	const download = useDownload();
	const { getDuration: getFileDuration } = useFile();
	const { categorizedTypes, getElementIcon, parseElementName } = useElement();

	const allowedTypes = useMemo(() => {
		return Object.entries(user.resourcesId.ads)
			.filter(([, value]) => value === true)
			.map(([key]) => key.replace('_', '-'));
	}, [user]);

	const [fallback, setFallback] = useState({ creatingElement: false });
	const [tab, setTab] = useState(allowedTypes[0]);
	const [showModal, setShowModal] = useState(false);

	const [loadedTypes, setLoadedTypes] = useState(() => {
		const _loadedCategs = {};

		categorizedTypes.merchanElements.forEach((type) => (_loadedCategs[type] = false));

		return _loadedCategs;
	});

	const [elements, setElements] = useState(() => {
		const _elements = {};

		categorizedTypes.merchanElements.forEach((type) => (_elements[type] = []));

		return _elements;
	});

	const [type, setType] = useState(undefined);
	const [file, setFile] = useState(null);
	const [name, setName] = useState('');
	const [artist, setArtist] = useState('');
	const [color, setColor] = useState('#000000');
	const [expirationDate, setExpirationDate] = useState(null);
	const [isValidating, setIsValidating] = useState(false);
	const { hasValidationError } = useFilesValidator();

	const columns = [
		{
			title: 'ID',
			dataIndex: '_id',
			key: 'id',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					8
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Nome',
			key: 'name',
			render: (element) => (
				<span>
					<Badge color={element.color} />
					{element.name}
				</span>
			),
		},
		{
			title: 'Duração',
			align: 'center',
			key: 'duration',
			render: (element) => <FileDuration src={resolveFileSrc({ fileName: element.filename })} />,
		},
		{
			title: 'Formato',
			align: 'center',
			key: 'ext',
			render: (element) => <FileExt src={resolveFileSrc({ fileName: element.filename })} />,
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'actions',
			render: ({ _id, filename, name, type }) => (
				<Dropdown
					placement='bottomRight'
					overlay={
						<Menu>
							<Menu.Item
								key='play-off'
								onClick={() => {
									player.start({
										ref: _id,
										src: resolveFileSrc({ fileName: filename }),
										meta: { name: name, artist: program?.name },
									});
								}}>
								<Icon type='play-circle' /> Reproduzir
							</Menu.Item>
							<Menu.Item
								key='download'
								disabled={!program?.isEditable}
								onClick={() => {
									handleDownload({
										filename,
										name: `${name} - ${program?.name}`,
									});
								}}>
								<Icon type='download' /> Fazer download
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								key='delete'
								className='ant-dropdown-menu-item-danger'
								disabled={!program?.isEditable}
								onClick={() => {
									Modal.confirm({
										title: 'Excluir elemento?',
										icon: 'exclamation-circle',
										content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
										okText: 'Excluir',
										onOk: () => handleDeleteElement({ elementId: _id, type }),
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<Icon type='delete' /> Excluir
							</Menu.Item>
						</Menu>
					}>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleCreateElement = useCallback(async () => {
		if (hasValidationError([file])) {
			return message.warning('O arquivo selecionado está corrompido. Por favor, substitua-o.');
		}

		try {
			if (file && type === 'TRACK-AD') {
				const fileUrl = URL.createObjectURL(file);
				const fileDuration = await getFileDuration({ fileUrl });

				if (fileDuration > 15) {
					message.error('O arquivo excedeu o limite máximo de duração para propagandas curtas.', 4);
					return message.error('Por favor selecione um arquivo com 15 segundos ou menos.', 5);
				}
			}

			const validationSchema =
				type === 'CASHTRACK'
					? Yup.object().shape({
							elementName: Yup.string().required('Informe o nome do elemento'),
							color: Yup.string().required('Informe o nome do elemento'),
							file: Yup.mixed().required('Selecione o arquivo de áudio'),
							type: Yup.string().required('Informe o tipo do elemento'),
							expirationDate: Yup.date().required('Informe a data de expiração'),
					  })
					: Yup.object().shape({
							elementName: Yup.string().required('Informe o nome do elemento'),
							color: Yup.string().required('Informe o nome do elemento'),
							file: Yup.mixed().required('Selecione o arquivo de áudio'),
							type: Yup.string().required('Informe o tipo do elemento'),
					  });

			const category = type === 'CASHTRACK' ? 'TRACK' : type === 'TESTIMONIAL' ? 'OFF' : 'CUSTOM';
			const payload =
				type === 'CASHTRACK'
					? {
							color,
							file: file.data,
							type,
							category,
							programId: program?._id,
							userId: user?._id,
							expirationDate,
							elementName: `${artist.trim()} - ${name.trim()}`,
					  }
					: {
							elementName: name,
							color,
							file: file.data,
							type,
							category,
							programId: program?._id,
							userId: user?._id,
					  };

			await validationSchema.validate(payload);

			setFallback((prev) => ({ ...prev, creatingElement: true }));

			const formData = new FormData();

			for (const key in payload) {
				if (Object.hasOwnProperty.call(payload, key)) {
					formData.append(key, payload[key]);
				}
			}

			const { data } =
				type === 'TESTIMONIAL'
					? await ElementsAPI.store({ payload: formData })
					: await ElementsAPI.custom.store({ payload: formData });

			const newElement = type === 'TESTIMONIAL' ? data.elements[0] : data?.element;

			setType(undefined);
			setFile(null);
			setName('');
			setArtist('');
			setColor('#000000');
			setExpirationDate(null);
			setShowModal(false);
			setFallback((prev) => ({ ...prev, creatingElement: false }));
			setElements((prev) => ({ ...prev, [type]: [...prev[type], newElement] }));

			return message.success('Elemento criado com sucesso!');
		} catch (error) {
			setFallback((prev) => ({ ...prev, creatingElement: false }));

			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			const errorMessage = error?.response?.data?.message;

			if (errorMessage) {
				message.error(errorMessage);
			}

			console.error(error);
		}
	}, [
		hasValidationError,
		file,
		type,
		color,
		program?._id,
		user?._id,
		expirationDate,
		artist,
		name,
		getFileDuration,
	]);

	const handleDeleteElement = useCallback(async ({ elementId, type }) => {
		try {
			await ElementsAPI.custom.destroy(elementId);

			setElements((prev) => ({
				...prev,
				[type]: prev[type].filter((e) => e?._id !== elementId),
			}));

			return message.success('Elemento deletada com sucesso!');
		} catch (error) {
			console.error(error);
		}
	}, []);

	const handleDownload = useCallback(
		async ({ filename, name }) => {
			try {
				const ext = path.extname(filename);
				await download({ filename, name: `${name}${ext}` });
			} catch (error) {
				console.error(error);
			}
		},
		[download]
	);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setFallback((prev) => ({ ...prev, [tab]: true }));

				const query = `program=${program?._id}&userId=${user?._id}&type=${tab}`;
				const {
					data: { elements: newElements },
				} = await ElementsAPI.custom.index({ query });

				setLoadedTypes((prev) => ({ ...prev, [tab]: true }));
				setElements((prev) => ({ ...prev, [tab]: newElements }));
				setFallback((prev) => ({ ...prev, [tab]: false }));
			} catch (error) {
				console.error(error);
				message.error(error);
			}
		};

		if (!loadedTypes[tab]) {
			fetchData();
		}
	}, [tab, elements, program, loadedTypes, user]);

	return (
		<>
			<TabWrapper>
				<header>
					<Select style={{ width: 250 }} value={tab} onChange={(value) => setTab(value)}>
						{allowedTypes.map((type) => (
							<Select.Option value={type} key={type}>
								{parseElementName({ type })}
							</Select.Option>
						))}
					</Select>

					<Button disabled={!program?.isEditable} onClick={() => setShowModal(true)}>
						<FiPlus /> Nova publicidade
					</Button>
				</header>

				<Tabs size='small' activeKey={tab}>
					{getResource(user, resourcesKeys.TRACK_AD)
						? categorizedTypes.merchanElements.map((type) => (
								<Tabs.TabPane tab={type} key={type}>
									{loadedTypes[type] && (
										<Table
											rowKey='_id'
											pagination={{ size: 'large' }}
											style={{ border: 'none' }}
											loading={fallback?.fetchingData}
											size='middle'
											columns={columns}
											dataSource={elements[type]}
											rowClassName='__row'
										/>
									)}
								</Tabs.TabPane>
						  ))
						: categorizedTypes.merchanElements
								.filter((type) => type !== 'TRACK-AD')
								.map((type) => (
									<Tabs.TabPane tab={type} key={type}>
										{loadedTypes[type] && (
											<Table
												rowKey='_id'
												pagination={{ size: 'large' }}
												style={{ border: 'none' }}
												loading={fallback?.fetchingData}
												size='middle'
												columns={columns}
												dataSource={elements[type]}
												rowClassName='__row'
											/>
										)}
									</Tabs.TabPane>
								))}
				</Tabs>
			</TabWrapper>

			<Modal
				width={600}
				destroyOnClose
				visible={showModal}
				closable={!fallback?.creatingElement}
				onOk={handleCreateElement}
				onCancel={() => setShowModal(false)}
				cancelButtonProps={{ disabled: fallback?.creatingElement }}
				okButtonProps={{ loading: fallback?.creatingElement, disabled: isValidating }}
				okText={
					<>
						{!isValidating && <FiUpload />}
						{isValidating ? 'Validando Arquivo' : 'Fazer upload'}
					</>
				}
				cancelText={
					<>
						<FiXCircle />
						Cancelar
					</>
				}
				title={
					<>
						<FiPlusCircle />
						Nova publicidade
					</>
				}>
				<Form.Container>
					{type && type === 'CASHTRACK' && (
						<Alert
							showIcon
							style={{ marginBottom: 16 }}
							type='warning'
							description='Informe corretamente o nome da música e do artista para que o sistema possa evitar que a mesma seja inserida na programação caso já exista na playlist do dia'
						/>
					)}

					{type && type === 'TRACK-AD' && (
						<Alert
							showIcon
							style={{ marginBottom: 16 }}
							type='warning'
							description={
								<>
									A duração máxima permitida do arquivo para propagandas curtas é de{' '}
									<strong>15 segundos</strong>
								</>
							}
						/>
					)}

					<Form.Item label='Tipo'>
						<Select
							onChange={(value) => setType(value)}
							value={type}
							placeholder='Tipo do elemento'>
							{allowedTypes.map((type) => (
								<Select.Option key={type} value={type}>
									{getElementIcon({ type })}
									<span style={{ marginLeft: 4 }}>{parseElementName({ type })}</span>
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					{type &&
						(type === 'CASHTRACK' ? (
							<>
								<Form.Item label='Artista'>
									<Input
										placeholder='Informe o artista da música'
										value={artist}
										style={{ marginBottom: 8, width: '100%' }}
										onChange={({ target: { value } }) => setArtist(value)}
									/>
								</Form.Item>

								<Form.Item label='Nome'>
									<Input
										placeholder='Informe o nome da música'
										value={name}
										style={{ marginBottom: 8, width: '100%' }}
										onChange={({ target: { value } }) => setName(value)}
									/>
								</Form.Item>

								<Form.Item
									label='Data de expiração'
									help={
										expirationDate ? (
											<>
												Essa música estará na sua programação até{' '}
												<strong>{format(new Date(expirationDate), 'dd/MM/yyyy')}</strong>
											</>
										) : (
											'Após essa data, o elemento será automaticamente excluído e removido do modelo'
										)
									}>
									<DatePicker
										value={expirationDate}
										format='DD/MM/YYYY'
										style={{ marginBottom: 8, width: '100%' }}
										placeholder='Informe até quando a música deve tocar'
										onChange={(date) => setExpirationDate(date)}
									/>
								</Form.Item>
							</>
						) : (
							<>
								<Form.Item label='Nome'>
									<Input
										placeholder='Informe um nome para o elemento'
										value={name}
										style={{ marginBottom: 8, width: '100%' }}
										onChange={({ target: { value } }) => setName(value)}
									/>
								</Form.Item>

								{type !== 'TESTIMONIAL' && (
									<Form.Item label='Cor do elemento'>
										<Input
											style={{ cursor: 'pointer', marginBottom: 8 }}
											type='color'
											value={color}
											onChange={({ target: { value } }) => setColor(value)}
										/>
									</Form.Item>
								)}
							</>
						))}

					<FilesUploader
						multiple={false}
						onChange={(files) => setFile(files[0])}
						onStartValidation={() => setIsValidating(true)}
						onEndValidation={() => setIsValidating(false)}
						validTypes={['.mp3', '.wav']}
					/>
				</Form.Container>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(AdsElements);
