import styled from 'styled-components';

export const Container = styled.div`
	background: #fff;
	padding: 16px 16px;

	& .ant-breadcrumb > span:last-child {
		font-weight: 600 !important;
		opacity: 0.8;
	}

	& .ant-breadcrumb-link {
		font-size: 12px;
	}

	& > header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 32px;

		& > h2 {
			color: rgba(0, 0, 0, 0.75) !important;
			display: flex;
			align-items: center;
			margin: 0;
			margin-bottom: -10px;
		}
	}
`;

export const PaginationContainer = styled.div`
	margin-top: 24px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const CardHeader = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2px;

	& > strong {
		font-size: 18px;
	}

	& > span {
		opacity: 0.8;
		font-weight: 400;
		font-size: 14px;
	}
`;

export const ScheduleGrid = styled.ul`
	width: 100%;
	display: grid;
	margin: 0;
	grid-template-columns: repeat(7, 1fr);
	gap: 12px;
`;

export const ScheduleDay = styled.li`
	list-style: none;
	border-radius: var(--border-radius-base);
	padding: 4px 12px;
	display: flex;
	flex-direction: column;
	border: 1px solid
		${({ isActive }) => (isActive ? 'var(--primary-opacity-85)' : 'var(--primary-opacity-25)')};
	background: ${({ isActive }) =>
		isActive ? 'var(--primary-opacity-85)' : 'var(--primary-opacity-25)'};
	color: #fff;

	& > small {
		font-size: 11px;
		margin-left: 26px;
	}

	& > div {
		gap: 12px;
		display: flex;
		align-items: center;

		& strong {
			font-weight: 500;
		}
	}
`;

export const EditScheduleContainer = styled.div`
	display: flex;
	flex-direction: column;

	& > div.info {
		border-bottom: 1px dashed #dedede;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 6px;
		padding-bottom: 8px;

		& > span {
			font-size: 14px;
		}

		& > strong {
			font-size: 15px;
			font-weight: 500;
		}

		&:last-child {
			margin-bottom: 0px;
		}
	}

	& > ul {
		margin-top: 32px;
		display: flex;
		flex-direction: column;
		gap: 12px;

		& > li {
			display: flex;
			cursor: pointer;
			padding: 12px;
			gap: 12px;
			border: 1px solid #dedede;
			border-radius: var(--border-radius-base);

			&:hover {
				border-color: var(--primary);
			}

			& > div {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				& > strong {
					font-weight: 500;
				}

				& > small {
					font-size: 11px;
				}
			}
		}
	}
`;
