import React from 'react';
import { Typography, Divider, Alert } from 'antd';

import Meta from '../../../../components/Meta';
import HelpButtons from '../../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img04 from '../../../../assets/images/tutorials/main-voices/img-04.PNG';
import img07 from '../../../../assets/images/tutorials/main-voices/img-07.PNG';


const { Title, Paragraph } = Typography;

const ConfigIA = () => (
	<Container>
		<Meta title='Ajuda - Configurando a IA' />

		<Title>Configuração de IA de Voz</Title>

		<Paragraph>
		A IA de voz da TalkPlay é uma tecnologia avançada utilizada para criar e apresentar programas de rádio de forma automática e personalizada. Vamos detalhar como ela é usada e como pode ser configurada:
		</Paragraph>

		<ul>
			<li>
				<i>Falas Globais: Tudo o que a IA sabe fala em nível global.</i>
			</li>
			<li>
				<i>Falas Locais: Tudo o que a IA sabe fala de forma local</i>
			</li>
		</ul>

		<Paragraph>
		<strong><i> Criação de Programas de Rádio</i></strong>: A IA de voz é utilizada para locução automática de programas de rádio. Ela pode ser programada para realizar várias tarefas, como cumprimentar os ouvintes, mencionar o nome da rádio, solicitar músicas, fornecer notícias, e outros conteúdos específicos.
		</Paragraph>

		<Paragraph>
		<strong> <i>Personalização do Conteúdo</i></strong>: A IA pode ser instruída para incluir saudações, menções especiais, e segmentos específicos do programa, de acordo com as necessidades da estação de rádio.
		</Paragraph>

		<Paragraph>
		<strong><i> Flexibilidade na Programação</i></strong>: Os produtores podem manipular os módulos de fala da IA, decidindo quando e como ela fala, ajustando a quantidade e a natureza das intervenções.
		</Paragraph>

		<Paragraph>
		<strong>  <i>Interatividade e Variedade</i></strong>: A IA é capaz de responder a eventos ou interações específicas, como participações de ouvintes, mantendo a consistência na apresentação e oferecendo variação no conteúdo do programa.
		</Paragraph>
		<Paragraph>
		<strong>  <i>Autonomia com Controle</i></strong>: Apesar de ser uma IA autônoma, os produtores mantêm o controle editorial, podendo ajustar aspectos específicos da fala da IA.
		</Paragraph>

		<Paragraph>
			Em Elementos, procure pelas cabeças de IA<i> 2 CABEÇAS</i> `{`->`}`{' '}
			<strong><i>LK para Falas Locais</i>,  `{`->`}`{' '}		 <i>TK para Falas Globais</i></strong>. Ao clicar na fala ela será jogada para dentro do bloco do seu programa. Você deverá arastar para a posição desejada.
		</Paragraph>

		<ImgContainer>
			<img src={img04} alt='Voz IA' />
		</ImgContainer>
		<Paragraph>
		<strong>  <i>Utilização de Memórias da IA:</i></strong>:
		</Paragraph>
		<ul>
			<li>
				<i><strong>Memória LK</strong>: Relacionada especificamente à rádio, incluindo o nome da estação, slogans, etc.</i>
			</li>
			<li>
				<i><strong>Memória TK</strong>: Usada para frases e expressões gerais.</i>
			</li>
		</ul>

		<Paragraph>
		Ao inserir elementos dessas memórias, a IA pode criar frases coerentes e contextuais. Por exemplo, combinar "Bom Dia" com "Nome da Rádio" permite à IA gerar uma saudação personalizada que inclui um cumprimento e a identificação da rádio.A IA é programada para variar as falas em programas subsequentes, evitando conteúdo repetitivo.
		</Paragraph>
		<ul>
			<li>
				<i><strong>01</strong>: Clique nos 3 pontinhos para abrir os menus dos elementos.</i>
			</li>
			<li>
				<i><strong>02</strong>: Clique no menu 'ADICIONAR OFF' `{`->`}`{' '} Escolha uma Memória de IA.	</i>
			</li>
			<li>
				<i><strong>03</strong>: Selecione a memória de fala que deseja.</i>
			</li>
			<li>
				<i><strong>04</strong>: Clique em 'Adicionar' para inserir ela no modelo do bloco.</i>
			</li>
			<li>
				<i><strong>05</strong>: Repita o procedimento até que esteja satisfeito.</i>
			</li>
		</ul>
		<ImgContainer>
			<img src={img07} alt='TVoz IA' />
		</ImgContainer>

		<Alert
			showIcon
			type='warning'
			description='Essas informações detalham como a IA de voz da TalkPlay funciona e como pode ser configurada para atender às necessidades específicas de uma estação de rádio, oferecendo uma solução personalizada e interativa para a programação de rádio.'
		/>

		<Divider />

		<HelpButtons
			prev={{ label: 'Trilhas Sonoras', to: '/help/soundtracks' }}
			next={{ label: 'Locutores', to: '/help/casters' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default ConfigIA;
