import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const FiltersContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: end;
`;
