import { useContext } from 'react';

import { PlayerContext } from '../contexts/PlayerContext';

const usePlayer = () => {
	const context = useContext(PlayerContext);

	return context;
};

export default usePlayer;
