import React, { useCallback, useEffect, useState } from 'react';

import UpsertUserContext from './context';
import {
	DEFAULT_ACCOUNT_INFOS,
	DEFAULT_PERSONAL_INFOS,
	DEFAULT_PERMISSIONS,
	DEFAULT_MODULES,
} from './defaultData';

const UpsertUserProvider = ({ action, children }) => {
	const [personalInfos, setPersonalInfos] = useState(DEFAULT_PERSONAL_INFOS);
	const [accountInfos, setAccountInfos] = useState(DEFAULT_ACCOUNT_INFOS);
	const [permissions, setPermissions] = useState(DEFAULT_PERMISSIONS);
	const [modules, setModules] = useState(DEFAULT_MODULES());
	const [network, setNetwork] = useState([]);
	console.log(modules);
	const handleChangePersonalInfo = useCallback((e) => {
		e?.persist && e.persist();
		setPersonalInfos((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	}, []);

	const handleChangeAccountInfo = useCallback((e) => {
		e?.persist && e.persist();
		setAccountInfos((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	}, []);

	const handleChangePermission = useCallback((key, value) => {
		setPermissions((prev) => ({ ...prev, [key]: value }));
	}, []);

	const getModuleState = useCallback((subitems) => {
		const checked = Object.values(subitems).every((v) => v);
		const indeterminate = checked ? false : Object.values(subitems).some((v) => v);

		return {
			checked,
			indeterminate,
		};
	}, []);

	const onModuleCheckboxClick = useCallback(
		(key) => {
			setModules((modules) => {
				const subitems = modules[key];
				const { checked } = getModuleState(subitems);

				for (const subitemKey in subitems) {
					subitems[subitemKey] = !checked;
				}

				return { ...modules, [key]: subitems };
			});
		},
		[getModuleState]
	);

	const onModuleSubitemCheckboxClick = useCallback((moduleKey, itemKey) => {
		setModules((modules) => {
			const value = modules[moduleKey][itemKey];
			modules[moduleKey][itemKey] = !value;
			return { ...modules };
		});
	}, []);

	const getData = useCallback(() => {
		const user = { ...personalInfos, ...accountInfos };
		const resources = {
			...modules,
			contentGeneration: {
				RADIO_SIGNATURE: permissions.RADIO_SIGNATURE,
				TRACK_SIGNATURE: permissions.TRACK_SIGNATURE,
				NOCTURNAL_GENERATION: permissions.NOCTURNAL_GENERATION,
				AUTO_DOWNLOAD: permissions.AUTO_DOWNLOAD,
				MAX_GENERATIONS_PER_PROGRAM: permissions.MAX_GENERATIONS_PER_PROGRAM,
				MASTERIZATION: permissions.MASTERIZATION,
			},
			contentCreation: {
				PROGRAMS_LIMIT: permissions.PROGRAMS_LIMIT,
				MAX_BLOCKS_PER_PROGRAM: permissions.MAX_BLOCKS_PER_PROGRAM,
				MAX_MUSICS_PER_BLOCK: permissions.MAX_MUSICS_PER_BLOCK,
				PROGRAMS_SHARING: permissions.PROGRAMS_SHARING,
			},
		};

		return {
			user,
			resources,
			network: permissions.PROGRAMS_SHARING ? network : [],
		};
	}, [network, accountInfos, modules, permissions, personalInfos]);

	const initUser = useCallback((data) => {
		setPersonalInfos({
			name: data.user?.name,
			surname: data.user?.surname,
			radioName: data.user?.radioName,
			email: data.user?.email,
			cpf: data.user?.cpf || '___.___.___-__',
			cnpj: data.user?.cnpj || '__.___.___/____-__',
			phone: data.user?.phone,
			city: data.user?.city,
			state: data.user?.state,
		});

		setAccountInfos({
			type: data.user?.type,
			withLiveContent: data.user?.withLiveContent,
			caster: data.user?.caster || undefined,
			intranetId: data.user?.intranetId || '',
			paymentDay: data.user?.paymentDay || undefined,
			password: data.user?.password,
			passwordConfirmation: data.user?.passwordConfirmation,
		});

		setNetwork(data.network.users);

		setModules({
			tracks: { ...DEFAULT_MODULES().tracks, ...data.user?.resourcesId.tracks },
			news: { ...DEFAULT_MODULES().news, ...data.user?.resourcesId.news },
			wpps: { ...DEFAULT_MODULES().wpps, ...data.user?.resourcesId.wpps },
			interviews: { ...DEFAULT_MODULES().interviews, ...data.user?.resourcesId.interviews },
			offs: { ...DEFAULT_MODULES().offs, ...data.user?.resourcesId.offs },
			ads: { ...DEFAULT_MODULES().ads, ...data.user?.resourcesId.ads },
			blitz: { ...DEFAULT_MODULES().blitz, ...data.user?.resourcesId.blitz },
			vignettes: { ...DEFAULT_MODULES().vignettes, ...data.user?.resourcesId.vignettes },
			other: { ...DEFAULT_MODULES().other, ...data.user?.resourcesId.other }
		});

		setPermissions({
			RADIO_SIGNATURE: data.user?.resourcesId.contentGeneration.RADIO_SIGNATURE,
			TRACK_SIGNATURE: data.user?.resourcesId.contentGeneration.TRACK_SIGNATURE,
			NOCTURNAL_GENERATION: data.user?.resourcesId.contentGeneration.NOCTURNAL_GENERATION,
			AUTO_DOWNLOAD: data.user?.resourcesId.contentGeneration.AUTO_DOWNLOAD,
			MAX_GENERATIONS_PER_PROGRAM:
				data.user?.resourcesId.contentGeneration.MAX_GENERATIONS_PER_PROGRAM,
			MASTERIZATION: data.user?.resourcesId.contentGeneration.MASTERIZATION,
			PROGRAMS_LIMIT: data.user?.resourcesId.contentCreation.PROGRAMS_LIMIT,
			MAX_BLOCKS_PER_PROGRAM: data.user?.resourcesId.contentCreation.MAX_BLOCKS_PER_PROGRAM,
			MAX_MUSICS_PER_BLOCK: data.user?.resourcesId.contentCreation.MAX_MUSICS_PER_BLOCK,
			PROGRAMS_SHARING: data.user?.resourcesId.contentCreation.PROGRAMS_SHARING,
		});
	}, []);

	useEffect(() => {
		if (action === 'CREATE') {
			if (accountInfos.type === 'ADMIN') {
				setModules(DEFAULT_MODULES(true));
			} else {
				setModules(DEFAULT_MODULES(false));
			}
		}
	}, [action, accountInfos.type]);

	return (
		<UpsertUserContext.Provider
			value={{
				personalInfos,
				accountInfos,
				permissions,
				modules,
				handleChangePermission,
				handleChangeAccountInfo,
				handleChangePersonalInfo,
				getModuleState,
				onModuleCheckboxClick,
				onModuleSubitemCheckboxClick,
				network,
				setNetwork,
				getData,
				initUser,
			}}>
			{children}
		</UpsertUserContext.Provider>
	);
};

export default UpsertUserProvider;
