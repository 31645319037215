import { useCallback } from 'react';
import fileSaver from 'file-saver';
import JSZip from 'jszip';
import axios from 'axios';
import path from 'path';

const useZip = () => {
	const getFileName = useCallback((filenames, filename, ext) => {
		const filesWithSameName = filenames.filter((n) => n === filename);
		const name = filesWithSameName.length
			? `${filename}_${filesWithSameName.length + 1}`
			: filename;

		return `${name}.${ext}`;
	}, []);

	const getExt = useCallback((url) => {
		return path.extname(url).split('?')[0].replace('.', '');
	}, []);

	const download = useCallback(
		async (data, zipName, onProgress) => {
			const zip = new JSZip();
			const files = Array.isArray(data) ? data : [data];
			const filenames = [];

			const downloadingPercents = files.map(() => 0);

			await Promise.all(
				Object.entries(files).map(async ([index, file]) => {
					const response = await axios.get(file.url, {
						responseType: 'arraybuffer',
						maxContentLength: Infinity,
						onDownloadProgress:
							typeof onProgress === 'function'
								? ({ total, loaded }) => {
										onProgress({
											index: Number(index),
											completed: Math.floor((loaded * 100) / total),
											speed: downloadingPercents[index]
												? Math.floor((loaded - downloadingPercents[index]) / 100)
												: 0,
										});

										downloadingPercents[index] = loaded;
								  }
								: undefined,
					});

					const ext = getExt(file.url);
					const blob = new Blob([response.data], { type: ext });

					zip.file(getFileName(filenames, file.name, ext), blob);
					filenames.push(file.name);
				})
			);

			const zipContent = await zip.generateAsync({ type: 'blob' });
			return fileSaver.saveAs(zipContent, zipName, {});
		},
		[getExt, getFileName]
	);

	return {
		download,
	};
};

export default useZip;
