const INITIAL_STATE = null;

export default function user(state = INITIAL_STATE, action) {
	if (action.type === 'SET_USER') {
		const data = Object.assign(action.data.user, { token: action.data.token });

		return data;
	}

	if (action.type === 'UPDATE_USER') {
		return Object.assign(action.state, action.data);
	}

	if (action.type === 'LOGOUT') return INITIAL_STATE;

	return state;
}
