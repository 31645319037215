import styled from 'styled-components';

export const Container = styled.div`
	background: #fff;
	padding: 16px 16px;

	& .ant-breadcrumb > span:last-child {
		font-weight: 600 !important;
		opacity: 0.8;
	}

	& .ant-breadcrumb-link {
		font-size: 12px;
	}

	& > header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 32px;

		& > h2 {
			color: rgba(0, 0, 0, 0.75) !important;
			display: flex;
			align-items: center;
			margin: 0;
			margin-bottom: -10px;
		}
	}
`;

export const TableHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;

	& > div.actions {
		padding-right: 24px;
		margin-right: 24px;
		border-right: 1px solid #dedede;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex: 1;

		& > span {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.65);
		}

		& > div {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			& > .ant-btn {
				margin: 0 2px;
			}
		}
	}
`;
