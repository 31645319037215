import React, { useState, useEffect, useCallback } from 'react';
import {
	PageHeader,
	Table,
	Card,
	Dropdown,
	Menu,
	Icon,
	Input,
	Typography,
	Modal,
	message,
	Tooltip,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import { Container, ReasonCell, SearchContainer } from './styles';

import ProgramsAPI from '../../../../services/sdks/programs';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Conteúdos' },
		{ breadcrumbName: 'Requisições de exclusão' },
	],
	style: { marginBottom: 12 },
};

const DeleteRequests = () => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [filteredRequests, setFilteredRequests] = useState([]);
	const [requests, setRequests] = useState([]);

	const columns = [
		{
			key: 'id',
			ellipsis: true,
			title: 'ID',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					6
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Nome',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Rádio',
			dataIndex: 'userId',
			key: 'userId',
			render: (user) => `${user?.radioName}`,
		},
		{
			title: 'Motivo da Exclusão',
			dataIndex: 'deleteReason',
			key: 'deleteReason',
			align: 'center',
			ellipsis: true,
			render: (deleteReason) => (
				<Tooltip title={deleteReason} placement='left'>
					<ReasonCell>{deleteReason}</ReasonCell>
				</Tooltip>
			),
		},
		{
			title: 'Ações',
			key: 'actions',
			align: 'right',
			render: (program) => (
				<Dropdown
					placement='bottomRight'
					overlay={
						<Menu>
							<Menu.Item
								onClick={() => {
									Modal.confirm({
										title: 'Revogar pedido?',
										icon: 'exclamation-circle',
										content:
											'O programa retornará a grade do usuário normalmente, deseja continuar?',
										okText: 'Confirmar',
										onOk: () => handleRejectDeleteRequest(program),
										okButtonProps: {
											icon: 'stop',
											type: 'primary',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}
							>
								<Icon type='stop' /> Revogar pedido
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								className='ant-dropdown-menu-item-danger'
								onClick={() => {
									Modal.confirm({
										title: 'Excluir programa?',
										icon: 'exclamation-circle',
										content:
											'O programa será retirado da grade do usuário, realmente deseja continuar?',
										okText: 'Excluir',
										onOk: () => handleDeleteProgram(program._id),
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}
							>
								<Icon type='delete' /> Excluir programa
							</Menu.Item>
						</Menu>
					}
				>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 12 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleSearch = useCallback(
		(search) => {
			if (!search) {
				return setFilteredRequests(requests);
			}

			setFilteredRequests(
				requests.filter((request) => {
					const userMatch = request?.userId?.radioName.toLowerCase().includes(search.toLowerCase());
					const programMatch = request?.name.toLowerCase().includes(search.toLowerCase());

					return userMatch || programMatch;
				})
			);
		},
		[requests]
	);

	const handleDeleteProgram = useCallback(async (programId) => {
		try {
			await ProgramsAPI.destroy(programId);

			setRequests((prev) => prev.filter((p) => p?._id !== programId));
			setFilteredRequests((prev) => prev.filter((p) => p?._id !== programId));

			message.success('O programa foi excluído com sucesso');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, []);

	const handleRejectDeleteRequest = useCallback(async (program) => {
		try {
			const { _id: programId, ...rest } = program;
			const payload = { ...rest, deleteRequested: false, deleteReason: null };

			await ProgramsAPI.update(programId, payload);

			setRequests((prev) => prev.filter((p) => p?._id !== programId));
			setFilteredRequests((prev) => prev.filter((p) => p?._id !== programId));

			message.success('O pedido de exclusão foi rejeitado');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, []);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { programs: deleteRequests },
				} = await ProgramsAPI.index(`deleteRequested=true&isDeleted=false`);

				setRequests(deleteRequests);
				setFilteredRequests(deleteRequests);
				setFallback((fallback) => ({ ...fallback, initialData: false }));
			} catch (error) {
				console.error(error);
			}
		};

		fetchInitialData();
	}, []);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor aguarde' />;
	}

	return (
		<>
			<Meta title='Requisições de exclusão' />

			<PageHeader title='Requisições de Exclusão' breadcrumb={breadcrumb}>
				<Typography.Text>Pedidos para exclusão de programas feitas por usuários</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<SearchContainer>
						<Input.Search
							size='large'
							allowClear
							style={{ width: 300 }}
							placeholder='Buscar por programa ou usuário'
							onSearch={handleSearch}
						/>
					</SearchContainer>

					<Table
						columns={columns}
						dataSource={filteredRequests}
						size='middle'
						pagination={{ size: 'large' }}
						rowKey='_id'
					/>
				</Card>
			</Container>
		</>
	);
};

export default DeleteRequests;
