import api from '../api';

const BASE_URL = '/api/v2/soundtracks';

const SoundtracksAPI = {
	index: (query) => api.get(`${BASE_URL}?${query}`),
	store: (payload, onUploadProgress) => api.post(BASE_URL, payload, { onUploadProgress }),
	update: (soundtrackId, payload) => api.put(`${BASE_URL}/${soundtrackId}`, payload),
	destroy: (soundtrackId) => api.delete(`${BASE_URL}/${soundtrackId}`),
};

export default SoundtracksAPI;
