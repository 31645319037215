import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px;

	& > .ant-card {
		margin: 12px 0;
	}

	& .ssl-card {
		grid-column: span 3;

		& > .ant-card-body {
			padding: 12px !important;
		}
	}

	& .ant-statistic-content-value {
		font-size: 36px;
	}
`;

export const FlexHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > span {
		display: flex;
		align-items: center;
	}
`;

export const ChartFooter = styled.div`
	width: 100%;
	margin-top: 12px;
	padding-top: 12px;
	border-top: 1px solid #dedede;
	display: flex;

	& span {
		font-size: 12px;
		text-transform: uppercase;
		color: rgba(0, 0, 0, 0.65);

		& > strong {
			margin-left: 4px;
		}
	}

	& div.divider {
		margin: 0 12px;
		width: 1px;
		background: #dedede;
	}
`;

export const RealtimeGenerationContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 32px;
	margin: 12px 0;

	& div.chart-wrapper {
		display: flex;
		flex-direction: column;
	}
`;

export const ListWrapper = styled.div`
	width: 100%;
	height: 290px;
	overflow: auto;
`;

export const TooltipContainer = styled.div`
	border-radius: var(--border-radius-base);
	background: #fff;
	border: 1px solid #dedede;
	display: flex;
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	& > i {
		margin-bottom: 16px;
		font-size: 18px;
	}

	& > span {
		font-size: 12px;
		color: rgba(0, 0, 0, 0.65);
		margin-bottom: 2px;
	}

	& > strong {
		font-size: 15px;
		color: rgba(0, 0, 0, 0.55);
	}
`;

export const AutoEpsProgressContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	& i.icon {
		font-size: 22px;
		margin-right: 4px;
	}

	& i.icon-success {
		color: var(--succes);
	}

	& > h4 {
		margin-bottom: 10px;
	}
`;
