import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const ButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	& > button {
		margin-left: 8px;
	}
`;

export const highlightStyle = {
	background: '#7bff90',
	color: '#3a3a3a',
};
