import styled from 'styled-components';

export const Section = styled.div`
	width: 100%;
	padding: 3rem 1rem;
	background-color: #000000;

	@media (min-width: 768px) {
		padding: 6rem 1.5rem;
	}

	@media (min-width: 1024px) {
		padding: 8rem 2rem;
	}
`;

export const Container = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	text-align: center;
	background-color: black;
`;

export const TitleContainer = styled.div`
	margin-bottom: 3rem;
`;

export const Badge = styled.div`
	display: inline-block;
	padding: 0.5rem 1rem;
	background-color: #17181E;
	color: #7F10EE;
	border-radius: 0.375rem;
	font-size: 0.875rem;
	margin-bottom: 1rem;
`;

export const Title = styled.h2`
	font-size: 2.25rem;
	font-weight: bold;
	color: #cfcfcf;
	line-height: 1.2;
	margin-bottom: 1rem;

	@media (min-width: 640px) {
		font-size: 3rem;
	}
`;

export const Description = styled.p`
	max-width: 700px;
	margin: 0 auto;
	font-size: 1rem;
	color: #82828A;
	margin-bottom: 2rem;

	@media (min-width: 768px) {
		font-size: 1.25rem;
	}
`;

export const Grid = styled.div`
	display: grid;
	gap: 2rem;

	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 1024px) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	padding: 2rem;
	background-color: #17181E;
	border-radius: 0.75rem;
	border: 1px solid #000000;
`;

export const CardIcon = styled.svg`
	width: 3rem;
	height: 3rem;
	color: #cfcfcf;
`;

export const CardTitle = styled.h3`
	font-size: 1.25rem;
	font-weight: bold;
	color: #7F10EE;
`;

export const CardDescription = styled.p`
	font-size: 1rem;
	color: #82828A;
	text-align: center;
`;
