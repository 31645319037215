import React from 'react';
import { Typography, Divider, Alert } from 'antd';

import Meta from '../../../../components/Meta';
import HelpButtons from '../../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../../assets/images/tutorials/bancomusical/img-01.png';
import img02 from '../../../../assets/images/tutorials/bancomusical/img-02.png';
import img03 from '../../../../assets/images/tutorials/bancomusical/img-03.png';

const { Title, Paragraph } = Typography;

const MTalk = () => (
	<Container>
		<Meta title='Ajuda - Banco Musical' />

		<Title>Banco Musical TalkPlay</Title>

		<Paragraph>
		Na TalkPlay, a seleção musical é uma combinação de arte e ciência, essencial para a criação de programas de rádio cativantes e envolventes. Compreendemos que a escolha das músicas certas é tão importante quanto ter uma boa plástica de rádio. Por isso, a IA da TalkPlay é meticulosamente treinada para atender a esses detalhes vitais.		</Paragraph>
		<Paragraph>
		Em nossa abordagem, diferenciamos dois tipos de músicas com base em uma linha do tempo específica:		</Paragraph>
		<Paragraph>
		1 - <strong>Antes da TalkPlay</strong>: Aqui, as músicas são escolhidas humanamente, com base em conhecimento histórico. Este processo permite uma seleção musical que respeita as tendências e preferências já estabelecidas ao longo do tempo.		</Paragraph>
		<Paragraph>
		2 - <strong>Após a TalkPlay</strong>: Esta categoria é mais dinâmica, onde as músicas são auditadas pela TalkPlay, observando dados e comportamentos extraídos de várias fontes. A TalkPlay analisa meticulosamente a frequência de execução das músicas. Quando uma música atinge um número específico de execuções (um número X de milhões de vezes), ela é considerada potencialmente bem-sucedida. Se isso acontecer, a música é apresentada à equipe TalkRadio para aprovação e, uma vez aprovada, é incluída no BANCO MUSICAL para uso nos programas. Para a TalkPlay, se uma música é executada um número padrão de vezes, isso indica uma preferência generalizada e, portanto, há uma alta probabilidade de que 99% dos ouvintes irão aderir se tocar no programa.		</Paragraph>
		<Paragraph>
		<strong>Um ponto importante a ser destacado é o tratamento das músicas das grandes rádios, consideradas pela TalkPlay como Jaba. Estas músicas são enviadas diretamente para pastas específicas, conhecidas como "Charts10" de cada segmento e "Charts100", que inclui a mistura de todas as músicas. Consideradas Jaba, cada emissora tem a opção de usar essas pastas em seus conteúdos se assim desejar.	</strong>	</Paragraph>
		<Paragraph>
			Para acessar, navegue até <i><strong>Banco Musical</strong></i> no menu lateral:
		</Paragraph>
		<ImgContainer>
			<img src={img01} alt='Banco Musical' />
		</ImgContainer>

		<Paragraph>
			Para acessar as músicas, selecione em <i>Filtrar por categoria</i> podendo ser Artista ou Nome da Música:
		</Paragraph>

		<ImgContainer>
			<img src={img02} alt='Banco Musical' />
		</ImgContainer>

		<Paragraph>
			Para ouvir clique no play da música.
		</Paragraph>

		<ImgContainer>
			<img src={img03} alt='Banco Musical' />
		</ImgContainer>


		<Alert
			showIcon
			type='warning'
			description='Acesse o Menu lateral "Enviando Minhas Músicas" para aprender como enviar suas músicas para a TalkPlay.'
		/>

		<HelpButtons
			prev={{
				label: 'Narrações Rede',
				to: '/help/main-voices',
			}}
			next={{ label: 'Playlists', to: '/help/programs/playlists' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 05/02/2024</Paragraph>
	</Container>
);

export default MTalk;
