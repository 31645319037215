import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 20vh; /* Diminuído para reduzir os espaços superiores e inferiores */
	background-color: black;
	color: white;
`;

export const Title = styled.h1`
	font-size: 2.5rem;
	font-weight: bold;
	margin-bottom: 1rem;
	color: #7f10ee;
`;

export const Subtitle = styled.p`
	font-size: 1.125rem;
	margin-bottom: 2rem;
`;

