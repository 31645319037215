import api from '../api';

const BASE_URL = '/api/v2/tkvoices';
const headers = { 'Content-Type': 'multipart/form-data' };

const TkVoicesAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	destroy: ({ tkVoiceId }) => api.delete(`${BASE_URL}/${tkVoiceId}`),
	store: ({ payload, onUploadProgress }) =>
		api.post(BASE_URL, payload, { headers, onUploadProgress }),
};

export default TkVoicesAPI;
