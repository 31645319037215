import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Menu } from 'antd';

import { LinkContainer } from './styles';
import {
	FiPieChart,
	FiSliders,
	FiUsers,
	FiMic,
	FiFileText,
	FiEdit,
	FiCast,
	FiFileMinus,
	FiList,
	FiLayout,
} from 'react-icons/fi';

const AdminNavigation = ({ collapsed }) => {
	const [selectedKeys, setSelectedKeys] = useState(['generation-requests']);

	const renderMenuTitle = useCallback(
		(title, Icon, to) =>
			to ? (
				<Link to={to}>
					{collapsed ? (
						<>
							<Icon style={{ fontSize: 18, marginBottom: -4 }} />
						</>
					) : (
						<LinkContainer>
							<Icon /> {title}
						</LinkContainer>
					)}
				</Link>
			) : collapsed ? (
				<>
					<Icon style={{ fontSize: 18, marginBottom: -4 }} />
				</>
			) : (
				<LinkContainer>
					<Icon /> {title}
				</LinkContainer>
			),
		[collapsed]
	);

	return (
		<Menu
			theme='dark'
			mode='inline'
			style={{ border: 'none' }}
			selectedKeys={selectedKeys}
			defaultOpenKeys={['analysis']}
			onSelect={({ key }) => setSelectedKeys([key])}>
			<Menu.SubMenu key='analysis' title={renderMenuTitle('Análises', FiPieChart)}>
				<Menu.Item key='generation-requests'>
					<Link to='/admin/contents/programs/generation-requests'>Requisições de Geração</Link>
				</Menu.Item>

				<Menu.Item key='dash'>
					<Link to='/admin/analysis/dashboard'>Dashboard</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.SubMenu key='general' title={renderMenuTitle('Geral', FiSliders)}>
				<Menu.Item key='banners'>
					<Link to='/admin/general/banners'>Mensagens</Link>
				</Menu.Item>
				<Menu.Item key='tkplay-config'>
					<Link to='/admin/general/tkplay-config'>Nomenclatura TKPLAY</Link>
				</Menu.Item>
				<Menu.Item key='ai-voices-config'>
					<Link to='/admin/general/ai-voices-config'>Nomenclatura IA</Link>
				</Menu.Item>
				<Menu.Item key='mixage-config'>
					<Link to='/admin/general/mixage-config'>Mixagem</Link>
				</Menu.Item>
				<Menu.Item key='active-users'>
					<Link to='/admin/general/categories'>Categorias Musicais</Link>
				</Menu.Item>
				<Menu.Item key='inactive-users'>
					<Link to='/admin/general/genres'>Gêneros</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.SubMenu key='templates' title={renderMenuTitle('Templates', FiLayout)}>
				<Menu.Item key='script-templates'>
					<Link to='/admin/templates/script-templates'>Templates de Modelo</Link>
				</Menu.Item>
				<Menu.Item key='script-templates'>
					<Link to='/admin/templates/block-templates'>Templates de Bloco</Link>
				</Menu.Item>
				<Menu.Item key='playlist-script-templates'>
					<Link to='/admin/templates/playlist-script-templates'>Templates de Playlist</Link>
				</Menu.Item>
				<Menu.Item key='VIGNETTE-TEMPLATEs'>
					<Link to='/admin/templates/vignettes'>Vinhetas de Template</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.SubMenu key='demos' title={renderMenuTitle('Demos', FiList)}>
				<Menu.Item key='program-demos'>
					<Link to='/admin/demos/programs'>Programas</Link>
				</Menu.Item>

				<Menu.Item key='voice-demos'>
					<Link to='/admin/demos/ia-voices'>Vozes IA</Link>
				</Menu.Item>

				<Menu.Item key='news-demos'>
					<Link to='/admin/demos/news'>Notícias</Link>
				</Menu.Item>

				<Menu.Item key='cash-track-demos'>
					<Link to='/admin/demos/cash-tracks'>Música Cash</Link>
				</Menu.Item>

				<Menu.Item key='ads-demos'>
					<Link to='/admin/demos/ads'>ADS</Link>
				</Menu.Item>

				<Menu.Item key='ads-demos'>
					<Link to='/admin/demos/casters'>Demo IA</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.SubMenu key='users' title={renderMenuTitle('Usuários', FiUsers)}>
				<Menu.Item key='active-users'>
					<Link to='/admin/users/active-users'>Usuários Ativos</Link>
				</Menu.Item>
				<Menu.Item key='inactive-users'>
					<Link to='/admin/users/inactive-users'>Usuários Inativos</Link>
				</Menu.Item>
				<Menu.Item key='/admin/contact-requests'>
					<Link to='/admin/contact-requests'>Requisições de Contato</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.Item key='casters'>{renderMenuTitle('Locutores', FiMic, '/admin/casters')}</Menu.Item>

			<Menu.SubMenu key='contents' title={renderMenuTitle('Conteúdos', FiCast)}>
				<Menu.Item key='manage-programs'>
					<Link to='/admin/contents/programs'>Gerenciar Programas</Link>
				</Menu.Item>
				<Menu.Item key='generation-schedules'>
					<Link to='/admin/contents/generation-schedules'>Cronogramas de Geração</Link>
				</Menu.Item>
				<Menu.Item key='create-program'>
					<Link to='/admin/contents/programs/create-program'>Novo Programa</Link>
				</Menu.Item>
				<Menu.Item key='delete-requests'>
					<Link to='/admin/contents/programs/delete-requests'>Requisições de Exclusão</Link>
				</Menu.Item>

				<Divider style={{ marginTop: 5, marginBottom: 5, opacity: 0.1 }} />

				<Menu.Item key='create-sctipt'>
					<Link to='/admin/contents/scripts/create-script'>Criar Modelo</Link>
				</Menu.Item>
				<Menu.Item key='edit-script'>
					<Link to='/admin/contents/scripts/edit-script'>Editar Modelo</Link>
				</Menu.Item>

				<Divider style={{ marginTop: 5, marginBottom: 5, opacity: 0.1 }} />

				<Menu.Item key='manage-elements'>
					<Link to='/admin/contents/elements'>Gerenciar Elementos</Link>
				</Menu.Item>
				<Menu.Item key='manage-tk-voices'>
					<Link to='/admin/contents/tk-voices'>Gerenciar IA's</Link>
				</Menu.Item>
				<Menu.Item key='signatures'>
					<Link to='/admin/contents/signatures'>Assinaturas de Rádio</Link>
				</Menu.Item>
				<Menu.Item key='offs'>
					<Link to='/admin/contents/offs'>Gerenciar Offs</Link>
				</Menu.Item>

				<Divider style={{ marginTop: 5, marginBottom: 5, opacity: 0.1 }} />

				<Menu.Item key='weekly-scripts'>
					<Link to='/admin/contents/playlists/weekly-scripts'>Modelos Semanais</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.SubMenu key='contracts' title={renderMenuTitle('Contratos', FiFileText)}>
				<Menu.Item key='manage-contracts'>
					<Link to='/admin/contracts'>Gerenciar contratos</Link>
				</Menu.Item>

				<Menu.Item key='create-contract'>
					<Link to='/admin/contracts/create-contract'>Criar contrato</Link>
				</Menu.Item>

				<Menu.Item key='billets'>
					<Link to='/admin/contracts/billets'>Boletos</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.SubMenu key='plans' title={renderMenuTitle('Planos', FiEdit)}>
				<Menu.Item key='active-plans'>
					<Link to='/admin/plans/active-plans'>Planos ativos</Link>
				</Menu.Item>
				<Menu.Item key='inactive-plans'>
					<Link to='/admin/plans/inactive-plans'>Planos inativos</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.Item key='terms'>
				{renderMenuTitle('Termos de Uso', FiFileMinus, '/admin/terms')}
			</Menu.Item>
		</Menu>
	);
};

export default AdminNavigation;
