import React, { useState, useEffect, useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import qs from 'query-string';
import {
	Modal,
	Tabs,
	Tag,
	Divider,
	message,
	Typography,
	Breadcrumb,
	Button,
	Input,
	Switch,
	Select,
	Alert,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import Form from '../../../../components/Form';
import Scripts from '../../../../components/ProgramDetailsTabs/Scripts';
import Vignettes from '../../../../components/ProgramDetailsTabs/Vignettes';
import CustomElements from '../../../../components/ProgramDetailsTabs/CustomElements';
import AdsElements from '../../../../components/ProgramDetailsTabs/AdsElements';
import RecordedOffs from '../../../../components/ProgramDetailsTabs/RecordedOffs';
import LocalNews from '../../../../components/ProgramDetailsTabs/LocalNews';
import LocalOffs from '../../../../components/ProgramDetailsTabs/LocalOffs';
import LocalBlitz from '../../../../components/ProgramDetailsTabs/LocalBlitz';
import LocalWpps from '../../../../components/ProgramDetailsTabs/LocalWpps';
import LocalInterviews from '../../../../components/ProgramDetailsTabs/LocalInterviews';
import NightlyGenerationSchedule from '../../../../components/ProgramDetailsTabs/NightlyGenerationSchedule';
import { checkResource, checkResourcesGroup } from '../../../../helpers/checkResource';

import { Container } from './styles';

import GenresAPI from '../../../../services/sdks/genres';
import ProgramsAPI from '../../../../services/sdks/programs';
import ShareAPI from '../../../../services/sdks/share';

import {
	FiFileText,
	FiList,
	FiStopCircle,
	FiGlobe,
	FiMic,
	FiVoicemail,
	FiDollarSign,
	FiEdit,
	FiTrash2,
	FiBook,
	FiCalendar,
	FiXCircle,
	FiSave,
	FiZap,
	FiUser,
	FiPlay,
} from 'react-icons/fi';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import TKPlayOffs from '../../../../components/ProgramDetailsTabs/TKPlayOffs';
import NightlyGenerationSchedulesAPI from '../../../../services/sdks/nightly-generation-schedules';
import userTypes from '../../../../constants/userTypes';
import resourcesKeys from '../../../../constants/resourcesKeys';
import resourceGroupsKeys from '../../../../constants/resourceGroupsKeys';

const ProgramDetails = ({ user }) => {
	const { search } = useLocation();
	const { programId } = useParams();
	const history = useHistory();

	const [fallback, setFallback] = useState({ initialData: true });
	const [genres, setGenres] = useState([]);
	const [program, setProgram] = useState(null);
	const [updatedProgram, setUpdatedProgram] = useState(program);
	const [scripts, setScripts] = useState(null);
	const [visibleModals, setVisibleModals] = useState({ edit: false });
	const [deleteReason, setDeleteReason] = useState('');
	const [sharing, setSharing] = useState(null);
	const [nightlyGenerationSchedule, setNightlyGenerationSchedule] = useState(null);

	/** elements */
	const [elements, setElements] = useState(null);
	const [customElements, setCustomElements] = useState(null);

	const isSharedProgram = useMemo(() => {
		if (!user || !program) {
			return undefined;
		}

		return program?.userId?._id !== user?._id;
	}, [user, program]);

	const showTalkManagedElements = useMemo(() => {
		return [userTypes.ADMIN, userTypes.TALK].includes(user?.type) && !isSharedProgram;
	}, [user, isSharedProgram]);

	useEffect(() => {
		const fetchNightlyGenerationSchedule = async () => {
			setFallback((prev) => ({ ...prev, fetchingNightlyGenerationSchedule: true }));

			try {
				const res = await NightlyGenerationSchedulesAPI.getByProgramId(programId);
				const { weekSchedule, ...rest } = res.data.nightlyGenerationSchedule;

				setNightlyGenerationSchedule({
					...rest,
					weekSchedule: weekSchedule.sort((a, b) => (a.day > b.day ? 1 : -1)),
				});
			} catch (error) {
				if (error.status === 404) {
					setNightlyGenerationSchedule(null);
				} else {
					message.error('Houve um erro ao buscar o cronograma de geração noturna');
				}
			}

			setFallback((prev) => ({ ...prev, fetchingNightlyGenerationSchedule: false }));
		};

		const fetchInitialData = async () => {
			try {
				const {
					data: { program },
				} = await ProgramsAPI.show(programId);
				const {
					data: { genres },
				} = await GenresAPI.index();

				setProgram(program);
				setGenres(genres);

				const isSharedProgram = program?.userId?._id !== user?._id;

				if (isSharedProgram) {
					const {
						data: { sharings },
					} = await ShareAPI.index(`user=${user?._id}&program=${program._id}`);

					if (sharings) {
						setSharing(sharings[0]);
					}
				}

				return setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
			}
		};

		if (user && programId) {
			fetchInitialData();
			fetchNightlyGenerationSchedule();
		}
	}, [programId, user]);

	useEffect(() => {
		setUpdatedProgram({
			name: program?.name,
			description: program?.description,
			filenameTemplate: program?.filenameTemplate,
			genreId: program?.genreId?._id,
			auto: program?.auto,
		});
	}, [program]);

	const handleUpdateAuto = useCallback(
		async (auto) => {
			try {
				setFallback((prev) => ({ ...prev, updatingNocturnalGeneration: true }));

				if (isSharedProgram) {
					await ShareAPI.modify(sharing._id, { auto });
					setSharing((prev) => ({ ...prev, auto }));
				} else {
					await ProgramsAPI.update(programId, { auto });
					setProgram((prev) => ({ ...prev, auto }));
				}

				setFallback((prev) => ({ ...prev, updatingNocturnalGeneration: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao atualizar a geração noturna');
			}
		},
		[isSharedProgram, sharing, programId]
	);

	const handleUpdateInfos = useCallback(async () => {
		try {
			const payload = updatedProgram;
			const validationSchema = Yup.object().shape({
				name: Yup.string().required('Informe o nome'),
				description: Yup.string().required('Informe a descrição'),
				filenameTemplate: Yup.string().required('Informe o nome dos arquivos'),
				genreId: Yup.string().required('Informe o gênero'),
			});

			await validationSchema.validate(payload);

			setFallback((prev) => ({ ...prev, updating: true }));

			await ProgramsAPI.update(programId, payload);

			const g = genres.find(({ _id }) => _id === payload?.genreId);

			setProgram({ ...program, ...payload, genreId: g });
			setVisibleModals((prev) => ({ ...prev, edit: false }));
			setFallback((prev) => ({ ...prev, updating: false }));

			return message.success('Informações editadas com sucesso!');
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				return message.error(`${error.message} do programa`);
			}

			console.error(error);
		}
	}, [updatedProgram, program, programId, genres]);

	const handleRequestProgramExclusion = useCallback(async () => {
		try {
			if (!deleteReason) {
				return message.warn('Por favor, informe o motivo');
			}

			setFallback((prev) => ({ ...prev, deleting: true }));

			await ProgramsAPI.update(program._id, { deleteReason, deleteRequested: true });

			setDeleteReason('');
			setProgram((prev) => ({ ...prev, deleteReason, deleteRequested: true }));
			setFallback((prev) => ({ ...prev, deleting: false }));
			setVisibleModals((prev) => ({ ...prev, delete: false }));

			return message.success('A exclusão foi enviada para análise');
		} catch (error) {
			console.error(error);
		}
	}, [program, deleteReason]);

	const handleCancelProgramExclusion = useCallback(async () => {
		try {
			setFallback((prev) => ({ ...prev, exclusionRequest: true }));

			await ProgramsAPI.update(program._id, { deleteReason: null, deleteRequested: false });

			setProgram((prev) => ({ ...prev, deleteReason: null, deleteRequested: false }));
			setFallback((prev) => ({ ...prev, exclusionRequest: false }));

			return message.success('A exclusão foi cancelada');
		} catch (error) {
			console.error(error);
		}
	}, [program]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title={program?.name || ''} />

			<Container>
				<Breadcrumb
					separator='>'
					style={{ marginBottom: 24 }}
					routes={[
						{ breadcrumbName: 'INÍCIO' },
						{ breadcrumbName: 'CONTEÚDOS' },
						{ breadcrumbName: 'GERENCIAR PROGRAMAS' },
						{ breadcrumbName: 'DETALHES' },
					]}
				/>
				<header>
					<div className='title'>
						<Typography.Title level={2}>{program?.name}</Typography.Title>

						{!program?.isEditable && (
							<Tag color='red' style={{ marginLeft: 12 }}>
								Programa não editável
							</Tag>
						)}

						{isSharedProgram && (
							<Tag color='gold' style={{ marginLeft: 12 }}>
								Programa compartilhado
							</Tag>
						)}
					</div>
					<div className='buttons'>
						<Button
							type='primary'
							onClick={() => setVisibleModals({ ...visibleModals, edit: true })}
							disabled={isSharedProgram || !program?.isEditable}>
							<FiEdit /> Editar
						</Button>
						<Button
							type='danger'
							onClick={() => setVisibleModals({ ...visibleModals, delete: true })}
							disabled={isSharedProgram || program?.deleteRequested}>
							<FiTrash2 /> Excluir
						</Button>
					</div>
				</header>

				<div className='infos'>
					<div>
						<FiBook /> {program?.genreId?.name}
					</div>
					<div>
						<FiCalendar /> Criado em {format(parseISO(program?.dateUpload), 'dd/MM/yyyy')}
					</div>

					{checkResource(user, resourcesKeys.NOCTURNAL_GENERATION) && (
						<div>
							<Form.Item label='Geração noturna'>
								<Switch
									disabled={fallback?.updatingNocturnalGeneration}
									style={{ width: 'max-content' }}
									checked={isSharedProgram ? sharing.auto : program.auto}
									onChange={(value) => handleUpdateAuto(value)}
								/>
							</Form.Item>
						</div>
					)}
				</div>

				{program?.deleteRequested && (
					<Alert
						showIcon
						type='error'
						message={
							<div
								style={{
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}>
								<span>Exclusão requisitada!</span>
								<Button size='small' type='danger' onClick={handleCancelProgramExclusion}>
									Cancelar
								</Button>
							</div>
						}
						description={`➤ ${program?.deleteReason}`}
						style={{ width: '100%', margin: '24px 0' }}
					/>
				)}

				<Divider />

				<Tabs
					activeKey={qs.parse(search).tab || 'scripts'}
					tabPosition='left'
					onChange={(tab) => history.push(`${history.location.pathname}?tab=${tab}`)}>
					<Tabs.TabPane
						key='scripts'
						tab={
							<>
								<FiFileText />
								Modelos
							</>
						}>
						<Scripts data={scripts} setData={setScripts} program={program} user={user} />
					</Tabs.TabPane>

					{!isSharedProgram && program.auto && (
						<Tabs.TabPane
							key='nightly-generation-schedule'
							tab={
								<>
									<FiCalendar />
									Geração Noturna
								</>
							}>
							<NightlyGenerationSchedule
								data={nightlyGenerationSchedule}
								setData={setNightlyGenerationSchedule}
								program={program}
							/>
						</Tabs.TabPane>
					)}

					{!isSharedProgram && checkResource(user, resourcesKeys.VIGNETTES_PROGRAM) && (
						<Tabs.TabPane
							key='vignettes'
							tab={
								<>
									<FiList />
									Vinhetas
								</>
							}>
							<Vignettes
								data={elements}
								setData={setElements}
								fallback={fallback}
								program={program}
								user={user}
							/>
						</Tabs.TabPane>
					)}

					{!isSharedProgram && checkResource(user, resourcesKeys.CUSTOMS) && (
						<Tabs.TabPane
							key='custom-elements'
							tab={
								<>
									<FiStopCircle />
									Customizados
								</>
							}>
							<CustomElements
								data={customElements}
								setData={setCustomElements}
								program={program}
								user={user}
							/>
						</Tabs.TabPane>
					)}

					{checkResourcesGroup(user, resourceGroupsKeys.ADS) && (
						<Tabs.TabPane
							key='ads'
							tab={
								<>
									<FiDollarSign />
									Publicidades
								</>
							}>
							<AdsElements program={program} />
						</Tabs.TabPane>
					)}

					{!isSharedProgram && checkResource(user, resourcesKeys.OFFS_LOCAL) && (
						<Tabs.TabPane
							key='local-offs'
							tab={
								<>
									<FiMic />
									Offs Locais
								</>
							}>
							<LocalOffs program={program} />
						</Tabs.TabPane>
					)}

					{showTalkManagedElements && (
						<Tabs.TabPane
							key='recorded-offs'
							tab={
								<>
									<FiVoicemail />
									TKVOX
								</>
							}>
							<RecordedOffs program={program} />
						</Tabs.TabPane>
					)}

					{showTalkManagedElements && (
						<Tabs.TabPane
							key='tk-play'
							tab={
								<>
									<FiPlay />
									TKPLAY
								</>
							}>
							<TKPlayOffs program={program} />
						</Tabs.TabPane>
					)}

					{checkResource(user, resourcesKeys.NEWS_LOCAL) && (
						<Tabs.TabPane
							key='local-news'
							tab={
								<>
									<FiGlobe />
									Notícias locais
								</>
							}>
							<LocalNews program={program} />
						</Tabs.TabPane>
					)}

					{checkResource(user, resourcesKeys.BLITZ_LOCAL) && (
						<Tabs.TabPane
							key='local-blitz'
							tab={
								<>
									<FiZap />
									Blitz locais
								</>
							}>
							<LocalBlitz program={program} />
						</Tabs.TabPane>
					)}

					{checkResource(user, resourcesKeys.INTERVIEW_LOCAL) && (
						<Tabs.TabPane
							key='local-interviews'
							tab={
								<>
									<FiUser />
									Entrevistas locais
								</>
							}>
							<LocalInterviews program={program} />
						</Tabs.TabPane>
					)}

					{checkResource(user, resourcesKeys.WPP_LOCAL) && (
						<Tabs.TabPane
							key='local-wpps'
							tab={
								<>
									<AiOutlineWhatsApp />
									Wpp's locais
								</>
							}>
							<LocalWpps program={program} />
						</Tabs.TabPane>
					)}
				</Tabs>
			</Container>

			<Modal
				visible={visibleModals?.edit}
				okButtonProps={{ loading: fallback?.updating }}
				cancelButtonProps={{ disabled: fallback?.updating }}
				onCancel={() => setVisibleModals({ ...visibleModals, edit: false })}
				onOk={handleUpdateInfos}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				okText={
					<>
						<FiSave /> Atualizar
					</>
				}
				title={
					<>
						<FiEdit /> Editar informações{' '}
					</>
				}>
				<Form.Container>
					<Form.Item label='Nome'>
						<Input
							value={updatedProgram?.name}
							onChange={({ target: { value } }) =>
								setUpdatedProgram({ ...updatedProgram, name: value })
							}
						/>
					</Form.Item>

					<Form.Item label='Descrição'>
						<Input.TextArea
							value={updatedProgram?.description}
							onChange={({ target: { value } }) =>
								setUpdatedProgram({ ...updatedProgram, description: value })
							}
						/>
					</Form.Item>
					<Form.Item label='Template de arquivo'>
						<Input
							value={updatedProgram?.filenameTemplate}
							onChange={({ target: { value } }) =>
								setUpdatedProgram({ ...updatedProgram, filenameTemplate: value })
							}
						/>
					</Form.Item>

					<Form.Item label='Gênero'>
						<Select
							value={updatedProgram?.genreId}
							onChange={(genreId) => setUpdatedProgram({ ...updatedProgram, genreId })}>
							{genres.map((g) => (
								<Select.Option key={g._id}>{g?.name}</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Form.Container>
			</Modal>

			<Modal
				visible={visibleModals?.delete}
				title={
					<>
						<FiTrash2 />
						Requisitar exclusão do programa
					</>
				}
				okText={
					<>
						<FiTrash2 /> Enviar requisição de exclusão
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				onOk={handleRequestProgramExclusion}
				onCancel={() => setVisibleModals({ ...visibleModals, delete: false })}
				okButtonProps={{ loading: fallback?.deleting }}
				cancelButtonProps={{ disabled: fallback?.deleting }}>
				<Typography.Text>
					Após requisitar a exclusão desse programa, seu pedido irá passar pela análise de nossos
					administradores e somente após aprovação, o mesmo será apagado.
				</Typography.Text>
				<Divider />

				<Typography.Paragraph>
					Por favor, informe o motivo da exclusão no campo abaixo:
				</Typography.Paragraph>

				<Input.TextArea
					value={deleteReason}
					rows={1}
					placeholder='Informe o motivo da exclusão'
					onChange={({ target: { value } }) => setDeleteReason(value)}
				/>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(ProgramDetails);
