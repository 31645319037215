import React, { useMemo } from 'react';
import { useDraggable } from '@dnd-kit/core';

import { DraggableDayContainer } from './styles';

const DragabbleDay = ({ id, label }) => {
	const day = useMemo(() => String(id), [id]);

	const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
		id: day,
	});

	const style = transform
		? {
				transform: `translate3d(${transform.x}px, ${transform.y}px, 0) rotateZ(${
					isDragging ? '3deg' : '0deg'
				})`,
		  }
		: {};

	return (
		<DraggableDayContainer
			isDragging={isDragging}
			ref={setNodeRef}
			style={style}
			{...listeners}
			{...attributes}>
			{label}
		</DraggableDayContainer>
	);
};

export { DragabbleDay };
