import React from 'react';
import { Container, Input as StyledInput, MaskedInput as StyledMaskedInput, Label } from './styles';

export const Input = ({ label, onChange, placeholder, style, variant, type, error }) => {
	return (
		<Container style={style}>
			<Label>{label}</Label>
			<StyledInput
				error={!!error}
				type={type ?? 'text'}
				variant={variant ?? 'blue'}
				placeholder={placeholder}
				onChange={(e) => onChange(e.target.value)}
			/>
			{error && <small>{error}</small>}
		</Container>
	);
};

export const MaskedInput = ({
	label,
	mask,
	onChange,
	placeholder,
	style,
	variant,
	type,
	error,
}) => {
	return (
		<Container style={style}>
			<Label>{label}</Label>
			<StyledMaskedInput
				error={!!error}
				type={type}
				variant={variant ?? 'blue'}
				mask={mask}
				placeholder={placeholder}
				onChange={(e) => onChange(e.target.value)}
			/>
			{error && <small>{error}</small>}
		</Container>
	);
};
