import styled from 'styled-components';

export const Container = styled.div`
	background-color: #000000;
	color: #cfcfcf;
	padding: 3rem;
`;

export const Header = styled.h2`
	text-align: center;
	font-size: 3rem;
	font-weight: bold;
	color: #ffffff;
	margin-bottom: 1.5rem;
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 2rem;
`;

export const ContentItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const IconWrapper = styled.div`
	background-color: #000;
	border-radius: 50%;
	padding: 1rem;
	margin-bottom: 0.5rem;

	.icon {
		width: 2rem;
		height: 2rem;
		color: #ffffff;
	}
`;

export const Description = styled.p`
	text-align: center;
	font-size: 0.875rem;
	color: #7C7C85;
`;

export const ArrowIcon = styled.div`
	.icon {
		width: 1.5rem;
		height: 1.5rem;
		color: #ffffff;
	}
`;


