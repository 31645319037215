import React from 'react';
import { useDroppable } from '@dnd-kit/core';

import { FiMinusCircle } from 'react-icons/fi';
import { EmptyColumn, DroppableZoneContainer } from './styles';
import { DragabbleDay } from './DraggableDay';

const daysLabels = {
	0: 'Domingo',
	1: 'Segunda-Feira',
	2: 'Terça-Feira',
	3: 'Quarta-Feira',
	4: 'Quinta-Feira',
	5: 'Sexta-Feira',
	6: 'Sábado',
};

const DroppableZone = ({ id, data, label }) => {
	const weekDay = String(id);

	const { setNodeRef, isOver } = useDroppable({
		id: weekDay,
	});

	if (!data?.length) {
		return (
			<DroppableZoneContainer ref={setNodeRef} isOver={isOver}>
				<EmptyColumn>
					<FiMinusCircle />
					<span>Nenhum dia atribuido</span>
					<small>
						O programa não será gerado no(a) {label}. Para ativar a geração noturna, arraste pelo
						menos um dia da semana para cá
					</small>
				</EmptyColumn>
			</DroppableZoneContainer>
		);
	}

	return (
		<DroppableZoneContainer ref={setNodeRef} isOver={isOver}>
			{data.map((dayKey) => (
				<DragabbleDay
					id={dayKey}
					weekDay={weekDay}
					label={daysLabels[dayKey]}
					key={dayKey}
					currentDay={dayKey}
				/>
			))}
		</DroppableZoneContainer>
	);
};

export { DroppableZone };
