import api from '../api';

const BASE_URL = '/api/v2/mixage-templates';

const MixageTemplatesAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	show: (templateId) => api.get(`${BASE_URL}/${templateId}`),
	store: (payload) => api.post(BASE_URL, payload),
	update: (templateId, payload) => api.put(`${BASE_URL}/${templateId}`, payload),
	destroy: (templateId) => api.delete(`${BASE_URL}/${templateId}`),
};

export default MixageTemplatesAPI;
