import api from '../api';

const BASE_URL = '/api/v2/playlist-script-templates';

const PlaylistScriptTemplatesAPI = {
	getAll: () => api.get(BASE_URL),
	getOne: (id) => api.get(`${BASE_URL}/${id}`),
	create: (data) => api.post(BASE_URL, data),
	update: (id, data) => api.put(`${BASE_URL}/${id}`, data),
	delete: (id) => api.delete(`${BASE_URL}/${id}`),
};

export default PlaylistScriptTemplatesAPI;
