import api from '../api';

const BASE_URL = '/api/v2/news';

const NewsAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	show: ({ newsId }) => api.get(`${BASE_URL}/${newsId}`),
	store: ({ payload, onUploadProgress }) => api.post(BASE_URL, payload, { onUploadProgress }),
	update: ({ newsId, payload }) => api.put(`${BASE_URL}/${newsId}`, payload),
	destroy: ({ newsId }) => api.delete(`${BASE_URL}/${newsId}`),
	updateGlobalFlag: ({ payload }) => api.patch(`${BASE_URL}/`, payload),
};

export default NewsAPI;
