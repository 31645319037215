import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
	memo,
	forwardRef,
	useImperativeHandle,
} from 'react';

import { BsFiletypeWav, BsFiletypeMp3, BsFiletypeRaw } from 'react-icons/bs';
import { FiPlus, FiXCircle } from 'react-icons/fi';
import { MdCheckCircle, MdError, MdOutlineDriveFolderUpload } from 'react-icons/md';

import {
	Container,
	FilesList,
	FileItem,
	FilesListTitle,
	UploadZone,
	FileIconContainer,
	FileItemAttributes,
	FileItemHeader,
	FileItemInfos,
	UploadZoneLabel,
	RemoveFileButton,
	ValidatingFile,
	FileStatus,
	Input,
	FilesListHeader,
} from './styles';
import { Button, Icon, Popconfirm, Progress, Spin, message } from 'antd';
import { useFilesValidator } from '../../hooks';

const IconsMap = {
	'.mp3': BsFiletypeMp3,
	'.wav': BsFiletypeWav,
	common: BsFiletypeRaw,
};

export const FileValidationStatusMessages = {
	VALIDATING: 'Validando arquivo, por favor aguarde',
	INVALID_TYPE: 'Formato inválido. Por favor, selecione um arquivo com formato aceito',
	CORRUPTED_FILE: 'Arquivo corrompido. Por favor, selecione outro arquivo',
	VALID_FILE: 'Integridade do arquivo validada com sucesso',
};
export const FileValidationStatusCodes = {
	VALID: 'VALID',
	INVALID: 'INVALID',
	VALIDATING: 'VALIDATING',
	UPLOADING: 'UPLOADING',
};

const FilesUploader = forwardRef(
	(
		{
			onChange,
			onStartValidation,
			onEndValidation,
			validTypes,
			multiple,
			amount,
			help,
			isDisabled,
			customValidations,
		},
		ref
	) => {
		const [validatedFiles, setValidatedFiles] = useState([]);
		const mainInputRef = useRef(null);
		const secondaryInputRef = useRef(null);
		const validator = useFilesValidator({ validTypes, customValidations });

		const removeFile = useCallback(
			(index) => {
				setValidatedFiles((prev) => {
					const newValidations = [...prev];
					newValidations.splice(index, 1);
					typeof onChange === 'function' && onChange(newValidations);
					return newValidations;
				});
			},
			[onChange]
		);

		const validateFiles = useCallback(
			async (files) => {
				typeof onStartValidation === 'function' && onStartValidation();
				onChange([]);
				const validations = files;

				for (const [index, file] of Object.entries(files)) {
					const { code, message } = await validator.validateFile(file, index);

					validations[index].status = {
						code,
						message,
					};

					setValidatedFiles((prev) => {
						const newValidations = [...prev];
						newValidations[index].status = {
							code,
							message,
						};

						return newValidations;
					});
				}

				onChange(validations);
				typeof onEndValidation === 'function' && onEndValidation();
			},
			[onStartValidation, onChange, onEndValidation, validator]
		);

		const renderFileIcon = useCallback((ext) => {
			const Icon = IconsMap[ext] || IconsMap.common;

			return (
				<FileIconContainer>
					<Icon />
				</FileIconContainer>
			);
		}, []);

		const renderFileFooter = useCallback((file) => {
			if (file.status.code === FileValidationStatusCodes.VALIDATING) {
				return (
					<ValidatingFile>
						<Spin
							size='small'
							indicator={<Icon spin type='setting' style={{ color: '#494949' }} />}
						/>
						<small>{file.status.message}</small>
					</ValidatingFile>
				);
			}

			if (file.status.code === FileValidationStatusCodes.UPLOADING) {
				return <Progress percent={file.upload.progress} size='small' />;
			}

			return (
				<FileStatus status={file.status.code}>
					{file.status.code === FileValidationStatusCodes.VALID ? <MdCheckCircle /> : <MdError />}
					<small>{file.status.message}</small>
				</FileStatus>
			);
		}, []);

		const renderFileSize = useCallback((file) => {
			const sizeInMb = parseFloat((file.metadata.size / 1000000).toFixed(1));
			return sizeInMb < 1 ? `${sizeInMb * 1000} KB` : `${Math.floor(sizeInMb)} MB`;
		}, []);

		const upadateUploadProgress = useCallback(
			(index, progress) => {
				setValidatedFiles((prev) => {
					const newValidations = [...prev];
					newValidations[index].status.code = FileValidationStatusCodes.UPLOADING;
					newValidations[index].upload.progress = progress;
					onChange(newValidations);
					return newValidations;
				});
			},
			[onChange]
		);

		const handleSelectFiles = useCallback(
			(e) => {
				const files = Array.from(e.target.files);

				if (!isNaN(amount) && files.length !== amount) {
					return message.info(`Por favor, selecione exatamente ${amount} arquivos`);
				}

				if (files.length) {
					const filesList = files.map((file, index) => {
						return {
							upload: {
								progress: 0,
								update: (progress) => upadateUploadProgress(index, progress),
							},
							data: file,
							metadata: validator.getFileMetadata(file),
							status: {
								code: FileValidationStatusCodes.VALIDATING,
								message: FileValidationStatusMessages.VALIDATING,
							},
						};
					});

					setValidatedFiles(filesList);
					validateFiles(filesList);
				} else {
					setValidatedFiles([]);
				}
			},
			[amount, validateFiles, upadateUploadProgress, validator]
		);

		const getValidatedFiles = useCallback(
			() =>
				validatedFiles.filter((f) =>
					[FileValidationStatusCodes.VALID, FileValidationStatusCodes.INVALID].includes(
						f.status.code
					)
				),
			[validatedFiles]
		);

		const getValidFiles = useCallback(
			() => validatedFiles.filter((f) => f.status.code === FileValidationStatusCodes.VALID),
			[validatedFiles]
		);

		const getInvalidFiles = useCallback(
			() => validatedFiles.filter((f) => f.status.code === FileValidationStatusCodes.INVALID),
			[validatedFiles]
		);

		useImperativeHandle(
			ref,
			() => ({
				getValidatedFiles,
				getValidFiles,
				getInvalidFiles,
				reset: () => setValidatedFiles([]),
			}),
			[getInvalidFiles, getValidFiles, getValidatedFiles]
		);

		useEffect(() => {
			return () => {
				setValidatedFiles([]);
			};
		}, []);

		useEffect(() => {
			const list = new DataTransfer();

			for (const file of validatedFiles) {
				list.items.add(file.data);
			}

			mainInputRef.current.files = list.files;
		}, [validatedFiles]);

		return (
			<Container>
				<Input
					multiple={multiple}
					type='file'
					ref={mainInputRef}
					accept={validTypes.join(', ')}
					onChange={handleSelectFiles}
				/>

				<UploadZone
					isDisabled={isDisabled}
					onClick={() => mainInputRef.current.click()}
					data-help={validatedFiles.length ? '' : help}>
					<UploadZoneLabel>
						<MdOutlineDriveFolderUpload />
						<strong>Selecione os arquivos para upload</strong>
						<span>Formatos aceitos: mp3, wav</span>
					</UploadZoneLabel>
				</UploadZone>

				{validatedFiles.length > 0 && (
					<>
						<FilesListHeader>
							<FilesListTitle>Arquivos Selecionados</FilesListTitle>

							<Popconfirm
								arrowPointAtCenter
								onConfirm={() => {
									setValidatedFiles([]);
									typeof onChange === 'function' && onChange([]);
								}}
								title='Remover todos os arquivos?'
								okType='danger'
								okText='Remover'
								placement='left'>
								<Button size='small' type='default'>
									Remover Todos
								</Button>
							</Popconfirm>
						</FilesListHeader>

						<FilesList>
							{validatedFiles.map((file, index) => (
								<FileItem key={index}>
									<FileItemHeader>
										<FileItemInfos>
											{renderFileIcon(file.metadata.ext)}
											<div>
												<span>{file.metadata.name}</span>
												<FileItemAttributes>
													<small>{file.metadata.ext.replaceAll('.', '')}</small>
													<small style={{ opacity: 0.5, margin: '0 4px' }}>•</small>
													<small>{renderFileSize(file)}</small>
												</FileItemAttributes>
											</div>
										</FileItemInfos>

										<Popconfirm
											arrowPointAtCenter
											onConfirm={() => removeFile(index)}
											title='Realmente deseja remover esse arquivo?'
											okType='danger'
											okText='Remover'
											placement='left'>
											<RemoveFileButton>
												<FiXCircle />
											</RemoveFileButton>
										</Popconfirm>
									</FileItemHeader>

									{renderFileFooter(file)}
								</FileItem>
							))}
						</FilesList>

						{!isNaN(amount) && validatedFiles.length !== amount && (
							<Button
								type='dashed'
								size='large'
								onClick={() => secondaryInputRef.current.click()}
								style={{ marginBottom: 16, width: '100%' }}>
								<FiPlus /> Adicionar Arquivos
							</Button>
						)}
					</>
				)}
			</Container>
		);
	}
);

export default memo(FilesUploader, (props, nextProps) => false);
