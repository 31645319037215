import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    :root {
        --border-radius-base: 4px;
        --border-radius-strong: 6px;
        --border-radius-strongest: 12px;

        --primary: #102b9c;
        --primary-opacity-10: #102b9c10;
        --primary-opacity-25: #849af35e;
        --primary-opacity-65: #102b9ca6;
        --primary-opacity-85: #102b9ce0;
        --primary-strong: #071a66;

        --succes: #3DD598;
        --succes-opacity-10: #e5f0f0;
        --succes-opacity-25: #d6eee7;
        --succes-opacity-65: #a9e6d0;
        --succes-opacity-85: #93e3c4;

        --danger: #f03a51;
        --danger-opacity-10: #ffe6ea;
        --danger-opacity-25: #f0d7dd;
        --danger-opacity-65: #f0a9b4;
        --danger-opacity-85: #f0929f;
        --danger-strong: #dc1730;

        --warning: #FF974A;
        --warning-opacity-10: #f1eceb;
        --warning-opacity-25: #f2e5dc;
        --warning-opacity-65: #f6ceb1;
        --warning-opacity-85: #f8c39c;

        --info: #0062FF;
        --info-opacity-10: #e1e9f6;
        --info-opacity-25: #cdddf6;
        --info-opacity-65: #91b9f9;
        --info-opacity-85: #73a7fa;

        --lp-yellow: #E7A62C;
        --lp-blue: #102B9C;
        --lp-red: #C13935;
        --lp-gray: #808289;
        --lp-gray-light: #F2F2F9;
    }

    * {
        margin: 0;
        padding: 0;
    }

    html,
    body,
    #root {
        scroll-behavior: smooth;
        height: 100%;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
    }

    a {
        color: var(--primary);
        position: relative;

        &::after {
           content: '' ;
           position: absolute;
           top: 95%;
           left: 0;
           width: 0;
           height: 1px;
           background: var(--primary-strong);
           transition: width 150ms;
           transition-delay: 100ms;
           transition-timing-function: ease-in;
        }

    }

    a:hover {
        color: var(--primary-opacity-85);
    }

    a.__link:hover {
        &::after {
            width: 100%;
        }
    }

    .ant-btn {
        text-shadow: none !important;
        font-size: 15px !important;
        font-weight: 500 !important;
        transition: 150ms !important;

        & > svg {
            margin-right: 6px !important;
            font-size: 17px;
            transform: translateY(3px);
        }
    }

    .ant-btn > i.anticon {
        font-size: 18px !important;
    }

    .ant-btn-danger {
        background: var(--danger);
        border-color: var(--danger);

        &:hover {
            background: var(--danger-strong);
            border-color: var(--danger-strong);

        }
    }

    .ant-btn-primary {
        background: var(--primary);
        border-color: var(--primary);

        &:hover {
            background: var(--primary-strong);
            border-color: var(--primary-strong);

        }
    }

    .ant-input::placeholder {
        font-size: 14px !important;
    }

    .ant-input, .ant-select-selection {
        transition: 200ms !important;
        /* background: #f9f9f9 !important; */
    }

    .ant-input:hover {
        border-color: var(--primary);

        &::placeholder {
            color: var(--primary-opacity-65);
        }
    }

    .ant-input:focus {
        box-shadow: 0px 0px 0px 2px var(--primary-opacity-65) !important;
        border-color: var(--primary);
        color: var(--primary);
        background: var(--primary-opacity-10);

        &::placeholder {
            color: var(--primary-opacity-65);
        }
    }

    .has-error .ant-input {
        color: var(--danger);

        &::placeholder {
            color: var(--danger-opacity-85);
        }
    }

    .has-error .ant-input:focus {
        box-shadow: 0px 0px 0px 2px var(--danger-opacity-85) !important;
        border-color: var(--danger);
        background: var(--danger-opacity-10) !important;
    }

    .has-error .ant-input:hover {
        border-color: var(--danger);
        background: var(--danger-opacity-10);

        &::placeholder {
            color: var(--danger-opacity-65);
        }
    }

    .ant-dropdown-menu-item-danger {
        color: var(--danger) !important;

        &:hover {
            background: var(--danger-opacity-25) !important;
            border-color: var(--danger) !important;
        }
    }

    .ant-dropdown-menu-item-disabled {
        color: inherit !important;
        opacity: .6;

        &:hover {
            background: inherit !important;
        }
    }

    .ant-layout-content {
        margin: 0 !important;
        border-bottom: none !important;
    }

    .ant-card {
        border-radius: var(--border-radius-base) !important;
    }

    .ant-page-header {
        background: #FFF !important;
    }

    .ant-table-content {
        border: 1px solid #e8e8e8;
        border-radius: 10px;
    }

    .ant-table-body {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .ant-table-row:nth-child(odd) {
        background: #8c52f710;
    }

    .ant-table-row:nth-child(even) {
        background: #8c52f702;
    }

    .ant-table-row:hover {
        color: rgba(0,0,0,.9) !important;
    }

    .ant-table-pagination {
        width: 100% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .ant-page-header-heading > span {
        font-size: 30px !important;
    }

    .ant-btn-ghost {
        border-color: var(--primary-opacity-10) !important;
        color: var(--primary-opacity-85) !important;
        background: var(--primary-opacity-10) !important;

        &:hover {
            background: var(--primary-opacity-25) !important;
        }
    }

    .ant-btn-ghost:disabled {
        border-color: #d9d9d9 !important;
        color: rgba(0, 0, 0, 0.25) !important;
        background: #f5f5f5 !important;

        &:hover {
            background: #f5f5f5 !important;
        }
    }

    .ant-page-header {
        border-bottom: 1px solid #e8e8e8 !important;
    }

    .ant-page-header-content {
        padding-top: 0 !important;
    }

    .ant-page-header-heading {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 2px !important;
        margin-top: 0 !important;
    }

    .ant-form-item-label > label:after {
        content: '' !important;
    }

    .ant-btn-sm  {
        font-size: 14px !important;
    }

    .ant-btn-sm  > i.anticon {
        font-size: 16px !important;
    }

    .ant-spin-spinning  {
        & > i, .ant-spin-text {
            color: var(--primary);
        }
    }

    .ant-tag {
        font-weight: 500;
    }

    .ant-tag > svg {
        margin-bottom: -1px;
    }

    .ant-drawer-title, .ant-modal-title {
        display: flex !important;
        align-items: center !important;

        & > svg {
            margin-right: 6px;
            font-size: 18px;
            transform: translateY(-1px);
        }
    }

    .ant-dropdown-menu-item {
      display: flex !important;
      align-items: center !important;

      & > svg {
        margin-right: 4px;
      }
    }

    .ant-tabs-tab > svg {
        margin-right: 4px;
        transform: translateY(2px);
    }

    .ant-btn > svg {
        margin-right: 4px;
        transform: translateY(3px);
    }

    .ant-calendar-picker, .ant-calendar-picker * {
      cursor: pointer !important;
    }

    .uppercase-input {
        text-transform: uppercase;

        &::placeholder {
            text-transform: lowercase;
        }

        &::placeholder::first-letter {
            text-transform: uppercase !important;
        }
    }
`;
