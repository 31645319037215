import React from 'react';
import { Typography, Divider, Alert } from 'antd';

import Meta from '../../../../components/Meta';
import HelpButtons from '../../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img05 from '../../../../assets/images/tutorials/signatures/img-05.png';
import img06 from '../../../../assets/images/tutorials/signatures/img-06.png';
import img07 from '../../../../assets/images/tutorials/signatures/img-07.png';

const { Title, Paragraph } = Typography;

const SignatureMusicas = () => (
	<Container>
		<Meta title='Ajuda - Assinaturas Músicas' />

		<Title>Assinaturas Músicas</Title>

		<Alert
			showIcon
			type='warning'
			description={
				<>
					Este produto é cobrado à parte. Verifique a Disponibilidade
				</>
			}
		/>
<Paragraph></Paragraph>
		<Paragraph>
			Diferente das vinhetas de assinatura, você só pode cadastrar uma{' '}
			<strong>Assinatura de músicas</strong>. Como o nome sugere, essa assinatura é inserida durante
			as músicas do episódio.
		</Paragraph>

		<Paragraph>
			Antes de gerar um episódio, você pode selecionar se deseja ou não que suas músicas sejam
			carimbadas. Caso opte pelo carimbo, pode escolher qunatas músicas por bloco devem ser
			carimbadas.
		</Paragraph>

		<ImgContainer>
			<img src={img05} alt='Assinaturas' />
		</ImgContainer>

		<Paragraph>
			Para cadastrar uma assinatura de músicas é bem simples. Acesse a aba{' '}
			<strong>Assinatura de músicas</strong>, clique no botão <strong>Nova assinatura</strong>,
			selecione o arquivo de áudio e faça o upload.
		</Paragraph>

		<ImgContainer>
			<img src={img06} alt='Assinaturas' />
		</ImgContainer>

		<Paragraph>
			Após cadastrada, você poderá excluir a assinatura, substituí-la ou fazer o download.
		</Paragraph>

		<ImgContainer>
			<img src={img07} alt='Assinaturas' />
		</ImgContainer>

		<Alert
			showIcon
			type='warning'
			description='Você não precisa ter uma Assinatura de Músicas cadastrada para gerar programas.'
		/>

		<Divider />

		<HelpButtons
			prev={{
				label: 'Programa Compartilhado',
				to: '/help/programs/program-details/shared',
			}}
			next={{ label: 'Trilhas Sonoras', to: '/help/soundtracks' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default SignatureMusicas;
