import React, { useState } from 'react';

import { Container, Animation } from './styles';

import { FiPlayCircle, FiPauseCircle } from 'react-icons/fi';

const EqualizerAnimaion = () => (
	<Animation>
		<div className='bar-1'></div>
		<div className='bar-2'></div>
		<div className='bar-3'></div>
		<div className='bar-4'></div>
	</Animation>
);

const PlayCell = ({ isPlaying, onPlay, onPause, meta }) => {
	const [isHovering, setIsHovering] = useState(false);

	return (
		<Container isPlaying={isPlaying}>
			<div
				className='icon-container'
				onMouseLeave={() => setIsHovering(false)}
				onMouseEnter={() => setIsHovering(true)}
			>
				{isPlaying ? (
					isHovering ? (
						<FiPauseCircle className='_icon' onClick={onPause} />
					) : (
						<EqualizerAnimaion />
					)
				) : (
					<FiPlayCircle className='_icon' onClick={onPlay} />
				)}
			</div>
			<div className='infos'>
				{meta?.primary && <p>{meta?.primary}</p>}
				{meta?.secondary && <span>{meta?.secondary}</span>}
			</div>
		</Container>
	);
};

export default PlayCell;
