import api from '../api';

const BASE_URL = '/api/v2/blitz';

const BlitzAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	show: ({ blitzId }) => api.get(`${BASE_URL}/${blitzId}`),
	store: ({ payload, onUploadProgress }) => api.post(BASE_URL, payload, { onUploadProgress }),
	update: ({ blitzId, payload }) => api.put(`${BASE_URL}/${blitzId}`, payload),
	destroy: ({ blitzId }) => api.delete(`${BASE_URL}/${blitzId}`),
};

export default BlitzAPI;
