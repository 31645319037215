import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 15px;
`;

const IndexPage = () => {
  return (
    <Container>
      <Title>Bem-vindo à TalkPlay!</Title>
      <Paragraph>
        A <strong>TalkPlay </strong>Té uma revolucionária aplicação de Inteligência Artificial, especializada na criação de conteúdo de rádio. Utilizando modelos avançados de linguagem, oferece uma gama de soluções que vão desde a produção automatizada de programas até a locução com tecnologia de ponta.
      </Paragraph>
      <Paragraph>
        Como usuário iniciante, você encontrará neste Help todas as informações necessárias para aproveitar ao máximo as funcionalidades da TalkPlay. Comece explorando os menus laterais, onde você aprenderá a configurar a <strong>IA produtora e a IA locutora</strong>, entenderá melhor os diversos módulos disponíveis e descobrirá como utilizar todos os recursos que a TalkPlay tem a oferecer.
      </Paragraph>
      <Paragraph>
        Seja para criar programas personalizados, gerenciar playlists musicais, ou integrar conteúdo exclusivo da sua rádio, a TalkPlay é a ferramenta ideal para elevar a qualidade e a eficiência da sua estação. Siga nosso guia passo a passo e descubra como a <strong>TalkPlay </strong>pode transformar a sua experiência no mundo de produção de programas de rádio fácil e tecnológica.
      </Paragraph>
    </Container>
  );
};

export default IndexPage;
