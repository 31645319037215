import styled, { css } from 'styled-components';

export const Container = styled.div`
	background: #fff;
	padding: 16px 16px;

	& .ant-breadcrumb > span:last-child {
		font-weight: 600 !important;
		opacity: 0.8;
	}

	& .ant-breadcrumb-link {
		font-size: 12px;
	}

	& > header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 32px;

		& > h2 {
			color: rgba(0, 0, 0, 0.75) !important;
			display: flex;
			align-items: center;
			margin: 0;
			margin-bottom: -10px;
		}

		& > div.buttons {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
		}
	}
`;

export const TableHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;

	& > div.actions {
		padding-right: 24px;
		margin-right: 24px;
		border-right: 1px solid #dedede;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex: 1;

		& > span {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.65);
		}

		& > div {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			& > .ant-btn {
				margin: 0 2px;
			}
		}
	}
`;

export const OptionContent = styled.span`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > span {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
`;

export const GenerationWeekGrid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 0 8px;
`;

export const GenerationDayItem = styled.span`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2px 0;
	border: 1px solid var(--primary);
	background: var(--primary-opacity-25);
	border-radius: var(--border-radius-base);
	pointer-events: none;
	color: var(--primary);

	& > span.label {
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		margin-bottom: 2px;
	}

	& > span.date {
		font-size: 12px;
	}

	${({ active }) =>
		!active &&
		css`
			border-color: #dedede;
			background: #f1f1f1;
			opacity: 0.5;
			color: var(--dedede);
		`}
`;

export const GeneratedPlaylists = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	& > ul {
		width: 100%;
		margin-top: 8px;

		& > li {
			list-style: none;
			padding: 6px 12px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 6px;
			border-radius: var(--border-radius-base);
			border-bottom: 1px dashed #ccc;

			& > div {
				display: flex;
				flex-direction: column;

				& > div.meta {
					display: grid;
					grid-template-columns: 50px auto;

					& > span {
						font-size: 13px;
						color: rgba(0, 0, 0, 0.55);
						text-transform: uppercase;
					}

					& > strong {
						font-size: 14px;
						color: rgba(0, 0, 0, 0.75);
						margin-left: 8px;
					}
				}
			}

			&:nth-child(odd) {
				/* background: #f1f1f1; */
			}
		}
	}
`;

export const RadioCardsContainer = styled.div`
	margin-top: 8px;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
`;
