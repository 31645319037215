import styled from 'styled-components';

export const PageHeader = styled.header`
	background: #fff;
	padding: 16px 16px;

	& .ant-breadcrumb > span:last-child {
		font-weight: 600 !important;
		opacity: 0.8;
	}

	& .ant-breadcrumb-link {
		font-size: 12px;
	}

	& > header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 32px;

		& > h2 {
			color: rgba(0, 0, 0, 0.75) !important;
			display: flex;
			align-items: center;
			margin: 0;
			margin-bottom: -10px;
		}
	}
`;

export const CardContainer = styled.div`
	width: 100%;
	margin: 16px 0;
	padding: 0 16px;

	& > ._card > .ant-card-body {
		padding: 0 0 16px 0;
	}
`;

export const Grid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 12px;
	padding-right: 16px;
	padding-left: 16px;

	& > header {
		grid-column: span 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		& > span.search {
			width: 400px;
			margin-bottom: 12px;
		}
	}

	@media (max-width: 1100px) {
		grid-template-columns: 1fr;

		& > header {
			grid-column: span 1;
		}
	}
`;

export const StatisticsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 24px;
	padding-bottom: 24px;
`;
