import { useContext } from 'react';

import { ScriptContext } from '../contexts/ScriptContext';

const useScript = () => {
	const context = useContext(ScriptContext);
	return context;
};

export default useScript;
