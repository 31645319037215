import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 24px;
	padding: 0 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const CardContent = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 70% 30%;
`;

export const ProgramInfos = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	border-left: 1px solid #f1f1f1;
	margin-left: 32px;
`;

export const InfoSection = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #f1f1f1;
	gap: 16px;
	margin-bottom: 32px;
	padding-bottom: 32px;
	padding-left: 32px;

	&:last-child {
		border: none;
		padding-bottom: 0;
	}
`;

export const InfoCell = styled.div`
	display: grid;
	grid-template-columns: 35% 65%;

	& > small {
		font-size: 14px;
		opacity: 0.8;
	}

	& > span:not(.ant-tag) {
		font-size: 14px;
		font-weight: 500;
	}

	& .ant-tag {
		width: max-content !important;
	}
`;

export const BlocksList = styled.ul`
	max-height: 550px;
	overflow-y: auto;
	padding-right: 4px;

	&::-webkit-scrollbar {
		width: 8px;
		border-radius: 2px;
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	&::-webkit-scrollbar-thumb {
		background: #dedede;
		border-radius: 2px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #ccc;
	}
`;

export const Block = styled.li`
	width: 100%;
	display: flex;
	padding-left: 16px;
	align-items: center;
	border-radius: var(--border-radius-base);
	margin-bottom: 12px;
	list-style: none;
	gap: 16px;
	border: 1px solid #f2f2f2;

	&:hover {
		border-color: #ccc;
	}
`;

export const BlockContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	border-left: 1px solid #f2f2f2;

	& > header {
		padding: 16px;
		display: flex;
		flex-direction: column;

		& > h3 {
			font-size: 16px;
		}

		& > small {
			font-size: 12px;
			opacity: 0.7;
			text-transform: uppercase;
		}
	}

	& > footer {
		border-top: 1px solid #f2f2f2;
		padding: 8px 16px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1.5fr;

		& > div {
			display: flex;
			align-items: center;
			gap: 8px;

			& > small {
				font-size: 11px;
				opacity: 0.8;
				text-transform: uppercase;
			}

			& > span {
				font-size: 11px;
				font-weight: 500;
			}
		}
	}
`;

export const DownloadAncor = styled.a`
	display: none;
`;

export const Clock = styled.div`
	display: flex;
	max-width: max-content;

	& > p {
		color: rgba(0, 0, 0, 0.65);
		margin: 0;
		margin-right: 6px;
	}

	& > span {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.65);
		font-weight: 600;
		font-weight: 500;
		font-style: italic;
		margin-right: 2px;
	}
`;

export const GeneratingEpisodeFallback = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px 24px;
	border-radius: 2px;
	border-bottom: 5px solid var(--primary);
	background-color: var(--primary-opacity-10);
`;

export const ButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-top: 24px;
	border-top: 1px solid #f1f1f1;
	gap: 8px;
`;

export const RadiosContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;
