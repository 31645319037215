import api from '../api';

const BASE_URL = '/api/v2/nightly-program-generation-schedules';

const NightlyGenerationSchedulesAPI = {
	getByProgramId: (programId) => api.get(`${BASE_URL}/${programId}`),
	create: (payload) => api.post(BASE_URL, payload),
	update: (id, payload) => api.put(`${BASE_URL}/${id}`, payload),
};

export default NightlyGenerationSchedulesAPI;
