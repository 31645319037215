import React, { useEffect } from 'react';
import {
	Footer,
	Container,
	Grid,
	Column,
	Heading,
	LinkItem,
	Form,
	EmailInput,
	SubscribeButton,
	Border,
	GridItem,
	Brand,
	Copy,
	SocialLinks,
	SocialLink,
} from './styles';
import { InstagramIcon, TwitterIcon } from './icons'; // Removido FacebookIcon

const Component = () => {
	useEffect(() => {
		const setupSF = (id, view, bool1, theme, bool2, num) => {
			// Defina a função setupSF aqui, ou deixe-a vazia se for apenas um placeholder para o script carregado
		};

		const script = document.createElement('script');
		script.src = 'https://gcrf-cmpzourl.maillist-manage.com/js/optin.min.js';
		script.onload = () => {
			setupSF(
				'sf3z0c44a9193e174d67daa6ea0f19a17b81eeb201975de491bdc20db80298167e4e',
				'ZCFORMVIEW',
				false,
				'light',
				false,
				'0'
			);
		};
		document.body.appendChild(script);

		const style = document.createElement('style');
		style.innerHTML = '::-webkit-input-placeholder { color: rgb(106, 73, 162) }';
		document.head.appendChild(style);

		return () => {
			document.body.removeChild(script);
			document.head.removeChild(style);
		};
	}, []);

	return (
		<Footer>
			<div id='footer'></div>
			<Container>
				<Grid>
					<Column>
						<Heading>Empresa</Heading>
						<div>
							<LinkItem
								href='https://www.talkradio.com.br/'
								target='_blank'
								rel='noopener noreferrer'>
								Sobre Nós
							</LinkItem>
							<LinkItem
								href='https://locutor.talk-play.com/'
								target='_blank'
								rel='noopener noreferrer'>
								Trabalhe Conosco
							</LinkItem>
							<LinkItem href='#'>Imprensa</LinkItem>
							<LinkItem href='#'>Sustentabilidade</LinkItem>
						</div>
					</Column>
					<Column>
						<Heading>Produtos</Heading>
						<div>
							<LinkItem href='#Hibrido'>Programas Híbridos</LinkItem>
							<LinkItem href='#Liveplay'>Produtora IA</LinkItem>
							<LinkItem
								href='https://www.programete.com.br/Default.aspx?ReturnUrl=%2f'
								target='_blank'
								rel='noopener noreferrer'>
								Intranet TalkRadio
							</LinkItem>
							<LinkItem href='https://chatcontrole.com' target='_blank' rel='noopener noreferrer'>
								Chat Controle
							</LinkItem>
						</div>
					</Column>
					<Column>
						<Heading>Recursos</Heading>
						<div>
							<LinkItem href='#'>Blog</LinkItem>
							<LinkItem href='#'>Guias</LinkItem>
							<LinkItem href='#'>Tutoriais</LinkItem>
						</div>
					</Column>
					<Column>
						<Heading>Suporte</Heading>
						<div>
							<LinkItem href='#Contato'>Contato</LinkItem>
							<LinkItem href='#faq'>FAQ</LinkItem>
						</div>
					</Column>
					<Column>
						<Heading>Inscreva-se</Heading>
						<p>Receba novidades, promoções e muito mais.</p>
						<Form
							id='zcampaignOptinForm'
							method='POST'
							action='https://gcrf-cmpzourl.maillist-manage.com/weboptin.zc'
							target='_zcSignup'>
							<EmailInput
								type='email'
								placeholder='Digite seu e-mail'
								name='CONTACT_EMAIL'
								id='EMBED_FORM_EMAIL_LABEL'
							/>
							<SubscribeButton type='button' name='SIGNUP_SUBMIT_BUTTON' id='zcWebOptin'>
								Inscrever-se
							</SubscribeButton>
							<input type='hidden' name='submitType' value='optinCustomView' />
							<input type='hidden' name='emailReportId' value='' />
							<input type='hidden' name='formType' value='QuickForm' />
							<input type='hidden' name='zx' value='132a85c12' />
							<input type='hidden' name='zcvers' value='3.0' />
							<input type='hidden' name='oldListIds' value='' />
							<input type='hidden' name='mode' value='OptinCreateView' />
							<input type='hidden' name='zcld' value='111a2b2385bcefe11' />
							<input type='hidden' name='zctd' value='111a2b2385bce17a9' />
							<input type='hidden' name='zc_trackCode' value='ZCFORMVIEW' />
							<input
								type='hidden'
								name='zc_formIx'
								value='3z0c44a9193e174d67daa6ea0f19a17b81eeb201975de491bdc20db80298167e4e'
							/>
							<input type='hidden' name='viewFrom' value='URL_ACTION' />
						</Form>
						<div id='errorMsgDiv' style={{ display: 'none' }}></div>
					</Column>
				</Grid>
				<Border>
					<Grid>
						<GridItem>
							<Brand>
								<span className='ml-2 text-lg font-semibold'>TALKPLAY AI</span>
							</Brand>
						</GridItem>
						<GridItem>
							<Copy>&copy; 2002 TalkRadio+ | TalkPlay | Todos os direitos reservados.</Copy>
						</GridItem>
						<GridItem>
							<SocialLinks>
								<SocialLink href='#'>
									<TwitterIcon className='h-5 w-5' />
								</SocialLink>
								<SocialLink
									href='https://www.instagram.com/_talkradio/'
									target='_blank'
									rel='noopener noreferrer'>
									<InstagramIcon className='h-5 w-5' />
								</SocialLink>
							</SocialLinks>
						</GridItem>
						<GridItem>
							<LinkItem
								href='https://talkplay.ai/politicprivacy.html'
								target='_blank'
								rel='noopener noreferrer'>
								Política de Privacidade
							</LinkItem>
						</GridItem>
					</Grid>
				</Border>
			</Container>
		</Footer>
	);
};

export default Component;
