import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Avatar, Button, Input, Select } from 'antd';

import Form from '../../../../../components/Form';
import { userTypesDescriptions } from '../../../../../constants';
import { ButtonsContainer, CreateUserOptContainer } from '../styles';
import { resolveFileSrc } from '../../../../../helpers/fileSrcResolver';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { useUpsertUser } from '../../../../../contexts/UserUpsert';

const AccountInfos = ({ casters, setCurrentStep, action }) => {
	const { accountInfos, handleChangeAccountInfo } = useUpsertUser();
	const [errors, setErrors] = useState({});

	const nextStep = useCallback(async () => {
		try {
			const validationShape = {
				type: Yup.string().required(),
				withLiveContent: Yup.boolean().required(),
				caster: Yup.string().when('type', (type, schema) => {
					return type === 'CASTER' ? schema.required() : schema.nullable();
				}),
			};

			if (action === 'CREATE') {
				validationShape.password = Yup.string().required();
				validationShape.passwordConfirmation = Yup.string()
					.required()
					.oneOf([Yup.ref('password')]);
			}

			const validationSchema = Yup.object().shape(validationShape);
			await validationSchema.validate(accountInfos, { abortEarly: false });

			setErrors({});
			setCurrentStep((step) => step + 1);
		} catch (error) {
			setErrors(
				Object.values(error.inner).reduce((err, { path }) => ({ ...err, [path]: true }), {})
			);
		}
	}, [accountInfos, setCurrentStep, action]);

	return (
		<>
			<Form.Container layout='1fr 1fr'>
				<Form.Item error={errors.type} size={1} label='Tipo da Conta'>
					<Select
						size='large'
						name='type'
						placeholder='Selecione o tipo da conta'
						value={accountInfos.type}
						onChange={(value) => handleChangeAccountInfo({ target: { name: 'type', value } })}>
						{userTypesDescriptions.map(({ description, name, value }) => (
							<Select.Option key={value} value={value}>
								<CreateUserOptContainer>
									<strong>{name}</strong>
									<small>{description}</small>
								</CreateUserOptContainer>
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item error={errors.withLiveContent} size={1} label='Tipo de Conteúdo'>
					<Select
						size='large'
						name='withLiveContent'
						placeholder='Selecione o tipo do conteúdo'
						value={
							accountInfos.withLiveContent === undefined
								? undefined
								: String(accountInfos.withLiveContent)
						}
						onChange={(value) =>
							handleChangeAccountInfo({
								target: { name: 'withLiveContent', value: JSON.parse(value) },
							})
						}>
						<Select.Option key='true' value={'true'}>
							Ao-Vivo
						</Select.Option>
						<Select.Option key='false' value={'false'}>
							Enlatado
						</Select.Option>
					</Select>
				</Form.Item>

				{accountInfos.type === 'CASTER' && (
					<Form.Item
						style={{ marginBottom: 12 }}
						size={2}
						error={errors.caster}
						label='Locutor'
						help='É necessário vincular um locutor à essa conta'>
						<Select
							size='large'
							name='caster'
							placeholder='Selecione o tipo da conta'
							value={accountInfos.caster}
							onChange={(value) => handleChangeAccountInfo({ target: { name: 'caster', value } })}>
							{casters.map((caster) => (
								<Select.Option key={caster._id} value={caster._id}>
									<CreateUserOptContainer>
										<Avatar
											size='small'
											src={resolveFileSrc({
												fileName: caster?.profilePic,
												folder: ['profile_pics'],
											})}>
											{caster.name} {caster.surname}
										</Avatar>

										<span>
											{caster.name} {caster.surname}
										</span>
									</CreateUserOptContainer>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				)}

				<Form.Item optional size={1} label='ID do Admin'>
					<Input
						name='intranetId'
						value={accountInfos.intranetId}
						onChange={handleChangeAccountInfo}
						size='large'
						placeholder='ID do admin'
					/>
				</Form.Item>
				<Form.Item optional size={1} label='Dia de Pagamento'>
					<Select
						showSearch
						size='large'
						name='paymentDay'
						placeholder='Selecione o dia do pagamento'
						value={accountInfos.paymentDay}
						onChange={(value) =>
							handleChangeAccountInfo({ target: { name: 'paymentDay', value } })
						}>
						{Array.from({ length: 31 })
							.fill(null)
							.map((_, index) => (
								<Select.Option key={index + 1} value={index + 1}>
									{String(index + 1).padStart(2, '0')}
								</Select.Option>
							))}
					</Select>
				</Form.Item>

				{action === 'CREATE' && (
					<>
						<Form.Item error={errors.password} size={1} label='Senha'>
							<Input.Password
								name='password'
								value={accountInfos.password}
								onChange={handleChangeAccountInfo}
								size='large'
								placeholder='Senha'
							/>
						</Form.Item>
						<Form.Item
							error={errors.passwordConfirmation}
							errorMessage='Senhas não são iguais'
							size={1}
							label='Confirmar Senha'>
							<Input.Password
								name='passwordConfirmation'
								value={accountInfos.passwordConfirmation}
								onChange={handleChangeAccountInfo}
								size='large'
								placeholder='Confirmar senha'
							/>
						</Form.Item>
					</>
				)}
			</Form.Container>

			{action === 'CREATE' && (
				<ButtonsContainer>
					<Button type='default' size='large' onClick={() => setCurrentStep((prev) => prev - 1)}>
						<FiArrowLeft />
						Voltar
					</Button>
					<Button type='primary' size='large' onClick={nextStep}>
						<FiArrowRight />
						Continuar
					</Button>
				</ButtonsContainer>
			)}
		</>
	);
};

export { AccountInfos };
