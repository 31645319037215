import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';

export function validateCPF(value) {
	console.log({ value });

	if (['___.___.___-__', null].includes(value)) {
    console.log('AQUIii')

		return { isValid: true, value: null };
	}

	return { isValid: cpfValidator.isValid(value), value };
}

export function validateCNPJ(value) {

	if (['__.___.___/____-__', null].includes(value)) {
		return { isValid: true, value: null };
	}

	return { isValid: cnpjValidator.isValid(value), value };
}

export function validatePhone(value) {
	if (['(__) _ ____-____', null].includes(value)) {
		return { isValid: true, value: null };
	}

	return {
		isValid: String(value.replace(/\D/g, '')).length === 11,
		value,
	};
}
