import api from '../api';

const BASE_URL = '/api/v2/radio-vignettes';

const RadioVignettesAPI = {
	list: (params) => api.get(BASE_URL, { params }),
	getPopulatedTypes: () => api.get(`${BASE_URL}/populated-types`),
	create: (payload) => api.post(BASE_URL, payload),
	delete: (ids) => api.delete(BASE_URL, { data: { ids } }),
};

export default RadioVignettesAPI;
