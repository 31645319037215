import styled from 'styled-components';

export const Container = styled.section`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--primary);
	overflow: none;
`;

export const Cover = styled.section`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-size: 400% 400%;
	background: linear-gradient(67.29deg, #f135f5 -38.22%, #990cfb 81.71%);
	opacity: 0.94;
`;

export const CoverOverlay = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 0.2;
	transition: 300ms;
	z-index: 10;
	pointer-events: none;
	user-select: none;
`;

export const FormContainer = styled.div`
	padding: 64px 32px 32px 32px;
	width: 500px;
	background: #fff;
	border-radius: var(--border-radius-base);
	z-index: 100;

	& > div.title-container {
		margin-bottom: 16px;

		& > h2 {
			margin-bottom: 2px !important;
		}

		& > span {
			font-size: 15px;
		}
	}

	& > form {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		gap: 24px 0;
		margin: 32px 0;
	}
`;

export const Copyright = styled.span`
	font-size: 14px;
	opacity: 0.7;
	margin-top: 12px;
	width: 100%;
	display: flex;
	justify-content: center;

	& a {
		margin-left: 6px;
	}
`;
