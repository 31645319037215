import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-height: 10vh;
	background-color: #101010;
	color: #fff;
	padding: 1rem;
`;

export const Header = styled.header`
	text-align: center;
	margin-bottom: 2rem;
`;

export const Title = styled.h1`
	font-size: 2.5rem;
	font-weight: 700;
	color: #ffcc00;
`;

export const Subtitle = styled.h2`
	font-size: 1.25rem;
	font-weight: 400;
	color: #cccccc;
`;

export const Content = styled.div`
	position: relative;
	width: 100%;
	max-width: 800px;
	padding: 1rem;
	background-color: #1a1a1a;
	border-radius: 0.5rem;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
	/* align-items: center; */
`;

export const Timestamps = styled.div`
	display: flex;
	justify-content: space-between;
	color: #ffcc00;
	font-size: 1rem;
	width: 100%;
	margin-bottom: 0.5rem;
`;

export const ProgressBar = styled.div`
	height: 10px;
	background: linear-gradient(to right, #ffcc00, #ff3300);
	border-radius: 5px;
	transition: width 0.1s;
	margin-bottom: 1rem;
	width: ${({ progress }) => `${progress}%`};
`;

export const Controls = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
`;

export const Button = styled.button`
	padding: 0.75rem 1.5rem;
	border: 2px solid #ffcc00;
	border-radius: 5px;
	background: ${(props) => (props.variant === 'outline' ? 'transparent' : '#ffcc00')};
	color: ${(props) => (props.variant === 'outline' ? '#ffcc00' : '#000')};
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	transition: background 0.3s, color 0.3s;

	&:hover {
		background: ${(props) => (props.variant === 'outline' ? '#ffcc00' : '#ff3300')};
		color: ${(props) => (props.variant === 'outline' ? '#000' : '#fff')};
	}
`;

export const Footer = styled.footer`
	text-align: center;
	margin-top: 2rem;
	color: #cccccc;
	font-size: 0.875rem;
`;
