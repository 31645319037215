import React from 'react';
import {
	Container,
	InnerContainer,
	Badge,
	Button,
	List,
	ListItem,
	CheckIcon,
	ImageContainer,
	StyledImage,
	Title,
	Description,
	ButtonContainer,
} from './styles';

import img from '../../../../assets/images/imgland/tk.PNG';

export default function Component() {
	return (
		<Container>
			<div id='about'></div>
			<InnerContainer>
				<div className='content'>
					<div className='text-content'>
						<Badge>Transformando o Rádio</Badge>
						<Title>TalkPlay: Rádio Inteligente e Envolvente</Title>

						<Description>
							Utilizando algoritmos avançados de aprendizado de máquina, TalkPlay automatiza e
							personaliza a programação das emissoras, oferecendo conteúdo único e envolvente para
							os ouvintes.
						</Description>
						<List>
							<ListItem>
								<CheckIcon className='icon' />
								Crie Programas em tempo real e personalizados
							</ListItem>
							<ListItem>
								<CheckIcon className='icon' />
								Áudio de alta qualidade e eficiência operacional
							</ListItem>
							<ListItem>
								<CheckIcon className='icon' />
								Atrai novos públicos e redefine o futuro do rádio
							</ListItem>
							<ListItem>
								<CheckIcon className='icon' />
								Gere seus arquivos e inclua em sua programação.
							</ListItem>
						</List>
						<ButtonContainer>
							<Button className='primary'>Recursos Principais</Button>
							<Button className='ghost'>Contato</Button>
						</ButtonContainer>
					</div>
					<ImageContainer>
						<StyledImage src={img} alt='Dashboard' />
					</ImageContainer>
				</div>
			</InnerContainer>
		</Container>
	);
}
