import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Section = styled.section`
	position: relative;
	width: 100%;
	height: 0.7vh;
	min-height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #000000;
	overflow: hidden;
`;

export const Video = styled.video`
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-cover;
    opacity: 0.5;
    
`;

export const Container = styled.div`
	position: relative;
	z-index: 10;
	max-width: 76rem;
	padding-left: 1rem;
	padding-right: 1rem;
	text-align: center;

	@media (min-width: 640px) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	@media (min-width: 1024px) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
`;
export const HighlightedText = styled.span`
	color: #7f10ee;
	animation: fadeIn 0.5s ease-in-out;
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;
export const Heading = styled.h1`
	font-size: 2.25rem;
	font-weight: 800;
	color: #cfcfcf;
	line-height: 1.2;
	letter-spacing: -0.05em;

	@media (min-width: 640px) {
		font-size: 3rem;
	}

	@media (min-width: 1024px) {
		font-size: 3.75rem;
	}
`;

export const Heading2 = styled.h1`
	font-size: 2.25rem;

	color: #7f10ee;
	line-height: 1;
	letter-spacing: -0.05em;

	@media (min-width: 640px) {
		font-size: 3rem;
	}

	@media (min-width: 1024px) {
		font-size: 3.75rem;
	}
`;

export const Paragraph = styled.p`
	margin-top: 1rem;
	font-size: 1.25rem;
	color: #82828a;
`;

export const ButtonContainer = styled.div`
	margin-top: 2rem;
	display: flex;
	justify-content: center;
	gap: 1rem;
`;

export const PrimaryButton = styled(Link)`
	display: inline-flex;
	height: 2.5rem;
	align-items: center;
	justify-content: center;
	border-radius: 0.375rem;
	background-color: #102B9C;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-size: 0.875rem;
	font-weight: 500;
	color: #ffffff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
	transition: background-color 0.3s;

	&:hover {
		background-color: #4338ca;
	}

	&:focus {
		outline: none;
		box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.5), 0 0 0 2px rgba(255, 255, 255, 0.75);
	}
`;

export const SecondaryButton = styled(Link)`
	display: inline-flex;
	height: 2.5rem;
	align-items: center;
	justify-content: center;
	border-radius: 0.375rem;
	border: 1px solid transparent;
	background-color: #ffffff;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-size: 0.875rem;
	font-weight: 500;
	color: #4f46e5;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
	transition: background-color 0.3s;

	&:hover {
		background-color: #f3f4f6;
	}

	&:focus {
		outline: none;
		box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.5), 0 0 0 2px rgba(255, 255, 255, 0.75);
	}
`;
