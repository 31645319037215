import React, { useState, useEffect, useCallback } from 'react';
import Highlighter from 'react-highlight-words';
import {
	PageHeader,
	Input,
	Table,
	Button,
	Dropdown,
	Menu,
	Typography,
	Modal,
	Icon,
	Card,
	message,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import { Container, highlightStyle, TableHeader } from './styles';

import GenresAPI from '../../../../services/sdks/genres';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Geral' },
		{ breadcrumbName: 'Gêneros' },
	],
	style: { marginBottom: 12 },
};

const ManageGenres = () => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [genres, setGenres] = useState([]);
	const [filteredGenres, setFilteredGenres] = useState([]);
	const [search, setSearch] = useState('');
	const [modals, setModals] = useState({
		edit: { genre: null, show: false },
		create: { genre: { name: '' }, show: false },
	});

	const columns = [
		{
			key: 'id',
			title: 'ID',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					10
				)}...`}</Typography.Text>
			),
		},
		{
			key: 'name',
			title: 'Nome',
			render: ({ name }) => (
				<Highlighter
					highlightStyle={highlightStyle}
					searchWords={[search]}
					textToHighlight={name}
				/>
			),
		},
		{
			key: 'actions',
			title: 'Ações',
			align: 'center',
			render: (genre) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									key='edit'
									onClick={() => setModals({ ...modals, edit: { genre, show: true } })}
								>
									<Icon type='edit' /> Editar gênero
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item
									key='delete'
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: 'Excluir gênero?',
											icon: 'exclamation-circle',
											content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											okText: 'Excluir gênero',
											onOk: () => handleDeleteGenre(genre._id),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}
								>
									<Icon type='delete' /> Apagar gênero
								</Menu.Item>
							</Menu>
						}
					>
						<Icon style={{ cursor: 'pointer', fontSize: 20 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const handleDeleteGenre = useCallback(async (genreId) => {
		try {
			await GenresAPI.destroy(genreId);

			setGenres((genres) => genres.filter(({ _id }) => _id !== genreId));
			setFilteredGenres((genres) => genres.filter(({ _id }) => _id !== genreId));
			message.success('gênero deletado com sucesso!');
		} catch (error) {
			console.error(error);
			message.error(error);
		}
	}, []);

	const handleChangeFormField = useCallback((key, value, form) => {
		setModals((modals) => ({
			...modals,
			[form]: {
				...modals[form],
				genre: {
					...modals[form].genre,
					[key]: value,
				},
			},
		}));
	}, []);

	const handleUpdateGenre = useCallback(async () => {
		try {
			const genreId = modals?.edit?.genre?._id;
			const name = modals?.edit?.genre?.name;

			if (!name) {
				return message.error('Informe o nome do gênero');
			}

			setFallback((fallback) => ({ ...fallback, editing: true }));

			await GenresAPI.update(genreId, { name });

			setGenres((genres) => {
				return genres.map((genre) => {
					if (genre._id === genreId) {
						return { ...genre, name };
					}

					return genre;
				});
			});

			setFilteredGenres((genres) => {
				return genres.map((genre) => {
					if (genre._id === genreId) {
						return { ...genre, name };
					}

					return genre;
				});
			});

			setFallback((fallback) => ({ ...fallback, editing: false }));
			setModals((modals) => ({ ...modals, edit: { genre: null, show: false } }));

			message.success('gênero editada com sucesso!');
		} catch (error) {
			console.error(error);
		}
	}, [modals]);

	const handleCreateGenre = useCallback(async () => {
		try {
			const name = modals?.create?.genre?.name;

			if (!name) {
				return message.error('Informe o nome do gênero');
			}

			setFallback((fallback) => ({ ...fallback, creating: true }));

			const {
				data: { genre },
			} = await GenresAPI.store({ name });

			setGenres((genres) => [genre, ...genres]);
			setFilteredGenres((genres) => [genre, ...genres]);

			setFallback((fallback) => ({ ...fallback, creating: false }));
			setModals((modals) => ({ ...modals, create: { genre: { name: '' }, show: false } }));

			message.success('Gênero criado com sucesso!');
		} catch (error) {
			console.error(error);
		}
	}, [modals]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { genres },
				} = await GenresAPI.index();

				setGenres(genres);
				setFilteredGenres(genres);
				setFallback(false);
			} catch (error) {
				console.error(error);
				message.error('Erro ao buscar os gêneros');
			}
		};

		fetchInitialData();
	}, []);

	useEffect(() => {
		if (!search) {
			return setFilteredGenres(genres);
		}

		const filteredGenres = genres.filter(({ name }) => {
			return name.toUpperCase().includes(search.toUpperCase());
		});

		return setFilteredGenres(filteredGenres);
	}, [search, genres]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Gêneros' />

			<PageHeader
				title='Gêneros'
				breadcrumb={breadcrumb}
				extra={[
					<Button
						key='1'
						type='ghost'
						size='large'
						icon='plus-circle'
						onClick={() => {
							return setModals({
								...modals,
								create: { ...modals.create, show: true },
							});
						}}
					>
						Criar novo gênero
					</Button>,
				]}
			>
				<Typography.Text>Gêneros das vinhetas dos programas</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<Table
						pagination={{ pageSize: 20, size: 'large', hideOnSinglePage: true }}
						loading={fallback === 'fetching-tracks'}
						rowKey='_id'
						size='middle'
						style={{ border: 'none' }}
						columns={columns}
						dataSource={filteredGenres}
						title={() => (
							<TableHeader key='tb-header'>
								<Input.Search
									allowClear
									size='large'
									placeholder='Pesquisar por nome do gênero'
									onSearch={(value) => setSearch(value)}
								/>
							</TableHeader>
						)}
					/>
				</Card>
			</Container>

			<Modal
				title={
					<>
						<Icon type='edit' style={{ marginRight: 8 }} /> Editar gênero
					</>
				}
				okText='Salvar'
				closable={!fallback?.editing}
				onOk={handleUpdateGenre}
				onCancel={() => setModals({ ...modals, edit: { genre: null, show: false } })}
				okButtonProps={{ icon: 'check-circle', loading: fallback?.editing }}
				cancelButtonProps={{ icon: 'close-circle', disabled: fallback?.editing }}
				visible={!!modals?.edit?.show}
			>
				<Input
					placeholder='Nome do gênero'
					value={modals?.edit?.genre?.name}
					onChange={({ target: { value } }) => {
						return handleChangeFormField('name', value, 'edit');
					}}
				/>
			</Modal>

			<Modal
				title={
					<>
						<Icon type='plus-circle' style={{ marginRight: 8 }} /> Novo gênero
					</>
				}
				okText='Salvar'
				closable={!fallback?.creating}
				onOk={handleCreateGenre}
				onCancel={() => {
					setModals({ ...modals, create: { genre: { name: '' }, show: false } });
				}}
				okButtonProps={{ icon: 'check-circle', loading: fallback?.creating }}
				cancelButtonProps={{ icon: 'close-circle', disabled: fallback?.creating }}
				visible={modals?.create?.show}
			>
				<Input
					placeholder='Nome do gênero'
					value={modals?.create?.genre?.name}
					onChange={({ target: { value } }) => {
						return handleChangeFormField('name', value, 'create');
					}}
				/>
			</Modal>
		</>
	);
};

export default ManageGenres;
