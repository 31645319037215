import React, { useState } from 'react';
import { Modal, Select } from 'antd';
import { FiCheckCircle, FiList, FiXCircle } from 'react-icons/fi';
import Form from '../Form';

const TemplatesModal = ({ templates, visible, onConfirm, onCancel }) => {
	const [templateId, setTemplateId] = useState(undefined);

	return (
		<Modal
			destroyOnClose
			width={550}
			visible={visible}
			okButtonProps={{ disabled: !templateId }}
			onCancel={() => {
				setTemplateId(undefined);
				onCancel();
			}}
			onOk={() => {
				setTemplateId(undefined);
				onConfirm(templates.find((t) => t._id === templateId));
			}}
			title={
				<>
					<FiList /> Carregar Template
				</>
			}
			okText={
				<>
					<FiCheckCircle /> Confirmar
				</>
			}
			cancelText={
				<>
					<FiXCircle /> Cancelar
				</>
			}
		>
			<Form.Container>
				<Form.Item label='Selecione o template'>
					<Select
						onChange={(value) => setTemplateId(value)}
						size='large'
						style={{ width: '100%' }}
						placeholder='Selecione o template'
					>
						{templates.map((template) => (
							<Select.Option value={template._id} key={template._id}>
								{template.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Form.Container>
		</Modal>
	);
};

export default TemplatesModal;
