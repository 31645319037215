import api from '../api';

const BASE_URL = '/api/v2/contact-requests';

const ContactRequestApi = {
	index: () => api.get(BASE_URL),
	store: (data) => api.post(BASE_URL, data),
	modify: (data, contactRequestId) => api.patch(`${BASE_URL}/${contactRequestId}`, data),
};

export default ContactRequestApi;
