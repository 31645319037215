import axios from 'axios';
import { message } from 'antd';

import env from '../config/env';

function createAPI({ baseURL } = {}) {
	const Api = axios.create({ baseURL: baseURL || env.baseURL });

	Api.interceptors.request.use((config) => {
		const token = localStorage.getItem('@auth');

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	});

	Api.interceptors.response.use(
		(res) => {
			return res;
		},
		(error) => {
			if (401 === error.response.status) {
				localStorage.clear();
				sessionStorage.clear();
				window.location.replace('/');

				return message.warning('Sua sessão expirou!', 5);
			}

			const {
				response: { status, data },
			} = error;

			error.isHttpError = true;
			error.message = data?.message || 'Internal server error';
			error.status = status;

			return Promise.reject(error);
		}
	);

	return Api;
}

export { createAPI };
export default createAPI();
