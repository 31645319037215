const useFile = () => {
	const getDuration = ({ fileUrl }) => {
		return new Promise((resolve, reject) => {
			const audio = new Audio(fileUrl);
			let _duration = null;

			audio.onloadeddata = ({ target: { duration } }) => {
				_duration = duration;

				if (duration) {
					resolve(_duration);
				}
			};

			audio.onerror = (error) => reject(error);
		});
	};

	return {
		getDuration,
	};
};

export default useFile;
