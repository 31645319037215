import styled from 'styled-components';

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	width: max-content;
	gap: 0 32px;
`;

export const Card = styled.div`
	background: #fff;
	border-radius: 8px;
	padding: 16px;
	width: 180px;
	display: flex;
	flex-direction: column;
	z-index: 20;
	pointer-events: none;

	& > p {
		color: #414141;
		margin-bottom: 0;
		font-size: 24px;
		font-weight: 500;

		& > .icon {
			font-size: 20px;
			margin-right: 2px;
		}
	}

	& > span {
		color: rgba(0, 0, 0, 0.65);
		font-size: 12px;
	}
`;
