import React, { useState, useEffect, useCallback } from 'react';
import Highlighter from 'react-highlight-words';
import {
	PageHeader,
	Input,
	Table,
	Button,
	Dropdown,
	Menu,
	Typography,
	Modal,
	Icon,
	Card,
	message,
} from 'antd';

import Meta from '../../../components/Meta';
import Fallback from '../../../components/Fallback';
import { Container, highlightStyle, TableHeader } from './styles';

import SongsAPI from '../../../services/sdks/tracks';
import CategoriesAPI from '../../../services/sdks/categories';

const breadcrumb = {
	routes: [{ breadcrumbName: 'Início' }, { breadcrumbName: 'Categorias Musicais' }],
};

const ManageCategories = () => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [categories, setCategories] = useState([]);
	const [filteredCategories, setFilteredCategories] = useState([]);
	const [search, setSearch] = useState('');
	const [modals, setModals] = useState({
		edit: { category: null, show: false },
		create: { category: { name: '' }, show: false },
	});

	const columns = [
		{
			key: 'id',
			title: 'ID',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					5
				)}...`}</Typography.Text>
			),
		},
		{
			key: 'name',
			title: 'Nome',
			render: ({ name }) => (
				<Highlighter
					highlightStyle={highlightStyle}
					searchWords={[search]}
					textToHighlight={name}
				/>
			),
		},
		{
			key: 'qtd',
			title: 'Quantidade de músicas',
			render: ({ tracksCount }) => (tracksCount === 1 ? `1 música` : `${tracksCount} músicas`),
		},
		{
			key: 'actions',
			title: 'Ações',
			align: 'center',
			render: (category) => (
				<Dropdown
					placement='bottomRight'
					overlay={
						<Menu>
							<Menu.Item
								key='edit'
								onClick={() => setModals({ ...modals, edit: { category, show: true } })}
							>
								<Icon type='edit' /> Editar categoria
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								key='clear-tracks'
								className='ant-dropdown-menu-item-danger'
								disabled={!category.tracksCount}
								onClick={() => {
									Modal.confirm({
										title: 'Excluir músicas?',
										icon: 'exclamation-circle',
										content:
											'Todas as músicas dessa categoria serão excluídas, deseja continuar mesmo assim?',
										okText: 'Excluir músicas',
										onOk: () => handleClearTracks(category._id),
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}
							>
								<Icon type='stop' /> Excluir músicas
							</Menu.Item>
							<Menu.Item
								key='delete'
								className='ant-dropdown-menu-item-danger'
								onClick={() => {
									Modal.confirm({
										title: 'Excluir categoria?',
										icon: 'exclamation-circle',
										content:
											'A categoria e todas as suas músicas serão excluídas, deseja continuar mesmo assim?',
										okText: 'Excluir categoria',
										onOk: () => handleDeleteCategory(category._id),
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}
							>
								<Icon type='delete' /> Apagar categoria
							</Menu.Item>
						</Menu>
					}
				>
					<Icon style={{ cursor: 'pointer', fontSize: 20 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleDeleteCategory = useCallback(async (categoryId) => {
		try {
			await CategoriesAPI.delete(categoryId);

			setCategories((categories) => categories.filter(({ _id }) => _id !== categoryId));
			setFilteredCategories((categories) => categories.filter(({ _id }) => _id !== categoryId));
			message.success('Categoria deletada com sucesso!');
		} catch (error) {
			console.error(error);
			message.error(error);
		}
	}, []);

	const handleClearTracks = useCallback(async (categoryId) => {
		try {
			const {
				data: { tracksDB },
			} = await SongsAPI.index(`categoryId=${categoryId}`);
			const categoryTracks = tracksDB.records;

			for (let index = 0; index < categoryTracks.length; index++) {
				await SongsAPI.delete(categoryTracks[index]._id);
			}

			setCategories((prev) =>
				prev.map((category) => {
					if (category._id === categoryId) {
						return { ...category, tracksCount: 0 };
					}

					return category;
				})
			);

			setFilteredCategories((prev) =>
				prev.map((category) => {
					if (category._id === categoryId) {
						return { ...category, tracksCount: 0 };
					}

					return category;
				})
			);

			message.success('As músicas da categoria foram excluídas!');
		} catch (error) {
			console.error(error);
			message.error(error);
		}
	}, []);

	const handleChangeFormField = useCallback((key, value, form) => {
		setModals((modals) => ({
			...modals,
			[form]: {
				...modals[form],
				category: {
					...modals[form].category,
					[key]: value,
				},
			},
		}));
	}, []);

	const handleEditCategory = useCallback(async () => {
		try {
			const categoryId = modals?.edit?.category?._id;
			const name = modals?.edit?.category?.name;

			if (!name) {
				return message.error('Informe o nome da categoria');
			}

			setFallback((fallback) => ({ ...fallback, editing: true }));

			await CategoriesAPI.update(categoryId, { name });

			setCategories((categories) => {
				return categories.map((category) => {
					if (category._id === categoryId) {
						return { ...category, name };
					}

					return category;
				});
			});

			setFilteredCategories((categories) => {
				return categories.map((category) => {
					if (category._id === categoryId) {
						return { ...category, name };
					}

					return category;
				});
			});

			setFallback((fallback) => ({ ...fallback, editing: false }));
			setModals((modals) => ({ ...modals, edit: { category: null, show: false } }));

			message.success('Categoria editada com sucesso!');
		} catch (error) {
			console.error(error);
		}
	}, [modals]);

	const handleCreateCategory = useCallback(async () => {
		try {
			const name = modals?.create?.category?.name;

			if (!name) {
				return message.error('Informe o nome da categoria');
			}

			setFallback((fallback) => ({ ...fallback, creating: true }));

			const {
				data: { category },
			} = await CategoriesAPI.post({ name });

			setCategories((categories) => [{ ...category, tracksCount: 0 }, ...categories]);
			setFilteredCategories((categories) => [{ ...category, tracksCount: 0 }, ...categories]);

			setFallback((fallback) => ({ ...fallback, creating: false }));
			setModals((modals) => ({ ...modals, create: { category: { name: '' }, show: false } }));

			message.success('Categoria criada com sucesso!');
		} catch (error) {
			console.error(error);
		}
	}, [modals]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { categories },
				} = await CategoriesAPI.index({ 'count-tracks': 'true' });

				setCategories(categories);
				setFilteredCategories(categories);
				setFallback(false);
			} catch (error) {
				console.error(error);
				message.error('Erro ao buscar as categorias');
			}
		};

		fetchInitialData();
	}, []);

	useEffect(() => {
		if (!search) {
			return setFilteredCategories(categories);
		}

		const filteredCategories = categories.filter(({ name }) => {
			return name.toUpperCase().includes(search.toUpperCase());
		});

		return setFilteredCategories(filteredCategories);
	}, [search, categories]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Categorias musicais' />

			<PageHeader
				title='Categorias Musicais'
				breadcrumb={breadcrumb}
				extra={[
					<Button
						key='new-category'
						type='ghost'
						size='large'
						icon='plus-circle'
						onClick={() => {
							return setModals({
								...modals,
								create: { ...modals.create, show: true },
							});
						}}
					>
						Criar nova categoria
					</Button>,
				]}
			>
				<Typography.Text>
					Essas são as categorias musicais disponíveis na plataforma
				</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<Table
						pagination={{ pageSize: 20, size: 'large', hideOnSinglePage: true }}
						loading={fallback === 'fetching-tracks'}
						rowKey='_id'
						size='middle'
						style={{ border: 'none' }}
						columns={columns}
						dataSource={filteredCategories}
						title={() => (
							<TableHeader key='tb-header'>
								<Input.Search
									allowClear
									size='large'
									placeholder='Pesquisar por nome da categoria'
									onSearch={(value) => setSearch(value)}
								/>
							</TableHeader>
						)}
					/>
				</Card>
			</Container>

			<Modal
				title={
					<>
						<Icon type='edit' style={{ marginRight: 8 }} /> Editar categoria{' '}
					</>
				}
				okText='Salvar'
				closable={!fallback?.editing}
				onOk={handleEditCategory}
				onCancel={() => setModals({ ...modals, edit: { category: null, show: false } })}
				okButtonProps={{ icon: 'check-circle', loading: fallback?.editing }}
				cancelButtonProps={{ icon: 'close-circle', disabled: fallback?.editing }}
				visible={modals?.edit?.category}
			>
				<Input
					placeholder='Nome da categoria'
					value={modals?.edit?.category?.name}
					onChange={({ target: { value } }) => {
						return handleChangeFormField('name', value, 'edit');
					}}
				/>
			</Modal>

			<Modal
				title={
					<>
						<Icon type='plus-cirlce' style={{ marginRight: 8 }} /> Nova categoria{' '}
					</>
				}
				okText='Salvar'
				closable={!fallback?.creating}
				onOk={handleCreateCategory}
				onCancel={() => {
					setModals({ ...modals, create: { category: { name: '' }, show: false } });
				}}
				okButtonProps={{ icon: 'check-circle', loading: fallback?.creating }}
				cancelButtonProps={{ icon: 'close-circle', disabled: fallback?.creating }}
				visible={modals?.create?.show}
			>
				<Input
					placeholder='Nome da categoria'
					value={modals?.create?.category?.name}
					onChange={({ target: { value } }) => {
						return handleChangeFormField('name', value, 'create');
					}}
				/>
			</Modal>
		</>
	);
};

export default ManageCategories;
