import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaskedInput from 'antd-mask-input';
import * as Yup from 'yup';
import { PageHeader, Card, Button, Select, Input, message, Typography } from 'antd';

import Meta from '../../../components/Meta';
import { Container } from './styles';

import UsersAPI from '../../../services/sdks/user';

import { states as brStates } from '../../../constants';
import * as UserActions from '../../../store/actions/user';
import { CreateUserOptContainer } from '../../Admin/Users/CreateUser/styles';
import Form from '../../../components/Form';
import { FiSave } from 'react-icons/fi';
import { validateCNPJ, validateCPF, validatePhone } from '../../../helpers/validators';

const breadcrumb = {
	routes: [{ breadcrumbName: 'Início' }, { breadcrumbName: 'Configurações' }],
	style: { marginBottom: 12 },
};

const Settings = ({ user, updateUser }) => {
	const [fallback, setFallback] = useState(null);
	const [isEmailTaken, setIsEmailTaken] = useState(false);
	const [errors, setErrors] = useState({});
	const [personalInfos, setPersonalInfos] = useState(() => ({
		name: user?.name,
		surname: user?.surname,
		email: user?.email,
		city: user?.city,
		state: user?.state,
		radioName: user?.radioName,
		phone: user?.phone,
		cpf: user?.cpf,
		cnpj: user?.cnpj,
	}));

	const handleChangePersonalInfo = useCallback((e) => {
		e?.persist && e.persist();
		setPersonalInfos((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	}, []);
	console.log({ user });
	const handleSubmit = useCallback(
		async (event) => {
			setIsEmailTaken(false);
			setErrors({});
			setFallback((prev) => ({ ...prev, updatingUser: true }));

			try {
				event.preventDefault();
				const validationSchema = Yup.object().shape({
					name: Yup.string().required(),
					surname: Yup.string().required(),
					radioName: Yup.string().required(),
					email: Yup.string().email().required(),
					cpf: Yup.string()
						.nullable(true)
						.test('cpf', (v) => validateCPF(v).isValid)
						.transform((v) => validateCPF(v).value),
					cnpj: Yup.string()
						.nullable(true)
						.test('cnpj', (v) => validateCNPJ(v).isValid)
						.transform((v) => validateCNPJ(v).value),
					phone: Yup.string()
						.nullable(true)
						.test('phone', (v) => validatePhone(v).isValid)
						.transform((v) => validatePhone(v).value),
					city: Yup.string().required(),
					state: Yup.string().required(),
				});

				const formValue = await validationSchema.validate(personalInfos);
				const payload = {
					user: {
						...formValue,
						withLiveContent: user?.withLiveContent,
						intranetId: user?.intranetId,
						paymentDay: user?.paymentDay,
						caster: user?.caster,
					},
				};

				
				setFallback((prev) => ({ ...prev, updatingUser: true }));
				await UsersAPI.update(user?._id, payload);

				updateUser({ ...user, ...formValue });

				return message.success('Perfil atualizado com sucesso');
			} catch (error) {
				if (error instanceof Yup.ValidationError) {
					setErrors(
						Object.values(error.inner).reduce((err, { path }) => ({ ...err, [path]: true }), {})
					);
				}

				if (error?.isHttpError) {
					switch (error?.status) {
						case 409:
							setIsEmailTaken(true);
							setErrors((prev) => ({ ...prev, email: true }));
							break;
						default:
							message.error('Houve um erro no servidor, tente novamente');
							break;
					}
				}
			}

			setFallback((prev) => ({ ...prev, updatingUser: false }));
		},
		[personalInfos, updateUser, user]
	);

	return (
		<>
			<Meta title='Configurações' />

			<PageHeader title='Configurações' breadcrumb={breadcrumb}>
				<Typography.Text>Alterar informações da sua conta</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<Form.Container layout='1fr 1fr 1fr 1fr 1fr 1fr'>
						<Form.Item size={2} label='Nome' error={errors?.name}>
							<Input
								name='name'
								value={personalInfos.name}
								size='large'
								placeholder='Nome do usuário'
								onChange={handleChangePersonalInfo}
							/>
						</Form.Item>
						<Form.Item size={2} label='Sobrenome' error={errors?.surname}>
							<Input
								name='surname'
								value={personalInfos.surname}
								size='large'
								placeholder='Sobrenome do usuário'
								onChange={handleChangePersonalInfo}
							/>
						</Form.Item>
						<Form.Item size={2} label='Nome da Rádio' error={errors?.radioName}>
							<Input
								name='radioName'
								value={personalInfos.radioName}
								size='large'
								placeholder='Nome da rádio'
								onChange={handleChangePersonalInfo}
							/>
						</Form.Item>
						<Form.Item
							size={2}
							label='Email'
							error={errors?.email}
							errorMessage={isEmailTaken ? 'Email já está em uso' : 'Email inválido'}>
							<Input
								name='email'
								value={personalInfos.email}
								size='large'
								placeholder='Email do usuário'
								onChange={handleChangePersonalInfo}
							/>
						</Form.Item>
						<Form.Item optional size={2} label='CPF' error={errors?.cpf}>
							<MaskedInput
								name='cpf'
								value={personalInfos.cpf || '___.___.___-__'}
								size='large'
								autoComplete='off'
								mask='000.000.000-00'
								placeholder='___.___.___-__'
								onChange={(e) => handleChangePersonalInfo(e)}
							/>
						</Form.Item>
						<Form.Item optional size={2} label='CNPJ' error={errors?.cnpj}>
							<MaskedInput
								name='cnpj'
								value={personalInfos.cnpj || '__.___.___/____-__'}
								size='large'
								autoComplete='off'
								mask='00.000.000/0000-00'
								placeholder='__.___.___/____-__'
								onChange={handleChangePersonalInfo}
							/>
						</Form.Item>
						<Form.Item optional size={2} label='Telefone' error={errors?.phone}>
							<MaskedInput
								name='phone'
								value={personalInfos.phone || '(__) _ ____-____'}
								size='large'
								autoComplete='off'
								mask='(00) 0 0000-0000'
								placeholder='(__) _ ____-____'
								onChange={handleChangePersonalInfo}
							/>
						</Form.Item>
						<Form.Item size={2} label='Cidade' error={errors?.city}>
							<Input
								name='city'
								value={personalInfos.city}
								size='large'
								placeholder='Cidade do usuário'
								onChange={handleChangePersonalInfo}
							/>
						</Form.Item>
						<Form.Item size={2} label='Estado' error={errors?.state}>
							<Select
								showSearch
								size='large'
								value={personalInfos.state}
								onChange={(value) => handleChangePersonalInfo({ target: { name: 'state', value } })}
								placeholder='Selecione o estado'
								filterOption={(input, { props: { children } }) => {
									return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
								}}>
								{brStates.map(({ label, value }) => (
									<Select.Option key={value} value={value}>
										<CreateUserOptContainer>
											<strong>{value}</strong>
											<small>{label}</small>
										</CreateUserOptContainer>
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Form.Container>
					<footer className='btn-container'>
						<Button
							loading={fallback?.updatingUser}
							onClick={handleSubmit}
							size='large'
							type='primary'>
							<FiSave /> Salvar Alterações
						</Button>
					</footer>
				</Card>
			</Container>
		</>
	);
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
