import api from '../api';

const BASE_URL = '/api/v2/programs';

const ProgramsAPI = {
	index: (query = '') => api.get(`${BASE_URL}?${query}`),
	show: (programId) => api.get(`${BASE_URL}/${programId}`),
	store: (payload) => api.post(BASE_URL, payload),
	update: (programId, payload) => api.put(`${BASE_URL}/${programId}`, payload),
	destroy: (programId) => api.delete(`${BASE_URL}/${programId}`),
};

export default ProgramsAPI;
