import React from 'react';
import { Select } from 'antd';

const PageSizeHandler = ({ pagination, setPagination }) => {
	return (
		<Select
			size='small'
			value={pagination?.pageSize}
			onChange={(pageSize) => setPagination({ ...pagination, current: 1, pageSize })}
		>
			<Select.Option value={5}>5 itens por página</Select.Option>
			<Select.Option value={10}>10 itens por página</Select.Option>
			<Select.Option value={20}>20 itens por página</Select.Option>
			<Select.Option value={50}>50 itens por página</Select.Option>
			<Select.Option value={100}>100 itens por página</Select.Option>
		</Select>
	);
};

export default PageSizeHandler;
