import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
	cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
	position: relative;
	border: 1px solid
		${({ status }) => (status === 'ALL' ? 'var(--succes-opacity-85)' : 'var(--danger-opacity-85)')};
	width: 160px;
	height: 36px;
	border-radius: 100px;
	background: ${({ status }) =>
		status === 'ALL' ? 'var(--succes-opacity-25)' : 'var(--danger-opacity-25)'};
	opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
`;

export const InnerText = styled.span`
	position: absolute;
	font-size: 11px;
	font-weight: 600;
	text-transform: uppercase;
	top: 50%;
	right: ${({ status }) => (status === 'ALL' ? '16px' : 'auto')};
	left: ${({ status }) => (status === 'ALL' ? 'auto' : '16px')};
	transform: translateY(-50%);
	transition: 300ms;
	transition-delay: 300ms;
`;

const loadingAnimation = keyframes`
  100% { transform: rotate(360deg); }
`;

export const Indicator = styled.div`
	position: absolute;
	width: 32px;
	height: 32px;
	border-radius: 100px;
	top: 50%;
	left: ${({ status }) => (status === 'ALL' ? '2px' : 'calc(100% - 2px - 32px)')};
	transform: translateY(-50%);
	background: ${({ status }) => (status === 'ALL' ? 'var(--succes)' : 'var(--danger)')};
	transition: 300ms;
	display: flex;
	align-items: center;
	justify-content: center;

	& .__loading-indicator {
		animation: ${loadingAnimation};
		animation-iteration-count: infinite;
		animation-duration: 1000ms;
	}
`;
