import styled, { css } from 'styled-components';
import { FileValidationStatusCodes } from '../../hooks/useFilesValidator';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const Input = styled.input`
	display: none;
	visibility: hidden;
	opacity: 0;
	user-select: none;
	width: 0;
`;

export const UploadZone = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px dashed #dedede;
	border-radius: var(--border-radius-strong);
	margin-bottom: 32px;
	cursor: pointer;

	&:hover {
		background: #f1f1f1;
		border-color: #ccc;
	}

	&::after {
		content: attr(data-help);
		position: absolute;
		top: 102%;
		left: 0;
		font-size: 12px;
	}

	${({ isDisabled }) =>
		isDisabled &&
		css`
			pointer-events: none !important;
			background-color: #f5f5f5;

			& > div {
				opacity: 0.6;
			}
		`}
`;

export const UploadZoneLabel = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 48px 0;

	& > svg {
		font-size: 64px;
		margin-bottom: 8px;
	}

	& > strong {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 2px;
	}

	& > span {
		font-size: 13px;
		font-weight: 300;
		opacity: 0.9;
	}
`;

export const FilesList = styled.ul`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 6px;
	margin: 0;
	margin-bottom: 6px;
`;

export const FileItem = styled.li`
	width: 100%;
	padding: 8px;
	border: 1px solid #dedede;
	border-radius: var(--border-radius-strong);
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const FileItemHeader = styled.header`
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 12px;
`;

export const FileItemInfos = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: center;

		& > span {
			color: black;
			font-weight: 600;
			font-size: 12px;
		}
	}
`;

export const FileItemAttributes = styled.div`
	width: 100%;
	display: flex;
	gap: 3px;

	& > small {
		opacity: calc(0.9);
		font-size: 10px;
		text-transform: uppercase;
	}
`;

export const FileIconContainer = styled.div`
	width: 40px;
	height: 40px;
	border-radius: var(--border-radius-strong);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	background: #b3b3b3;
	color: white;
`;

export const RemoveFileButton = styled.button`
	border: none;
	background: none;
	outline: none;
	cursor: pointer;
	background: #f8f8f8;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: var(--border-radius-strong);

	&:hover {
		background: var(--danger-opacity-25);
		border-color: var(--danger-opacity-25);

		& > svg {
			color: var(--danger);
		}
	}

	& > svg {
		font-size: 16px;
		color: #494949;
	}
`;

export const ValidatingFile = styled.footer`
	width: 100%;
	display: flex;
	gap: 8px;
	align-items: center;

	& > small {
		font-size: 11px;
	}
`;

export const FileStatus = styled.footer`
	width: 100%;
	display: flex;
	gap: 8px;
	align-items: center;

	& > svg {
		font-size: 16px;
		color: ${({ status }) =>
			status === FileValidationStatusCodes.VALID ? '#15b92b' : 'var(--danger)'};
	}

	& > small {
		font-size: 11px;
	}
`;

export const FilesListHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
`;

export const FilesListTitle = styled.strong`
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
`;
