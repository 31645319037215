import api from '../api';

const BASE_URL = '/api/v2/contracts';

const ContractsApi = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	store: (payload, headers) => api.post(BASE_URL, payload, { headers }),
	update: (contractId, payload) => api.put(`${BASE_URL}/${contractId}`, payload),
	modify: (contractId, payload, headers) => {
		return api.patch(`${BASE_URL}/${contractId}`, payload, { headers });
	},
	destroy: (contractId) => api.delete(`${BASE_URL}/${contractId}`),
};

export default ContractsApi;
