import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	height: 100%;
	position: relative;
	overflow: none;
	display: flex;
	align-items: center;
`;

export const Cover = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;

	& > div.overlay {
		width: 100%;
		height: 100%;
		background: rgba(44, 73, 168, 0.7);
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(5.7px);
		-webkit-backdrop-filter: blur(5.7px);
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.94;
		z-index: 10;
	}

	& > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 5;
	}
`;

export const CoverArea = styled.section`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 10;
	padding: 32px;
	width: 65%;
`;

export const CoverText = styled.article`
	display: flex;
	flex-direction: column;
	justify-content: center;
	pointer-events: none;

	& > img.logo {
		width: 180px;
		margin-bottom: 24px;
	}

	& > h1 {
		color: #fff !important;
		font-size: 38px !important;
		margin-bottom: 4px !important;
	}

	& > p {
		margin: 0;
		color: #fff;
		font-size: 14px;
		width: 80%;
		font-weight: 500;
		opacity: 0.9;
	}
`;

export const FormContainer = styled.div`
	width: 35%;
	height: 90%;
	margin-right: 32px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	background: #fff;
	z-index: 10;
	padding: 32px;
	border-radius: 8px;
`;

export const FormContainerInner = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Copyright = styled.span`
	font-size: 14px;
	opacity: 0.7;
	text-align: end;
	width: 100%;

	& a {
		color: var(--primary);

		&:hover {
			text-decoration: underline;
		}
	}
`;
