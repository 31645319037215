import styled, { css } from 'styled-components';

export const Container = styled.div`
	padding: 16px;

	& > .ant-card {
		margin: 12px 0;
	}

	${({ isSorting }) =>
		isSorting &&
		css`
			cursor: grabbing !important;

			& * {
				cursor: grabbing !important;
			}
		`}
`;

export const Grid = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	& > div {
		width: calc(50% - 8px);
	}
`;

export const ScriptWrapper = styled.div`
	width: 100%;
	border-radius: var(--border-radius-base);
	padding: 6px;
	border: 1px solid rgb(204, 204, 204);
	background: rgb(249, 249, 249);
`;

export const ButtonContainer = styled.div`
	width: 100%;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const OptionsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	& > div.divider {
		width: 1px;
		height: 14px;
		margin: 0 4px;
		background: #dedede;
	}

	& .ant-btn {
		margin: 0 2px;
	}
`;
