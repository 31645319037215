import { useCallback } from 'react';
import axios from 'axios';
import path from 'path';
import download from 'js-file-download';
import { resolveFileSrc } from '../helpers/fileSrcResolver';

const useDownload = () => {
	return useCallback(
		async ({ filename, folder, customS3Bucket, useAccelerate, name, onProgress }) => {
			const ext = path.extname(filename);
			const url = resolveFileSrc({ fileName: filename, folder, useAccelerate, customS3Bucket });

			let lastDownloadPercent = 0;

			const res = await axios.get(url, {
				responseType: 'arraybuffer',
				maxContentLength: Infinity,
				onDownloadProgress:
					typeof onProgress === 'function'
						? ({ total, loaded }) => {
								onProgress({
									completed: Math.floor((loaded * 100) / total),
									speed: lastDownloadPercent ? Math.floor((loaded - lastDownloadPercent) / 100) : 0,
								});

								lastDownloadPercent = loaded;
						  }
						: undefined,
			});

			const blob = new Blob([res.data]);

			return download(blob, `${name}${ext}`);
		},
		[]
	);
};

export default useDownload;
