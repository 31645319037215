import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Timer from 'react-compound-timer';
import Loader from 'react-spinners/ScaleLoader';
import querystring from 'query-string';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { PageHeader, Button, Modal, Card, Typography, message, Tag, Checkbox } from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import {
	Container,
	DownloadAncor,
	Clock,
	GeneratingEpisodeFallback,
	CardContent,
	ProgramInfos,
	InfoCell,
	BlocksList,
	Block,
	BlockContent,
	InfoSection,
	ButtonsContainer,
	RadiosContainer,
} from './styles';

import MasterAPI from '../../../../services/sdks/master';
import EpisodesAPI from '../../../../services/sdks/episodes';
import env from '../../../../config/env';
import { GoFileZip, GoListUnordered } from 'react-icons/go';

import { useDownload, useFilenameTemplate, useZip } from '../../../../hooks';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';
import EpisodeDownloadProgress from '../../../../components/EpisodeDownloadProgress';
import RadioCard from '../../../../components/RadioCard';
import { FiCheckSquare, FiCopy, FiDownload, FiSliders } from 'react-icons/fi';
import { connect } from 'react-redux';
import { getResource } from '../../../../helpers/getResource';
import resourcesKeys from '../../../../constants/resourcesKeys';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Home' },
		{ breadcrumbName: 'Episódios' },
		{ breadcrumbName: 'Episódio Gerado' },
	],
	style: { marginBottom: 12 },
};

const CreatedEpisiode = ({ user }) => {
	const generateFilename = useFilenameTemplate();

	const audioRef = useRef(null);
	const downloadRef = useRef(null);
	const zip = useZip();
	const downloader = useDownload();
	const { episodeId } = useParams();
	const history = useHistory();
	const { search } = useLocation();

	const [fallback, setFallback] = useState({ initialData: true });
	const [episode, setEpisode] = useState(null);
	const [showModal, setShowModal] = useState(null);
	const [downloadMode, setDownloadMode] = useState('zip');
	const [player, setPlayer] = useState(null);
	const [download, setDownload] = useState({ program: null, blocks: [] });
	const [selectedBlocks, setSelectedBlocks] = useState([]);
	const [urlCopied, setUrlCopied] = useState(null);

	const getFilePath = useCallback(
		(fileName) => {
			return resolveFileSrc({
				useAccelerate: true,
				customS3Bucket: episode.isMasterized ? env.episodesS3Bucket : env.miscS3Bucket,
				folder: null,
				fileName: episode.isMasterized ? fileName : `tmp${fileName}`,
			});
		},
		[episode]
	);

	const handleDownloadBlock = useCallback(
		async (block, index) => {
			try {
				setFallback((fallback) => ({ ...fallback, downloadingBlock: index }));

				await downloader({
					filename: episode.isMasterized ? block.filename : `tmp${block.filename}`,
					customS3Bucket: episode.isMasterized ? env.episodesS3Bucket : env.miscS3Bucket,
					folder: null,
					useAccelerate: true,
					name: generateFilename({
						index,
						template: episode.program.filenameTemplate,
					}),
					onProgress: ({ completed, speed }) => {
						setDownload((prev) => ({
							...prev,
							blocks: prev.blocks.map((b, idx) => (idx === index ? { completed, speed } : b)),
						}));
					},
				});

				setFallback((fallback) => ({ ...fallback, downloadingBlock: null }));
			} catch (error) {
				console.error(error);
			}
		},
		[downloader, episode, generateFilename]
	);

	const handleDowloadEpisode = useCallback(async () => {
		const dataSource = selectedBlocks.length ? selectedBlocks : episode.blocks;

		try {
			setDownload({
				program: episode.program.name,
				blocks: dataSource.map(() => ({ completed: 0, speed: 0 })),
			});

			if (downloadMode === 'zip') {
				const zipName = `${episode.program.name} - ${episode.date}`;
				const zipData = dataSource.map((block, index) => ({
					url: getFilePath(block.filename),
					name: generateFilename({
						index,
						template: episode.program.filenameTemplate,
					}),
				}));

				await zip.download(zipData, zipName, ({ index, completed, speed }) => {
					setDownload((prev) => ({
						...prev,
						blocks: prev.blocks.map((b, idx) => (idx === index ? { completed, speed } : b)),
					}));
				});
			} else {
				await Promise.all(dataSource.map((block, index) => handleDownloadBlock(block, index)));
			}

			setShowModal((showModal) => ({ ...showModal, downloadModal: false }));
			setDownload({ program: episode.program.name, blocks: [] });
		} catch (error) {
			console.error(error);
		}
	}, [
		selectedBlocks,
		episode,
		downloadMode,
		zip,
		getFilePath,
		generateFilename,
		handleDownloadBlock,
	]);

	const handleMasterize = useCallback(async () => {
		try {
			if (player) {
				setPlayer(null);
				audioRef.current.pause();
			}

			const { statistics } = querystring.parse(search);

			setFallback((fallback) => ({ ...fallback, mastering: true }));

			const {
				data: { masterizedEpisode },
			} = await MasterAPI.modify({ episodeId }, { statisticId: statistics });

			setFallback((fallback) => ({ ...fallback, mastering: false }));

			message.success('Episódio masterizado com sucesso');
			history.push(`/commom/episodes/${masterizedEpisode._id}/details`);
		} catch (error) {
			console.error(error);
		}
	}, [episodeId, history, player, search]);

	useEffect(() => {
		if (urlCopied !== null) {
			setTimeout(() => {
				setUrlCopied(null);
			}, 3000);
		}
	}, [urlCopied]);

	useEffect(() => {
		const fetchEpisode = async () => {
			try {
				const {
					data: { episode },
				} = await EpisodesAPI.show({ episodeId });

				setEpisode(episode);
				setFallback((fallback) => ({ ...fallback, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Erro o episódio');
			}
		};

		fetchEpisode();
	}, [episodeId]);

	if (fallback.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title={`Episódio gerado - ${episode?.program?.name}`} />

			<DownloadAncor ref={downloadRef} />

			<PageHeader title='Episódio Gerado' breadcrumb={breadcrumb} />

			<Container>
				<Card>
					<CardContent>
						<BlocksList>
							{episode?.blocks.map((block, index) => (
								<Block key={index}>
									<Checkbox
										onChange={(e) => {
											setSelectedBlocks((prev) =>
												e.target.checked
													? [...prev, block]
													: prev.filter((b) => b._id !== block._id)
											);
										}}
									/>
									<BlockContent>
										<header>
											<h3>Bloco {String(index + 1).padStart(2, '0')}</h3>
											<small>
												{generateFilename({
													index,
													template: episode.program.filenameTemplate,
												})}
											</small>
										</header>

										<footer>
											<div>
												<small>Tamanho</small>
												<span>{Math.floor(block.size)}MB</span>
											</div>
											<div>
												<small>Duração</small>
												<span>{block.duration}</span>
											</div>
											<div>
												<small>Formato</small>
												<span>{block.format}</span>
											</div>
											<div style={{ justifyContent: 'flex-end' }}>
												<CopyToClipboard
													text={getFilePath(block.filename)}
													onCopy={() => setUrlCopied(index)}>
													<Button
														size='small'
														type='link'
														disabled={urlCopied === index}
														style={{ color: urlCopied === index ? '#52c41a' : undefined }}>
														{urlCopied === index ? <FiCheckSquare /> : <FiCopy />}
														{urlCopied === index ? 'URL Copiada' : 'Copiar URL'}
													</Button>
												</CopyToClipboard>

												<Button
													type='ghost'
													size='small'
													onClick={async () => {
														setDownload({
															program: episode.program.name,
															blocks: [{ completed: 0, speed: 0 }],
														});

														await handleDownloadBlock(block, index);

														setDownload({
															program: episode.program.name,
															blocks: [],
														});
													}}>
													<FiDownload />
													Baixar
												</Button>
											</div>
										</footer>
									</BlockContent>
								</Block>
							))}
						</BlocksList>
						<ProgramInfos>
							<InfoSection>
								<InfoCell>
									<small>Programa</small>
									<span>{episode?.program?.name}</span>
								</InfoCell>
								<InfoCell>
									<small>Data</small>
									<span>{episode?.date}</span>
								</InfoCell>
								<InfoCell>
									<small>Número de Blocos</small>
									<span>{episode?.blocks.length}</span>
								</InfoCell>
							</InfoSection>

							<InfoSection>
								<InfoCell>
									<small>Formato</small>
									<span>{episode?.blocks[0].format}</span>
								</InfoCell>
								<InfoCell>
									<small>Qualidade</small>
									{episode?.isMasterized ? (
										<Tag color='green'>Masterizado</Tag>
									) : (
										<Tag color='#9b9b9b'>Qualidade Padrão</Tag>
									)}
								</InfoCell>
								<InfoCell>
									<small>Duração</small>
									<span>{episode?.duration}</span>
								</InfoCell>
								<InfoCell>
									<small>Tamanho</small>
									<span>
										{Math.floor(episode?.blocks.reduce((acc, block) => acc + block.size, 0))}MB
									</span>
								</InfoCell>
							</InfoSection>
						</ProgramInfos>
					</CardContent>
					<ButtonsContainer>
						{getResource(user, resourcesKeys.MASTERIZATION) !== 'BLOCKED' && (
							<Button disabled={episode.isMasterized} type='ghost' onClick={handleMasterize}>
								<FiSliders />
								Masterizar
							</Button>
						)}
						<Button
							type='primary'
							onClick={() => setShowModal({ ...showModal, downloadModal: true })}>
							<FiDownload />
							{selectedBlocks.length
								? `Baixar Blocos Selecionados (${selectedBlocks.length})`
								: 'Baixar Episódio Completo'}
						</Button>
					</ButtonsContainer>
				</Card>
			</Container>

			<EpisodeDownloadProgress isVisible={download.blocks.length !== 0} blocks={download.blocks} />

			<Modal
				title='Baixar Episódio'
				width={500}
				visible={showModal?.downloadModal && !download.blocks.length}
				footer={
					<>
						<Button
							icon='close-circle'
							onClick={() => setShowModal({ ...showModal, downloadModal: false })}>
							Cancelar
						</Button>
						<Button icon='download' type='primary' onClick={handleDowloadEpisode}>
							Baixar Episódio
						</Button>
					</>
				}>
				<RadiosContainer>
					<RadioCard
						checked={downloadMode === 'zip' ? true : false}
						onClick={() => setDownloadMode('zip')}
						label='Baixar em formato zip (Recomendado)'
						icon={GoFileZip}
						descriptions={[
							{
								text: 'Os blocos serão compactados e baixados como um único arquivo no formato .zip',
							},
						]}
					/>
					<RadioCard
						checked={downloadMode === 'separetade' ? true : false}
						onClick={() => setDownloadMode('separetade')}
						icon={GoListUnordered}
						label='Baixar blocos separadamente'
						descriptions={[
							{
								text: 'Os blocos serão baixados individualmente, correspondendo a cada bloco do episódio gerado',
							},
						]}
					/>
				</RadiosContainer>
			</Modal>

			<Modal visible={fallback.mastering} closable={false} footer={null} width={700}>
				<GeneratingEpisodeFallback>
					<Loader color='var(--primary)' />

					<Typography.Title level={4}>Seu episódio está sendo masterizado</Typography.Title>

					<Timer>
						<Clock>
							<p>Tempo decorrido: </p>
							<span>
								<Timer.Hours /> :
							</span>
							<span>
								<Timer.Minutes /> :
							</span>
							<span>
								<Timer.Seconds />
							</span>
						</Clock>
					</Timer>

					<Typography.Paragraph style={{ marginTop: 24 }}>
						<strong>Sabia que um mesmo áudio pode soar diferente, dependendo da situação?</strong>
						<br />
						<br />
						Ouvindo no carro, com fones de ouvido, em aparelhos de som com baixa ou alta
						qualidade...cada um destes <i>players</i> traduzem a faixa de uma maneira diferente. O
						processo de masterização faz com que o conteúdo soe sempre da mesma forma, independente
						do aparelho. Assim é possível consertar alguns problemas(<i>geralmente graves</i>) que
						passam despercebidos, como a diferença de volume entre as faixas por exemplo,
						normalizando todo o arquivo de áudio, deixando-o em um mesmo nível de decibéis.
						<br />
						<br />
						Mas para que isso aconteça a Talk Play Radio Edit precisa processar todo o arquivo e
						isso demanda tempo, sendo esse determinado principalmente pela duração do conteúdo.
						<br />
						<br />
						Mas não se preocupe a Talk Play <strong>não utiliza a sua internet</strong> para
						realizar esse processo. Por isso aperte o Master e vá tomar um café, estamos trabalhando
						para você.
					</Typography.Paragraph>
				</GeneratingEpisodeFallback>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(CreatedEpisiode);
