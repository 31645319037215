import { v4 as uuid } from 'uuid';
import env from '../config/env';

const resolveFileSrc = ({ fileName, customS3Bucket, folder, useAccelerate }) => {
	const preffix = 'https://';
	const suffix = useAccelerate ? '.s3-accelerate.amazonaws.com' : '.s3.amazonaws.com';
	const bucketName = customS3Bucket || env.miscS3Bucket;
	const bucketLink = `${preffix}${bucketName}${suffix}`;
	const folderPath = (folder || []).join('/');
	const filename = fileName && fileName?.startsWith('/') ? fileName.slice(1) : fileName;
	const linksArr = [bucketLink, folderPath, filename].filter((v) => !!v);

	return [linksArr.join('/'), uuid()].join('?');
};

export { resolveFileSrc };
