import React from 'react';
import {
	GlobalStyle,
	Container,
	Title,
	Paragraph,
	FlexCenter,
	FlexColumn,
	FlexItemsCenter,
	FlexItem,
	Divider,
	MaintenanceBox,
	TrafficFlowText,
} from './styles';

export default function Component() {
	return (
		<>
			<GlobalStyle />
			<Container  div id="tecnologia">
				<Title>Tecnologia De Economia de Tempo e Dinheiro</Title>
				<Paragraph>
					A tecnologia de IA TalkPlay é essencial para a operação eficiente de uma estação de rádio,
					oferecendo economia de tempo e dinheiro, além de melhorar a qualidade e rapidez nos
					processos. Aqui estão quatro aspectos principais nos quais a TalkPlay impacta diretamente:
				</Paragraph>
				<FlexCenter>
					<FlexColumn>
						<FlexItemsCenter>
							<FlexColumn>
								<MaintenanceBox className='Laranja'>Agilidade de Tempo</MaintenanceBox>
								<TrafficFlowText>
									A TalkPlay permite a criação rápida de programas, otimizando o processo de
									produção de conteúdo. Em vez de horas de trabalho manual, a IA pode gerar
									programas completos em minutos, garantindo uma resposta rápida às demandas de
									programação.
								</TrafficFlowText>
							</FlexColumn>
							<Divider />
							<FlexColumn>
								<MaintenanceBox className='Azul'>Melhoramento de Qualidade</MaintenanceBox>
								<TrafficFlowText>
									Com a TalkPlay, a qualidade do conteúdo é consistentemente alta. A IA é treinada
									para reconhecer e utilizar os melhores elementos de áudio da sua rádio, resultando
									em programas profissionais e bem estruturados, sempre alinhados aos padrões da
									estação.
								</TrafficFlowText>
							</FlexColumn>
						</FlexItemsCenter>
						<FlexItemsCenter>
							<FlexColumn>
								<FlexItem className='black-vertical' />
								<MaintenanceBox className='Verde'>Produção de Novos Programa</MaintenanceBox>
								<TrafficFlowText>
									A flexibilidade da TalkPlay facilita a criação de novos programas. A IA pode ser
									configurada para diferentes formatos e estilos, permitindo uma variedade de
									conteúdos que atendem às necessidades do público. Isso torna mais fácil e rápido
									experimentar novos conceitos e expandir a grade de programação.
								</TrafficFlowText>
							</FlexColumn>
							<Divider />
							<FlexColumn>
								<FlexItem className='black-vertical' />
								<MaintenanceBox className='Verde-escuro'>Economia Financeira</MaintenanceBox>
								<TrafficFlowText>
									A TalkPlay reduz significativamente os custos operacionais. Ao automatizar a
									produção de programas, a necessidade de recursos humanos e materiais é minimizada,
									resultando em uma operação mais econômica. Além disso, a eficiência da IA permite
									que a estação invista em outras áreas, aumentando o retorno sobre o investimento.
								</TrafficFlowText>
							</FlexColumn>
						</FlexItemsCenter>
					</FlexColumn>
				</FlexCenter>
				<TrafficFlowText>
					TalkPlay é programada para atender exatamente às necessidades do usuário, sem desvios,
					proporcionando uma solução rápida, econômica e de alta qualidade para a criação de novos
					programas de rádio
					</TrafficFlowText>
			</Container>
		</>
	);
}
