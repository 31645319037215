import React, { useCallback } from 'react';
import { Switch, Modal, Button } from 'antd';

import { Container, ElementContainer, ElementContent } from './styles';

import { useScript, useElement } from '../../hooks';

import { FiSettings, FiCheckCircle } from 'react-icons/fi';

const ConfigScriptStrs = ({ visible, onConfirm }) => {
	const { script, toggleElementSoundtrack } = useScript();
	const { parseElementName, getElementColor, getElementIcon } = useElement();

	const renderElementLabel = useCallback(
		({ element }) => {
			if (element?.options?.name) {
				return element?.options?.name;
			}

			let name = '';

			if (element?.type.startsWith('NEWS-')) {
				if (element?.type.startsWith('NEWS-MAIN')) {
					name = `NOTÍCIA REDE - ${parseElementName({ type: element?.options?.category })}`;
				}

				if (element?.type.startsWith('NEWS-LOCAL')) {
					name = `NOTÍCIA LOCAL - ${parseElementName({ type: element?.options?.category })}`;
				}
			} else if (element?.type.startsWith('BLITZ-')) {
				if (element?.type.startsWith('BLITZ-MAIN')) {
					name = `BLITZ REDE - ${element?.options?.category.split('-')[1].trim()}`;
				}

				if (element?.type.startsWith('BLITZ-LOCAL')) {
					name = `BLITZ LOCAL - ${element?.options?.category.split('-')[1].trim()}`;
				}
			} else if (element?.type.startsWith('WPP-')) {
				if (element?.type.startsWith('WPP-MAIN')) {
					name = `WPP REDE - ${element?.options?.category.split('-')[1].trim()}`;
				}

				if (element?.type.startsWith('WPP-LOCAL')) {
					name = `WPP LOCAL - ${element?.options?.category.split('-')[1].trim()}`;
				}
			} else if (element?.type.startsWith('INTERVIEW-')) {
				if (element?.type.startsWith('INTERVIEW-MAIN')) {
					name = `ENTREVISTA REDE - ${element?.options?.category.split('-')[1].trim()}`;
				}

				if (element?.type.startsWith('INTERVIEW-LOCAL')) {
					name = `ENTREVISTA LOCAL - ${element?.options?.category.split('-')[1].trim()}`;
				}
			} else {
				name = parseElementName({ type: element?.type });
			}

			return name;
		},
		[parseElementName]
	);

	return (
		<Modal
			visible={visible}
			onCancel={onConfirm}
			footer={
				<>
					<Button style={{ width: '100%' }} type='primary' onClick={onConfirm}>
						<FiCheckCircle /> Concluir
					</Button>
				</>
			}
			title={
				<>
					<FiSettings /> Configurar trilhas sonoras
				</>
			}>
			<Container>
				{script.body.map((element, index) => {
					const enabled =
						element?.type === 'TESTIMONIAL' ||
						element?.type.startsWith('OFF-') ||
						element?.type.startsWith('NEWS-') ||
						element?.type.startsWith('WPP-') ||
						element?.type.startsWith('BLITZ-') ||
						element?.type.startsWith('INTERVIEW-');

					return (
						<ElementContainer enabled={enabled} key={index}>
							<ElementContent
								color={
									element?.options?.color
										? element?.options?.color
										: getElementColor({ type: element.type })
								}>
								{getElementIcon({ type: element.type })}
								<p>{renderElementLabel({ element })}</p>
							</ElementContent>
							{enabled && (
								<Switch
									checked={element?.options?.withSoundtrack}
									onChange={(value) => toggleElementSoundtrack({ index, withSoundtrack: value })}
								/>
							)}
						</ElementContainer>
					);
				})}
			</Container>
		</Modal>
	);
};

export default ConfigScriptStrs;
