import api from '../api';

const BASE_URL = '/api/v2/block-templates';

const BlockTemplatesAPI = {
	getAll: () => api.get(BASE_URL),
	getOne: ({ id }) => api.get(`${BASE_URL}/${id}`),
	create: ({ payload }) => api.post(BASE_URL, payload),
	update: ({ id, payload }) => api.put(`${BASE_URL}/${id}`, payload),
	delete: ({ id }) => api.delete(`${BASE_URL}/${id}`),
};

export default BlockTemplatesAPI;
