import React, { useState, useEffect, useCallback, useRef } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import axios from 'axios';
import JSZip from 'jszip';
import * as Yup from 'yup';
import path from 'path';
import { connect } from 'react-redux';

import {
	Table,
	Menu,
	Dropdown,
	Button,
	Icon,
	Divider,
	Select,
	Modal,
	Typography,
	DatePicker,
	Tag,
	Breadcrumb,
	message,
	Checkbox,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import FileDuration from '../../../../components/FileDuration';
import FileExt from '../../../../components/FileExt';
import PageSizeHandler from '../../../../components/PageSizeHandle';

import PlayCell from '../../../../components/PlayCell';
import Form from '../../../../components/Form';
import { Container, OptionContent, TableHeader } from './styles';

import InterviewsAPI from '../../../../services/sdks/interviews';
import ProgramsAPI from '../../../../services/sdks/programs';

import { useElement, usePlayer, useDownload, useFilesValidator } from '../../../../hooks';
import {
	FiPlusCircle,
	FiShare2,
	FiTrash2,
	FiUploadCloud,
	FiXCircle,
	FiXOctagon,
} from 'react-icons/fi';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';
import FilesUploader from '../../../../components/FilesUploader';
import { getResource } from '../../../../helpers/getResource';
import resourcesKeys from '../../../../constants/resourcesKeys';

const Interviews = ({ user }) => {
	const player = usePlayer();
	const download = useDownload();
	const uploaderRef = useRef();
	const { hasValidationError } = useFilesValidator();

	const {
		categorizedTypes: { interviews: categories },
		parseElementName,
	} = useElement();

	const [fallback, setFallback] = useState({ initialData: false });
	const [interviews, setInterviews] = useState([]);
	const [isGlobal, setIsGlobal] = useState(false);
	const [selectedInterviews, setSelectedInterviews] = useState([]);
	const [programs, setPrograms] = useState([]);
	const [visibleModals, setVisibleModals] = useState({ addInterview: false });
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [newInterview, setNewInterview] = useState({
		category: undefined,
		programs: [],
		releaseDate: null,
		files: [],
	});

	const [filters, setFilters] = useState({
		program: undefined,
		category: undefined,
		releaseDate: null,
	});

	const columns = [
		{
			title: 'Categoria',
			key: 'category',
			render: (interview) => (
				<PlayCell
					meta={{
						primary: `ENTREVISTA REDE - ${interview.category.split('-')[1].trim()}`,
						secondary: interview?.program?.name,
					}}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: interview?.filename }),
							ref: interview?._id,
							meta: {
								name: `ENTREVISTA REDE - ${interview.category.split('-')[1].trim()}`,
								artist: interview?.program?.name,
							},
						});
					}}
					onPause={player.resume}
					isPlaying={player?.ref === interview?._id && player?.isPlaying}
				/>
			),
		},

		{
			title: 'Data de Lançamento',
			dataIndex: 'releaseDate',
			align: 'center',
			key: 'date',
		},
		{
			title: 'Duração',
			align: 'center',
			key: 'duration',
			render: (interview) => (
				<FileDuration src={resolveFileSrc({ fileName: interview?.filename })} />
			),
		},
		{
			title: 'Formato',
			align: 'center',
			key: 'ext',
			render: (interview) => <FileExt src={resolveFileSrc({ fileName: interview?.filename })} />,
		},
		getResource(user, resourcesKeys.INTERVIEW_SHARING)
			? {
					title: 'Entrevista Global?',
					align: 'center',
					key: 'isGlobal',
					render: (interview) =>
						interview?.isGlobal ? <Tag color='green'>Sim</Tag> : <Tag color='red'>Não</Tag>,
			  }
			: {},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					8
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'options',
			render: (interview) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									key='play'
									onClick={() => {
										player.start({
											src: resolveFileSrc({ fileName: interview?.filename }),
											ref: interview?._id,
											meta: {
												artist: `ENTREVISTA REDE - ${interview.category.split('-')[1].trim()}`,
												name: interview?.program?.name,
											},
										});
									}}>
									<Icon type='play-circle' /> Reproduzir
								</Menu.Item>
								<Menu.Item key='download' onClick={() => handleDownload({ interview })}>
									<Icon type='download' /> Fazer download
								</Menu.Item>
								{getResource(user, resourcesKeys.INTERVIEW_SHARING) && (
									<Menu.Item
										key='share'
										onClick={() => handleUpdateIsGlobalFlag([interview._id], !interview.isGlobal)}>
										{interview.isGlobal ? (
											<>
												<FiXOctagon /> Remover compartilhamento
											</>
										) : (
											<>
												<FiShare2 /> Compartilhar{' '}
											</>
										)}
									</Menu.Item>
								)}

								<Menu.Divider />

								<Menu.Item
									key='delete'
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: 'Excluir entrevista?',
											icon: 'exclamation-circle',
											content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											okText: 'Excluir',
											onOk: () => handleDelete({ interviewId: interview?._id }),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<Icon type='delete' /> Excluir
								</Menu.Item>
							</Menu>
						}>
						<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const handleUpdateIsGlobalFlag = useCallback(async (interviewIds, isGlobal) => {
		try {
			setFallback((prev) => ({ ...prev, loadingActions: true }));
			message.loading('Atualizando...');

			await InterviewsAPI.updateGlobalFlag({ payload: { interviewIds, isGlobal } });

			message.destroy();
			message.success(
				isGlobal
					? 'A entrevistas foram disponibilizadas com sucesso'
					: 'O compartilhamento das entrevistas foi removido e as rádios não podem mais utilizá-las',
				6
			);
			setFallback((prev) => ({ ...prev, loadingActions: false }));
			setInterviews((interviews) =>
				interviews.map((n) => {
					return interviewIds.includes(n._id) ? { ...n, isGlobal } : n;
				})
			);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao atualizar os wpps');

			setFallback((prev) => ({ ...prev, loadingActions: false }));
		}
	}, []);

	const handleDownload = useCallback(
		async ({ interview }) => {
			try {
				const name = `ENTREVISTA REDE - ${interview.category.split('-')[1].trim()}`;

				return download({ filename: interview?.filename, name });
			} catch (error) {
				console.error(error);
				message.error('Não foi possível fazer o download.');
			}
		},
		[download]
	);

	const handleDownloadAsZIP = useCallback(async () => {
		setFallback((prev) => ({ ...prev, multiDownload: true }));

		const zip = new JSZip();

		for (let index = 0; index < selectedInterviews.length; index++) {
			const interview = selectedInterviews[index];
			const filePath = resolveFileSrc({ fileName: interview?.filename });
			const blob = await axios.get(filePath, { responseType: 'blob' });
			const name = `ENTREVISTA REDE - ${interview.category.split('-')[1].trim()}`;
			const ext = path.extname(interview?.filename);
			const fName = `${interview?.program?.name} - ${name}${ext}`;

			zip.file(fName, blob.data, { binary: true });
		}

		const zipContent = await zip.generateAsync({ type: 'blob' });
		const selectedProgram = programs.find(({ _id }) => _id === filters?.program);

		let downloadName = 'ESTREVISTAS REDE';

		selectedProgram && (downloadName = `${downloadName} - ${selectedProgram.name}`);
		filters?.releaseDate && (downloadName = `${downloadName} - ${filters.releaseDate}`);

		saveAs(zipContent, downloadName);
		setFallback((prev) => ({ ...prev, multiDownload: false }));
	}, [programs, selectedInterviews, filters]);

	const handleDelete = useCallback(async ({ interviewId }) => {
		try {
			setFallback((prev) => ({ ...prev, deleting: true }));

			await InterviewsAPI.destroy({ interviewId });

			setInterviews((interviews) => interviews.filter((i) => i?._id !== interviewId));
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao deletar a entrevista');
		} finally {
			setFallback((prev) => ({ ...prev, deleting: false }));
		}
	}, []);

	const handleMultiDelete = useCallback(async () => {
		for (let index = 0; index < selectedInterviews.length; index++) {
			const { _id: interviewId } = selectedInterviews[index];
			await handleDelete({ interviewId });
		}

		setSelectedInterviews([]);
	}, [selectedInterviews, handleDelete]);

	const uploadInterviews = useCallback(
		(file, programId) => {
			const payload = new FormData();

			payload.append('category', newInterview.category);
			payload.append('releaseDate', newInterview.releaseDate);
			payload.append('program', programId);
			payload.append('user', user?._id);
			payload.append('isLocal', false);
			payload.append('isGlobal', isGlobal);
			payload.append('file', file.data);

			return InterviewsAPI.store({
				payload,
				onUploadProgress: ({ total, loaded }) => {
					file.upload.update(Math.floor((loaded * 100) / total));
				},
			});
		},
		[newInterview, user, isGlobal]
	);

	const createProgramInterviews = useCallback(
		async (programId) => {
			const programName = programs.find((p) => p._id === programId)?.name ?? '';
			message.loading(`Enviando entrevistas do programa ${programName}`, 4);
			await Promise.all(newInterview.files.map((file) => uploadInterviews(file, programId)));
		},
		[newInterview.files, programs, uploadInterviews]
	);

	const handleCreateInterviews = useCallback(async () => {
		try {
			if (hasValidationError(newInterview.files)) {
				return message.warning(
					'Alguns dos arquivos selecionados estão corrompidos. Por favor, substitua-os por arquivos válidos.'
				);
			}

			const validationSchema = Yup.object().shape({
				category: Yup.string().required('Informe a categoria'),
				releaseDate: Yup.string().required('Informe a data de lançamento'),
				files: Yup.array().required('Selecione no mínimo 1 arquivo'),
			});

			await validationSchema.validate(newInterview);
			setFallback((prev) => ({ ...prev, uploadingInterviews: true }));

			for (const program of newInterview.programs) {
				await createProgramInterviews(program);
			}

			message.success('Entrevistas enviadas com sucesso', 3, () => {
				window.location.reload();
			});
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			console.error(error);
			message.error('Houve um erro, tente novamente');
		} finally {
			setFallback((prev) => ({ ...prev, uploadingInterviews: false }));
		}
	}, [hasValidationError, newInterview, createProgramInterviews]);

	useEffect(() => {
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters]);

	useEffect(() => {
		const fetchInterviews = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingInterviews: true }));

				let query = `isLocal=false&page=${pagination?.current - 1}&limit=${
					pagination?.pageSize
				}&user=${user?._id}&`;

				filters?.program && (query = `${query}program=${filters.program}&`);
				filters?.releaseDate && (query = `${query}releaseDate=${filters.releaseDate}&`);
				filters?.category && (query = `${query}category=${filters.category}&`);

				const {
					data: { interviews, total },
				} = await InterviewsAPI.index({ query });

				setPagination((prev) => ({ ...prev, total }));
				setInterviews(interviews);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as wpps');
			} finally {
				setFallback((prev) => ({ ...prev, fetchingInterviews: false }));
			}
		};

		fetchInterviews();
	}, [filters, pagination.current, pagination.pageSize]); //eslint-disable-line

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { programs },
				} = await ProgramsAPI.index(`userId=${user?._id}&isDeleted=false`);

				setPrograms(
					programs.sort((x, y) => {
						return x?.isFavorited === y?.isFavorited ? 0 : x?.isFavorited ? -1 : 1;
					})
				);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os programas');
			} finally {
				setFallback((prev) => ({ ...prev, initialData: false }));
			}
		};

		if (user) {
			fetchInitialData();
		}
	}, [user]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Entrevistas Rede' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'INÍCIO' },
						{ breadcrumbName: 'NARRAÇÕES' },
						{ breadcrumbName: 'ENTREVISTAS REDE' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Entrevistas rede</Typography.Title>
					<Button
						type='primary'
						onClick={() => setVisibleModals({ ...visibleModals, addInterview: true })}>
						<FiPlusCircle /> Adicionar entrevistas
					</Button>
				</header>

				<Form.Container layout='30% 30% 30%'>
					<Form.Item label='Filtrar por programa'>
						<Select
							showSearch
							value={filters.program}
							placeholder='Programa'
							onChange={(value) => setFilters({ ...filters, program: value })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							<Select.Option key='all' value={undefined} _search='TODOS'>
								TODOS
							</Select.Option>

							{programs.map((program) => (
								<Select.Option
									key={program._id}
									disabled={!program.isActive}
									_search={program?.name}>
									<OptionContent>
										<Icon
											theme='filled'
											type='heart'
											style={{
												color: 'var(--danger)',
												marginRight: 8,
												opacity: program?.isFavorited ? 1 : 0,
												pointerEvents: 'none',
											}}
										/>
										<span>
											{program?.name}
											{!program?.isActive && <Tag color='red'>Inadimplente</Tag>}
										</span>
									</OptionContent>
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por categoria'>
						<Select
							showSearch
							value={filters.category}
							placeholder='Categoria'
							onChange={(category) => setFilters({ ...filters, category })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							<Select.Option key='all' value={undefined} _search='TODOS'>
								TODOS
							</Select.Option>

							{categories.map((category) => (
								<Select.Option value={category} key={category}>
									ENTREVISTA REDE - {category.split('-')[1].trim()}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por data'>
						<DatePicker
							placeholder='Data de lançamento'
							format='DD/MM/yyyy'
							value={filters?.releaseDate && moment(filters.releaseDate, 'DD/MM/yyyy')}
							onChange={(_, releaseDate) => setFilters({ ...filters, releaseDate })}
						/>
					</Form.Item>
				</Form.Container>

				<Divider />

				<TableHeader>
					<div className='actions'>
						<span>
							Quantidade: <strong>{pagination?.total}</strong>
						</span>
						<div>
							<Dropdown.Button
								disabled={!selectedInterviews.length || fallback.loadingActions}
								size='small'
								type='ghost'
								onClick={handleDownloadAsZIP}
								overlay={
									<Menu>
										<Menu.Item
											key='1'
											onClick={() => {
												handleUpdateIsGlobalFlag(
													selectedInterviews.map((n) => n._id),
													true
												);
											}}>
											<FiShare2 />
											Compartilhar entrevistas selecionadas
										</Menu.Item>

										<Menu.Item
											key='2'
											onClick={() => {
												handleUpdateIsGlobalFlag(
													selectedInterviews.map((n) => n._id),
													false
												);
											}}>
											<FiShare2 />
											Remover compartilhamento=
										</Menu.Item>

										<Menu.Item
											key='2'
											onClick={() => {
												Modal.confirm({
													title: 'Deletar entrevistas selecionadas?',
													type: 'danger',
													content:
														'Todos as entrevistas selecionados serão excluídos e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
													okText: 'Deletar',
													onOk: handleMultiDelete,
													okButtonProps: {
														icon: 'delete',
														type: 'danger',
													},
													cancelText: 'Cancelar',
													cancelButtonProps: {
														icon: 'close-circle',
													},
												});
											}}>
											<FiTrash2 /> Deletar selecionados{' '}
											{selectedInterviews.length !== 0 && `(${selectedInterviews.length})`}
										</Menu.Item>
									</Menu>
								}>
								Baixar
							</Dropdown.Button>
						</div>
					</div>

					<PageSizeHandler pagination={pagination} setPagination={setPagination} />
				</TableHeader>

				<Table
					loading={fallback?.fetchingInterviews}
					size='middle'
					rowKey='_id'
					columns={columns}
					dataSource={interviews}
					style={{ border: 'none' }}
					pagination={{
						...pagination,
						size: 'large',
						onChange: (current) => setPagination({ ...pagination, current }),
					}}
					rowSelection={{
						onChange: (_, selectedRows) => setSelectedInterviews(selectedRows),
					}}
				/>
			</Container>

			<Modal
				width={600}
				visible={visibleModals?.addInterview}
				onCancel={() => setVisibleModals({ ...visibleModals, addInterview: false })}
				onOk={handleCreateInterviews}
				okButtonProps={{
					loading: fallback?.uploadingInterviews,
					disabled:
						!newInterview?.files.length ||
						!newInterview?.category ||
						!newInterview?.programs.length,
				}}
				cancelButtonProps={{ disabled: fallback?.uploadingInterviews }}
				okText={
					<>
						<FiUploadCloud /> Enviar entrevista
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				title={
					<>
						<FiPlusCircle /> Adicionar entrevista
					</>
				}>
				<Form.Container>
					<Form.Item label='Categoria'>
						<Select
							showSearch
							value={newInterview?.category}
							placeholder='Categoria'
							style={{ marginBottom: 8, width: '100%' }}
							onChange={(category) => setNewInterview({ ...newInterview, category })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							{categories.map((category) => (
								<Select.Option
									key={category}
									value={category}
									_search={parseElementName({ type: category })}>
									ENTREVISTA REDE - {category.split('-')[1].trim()}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Programas'>
						<Select
							showSearch
							mode='multiple'
							value={newInterview.programs}
							placeholder='Programas'
							style={{ marginBottom: 8, width: '100%' }}
							onChange={(programs) => setNewInterview({ ...newInterview, programs })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							{programs.map((program) => (
								<Select.Option
									key={program._id}
									disabled={!program.isActive}
									_search={program?.name}>
									<OptionContent>
										<Icon
											theme='filled'
											type='heart'
											style={{
												color: 'var(--danger)',
												marginRight: 8,
												opacity: program?.isFavorited ? 1 : 0,
												pointerEvents: 'none',
											}}
										/>
										<span>
											{program?.name}
											{!program?.isActive && <Tag color='red'>Inadimplente</Tag>}
										</span>
									</OptionContent>
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Data de lançamento'>
						<DatePicker
							placeholder='Data de lançamento'
							format='DD/MM/yyyy'
							style={{ width: '100%' }}
							value={newInterview?.releaseDate && moment(newInterview.releaseDate, 'DD/MM/yyyy')}
							onChange={(_, releaseDate) => setNewInterview({ ...newInterview, releaseDate })}
						/>
					</Form.Item>

					<FilesUploader
						multiple
						ref={uploaderRef}
						onChange={(files) => setNewInterview((prev) => ({ ...prev, files }))}
						validTypes={['.mp3', '.wav']}
					/>

					{getResource(user, resourcesKeys.INTERVIEW_SHARING) && (
						<Form.Item>
							<Checkbox checked={isGlobal} onChange={(e) => setIsGlobal(e.target.checked)}>
								Disponibilizar entrevistas para as demais rádios
							</Checkbox>
						</Form.Item>
					)}
				</Form.Container>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(Interviews);
