import styled from 'styled-components';
import AudioPlayer from 'react-h5-audio-player';

export const TabWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	.ant-tabs-nav-container {
		display: none !important;
	}

	& > header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 12px;
	}
`;

export const VersionsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 4;
`;

export const TabHeader = styled.header`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;

	& > span {
		margin-top: 12px;
	}

	& > div {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		& > h4 {
			margin: 0;
		}
	}
`;

export const StyledAudioPlayer = styled(AudioPlayer)`
	background-color: #ffffff;
	border-radius: 5px;
	border: 2px solid #fbc02d;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
	margin-bottom: 10px;

	button {
		color: #fbc02d;
	}
	.rhap_progress-indicator {
		background-color: #fbc02d;
	}
	.rhap_volume-indicator {
		background-color: #fbc02d;
	}
`;

export const TableActions = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;

	& > div.actions {
		padding-right: 24px;
		margin-right: 24px;
		border-right: 1px solid #dedede;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex: 1;

		& > span {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.65);
		}

		& > div {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			& > .ant-btn {
				margin: 0 2px;
			}
		}
	}
`;

export const TableHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;

	& > div.actions {
		padding-right: 24px;
		margin-right: 24px;
		border-right: 1px solid #dedede;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex: 1;

		& > span {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.65);
		}

		& > div {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			& > .ant-btn {
				margin: 0 2px;
			}
		}
	}
`;

export const FiltersContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 32px;

	& > div.filters {
		display: flex;
		align-items: center;
	}
`;
