import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

import { LinkContainer } from './styles';
import { FiFileText } from 'react-icons/fi';

const SellerNavigation = ({ collapsed }) => {
	const [selectedKeys, setSelectedKeys] = useState(['dash']);

	const renderMenuTitle = useCallback(
		(title, Icon, to) =>
			to ? (
				<Link to={to}>
					{collapsed ? (
						<>
							<Icon style={{ fontSize: 18, marginBottom: -4 }} />
						</>
					) : (
						<LinkContainer>
							<Icon /> {title}
						</LinkContainer>
					)}
				</Link>
			) : collapsed ? (
				<>
					<Icon style={{ fontSize: 18, marginBottom: -4 }} />
				</>
			) : (
				<LinkContainer>
					<Icon /> {title}
				</LinkContainer>
			),
		[collapsed]
	);

	return (
		<Menu
			theme='dark'
			mode='inline'
			style={{ border: 'none' }}
			selectedKeys={selectedKeys}
			defaultOpenKeys={['contact-requests']}
			onSelect={({ key }) => setSelectedKeys([key])}
		>
			<Menu.Item key='contact-requests'>
				{renderMenuTitle('R. de Contato', FiFileText, '/admin/contact-requests')}
			</Menu.Item>
		</Menu>
	);
};

export default SellerNavigation;
