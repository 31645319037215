import styled from 'styled-components';

export const Container = styled.div`
	padding: 16px;

	& > .ant-card {
		margin: 12px 0;
	}

	& footer.btn-container {
		margin-top: 12px;
		display: flex;
		justify-content: flex-end;
	}
`;
