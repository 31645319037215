import React from 'react';
import { Container, ContentWrapper, LeftColumn, RightColumn } from './styles';
import UploadTeachSection from './UploadTeachSection';
import CreatePromptSection from './CreatePromptSection';
import EditProduceDeliverSection from './EditProduceDeliverSection';
import GetStartedSection from './GetStartedSection';

export default function Component() {
	return (
		<Container>
            <div id="explication"></div>
			<ContentWrapper>
				<LeftColumn>
					<UploadTeachSection />
                    <EditProduceDeliverSection />
				</LeftColumn>
				<RightColumn>
                <CreatePromptSection />
					
					<GetStartedSection />
				</RightColumn>
			</ContentWrapper>
		</Container>
	);
}
