import api from '../api';

const BASE_URL = '/api/v2/generation-lock';

const GenerationLockApi = {
	show: () => api.get(BASE_URL),
	toggleStatus: () => api.patch(BASE_URL),
};

export default GenerationLockApi;
