import { useCallback, useMemo } from 'react';

const useSoundtracks = () => {
	const categories = useMemo(
		() => [
			'GENERIC',
			'OFF',
			'BLITZ',
			'WPP',
			'INTERVIEW',
			'NEWS-GENERIC',
			'NEWS-INTERNATIONAL',
			'NEWS-HEALTH',
			'NEWS-MONEY',
			'NEWS-EDUCATION',
			'NEWS-POLICY',
			'NEWS-ECONOMY',
			'NEWS-ELECTIONS',
			'NEWS-FARMING',
			'NEWS-ENTERTAINMENT',
			'NEWS-TECHNOLOGY',
			'NEWS-CINE',
			'NEWS-SPORTS',
			'NEWS-GOSSIP',
			'NEWS-MUSIC',
			'NEWS-PET',
			'NEWS-CARS',
			'NEWS-WOMAN',
			'NEWS-HOROSCOPE',
		],
		[]
	);

	const categoriesDictonary = useMemo(
		() => ({
			GENERIC: 'TODOS OS ARQUIVOS',
			OFF: 'OFFS',
			BLITZ: 'BLITZ',
			WPP: "WPP'S",
			INTERVIEW: 'ENTREVISTAS',
			'NEWS-GENERIC': 'NOTÍCIAS (GERAL)',
			'NEWS-INTERNATIONAL': 'NOTÍCIAS (INTERNACIONAL)',
			'NEWS-HEALTH': 'NOTÍCIAS (SAÚDE E BEM ESTAR)',
			'NEWS-MONEY': 'NOTÍCIAS (DINHEIRO)',
			'NEWS-EDUCATION': 'NOTÍCIAS (EDUCAÇÃO)',
			'NEWS-POLICY': 'NOTÍCIAS (POLÍTICA)',
			'NEWS-ECONOMY': 'NOTÍCIAS (ECONOMIA)',
			'NEWS-ELECTIONS': 'NOTÍCIAS (ELEIÇÕES)',
			'NEWS-FARMING': 'NOTÍCIAS (AGROPECUÁRIA)',
			'NEWS-ENTERTAINMENT': 'NOTÍCIAS (ENTRETENIMENTO)',
			'NEWS-TECHNOLOGY': 'NOTÍCIAS (GAMES & TECNOLOGIA)',
			'NEWS-CINE': 'NOTÍCIAS (CINEMA E PIPOCA)',
			'NEWS-SPORTS': 'NOTÍCIAS (ESPORTES)',
			'NEWS-GOSSIP': 'NOTÍCIAS (FOFOCAS & BASTIDORES)',
			'NEWS-MUSIC': 'NOTÍCIAS (MÚSICA)',
			'NEWS-PET': 'NOTÍCIAS (PET)',
			'NEWS-CARS': 'NOTÍCIAS (CARROS)',
			'NEWS-WOMAN': 'NOTÍCIAS (MULHERES)',
			'NEWS-HOROSCOPE': 'NOTÍCIAS (HORÓSCOPO)',
		}),
		[]
	);

	const parseSoundtrackCategory = useCallback(
		(rawCategory) => {
			return categoriesDictonary[rawCategory];
		},
		[categoriesDictonary]
	);

	return {
		categories,
		parseSoundtrackCategory,
	};
};

export default useSoundtracks;
