import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import querystring from 'query-string';
import {
	Button,
	Typography,
	Divider,
	message,
	Modal,
	Tooltip,
	Breadcrumb,
	Input,
	Switch,
	Form as AntForm,
	Select,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import Script from '../../../../components/Script';
import ConfigScriptStrs from '../../../../components/ConfigScriptStrs';
import ElementsTabs from '../../../../components/ElementsTabs';
import Form from '../../../../components/Form';
import { Container, Grid, ScriptWrapper, ButtonContainer, OptionsContainer } from './styles';

import ScriptsAPI from '../../../../services/sdks/scripts';
import ProgramsAPI from '../../../../services/sdks/programs';
import ScriptTemplatesAPI from '../../../../services/sdks/scriptTemplates';
import ElementsAPI from '../../../../services/sdks/elements';

import { FiList, FiMaximize, FiMinimize, FiSave, FiSettings, FiXSquare } from 'react-icons/fi';

import { useScript } from '../../../../hooks';

import env from '../../../../config/env';
import TemplatesModal from '../../../../components/TemplatesModal';
import TKVoiceConfigAPI from '../../../../services/sdks/tkVoiceConfig';
import { checkResource } from '../../../../helpers/checkResource';
import resourcesKeys from '../../../../constants/resourcesKeys';

const CreateScript = ({ user }) => {
	const history = useHistory();
	const { search } = useLocation();

	const [fallback, setFallback] = useState({ initialData: true });
	const [name, setName] = useState('');
	const [weekDay, setWeekDay] = useState(undefined);
	const [errors, setErrors] = useState({});
	const [templates, setTemplates] = useState([]);
	const [visibleModals, setVisibleModals] = useState({});
	const [showInactiveElements, setShowInactiveElements] = useState(true);
	const [tkVoiceTypes, setTkVoiceTypes] = useState({ caster: [], local: [] });

	const {
		cleanUp,
		setElements,
		script,
		setScript,
		program,
		setProgram,
		isSorting,
		setIsSorting,
		onElementMove,
		compact,
		setCompact,
	} = useScript();

	const handleCreateScript = useCallback(async () => {
		try {
			const validationErrors = { ...errors, name: !name, weekDay: weekDay === undefined };

			setErrors(validationErrors);

			if (Object.values(validationErrors).some((e) => e)) {
				return message.error('Informe todos os campos obrigatórios');
			}

			setFallback((prev) => ({ ...prev, creatingScript: true }));

			await ScriptsAPI.store({
				payload: {
					body: script.body,
					program: program?._id,
					userId: user?._id,
					name,
					weekDay,
				},
			});

			setFallback((prev) => ({ ...prev, creatingScript: false }));

			message.success('Modelo criado com sucesso');
			history.push(`/commom/programs/${program?._id}/details`);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
			message.error(error?.response?.data?.message);

			setFallback((prev) => ({ ...prev, creatingScript: false }));
		}
	}, [errors, script, program, user, name, history, weekDay]);

	const handleLoadTemplate = useCallback(
		(template) => {
			setScript((prev) => ({ ...prev, body: template.body }));
			setVisibleModals((prev) => ({ ...prev, loadTemplate: false }));
		},
		[setScript]
	);

	useEffect(() => cleanUp(), [cleanUp]);

	useEffect(() => {
		const fetchInitialData = async ({ programId }) => {
			try {
				const [
					{
						data: { program },
					},
					{
						data: { templates },
					},
					{
						data: { elements },
					},
				] = await Promise.all([
					await ProgramsAPI.show(programId),
					await ScriptTemplatesAPI.getAll(),
					await ElementsAPI.index({ query: `userId=${user?._id}&program=${programId}` }),
				]);

				const cashTracks = elements.filter(({ type }) => type === 'CASHTRACK');
				const customs = elements.filter(({ type }) => type === 'CUSTOM');
				const merchans = elements.filter(({ type }) => ['SPONSOR', 'OFFERING'].includes(type));
				const trackAds = elements.filter(({ type }) => type === 'TRACK-AD');

				setTemplates(templates);
				setScript((prev) => ({ ...prev, body: [{ type: 'NEW-BLOCK', options: null }] }));
				setProgram({ ...program, isTalkProgram: program?.userId?._id === env.talkId });
				setElements({ cashTracks, customs, merchans, trackAds });
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
			}
		};

		const { program: programId } = querystring.parse(search);

		programId && fetchInitialData({ programId });
	}, [search, user, setElements, setProgram, setScript]);

	useEffect(() => {
		const fetchTkVoiceTypes = async () => {
			try {
				const res = await TKVoiceConfigAPI.getPopulatedTypes(program._id, user?._id);
				setTkVoiceTypes(res.data.types);
			} catch (error) {
				setTkVoiceTypes({ caster: [], local: [] });
			}
		};

		if (program && user) {
			fetchTkVoiceTypes();
		}
	}, [program, user]);

	useEffect(() => {
		if (program) {
			if (!program?.isEditable) {
				return history.push(`/commom/programs/${program?._id}/details`);
			}
		}
	}, [program, history]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Criar Modelos' />

			<Container isSorting={isSorting}>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'INÍCIO' },
						{ breadcrumbName: 'PROGRAMAS' },
						{ breadcrumbName: 'DETALHES DO PROGRAMA' },
						{ breadcrumbName: 'CRIAR MODELO' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Criar modelo</Typography.Title>
				</header>

				<Divider />

				<Grid>
					<ElementsTabs isAdmin={false} user={user} tkVoiceTypes={tkVoiceTypes} />
					<ScriptWrapper>
						<header>
							<div className='title-container'>
								<Typography.Title level={4}>Modelo</Typography.Title>

								{checkResource(
									user,
									[
										resourcesKeys.VIGNETTES_RADIO_CONTINUOUS,
										resourcesKeys.VIGNETTES_RADIO_SCHEDULED,
									],
									{ strict: false }
								) && (
									<div
										className='switch-container'
										onClick={() => setShowInactiveElements(!showInactiveElements)}>
										<Switch checked={showInactiveElements} style={{ pointerEvents: 'none' }} />
										<span>Mostrar vinhetas inativas</span>
									</div>
								)}
							</div>
							{checkResource(user, resourcesKeys.TAG) && (
								<Button
									size='small'
									onClick={() => setVisibleModals({ ...visibleModals, loadTemplate: true })}>
									<FiList />
									Carregar Template
								</Button>
							)}
						</header>

						<Divider style={{ margin: '8px 0' }} />

						<OptionsContainer>
							{checkResource(user, resourcesKeys.SOUNDTRACK) && (
								<Button
									style={{ flex: 1 }}
									size='small'
									disabled={script?.body?.length === 0 || script?.isShared}
									onClick={() => setVisibleModals({ ...visibleModals, configStrs: true })}>
									<FiSettings /> Configurar trilhas sonoras
								</Button>
							)}

							<div className='divider'></div>

							<Tooltip title={compact ? 'Maximizar' : 'Compactar'}>
								<Button
									size='small'
									onClick={() => setCompact(!compact)}
									disabled={script?.body?.length === 0}>
									{compact ? <FiMaximize /> : <FiMinimize />}
								</Button>
							</Tooltip>

							<Tooltip title={script?.body?.length !== 0 && !script?.isShared && 'Limpar modelo'}>
								<Button
									disabled={script?.body?.length === 0 || script?.isShared}
									type='danger'
									size='small'
									onClick={() => {
										Modal.confirm({
											title: 'Limpar modelo?',
											icon: 'exclamation-circle',
											content: 'Todos os blocos e elementos serão removidos, deseja continuar?',
											okText: 'Limpar modelo',
											onOk: () => setScript({ ...script, body: [] }),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<FiXSquare />
								</Button>
							</Tooltip>
						</OptionsContainer>

						<Divider style={{ margin: '8px 0' }} />

						<Script
							showInactiveElements={showInactiveElements}
							onSortEnd={onElementMove}
							onSortStart={() => setIsSorting(true)}
							loggedUser={user}
							tkVoiceTypes={tkVoiceTypes}
						/>
					</ScriptWrapper>
				</Grid>

				<Form.Container layout='1fr 1fr' style={{ marginTop: 16 }}>
					<AntForm.Item
						validateStatus={errors['name'] && 'error'}
						help={errors['name'] && 'Informe o nome do modelo'}>
						<Input
							size='large'
							className='uppercase-input'
							placeholder='Nome do Modelo'
							onChange={(e) => setName(e.target.value.toUpperCase())}
						/>
					</AntForm.Item>

					<AntForm.Item
						validateStatus={errors['weekDay'] && 'error'}
						help={errors['weekDay'] && 'Informe o dia da semana à qual esse modelo corresponde'}>
						<Select
							size='large'
							placeholder='Dia da Semana'
							style={{ fontSize: 14 }}
							onChange={(value) => setWeekDay(value)}>
							<Select.Option value={0}>Domingo</Select.Option>
							<Select.Option value={1}>Segunda-Feira</Select.Option>
							<Select.Option value={2}>Terça-Feira</Select.Option>
							<Select.Option value={3}>Quarta-Feira</Select.Option>
							<Select.Option value={4}>Quinta-Feira</Select.Option>
							<Select.Option value={5}>Sexta-Feira</Select.Option>
							<Select.Option value={6}>Sábado</Select.Option>
						</Select>
					</AntForm.Item>
				</Form.Container>

				<ButtonContainer>
					<Button
						type='primary'
						disabled={script?.body?.length === 0}
						onClick={handleCreateScript}
						loading={fallback?.creatingScript}>
						<FiSave /> Criar modelo
					</Button>
				</ButtonContainer>
			</Container>

			<ConfigScriptStrs
				visible={visibleModals?.configStrs}
				onConfirm={() => setVisibleModals({ ...visibleModals, configStrs: false })}
			/>

			<TemplatesModal
				visible={visibleModals.loadTemplate}
				onConfirm={handleLoadTemplate}
				onCancel={() => setVisibleModals({ ...visibleModals, loadTemplate: false })}
				templates={templates}
			/>
		</>
	);
};

export default connect(({ user }) => ({ user }))(CreateScript);
