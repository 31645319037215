import api from '../api';

const url = '/api/v2/playlist-scripts';

const PlaylistScriptAPI = {
	show: (programId) => api.get(`${url}/${programId}`),
	upsert: (payload) => api.put(url, payload),
};

export default PlaylistScriptAPI;
