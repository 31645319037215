import React, { useMemo, useCallback, useState } from 'react';
import { Typography, Divider, Button, message } from 'antd';
import { connect } from 'react-redux';

import { Container, Blocks, Block, Tracks, Track } from './styles';

import TracksAPI from '../../services/sdks/tracks';
import { usePlayer } from '../../hooks';

import { FiPlay, FiSquare } from 'react-icons/fi';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';

const PlaylistView = ({ data, user }) => {
	const player = usePlayer();

	const [fallback, setFallback] = useState({ fetchgingTrack: null });
	const [playingTrackId, setPlayingTrackId] = useState(null);

	const date = useMemo(() => (data ? data[3][1] : null), [data]);
	const program = useMemo(() => (data ? data[4][1] : null), [data]);
	const blocks = useMemo(() => {
		if (!data) return null;

		const _blocks = [];
		const allTracks = data.slice(6, data.length).map((row) => [row[2], row[3], row[4]]);

		allTracks.forEach((row) => {
			const isNewBlock = row[0].startsWith('BLOCO ') && !row[1] && !row[2];

			if (isNewBlock) {
				_blocks.push({
					name: `BLOCO #${_blocks.length + 1}`,
					tracks: [],
				});
			} else {
				_blocks[_blocks.length - 1].tracks.push(row);
			}
		});

		return _blocks;
	}, [data]);

	const handlePlaySong = useCallback(
		async ({ artist, trackName, trackId }) => {
			try {
				artist = artist.replace('&', '|');
				trackName = trackName.replace('&', '|');

				setFallback((prev) => ({ ...prev, fetchgingTrack: true }));
				setPlayingTrackId(trackId);

				const { data: dbTracks } = await TracksAPI.index({
					query: `artist=${artist}&name=${trackName}&active=true`,
				});

				if (dbTracks.total) {
					return await player.start({
						src: resolveFileSrc({ fileName: dbTracks.tracks[0]?.filename }),
						ref: dbTracks.tracks[0]?._id,
						meta: {
							name: dbTracks.tracks[0]?.name,
							artist: dbTracks.tracks[0]?.artist,
						},
					});
				}

				/**
				 * Música não foi encontrada no banco.
				 * Busca dentre as músicas do usuário */
				const { data: userTracks } = await TracksAPI.index({
					query: `artist=${artist}&name=${trackName}&active=false&userId=${user?._id}`,
				});

				if (userTracks.total) {
					return await player.start({
						src: resolveFileSrc({ fileName: userTracks.tracks[0]?.filename }),
						ref: userTracks.tracks[0]?._id,
						meta: {
							name: userTracks.tracks[0]?.name,
							artist: userTracks.tracks[0]?.artist,
						},
					});
				}

				return message.error('Não foi possível reproduzir a música');
			} catch (error) {
				console.error(error);
				message.error('Houve um erro, tente novamente');

				setPlayingTrackId(null);
			} finally {
				setFallback((prev) => ({ ...prev, fetchgingTrack: null }));
			}
		},
		[player, user]
	);

	const handleStopPlayer = useCallback(() => {
		setPlayingTrackId(null);
		player.stop();
	}, [player]);

	if (!blocks) {
		return null;
	}

	return (
		<Container>
			<Typography.Title style={{ marginBottom: 4 }} level={4}>
				PLAYLIST - {program}
			</Typography.Title>
			<Typography.Text>{date}</Typography.Text>

			<Divider />

			<Blocks>
				{blocks.map((block, blockIndex) => (
					<Block key={blockIndex}>
						<header>
							<p>{block.name}</p>
						</header>

						<Tracks>
							<tbody>
								{block.tracks.map(([trackName, artist], trackIndex) => (
									<Track key={trackIndex}>
										<td>
											<span className='index'>#{trackIndex + 1}</span>
										</td>
										<td>
											<strong>{artist}</strong>
										</td>
										<td>
											<p>{trackName}</p>
										</td>
										<td></td>
										<td>
											<div className='actions'>
												<Button
													size='small'
													style={{ width: '100%' }}
													onClick={() => {
														playingTrackId === `${blockIndex}-${trackIndex}`
															? handleStopPlayer()
															: handlePlaySong({
																	artist,
																	trackName,
																	trackId: `${blockIndex}-${trackIndex}`,
																});
													}}
													loading={
														fallback?.fetchgingTrack &&
														playingTrackId === `${blockIndex}-${trackIndex}`
													}
													disabled={
														fallback?.fetchgingTrack &&
														fallback?.fetchgingTrack !== `${blockIndex}-${trackIndex}`
													}
												>
													{fallback?.fetchgingTrack &&
													playingTrackId === `${blockIndex}-${trackIndex}` ? (
														<span style={{ paddingLeft: 4 }}>Carregando...</span>
													) : playingTrackId === `${blockIndex}-${trackIndex}` ? (
														<>
															<FiSquare /> Parar
														</>
													) : (
														<>
															<FiPlay /> Reproduzir
														</>
													)}
												</Button>
											</div>
										</td>
									</Track>
								))}
							</tbody>
						</Tracks>
					</Block>
				))}
			</Blocks>
		</Container>
	);
};

export default connect(({ user }) => ({ user }))(PlaylistView);
