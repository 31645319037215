import React from 'react';

import { FormContainer, ItemContainer } from './styles';

const Container = ({ children, layout, ...rest }) => {
	return (
		<FormContainer layout={layout} {...rest}>
			{children}
		</FormContainer>
	);
};

const Item = ({ children, label, size, optional, error, errorMessage, help, ...rest }) => {
	return (
		<ItemContainer size={size} {...rest} error={error}>
			<label className='form-item-label'>
				{label && label} {optional && <small style={{ opacity: 0.7 }}>(Opcional)</small>}{' '}
			</label>
			{children}
			{error && <span className='error'>{errorMessage || 'Campo obrigatório'}</span>}
			{<span className='help'>{!error && help && help}</span>}
		</ItemContainer>
	);
};

const Form = { Container, Item };

export default Form;
