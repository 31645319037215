import React, { useState } from 'react';
import {
	Button,
	Switch,
	Modal,
	Icon,
	Divider,
	Tabs,
	Tooltip,
	Dropdown,
	Menu,
	Typography,
	Select,
	Checkbox,
	Slider,
} from 'antd';

import {
	Block as StyledBlock,
	Blocks,
	Categories,
	Category as StyledCategory,
	InactiveDay,
	NewBlock,
	NewCategory,
	ShareModalContainer,
	TabActions,
} from './styles';

const Category = ({ isEmpty, bIndex, actions, day, categories, category, cIndex }) => {
	const [replacerCategory, setReplacerCategory] = useState(undefined);
	const [showModals, setShowModals] = useState({ add: false, replace: false });

	return (
		<>
			<StyledCategory>
				<div>
					{category?.id ? (
						<span>
							<span style={{ fontWeight: 300, fontSize: 11, marginRight: 12 }}>{cIndex + 1}</span>
							<strong>{category?.name}</strong>
						</span>
					) : (
						<span>
							<span style={{ fontWeight: 300, fontSize: 11, marginRight: 12 }}>{cIndex + 1}</span>
							{isEmpty ? (
								<Typography.Text style={{ fontStyle: 'italic' }}>
									CATEGORIA NÃO SELECIONADA
								</Typography.Text>
							) : (
								<Typography.Text style={{ fontStyle: 'italic' }} delete>
									CATEGORIA REMOVIDA
								</Typography.Text>
							)}
						</span>
					)}
				</div>

				{category?.id ? (
					<>
						<div>
							<span>Músicas no banco: </span>
							<strong>{actions.calculateCategoryCount(category?.id)?.dbTracks}</strong>
						</div>
						<div>
							<span>Músicas no modelo: </span>

							{actions.calculateCategoryCount(category?.id)?.isDanger ? (
								<Tooltip title='A quantidade de músicas dessa categoria no modelo, excede a quantidade de músicas no banco de dados.'>
									<strong className='is-danger'>
										{actions.calculateCategoryCount(category?.id)?.scriptTracks}
										<Icon className='icon' type='exclamation-circle' />
									</strong>
								</Tooltip>
							) : (
								<strong>{actions.calculateCategoryCount(category?.id)?.scriptTracks}</strong>
							)}
						</div>
					</>
				) : (
					<>
						<div>-</div>
						<div>-</div>
					</>
				)}

				<div>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									onClick={() => {
										setShowModals({ ...showModals, replace: true });
									}}>
									<Icon type='retweet' /> {isEmpty ? 'Selecionar' : 'Substituir'}
								</Menu.Item>
								<Menu.Divider />
								<Menu.Item
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: `Remover categoria?`,
											icon: 'exclamation-circle',
											content: 'A categoria será removida do bloco, deseja continuar?',
											okText: 'Limpar bloco',
											onOk: () => {
												actions.handleRemoveCategory(day?.key, bIndex, cIndex);
											},
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<Icon type='delete' /> Remover
								</Menu.Item>
							</Menu>
						}>
						<Icon
							style={{
								cursor: 'pointer',
								fontSize: 18,
							}}
							type='more'
						/>
					</Dropdown>
				</div>
			</StyledCategory>

			<Modal
				visible={showModals?.replace}
				title={
					<>
						<Icon type='retweet' style={{ marginRight: 8 }} />{' '}
						{isEmpty ? 'Selecionar' : 'Substituir'} categoria
					</>
				}
				cancelButtonProps={{ icon: 'close-circle' }}
				okButtonProps={{ icon: 'retweet', disabled: !replacerCategory }}
				okText='Substituir'
				onOk={() => {
					actions.handleReplaceCategory(day?.key, bIndex, replacerCategory, cIndex);
					setShowModals({ ...showModals, replace: false });
				}}
				onCancel={() => setShowModals({ ...showModals, replace: false })}>
				<Typography.Paragraph>Selecione a categoria para substituir a atual</Typography.Paragraph>

				<Select
					showSearch
					placeholder='Selecione a nova categoria'
					size='large'
					style={{ width: '100%' }}
					onChange={(value) => setReplacerCategory(JSON.parse(value))}
					filterOption={(input, option) => {
						return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
					}}>
					{categories.map((categ) => (
						<Select.Option
							value={JSON.stringify({
								name: categ?.name,
								id: categ?._id,
							})}
							key={categ?._id}>
							{categ?.name}
						</Select.Option>
					))}
				</Select>
			</Modal>
		</>
	);
};

const Block = ({ block, bIndex, actions, day, categories }) => {
	const [category, setCategory] = useState(undefined);
	const [inserts, setInserts] = useState(1);
	const [showModals, setShowModals] = useState({ add: false, replace: false });

	return (
		<>
			<StyledBlock key={bIndex}>
				<header>
					<p>Bloco #{bIndex + 1}</p>

					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									onClick={() => {
										Modal.confirm({
											title: `Duplicar bloco #${bIndex + 1}?`,
											icon: 'exclamation-circle',
											content: (
												<>
													Será criado um novo bloco após esse, contendo as mesmas categorias.
													<br />
													Deseja realmente duplicar o bloco?
												</>
											),
											okText: 'Confirmar',
											onOk: () => actions.handleCloneBlock(day?.key, bIndex),
											okButtonProps: {
												icon: 'column-height',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<Icon type='column-height' /> Duplicar bloco
								</Menu.Item>
								<Menu.Item
									onClick={() => {
										Modal.confirm({
											title: `Limpar bloco #${bIndex + 1}?`,
											icon: 'exclamation-circle',
											content: 'Todas as categorias do bloco serão removidas, deseja continuar?',
											okText: 'Limpar bloco',
											onOk: () => actions.handleClearBlock(day?.key, bIndex),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<Icon type='close-circle' /> Limpar bloco
								</Menu.Item>
								<Menu.Divider />
								<Menu.Item
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: `Remover bloco #${bIndex + 1}?`,
											icon: 'exclamation-circle',
											content:
												'O bloco e todas as categorias presentes nele serão removidos, deseja continuar?',
											okText: 'Remover bloco',
											onOk: () => actions.handleRemoveBlock(day?.key, bIndex),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<Icon type='delete' /> Remover bloco
								</Menu.Item>
							</Menu>
						}>
						<Icon
							style={{
								cursor: 'pointer',
								fontSize: 22,
								color: '#FFF',
							}}
							type='more'
						/>
					</Dropdown>
				</header>

				<Categories>
					{block.map((category, cIndex) => (
						<Category
							isEmpty={category === null}
							key={cIndex}
							category={category}
							categories={categories}
							actions={actions}
							bIndex={bIndex}
							cIndex={cIndex}
							day={day}
						/>
					))}

					<NewCategory>
						<Button
							icon='plus'
							type='dashed'
							style={{ width: '100%' }}
							onClick={() => setShowModals({ ...showModals, add: true })}>
							Inserir categorias
						</Button>
					</NewCategory>
				</Categories>
			</StyledBlock>

			<Modal
				visible={showModals?.add}
				title={
					<>
						<Icon type='plus-circle' style={{ marginRight: 8 }} /> Inserir categoria
					</>
				}
				cancelButtonProps={{ icon: 'close-circle' }}
				okButtonProps={{ icon: 'plus-circle', disabled: !category }}
				okText='Inserir'
				onOk={() => {
					actions.handleAddCategories(day?.key, bIndex, category, inserts);
					setShowModals({ ...showModals, add: false });
				}}
				onCancel={() => setShowModals({ ...showModals, add: false })}>
				<Typography.Paragraph>
					Selecione a nova categoria e a quantidade de vezes que deseja incerí-la no bloco #
					{bIndex + 1}
				</Typography.Paragraph>

				<Divider />

				<Slider
					min={1}
					max={10}
					valu={inserts}
					onChange={(value) => setInserts(value)}
					style={{ width: '100%' }}
					marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 }}
				/>

				<Select
					showSearch
					placeholder='Selecione a categoria'
					size='large'
					style={{ marginTop: 16, width: '100%' }}
					onChange={(value) => setCategory(JSON.parse(value))}
					filterOption={(input, option) => {
						return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
					}}>
					{categories.map((category) => (
						<Select.Option
							value={JSON.stringify({
								name: category?.name,
								id: category?._id,
							})}
							key={category?._id}>
							{category?.name}
						</Select.Option>
					))}
				</Select>
			</Modal>
		</>
	);
};

const PlaylistScriptTabs = ({ week, actions, categories, share }) => {
	const [showShareModal, setShowShareModal] = useState(null);
	const weekDays = [
		{ key: 'seg', title: 'Segunda-Feira' },
		{ key: 'ter', title: 'Terça-Feira' },
		{ key: 'qua', title: 'Quarta-Feira' },
		{ key: 'qui', title: 'Quinta-Feira' },
		{ key: 'sex', title: 'Sexta-Feira' },
		{ key: 'sab', title: 'Sábado' },
		{ key: 'dom', title: 'Domingo' },
	];

	return (
		<Tabs size='small'>
			{Object.values(week).map((day) => (
				<Tabs.TabPane
					key={day?.key}
					tab={
						<>
							<span style={{ textTransform: 'capitalize' }}>{day?.key}</span>
							<Tooltip
								title={
									day?.active
										? `Programa vai ao ar na ${day?.title}`
										: `Programa não vai ao ar na ${day?.title}`
								}
								mouseEnterDelay={0.3}>
								<Switch
									size='small'
									style={{ marginLeft: 8 }}
									checked={day?.active}
									onChange={(checked) => {
										actions.handleToggleDayActive(day?.key, checked);
									}}
								/>
							</Tooltip>
						</>
					}>
					{day?.active ? (
						<>
							<TabActions>
								<Button
									icon='block'
									onClick={() => {
										setShowShareModal(day?.key);
										share.setSharingDayScript({
											active: day?.active,
											blocks: day?.blocks,
										});
									}}>
									Compartilhar
								</Button>

								<Button
									type='danger'
									icon='delete'
									onClick={() => {
										return Modal.confirm({
											title: `Limpar grade?`,
											icon: 'exclamation-circle',
											content: (
												<>
													Todos os blocos e categorias do dia selecionado serão removidos.
													<br />
													Deseja continuar?
												</>
											),
											okText: 'Limpar',
											onOk: () => actions.handleClearDay(day?.key),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									Limpar
								</Button>
							</TabActions>

							<Divider />

							<Blocks>
								{day.blocks.map((block, bIndex) => (
									<Block
										key={bIndex}
										block={block}
										bIndex={bIndex}
										actions={actions}
										day={day}
										categories={categories}
									/>
								))}

								<NewBlock>
									<button onClick={() => actions.handleAddBlock(day?.key)}>
										<Icon type='plus-circle' />
										<p>Novo Bloco</p>
										<span>Clique para adicionar um novo bloco</span>
									</button>
								</NewBlock>
							</Blocks>
						</>
					) : (
						<InactiveDay>
							<Icon type='close-circle' />
							<p>Não vai ao ar {day?.title}</p>
							<span>Para adicionar blocos, ative a chave ao lado do nome do dia</span>
						</InactiveDay>
					)}

					<Modal
						width={430}
						title={
							<>
								<Icon type='block' style={{ marginRight: 8 }} /> Compartilhar grade de {day?.title}
							</>
						}
						visible={showShareModal === day?.key}
						okText='Confirmar'
						okButtonProps={{ icon: 'check-circle' }}
						cancelButtonProps={{ icon: 'close-circle' }}
						onOk={() => {
							actions.handleShareDay();
							share.setSelectedDaysToShare([]);
							setShowShareModal(null);
						}}
						onCancel={() => {
							share.setSelectedDaysToShare([]);
							setShowShareModal(null);
						}}>
						<Typography.Text>
							Ao confirmar, os dias selecionados passarão a ter as grades idênticas a grade de{' '}
							{day?.title}.
						</Typography.Text>

						<Divider />

						<ShareModalContainer>
							{weekDays
								.filter(({ key }) => key !== day?.key)
								.map(({ key, title }) => (
									<Checkbox
										key={key}
										checked={share?.selectedDaysToShare.includes(key)}
										onChange={({ target: { checked } }) => {
											if (checked) {
												share.setSelectedDaysToShare([...share?.selectedDaysToShare, key]);
											} else {
												share.setSelectedDaysToShare(
													share?.selectedDaysToShare.filter((k) => {
														return k !== key;
													})
												);
											}
										}}>
										Compartilhar com {title}
									</Checkbox>
								))}
						</ShareModalContainer>
					</Modal>
				</Tabs.TabPane>
			))}
		</Tabs>
	);
};

export default PlaylistScriptTabs;
