import React from 'react';
import { Typography, Divider, Alert } from 'antd';

import Meta from '../../../components/Meta';
import HelpButtons from '../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../assets/images/tutorials/casters/img-01.png';
import img02 from '../../../assets/images/tutorials/casters/img-02.png';

const { Title, Paragraph } = Typography;

const Casters = () => (
	<Container>
		<Meta title='Ajuda - Locutores' />

		<Title>Locutores</Title>

		<Paragraph>
			Nessa página você irá conhecer todos os locutores cadastrados na plataforma. Aqui você será
			capaz somente de conhecê-los melhor lendo suas biografias, vendo quais dispositivos utilizam
			para as gravações, e claro, ouvindo suas demonstrações de voz.
		</Paragraph>

		<Paragraph>
			Para acessar, navegue até <i>Locutores</i> no menu lateral:
		</Paragraph>

		<ImgContainer>
			<img src={img01} alt='Locutores' />
		</ImgContainer>

		<Paragraph>
			Para acessar os detalhes, clique em <i>Detalhes</i> na parte direita:
		</Paragraph>

		<ImgContainer>
			<img src={img02} alt='Locutores' />
		</ImgContainer>

		<Alert
			showIcon
			type='warning'
			description='Logo mais iremos aprender como contratar um locutor para que ele grave seus conteúdos.'
		/>

		<HelpButtons
			prev={{
				label: 'Narrações Rede',
				to: '/help/main-voices',
			}}
			next={{ label: 'Playlists', to: '/help/programs/playlists' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 05/02/2024</Paragraph>
	</Container>
);

export default Casters;
