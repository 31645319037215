import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const TutorialsNavigation = () => {
	const { pathname } = useLocation();

	return (
		<Menu mode='inline' style={{ border: 'none' }} selectedKeys={[pathname.replace('/help/', '')]}>
			<Menu.SubMenu key='programs' title='Programas'>
				<Menu.Item key='programs/creating-a-program'>
					<Link to='/help/programs/creating-a-program'>Criando um Programa</Link>
				</Menu.Item>
				<Menu.Item key='programs/programs-management'>
					<Link to='/help/programs/programs-management'>Gerenciamento de Programas</Link>
				</Menu.Item>

				<Menu.SubMenu title='Detalhes do Programa'>
					<Menu.Item key='programs/program-details/own'>
						<Link to='/help/programs/program-details/own'>Edite/Exclua</Link>
					</Menu.Item>
					<Menu.Item key='programs/program-details/more-details'>
						<Link to='/help/programs/program-details/more-details'>
							Conhecendo melhor a TalkPlay
						</Link>
					</Menu.Item>
					<Menu.Item key='programs/program-details/shared'>
						<Link to='/help/programs/program-details/shared'>Compartilhado Seu Programa</Link>
					</Menu.Item>
				</Menu.SubMenu>
			</Menu.SubMenu>
			<Menu.SubMenu title='Módulo Plástica'>
				<Menu.Item key='signatures'>
				<Link to='/help/signatures'>Vinhetas de Assinaturas Rádio</Link>
				</Menu.Item>
				<Menu.Item key='soundtracksradio'>
				<Link to='/help/soundtracksradio'>Vinhetas de Programas</Link>
			</Menu.Item>
			<Menu.Item key='soundtracks'>
				<Link to='/help/soundtracks'>Trilhas Sonoras</Link>
			</Menu.Item>
			<Menu.Item key='signaturesmusicas'>
					<Link to='/help/signaturesmusicas-scripts'>Carimbo Musical</Link>
				</Menu.Item>
			</Menu.SubMenu>
			<Menu.SubMenu title='Módulo Musical'>
				<Menu.Item key='bancomusical'>
					<Link to='/help/bancomusical'>Músicas TalkPlay</Link>
				</Menu.Item>
				<Menu.Item key='bancomusical/bancomusical-scripts'>
					<Link to='/help/bancomusical/bancomusical-scripts'>Suas Músicas</Link>
				</Menu.Item>
				<Menu.Item key='playlists'>
					<Link to='/help/playlists'>Conhecendo as Playlists</Link>
				</Menu.Item>
				<Menu.Item key='playlists/playlists-scripts'>
					<Link to='/help/playlists/playlists-scripts'>Criando Modelo</Link>
				</Menu.Item>
			</Menu.SubMenu>
			<Menu.SubMenu title='Módulo Apresentador'>
			<Menu.Item key='voices-ia'>
					<Link to='/help/voices-ia'>Configurando Sua IA</Link>
				</Menu.Item>
				<Menu.Item key='vozia/vozia-scripts'>
					<Link to='/help/vozia/vozia-scripts'>Controlado As Falas da IA</Link>
				</Menu.Item>
				<Menu.Item key='voziamusic/voziaiamusic-scripts'>
					<Link to='/help/voziaiamusic/voziamusic-scripts'>Apresentando Músicas/Artistas</Link>
				</Menu.Item>
				<Menu.Item key='main-voices'>
					<Link to='/help/main-voices'>Locutor Local</Link>
				</Menu.Item>
				<Menu.Item key='automatiza-voices'>
					<Link to='/help/automatiza-voices'>Automatizando Seu Locutor</Link>
				</Menu.Item>
				<Menu.Item key='casters'>
					<Link to='/help/casters'>Contratando Locutores</Link>
				</Menu.Item>
			</Menu.SubMenu>
			<Menu.SubMenu title='Módulo Artístico'>
				<Menu.Item key='moduloartistico'>
					<Link to='/help/moduloartistico/wats'>Whats APP</Link>
				</Menu.Item>
				<Menu.Item key='moduloartistico/noticiasloc-scripts'>
					<Link to='/help/moduloartistico/noticiasrede-scripts'>Notícias Locais</Link>
				</Menu.Item>
				<Menu.Item key='moduloartistico/noticiasglobal-scripts'>
					<Link to='/help/moduloartistico/noticiasglobal-scripts'>Noticias Global</Link>
				</Menu.Item>
				<Menu.Item key='moduloartistico/quadrohoroscopo-scripts'>
					<Link to='/help/moduloartistico/quadrohoroscopo-scripts'>Quadro Horóscopo</Link>
				</Menu.Item>
			</Menu.SubMenu>
			<Menu.SubMenu title='Módulo Comercial'>
				<Menu.Item key='modulocomercial'>
					<Link to='/help/modulocomercial/mcash'>Musica Cash</Link>
				</Menu.Item>
				<Menu.Item key='modulocomercial/tetemunhal-scripts'>
					<Link to='/help/modulocomercial/testemunhal-scripts'>Testemunhal</Link>
				</Menu.Item>
				<Menu.Item key='modulocomercial/patrocinio-scripts'>
					<Link to='/help/modulocomercial/patrocinio-scripts'>Patrocínio</Link>
				</Menu.Item>
				<Menu.Item key='modulocomercial/oferecimento-scripts'>
					<Link to='/help/modulocomercial/oferecimento-scripts'>Oferecimento</Link>
				</Menu.Item>
			</Menu.SubMenu>
			<Menu.Item key='gerendoprograma'>
				<Link to='/help/gerendoprograma'>Gerando o Programa</Link>
			</Menu.Item>
			<Menu.Item key='tksink'>
				<Link to='/help/tksynk'>Configurando o TKSynk</Link>
			</Menu.Item>
			<Menu.Item key='novosrecursos'>
				<Link to='/help/novosrecursos'>Adquirindo Novos Recursos </Link>
			</Menu.Item>
		</Menu>
	);
};

export default TutorialsNavigation;
