import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 350px auto;
	grid-template-rows: 60px auto;
	grid-template-areas:
		'HD HD'
		'MN DC';
`;

export const Header = styled.header`
	grid-area: HD;
	background: #fff !important;
	padding: 0px !important;
	border-bottom: 1px solid rgb(0 21 41 / 8%);
	display: flex;
	align-items: center;
	
	& > h2 {
		display: flex;
		align-items: center;
		margin: 0;
		gap: 8px;
		margin-left: 16px;
	}
`;

export const MenuContainer = styled.aside`
	grid-area: MN;
	background: #f3f5f6;
	padding: 0px !important;
	border-right: 1px solid rgb(0 21 41 / 8%);
	background: #FFF;
`;

export const Docs = styled.section`
	grid-area: DC;
	background: #fff;
	overflow: auto;
	padding: 24px 80px;
`;
