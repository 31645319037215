import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Table,
	Button,
	Icon,
	Modal,
	Tag,
	Select,
	message,
	Menu,
	Dropdown,
	Typography,
	Breadcrumb,
	Card,
	Avatar,
} from 'antd';

import Meta from '../../../../components/Meta';
import Form from '../../../../components/Form';
import Fallback from '../../../../components/Fallback';
import PageSizeHandler from '../../../../components/PageSizeHandle';
import PlayCell from '../../../../components/PlayCell';
import { PageHeader, CardContainer, TableHeader } from './styles';

import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';
import { FiPlusCircle, FiXCircle, FiTrash2 } from 'react-icons/fi';

import { usePlayer, useDownload } from '../../../../hooks';
import TrackPresentationsAPI from '../../../../services/sdks/trackPresentations';
import CastersApi from '../../../../services/sdks/caster';
import { userTypes } from '../../../../constants';

const ManageTrackPresentations = ({ user }) => {
	const player = usePlayer();
	const download = useDownload();

	const [fallback, setFallback] = useState({ initialData: true });
	const [presentations, setPresentations] = useState([]);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [selectedPresentations, setSelectedPresentations] = useState([]);
	const [casters, setCasters] = useState([]);
	const [filters, setFilters] = useState(() => ({
		type: null,
		casterId: user?.type === userTypes.CASTER ? user?.caster._id : null,
	}));

	const columns = [
		{
			title: 'Tipo do Elemento',
			key: 'play',
			render: (p) => (
				<PlayCell
					meta={{
						primary: (
							<Tag color={p.type === 'CALL' ? 'green' : 'blue'}>{parseType(p.type)} MUSICAL</Tag>
						),
						secondary: null,
					}}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: p.filename }),
							ref: p?._id,
							meta: {
								name: `${parseType(p.type)} músical`,
								artist: null,
							},
						});
					}}
					onPause={player.resume}
					isPlaying={player?.ref === p?._id && player?.isPlaying}
				/>
			),
		},
		user?.type === userTypes.TALK
			? {
					title: 'Locutor',
					key: 'caster',
					render: (record) => (
						<>
							<Avatar
								size='small'
								style={{ background: 'var(--primary)', marginRight: 8 }}
								src={resolveFileSrc({
									fileName: record.casterId?.profilePic,
								})}>
								{record.casterId?.name[0] || ''} {record.casterId?.surname[0] || ''}
							</Avatar>
							{record.casterId?.name} {record.casterId?.surname}
						</>
					),
			  }
			: {},
		{
			title: 'Músicas',
			key: 'tracks',
			render: (presentation) => (
				<div>
					{presentation.tracks.map((t) => (
						<Tag
							key={t._id}
							closable={presentation.tracks.length > 1}
							onClose={(e) => {
								e.preventDefault();
								Modal.confirm({
									title: `Realmente deseja desassociar essa música dessa apresentação musical?`,
									icon: 'exclamation-circle',
									content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
									onOk: () => handleRemoveTrack(presentation._id, t._id),
									okButtonProps: { type: 'danger' },
									okText: (
										<>
											<FiTrash2 /> Remover
										</>
									),
									cancelText: (
										<>
											<FiXCircle /> Cancelar
										</>
									),
								});
							}}>
							{t.artist} - {t.name}
						</Tag>
					))}
				</div>
			),
		},

		{
			title: 'Ações',
			align: 'center',
			key: 'options',
			render: (presentation) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									key='play'
									onClick={() => {
										player.start({
											src: resolveFileSrc({ fileName: presentation.filename }),
											ref: presentation?._id,
											meta: {
												name: `${parseType(presentation.type)} MUSICAL`,
												artist: null,
											},
										});
									}}>
									<Icon type='play-circle' /> Reproduzir
								</Menu.Item>

								<Menu.Item
									key='play'
									onClick={() => {
										download({
											filename: presentation?.filename,
											name: `${parseType(presentation.type)} MUSICAL`,
										});
									}}>
									<Icon type='download' /> Baixar
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item
									key='delete'
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: `Excluir ${parseType(presentation.type)} músical?`,
											icon: 'exclamation-circle',
											content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											onOk: () => handleDeletePresentation(presentation._id),
											okButtonProps: { type: 'danger' },
											okText: (
												<>
													<FiTrash2 /> Excluir
												</>
											),
											cancelText: (
												<>
													<FiXCircle /> Cancelar
												</>
											),
										});
									}}>
									<Icon type='delete' /> Excluir
								</Menu.Item>
							</Menu>
						}>
						<Icon style={{ cursor: 'pointer', fontSize: 20 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const handleRemoveTrack = useCallback(async (trackPresentationId, trackId) => {
		try {
			await TrackPresentationsAPI.removeTrack(trackPresentationId, trackId);
			setPresentations((prev) =>
				prev.map((p) => {
					return p._id === trackPresentationId
						? { ...p, tracks: p.tracks.filter(({ _id }) => _id !== trackId) }
						: p;
				})
			);

			message.success('Programa desassociado com sucesso');
		} catch (error) {
			message.error('Houve um erro, tente novamente');
		}
	}, []);

	const handleDeletePresentation = useCallback(async (trackPresentationId) => {
		try {
			await TrackPresentationsAPI.delete(trackPresentationId);
			setPresentations((prev) => prev.filter((p) => p._id !== trackPresentationId));

			message.success('Apresentação removida com sucesso');
		} catch (error) {
			message.error('Houve um erro, tente novamente');
		}
	}, []);

	const parseType = useCallback((type) => {
		return type === 'CALL' ? 'CHAMADA' : 'SAÍDA';
	}, []);

	const getQuery = useCallback(() => {
		const { current, pageSize } = pagination;
		let query = `&page=${current - 1}&limit=${pageSize}`;

		Object.keys(filters).forEach((key) => {
			const value = filters[key];

			if (value) {
				if (Array.isArray(value)) {
					value.forEach((v) => (query = `${query}&${key}=${v}`));
				} else {
					query = `${query}&${key}=${value}`;
				}
			}
		});

		return query;
	}, [filters, pagination]);

	const handleDeleteMany = useCallback(async () => {
		try {
			const ids = selectedPresentations.map((st) => st._id);
			await TrackPresentationsAPI.deleteMany(ids);
			setSelectedPresentations([]);
			setPresentations((prev) => prev.filter((item) => !ids.includes(item._id)));
			message.success('Apresentações excluídas com sucesso');
		} catch (error) {
			message.error('Erro ao excluir as apresentações musicais');
		}
	}, [selectedPresentations]);

	useEffect(() => {
		const fetchPresentations = async () => {
			setFallback((prev) => ({ ...prev, fetchingPresentations: true }));

			try {
				const {
					data: { records, total },
				} = await TrackPresentationsAPI.index({ query: getQuery() });

				setPresentations(records);
				setPagination((prev) => ({ ...prev, total }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as apresentações');
			}

			setFallback((prev) => ({ ...prev, fetchingPresentations: false }));
		};

		fetchPresentations();
	}, [filters, user, pagination.current, pagination.pageSize]); // eslint-disable-line

	useEffect(() => {
		const fetchCasters = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingCasters: true }));

				const {
					data: { casters },
				} = await CastersApi.index();

				setCasters(casters);
			} catch (error) {
				message.error('Erro ao buscar os locutores');
			}

			setFallback((prev) => ({ ...prev, fetchingCasters: false }));
		};

		if (user?.type === userTypes.TALK) {
			fetchCasters();
		} else {
			setFallback((prev) => ({ ...prev, fetchingCasters: false }));
		}
	}, [user]);

	useEffect(() => {
		/** Reseta a página atual para 1 sempre que os filtros mudal */
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters]);

	if (fallback?.fetchingCasters) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Apresentações Musicais' />

			<PageHeader>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[{ breadcrumbName: 'INÍCIO' }, { breadcrumbName: 'APRESENTAÇÕES MUSICAIS' }]}
				/>

				<header>
					<Typography.Title level={2}>Apresentações Musicais</Typography.Title>

					<Link to='/commom/track-presentations/create'>
						<Button type='primary'>
							<FiPlusCircle /> Criar Apresentações
						</Button>
					</Link>
				</header>
			</PageHeader>

			<CardContainer>
				<Card className='_card'>
					<Form.Container layout='30% 30% auto'>
						<Form.Item label='Filtrar por tipo'>
							<Select value={filters?.type} onChange={(type) => setFilters({ ...filters, type })}>
								<Select.Option value={null}>TODOS</Select.Option>
								<Select.Option value='CALL'>CHAMADAS</Select.Option>
								<Select.Option value='POST'>SAÍDAS</Select.Option>
							</Select>
						</Form.Item>
						{user?.type === userTypes.TALK && (
							<Form.Item label='Filtrar por locutor'>
								<Select
									showSearch
									value={filters?.casterId}
									placeholder='Selecione um locutor'
									onChange={(casterId) => setFilters({ ...filters, casterId })}
									filterOption={(input, { props: { _search } }) => {
										return _search.match(new RegExp(input, 'i'));
									}}>
									<Select.Option value={null} _search='TODOS'>
										TODOS
									</Select.Option>
									{casters.map((caster) => (
										<Select.Option
											_search={`${caster.name} ${caster.surname}`}
											value={caster._id}
											key={caster._id}>
											<Avatar
												size='small'
												style={{ background: 'var(--primary)', marginRight: 8 }}
												src={resolveFileSrc({
													fileName: caster?.profilePic,
												})}>
												{caster?.name[0] || ''} {caster?.surname[0] || ''}
											</Avatar>
											{caster?.name} {caster?.surname}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						)}
					</Form.Container>

					<TableHeader>
						<div className='actions'>
							<span>
								Quantidade: <strong>{pagination?.total}</strong>
							</span>
							<div>
								<Button
									size='small'
									disabled={!selectedPresentations.length}
									type='danger'
									onClick={() => {
										Modal.confirm({
											title: 'Deletar offs selecionadas?',
											type: 'danger',
											content:
												'Todos os offs selecionados serão excluídos e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											okText: 'Deletar',
											onOk: handleDeleteMany,
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<FiTrash2 /> Deletar selecionados
									{selectedPresentations.length !== 0 && `(${selectedPresentations.length})`}
								</Button>
							</div>
						</div>

						<PageSizeHandler pagination={pagination} setPagination={setPagination} />
					</TableHeader>

					<Table
						rowKey='_id'
						size='middle'
						columns={columns}
						dataSource={presentations}
						style={{ border: 'none' }}
						loading={fallback?.fetchingPresentations}
						pagination={{
							...pagination,
							size: 'large',
							onChange: (current) => setPagination({ ...pagination, current }),
						}}
						rowSelection={{
							onChange: (_, selectedRows) => setSelectedPresentations(selectedRows),
						}}
					/>
				</Card>
			</CardContainer>
		</>
	);
};

export default connect(({ user }) => ({ user }))(ManageTrackPresentations);
