import React, { useState, useEffect, useCallback } from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import {
	PageHeader,
	Input,
	Button,
	Card,
	Icon,
	Collapse,
	List,
	Tabs,
	Empty,
	Typography,
	Modal,
	Checkbox,
	message,
	Table,
} from 'antd';

import Meta from '../../../components/Meta';
import Fallback from '../../../components/Fallback';
import {
	CollapseTitleContainer,
	Container,
	SearchContainer,
	ShargingWrapper,
	UserContainer,
	highlightStyle,
} from './styles';

import SharingsAPI from '../../../services/sdks/share';
import ProgramsAPI from '../../../services/sdks/programs';
import NetworksAPI from '../../../services/sdks/networks';

const breadcrumb = {
	routes: [{ breadcrumbName: 'Início' }, { breadcrumbName: 'Minha Rede' }],
	style: { marginBottom: 12 },
};

const Network = ({ user }) => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [users, setUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [programs, setPrograms] = useState([]);
	const [filteredPrograms, setFilteredPrograms] = useState([]);
	const [groups, setGroups] = useState([]);
	const [filteredGroup, setFilteredGroup] = useState([]);
	const [selectedSharings, setSelectedSharings] = useState([]);
	const [modals, setModals] = useState({ editSharings: false });
	const [selectedUser, setSelectedUser] = useState(null);
	const [userSharings, setUserSharings] = useState({ tableData: [], static: [] });
	const [updatedUserSharings, setUpdatedUserSharings] = useState([]);

	const [searchs, setSearchs] = useState({ user: '', program: '', group: '' });

	const columns = [
		{
			title: 'Usuário',
			key: 'radioName',
			render: (user) => (
				<Typography.Text style={{ display: 'flex', flexDirection: 'column' }}>
					<span>
						<strong>
							<Highlighter
								highlightStyle={highlightStyle}
								searchWords={[searchs.user]}
								textToHighlight={user?.radioName}
							/>
						</strong>
						,{' '}
						<span>
							<Highlighter
								highlightStyle={highlightStyle}
								searchWords={[searchs.user]}
								textToHighlight={user?.city}
							/>
							/{user?.state}
						</span>
					</span>

					<span style={{ fontStyle: 'italic', opacity: 0.95, fontSize: 14 }}>
						<Highlighter
							highlightStyle={highlightStyle}
							searchWords={[searchs.user]}
							textToHighlight={user?.email}
						/>
					</span>
				</Typography.Text>
			),
		},
		{
			title: 'Compartilhamentos',
			key: 'sharings',
			render: (user) => (
				<>
					<Typography.Text>
						<strong>{user?.sharings?.length}</strong> programas compartilhados
					</Typography.Text>
					<Button
						size='small'
						type='primary'
						style={{ marginLeft: 12 }}
						onClick={() => {
							setSelectedUser(user);
							setModals({ ...modals, editSharings: true });
							setUserSharings({
								tableData: user?.sharings.map(({ program }) => program?._id),
								static: user?.sharings.map(({ program }) => program?._id),
							});
						}}>
						Editar
					</Button>
				</>
			),
		},
	];

	const fetchPageData = useCallback(async () => {
		try {
			const {
				data: { network },
			} = await NetworksAPI.getOneByUserId(user?._id);

			const {
				data: { sharings },
			} = await SharingsAPI.index(`sharer=${user?._id}`);

			const {
				data: { programs },
			} = await ProgramsAPI.index(`userId=${user?._id}&isDeleted=false`);

			let groupedByProgram = [];
			let selectedSharings = {};

			sharings.forEach((sharing) => {
				const index = groupedByProgram.findIndex(({ program }) => {
					return program._id === sharing.program._id;
				});

				selectedSharings[sharing?.program?._id] = [];

				if (index !== -1) {
					const { program, ...rest } = sharing;
					groupedByProgram[index] = {
						...groupedByProgram[index],
						sharings: [...groupedByProgram[index].sharings, { ...rest }],
					};
				} else {
					const { program, ...rest } = sharing;
					groupedByProgram.push({ program, sharings: [{ ...rest }] });
				}
			});

			const users = network.users.map((user) => {
				return {
					...user,
					sharings: sharings.filter((s) => {
						return s?.user?._id === user?._id;
					}),
				};
			});

			setUsers(users);
			setFilteredUsers(users);
			setPrograms(programs);
			setFilteredPrograms(programs);
			setGroups(groupedByProgram);
			setFilteredGroup(groupedByProgram);
			setSelectedSharings(selectedSharings);
			setFallback((prev) => ({ ...prev, initialData: false }));
		} catch (error) {
			console.error(error);
			message.error('Não foi possível buscar os compartilhamentos');
		}
	}, [user]);

	const handleSearchProgramGroup = useCallback(
		(search) => {
			/** Filtra os agrupamentos de programas */

			if (!search) {
				return setFilteredGroup(groups);
			}

			return setFilteredGroup(
				groups.filter(({ program }) => {
					return program?.name?.match(new RegExp(search, 'i'));
				})
			);
		},
		[groups]
	);

	const handleSearchSharing = useCallback(
		(search, programId) => {
			/** Filtra os compartilhamentos em cada agrupamento */

			let { sharings: programSharings } = groups.find(({ program }) => {
				return program?._id === programId;
			});

			programSharings = programSharings.filter(({ user }) => {
				const radioNameMatch = user?.radioName?.match(new RegExp(search, 'i'));
				const emailMatch = user?.email?.match(new RegExp(search, 'i'));
				const cityMatch = user?.city?.match(new RegExp(search, 'i'));

				return radioNameMatch || emailMatch || cityMatch;
			});

			return setFilteredGroup((prev) =>
				prev.map((s) => {
					if (s.program._id === programId) {
						return { ...s, sharings: programSharings };
					}

					return s;
				})
			);
		},
		[groups]
	);

	const handleSearchProgram = useCallback(
		(search) => {
			/** Filtra os programas do modal */

			if (!search) {
				return setFilteredPrograms(programs);
			}

			return setFilteredPrograms(
				programs.filter(({ name }) => {
					return name?.match(new RegExp(search, 'i'));
				})
			);
		},
		[programs]
	);

	const handleSearchUser = useCallback(
		(search) => {
			/** Filtra usuários na tabela de usuários */

			if (!search) {
				return setFilteredUsers(users);
			}

			return setFilteredUsers(
				users.filter((user) => {
					const radioNameMatch = user?.radioName?.match(new RegExp(search, 'i'));
					const emailMatch = user?.email?.match(new RegExp(search, 'i'));
					const cityMatch = user?.city?.match(new RegExp(search, 'i'));

					return radioNameMatch || emailMatch || cityMatch;
				})
			);
		},
		[users]
	);

	const handleCheck = useCallback((checked, sharingId, programId) => {
		if (checked) {
			return setSelectedSharings((prev) => ({
				...prev,
				[programId]: [...prev[programId], sharingId],
			}));
		}

		return setSelectedSharings((prev) => ({
			...prev,
			[programId]: prev[programId].filter((s) => s !== sharingId),
		}));
	}, []);

	const handleDelete = useCallback(async (sharingId, programId) => {
		try {
			await SharingsAPI.destroy.byId(sharingId);

			setGroups((prev) =>
				prev.map(({ sharings, program }) => {
					if (program?._id === programId) {
						return { program, sharings: sharings.filter((s) => s?._id !== sharingId) };
					}

					return { program, sharings };
				})
			);

			setFilteredGroup((prev) =>
				prev.map(({ sharings, program }) => {
					if (program?._id === programId) {
						return { program, sharings: sharings.filter((s) => s?._id !== sharingId) };
					}

					return { program, sharings };
				})
			);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao remover o compartilhamento');
		}
	}, []);

	const handleMultiDelete = useCallback(
		async (programId) => {
			for (let index = 0; index < selectedSharings[programId]?.length; index++) {
				const sharingId = selectedSharings[programId][index];
				await handleDelete(sharingId, programId);
			}
		},
		[selectedSharings, handleDelete]
	);

	const handleInsertUpdatedUserSharing = useCallback(
		(checked, programId) => {
			let action = null;

			if (checked) {
				if (!userSharings.static.includes(programId)) {
					action = 'store';
				}
			} else {
				if (userSharings.static.includes(programId)) {
					action = 'destroy';
				}
			}

			if (userSharings.tableData.includes(programId)) {
				setUserSharings((prev) => ({
					...prev,
					tableData: prev.tableData.filter((sId) => sId !== programId),
				}));
			} else {
				setUserSharings((prev) => ({
					...prev,
					tableData: [...prev.tableData, programId],
				}));
			}

			const _updatedSharings = [...updatedUserSharings];
			const updatedSharing = { programId, action };

			const updatedIndex = updatedUserSharings.map(({ programId }) => programId).indexOf(programId);

			if (!action) {
				if (updatedIndex !== -1) {
					_updatedSharings.splice(updatedIndex, 1);
				}

				return setUpdatedUserSharings(_updatedSharings);
			}

			if (updatedIndex !== -1) {
				_updatedSharings.splice(updatedIndex, 1, updatedSharing);
			} else {
				_updatedSharings.push(updatedSharing);
			}

			return setUpdatedUserSharings(_updatedSharings);
		},
		[userSharings, updatedUserSharings]
	);

	const handleUpdateUserSharings = useCallback(async () => {
		try {
			setFallback((prev) => ({ ...prev, updatingSharings: true }));

			for (let index = 0; index < updatedUserSharings.length; index++) {
				const { action, programId } = updatedUserSharings[index];
				const payload = { user: selectedUser, program: programId };

				if (action === 'store') {
					await SharingsAPI.post(payload);
				} else {
					await SharingsAPI.destroy.byProgramAndUser(payload);
				}
			}

			await fetchPageData();

			return message.success('Os compartilhamentos foram editados');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao editar os compartilhamentos!');
		} finally {
			setFallback((prev) => ({ ...prev, updatingSharings: false }));
			setModals((prev) => ({ ...prev, editSharings: false }));
			setUpdatedUserSharings([]);
			setUserSharings({ tableData: [], static: [] });
			setSelectedUser(null);
		}
	}, [selectedUser, updatedUserSharings, fetchPageData]);

	useEffect(() => {
		fetchPageData();
	}, [fetchPageData]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Minha rede' />

			<PageHeader title='Minha Rede' breadcrumb={breadcrumb}>
				<Typography.Text>Esses são os programas que foram compartilhados por você</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<Tabs size='large'>
						<Tabs.TabPane
							key='users'
							tab={
								<>
									<Icon type='user' />
									Agrupados por usuários
								</>
							}>
							<SearchContainer>
								<Input.Search
									size='large'
									style={{ width: 300 }}
									placeholder='Buscar usuário'
									onSearch={(search) => handleSearchUser(search)}
									onChange={({ target: { value } }) => {
										setSearchs((prev) => ({ ...prev, user: value }));
									}}
								/>
							</SearchContainer>

							<Table
								size='middle'
								rowKey='_id'
								columns={columns}
								style={{ border: 'none' }}
								dataSource={filteredUsers}
								pagination={{ size: 'large', hideOnSinglePage: true }}
							/>
						</Tabs.TabPane>

						<Tabs.TabPane
							key='programs'
							tab={
								<>
									<Icon type='play-square' />
									Agrupados por programas
								</>
							}>
							<SearchContainer>
								<Input.Search
									size='large'
									style={{ width: 300 }}
									onSearch={(search) => handleSearchProgramGroup(search)}
									placeholder='Buscar programa'
									onChange={({ target: { value } }) => {
										setSearchs((prev) => ({ ...prev, group: value }));
									}}
								/>
							</SearchContainer>

							{filteredGroup?.length ? (
								<Collapse>
									{filteredGroup.map(({ program, sharings }, index) => (
										<Collapse.Panel
											key={index}
											header={
												<CollapseTitleContainer>
													<div className='info'>
														<Highlighter
															highlightStyle={highlightStyle}
															searchWords={[searchs.group]}
															textToHighlight={program?.name}
														/>
													</div>
													<div className='info'>
														Compartilhado com <strong>{sharings?.length}</strong> usuários
													</div>
												</CollapseTitleContainer>
											}>
											<SearchContainer inner>
												<Input.Search
													placeholder='Pesquisar usuário'
													style={{ width: 300, marginRight: 12 }}
													onSearch={(search) => {
														handleSearchSharing(search, program?._id);
													}}
												/>

												<Button
													type='danger'
													onClick={() => {
														Modal.confirm({
															title: 'Remover compartilhamentos selecionados?',
															type: 'danger',
															content: (
																<>
																	Todos os compartilhamentos do programa{' '}
																	<strong>{program?.name}</strong> selecionados serão excluídos e
																	essa ação não poderá ser revertida, deseja continuar mesmo assim?
																</>
															),
															okText: 'Remover',
															onOk: () => handleMultiDelete(program?._id),
															okButtonProps: {
																icon: 'delete',
																type: 'danger',
															},
															cancelText: 'Cancelar',
															cancelButtonProps: {
																icon: 'close-circle',
															},
														});
													}}
													disabled={!selectedSharings[program?._id]?.length}>
													Remover selecionados
												</Button>
											</SearchContainer>
											<List
												size='small'
												dataSource={sharings}
												renderItem={(sharing) => {
													const _user = sharing?.user ? (
														<>
															<strong>{sharing.user?.radioName}</strong>,{' '}
															<span className='secondary'>
																{sharing.user?.city}/{sharing.user?.state}
															</span>
														</>
													) : (
														<span className='secondary'>Usuário excluído</span>
													);

													return (
														<List.Item key={sharing?._id}>
															<Checkbox
																style={{ marginRight: 16 }}
																checked={selectedSharings[program?._id].includes(sharing?._id)}
																onChange={({ target: { checked } }) => {
																	handleCheck(checked, sharing?._id, program?._id);
																}}
															/>

															<ShargingWrapper>
																<UserContainer>
																	<Typography.Text>
																		<Icon
																			type='share-alt'
																			style={{
																				marginRight: 12,
																			}}
																		/>
																		{_user}
																	</Typography.Text>
																	<Typography.Text>{sharing?.user?.email}</Typography.Text>
																</UserContainer>

																<Typography.Text
																	copyable={{
																		text: sharing?._id,
																	}}>
																	<strong>ID: </strong>
																	{sharing?._id?.slice(0, 6)}...
																</Typography.Text>
															</ShargingWrapper>

															<Button
																type='danger'
																size='small'
																onClick={() => {
																	Modal.confirm({
																		title: 'Remover compartilhamento?',
																		type: 'danger',
																		content:
																			'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
																		okText: 'Remover',
																		onOk: () => handleDelete(sharing?._id, program?._id),
																		okButtonProps: {
																			icon: 'delete',
																			type: 'danger',
																		},
																		cancelText: 'Cancelar',
																		cancelButtonProps: {
																			icon: 'close-circle',
																		},
																	});
																}}>
																Remover
															</Button>
														</List.Item>
													);
												}}
											/>
										</Collapse.Panel>
									))}
								</Collapse>
							) : (
								<Empty description='Você ainda não compartilhou nenhum conteúdo' />
							)}
						</Tabs.TabPane>
					</Tabs>
				</Card>
			</Container>

			<Modal
				width={480}
				closable={!fallback?.updatingSharings}
				visible={modals?.editSharings}
				onCancel={() => {
					setModals({ ...modals, editSharings: false });
					setUpdatedUserSharings([]);
					setUserSharings({ tableData: [], static: [] });
					setSelectedUser(null);
				}}
				title={
					<>
						<Icon type='share-alt' style={{ marginRight: 8 }} />
						Editar compartilhamentos <strong>({user?.radioName})</strong>
					</>
				}
				footer={
					<Button
						icon='check-circle'
						size='large'
						type='primary'
						onClick={handleUpdateUserSharings}
						loading={fallback?.updatingSharings}
						style={{ width: '100%' }}>
						Salvar alterações
					</Button>
				}>
				<SearchContainer>
					<Input.Search
						size='large'
						placeholder='Pesquisar programa'
						style={{ width: '100%' }}
						onSearch={(search) => handleSearchProgram(search)}
						onChange={({ target: { value } }) => {
							setSearchs((prev) => ({ ...prev, program: value }));
						}}
					/>
				</SearchContainer>

				<Table
					rowKey='_id'
					size='small'
					style={{ border: 'none' }}
					pagination={{
						size: 'large',
						pageSize: Infinity,
						hideOnSinglePage: true,
					}}
					dataSource={filteredPrograms}
					rowSelection={{
						onSelectAll: null,
						selectedRowKeys: userSharings.tableData,
						onSelect: (program, selected) => {
							handleInsertUpdatedUserSharing(selected, program?._id);
						},
					}}
					columns={[
						{
							title: 'Programa',
							dataIndex: 'name',
							render: (programName) => (
								<Highlighter
									highlightStyle={highlightStyle}
									searchWords={[searchs.program]}
									textToHighlight={programName}
								/>
							),
						},
					]}
				/>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(Network);
