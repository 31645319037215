import React, { useCallback } from 'react';
import { Container } from './styles';
import { BiLoaderAlt } from 'react-icons/bi';

const Button = ({ variant, content, onClick, style, type, disabled, loading }) => {
	const getStyle = useCallback(() => {
		switch (variant) {
			case 'blue':
				return { bg: '#102B9C', color: '#FFF' };
			case 'bluemed':
				return { bg: '#3742FA', color: '#FFF' };	
			case 'red':
				return { bg: '#C13935', color: '#FFF' };
			case 'yellow':
				return { bg: '#E7A62C', color: '#FFF' };
			case 'white':
				return { bg: '#FFF', color: '#0D1317' };
			case 'yellow2':
				return { bg: '#E7A62C', color: '#070600' };
			default:
				return { bg: '#070600', color: '#0D1317' };
		}
	}, [variant]);

	const { bg, color } = getStyle();

	const handleClick = useCallback(() => {
		if (disabled || loading) {
			return;
		}

		if (typeof onClick === 'function') {
			onClick();
		}
	}, [onClick, disabled, loading]);

	return (
		<Container
			type={type}
			disabled={disabled || loading}
			onClick={() => handleClick()}
			style={{
				...(style ?? {}),
				backgroundColor: bg,
				color,
			}}
		>
			{loading && <BiLoaderAlt color='#FFF' size={25} />}
			{!loading && content}
		</Container>
	);
};

export default Button;
