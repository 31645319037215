import styled from 'styled-components';

export const Container = styled.div`
	padding: 16px;

	& > .ant-card {
		margin-bottom: 16px;
		width: 100%;
	}

	.ant-collapse-borderless > .ant-collapse-item {
		border: none !important;
		background: transparent !important;
	}
`;

export const FadesList = styled.ul`
	width: 100%;
	margin: 0;
`;

export const FadeRow = styled.li`
	width: 100%;
	list-style: none;
	display: flex;
	flex-direction: column;
	padding: 12px;
	border: 1px solid #dedede;
	border-radius: var(--border-radius-base);
	margin-bottom: 16px;
`;

export const FadeRowHeader = styled.header`
	display: flex;
	flex-direction: column;
	width: 100%;

	& > div {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`;

export const CurvesContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 0 24px;

	& > div {
		display: flex;
		flex-direction: column;

		& > span {
			font-size: 13px;
			margin-bottom: 6px;
			color: rgba(0, 0, 0, 0.65);
		}
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 16px;
`;

export const CurveImg = styled.img`
	width: ${({ size }) => size};
`;

export const ThresholdContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 12px;
	padding-right: 32px;

	& > div.slider-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		margin-right: 48px;
	}
`;

export const ThresholdDemo = styled.div`
	width: 300px;
	background: #b50cfb20;
	border-top: 4px solid var(--primary);
	border-bottom: 4px solid var(--primary);
	position: relative;
	padding: 2px 0;
	border-radius: 6px;

	& > img {
		width: 100%;

		object-fit: cover;
	}

	&:after {
		content: '0dB';
		position: absolute;
		top: 0;
		right: -28px;
		font-size: 14px;
		font-weight: 500;
		color: var(--primary);
		transform: translateY(-55%);
	}

	&:before {
		content: '0dB';
		position: absolute;
		bottom: 0;
		right: -28px;
		font-size: 14px;
		font-weight: 500;
		color: var(--primary);
		transform: translateY(55%);
	}
`;

export const Threshold = styled.div`
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	height: ${({ threshold }) => {
		threshold = Math.abs(threshold);
		threshold = (threshold * 100) / 60;

		return `${100 - threshold}%`;
	}};
	background: #b50cfb59;
`;
