import api from '../api';

const BASE_URL = '/api/v2/interviews';

const InterviewsAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	show: ({ interviewId }) => api.get(`${BASE_URL}/${interviewId}`),
	store: ({ payload, onUploadProgress }) => api.post(BASE_URL, payload, { onUploadProgress }),
	update: ({ interviewId, payload }) => api.put(`${BASE_URL}/${interviewId}`, payload),
	destroy: ({ interviewId }) => api.delete(`${BASE_URL}/${interviewId}`),
};

export default InterviewsAPI;
