import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.label`
	display: flex;
	flex-direction: column;

	& small {
		color: var(--lp-red);
		font-size: 12px;
		margin-top: 2px;
		font-weight: 600;
	}
`;

export const Label = styled.span`
	color: #000;
	font-size: 12px;
	font-weight: 700;
	margin-bottom: 5px;
`;

export const Input = styled.input`
	width: 100%;
	border: 1px solid var(--lp-gray);
	height: 48px;
	padding: 0 16px;
	border-radius: var(--border-radius-base);
	font-size: 15px;
	outline: none;
	cursor: pointer;
	transition: 300ms;

	&::placeholder {
		color: var(--lp-gray);
		font-size: 15px;
	}

	${({ variant, error }) => {
		if (error) {
			return css`
				border-color: var(--lp-red) !important;
				color: var(--lp-red) !important;

				&::placeholder {
					color: var(--lp-red) !important;
				}

				&:focus {
					box-shadow: 0px 0px 1px 3px #c139353b !important;
				}
			`;
		}

		if (variant === 'blue') {
			return css`
				&:focus {
					border-color: var(--lp-blue);
					box-shadow: 0px 0px 1px 3px #102c9c45;
				}
			`;
		}

		if (variant === 'yellow') {
			return css`
				&:focus {
					border-color: var(--lp-yellow);
					box-shadow: 0px 0px 1px 3px #e7a62c45;
				}
			`;
		}

		if (variant === 'red') {
			return css`
				&:focus {
					border-color: var(--lp-red);
					box-shadow: 0px 0px 1px 3px #c139353b;
				}
			`;
		}
	}}
`;

export const MaskedInput = styled(InputMask)`
	width: 100%;
	border: 1px solid var(--lp-gray);
	height: 48px;
	padding: 0 16px;
	border-radius: var(--border-radius-base);
	font-size: 15px;
	outline: none;
	cursor: pointer;
	transition: 300ms;

	&::placeholder {
		color: var(--lp-gray);
		font-size: 15px;
	}

	${({ variant, error }) => {
		if (error) {
			return css`
				border-color: var(--lp-red) !important;
				color: var(--lp-red) !important;

				&::placeholder {
					color: var(--lp-red) !important;
				}

				&:focus {
					box-shadow: 0px 0px 1px 3px #c139353b !important;
				}
			`;
		}

		if (variant === 'blue') {
			return css`
				&:focus {
					border-color: var(--lp-blue);
					box-shadow: 0px 0px 1px 3px #102c9c45;
				}
			`;
		}

		if (variant === 'yellow') {
			return css`
				&:focus {
					border-color: var(--lp-yellow);
					box-shadow: 0px 0px 1px 3px #9a9c1045;
				}
			`;
		}

		if (variant === 'red') {
			return css`
				&:focus {
					border-color: var(--lp-red);
					box-shadow: 0px 0px 1px 3px #c139353b;
				}
			`;
		}
	}}
`;
