import React, { memo, useMemo } from 'react';
import { differenceInDays } from 'date-fns';
import CountPut from 'react-countup';

import { Container, Card } from './styles';
import { IoIosRadio, IoIosMicrophone, IoIosMusicalNote } from 'react-icons/io';

const UsersCounter = memo(() => (
	<Card>
		<p>
			<IoIosRadio className='icon' />

			<CountPut
				delay={0.5}
				duration={1.5}
				end={1762}
				formattingFn={(v) => {
					const l = 4 - String(v).length;

					return `${new Array(l).fill('0').join('').replace(/,/g, '')}${v}`;
				}}
				easingFn={(_, t, b, c, d) => {
					if ((t /= d / 2) < 1) return (c / 2) * t * t + b;

					return (-c / 2) * (--t * (t - 2) - 1) + b;
				}}
			/>
		</p>

		<span>usuários já estão utilizando o TalkPlay</span>
	</Card>
));

const TracksCounter = memo(() => (
	<Card>
		<p>
			<IoIosMusicalNote className='icon' />
			<CountPut delay={0.3} duration={1.2} end={20} formattingFn={(v) => `${v}K`} />
		</p>

		<span>
			+ de
			<CountPut
				delay={0.3}
				duration={1.2}
				end={20}
				formattingFn={(v) => (v <= 9 ? ` 0${v.toFixed(3)} ` : ` ${v.toFixed(3)} `)}
			/>
			músicas disponíveis para você
		</span>
	</Card>
));

const EpisodesCounter = memo(() => {
	const epsCounter = useMemo(() => {
		const start = new Date(2020, 1, 1);
		const end = new Date();
		const days = Math.abs(differenceInDays(start, end));
		const estimatedEpsPerDay = 340;
		return days * estimatedEpsPerDay;
	}, []);

	return (
		<Card>
			<p>
				<IoIosMicrophone className='icon' />
				<CountPut
					duration={epsCounter ? 2 : 10}
					end={epsCounter || 111111}
					formattingFn={(v) => {
						const vL = String(v).length;
						const l = isNaN(vL) ? 5 - 4 : 5 - vL >= 0 ? 5 - vL : 0;

						return `${new Array(l).fill('0').join('').replace(/,/g, '')}${v}`;
					}}
				/>
			</p>

			<span>episódios já foram produzidos aqui</span>
		</Card>
	);
});

const LoginCards = () => (
	<Container>
		<UsersCounter />
		<TracksCounter />
		<EpisodesCounter />
	</Container>
);

export { UsersCounter, TracksCounter, EpisodesCounter };
export default memo(LoginCards);
