import React, { useCallback, useEffect, useState } from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import { message } from 'antd';

import GenerationLockApi from '../../services/sdks/generationLock';
import { Container, InnerText, Indicator } from './styles';

const GenerationStatus = () => {
	const [status, setStatus] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const toggleStatus = useCallback(async () => {
		if (status === null) {
			return;
		}

		setIsLoading(true);

		try {
			await GenerationLockApi.toggleStatus();
			setStatus((status) => (status === 'ALL' ? 'TALK_ONLY' : 'ALL'));
		} catch (error) {
			console.error(error);
			message.error(`Houve um erro ao ${status === 'ALL' ? 'bloquear' : 'liberar'} as gerações`);
		}

		setIsLoading(false);
	}, [status]);

	const getLockStatus = useCallback(async () => {
		setIsLoading(true);

		try {
			const {
				data: { lock },
			} = await GenerationLockApi.show();

			setStatus(lock.status);
			setIsLoading(false);
		} catch (error) {
			console.error(error);
			message.success('Erro ao buscar o status de geração');
		}
	}, []);

	useEffect(() => {
		getLockStatus();
	}, [getLockStatus]);

	return (
		<Container status={status} isDisabled={isLoading} isLoading={isLoading} onClick={toggleStatus}>
			<InnerText status={status}>{status === 'ALL' ? 'TODAS AS RÁDIOS' : 'SOMENTE TALK'}</InnerText>
			<Indicator status={status}>
				{isLoading && <BiLoaderAlt size={20} color='#FFF' className='__loading-indicator' />}
			</Indicator>
		</Container>
	);
};

export default GenerationStatus;
