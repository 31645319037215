import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	background: #000000;
	padding: 40px 10px;
	display: flex;
	align-items: center;
	justify-content: center;

	.img {
		margin-left: 40px;
		margin-bottom: -40px;
	}

	@media (max-width: 768px) {
		flex-direction: column;

		.img {
			margin-left: 0;
			margin-top: 10px;
		}
	}
`;

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;

	& > h3 {
		margin-bottom: 40px;
		font-weight: 700;
		color: #fff;
		font-size: 32px;
	}

	@media (max-width: 512px) {
		padding: 0 24px;
	}
`;

export const Form = styled.form`
	width: 377px;
	display: flex;
	flex-direction: column;

	@media (max-width: 512px) {
		width: 100%;
	}
`;

export const Image = styled.img``;
