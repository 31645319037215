import React, { useCallback, useEffect, useState } from 'react';
import path from 'path';
import JSZip from 'jszip';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import {
	Select,
	Button,
	Dropdown,
	Table,
	Menu,
	Divider,
	Modal,
	Icon,
	message,
	Typography,
	Breadcrumb,
	Avatar,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import Form from '../../../../components/Form';
import PlayCell from '../../../../components/PlayCell';
import FileExt from '../../../../components/FileExt';
import FileDuration from '../../../../components/FileDuration';
import PageSizeHandler from '../../../../components/PageSizeHandle';
import Progress from '../../../../components/Progress';
import { CasterInfos, Container, TableHeader } from './styles';

import TkVoiceConfigAPI from '../../../../services/sdks/tkVoiceConfig';
import TkVoicesAPI from '../../../../services/sdks/tkVoices';
import CastersApi from '../../../../services/sdks/caster';

import { useDownload, useFilesValidator, usePlayer } from '../../../../hooks';
import { FiDownload, FiPlusCircle, FiTrash2, FiUpload, FiXCircle } from 'react-icons/fi';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';
import FilesUploader from '../../../../components/FilesUploader';

const NEW_ELEMENT = {
	type: undefined,
	isLocal: false,
	files: [],
};

const ManageTkVoices = () => {
	const download = useDownload();
	const player = usePlayer();
	const { casterId } = useParams();

	const [fallback, setFallback] = useState({ initialData: true });
	const [visibleModals, setVisibleModals] = useState({});
	const [progress, setProgress] = useState(null);
	const [caster, setCaster] = useState(null);
	const [names, setNames] = useState([]);
	const [tkVoices, setTkVoices] = useState([]);
	const [selectedTkVoices, setSelectedTkVoices] = useState([]);
	const { hasValidationError } = useFilesValidator();
	const [isValidating, setIsValidating] = useState(false);
	const [newTkVoice, setNewTkVoice] = useState(NEW_ELEMENT);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [filters, setFilters] = useState({
		type: undefined,
	});

	const columns = [
		{
			title: 'Tipo do TkVoice',
			key: 'type',
			render: (tkVoice) => (
				<PlayCell
					meta={{ primary: parseName(tkVoice?.type) }}
					isPlaying={player?.ref === tkVoice?._id && player?.isPlaying}
					onPause={player.resume}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: tkVoice?.filename }),
							ref: tkVoice?._id,
							meta: { name: parseName(tkVoice?.type) },
						});
					}}
				/>
			),
		},
		{
			title: 'Duração',
			align: 'center',
			key: 'duration',
			render: (tkVoice) => <FileDuration src={resolveFileSrc({ fileName: tkVoice?.filename })} />,
		},
		{
			title: 'Formato',
			align: 'center',
			key: 'ext',
			render: (tkVoice) => <FileExt src={resolveFileSrc({ fileName: tkVoice?.filename })} />,
		},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					12
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'actions',
			render: (tkVoice) => (
				<Dropdown
					placement='bottomRight'
					overlay={
						<Menu>
							<Menu.Item
								onClick={() => {
									player.start({
										src: resolveFileSrc({ fileName: tkVoice?.filename }),
										ref: tkVoice?._id,
										meta: {
											name: parseName(tkVoice?.type),
										},
									});
								}}>
								<Icon type='play-circle' /> Reproduzir
							</Menu.Item>
							<Menu.Item onClick={() => handleDownloadTkVoice(tkVoice.filename, tkVoice.name)}>
								<Icon type='download' /> Baixar arquivo
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								className='ant-dropdown-menu-item-danger'
								onClick={() => {
									Modal.confirm({
										title: 'Deletar Tk-Voice?',
										icon: 'exclamation-circle',
										content: 'Essa ação não poderá ser revertida, deseja continuar?',
										onOk: () => handleDeleteTkVoice(tkVoice),
										okText: 'Deletar',
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<Icon type='delete' /> Deletar
							</Menu.Item>
						</Menu>
					}>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 12 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleDownloadTkVoice = useCallback(
		async (filename, name) => {
			try {
				const ext = path.extname(filename);
				await download({ filename, name: `${name}${ext}` });
			} catch (error) {
				console.error(error);
			}
		},
		[download]
	);

	const handleDeleteTkVoice = useCallback(async (tkVoice) => {
		try {
			await TkVoicesAPI.destroy({ tkVoiceId: tkVoice._id });
			return setTkVoices((prev) => prev.filter(({ _id }) => _id !== tkVoice._id));
		} catch (error) {
			console.error(error);
		}
	}, []);

	const parseName = useCallback(
		(type) => {
			return names.find((item) => item.key === type)?.label;
		},
		[names]
	);

	const handleDownloadAsZIP = useCallback(async () => {
		setFallback((prev) => ({ ...prev, multiDownload: true }));

		const zip = new JSZip();

		for (const [index, tkVoiceId] of Object.entries(selectedTkVoices)) {
			const tkVoice = tkVoices.find((tk) => tk._id === tkVoiceId);
			const name = parseName(tkVoice.type);
			const filePath = resolveFileSrc({ fileName: tkVoice?.filename });
			const blob = await axios.get(filePath, { responseType: 'blob' });
			const ext = path.extname(tkVoice?.filename);
			zip.file(`#${index} ${name}${ext}`, blob.data, { binary: true });
		}

		const zipContent = await zip.generateAsync({ type: 'blob' });

		saveAs(zipContent, 'VOZES IA');
		setFallback((prev) => ({ ...prev, multiDownload: false }));
	}, [parseName, selectedTkVoices, tkVoices]);

	const handleMultiDelete = useCallback(async () => {
		setFallback((prev) => ({ ...prev, multiDelete: true }));

		for (const tkVoiceId of selectedTkVoices) {
			const tkVoice = tkVoices.find((tk) => tk._id === tkVoiceId);
			await handleDeleteTkVoice(tkVoice);
		}

		setSelectedTkVoices([]);
		setFallback((prev) => ({ ...prev, multiDelete: false }));
	}, [selectedTkVoices, tkVoices, handleDeleteTkVoice]);

	const handleCreateTkVoices = useCallback(async () => {
		if (hasValidationError(newTkVoice.files)) {
			return message.warning(
				'Alguns dos arquivos selecionados estão corrompidos. Por favor, substitua-os por arquivos válidos.'
			);
		}

		const payload = new FormData();
		payload.append('caster', casterId);

		for (const key in newTkVoice) {
			if (key === 'files') {
				newTkVoice.files.forEach((file, i) => payload.append(`file-${i}`, file.data));
			} else {
				payload.append(key, newTkVoice[key]);
			}
		}

		await TkVoicesAPI.store({
			payload,
			onUploadProgress: ({ total, loaded }) => {
				const percentage = Math.floor((loaded * 100) / total);
				setProgress(percentage);
			},
		});

		setFallback((prev) => ({ ...prev, uploadingElements: false }));
		setTimeout(() => {
			setVisibleModals((prev) => ({ ...prev, addElements: false }));
			setProgress(null);
			setNewTkVoice(NEW_ELEMENT);
		}, 1500);
	}, [casterId, newTkVoice, hasValidationError]);

	useEffect(() => {
		const getCaster = async () => {
			const res = await CastersApi.show(casterId);
			return res.data.caster;
		};

		const getConfig = async () => {
			const res = await TkVoiceConfigAPI.show();
			return res.data.tkVoiceConfig.names.filter((item) => item.type === 'CASTER');
		};

		const fetchInitialData = async () => {
			try {
				const [caster, tkVoiceConfig] = await Promise.all([getCaster(), getConfig()]);

				setCaster(caster);
				setNames(tkVoiceConfig);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os usuários');
			}
		};

		fetchInitialData();
	}, [casterId]);

	useEffect(() => {
		const fetchTkVoices = async () => {
			setFallback((prev) => ({ ...prev, fetchElements: true }));

			let query = `page=${pagination?.current - 1}&limit=${
				pagination?.pageSize
			}&caster=${casterId}&isLocal=false`;

			if (filters.type) {
				query = `${query}&type=${filters.type}`;
			}

			try {
				const {
					data: { tkVoices, total },
				} = await TkVoicesAPI.index({ query });

				setTkVoices(tkVoices);
				setPagination((prev) => ({ ...prev, total }));
			} catch (error) {
				console.error(error);
			}

			setFallback((prev) => ({ ...prev, fetchElements: false }));
		};

		fetchTkVoices();
	}, [filters, pagination.current, pagination.pageSize]); //eslint-disable-line

	useEffect(() => {
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Banco de Vozes do Locutor' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'PAINEL ADMINISTRATIVO' },
						{ breadcrumbName: 'LOCUTORES' },
						{ breadcrumbName: 'IAs' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Banco de Vozes do Locutor</Typography.Title>
					<Button
						type='primary'
						onClick={() => setVisibleModals({ ...visibleModals, addElements: true })}>
						<FiPlusCircle /> Adicionar IAs
					</Button>
				</header>

				<CasterInfos>
					<Avatar
						size='large'
						style={{ background: 'var(--primary)' }}
						src={resolveFileSrc({ fileName: caster?.profilePic })}>
						{caster?.name[0] || '-'} {caster?.surname[0] || '-'}
					</Avatar>

					<div className='text-container'>
						<h3>
							{caster?.name} {caster?.surname}
						</h3>
						<span>{pagination.total} vozes cadastradas no banco</span>
					</div>
				</CasterInfos>

				<Form.Container layout='30% 30% 30%'>
					<Form.Item label='Filtrar por tipo'>
						<Select
							showSearch
							placeholder='Selecione um tipo'
							value={filters?.type}
							onChange={(type) => setFilters({ ...filters, type })}
							filterOption={(input, { props: { _search } }) => {
								const regex = new RegExp(input, 'i');
								return _search.match(regex);
							}}>
							{names.map(({ key, label }) => (
								<Select.Option value={key} key={key} _search={label}>
									{label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Form.Container>

				<Divider />

				<TableHeader>
					<div className='actions'>
						<span>
							Quantidade: <strong>{pagination?.total}</strong>
						</span>
						<div>
							<Button
								size='small'
								disabled={!selectedTkVoices.length}
								type='ghost'
								onClick={handleDownloadAsZIP}
								loading={fallback?.multiDownload}>
								<FiDownload /> Baixar selecionados{' '}
								{selectedTkVoices.length !== 0 && `(${selectedTkVoices.length})`}
							</Button>

							<Button
								size='small'
								disabled={!selectedTkVoices.length}
								type='danger'
								onClick={() => {
									Modal.confirm({
										title: 'Deletar elementos selecionados?',
										type: 'danger',
										content:
											'Todos os elementos selecionados serão excluídos e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
										okText: 'Deletar',
										onOk: handleMultiDelete,
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<FiTrash2 /> Deletar selecionados{' '}
								{selectedTkVoices.length !== 0 && `(${selectedTkVoices.length})`}
							</Button>
						</div>
					</div>

					<PageSizeHandler pagination={pagination} setPagination={setPagination} />
				</TableHeader>

				<Table
					rowKey='_id'
					size='middle'
					columns={columns}
					dataSource={tkVoices}
					loading={fallback?.fetchElements}
					style={{ border: 'none' }}
					pagination={{
						...pagination,
						size: 'large',
						onChange: (current) => setPagination({ ...pagination, current }),
					}}
					rowSelection={{
						selectedRowKeys: selectedTkVoices,
						onChange: (_, selectedRows) => setSelectedTkVoices(selectedRows.map(({ _id }) => _id)),
					}}
				/>
			</Container>

			<Modal
				destroyOnClose
				title={
					<>
						<Icon style={{ marginRight: 8 }} type='plus-circle' /> Novo elemento
					</>
				}
				visible={visibleModals?.addElements}
				okText={
					isValidating ? (
						'Validando Arquivos'
					) : (
						<>
							<FiUpload /> Fazer upload
						</>
					)
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				okButtonProps={{
					loading: fallback?.uploadingElements,
					disabled: !newTkVoice.type || !newTkVoice.files.length || isValidating,
				}}
				cancelButtonProps={{ disabled: fallback?.uploadingElements }}
				onCancel={() => {
					setNewTkVoice(NEW_ELEMENT);
					setVisibleModals({ ...visibleModals, addElements: false });
				}}
				onOk={handleCreateTkVoices}>
				<Form.Container>
					<Form.Item label='Tipo do elemento' style={{ marginBottom: 12, width: '100%' }}>
						<Select
							showSearch
							placeholder='Selecione um tipo'
							value={newTkVoice?.type}
							onChange={(value) => setNewTkVoice({ ...newTkVoice, type: value })}
							filterOption={(input, { props: { _search } }) => {
								const regex = new RegExp(input, 'i');
								return _search.match(regex);
							}}>
							{names.map(({ key, label }) => (
								<Select.Option value={key} key={key} _search={label}>
									{label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Form.Container>

				<FilesUploader
					multiple
					onChange={(files) => setNewTkVoice((prev) => ({ ...prev, files }))}
					onStartValidation={() => setIsValidating(true)}
					onEndValidation={() => setIsValidating(false)}
					validTypes={['.mp3', '.wav']}
				/>
			</Modal>

			<Progress
				progress={progress}
				succesTitle='Elementos enviados com sucesso'
				title={
					<Typography.Paragraph>Enviando elementos, por favor aguarde...</Typography.Paragraph>
				}
			/>
		</>
	);
};

export default ManageTkVoices;
