import React from 'react';
import { Typography, Divider } from 'antd';

import Meta from '../../../components/Meta';
import HelpButtons from '../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../assets/images/tutorials/signaturesprogram/img-01.png';
import img02 from '../../../assets/images/tutorials/signaturesprogram/img-02.png';
import img03 from '../../../assets/images/tutorials/signaturesprogram/img-03.png';
import img04 from '../../../assets/images/tutorials/signaturesprogram/img-04.png';
import img05 from '../../../assets/images/tutorials/signaturesprogram/img-05.png';

const { Title, Paragraph } = Typography;

const SignaturesProgram = () => (
	<Container>
		<Meta title='Ajuda - Vinhetas de Programas' />

		<Title>Vinhetas de Programas</Title>

		<Paragraph>
		As vinhetas de programa são componentes cruciais na TalkPlay, funcionando como identificadores sonoros únicos para cada programa em sua estação da rádio. Estes pequenos trechos sonoros são projetados para separar e destacar diferentes partes programas ou segmentos dentro da programação. Geralmente, incluem o nome do programa e, em alguns casos, o nome do apresentador, acompanhados de música ou efeitos sonoros característicos.
		</Paragraph>
		<Paragraph>
Para criar um programa na TalkPlay, é essencial que os usuários enviem suas vinhetas para a plataforma. Isso permite que estas vinhetas sejam integradas durante a configuração e produção do conteúdo, assegurando que cada programa tenha uma identidade sonora distinta e reconhecível. Esta integração de vinhetas é um passo vital na personalização e na construção da identidade da sua estação, ajudando a manter uma consistência auditiva e a fortalecer a marca da sua rádio no âmbito do público ouvinte.
		</Paragraph>

		<Paragraph>
		<strong>	Para acessar, basta ir no item <i>Add Vinheta</i>, no menu lateral.</strong>
		</Paragraph>

		<ImgContainer>
			<img src={img01} alt='Vinhetas de Programa' />
		</ImgContainer>

		<Paragraph>
			Uma vinheta de programa sempre será anexada a um programa, este por sua vez pode possuir quantas
			vinhetas desejar em cada parte.
		</Paragraph>

		<Paragraph>
		É importante destacar que, ao utilizar várias vinhetas, a TalkPlay possui a autonomia para decidir qual vinheta será utilizada em cada geração de programa. Isso significa que, se você deseja garantir que uma vinheta específica, como a "Abertura de Programa", seja usada consistentemente, você deve enviar apenas essa vinheta para a plataforma. Esta abordagem assegura que sua escolha seja respeitada em cada nova iteração do programa, mantendo uma consistência sonora que reflete o estilo e o tom que você deseja estabelecer para seus ouvintes.
		</Paragraph>
		<Paragraph>
<strong>Selecione o Programa o qual deseja adicionas as vinhetas.</strong>
</Paragraph>
		<ImgContainer>
			<img src={img02} alt='Vinhetas de Programa' />
		</ImgContainer>

		<Paragraph>
			Após isso, clique nos menus do módulo e envie as vinhetas desejadas para cada parte do programa.
		</Paragraph>

		<Divider />

		<Title level={3}>Selecionando e Enviado as Vinhetas</Title>

		<Paragraph>
			Por padrão, as vinhetas são inseridas nos módulos(<i>Introdução</i>,{' '}
			<i>Encerramento</i>, <i>Saída para Break</i>, <i>Volta de Break</i>, <i>Passagem</i>).

		</Paragraph>

		<Paragraph>
			Selecione o menu correspondente e selecione as vinhetas em sua máquina.{' '}
			<i>Preencha todos os menus desejados com as vinhetas antes de enviar para a TalkPlay.</i>.
		</Paragraph>

		<ImgContainer>
			<img src={img03} alt='TVinhetas de Programa' />
		</ImgContainer>

		<Paragraph>
			Depois de tudo selecionado para cada parte do programa clique em: <i> FAZER UPLOAD DAS VINHETAS</i>.
		</Paragraph>

		<ImgContainer>
			<img src={img04} alt='Vinhetas de Programa' />
		</ImgContainer>

		<Paragraph>Suas vinhetas serão enviadas e armazenadas em <strong>ELEMENTOS PADRÃO</strong> para serem usadas na criação do seu programa.</Paragraph>

		<ImgContainer>
			<img src={img05} alt='Vinhetas de Programa' />
		</ImgContainer>

		<Paragraph>
			Vale lembrar que os elementos que não possuem vinhetas não podem ser colocadas nos programas do modelo, se colocadas a TalkPlay não aceitará a geração do programa por entender que não existe como criar um programa sem a vinheta.
		</Paragraph>

	

		<Divider />

		<HelpButtons
			prev={{
				label: 'Assinaturas',
				to: '/help/signatures',
			}}
			next={{ label: 'Narrações Rede', to: '/help/main-voices' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default SignaturesProgram;
