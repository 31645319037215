import styled from 'styled-components';

export const Container = styled.div`
	background: #fff;
	padding: 16px 16px;

	.ant-tabs-tab {
		text-align: left !important;
		padding-left: 0px !important;
		margin-right: 12px !important;
	}

	& .ant-breadcrumb > span:last-child {
		font-weight: 600 !important;
		opacity: 0.8;
	}

	& .ant-breadcrumb-link {
		font-size: 12px;
	}

	& > header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 16px;

		& > div.title {
			display: flex;
			align-items: center;
			justify-content: space-between;

			& > * {
				margin-right: 8px;
			}

			& > h2 {
				color: rgba(0, 0, 0, 0.85) !important;
				margin: 0;
			}
		}

		& > div.buttons {
			display: flex;
			align-items: center;
			justify-content: space-between;

			& > .ant-btn:last-child {
				margin-left: 6px;
			}
		}
	}

	& > div.infos {
		display: flex;
		align-items: center;
		margin-bottom: 32px;

		& > div {
			margin-right: 24px;
			color: rgba(0, 0, 0, 0.8);
			display: flex;
			align-items: center;
			width: max-content;

			& > svg {
				font-size: 13px;
				margin-right: 6px;
				color: rgba(0, 0, 0, 0.25);
			}
		}
	}
`;
