export const newsTypes = [
	'GENERIC',
	'INTERNATIONAL',
	'HEALTH',
	'MONEY',
	'EDUCATION',
	'POLICY',
	'ECONOMY',
	'ELECTIONS',
	'FARMING',
	'ENTERTAINMENT',
	'TECHNOLOGY',
	'CINE',
	'SPORTS',
	'GOSSIP',
	'MUSIC',
	'PET',
	'CARS',
	'WOMAN',
	'HOROSCOPE',
];
