import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
	PageHeader,
	Table,
	Card,
	Icon,
	Modal,
	Typography,
	Input,
	Divider,
	message,
	Avatar,
	Button,
	Select,
	Tag,
	Dropdown,
	Menu,
	Form,
} from 'antd';

import Meta from '../../../../components/Meta';
import Player from '../../../../components/Player';
import Fallback from '../../../../components/Fallback';
import {
	Container,
	SearchContainer,
	AvatarCell,
	DetailsContainer,
	FilterContainer,
} from './styles';

import CastersAPI from '../../../../services/sdks/caster';

import { IoMdMicrophone } from 'react-icons/io';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';

const layout = { labelCol: { span: 6 }, wrapperCol: { span: 16 } };
const breadcrumb = {
	routes: [{ breadcrumbName: 'Início' }, { breadcrumbName: 'Locutores' }],
	style: { marginBottom: 12 },
};

const ManageCasters = () => {
	const history = useHistory();
	const [fallback, setFallback] = useState({ initialData: true });
	const [caster, setCaster] = useState(null);
	const [casters, setCasters] = useState([]);
	const [filteredCasters, setFilteredCasters] = useState([]);
	const [showModals, setShowModals] = useState({ details: false });
	const [filter, setFilter] = useState(null);
	const [pass, setPass] = useState({ password: '', passwordConfirmation: '' });

	const columns = [
		{
			title: 'ID',
			dataIndex: '_id',
			key: 'id',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					5
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Nome',
			key: 'name',
			render: (caster) => (
				<AvatarCell>
					<Avatar
						style={{ background: 'var(--primary)' }}
						src={resolveFileSrc({ fileName: caster?.profilePic, folder: ['profile_pics'] })}>
						{caster?.name[0] || '-'} {caster?.surname[0] || '-'}
					</Avatar>
					<Typography.Text
						className='name'
						onClick={() => {
							setCaster(caster);
							setShowModals({ ...showModals, details: true });
						}}>
						{caster?.name} {caster?.surname}
					</Typography.Text>
				</AvatarCell>
			),
		},
		{
			title: 'Microfone',
			dataIndex: 'mic',
			key: 'mic',
		},
		{
			title: 'Placa de Som',
			dataIndex: 'soundcard',
			key: 'soundcard',
		},
		{
			title: 'Status',
			dataIndex: 'active',
			key: 'active',
			render: (active) =>
				active ? <Tag color='green'>Ativo</Tag> : <Tag color='red'>Inativo</Tag>,
		},
		{
			title: 'Ações',
			key: 'actions',
			align: 'right',
			render: (caster) => (
				<Dropdown
					placement='bottomLeft'
					overlay={
						<Menu>
							<Menu.Item
								key='details'
								onClick={() => {
									setCaster(caster);
									setShowModals({ ...showModals, details: true });
								}}>
								<Icon type='eye' /> Mais detalhes
							</Menu.Item>

							<Menu.Item
								key='tkvoices'
								onClick={() => history.push(`/admin/casters/${caster?._id}/tk-voices`)}>
								<Icon type='audio' /> Banco de Vozes
							</Menu.Item>

							<Menu.Item
								key='play'
								onClick={() => {
									setCaster(caster);
									setShowModals({ ...showModals, audioDemo: true });
								}}>
								<Icon type='play-circle' /> Reproduzir demo
							</Menu.Item>

							<Menu.Item
								key='edit-pass'
								onClick={() => {
									setShowModals({ ...showModals, editPass: true });
									setCaster(caster);
								}}>
								<Icon type='lock' /> Alterar senha
							</Menu.Item>

							<Menu.Item key='toggle' onClick={() => handleToggleAccess(caster)}>
								<Icon type={caster.active ? 'stop' : 'check-circle'} />{' '}
								{caster.active ? 'Bloquear acesso' : 'Liberar acesso'}
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								key='delete'
								className='ant-dropdown-menu-item-danger'
								onClick={() => {
									Modal.confirm({
										title: 'Excluir locutor?',
										icon: 'exclamation-circle',
										content:
											'Essa ação não poderá ser revertida, realmente deseja excluir esse locutor?',
										okText: 'Excluir',
										onOk: () => handleDeleteCaster(caster?._id),
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<Icon type='delete' /> Excluir
							</Menu.Item>
						</Menu>
					}>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 12 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleSearch = useCallback(
		(search) => {
			if (!search) {
				return setFilteredCasters(casters);
			}

			setFilteredCasters(
				casters.filter((caster) => {
					const casterName = `${caster?.name} ${caster?.surname}`.toLocaleLowerCase();

					return casterName.includes(search.toLocaleLowerCase());
				})
			);
		},
		[casters]
	);

	const handleDeleteCaster = useCallback(async (casterId) => {
		try {
			await CastersAPI.destroy(casterId);

			setCasters((prev) => prev.filter((caster) => caster?._id !== casterId));
			setFilteredCasters((prev) => prev.filter((caster) => caster?._id !== casterId));

			return message.success('Locutor excluído com sucesso');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, []);

	const handleToggleAccess = useCallback(async (caster) => {
		try {
			const active = !caster?.active;

			await CastersAPI.modify(caster?._id, { active });

			setCasters((prev) => prev.map((c) => (c?._id === caster?._id ? { ...c, active } : c)));
			setFilteredCasters((prev) =>
				prev.map((c) => (c?._id === caster?._id ? { ...c, active } : c))
			);

			return message.success(`Acesso do locutor foi ${active ? 'liberado' : 'bloqueado'}`);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, []);

	const handleChangePassword = useCallback(async () => {
		try {
			const { password, passwordConfirmation } = pass;

			if (!password) return message.error('Informe a senha');
			if (!passwordConfirmation) return message.error('Confirme a senha');

			if (password !== passwordConfirmation) {
				return message.error('Senhas não são iguais');
			}

			setFallback((prev) => ({ ...prev, changingPass: true }));

			await CastersAPI.updatePassword(caster?._id, { password, passwordConfirmation });

			setFallback((prev) => ({ ...prev, changingPass: false }));
			setShowModals((prev) => ({ ...prev, editPass: false }));
			setPass({ password: '', passwordConfirmation: '' });

			return message.success(`Senha do locutor foi atualizada com sucesso`);
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, [caster, pass]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				setFallback((prev) => ({ ...prev, initialData: true }));

				const query = typeof filter === 'string' ? `active=${filter}` : '';
				const {
					data: { casters },
				} = await CastersAPI.index(query);

				setCasters(casters);
				setFilteredCasters(casters);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os locutores, tente novamente');
			}
		};

		fetchInitialData();
	}, [filter]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Locutores' />

			<PageHeader title='Locutores' breadcrumb={breadcrumb}>
				<Typography.Text>Esses são os locutores cadastrados no TalkPLay</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<SearchContainer>
						<Input.Search
							allowClear
							size='large'
							onSearch={handleSearch}
							style={{ width: '300px' }}
							placeholder='Buscar por nome ou email'
						/>
					</SearchContainer>

					<FilterContainer>
						<Select value={filter} onChange={(value) => setFilter(value)} style={{ width: 300 }}>
							<Select.Option value={null}>Exibir todos</Select.Option>
							<Select.Option value={'true'}>Somente Ativos</Select.Option>
							<Select.Option value={'false'}>Somente Inativos</Select.Option>
						</Select>
					</FilterContainer>

					<Table
						size='middle'
						rowKey='_id'
						columns={columns}
						dataSource={filteredCasters}
						pagination={{ size: 'large', pageSize: 10, hideOnSinglePage: true }}
						style={{ border: 'none' }}
					/>
				</Card>
			</Container>

			<Modal
				visible={showModals?.details}
				title={
					<>
						<Icon type='file-text' style={{ marginRight: 8 }} /> Detalhes
					</>
				}
				footer={null}
				width={600}
				onCancel={() => setShowModals((prev) => ({ ...prev, details: false }))}>
				<DetailsContainer>
					<div className='desc'>
						{caster && (
							<AvatarCell>
								<Avatar
									size='large'
									style={{ background: 'var(--primary)' }}
									src={resolveFileSrc({ fileName: caster?.profilePic })}>
									{caster?.name[0] || '-'} {caster?.surname[0] || '-'}
								</Avatar>
								<Typography.Text className='name'>
									{caster?.name} {caster?.surname}
								</Typography.Text>
							</AvatarCell>
						)}

						<Typography.Text style={{ marginTop: 16 }}>{caster?.bio}</Typography.Text>
					</div>

					<Divider />

					<Button
						type='ghost'
						icon='play-circle'
						size='large'
						style={{ width: '100%' }}
						onClick={() => {
							setShowModals({ ...showModals, audioDemo: true });
						}}>
						Reproduzir demo de voz
					</Button>

					<Divider />

					<ul>
						<li>
							<span className='label'>Email</span>
							<strong>{caster?.email}</strong>
						</li>

						<li>
							<span className='label'>Microfone</span>
							<strong>{caster?.mic}</strong>
						</li>
						<li>
							<span className='label'>Placa de Som</span>
							<strong>{caster?.soundcard}</strong>
						</li>
						<li>
							<span className='label'>Cidade</span>
							<strong>
								{caster?.city}/{caster?.state}
							</strong>
						</li>
						<li>
							<span className='label'>CEP</span>
							<strong>{caster?.zipCode}</strong>
						</li>
						<li>
							<span className='label'>Telefone</span>
							<strong>{caster?.phone || '-'}</strong>
						</li>
						<li style={{ border: 'none' }}>
							<span className='label'>CPF</span>
							<strong>{caster?.cpf || '-'}</strong>
						</li>
					</ul>
				</DetailsContainer>
			</Modal>

			<Modal
				destroyOnClose
				title='Audio demo'
				footer={null}
				visible={showModals.audioDemo}
				onCancel={() => setShowModals({ ...showModals, audioDemo: false })}>
				<Player
					title='Audio demo'
					src={resolveFileSrc({ fileName: caster?.profilePic })}
					icon={IoMdMicrophone}
				/>
			</Modal>

			<Modal
				title={
					<>
						<Icon type='lock' style={{ marginRight: 8 }} /> Alterar senha
					</>
				}
				width={600}
				visible={showModals.editPass}
				okText='Alterar senha'
				onOk={handleChangePassword}
				okButtonProps={{ loading: fallback?.changingPass, icon: 'check-circle' }}
				cancelButtonProps={{ disabled: fallback?.changingPass, icon: 'close-circle' }}
				onCancel={() => {
					setPass({ password: '', passwordConfirmation: '' });
					setShowModals({ ...showModals, editPass: false });
				}}>
				<Form {...layout}>
					<Form.Item label='Senha'>
						<Input.Password
							placeholder='Informe a nova senha'
							value={pass?.password}
							onChange={({ target: { value } }) => {
								setPass({ ...pass, password: value });
							}}
						/>
					</Form.Item>
					<Form.Item label='Confirmar senha'>
						<Input.Password
							placeholder='Confirme a nova senha'
							value={pass?.passwordConfirmation}
							onChange={({ target: { value } }) => {
								setPass({ ...pass, passwordConfirmation: value });
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default ManageCasters;
