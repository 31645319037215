import api from '../api';

const BASE_URL = '/api/v2/scripts';

const ScriptsAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	getScriptVersions: (scriptId) => api.get(`${BASE_URL}/${scriptId}/versions`),
	show: ({ scriptId }) => api.get(`${BASE_URL}/${scriptId}`),
	store: ({ payload }) => api.post(BASE_URL, payload),
	modify: ({ scriptId, payload, options }) =>
		api.patch(`${BASE_URL}/${scriptId}`, payload, options),
	destroy: ({ scriptId }) => api.delete(`${BASE_URL}/${scriptId}`),
	renameScript: ({ scriptId, name }) => api.patch(`${BASE_URL}/rename/${scriptId}`, { name }),
	recoverScript: (versionId) => api.patch(`${BASE_URL}/recover/${versionId}`),
	changeWeekDay: ({ scriptId, weekDay }) =>
		api.patch(`${BASE_URL}/week-day/${scriptId}`, { weekDay }),
	toggleMain: ({ scriptId, isMain }) =>
		api.patch(`${BASE_URL}/toggle-main/${scriptId}`, { isMain }),
	toggleVisibility: ({ scriptId }) => api.patch(`${BASE_URL}/toggle-visibility/${scriptId}`),
};

export default ScriptsAPI;
