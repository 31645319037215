import styled, { css } from 'styled-components';

export const Container = styled.div`
	padding: 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const SelectContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	& > * {
		width: 450px;
		max-width: 100%;
	}
`;

export const OptionContent = styled.span`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > span {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`;

export const ScriptConfigContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 0 80px;

	& > form,
	.ant-tabs {
		width: 100%;
	}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.7;
			pointer-events: none;
		`}
`;

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
