import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
	background-color: #000000;
	color: #cfcfcf;
	padding: 2rem 0;
`;

export const InnerContainer = styled.div`
	margin: 0 auto;
	max-width: 1200px;
	padding: 0 2rem;

	.content {
		display: flex;
		justify-content: space-between;
		align-items: center; /* Alinha verticalmente os itens no centro */
		gap: 2rem;
	}

	.text-content {
		flex: 1 1 500px;
	}
`;

export const Badge = styled.div`
	background-color: #17181e;
	color: #cfcfcf;
	padding: 0.5rem 1rem;
	border-radius: 0.25rem;
	font-size: 0.875rem;
	display: inline-block;
`;

export const Title = styled.h1`
	font-size: 2.5rem;
	font-weight: bold;
	margin-top: 1rem;
	color: #cfcfcf;
`;

export const Paragraph = styled.p`
	margin-top: 1rem;
	font-size: 1rem;
	color: #82828A;
`;

export const Description = styled.p`
	margin-top: 1rem;
	font-size: 1rem;
	color: #82828A;
`;

export const ButtonContainer = styled.div`
	margin-top: 1.5rem;
	display: flex;
	gap: 1rem;
`;

export const Button = styled.button`
	padding: 0.75rem 2.5rem;
	border-radius: 0.375rem;
	font-size: 1rem;
	font-weight: 700;
	cursor: pointer;
	transition: background-color 0.3s;

	&.primary {
		background-color:  #102B9C;
		color: white;
	}

	&.ghost {
		background-color: transparent;
		color: #102b9c;
		border: 1px solid #102b9c;
	}
`;

export const List = styled.ul`
	margin-top: 1.5rem;
	list-style-type: none;
	padding: 0;
`;

export const ListItem = styled.li`
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
	font-size: 1rem;
	color: #82828A;
`;

export const CheckIcon = (props) => (
	<svg
		{...props}
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		stroke='currentColor'
		strokeWidth='2'
		strokeLinecap='round'
		strokeLinejoin='round'>
		<path d='M20 6 9 17l-5-5' />
	</svg>
);

export const ImageContainer = styled.div`
	flex: 1 1 500px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 2rem; /* Adicione padding-top para ajustar a posição vertical */
`;

export const StyledImage = styled.img`
	max-width: 100%;
	height: auto;
	border-radius: 0.5rem;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;
