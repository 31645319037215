import styled from 'styled-components';

export const LinkContainer = styled.span`
	width: 100%;
	display: flex;
	align-items: center;

	& > svg {
		font-size: 18px;
		margin-right: 10px;
	}
`;
