import React, { useState, useCallback, useMemo } from 'react';
import { DndContext } from '@dnd-kit/core';

import { Button, message, Empty, Divider, Typography } from 'antd';

import { TabWrapper } from '../styles';
import { WeekGrid, ColumnContainer, ButtonContainer, AllDaysSetInfo } from './styles';
import NightlyGenerationSchedulesAPI from '../../../services/sdks/nightly-generation-schedules';

import { DragabbleDay } from './DraggableDay';
import { DroppableZone } from './DroppableZone';
import { FiCheckCircle, FiRefreshCcw, FiSave } from 'react-icons/fi';

const daysLabels = {
	0: 'Domingo',
	1: 'Segunda-Feira',
	2: 'Terça-Feira',
	3: 'Quarta-Feira',
	4: 'Quinta-Feira',
	5: 'Sexta-Feira',
	6: 'Sábado',
};

const defaultWeekSchedule = [
	{ day: '0', daySchedule: [] },
	{ day: '1', daySchedule: ['2'] },
	{ day: '2', daySchedule: ['3'] },
	{ day: '3', daySchedule: ['4'] },
	{ day: '4', daySchedule: ['5'] },
	{ day: '5', daySchedule: ['6', '0'] },
	{ day: '6', daySchedule: ['1'] },
];

const NightlyGenerationSchedule = ({ data, setData, program }) => {
	const [fallback, setFallback] = useState({ updating: false });
	const unsetDays = useMemo(() => {
		const allPossibleDays = Object.keys(daysLabels);

		if (!data) {
			return allPossibleDays;
		}

		return allPossibleDays.filter((day) => {
			return !data.weekSchedule.some((d) => d.daySchedule.includes(Number(day)));
		});
	}, [data]);

	const handleCreateSchedule = useCallback(async () => {
		setFallback((prev) => ({ ...prev, creatingSchedule: true }));

		try {
			const response = await NightlyGenerationSchedulesAPI.create({
				weekSchedule: defaultWeekSchedule,
				programId: program._id,
			});

			setData(response.data.nightlyGenerationSchedule);
			message.success('Cronograma criado. Faça a configuração dia a dia');
		} catch (error) {
			message.error('Houve um erro ao criar o cronograma de geração noturna');
		}

		setFallback((prev) => ({ ...prev, creatingSchedule: false }));
	}, [program._id, setData]);

	const handleResetSchedule = useCallback(() => {
		setData((prev) => ({ ...prev, weekSchedule: defaultWeekSchedule }));
		message.info(
			'O cronograma foi restaurado para o padrão. Clique em "Salvar Alterações" para atualizar seu cronograma.',
			5
		);
	}, [setData]);

	const handleUpdateSchedule = useCallback(async () => {
		setFallback((prev) => ({ ...prev, updatingSchedule: true }));

		try {
			const weekSchedule = data.weekSchedule;
			const response = await NightlyGenerationSchedulesAPI.update(data._id, { weekSchedule });

			setData(response.data.nightlyGenerationSchedule);
			message.success('Cronograma atualizado com sucesso');
		} catch (error) {
			message.error('Houve um erro ao atualizar o cronograma de geração noturna');
		}

		setFallback((prev) => ({ ...prev, updatingSchedule: false }));
	}, [data, setData]);

	const onDragEnd = useCallback(
		(event) => {
			if (!event.over) {
				return;
			}

			const weekSchedule = data.weekSchedule;
			const daySchedule = weekSchedule[event.over.id].daySchedule;
			const usedDayIndex = weekSchedule.findIndex((item) =>
				item.daySchedule.some((d) => String(d) === String(event.active.id))
			);

			if (daySchedule.includes(event.active.id)) {
				return;
			}

			if (usedDayIndex !== -1) {
				weekSchedule[usedDayIndex].daySchedule = weekSchedule[usedDayIndex].daySchedule.filter(
					(d) => String(d) !== String(event.active.id)
				);
			}

			daySchedule.push(Number(event.active.id));

			setData((prev) => ({
				...prev,
				weekSchedule,
			}));
		},
		[data, setData]
	);

	if (!data) {
		return (
			<>
				<TabWrapper>
					<Empty
						description='Esse programa ainda não possui um cronograma de geração configurado'
						children={
							<Button
								onClick={handleCreateSchedule}
								loading={fallback?.creatingSchedule}
								type='primary'>
								Criar Cronograma de Geração Noturna
							</Button>
						}
					/>
				</TabWrapper>
			</>
		);
	}

	return (
		<>
			<TabWrapper>
				<Typography.Text>
					Para personalizar seu cronograma semanal de geração, simplesmente arraste os dias da
					semana de acordo com suas necessidades de antecipação na criação automática de conteúdo.
					Por exemplo, para programas que envolvam notícias, é aconselhável configurar a geração com
					1 dia de antecedência, uma vez que as notícias não devem ser publicadas com mais de 24
					horas de antecedência.
				</Typography.Text>

				<Typography.Text style={{ marginTop: 12 }}>
					* O cronograma padrão foi desenvolvido pensando em atender da melhor forma possível os
					programas da TalkRadio que incluem segmentos de notícias.
				</Typography.Text>

				<DndContext onDragEnd={onDragEnd}>
					<Divider />

					{unsetDays.length ? (
						<WeekGrid>
							{unsetDays.map((day) => (
								<DragabbleDay key={day} id={day} label={daysLabels[day]} />
							))}
						</WeekGrid>
					) : (
						<AllDaysSetInfo>
							<FiCheckCircle style={{ marginRight: 8 }} />
							Todos os dias da semana foram configurados
						</AllDaysSetInfo>
					)}

					<Divider />

					<WeekGrid>
						{data.weekSchedule.map((item) => (
							<ColumnContainer key={item.day}>
								<header>{daysLabels[item.day]}</header>
								<DroppableZone
									id={item.day}
									data={item?.daySchedule}
									label={daysLabels[item.day]}
								/>
							</ColumnContainer>
						))}
					</WeekGrid>
				</DndContext>

				<ButtonContainer>
					<Button onClick={handleResetSchedule}>
						<FiRefreshCcw /> Restaurar Padrão
					</Button>

					<Button
						type='primary'
						onClick={handleUpdateSchedule}
						loading={fallback?.updatingSchedule}>
						<FiSave /> Salvar Alterações
					</Button>
				</ButtonContainer>
			</TabWrapper>
		</>
	);
};

export default NightlyGenerationSchedule;
