import env from '../../config/env';
import { createAPI } from '../api';

const api = createAPI({ baseURL: env.mainMachineBaseURL });
const BASE_URL = '/api/v2/casters';

const CastersApi = {
	index: (query = '') => api.get(`${BASE_URL}?${query}`),
	show: (casterId) => api.get(`${BASE_URL}/${casterId}`),
	modify: (casterId, payload) => api.patch(`${BASE_URL}/${casterId}`, payload),
	updatePassword: (casterId, payload) => api.patch(`${BASE_URL}/${casterId}/password`, payload),
	destroy: (casterId) => api.delete(`${BASE_URL}/${casterId}`),
};

export default CastersApi;
