import styled, { css } from 'styled-components';

export const Container = styled.ul`
	width: 100%;
`;

export const ElementContainer = styled.li`
	width: 100%;
	list-style: none;
	display: flex;
	align-items: center;
	margin-bottom: 4px;
	opacity: 0.4;

	& > button {
		margin-left: 4px;
	}

	${({ isNewBlock }) =>
		isNewBlock &&
		css`
			margin-top: 16px;
		`}

	&:first-child {
		margin-top: 0 !important;
	}

	${({ enabled }) =>
		enabled &&
		css`
			pointer-events: all;
			opacity: 1;
		`}
`;

export const ElementContent = styled.div`
	background: ${({ color }) => color};
	height: 36px;
	flex: 1;
	display: flex;
	align-items: center;
	box-shadow: 0px 0px 0px 1px
		${({ color }) => (!color || color.startsWith('#FFF') ? '#b7b7b7' : color)};
	border: 1px solid #fff;
	padding: 0 12px;
	border-radius: var(--border-radius-base);

	& > svg {
		font-size: 16px;
		margin-right: 8px;
		color: #fff;
		color: ${({ color }) => (!color || color.startsWith('#FFF') ? '#040404' : '#FFF')};
	}

	& > p {
		font-size: 13px;
		font-weight: 600;
		margin: 0;
		color: #fff;
		color: ${({ color }) => (!color || color.startsWith('#FFF') ? '#040404' : '#FFF')};
	}
`;
