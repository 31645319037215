import React from 'react';
import { Typography, Divider } from 'antd';
import { Link } from 'react-router-dom';

import Meta from '../../../../components/Meta';
import HelpButtons from '../../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../../assets/images/tutorials/playlists/details/img-01.png';

const { Title, Paragraph } = Typography;

const PlaylistsDetails = () => (
	<Container>
		<Meta title='Ajuda - PLaylists' />

		<Title>Playlists</Title>

		<Paragraph>
			Uma playlist representa o set de músicas que irá compor a grade de um programa, ou seja, é
			através dela que o sistema sabe exatamente quais músicas inserir nos seus episódios.
		</Paragraph>

		<Paragraph>
			O TalkPlay consegue gerar automaticamente playlists com base nas músicas presentes no{' '}
			<Link to='/help/tracks'>Banco Músical</Link>, sendo assim você também fica livre dessa
			responsabilidade, mas para isso é necessário que seja criado um <i>Modelo de playslis</i> para
			cada programa, irémos aprender como fazer tudo isso em seguida.
		</Paragraph>

		<Paragraph>Confira um exemplo de playlist já gerada:</Paragraph>

		<ImgContainer>
			<img src={img01} alt='Playlist' />
		</ImgContainer>

		<Divider />

		<HelpButtons
			prev={{ label: 'Banco Musical', to: '/help/bancomusical' }}
			next={{ label: 'Criando um modelo de playlist', to: '/help/playlists/playlist-scripts' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default PlaylistsDetails;
