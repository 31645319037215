import Api from '../api';
const baseUrl = '/api/v2/password-recovery';

const ForgotApi = {
	verify: (token) => Api.get(`${baseUrl}/${token}`),
	request: (email) => Api.post(baseUrl, { email }),
	redefine: (token, password) => Api.put(baseUrl, { password }, { headers: { token } }),
};

export default ForgotApi;
