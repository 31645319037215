import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 16px;
	background-color: #000000;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

	@media (min-width: 768px) {
		padding: 12px 24px;
	}
`;

export const Nav = styled.nav`
	display: none;
	color: #cfcfcf;
	@media (min-width: 768px) {
		display: flex;
		gap: 24px;
	}
`;

export const StyledLink = styled(Link)`
	font-size: 14px;
	font-weight: 500;
	color: #cfcfcf;
	&:hover {
		color: #cfcfcf;
	}
`;

export const DemoButton = styled.a`
	display: inline-flex;
	align-items: center;
`;

export const Logo = styled.img`
	height: 30px;
`;

export const Dropdowns = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
`;

export const Dropdown = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #cfcfcf;
	span {
		margin-right: 8px;
	}

	svg {
		transition: transform 0.3s;
	}

	&:hover svg {
		transform: rotate(180deg);
	}
`;

export const Links = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

export const MobileMenuToggle = styled.div`
	display: block;
	cursor: pointer;

	@media (min-width: 768px) {
		display: none;
	}
`;
