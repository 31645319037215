import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Login from '../pages/Authentication/Login';
import ForgotPassword from '../pages/Authentication/ForgotPassword';
import RedefinePassword from '../pages/Authentication/RedefinePassword';
import LandPage from '../pages/Landpage';
import _404 from '../pages/Exceptions/_404';

const AuthStack = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path='/' component={LandPage} />
			<Route exact path='/login' component={Login} />
			<Route exact path='/forgot-password' component={ForgotPassword} />
			<Route exact path='/redefine/:token' component={RedefinePassword} />

			<Route exact path={['/commom', '/commom/*', '/admin', '/admin/*']}>
				<Redirect to='/login' />
			</Route>

			<Route path='*' component={_404} />
		</Switch>
	</BrowserRouter>
);

export default AuthStack;
