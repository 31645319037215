import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {
	PageHeader,
	Descriptions,
	Card,
	Menu,
	Form,
	Input,
	Dropdown,
	Tag,
	Button,
	Icon,
	Modal,
	message,
	Typography,
	Divider,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import { Container } from './styles';

import UserAPI from '../../../../services/sdks/user';
import userTypesDescriptions from '../../../../constants/userTypesDescriptions';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Usuários' },
		{ breadcrumbName: 'Detalhes do usuário' },
	],
	style: { marginBottom: 12 },
};

const UserDetails = () => {
	const { userId } = useParams();
	const history = useHistory();

	const [user, setUser] = useState(null);
	const [fallback, setFallback] = useState('initial-data');
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [showChangePassword, setShowChangePassword] = useState({ show: false, userId: null });
	const [pInput, setPInput] = useState({ p: 'password', p2: 'password' });
	const [passwords, setPasswors] = useState({ password: '', password2: '' });

	const handleDeleteUser = useCallback(async () => {
		try {
			await UserAPI.destroy(userId);

			message.success('Usuário foi excluído com sucesso');
			setTimeout(() => history.push('/admin/users'), 1000);
		} catch (error) {
			console.error(error);
		}
	}, [userId, history]);

	const handleToggleUserAcces = useCallback(
		async (active) => {
			try {
				await UserAPI.toggleActive(userId);
				setUser((user) => ({ ...user, active }));
				message.success(active ? 'Acesso liberado!' : 'Acesso bloqueado!');
			} catch (error) {
				console.error(error);
			}
		},
		[userId]
	);

	const handleChangePassword = useCallback(async () => {
		try {
			if (!passwords.password) {
				return message.error('Informe a senha');
			}

			if (passwords.password !== passwords.password2) {
				return message.error('Senhas não são iguais');
			}

			setFallback('changing-password');

			await UserAPI.updatePassword(showChangePassword.userId, {
				password: passwords.password,
				passwordConfirmation: passwords.password2,
			});

			setFallback(null);
			setShowChangePassword({ show: false, userId: null });

			message.success('A senha do usuário foi alterada!');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, [passwords, showChangePassword]);

	const renderModuleTag = useCallback((module) => {
		return module ? <Tag color='green'>COM ACESSO</Tag> : <Tag color='red'>SEM ACESSO</Tag>;
	}, []);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { user },
				} = await UserAPI.getOne(userId);

				setUser(user);
				setFallback(null);
			} catch (error) {
				console.error(error);
			}
		};

		if (userId) {
			fetchInitialData();
		}
	}, [userId]);

	const userType = useMemo(() => {
		return user ? userTypesDescriptions.find((t) => t.value === user?.type).name : false;
	}, [user]);

	const resources = useMemo(() => {
		return user ? user?.resourcesId : null;
	}, [user]);

	if (fallback === 'initial-data') {
		return <Fallback title='Carregando...' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title={`Detalhes ${user?.name}`} />

			<PageHeader
				title={user ? `Detalhes do Usuário (${user?.name})` : 'Detalhes do Usuário'}
				breadcrumb={breadcrumb}
				extra={[
					<Link to={`/admin/users/${userId}/edit`} key='1'>
						<Button key='1' type='ghost' icon='edit'>
							Editar usuário
						</Button>
					</Link>,
					<Dropdown.Button
						key='2'
						style={{ marginLeft: 4 }}
						onClick={() => setDropdownVisible(true)}
						visible={dropdownVisible}
						onVisibleChange={(visible) => setDropdownVisible(visible)}
						overlay={
							<Menu>
								<Menu.Item
									key='1'
									onClick={() => {
										setShowChangePassword({ show: true, userId });
									}}>
									<Icon type='lock' />
									Alterar senha
								</Menu.Item>

								<Menu.Item
									key='2'
									color='red'
									onClick={() => {
										Modal.confirm({
											title: user?.active ? 'Desativar usuário?' : 'Ativar usuário?',
											icon: 'exclamation-circle',
											content: user?.active
												? 'O usuário não poderá acessar o sistema, deseja continuar mesmo assim?'
												: 'O usuário terá acesso ao sistema, deseja continuar mesmo assim?',
											okText: 'Confirmar',
											onOk: () => handleToggleUserAcces(!user?.active),
											okButtonProps: {
												icon: user?.active ? 'close-circle' : 'check-circle',
												type: user?.active ? 'danger' : 'primary',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<Icon type={user?.active ? 'close-circle' : 'check-circle'} />
									{user?.active ? 'Remover Acesso Temporariamente' : 'Liberar Acesso'}
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item
									key='3'
									onClick={() => {
										Modal.confirm({
											title: 'Deletar usuário?',
											type: 'danger',
											content: 'Essa ação não podera ser revertida, deseja continuar mesmo assim?',
											okText: 'Deletar',
											onOk: () => handleDeleteUser(),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: { icon: 'close-circle' },
										});
									}}>
									<Icon type='delete' />
									Deletar Conta Permanentemente
								</Menu.Item>
							</Menu>
						}>
						Ações
					</Dropdown.Button>,
				]}>
				<Descriptions size='middle' style={{ marginTop: 16 }}>
					<Descriptions.Item label='Nome Completo'>
						{`${user?.name} ${user?.surname}`}
					</Descriptions.Item>
					<Descriptions.Item label='Nome da Rádio'>{user?.radioName}</Descriptions.Item>
					<Descriptions.Item label='Cidade'>{user?.city}</Descriptions.Item>
					<Descriptions.Item label='Estado'>{user?.state}</Descriptions.Item>
					<Descriptions.Item label='CPF'>{user?.cpf || <i>Não informado</i>}</Descriptions.Item>
					<Descriptions.Item label='CNPJ'>{user?.cnpj || <i>Não informado</i>}</Descriptions.Item>
					<Descriptions.Item label='Dia de pagamento'>
						{user?.paymentDay || <i>Não cadastrado</i>}
					</Descriptions.Item>
					<Descriptions.Item label='Email'>{user?.email}</Descriptions.Item>
					<Descriptions.Item label='Telefone'>
						{user?.phone || <i>Não informado</i>}
					</Descriptions.Item>
				</Descriptions>
			</PageHeader>

			<Container>
				<Card
					title={
						<>
							<Icon type='file-text' /> Informações da Conta
						</>
					}>
					<Descriptions size='middle'>
						<Descriptions.Item label='Tipo da Conta'>{userType}</Descriptions.Item>
						<Descriptions.Item label='Acesso ao Sistema' span={2}>
							{user?.subscriptionId === 1 ? (
								<Tag color='red'>
									BLOQUEADO (<strong>INADIMPLÊNCIA NO CARTÃO</strong>)
								</Tag>
							) : user?.active ? (
								<Tag color='green'>ACESSO LIBERADO</Tag>
							) : (
								<Tag color='red'>
									BLOQUEADO (<strong>MANUALMENTE</strong>)
								</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Data de Registro'>
							{format(new Date(user?.createdAt), 'dd/MM/yyyy HH:mm')}
						</Descriptions.Item>
						<Descriptions.Item label='ID Admin'>
							{user?.intranetId || <i>Não informado</i>}
						</Descriptions.Item>
						<Descriptions.Item label='ID GerenciaNet'>
							{user?.subscriptionId || <i>Não informado</i>}
						</Descriptions.Item>
					</Descriptions>
				</Card>

				<Card
					title={
						<>
							<Icon type='play-circle' /> Permissões
						</>
					}>
					<Descriptions size='middle' style={{ marginTop: 16 }}>
						<Descriptions.Item label='Tipo de Assinatura'>
							{resources.contentGeneration.RADIO_SIGNATURE === 'MANDATORY' ? (
								<Tag color='red'>OBRIGATÓRIA</Tag>
							) : (
								<Tag color='green'>OPCIONAL</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Assinatura Musical'>
							{resources.contentGeneration.TRACK_SIGNATURE ? (
								<Tag color='green'>HABILITADA</Tag>
							) : (
								<Tag color='red'>BLOQUADA</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Geração Noturna'>
							{resources.contentGeneration.NOCTURNAL_GENERATION ? (
								<Tag color='green'>HABILITADA</Tag>
							) : (
								<Tag color='red'>BLOQUEADA</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Compartilhamento'>
							{resources.contentCreation.PROGRAMS_SHARING ? (
								<Tag color='green'>HABILITADO</Tag>
							) : (
								<Tag color='red'>BLOQUEADO</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item label='Masterização'>
							{resources.contentGeneration.MASTERIZATION === 'MANDATORY' ? (
								<Tag color='yellow'>OBRIGATORIA</Tag>
							) : resources.contentGeneration.MASTERIZATION === 'OPTIONAL' ? (
								<Tag color='green'>OPCIONAL</Tag>
							) : (
								<Tag color='red'>BLOQUEADO</Tag>
							)}
						</Descriptions.Item>

						<Descriptions.Item label='Limite de Programas'>
							{resources.contentCreation.PROGRAMS_LIMIT}
						</Descriptions.Item>
						<Descriptions.Item label='Limite de Blocos por Programa'>
							{resources.contentCreation.MAX_BLOCKS_PER_PROGRAM}
						</Descriptions.Item>
						<Descriptions.Item label='Limite de Músicas por Bloco'>
							{resources.contentCreation.MAX_MUSICS_PER_BLOCK}
						</Descriptions.Item>
						<Descriptions.Item label='Máximo de Gerações por Programa/Dia'>
							{resources.contentGeneration.MAX_GENERATIONS_PER_PROGRAM}
						</Descriptions.Item>
					</Descriptions>
				</Card>

				<Card
					title={
						<>
							<Icon type='global' /> Módulos
						</>
					}>
					<Typography.Title level={4}>Publicidades</Typography.Title>
					<Descriptions>
						<Descriptions.Item label='Patrocínios'>
							{renderModuleTag(resources.ads.SPONSOR)}
						</Descriptions.Item>
						<Descriptions.Item label='Oferecimentos'>
							{renderModuleTag(resources.ads.OFFERING)}
						</Descriptions.Item>
						<Descriptions.Item label='Músicas Cash'>
							{renderModuleTag(resources.ads.CASHTRACK)}
						</Descriptions.Item>
						<Descriptions.Item label='Testemunhais'>
							{renderModuleTag(resources.ads.TESTIMONIAL)}
						</Descriptions.Item>
						<Descriptions.Item label='Propaganda Curta'>
							{renderModuleTag(resources.ads.TRACK_AD)}
						</Descriptions.Item>
					</Descriptions>

					<Divider />
					<Typography.Title level={4}>Músicas</Typography.Title>
					<Descriptions>
						<Descriptions.Item label='Músicas Locais'>
							{renderModuleTag(resources.tracks.TRACKS_LOCAL)}
						</Descriptions.Item>
						<Descriptions.Item label='Músicas Globais'>
							{renderModuleTag(resources.tracks.TRACKS_GLOBAL)}
						</Descriptions.Item>
						<Descriptions.Item label='Download de Músicas'>
							{renderModuleTag(resources.tracks.TRACKS_DOWNLOAD)}
						</Descriptions.Item>
					</Descriptions>

					<Divider />
					<Typography.Title level={4}>Notícias</Typography.Title>
					<Descriptions>
						<Descriptions.Item label='Notícias Locais'>
							{renderModuleTag(resources.news.NEWS_LOCAL)}
						</Descriptions.Item>
						<Descriptions.Item label='Notícias Globais'>
							{renderModuleTag(resources.news.NEWS_GLOBAL)}
						</Descriptions.Item>
						<Descriptions.Item label='Compartilhamento de Notícias'>
							{renderModuleTag(resources.news.NEWS_SHARING)}
						</Descriptions.Item>
					</Descriptions>

					<Divider />
					<Typography.Title level={4}>Wpps</Typography.Title>
					<Descriptions>
						<Descriptions.Item label='Wpps Locais'>
							{renderModuleTag(resources.wpps.WPP_LOCAL)}
						</Descriptions.Item>
						<Descriptions.Item label='Wpps Globais'>
							{renderModuleTag(resources.wpps.WPP_GLOBAL)}
						</Descriptions.Item>
						<Descriptions.Item label='Compartilhamento de Wpps'>
							{renderModuleTag(resources.wpps.WPP_SHARING)}
						</Descriptions.Item>
					</Descriptions>

					<Divider />
					<Typography.Title level={4}>Entrevistas</Typography.Title>
					<Descriptions>
						<Descriptions.Item label='Entrevistas Locais'>
							{renderModuleTag(resources.interviews.INTERVIEW_LOCAL)}
						</Descriptions.Item>
						<Descriptions.Item label='Entrevistas Globais'>
							{renderModuleTag(resources.interviews.INTERVIEW_GLOBAL)}
						</Descriptions.Item>
						<Descriptions.Item label='Compartilhamento de Entrevistas'>
							{renderModuleTag(resources.interviews.INTERVIEW_SHARING)}
						</Descriptions.Item>
					</Descriptions>

					<Divider />
					<Typography.Title level={4}>Vinhetas</Typography.Title>
					<Descriptions>
						<Descriptions.Item label='Vinhetas da Rádio (Cronogramadas)'>
							{renderModuleTag(resources.vignettes.VIGNETTES_RADIO_SCHEDULED)}
						</Descriptions.Item>
						<Descriptions.Item label='Vinhetas da Rádio (Contínuas)'>
							{renderModuleTag(resources.vignettes.VIGNETTES_RADIO_CONTINUOUS)}
						</Descriptions.Item>
						<Descriptions.Item label='Vinhetas de Programa'>
							{renderModuleTag(resources.vignettes.VIGNETTES_PROGRAM)}
						</Descriptions.Item>
					</Descriptions>

					<Divider />
					<Typography.Title level={4}>Offs</Typography.Title>
					<Descriptions>
						<Descriptions.Item label='Offs Locais'>
							{renderModuleTag(resources.offs.OFFS_LOCAL)}
						</Descriptions.Item>
						<Descriptions.Item label='TK VOX'>
							{renderModuleTag(resources.offs.TK_VOX)}
						</Descriptions.Item>
						<Descriptions.Item label='TK PLAY'>
							{renderModuleTag(resources.offs.TK_PLAY)}
						</Descriptions.Item>
						<Descriptions.Item label='IA Local'>
							{renderModuleTag(resources.offs.IA_LOCAL)}
						</Descriptions.Item>
						<Descriptions.Item label='IA Global'>
							{renderModuleTag(resources.offs.IA_GLOBAL)}
						</Descriptions.Item>
					</Descriptions>
				</Card>
			</Container>

			<Modal
				title='Alterar senha do usuário'
				width={600}
				visible={showChangePassword.show}
				onCancel={() => setShowChangePassword({ show: false, userId: null })}
				okText='Alterar senha do usuário'
				onOk={handleChangePassword}
				okButtonProps={{ icon: 'save', loading: fallback === 'changing-password' }}
				cancelButtonProps={{
					icon: 'close-circle',
					disabled: fallback === 'changing-password',
				}}>
				<Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					<Form.Item required label='Senha'>
						<Input
							placeholder='Informe a senha do usuário'
							type={pInput.p}
							suffix={
								pInput.p === 'password' ? (
									<Icon
										type='eye-invisible'
										onClick={() => {
											setPInput({ ...pInput, p: 'text' });
										}}
									/>
								) : (
									<Icon
										type='eye'
										onClick={() => {
											setPInput({ ...pInput, p: 'password' });
										}}
									/>
								)
							}
							value={passwords?.password}
							onChange={({ target: { value } }) => {
								return setPasswors({ ...passwords, password: value });
							}}
						/>
					</Form.Item>

					<Form.Item required label='Confirmar senha'>
						<Input
							placeholder='Confirme a senha'
							type={pInput.p2}
							suffix={
								pInput.p2 === 'password' ? (
									<Icon
										type='eye-invisible'
										onClick={() => {
											setPInput({ ...pInput, p2: 'text' });
										}}
									/>
								) : (
									<Icon
										type='eye'
										onClick={() => {
											setPInput({ ...pInput, p2: 'password' });
										}}
									/>
								)
							}
							value={passwords?.password2}
							onChange={({ target: { value } }) => {
								return setPasswors({ ...passwords, password2: value });
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default UserDetails;
