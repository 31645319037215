export const containerStyles = {
	width: '95vw',
	background: '#FFF',
	borderRadius: '4px',
	margin: '0 2.5vw 32px 2.5vw',
	boxShadow: '1px 2px 10px 2px rgba(0, 0, 0, .2)',
};

export const buttonStyles = {
	borderRadius: 'var(--border-radius-base)',
	background: 'var(--primary)',
	color: '#FFF',
};
