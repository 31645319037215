import React, { useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';

import { PageHeader, Button, Icon, message, Slider, Divider, Select, Card, Typography } from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import {
	ButtonContainer,
	Container,
	CurveImg,
	CurvesContainer,
	FadeRow,
	FadeRowHeader,
	FadesList,
	Threshold,
	ThresholdContainer,
	ThresholdDemo,
} from './styles';

import MixageTemplatesAPI from '../../../../services/sdks/mixage';

import waveSrc from '../../../../assets/images/mixage-config/wave.svg';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Geral' },
		{ breadcrumbName: 'Configurar mixagem' },
	],
	style: { marginBottom: 12 },
};

const marksSm = {
	0.25: '0.25',
	2.5: '2.5',
	5: '5',
	7.5: '7.5',
	10: '10',
	12.5: '12.5',
	15: '15',
};

const marksLg = {
	0.25: '0.25',
	10: '10',
	20: '20',
	30: '30',
	40: '40',
	50: '50',
	60: '60',
};

const thMarks = {
	'-60': '-60dB',
	'-50': '-50dB',
	'-40': '-40dB',
	'-30': '-30dB',
	'-20': '-20dB',
	'-10': '-10dB',
};

const MixageConfig = () => {
	const [fallback, setFallback] = useState({ initialData: false });
	const [template, setTemplate] = useState(null);

	const renderFadeOptions = useCallback(() => {
		const curves = [
			'nofade',
			'cbr',
			'cub',
			'dese',
			'desi',
			'esin',
			'exp',
			'hsin',
			'ihsin',
			'ipar',
			'iqsin',
			'log',
			'losi',
			'par',
			'qsin',
			'qua',
			'squ',
			'tri',
		];

		return curves.map((curve) => (
			<Select.Option key={curve} value={curve}>
				<CurveImg
					alt='curve'
					size='35px'
					style={{ marginRight: 12 }}
					src={require(`../../../../assets/images/mixage-config/fade-curves/${curve}.svg`)}
				/>{' '}
				{curve === 'nofade' ? 'Sem fade' : curve}
			</Select.Option>
		));
	}, []);

	const handleChangeField = useCallback(
		(categ, key, attr, value) => {
			if (categ === 'fades') {
				return setTemplate({
					...template,
					fades: {
						...template?.fades,
						[key]: {
							...template?.fades[key],
							[attr]: value,
						},
					},
				});
			}

			if (categ === 'trimming') {
				return setTemplate({
					...template,
					trimmingThresholds: {
						...template?.trimmingThresholds,
						[key]: value,
					},
				});
			}

			if (categ === 'trackSignature') {
				return setTemplate({
					...template,
					trackSignatureStart: value,
				});
			}

			if (categ === 'offsMerging') {
				return setTemplate({
					...template,
					offsMergingDelay: value,
				});
			}
		},
		[template]
	);

	const handleUpdateTemplate = useCallback(async () => {
		try {
			const { _id: templateId } = template;
			const payload = { ...template };

			setFallback((prev) => ({ ...prev, updating: true }));

			const {
				data: { template: updatedTemplate },
			} = await MixageTemplatesAPI.update(templateId, payload);

			setFallback((prev) => ({ ...prev, updating: false }));
			setTemplate(updatedTemplate);

			message.success('Template atualizado com sucesso');
		} catch (error) {
			setFallback((prev) => ({ ...prev, updating: false }));

			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, [template]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { templates },
				} = await MixageTemplatesAPI.index({ query: 'isActive=true' });

				setTemplate(templates[0]);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os templates');
			}
		};

		fetchInitialData();
	}, []);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Configurar mixagem' />

			<PageHeader title='Configurar Mixagem' breadcrumb={breadcrumb}>
				<Typography>
					Configurar transições entre elementos e limiar de remoção do silêncio das músicas
				</Typography>
			</PageHeader>

			<Container>
				<Card title='Delay entre a mixagem TKPLAY e IA'>
					<Slider
						min={10}
						max={1000}
						tipFormatter={(value) => `${value} milissegundos`}
						step={10}
						marks={(() => {
							let marks = {
								10: '10ms',
							};

							for (let index = 10; index <= 1000; index++) {
								if (index % 100 === 0) {
									marks = {
										...marks,
										[index]: `${index} ms`,
									};
								}
							}

							return marks;
						})()}
						style={{ width: '100%' }}
						value={template?.offsMergingDelay}
						onChange={(value) => {
							handleChangeField('offsMerging', null, null, value);
						}}
					/>
				</Card>

				<Card title='Minutagem da assinatura das músicas'>
					<Slider
						min={5}
						max={70}
						tipFormatter={(value) =>
							value <= 60
								? `${value} segundos`
								: `1:${value % 60 >= 10 ? value % 60 : `0${value % 60}`} segundos`
						}
						marks={(() => {
							let marks = {};

							for (let index = 5; index <= 70; index++) {
								if (index % 5 === 0) {
									marks = {
										...marks,
										[index]:
											index <= 60
												? `${index}s`
												: `1:${index % 60 >= 10 ? index % 60 : `0${index % 60}`}s`,
									};
								}
							}

							return marks;
						})()}
						style={{ width: '100%' }}
						value={template?.trackSignatureStart}
						onChange={(value) => {
							handleChangeField('trackSignature', null, null, value);
						}}
					/>
				</Card>

				<Card title='Limiar de corte das músicas'>
					<ThresholdContainer>
						<div className='slider-container'>
							<Typography.Paragraph style={{ opacity: 0.8, fontStyle: 'italic' }}>
								<Icon type='question-circle' style={{ marginRight: 12 }} /> Quanto mais próximo de{' '}
								<strong>0dB's</strong>, maior será o corte
							</Typography.Paragraph>
							<Typography.Paragraph>
								Todas as amostras do fim das músicas com volume abaixo de{' '}
								<strong>{template?.trimmingThresholds?.tracks}dB's</strong> serão cortados
							</Typography.Paragraph>
							<Slider
								min={-60}
								max={-10}
								marks={thMarks}
								style={{ width: '100%' }}
								value={template?.trimmingThresholds?.tracks}
								onChange={(value) => {
									handleChangeField('trimming', 'tracks', null, value);
								}}
							/>
						</div>
						<ThresholdDemo>
							<img src={waveSrc} alt='waveform' />
							<Threshold threshold={template?.trimmingThresholds?.tracks} />
						</ThresholdDemo>
					</ThresholdContainer>
				</Card>

				<Card title='Controle de transições'>
					<FadesList>
						<FadeRow>
							<FadeRowHeader>
								<Typography.Title level={4}>
									<Icon type='audio' /> Off <span style={{ margin: '0 10px' }}>➡</span>{' '}
									<Icon type='audio' /> Off
								</Typography.Title>
								<div>
									<Typography.Text>
										Transição ocorre entre a saída de um <strong>off</strong> para outro
									</Typography.Text>
								</div>
							</FadeRowHeader>

							<Divider style={{ margin: '16px 0' }} />

							<CurvesContainer>
								<div>
									<span>Curva off 1</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.off_off?.c1}
										onChange={(value) => {
											handleChangeField('fades', 'off_off', 'c1', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Curva off 2</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.off_off?.c2}
										onChange={(value) => {
											handleChangeField('fades', 'off_off', 'c2', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Duração</span>
									<Slider
										marks={marksSm}
										min={0.2}
										max={15}
										step={0.1}
										value={template?.fades?.off_off?.duration}
										onChange={(value) => {
											handleChangeField('fades', 'off_off', 'duration', value);
										}}
									/>
								</div>
							</CurvesContainer>
						</FadeRow>

						<FadeRow>
							<FadeRowHeader>
								<Typography.Title level={4}>
									<Icon type='audio' /> Off <span style={{ margin: '0 10px' }}>➡</span>{' '}
									<Icon type='customer-service' /> Música
								</Typography.Title>
								<div>
									<Typography.Text>
										Transição ocorre entre a saída de um <strong>off</strong> e a entrada de uma{' '}
										<strong>música</strong>
									</Typography.Text>
								</div>
							</FadeRowHeader>

							<Divider style={{ margin: '16px 0' }} />

							<CurvesContainer>
								<div>
									<span>Curva do off</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.off_track?.c1}
										onChange={(value) => {
											handleChangeField('fades', 'off_track', 'c1', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Curva da música</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.off_track?.c2}
										onChange={(value) => {
											handleChangeField('fades', 'off_track', 'c2', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Duração</span>
									<Slider
										marks={marksLg}
										min={0.2}
										max={60}
										step={0.1}
										value={template?.fades?.off_track?.duration}
										onChange={(value) => {
											handleChangeField('fades', 'off_track', 'duration', value);
										}}
									/>
								</div>
							</CurvesContainer>
						</FadeRow>

						<FadeRow>
							<FadeRowHeader>
								<Typography.Title level={4}>
									<Icon type='customer-service' /> Música
									<span style={{ margin: '0 10px' }}>➡</span> <Icon type='audio' /> Off{' '}
								</Typography.Title>
								<div>
									<Typography.Text>
										Transição ocorre entre a saída de uma <strong>música</strong> e a entrada de um{' '}
										<strong>off</strong>
									</Typography.Text>
								</div>
							</FadeRowHeader>

							<Divider style={{ margin: '16px 0' }} />

							<CurvesContainer>
								<div>
									<span>Curva da música</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.track_off?.c1}
										onChange={(value) => {
											handleChangeField('fades', 'track_off', 'c1', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Curva do off</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.track_off?.c2}
										onChange={(value) => {
											handleChangeField('fades', 'track_off', 'c2', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Duração</span>
									<Slider
										marks={marksLg}
										min={0.2}
										max={60}
										step={0.1}
										value={template?.fades?.track_off?.duration}
										onChange={(value) => {
											handleChangeField('fades', 'track_off', 'duration', value);
										}}
									/>
								</div>
							</CurvesContainer>
						</FadeRow>

						<FadeRow>
							<FadeRowHeader>
								<Typography.Title level={4}>
									<Icon type='audio' /> Off
									<span style={{ margin: '0 10px' }}>➡</span> <Icon type='ellipsis' /> Vinheta{' '}
								</Typography.Title>
								<div>
									<Typography.Text>
										Transição ocorre entre a saída de um <strong>off</strong> e a entrada de uma{' '}
										<strong>vinheta</strong>
									</Typography.Text>
								</div>
							</FadeRowHeader>

							<Divider style={{ margin: '16px 0' }} />

							<CurvesContainer>
								<div>
									<span>Curva do off</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.off_vignette?.c1}
										onChange={(value) => {
											handleChangeField('fades', 'off_vignette', 'c1', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Curva da vinheta</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.off_vignette?.c2}
										onChange={(value) => {
											handleChangeField('fades', 'off_vignette', 'c2', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Duração</span>
									<Slider
										marks={marksSm}
										min={0.2}
										max={15}
										step={0.1}
										value={template?.fades?.off_vignette?.duration}
										onChange={(value) => {
											handleChangeField('fades', 'off_vignette', 'duration', value);
										}}
									/>
								</div>
							</CurvesContainer>
						</FadeRow>

						<FadeRow>
							<FadeRowHeader>
								<Typography.Title level={4}>
									<Icon type='ellipsis' /> Vinheta <span style={{ margin: '0 10px' }}>➡</span>{' '}
									<Icon type='audio' /> Off
								</Typography.Title>
								<div>
									<Typography.Text>
										Transição ocorre entre a saída de uma <strong>vinheta</strong> e a entrada de um{' '}
										<strong>off</strong>
									</Typography.Text>
								</div>
							</FadeRowHeader>

							<Divider style={{ margin: '16px 0' }} />

							<CurvesContainer>
								<div>
									<span>Curva da vinheta</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.vignette_off?.c1}
										onChange={(value) => {
											handleChangeField('fades', 'vignette_off', 'c1', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Curva do off</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.vignette_off?.c2}
										onChange={(value) => {
											handleChangeField('fades', 'vignette_off', 'c2', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Duração</span>
									<Slider
										marks={marksSm}
										min={0.2}
										max={15}
										step={0.1}
										value={template?.fades?.vignette_off?.duration}
										onChange={(value) => {
											handleChangeField('fades', 'vignette_off', 'duration', value);
										}}
									/>
								</div>
							</CurvesContainer>
						</FadeRow>

						<FadeRow>
							<FadeRowHeader>
								<Typography.Title level={4}>
									<Icon type='customer-service' /> Música
									<span style={{ margin: '0 10px' }}>➡</span> <Icon type='ellipsis' /> Vinheta{' '}
								</Typography.Title>
								<div>
									<Typography.Text>
										Transição ocorre entre a saída de uma <strong>música</strong> e a entrada de uma{' '}
										<strong>vinheta</strong>
									</Typography.Text>
								</div>
							</FadeRowHeader>

							<Divider style={{ margin: '16px 0' }} />

							<CurvesContainer>
								<div>
									<span>Curva da música</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.track_vignette?.c1}
										onChange={(value) => {
											handleChangeField('fades', 'track_vignette', 'c1', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Curva da vinheta</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.track_vignette?.c2}
										onChange={(value) => {
											handleChangeField('fades', 'track_vignette', 'c2', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Duração</span>
									<Slider
										marks={marksLg}
										min={0.2}
										max={60}
										step={0.1}
										value={template?.fades?.track_vignette?.duration}
										onChange={(value) => {
											handleChangeField('fades', 'track_vignette', 'duration', value);
										}}
									/>
								</div>
							</CurvesContainer>
						</FadeRow>

						<FadeRow>
							<FadeRowHeader>
								<Typography.Title level={4}>
									<Icon type='ellipsis' /> Vinheta <span style={{ margin: '0 10px' }}>➡</span>{' '}
									<Icon type='customer-service' /> Música
								</Typography.Title>
								<div>
									<Typography.Text>
										Transição ocorre entre a saída de uma <strong>vinheta</strong> e a entrada de
										uma <strong>música</strong>
									</Typography.Text>
								</div>
							</FadeRowHeader>

							<Divider style={{ margin: '16px 0' }} />

							<CurvesContainer>
								<div>
									<span>Curva da vinheta</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.vignette_track?.c1}
										onChange={(value) => {
											handleChangeField('fades', 'vignette_track', 'c1', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Curva da música</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.vignette_track?.c2}
										onChange={(value) => {
											handleChangeField('fades', 'vignette_track', 'c2', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Duração</span>
									<Slider
										marks={marksLg}
										min={0.2}
										max={60}
										step={0.1}
										value={template?.fades?.vignette_track?.duration}
										onChange={(value) => {
											handleChangeField('fades', 'vignette_track', 'duration', value);
										}}
									/>
								</div>
							</CurvesContainer>
						</FadeRow>

						<FadeRow>
							<FadeRowHeader>
								<Typography.Title level={4}>
									<Icon type='ellipsis' /> Vinheta <span style={{ margin: '0 10px' }}>➡</span>{' '}
									<Icon type='ellipsis' /> Vinheta{' '}
								</Typography.Title>
								<div>
									<Typography.Text>
										Transição ocorre entre a saída de uma <strong>vinheta</strong> para outra
									</Typography.Text>
								</div>
							</FadeRowHeader>

							<Divider style={{ margin: '16px 0' }} />

							<CurvesContainer>
								<div>
									<span>Curva da vinheta 1</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.vignette_vignette?.c1}
										onChange={(value) => {
											handleChangeField('fades', 'vignette_vignette', 'c1', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Curva da vinheta 1</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.vignette_vignette?.c2}
										onChange={(value) => {
											handleChangeField('fades', 'vignette_vignette', 'c2', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Duração</span>
									<Slider
										marks={marksSm}
										min={0.2}
										max={15}
										step={0.1}
										value={template?.fades?.vignette_vignette?.duration}
										onChange={(value) => {
											handleChangeField('vignette_vignette', 'duration', value);
										}}
									/>
								</div>
							</CurvesContainer>
						</FadeRow>

						<FadeRow>
							<FadeRowHeader>
								<Typography.Title level={4}>
									<Icon type='customer-service' /> Música{' '}
									<span style={{ margin: '0 10px' }}>➡</span> <Icon type='customer-service' />{' '}
									Música{' '}
								</Typography.Title>
								<div>
									<Typography.Text>
										Transição ocorre entre a saída de uma <strong>música</strong> para outra
									</Typography.Text>
								</div>
							</FadeRowHeader>

							<Divider style={{ margin: '16px 0' }} />

							<CurvesContainer>
								<div>
									<span>Curva da música 1</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.track_track?.c1}
										onChange={(value) => {
											handleChangeField('fades', 'track_track', 'c1', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Curva da música 1</span>
									<Select
										style={{ width: '200px' }}
										value={template?.fades?.track_track?.c2}
										onChange={(value) => {
											handleChangeField('fades', 'track_track', 'c2', value);
										}}>
										{renderFadeOptions()}
									</Select>
								</div>

								<div>
									<span>Duração</span>
									<Slider
										marks={marksLg}
										min={0.2}
										max={60}
										step={0.1}
										value={template?.fades?.track_track?.duration}
										onChange={(value) => {
											handleChangeField('fades', 'track_track', 'duration', value);
										}}
									/>
								</div>
							</CurvesContainer>
						</FadeRow>
					</FadesList>

					<Divider />

					<ButtonContainer>
						<Button
							loading={fallback?.updating}
							onClick={handleUpdateTemplate}
							type='primary'
							icon='save'
							size='large'>
							Salvar alterações
						</Button>
					</ButtonContainer>
				</Card>
			</Container>
		</>
	);
};

export default MixageConfig;
