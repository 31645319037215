import React from 'react';
import { Section, SectionTitle, Badge, Text } from './styles';

export default function Creators() {
	return (
		<Section>
			<SectionTitle>OPEC</SectionTitle>
			<div className='flex justify-center space-x-2 mb-4'>
				<Badge variant='default' className='bg-[#9c27b0]'>
					Músicas $
				</Badge>
				<Badge variant='default' className='bg-[#9c27b0]'>
					Spots
				</Badge>
				<Badge variant='default' className='bg-[#9c27b0]'>
					Testemunhais
				</Badge>
			</div>
			<Text>
            Deixe de se preocupar com as publicidades no seu conteúdo. Com a TalkPlay, você pode inserir tudo o que precisa de forma simples e eficiente.
			</Text>
		</Section>
	);
}
