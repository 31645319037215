import React from 'react';
import { Typography, Divider } from 'antd';

import Meta from '../../../../components/Meta';
import HelpButtons from '../../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../../assets/images/tutorials/programs/own-program-details/img-01.png';
import img02 from '../../../../assets/images/tutorials/programs/own-program-details/img-02.png';
import img03 from '../../../../assets/images/tutorials/programs/own-program-details/img-03.png';
import img04 from '../../../../assets/images/tutorials/programs/own-program-details/img-04.png';

const { Title, Paragraph } = Typography;

const OwnProgramDetails = () => (
	<Container>
		<Meta title='Ajuda - Detalhes do Programa Próprio' />

		<Title>Detalhes do Programa </Title>

		<Divider />

		<Paragraph>
			Ao acessar os detalhes de um{' '}
			<i>
				<strong>programa</strong>
			</i>{' '}
			, você irá se deparar com a seguinte tela:
		</Paragraph>

		<ImgContainer>
			<img src={img01} alt='Gerenciar programas' />
		</ImgContainer>

		<Paragraph>
			Na parte superior direita você irá encontrar os botões para <strong>Editar</strong> e{' '}
			<strong>Excluir</strong> o programa:
		</Paragraph>

		<ImgContainer>
			<img src={img02} alt='Gerenciar programas' />
		</ImgContainer>

		<Divider />

		<Title level={3}>Edição</Title>

		<Paragraph>
			Para editar as informações do programa, basta clicar no botão informado acima(Editar). Em
			seguida será exibida uma janela com formulário para edição das informações.
		</Paragraph>

		<ImgContainer>
			<img src={img03} alt='Gerenciar programas' />
		</ImgContainer>

		<Paragraph>
			Após efetuar as alterações desejadas, basta clicar em <strong>Atualizar</strong>
		</Paragraph>

		<Divider />

		<Title level={3}>Exclusão</Title>

		<Paragraph>
			Para que um dos seus conteúdos sejá excluído, você precisa enviar uma solicitação para que
			nossa equipe analise o motivo da exclusão.
		</Paragraph>

		<Paragraph>
			Para editar as informações do programa, basta clicar no botão informado acima(Excluir). Em
			seguida será exibido o campo para que você possa informar o motivo da exclusão.
		</Paragraph>

		<ImgContainer>
			<img src={img04} alt='Gerenciar programas' />
		</ImgContainer>

		<Paragraph>
			Após isso, basta enviar a requisição e aguardar, nossa equipe irá analizar a situação, e caso
			sua requisição seja aceita, o programa será excluído e retirado da sua grade.
		</Paragraph>

		<HelpButtons
			prev={{ label: 'Gerenciamento de Programas', to: '/help/programs/programs-management' }}
			next={{ label: 'Detalhes do Programa', to: '/help/programs/program-details/more-details' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default OwnProgramDetails;
