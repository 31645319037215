import React, { useState } from 'react';

import { FiChevronDown } from 'react-icons/fi';
import styled from 'styled-components';
import logoSrc from '../../../../assets/landpage/logo-header.svg';
import { useMobileMenu } from '../../contexts/MobileMenu';
import { HeaderDropdown } from '../HeaderDropdown';
import { talkRadioMenuItems } from './data';
import { Container, Dropdown, Dropdowns,  Logo, MobileMenuToggle } from './styles';

const Nav = styled.nav`
	display: none;

	@media (min-width: 768px) {
		display: flex;
		gap: 24px;
	}
`;


const DemoButton = styled.a`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 8px 16px;
	font-size: 14px;
	font-weight: 500;
	color: white;
	background-color: #102B9C;
	border: none;
	border-radius: 8px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	transition: background-color 0.3s;

	&:hover {
		background-color: #cfcfcf;
	}

	&:focus {
		outline: none;
		box-shadow: 0 0 0 2px rgba(214, 132, 59, 0.5);
	}
`;

const Header = () => {
	const menu = useMobileMenu();
	const [visibleMenus, setVisibleMenus] = useState({
		play: false,
		radio: false,
	});

	return (
		<Container>
			<Logo src={logoSrc} />
			<Nav>
				<Dropdowns>
					<Dropdown
						onMouseOver={() => setVisibleMenus({ ...visibleMenus, radio: true })}
						onMouseLeave={() => setVisibleMenus({ ...visibleMenus, radio: false })}>
						<span>Talk Radio</span>
						<FiChevronDown />
						{visibleMenus.radio && <HeaderDropdown items={talkRadioMenuItems} />}
					</Dropdown>
				</Dropdowns>
			</Nav>
			<DemoButton href='/login'>Fazer login</DemoButton>
			<MobileMenuToggle size={25} color='#000' onClick={() => menu.toggle()} />
		</Container>
	);
};

export default Header;
