import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	Select,
	DatePicker,
	Button,
	Typography,
	Table,
	Tag,
	message,
	Divider,
	Tooltip,
	Breadcrumb,
	Icon,
	Modal,
	Alert,
} from 'antd';

import Meta from '../../../../components/Meta';
import Form from '../../../../components/Form';
import Fallback from '../../../../components/Fallback';
import PageSizeHandler from '../../../../components/PageSizeHandle';
import SelectOptWithBadge from '../../../../components/SelectOptWithBadge';

import { Container, OptionContent, tagStyle, TableHeader } from './styles';

import ProgramsAPI from '../../../../services/sdks/programs';
import EpisodesAPI from '../../../../services/sdks/episodes';
import env from '../../../../config/env';

import { useFilenameTemplate, useZip } from '../../../../hooks';
import { FiDownload } from 'react-icons/fi';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';
import EpisodeDownloadProgress from '../../../../components/EpisodeDownloadProgress';

const ManageEpisodes = ({ user }) => {
	const generateFilename = useFilenameTemplate();
	const zip = useZip();

	const [fallback, setFallback] = useState({ initialData: true, episodes: true });
	const [episodes, setEpisodes] = useState([]);
	const [selectedEpisodes, setSelectedEpisodes] = useState([]);
	const [programs, setPrograms] = useState([]);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [download, setDownload] = useState({ program: null, blocks: [] });
	const [error, setError] = useState(null);
	const [filters, setFilters] = useState({
		program: undefined,
		status: '',
		date: null,
		isAuto: '',
		isMasterized: '',
	});

	const getFilePath = useCallback((fileName, isMasterized) => {
		return resolveFileSrc({
			useAccelerate: true,
			customS3Bucket: isMasterized ? env.episodesS3Bucket : env.miscS3Bucket,
			folder: null,
			fileName: isMasterized ? fileName : `tmp${fileName}`,
		});
	}, []);

	const columns = [
		{
			key: 'id',
			title: 'ID',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					5
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Programa',
			dataIndex: 'program',
			key: 'program',
			render: (program) => program?.name,
		},
		{
			title: 'Data',
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: 'Qualidade',
			dataIndex: 'isMasterized',
			key: 'master',
			align: 'center',
			render: (isMasterized) =>
				isMasterized ? (
					<Tag color='green' style={tagStyle}>
						<span>Masterizado</span>
					</Tag>
				) : (
					<Tag color='#CCC' style={tagStyle}>
						<span>Padrão</span>
					</Tag>
				),
		},
		{
			title: 'Tipo de Geração',
			dataIndex: 'isAuto',
			key: 'isAuto',
			render: (isAuto) =>
				isAuto ? (
					<Tag color='blue' style={tagStyle}>
						<span>Geração Noturna</span>
					</Tag>
				) : (
					<Tag color='volcano' style={tagStyle}>
						<span>Geração Manual</span>
					</Tag>
				),
		},
		{
			title: 'Status',
			dataIndex: 'nonGerenationReason',
			key: 'nonGerenationReason',
			align: 'center',
			render: (nonGerenationReason) =>
				nonGerenationReason ? (
					<Tooltip title={nonGerenationReason?.replace(/\+/g, '')}>
						<Tag color='red' style={tagStyle}>
							{/* <span>{nonGerenationReason?.replace(/\+/g, '').slice(0, 25)}...</span> */}
							Gerado Com Erro
						</Tag>
					</Tooltip>
				) : (
					<Tag color='green' style={tagStyle}>
						Gerado Com Sucesso
					</Tag>
				),
		},
		{
			title: 'Ações',
			key: 'actions',
			align: 'right',
			render: (episode) => (
				<div>
					<Button
						style={{ marginRight: 4 }}
						onClick={() => setError(episode.nonGerenationReason)}
						disabled={episode.blocks || download?.blocks.length !== 0}
						size='small'
						icon='eye'
						type='danger'>
						Mostrar Erro
					</Button>
					<Button
						onClick={() => handleDownload(episode)}
						disabled={!episode.blocks || download?.blocks.length !== 0}
						size='small'
						icon='download'
						type='primary'>
						Baixar
					</Button>
				</div>
			),
		},
	];

	const handleDownload = useCallback(
		async ({ program, date, isMasterized, rowIndex, blocks }) => {
			try {
				setFallback((fallback) => ({
					...fallback,
					downloads: {
						...fallback.downloads,
						[rowIndex]: 0,
					},
				}));

				setDownload({ program: program.name, blocks: blocks.map(() => 0) });

				const zipName = `${program.name} - ${date}`;
				const zipData = blocks.map((block, index) => ({
					url: getFilePath(block, isMasterized),
					name: generateFilename({
						index,
						template: program.filenameTemplate,
					}),
				}));

				await zip.download(zipData, zipName, ({ index, completed, speed }) => {
					setDownload((prev) => ({
						...prev,
						blocks: prev.blocks.map((b, idx) => (idx === index ? { completed, speed } : b)),
					}));
				});

				setDownload({ program: null, blocks: [] });
			} catch (error) {
				console.error(error);
			}
		},
		[generateFilename, getFilePath, zip]
	);

	const handleMultiDownload = useCallback(async () => {
		try {
			setFallback((fallback) => ({ ...fallback, downloadingMult: true }));

			for (let index = 0; index < selectedEpisodes.length; index++) {
				await handleDownload(selectedEpisodes[index]);
			}

			setFallback((fallback) => ({ ...fallback, downloadingMult: false }));
			message.success('Episódios baixados com sucesso');
		} catch (error) {
			console.error(error);
		}
	}, [handleDownload, selectedEpisodes]);

	useEffect(() => {
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { programs },
				} = await ProgramsAPI.index(`userId=${user?._id}&isDeleted=false`);

				setPrograms(
					programs.sort((x, y) => {
						return x?.isFavorited === y?.isFavorited ? 0 : x?.isFavorited ? -1 : 1;
					})
				);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os programas');
			}
		};

		if (user) {
			fetchInitialData();
		}
	}, [user]);

	useEffect(() => {
		const fetchEpisodes = async () => {
			try {
				setFallback((fallback) => ({ ...fallback, episodes: true }));

				let query = `page=${pagination?.current - 1}&limit=${pagination?.pageSize}&user=${
					user?._id
				}&`;

				filters?.program && (query = `${query}program=${filters.program}&`);
				filters?.date && (query = `${query}date=${filters.date}&`);
				filters?.status && (query = `${query}status=${filters.status}&`);
				filters?.isAuto && (query = `${query}isAuto=${filters.isAuto}&`);
				filters?.isMasterized && (query = `${query}isMasterized=${filters.isMasterized}&`);

				const {
					data: { episodes, total },
				} = await EpisodesAPI.index({ query });

				setEpisodes(episodes);
				setEpisodes(episodes);
				setPagination((prev) => ({ ...prev, total }));
				setFallback((prev) => ({ ...prev, episodes: false }));
			} catch (error) {
				console.error(error);
			}
		};

		fetchEpisodes();
	}, [filters, pagination.current, pagination.pageSize, user]); //eslint-disable-line

	useEffect(() => {
		setSelectedEpisodes([]);
	}, [filters]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Episódios' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[{ breadcrumbName: 'INÍCIO' }, { breadcrumbName: 'GERENCIAR EPISÓDIOS' }]}
				/>

				<header>
					<Typography.Title level={2}>Gerenciar Episódios</Typography.Title>
				</header>

				<Alert
					showIcon
					closable
					type='info'
					style={{ marginBottom: 24 }}
					description='Após a geração do seu conteúdo, ele ficará disponível aqui por 24 horas. Este prazo permite que você acesse e gerencie seus episódios de forma conveniente e segura. Lembre-se de baixar ou salvar o que precisar dentro deste período!'
				/>

				<Form.Container layout='1fr 1fr 1fr 1fr 1fr'>
					<Form.Item label='Filtrar por programa'>
						<Select
							showSearch
							value={filters.program}
							placeholder='Programa'
							onChange={(value) => setFilters({ ...filters, program: value })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							<Select.Option key='all' value={undefined} _search='TODOS'>
								TODOS
							</Select.Option>

							{programs.map((program) => (
								<Select.Option
									key={program._id}
									disabled={!program.isActive}
									_search={program?.name}>
									<OptionContent>
										<Icon
											theme='filled'
											type='heart'
											style={{
												color: 'var(--danger)',
												marginRight: 8,
												opacity: program?.isFavorited ? 1 : 0,
												pointerEvents: 'none',
											}}
										/>
										<span>
											{program?.name}
											{!program?.isActive && <Tag color='red'>Inadimplente</Tag>}
										</span>
									</OptionContent>
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por status'>
						<Select
							placeholder='Selecione o status'
							onChange={(value) => setFilters({ ...filters, status: value })}
							value={filters.status}>
							<Select.Option value=''>
								<SelectOptWithBadge color='#ccc' label='Todos' />
							</Select.Option>
							<Select.Option value='success'>
								<SelectOptWithBadge color='#52c41a' label='Gerados Com Sucesso' />
							</Select.Option>
							<Select.Option value='error'>
								<SelectOptWithBadge color='#f5222d' label='Gerados Com Erro' />
							</Select.Option>
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por tipo de geração'>
						<Select
							placeholder='Selecione o tipo'
							onChange={(value) => setFilters({ ...filters, isAuto: value })}
							value={filters.isAuto}>
							<Select.Option value=''>
								<SelectOptWithBadge color='#ccc' label='Todos' />
							</Select.Option>
							<Select.Option value='false'>
								<SelectOptWithBadge color='#fa541c' label='Geração Manual' />
							</Select.Option>
							<Select.Option value='true'>
								<SelectOptWithBadge color='#1890ff' label='Geração Noturna' />
							</Select.Option>
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por qualidade'>
						<Select
							placeholder='Selecione a qualidade'
							onChange={(value) => setFilters({ ...filters, isMasterized: value })}
							value={filters.isMasterized}>
							<Select.Option value=''>
								<SelectOptWithBadge color='#ccc' label='Todos' />
							</Select.Option>
							<Select.Option value='true'>
								<SelectOptWithBadge color='green' label='Masterizado' />
							</Select.Option>
							<Select.Option value='false'>
								<SelectOptWithBadge color='#ccc' label='Qualidade Padrão' />
							</Select.Option>
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por data'>
						<DatePicker
							placeholder='Data de lançamento'
							format='DD/MM/yyyy'
							value={filters?.date && moment(filters.date, 'DD/MM/yyyy')}
							onChange={(_, date) => setFilters({ ...filters, date })}
						/>
					</Form.Item>
				</Form.Container>

				<Divider />

				<TableHeader>
					<div className='actions'>
						<span>
							Quantidade: <strong>{pagination?.total}</strong>
						</span>
						<div>
							<Button
								size='small'
								disabled={!selectedEpisodes.length}
								type='ghost'
								onClick={handleMultiDownload}
								loading={fallback?.multiDownload}>
								<FiDownload /> Baixar selecionados{' '}
								{selectedEpisodes.length !== 0 && `(${selectedEpisodes.length})`}
							</Button>
						</div>
					</div>

					<PageSizeHandler pagination={pagination} setPagination={setPagination} />
				</TableHeader>

				<Table
					rowKey='_id'
					size='middle'
					style={{ border: 'none' }}
					loading={fallback.episodes}
					columns={columns}
					dataSource={episodes}
					pagination={{
						...pagination,
						size: 'large',
						onChange: (current) => setPagination({ ...pagination, current }),
					}}
					rowSelection={{
						selectedRowKeys: selectedEpisodes.map((e) => e._id),
						getCheckboxProps: ({ nonGerenationReason }) => ({
							disabled: !!nonGerenationReason,
						}),
						onChange: (_, selectedRows) => setSelectedEpisodes(selectedRows),
					}}
				/>
			</Container>

			<EpisodeDownloadProgress isVisible={download.blocks.length !== 0} blocks={download.blocks} />

			<Modal
				width={400}
				visible={error !== null}
				title='Motivo da não geração'
				onCancel={() => setError(null)}
				footer={null}>
				<Typography.Text>{error}</Typography.Text>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(ManageEpisodes);
