const userTypes = {
	ADMIN: 'ADMIN',
	TALK: 'TALK',
	COMMON: 'COMMON',
	ELEMENTS_MANAGER: 'ELEMENTS-MANAGER',
	SIGNATURES_MANAGER: 'SIGNATURES-MANAGER',
	TRACKS_MANAGER: 'TRACKS-MANAGER',
	SELLER_MANAGER: 'SELLER-MANAGER',
	CASTER: 'CASTER',
};

export default userTypes;
