import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
	PageHeader,
	Table,
	Card,
	Button,
	Icon,
	Modal,
	message,
	Typography,
	Input,
	Spin,
} from 'antd';

import Meta from '../../../components/Meta';
import Fallback from '../../../components/Fallback';
import DecoratedText from '../../../components/DecoratedText';
import { Container } from './styles';

import TermsAPI from '../../../services/sdks/useTerms';

const breadcrumb = {
	routes: [{ breadcrumbName: 'Painel do Administrador' }, { breadcrumbName: 'Termos de Uso' }],
	style: { marginBottom: 12 },
};

const Terms = ({ user }) => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [terms, setTerms] = useState([]);
	const [term, setTerm] = useState(null);
	const [currentTerm, setCurrentTerm] = useState(null);
	const [showModals, setShowModals] = useState({ details: false, edit: false });

	const columns = [
		{
			key: 'id',
			title: 'ID',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					5
				)}...`}</Typography.Text>
			),
		},
		{
			key: 'version',
			dataIndex: 'version',
			title: 'Versão',
			render: (v) => (
				<>
					Termos de uso versão <strong>{v}.0</strong>
				</>
			),
		},
		{
			key: 'edit',
			title: 'Detalhes',
			align: 'right',
			render: (term) => (
				<Button
					onClick={() => {
						setTerm(term);
						setShowModals({ ...showModals, details: true });
					}}
					size='small'
					type='ghost'
					icon='eye'
				>
					Detalhes
				</Button>
			),
		},
	];

	const handleUpdate = useCallback(async () => {
		try {
			setFallback((prev) => ({ ...prev, updatingTerms: true }));

			const { data } =
				currentTerm.version === 0
					? await TermsAPI.create({ content: currentTerm?.content })
					: await TermsAPI.update(currentTerm._id, {
							content: currentTerm?.content,
						});

			const key = currentTerm.version === 0 ? 'term' : 'updatedTerm';

			setCurrentTerm(data[key]);
			setTerms((prev) => [...prev, data[key]]);
			setShowModals((prev) => ({ ...prev, update: false }));
			setFallback((prev) => ({ ...prev, updatingTerms: false }));

			return message.success('Termos atualizados com sucesso');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, [currentTerm]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { terms },
				} = await TermsAPI.list(user?._id);

				setTerms(terms);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro, tente novamente');
			}
		};

		fetchInitialData();
	}, [user]);

	useEffect(() => {
		const fetchCurrentTerms = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingCurrentTerm: true }));

				const {
					data: { term },
				} = await TermsAPI.getCurrentTerm();

				setCurrentTerm(term);
				setFallback((prev) => ({ ...prev, fetchingCurrentTerm: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro, tente novamente');
			}
		};

		if (showModals?.update) {
			fetchCurrentTerms();
		}
	}, [showModals]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor, aguarde' />;
	}

	return (
		<>
			<Meta title='Termos de uso' />

			<PageHeader
				title='Termos de Uso'
				breadcrumb={breadcrumb}
				extra={[
					<Button
						key='1'
						type='ghost'
						size='large'
						onClick={() => setShowModals({ ...showModals, update: true })}
					>
						Atualizar Termos <Icon type='retweet' />
					</Button>,
				]}
			/>

			<Container>
				<Card style={{ marginTop: 16 }}>
					<Table
						columns={columns}
						dataSource={terms}
						size='middle'
						pagination={{ size: 'large' }}
						rowKey='_id'
						style={{ border: 'none' }}
					/>
				</Card>
			</Container>

			<Modal
				width={700}
				onCancel={() => setShowModals({ ...showModals, details: false })}
				title={`Termos de uso versão ${term?.version}.0`}
				footer={null}
				visible={showModals?.details}
			>
				<DecoratedText text={term?.content} style={{ textAlign: 'justify' }} />
			</Modal>

			<Modal
				width={700}
				centered
				onCancel={() => setShowModals({ ...showModals, update: false })}
				title={`Atualizar termos`}
				okButtonProps={{ loading: fallback?.updatingTerms, icon: 'save' }}
				cancelButtonProps={{ disabled: fallback?.updatingTerms, icon: 'close-circle' }}
				okText='Atualizar Termos'
				onOk={handleUpdate}
				visible={showModals?.update}
			>
				<Spin spinning={fallback?.fetchCurrentTerms ? true : false} tip='Buscando termos atuais...'>
					<Input.TextArea
						rows={22}
						value={currentTerm?.content}
						onChange={({ target: { value } }) => setCurrentTerm({ ...currentTerm, content: value })}
					/>
				</Spin>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(Terms);
