import styled, { css } from 'styled-components';

export const ScriptBody = styled.ul`
	width: 100%;
`;

export const ElementContainer = styled.li`
	width: 100%;
	list-style: none;
	display: flex;
	margin-bottom: ${({ isHided }) => (isHided ? 0 : '4px')};

	${({ isHided }) =>
		isHided &&
		css`
			height: 0 !important;

			& * {
				opacity: 0 !important;
				height: 0 !important;
				pointer-events: none !important;
			}
		`}

	& > button {
		margin-left: 4px;
		width: 50px;
		height: 50px;
	}

	${({ isNewBlock }) =>
		isNewBlock &&
		css`
			margin-top: 16px;
		`}

	&:first-child {
		margin-top: 0 !important;
	}
`;

export const RadioVignetteOpContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	& > div {
		margin-top: -5px;
		display: flex;
		gap: 12px;
	}
`;

export const ElementContent = styled.div`
	background: ${({ color }) => color};
	height: ${({ compact }) => (compact ? '30px' : '50px')};
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0px 0px 0px 1px
		${({ color }) => (!color || color.startsWith('#FFF') ? '#b7b7b7' : color)};
	border: 1px solid #fff;
	padding: 0 12px;
	border-radius: var(--border-radius-base);
	cursor: ${({ _disabled }) => (_disabled ? 'not-allowed' : 'grab')};
	transition: height 300ms;

	${({ isInactive }) =>
		isInactive &&
		css`
			opacity: 0.2;
		`}

	& > div.element-infos {
		display: flex;
		align-items: center;
		gap: 16px;

		& > svg {
			font-size: 20px;
		}

		& > div.placeholder {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;

			& > p {
				font-size: 13px;
				font-weight: 600;
				margin: 0;
			}

			& > span.option {
				font-size: 12px;
				margin-top: -2px;
				opacity: 0.9;
			}
		}
	}

	& > span.index-label {
		font-size: 12px;
		color: #fff;
		opacity: 0.8;
	}

	& * {
		color: ${({ color }) => (!color || color.startsWith('#FFF') ? '#040404' : '#FFF')};
	}

	${({ _disabled }) =>
		_disabled &&
		css`
			opacity: 0.5;
		`}
`;
