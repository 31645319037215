import styled from 'styled-components';

export const ProgressContainer = styled.div`
	width: 100%;
	align-items: center;
	align-content: center;
	display: flex;
	flex-direction: column;
	margin-bottom: 12px;

	& > header {
		width: 100%;
		display: flex;
		align-items: center;

		& > span {
			text-transform: uppercase;
			font-weight: 500;
			font-size: 13px;
			margin-right: 12px;
		}

		& > small {
			font-size: 11px;
		}
	}
`;
