import React, { useCallback } from 'react';
import { Divider, Modal, Progress, Typography } from 'antd';
import { ProgressContainer } from './styles';

const EpisodeDownloadProgress = ({ isVisible, blocks }) => {
	const renderSpeed = useCallback((speed, completed) => {
		if (!speed || completed >= 100) {
			return '';
		}

		const symbol = speed >= 1000 ? 'MB/s' : 'KB/s';
		const value = speed >= 1000 ? Number(speed / 1000).toFixed(1) : speed;
		return `${value} ${symbol}`;
	}, []);

	return (
		<Modal visible={isVisible} header={null} closeIcon={null} closable={false} footer={null}>
			<Typography.Text>Baixando episódio, por favor aguarde...</Typography.Text>

			<Divider />

			{blocks.map(({ completed, speed }, index) => (
				<ProgressContainer key={index}>
					<header>
						<span>Bloco {String(index + 1).padStart(2, '0')}</span>
						<small>{renderSpeed(speed, completed)}</small>
					</header>
					<Progress percent={completed} />
				</ProgressContainer>
			))}
		</Modal>
	);
};

export default EpisodeDownloadProgress;
