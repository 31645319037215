import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    body, html {
    margin: 0;
    padding: 0;
    background-color: #000000;
    color: #cfcfcf;
    }
`;

export const Container = styled.div`
	max-width: 77rem;
	margin: 0 auto;
	padding: 1rem;
`;

export const Title = styled.h1`
	font-size: 2.875rem;
	font-weight: bold;

	color: #fff;
`;

export const Paragraph = styled.p`
	margin-bottom: 2rem;
	color: #82828a;
	&.text-center {
		text-align: center;
	}
`;

export const FlexCenter = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 2rem;
`;

export const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const FlexItemsCenter = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
`;

export const FlexItem = styled.div`
	width: 0.25rem;
	height: 2rem;
	background-color: #17181e;
	margin-bottom: 0.5rem;
	&.black-vertical {
		width: 0.25rem;
		height: 5rem;
		background-color: #17181e;
	}
`;

export const Divider = styled.div`
	width: 8rem;
	height: 0.25rem;
	background-color: #17181e;
	margin: 0 0.5rem;
`;

export const MaintenanceBox = styled.div`
	padding: 1rem;
	border-radius: 0.375rem;
	&.Laranja {
		background-color: #ffa500;
		color: white;
	}
	&.Azul {
		background-color: #007bff;
		color: white;
	}
	&.Verde {
		background-color: #474eb2;
		color: white;
	}
	&.Verde-escuro {
		background-color: #006400;
		color: white;
	}
`;

export const TrafficFlowText = styled.div`
	font-size: 0.975rem;
	margin-top: 0.5rem;
	color: #82828a;
`;
