import React from 'react';
import {
	Section,
	Title,
	ListItem,
	GetStartedTitle,
	SecundaryButton,
	TercearyButton,
	QuintryButton,
	QuartryButton,
	GetStartedText,
} from './styles';
import { PlayIcon } from './icons';

export default function EditProduceDeliverSection() {
	return (
		<Section>
			<Title style={{ color: '#7F10EE' }}>3. Programação, Edição, Produção. </Title>
			<ul>
				<GetStartedTitle>PRODUZINDO O SEU CONTEÚDO</GetStartedTitle>
				<ListItem>
					<GetStartedText>
						Efetua a execução da tarefa, produzindo o programa de ate 5 horas em 7 minutos{' '}
					</GetStartedText>
				</ListItem>
				<ListItem>
					<ListItem>
						<TercearyButton>IA Programadora</TercearyButton>
						<p></p> <PlayIcon className='w-5 h-5 mr-2 inline-block' />
						<QuintryButton>IA Locutora </QuintryButton>
						<p></p> <PlayIcon className='w-5 h-5 mr-2 inline-block' />
						<SecundaryButton>IA Editora</SecundaryButton>
						<p></p> <PlayIcon className='w-5 h-5 mr-2 inline-block' />
						<QuartryButton> IA Produtora </QuartryButton>
					</ListItem>
				</ListItem>
				<GetStartedText>
					TalkPlay é programada para obedecer exatamente o que o usuário deseja, sem desvios.
				</GetStartedText>
			</ul>
		</Section>
	);
}
