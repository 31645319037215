import React, { useState, useEffect, useCallback } from 'react';
import { Container } from './styles';

const DecoratedText = ({ text, ...rest }) => {
	const [decoratedText, setDecoratedText] = useState([]);

	const decorate = useCallback((text) => {
		const normalizedText = text.concat(' ');
		let startBold = null;
		let endBold = null;
		let startItalic = null;
		let endItalic = null;
		let commom = '';

		let currentVerification = null;

		const array = [];

		for (let i = 0; i < normalizedText.length; i++) {
			if (startBold !== null && endBold !== null) {
				const bold = normalizedText.substring(startBold, endBold);

				array.push({ type: 'C', content: commom });
				array.push({ type: 'B', content: `${bold}&` });

				commom = '';
				startBold = null;
				endBold = null;
				currentVerification = null;
			}

			if (startItalic !== null && endItalic !== null) {
				const italic = normalizedText.substring(startItalic, endItalic);

				array.push({ type: 'C', content: commom });
				array.push({ type: 'I', content: `${italic}*` });

				commom = '';
				startItalic = null;
				endItalic = null;
				currentVerification = null;
			}

			if (normalizedText.charAt(i) === '&' && currentVerification !== '*') {
				currentVerification = '&';

				if (startBold === null) {
					startBold = i;
				} else {
					endBold = i;
				}
			}

			if (normalizedText.charAt(i) === '*' && currentVerification !== '&') {
				currentVerification = '*';

				if (startItalic === null) {
					startItalic = i;
				} else {
					endItalic = i;
				}
			}

			if (startBold === null && endBold === null && startItalic === null && endItalic === null) {
				commom = commom.concat(normalizedText.charAt(i));
			}
		}

		return [...array, { type: 'C', content: commom }];
	}, []);

	const isItalicWord = (word) => {
		const normalizedWord = word.replace(/&/g, '');

		if (normalizedWord[0] === '*' && normalizedWord[normalizedWord.length - 1] === '*') {
			return true;
		}

		return false;
	};

	const isBoldWord = (word) => {
		const normalizedWord = word.replace(/\*/g, '');

		if (normalizedWord[0] === '&' && normalizedWord[normalizedWord.length - 1] === '&') {
			return true;
		}

		return false;
	};

	const verifyWordDecoration = (word, type) => {
		if (typeof word !== 'string') {
			return false;
		}

		return type === 'bold' ? isBoldWord(word) : isItalicWord(word);
	};

	useEffect(() => {
		if (!text) {
			return setDecoratedText([]);
		}

		return setDecoratedText(decorate(text));
	}, [text, decorate]);

	if (decoratedText.length === 0) {
		return null;
	}

	return (
		<Container {...rest}>
			{decoratedText.map((word, index) => {
				if (word.type === 'B') {
					return (
						<strong
							key={index}
							style={{
								fontStyle: verifyWordDecoration(word.content, 'italic') ? 'italic' : 'none',
							}}
						>
							{word.content.replace(/[&*]/g, '')}
						</strong>
					);
				}

				if (word.type === 'I') {
					return (
						<span
							key={index}
							style={{
								fontStyle: 'italic',
								fontWeight: verifyWordDecoration(word.content, 'bold') ? 'bold' : 'normal',
							}}
						>
							{word.content.replace(/[&*]/g, '')}
						</span>
					);
				}

				return word.content;
			})}
		</Container>
	);
};

export default DecoratedText;
