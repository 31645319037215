import styled, { keyframes } from 'styled-components';

const bar1Anim = keyframes`
    0% {
        height: 8px;
    }

    50% {
        height: 19px;
    }

    100% {
        height: 8px;
    }
`;

const bar2Anim = keyframes`
    0% {
        height: 15px;
    }

    40% {
        height: 11px;
    }

    80% {
        height: 17px;
    }

    100% {
        height: 15px;
    }
`;

const bar3Anim = keyframes`
    0% {
        height: 17px;
    }

    50% {
        height: 0px;
    }

    100% {
        height: 17px;
    }
`;

const bar4Anim = keyframes`
    0% {
        height: 12px;
    }

    50% {
        height: 17px;
    }

    100% {
        height: 12px;
    }
`;

export const Container = styled.div`
	display: flex;
	align-items: center;

	& > div.icon-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 8px;
		width: 30px;
		height: 100%;
	}

	& svg._icon {
		font-size: 25px;
		color: rgba(0, 0, 0, 0.45);
		cursor: pointer;
		transition: 100ms;

		&:hover {
			color: var(--primary);
			transform: scale(1.2);
		}
	}

	& > div.infos {
		display: flex;
		flex-direction: column;
		height: 100%;
		margin-left: 8px;

		& > p {
			margin-bottom: 0;
			font-size: 14px;
			color: ${({ isPlaying }) => (isPlaying ? 'var(--primary)' : 'rgba(0, 0, 0, 0.85)')};
		}

		& > span {
			font-size: 12px;
			font-weight: 500;
			color: rgba(0, 0, 0, 0.45);
		}
	}
`;

export const Animation = styled.div`
	display: flex;
	align-items: flex-end;

	& > div {
		width: 3px;
		margin-right: 1px;
		background: var(--primary);
	}

	.bar-1 {
		animation: ${bar1Anim} 1.2s infinite linear;
	}

	.bar-2 {
		animation: ${bar2Anim} 0.8s infinite linear;
	}

	.bar-3 {
		animation: ${bar3Anim} 1s infinite linear;
	}

	.bar-4 {
		animation: ${bar4Anim} 0.7s infinite linear;
	}

	.bar-5 {
		animation: ${bar2Anim} 0.9s infinite linear;
	}
`;
