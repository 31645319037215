const userTypesDescriptions = [
	{
		value: 'ADMIN',
		name: 'Administrador',
		description: '(Possui acesso ao painel administrativo completo)',
	},
	{
		value: 'TALK',
		name: 'Conta Talk',
		description: '(Conta de produção com permissões da TalkRadio)',
	},
	{ value: 'COMMON', name: 'Conta Padrão', description: '(Conta de usuário padrão)'},
	{
		value: 'ELEMENTS-MANAGER',
		name: 'Gerenciador de Elementos',
		description: '(Possui acesso aos elementos, modelos e templates no painel administrativo)',
	},
	{
		value: 'SIGNATURES-MANAGER',
		name: 'Gerenciador de Assinaturas',
		description: '(Possui acesso à curadoria das assinaturas)',
	},
	{
		value: 'TRACKS-MANAGER',
		name: 'Gerenciador do Banco Musical',
		description: '(Controla o banco de músicas)',
	},
	{
		value: 'SELLER-MANAGER',
		name: 'Vendedor',
		description: '(Tem acesso às requisições de contato feitas a partir da homepage)',
	},
	{ value: 'CASTER', name: 'Locutor', description: '(Pode enviar chamadas e sáidas musicais)'},
];

export default userTypesDescriptions