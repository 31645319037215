import React from 'react';
import { Container, Header } from './styles';

import { IoPlayCircle, IoPauseCircle } from 'react-icons/io5';

const Player = ({ src, title, icon: HeaderIcon, ...rest }) => {
	return (
		<Container
			{...rest}
			src={src}
			showJumpControls={false}
			autoPlayAfterSrcChange={false}
			customIcons={{
				play: <IoPlayCircle />,
				pause: <IoPauseCircle />,
			}}
			header={
				<Header>
					<span>{title}</span>
					<HeaderIcon />
				</Header>
			}
		/>
	);
};

export default Player;
