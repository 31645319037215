import React from 'react';
import { Container, Header, Main, Footer,  Title } from './styles';
import Producers from './Producers';
import Creators from './Creators';
import Brands from './Brands';


export default function Component() {
	return (
		<Container>
			<Header>
				<Title>CRIADO PARA VOCÊ</Title>
			</Header>
			<Main>
				<Producers />
				<Creators />
				<Brands />
			</Main>
			<Footer>
			
			</Footer>
		</Container>
	);
}
