import React, { useState, useEffect, useCallback, useRef } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import axios from 'axios';
import JSZip from 'jszip';
import * as Yup from 'yup';
import path from 'path';
import { connect } from 'react-redux';

import {
	Table,
	Menu,
	Dropdown,
	Button,
	Icon,
	Divider,
	Select,
	Modal,
	Typography,
	DatePicker,
	Tag,
	Breadcrumb,
	message,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import FileDuration from '../../../../components/FileDuration';
import FileExt from '../../../../components/FileExt';
import PageSizeHandler from '../../../../components/PageSizeHandle';
import PlayCell from '../../../../components/PlayCell';
import Form from '../../../../components/Form';
import { Container, OptionContent, TableHeader } from './styles';

import BlitzAPI from '../../../../services/sdks/blitz';
import ProgramsAPI from '../../../../services/sdks/programs';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';

import { useElement, usePlayer, useDownload, useFilesValidator } from '../../../../hooks';
import { FiDownload, FiPlusCircle, FiTrash2, FiUploadCloud, FiXCircle } from 'react-icons/fi';
import FilesUploader from '../../../../components/FilesUploader';

const Blitz = ({ user }) => {
	const player = usePlayer();
	const download = useDownload();
	const uploaderRef = useRef();
	const { hasValidationError } = useFilesValidator();

	const {
		categorizedTypes: { blitz: categories },
		parseElementName,
	} = useElement();

	const [fallback, setFallback] = useState({ initialData: false });
	const [blitz, setBlitz] = useState([]);
	const [selectedBlitz, setSelectedBlitz] = useState([]);
	const [programs, setPrograms] = useState([]);
	const [visibleModals, setVisibleModals] = useState({ addBlitz: false });
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [newBlitz, setNewBlitz] = useState({
		category: undefined,
		programs: [],
		releaseDate: null,
		files: [],
	});

	const [filters, setFilters] = useState({
		program: undefined,
		category: undefined,
		releaseDate: null,
	});

	const columns = [
		{
			title: 'Categoria',
			key: 'category',
			render: (blitz) => (
				<PlayCell
					meta={{
						primary: `BLITZ REDE - ${blitz.category.split('-')[1].trim()}`,
						secondary: blitz?.program?.name,
					}}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: blitz?.fileName }),
							ref: blitz?._id,
							meta: {
								name: `BLITZ REDE - ${blitz.category.split('-')[1].trim()}`,
								artist: blitz?.program?.name,
							},
						});
					}}
					onPause={player.resume}
					isPlaying={player?.ref === blitz?._id && player?.isPlaying}
				/>
			),
		},

		{
			title: 'Data de Lançamento',
			dataIndex: 'releaseDate',
			align: 'center',
			key: 'date',
		},
		{
			title: 'Duração',
			align: 'center',
			key: 'duration',
			render: (blitz) => <FileDuration src={resolveFileSrc({ fileName: blitz?.filename })} />,
		},
		{
			title: 'Formato',
			align: 'center',
			key: 'ext',
			render: (blitz) => <FileExt src={resolveFileSrc({ fileName: blitz?.filename })} />,
		},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					8
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'options',
			render: (blitz) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									key='play'
									onClick={() => {
										player.start({
											src: resolveFileSrc({ fileName: blitz?.filename }),
											ref: blitz?._id,
											meta: {
												artist: `BLITZ REDE - ${blitz.category.split('-')[1].trim()}`,
												name: blitz?.program?.name,
											},
										});
									}}>
									<Icon type='play-circle' /> Reproduzir
								</Menu.Item>
								<Menu.Item key='download' onClick={() => handleDownload({ blitz })}>
									<Icon type='download' /> Fazer download
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item
									key='delete'
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: 'Excluir off?',
											icon: 'exclamation-circle',
											content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											okText: 'Excluir',
											onOk: () => handleDelete({ blitzId: blitz?._id }),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<Icon type='delete' /> Excluir
								</Menu.Item>
							</Menu>
						}>
						<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const handleDownload = useCallback(
		async ({ blitz }) => {
			try {
				const name = `BLITZ REDE - ${blitz.category.split('-')[1].trim()}`;

				return download({ filename: blitz?.filename, name });
			} catch (error) {
				console.error(error);
				message.error('Não foi possível fazer o download.');
			}
		},
		[download]
	);

	const handleDownloadAsZIP = useCallback(async () => {
		setFallback((prev) => ({ ...prev, multiDownload: true }));

		const zip = new JSZip();

		for (let index = 0; index < selectedBlitz.length; index++) {
			const blitz = selectedBlitz[index];
			const filePath = resolveFileSrc({ fileName: blitz?.filename });
			const blob = await axios.get(filePath, { responseType: 'blob' });
			const name = `BLITZ REDE - ${blitz.category.split('-')[1].trim()}`;
			const ext = path.extname(blitz?.filename);
			const fName = `${blitz?.program?.name} - ${name}${ext}`;

			zip.file(fName, blob.data, { binary: true });
		}

		const zipContent = await zip.generateAsync({ type: 'blob' });
		const selectedProgram = programs.find(({ _id }) => _id === filters?.program);

		let downloadName = 'BLITZ REDE';

		selectedProgram && (downloadName = `${downloadName} - ${selectedProgram.name}`);
		filters?.releaseDate && (downloadName = `${downloadName} - ${filters.releaseDate}`);

		saveAs(zipContent, downloadName);
		setFallback((prev) => ({ ...prev, multiDownload: false }));
	}, [programs, selectedBlitz, filters]);

	const handleDelete = useCallback(async ({ blitzId }) => {
		try {
			setFallback((prev) => ({ ...prev, deleting: true }));

			await BlitzAPI.destroy({ blitzId });

			setFallback((prev) => ({ ...prev, deleting: false }));
			setBlitz((blitz) => blitz.filter(({ _id }) => _id !== blitzId));
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao deletar a blitz');

			setFallback((prev) => ({ ...prev, deleting: false }));
		}
	}, []);

	const handleMultiDelete = useCallback(async () => {
		for (let index = 0; index < selectedBlitz.length; index++) {
			const { _id: blitzId } = selectedBlitz[index];
			await handleDelete({ blitzId });
		}

		setSelectedBlitz([]);
	}, [selectedBlitz, handleDelete]);

	const uploadBlitz = useCallback(
		(file, programId) => {
			const payload = new FormData();

			payload.append('category', newBlitz.category);
			payload.append('releaseDate', newBlitz.releaseDate);
			payload.append('program', programId);
			payload.append('user', user?._id);
			payload.append('isLocal', false);
			payload.append('file', file.data);

			return BlitzAPI.store({
				payload,
				onUploadProgress: ({ total, loaded }) => {
					file.upload.update(Math.floor((loaded * 100) / total));
				},
			});
		},
		[newBlitz, user]
	);

	const createProgramBlitz = useCallback(
		async (programId) => {
			const programName = programs.find((p) => p._id === programId)?.name ?? '';
			message.loading(`Enviando blitz do programa ${programName}`, 4);
			await Promise.all(newBlitz.files.map((file) => uploadBlitz(file, programId)));
		},
		[newBlitz.files, programs, uploadBlitz]
	);

	const handleCreateBlitz = useCallback(async () => {
		try {
			if (hasValidationError(newBlitz.files)) {
				return message.warning(
					'Alguns dos arquivos selecionados estão corrompidos. Por favor, substitua-os por arquivos válidos.'
				);
			}

			const validationSchema = Yup.object().shape({
				category: Yup.string().required('Informe a categoria'),
				releaseDate: Yup.string().required('Informe a data de lançamento'),
				files: Yup.array().required('Selecione no mínimo 1 arquivo'),
			});

			await validationSchema.validate(newBlitz);
			setFallback((prev) => ({ ...prev, uploadingBlitz: true }));

			for (const program of newBlitz.programs) {
				await createProgramBlitz(program);
			}

			message.success('Blitz enviados com sucesso', 3, () => {
				window.location.reload();
			});
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}
		}
	}, [newBlitz, createProgramBlitz, hasValidationError]);

	useEffect(() => {
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters]);

	useEffect(() => {
		const fetchBlitz = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingBlitz: true }));

				let query = `isLocal=false&page=${pagination?.current - 1}&limit=${
					pagination?.pageSize
				}&user=${user?._id}&`;

				filters?.program && (query = `${query}program=${filters.program}&`);
				filters?.releaseDate && (query = `${query}releaseDate=${filters.releaseDate}&`);
				filters?.category && (query = `${query}category=${filters.category}&`);

				const {
					data: { blitz, total },
				} = await BlitzAPI.index({ query });

				setBlitz(blitz);
				setPagination((prev) => ({ ...prev, total }));
				setFallback((prev) => ({ ...prev, fetchingBlitz: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as blitz');
			}
		};

		fetchBlitz();
	}, [filters, pagination.current, pagination.pageSize]); //eslint-disable-line

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { programs },
				} = await ProgramsAPI.index(`userId=${user?._id}&isDeleted=false`);

				setPrograms(
					programs.sort((x, y) => {
						return x?.isFavorited === y?.isFavorited ? 0 : x?.isFavorited ? -1 : 1;
					})
				);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os programas');
			}
		};

		if (user) {
			fetchInitialData();
		}
	}, [user]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Blitz' />

			<Container>
				<Breadcrumb
					style={{ marginBottom: 12 }}
					separator='>'
					routes={[
						{ breadcrumbName: 'INÍCIO' },
						{ breadcrumbName: 'VOZES' },
						{ breadcrumbName: 'BLITZ REDE' },
					]}
				/>

				<header>
					<Typography.Title level={2}>Blitz rede</Typography.Title>
					<Button
						type='primary'
						onClick={() => setVisibleModals({ ...visibleModals, addBlitz: true })}>
						<FiPlusCircle /> Adicionar blitz
					</Button>
				</header>

				<Form.Container layout='30% 30% 30%'>
					<Form.Item label='Filtrar por programa'>
						<Select
							showSearch
							value={filters.program}
							placeholder='Programa'
							onChange={(value) => setFilters({ ...filters, program: value })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							<Select.Option key='all' value={undefined} _search='TODOS'>
								TODOS
							</Select.Option>

							{programs.map((program) => (
								<Select.Option
									key={program._id}
									disabled={!program.isActive}
									_search={program?.name}>
									<OptionContent>
										<Icon
											theme='filled'
											type='heart'
											style={{
												color: 'var(--danger)',
												marginRight: 8,
												opacity: program?.isFavorited ? 1 : 0,
												pointerEvents: 'none',
											}}
										/>
										<span>
											{program?.name}
											{!program?.isActive && <Tag color='red'>Inadimplente</Tag>}
										</span>
									</OptionContent>
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por categoria'>
						<Select
							showSearch
							value={filters.category}
							placeholder='Categoria'
							onChange={(category) => setFilters({ ...filters, category })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							<Select.Option key='all' value={undefined} _search='TODOS'>
								TODOS
							</Select.Option>

							{categories.map((category) => (
								<Select.Option value={category} key={category}>
									BLITZ REDE - {category.split('-')[1].trim()}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Filtrar por data'>
						<DatePicker
							placeholder='Data de lançamento'
							format='DD/MM/yyyy'
							value={filters?.releaseDate && moment(filters.releaseDate, 'DD/MM/yyyy')}
							onChange={(_, releaseDate) => setFilters({ ...filters, releaseDate })}
						/>
					</Form.Item>
				</Form.Container>

				<Divider />

				<TableHeader>
					<div className='actions'>
						<span>
							Quantidade: <strong>{pagination?.total}</strong>
						</span>
						<div>
							<Button
								size='small'
								disabled={!selectedBlitz.length}
								type='ghost'
								onClick={handleDownloadAsZIP}
								loading={fallback?.multiDownload}>
								<FiDownload /> Baixar selecionados{' '}
								{selectedBlitz.length !== 0 && `(${selectedBlitz.length})`}
							</Button>

							<Button
								size='small'
								disabled={!selectedBlitz.length}
								type='danger'
								onClick={() => {
									Modal.confirm({
										title: 'Deletar blitz selecionadas?',
										type: 'danger',
										content:
											'Todos as blitz selecionados serão excluídos e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
										okText: 'Deletar',
										onOk: handleMultiDelete,
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<FiTrash2 /> Deletar selecionados{' '}
								{selectedBlitz.length !== 0 && `(${selectedBlitz.length})`}
							</Button>
						</div>
					</div>

					<PageSizeHandler pagination={pagination} setPagination={setPagination} />
				</TableHeader>

				<Table
					loading={fallback?.fetchingBlitz}
					size='middle'
					rowKey='_id'
					columns={columns}
					dataSource={blitz}
					style={{ border: 'none' }}
					pagination={{
						...pagination,
						size: 'large',
						onChange: (current) => setPagination({ ...pagination, current }),
					}}
					rowSelection={{
						onChange: (_, selectedRows) => setSelectedBlitz(selectedRows),
					}}
				/>
			</Container>

			<Modal
				width={600}
				visible={visibleModals?.addBlitz}
				onCancel={() => setVisibleModals({ ...visibleModals, addBlitz: false })}
				onOk={handleCreateBlitz}
				okButtonProps={{
					loading: fallback?.uploadingBlitz,
					disabled: !newBlitz?.files.length || !newBlitz?.category || !newBlitz?.programs.length,
				}}
				cancelButtonProps={{ disabled: fallback?.uploadingBlitz }}
				okText={
					<>
						<FiUploadCloud /> Enviar blitz
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				title={
					<>
						<FiPlusCircle /> Adicionar blitz
					</>
				}>
				<Form.Container>
					<Form.Item label='Categoria'>
						<Select
							showSearch
							value={newBlitz?.category}
							placeholder='Categoria'
							style={{ marginBottom: 8, width: '100%' }}
							onChange={(category) => setNewBlitz({ ...newBlitz, category })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							{categories.map((category) => (
								<Select.Option
									key={category}
									value={category}
									_search={parseElementName({ type: category })}>
									BLITZ REDE - {category.split('-')[1].trim()}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Programas'>
						<Select
							showSearch
							mode='multiple'
							value={newBlitz.programs}
							placeholder='Programas'
							style={{ marginBottom: 8, width: '100%' }}
							onChange={(programs) => setNewBlitz({ ...newBlitz, programs })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							{programs.map((program) => (
								<Select.Option
									key={program._id}
									disabled={!program.isActive}
									_search={program?.name}>
									<OptionContent>
										<Icon
											theme='filled'
											type='heart'
											style={{
												color: 'var(--danger)',
												marginRight: 8,
												opacity: program?.isFavorited ? 1 : 0,
												pointerEvents: 'none',
											}}
										/>
										<span>
											{program?.name}
											{!program?.isActive && <Tag color='red'>Inadimplente</Tag>}
										</span>
									</OptionContent>
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label='Data de lançamento'>
						<DatePicker
							placeholder='Data de lançamento'
							format='DD/MM/yyyy'
							style={{ width: '100%' }}
							value={newBlitz?.releaseDate && moment(newBlitz.releaseDate, 'DD/MM/yyyy')}
							onChange={(_, releaseDate) => setNewBlitz({ ...newBlitz, releaseDate })}
						/>
					</Form.Item>

					<FilesUploader
						multiple
						ref={uploaderRef}
						onChange={(files) => setNewBlitz((prev) => ({ ...prev, files }))}
						validTypes={['.mp3', '.wav']}
					/>
				</Form.Container>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(Blitz);
