import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Menu } from 'antd';

import { LinkContainer } from './styles';
import { FiSliders, FiEdit3, FiLayout, FiCast, FiMic } from 'react-icons/fi';

const ElementsManagerNavigation = ({ collapsed }) => {
	const renderMenuTitle = useCallback(
		(title, Icon, to) =>
			to ? (
				<Link to={to}>
					{collapsed ? (
						<>
							<Icon style={{ fontSize: 18, marginBottom: -4 }} />
						</>
					) : (
						<LinkContainer>
							<Icon /> {title}
						</LinkContainer>
					)}
				</Link>
			) : collapsed ? (
				<>
					<Icon style={{ fontSize: 18, marginBottom: -4 }} />
				</>
			) : (
				<LinkContainer>
					<Icon /> {title}
				</LinkContainer>
			),
		[collapsed]
	);

	return (
		<Menu theme='dark' mode='inline' style={{ border: 'none' }}>
			<Menu.SubMenu key='general' title={renderMenuTitle('Geral', FiSliders)}>
				<Menu.Item key='active-users'>
					<Link to='/admin/general/categories'>Categorias Musicais</Link>
				</Menu.Item>
				<Menu.Item key='inactive-users'>
					<Link to='/admin/general/genres'>Gêneros</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.SubMenu key='namings' title={renderMenuTitle('Nomenclaturas', FiEdit3)}>
				<Menu.Item key='tkplay-config'>
					<Link to='/admin/general/tkplay-config'>Nomenclatura TKPLAY</Link>
				</Menu.Item>
				<Menu.Item key='ai-voices-config'>
					<Link to='/admin/general/ai-voices-config'>Nomenclatura IA</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.SubMenu key='templates' title={renderMenuTitle('Templates', FiLayout)}>
				<Menu.Item key='script-templates'>
					<Link to='/admin/templates/script-templates'>Templates de Modelo</Link>
				</Menu.Item>
				<Menu.Item key='script-templates'>
					<Link to='/admin/templates/block-templates'>Templates de Bloco</Link>
				</Menu.Item>
				<Menu.Item key='playlist-script-templates'>
					<Link to='/admin/templates/playlist-script-templates'>Templates de Playlist</Link>
				</Menu.Item>
				<Menu.Item key='VIGNETTE-TEMPLATEs'>
					<Link to='/admin/templates/vignettes'>Vinhetas de Template</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.SubMenu key='contents' title={renderMenuTitle('Conteúdos', FiCast)}>
				<Menu.Item key='generation-schedules'>
					<Link to='/admin/contents/generation-schedules'>Cronogramas de Geração</Link>
				</Menu.Item>

				<Divider style={{ marginTop: 5, marginBottom: 5, opacity: 0.1 }} />

				<Menu.Item key='create-sctipt'>
					<Link to='/admin/contents/scripts/create-script'>Criar Modelo</Link>
				</Menu.Item>
				<Menu.Item key='edit-script'>
					<Link to='/admin/contents/scripts/edit-script'>Editar Modelo</Link>
				</Menu.Item>

				<Divider style={{ marginTop: 5, marginBottom: 5, opacity: 0.1 }} />

				<Menu.Item key='manage-elements'>
					<Link to='/admin/contents/elements'>Gerenciar Elementos</Link>
				</Menu.Item>
				<Menu.Item key='manage-tk-voices'>
					<Link to='/admin/contents/tk-voices'>Gerenciar IA's</Link>
				</Menu.Item>
				<Menu.Item key='signatures'>
					<Link to='/admin/contents/signatures'>Assinaturas de Rádio</Link>
				</Menu.Item>
				<Menu.Item key='offs'>
					<Link to='/admin/contents/offs'>Gerenciar Offs</Link>
				</Menu.Item>

				<Divider style={{ marginTop: 5, marginBottom: 5, opacity: 0.1 }} />

				<Menu.Item key='weekly-scripts'>
					<Link to='/admin/contents/playlists/weekly-scripts'>Modelos Semanais</Link>
				</Menu.Item>
			</Menu.SubMenu>

			<Menu.Item key='casters'>{renderMenuTitle('Locutores', FiMic, '/admin/casters')}</Menu.Item>
		</Menu>
	);
};

export default ElementsManagerNavigation;
