import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { format, isAfter } from 'date-fns';
import {
	PageHeader,
	Table,
	Card,
	Icon,
	Modal,
	Typography,
	Input,
	message,
	Button,
	Tag,
	Dropdown,
	Menu,
	DatePicker,
} from 'antd';

import Meta from '../../../../components/Meta';
import Form from '../../../../components/Form';
import Fallback from '../../../../components/Fallback';
import { Container } from './styles';

import BannersApi from '../../../../services/sdks/banners';
import moment from 'moment';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Início' },
		{ breadcrumbName: 'Geral' },
		{ breadcrumbName: 'Mensagens' },
	],
	style: { marginBottom: 12 },
};

const Banners = () => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [banners, setBanners] = useState([]);
	const [page, setPage] = useState(0);
	const [showUpsertModal, setShowUpsertModal] = useState(false);
	const [banner, setBanner] = useState(null);
	const isFormValid = useMemo(() => {
		if (!banner?.title || !banner?.message || !banner?.expirationDate) {
			return false;
		}

		return true;
	}, [banner]);

	const columns = [
		{
			title: 'ID',
			dataIndex: '_id',
			key: 'id',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					5
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Título',
			key: 'title',
			dataIndex: 'title',
		},
		{
			title: 'Mensagem',
			key: 'message',
			dataIndex: 'message',
			render: (message) => (message.length <= 50 ? message : `${message.split(0, 50)}...`),
		},
		{
			title: 'Expiração',
			dataIndex: 'expirationDate',
			key: 'expiration',
			render: (expirationDate) => format(new Date(expirationDate), 'dd/MM/yyyy'),
		},
		{
			title: 'Status',
			dataIndex: 'expirationDate',
			align: 'center',
			key: 'status',
			render: (expirationDate) => {
				const isExpired = isAfter(new Date(), new Date(expirationDate));
				return <Tag color={isExpired ? 'red' : 'green'}>{isExpired ? 'Expirada' : 'Vigente'}</Tag>;
			},
		},
		{
			title: 'Ações',
			key: 'actions',
			align: 'right',
			render: (banner) => (
				<Dropdown
					placement='bottomLeft'
					overlay={
						<Menu>
							<Menu.Item
								key='details'
								onClick={() => {
									setBanner(banner);
									setShowUpsertModal(true);
								}}>
								<Icon type='edit' /> Editar
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								key='delete'
								className='ant-dropdown-menu-item-danger'
								onClick={() => {
									Modal.confirm({
										title: 'Excluir mensagem?',
										icon: 'exclamation-circle',
										content:
											'Essa ação não poderá ser revertida, realmente deseja excluir essa mensagem?',
										okText: 'Excluir',
										onOk: () => handleDeleteBanner(banner?._id),
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<Icon type='delete' /> Excluir
							</Menu.Item>
						</Menu>
					}>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 12 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleDeleteBanner = useCallback(async (bannerId) => {
		try {
			await BannersApi.deleteBanner(bannerId);

			setBanners((prev) => prev.filter((banner) => banner?._id !== bannerId));

			return message.success('Mensagem excluída com sucesso');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, []);

	const handleUpsertBanner = useCallback(async () => {
		setFallback((prev) => ({ ...prev, upsert: true }));

		try {
			const isUpdate = !!banner?._id;
			const payload = {
				title: banner.title,
				message: banner.message,
				expirationDate: banner.expirationDate,
			};

			if (isUpdate) {
				await BannersApi.updateBanner(banner._id, payload);
				setBanners((prev) => prev.map((b) => (b._id === banner._id ? { ...b, ...payload } : b)));
			} else {
				const {
					data: { banner },
				} = await BannersApi.createBanner(payload);
				setBanners((prev) => [...prev, banner]);
			}

			setBanner(null);
			setShowUpsertModal(false);
			message.success('Mensagem criada/atualizada com sucesso');
		} catch (error) {
			message.error('Error ao criar/atualizar a mensagem');
		}

		setFallback((prev) => ({ ...prev, upsert: false }));
	}, [banner]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				setFallback((prev) => ({ ...prev, initialData: true }));

				const {
					data: { banners },
				} = await BannersApi.getAllBanners(page);

				setBanners(banners);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar as mensagens, tente novamente');
			}
		};

		fetchInitialData();
	}, [page]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Mensagens' />

			<PageHeader
				title='Mensagens'
				breadcrumb={breadcrumb}
				extra={[
					<Button
						key='create-banner'
						type='ghost'
						size='large'
						icon='plus-circle'
						onClick={() => {
							setShowUpsertModal(true);
							setBanner({});
						}}>
						Nova Mensagem
					</Button>,
				]}>
				<Typography.Text>Mensagens que aparecem para os usuários da TalkPlay</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<Table
						size='middle'
						rowKey='_id'
						columns={columns}
						dataSource={banners}
						style={{ border: 'none' }}
						pagination={{
							size: 'large',
							pageSize: 10,
							hideOnSinglePage: true,
							onChange: (page) => setPage(page),
						}}
					/>
				</Card>
			</Container>

			<Modal
				title={
					<>
						<Icon type='file-text' style={{ marginRight: 8 }} /> {banner?._id ? 'Editar' : 'Criar'}{' '}
						Mensagem
					</>
				}
				width={600}
				visible={showUpsertModal}
				okButtonProps={{ disabled: !isFormValid, loading: fallback?.upsert }}
				okText={`${banner?._id ? 'Editar' : 'Criar'} Mensagem`}
				onOk={handleUpsertBanner}
				onCancel={() => {
					setShowUpsertModal(false);
					setBanner(null);
				}}>
				<Form.Container layout='1fr'>
					<Form.Item label='Título'>
						<Input
							placeholder='Informe o título da mensagem'
							value={banner?.title}
							onChange={(e) => setBanner({ ...banner, title: e.target.value })}
						/>
					</Form.Item>
					<Form.Item label='Conteúdo da Mensagem'>
						<Input.TextArea
							placeholder='Informe o conteúdo da mensagem'
							value={banner?.message}
							onChange={(e) => setBanner({ ...banner, message: e.target.value })}
						/>
					</Form.Item>
					<Form.Item
						label='Data de Expiração Automática'
						help='Após essa data a mensagem não ficará mais disponível para os usuários'>
						<DatePicker
							placeholder='Data de lançamento'
							format='DD/MM/yyyy'
							defaultValue={banner?.expirationDate && moment(banner?.expirationDate)}
							onChange={(d) =>
								setBanner({ ...banner, expirationDate: d ? d._d.toISOString() : null })
							}
						/>
					</Form.Item>
				</Form.Container>
			</Modal>
		</>
	);
};

export default Banners;
