import React, { useCallback, useMemo } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { LinkContainer } from './styles';
import { commonNavigationMap } from '../../constants/navigations/common';
import { getNavigationByResources } from '../../helpers/getNavigationByResources';

const CommomNavigation = ({ collapsed, user }) => {
	const renderMenuTitle = useCallback(
		(title, Icon, to) =>
			to ? (
				<Link to={to}>
					{collapsed ? (
						<>
							<Icon style={{ fontSize: 18, marginBottom: -4 }} />
						</>
					) : (
						<LinkContainer>
							<Icon /> {title}
						</LinkContainer>
					)}
				</Link>
			) : collapsed ? (
				<>
					<Icon style={{ fontSize: 18, marginBottom: -4 }} />
				</>
			) : (
				<LinkContainer>
					<Icon /> {title}
				</LinkContainer>
			),
		[collapsed]
	);

	const navigation = useMemo(() => getNavigationByResources(commonNavigationMap, user), [user]);
console.log(navigation)
	return (
		<Menu theme='dark' mode='inline' style={{ border: 'none' }}>
			{navigation.map((menuItem) =>
				menuItem.type === 'MENU' ? (
					<Menu.Item key={menuItem.label} title={menuItem.label}>
						{renderMenuTitle(menuItem.label, menuItem.icon, menuItem.link)}
					</Menu.Item>
				) : (
					<Menu.SubMenu key={menuItem.label} title={renderMenuTitle(menuItem.label, menuItem.icon)}>
						{menuItem.items.map((subItem) => (
							<Menu.Item key={subItem.label}>
								<Link to={subItem.link}>{subItem.label}</Link>
							</Menu.Item>
						))}
					</Menu.SubMenu>
				)
			)}
		</Menu>
	);
};

export default connect(({ user }) => ({ user }))(CommomNavigation);
