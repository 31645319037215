import React from 'react';
import { Typography, Divider, Alert } from 'antd';

import Meta from '../../../../components/Meta';
import HelpButtons from '../../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../../assets/images/tutorials/signatures/img-01.png';
import img02 from '../../../../assets/images/tutorials/signatures/img-02.png';
import img03 from '../../../../assets/images/tutorials/signatures/img-03.png';
import img04 from '../../../../assets/images/tutorials/signatures/img-04.png';
import img08 from '../../../../assets/images/tutorials/signatures/img-08.PNG';


const { Title, Paragraph } = Typography;

const SignaturesRadio = () => (
	<Container>
		<Meta title='Ajuda - Assinaturas Rádio e Músicas' />

		<Title>Vinhetas de Assinaturas Rádio </Title>

		<Paragraph>
			Assinaturas são arquivos de áudio que irão carimbar todas algumas músicas músicas, tando os
			programas próprios, quanto os programas compartilhados.
		</Paragraph>

		<Paragraph>
			Acesse através do item{' '}
			<strong>
				<i>Assinaturas</i>
			</strong>
			, no menu lateral.
		</Paragraph>

		<ImgContainer>
			<img src={img01} alt='Assinaturas' />
		</ImgContainer>

		<Paragraph>
			Nessa página você irá encontrar dois tipos de assinaturas:{' '}
			<strong>
				<i>Vinhetas de Assinatura</i>
			</strong>{' '}
			e{' '}
			<strong>
				<i>Assinatura de Músicas</i>. 
			</strong>
			</Paragraph>
		

		<Paragraph>
			Existe ainda um terceiro tipo de assinatura, a{' '}
			<strong>
				<i>Assinatura de Programa</i>
			</strong>
			, que é criada exclusivamente pela equipe TalkRadio para sua emissora e para cada um dos seus
			programas. Você só conseguirá posicionar essa assinatura em modelos de{' '}
			<strong>
				<i>Programas Próprios</i>
			</strong>
			.
		</Paragraph>
		<Paragraph>
			   As Assinatura de Músicas está disponível apenas com um <strong>VALOR ADICIONAL MENSAL</strong>. Consulte a equipe TalkRadio para mais informações.
		</Paragraph>
		<Divider />

		<Title level={3}>Vinhetas de Assinatura</Title>

		<Paragraph>
			São vinhetas curtas que possuem o intuito de carimbar o conteúdo com a marca da sua emissora.
			São inseridas em passagens, por exemplo entre um <i>OFF</i> e uma <i>MÚSICA</i>. Você pode
			cadastrar quantas vinhetas de assinatura desejar.
		</Paragraph>

		<Paragraph>
			Para criar vinhetas de assinatura, acesse a página e clique em <i>Criar Assinaturas</i>.
		</Paragraph>

		<ImgContainer>
			<img src={img02} alt='Assinaturas' />
		</ImgContainer>

		<Paragraph>
			Em seguida, certifique-se de selecionar a aba <i>Vinhetas de Assinatura</i>, informe o gênero
			da(s) assinatura(s), selecione os arquivos de áudio e clique em <strong>Salvar</strong> e
			aguarde o upload e configuração das assinaturas.
		</Paragraph>

		<ImgContainer>
			<img src={img03} alt='Assinaturas' />
		</ImgContainer>

		<Paragraph>
			O sistema tentará inserir assinaturas nos programas cujos gêneros sejam iguais. No caso de não
			haver assinaturas com o mesmo gênero do programa que está sendo produzido, o sistema irá
			inserir assinaturas de forma aleatória.
		</Paragraph>

		<Paragraph>
			Para posicionar assinaturas no modelo , vá em <strong>Editar modelo</strong> e selecione a aba{' '}
			<strong>Assinaturas</strong> clique no módulo e arraste ele para a posição desejada.
		</Paragraph>

		<ImgContainer>
			<img src={img04} alt='Assinaturas' />
		</ImgContainer>

		<Paragraph>
			Para posicionar assinaturas no bloco, selecione os <strong>3 pontinhos do BLOCO</strong> clique em{' '}
			<strong>Adicionar Assinaturas</strong>, selecione na aba <strong>Assinatura - Rádio</strong>, selecione a quantidade e clique em adicionar.
		</Paragraph>
		<ImgContainer>
			<img src={img08} alt='Assinaturas' />
		</ImgContainer>

		<Paragraph>
			Depois basta clicar nos elementos que deseja adicionar ao modelo e em seguida posicioná-los.
			Você pode ainda adicionar assinaturas em um bloco específico.
		</Paragraph>
		<Alert
			showIcon
			style={{ marginBottom: 16 }}
			type='warning'
			description='Para conseguir gerar programas, você precisa ter no mínimo uma Vinheta de Assinatura cadastrada.'
		/>
		<Divider />

		<Divider />

		<HelpButtons
			prev={{
				label: 'Programa Compartilhado',
				to: '/help/programs/program-details/shared',
			}}
			next={{ label: 'Trilhas Sonoras', to: '/help/soundtracks' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default SignaturesRadio;
