import api from '../api';

const BASE_URL = '/api/v2/wpps';

const WppsAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	show: ({ wppId }) => api.get(`${BASE_URL}/${wppId}`),
	store: ({ payload, onUploadProgress }) => api.post(BASE_URL, payload, { onUploadProgress }),
	update: ({ wppId, payload }) => api.put(`${BASE_URL}/${wppId}`, payload),
	updateGlobalFlag: ({ payload }) => api.patch(`${BASE_URL}/`, payload),
	destroy: ({ wppId }) => api.delete(`${BASE_URL}/${wppId}`),
};

export default WppsAPI;
