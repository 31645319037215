export const DEFAULT_PERSONAL_INFOS = {
	name: '',
	surname: '',
	radioName: '',
	email: '',
	cpf: '___.___.___-__',
	cnpj: '__.___.___/____-__',
	phone: '(__) _ ____-____',
	city: '',
	state: undefined,
};

export const DEFAULT_ACCOUNT_INFOS = {
	type: undefined,
	withLiveContent: undefined,
	caster: undefined,
	intranetId: '',
	paymentDay: undefined,
	password: '',
	passwordConfirmation: '',
};

export const DEFAULT_PERMISSIONS = {
	RADIO_SIGNATURE: 'OPTIONAL',
	TRACK_SIGNATURE: false,
	NOCTURNAL_GENERATION: false,
	AUTO_DOWNLOAD: false,
	MAX_GENERATIONS_PER_PROGRAM: 2,
	MASTERIZATION: 'OPTIONAL',
	PROGRAMS_LIMIT: 5,
	MAX_BLOCKS_PER_PROGRAM: 9,
	MAX_MUSICS_PER_BLOCK: 5,
	PROGRAMS_SHARING: false,
};

export const DEFAULT_MODULES = (defaultValues) => {
	return {
		tracks: {
			TRACKS_LOCAL: defaultValues || false,
			TRACKS_GLOBAL: defaultValues || false,
			TRACKS_DOWNLOAD: defaultValues || false,
			PLAYLIST: defaultValues || false,
		},
		news: {
			NEWS_MAIN: defaultValues || false,
			NEWS_LOCAL: defaultValues || false,
			NEWS_GLOBAL: defaultValues || false,
			NEWS_SHARING: defaultValues || false,
		},
		wpps: {
			WPP_MAIN: defaultValues || false,
			WPP_LOCAL: defaultValues || false,
			WPP_GLOBAL: defaultValues || false,
			WPP_SHARING: defaultValues || false,
		},
		interviews: {
			INTERVIEW_MAIN: defaultValues || false,
			INTERVIEW_LOCAL: defaultValues || false,
			INTERVIEW_GLOBAL: defaultValues || false,
			INTERVIEW_SHARING: defaultValues || false,
		},
		offs: {
			OFFS_MAIN: defaultValues || false,
			OFFS_LOCAL: defaultValues || false,
			TK_VOX: defaultValues || false,
			TK_PLAY: defaultValues || false,
			IA_LOCAL: defaultValues || false,
			IA_GLOBAL: defaultValues || false,
			SOUNDTRACK: defaultValues || false,
		},
		ads: {
			SPONSOR: defaultValues || false,
			OFFERING: defaultValues || false,
			CASHTRACK: defaultValues || false,
			TESTIMONIAL: defaultValues || false,
			TRACK_AD: defaultValues || false,
			CUSTOMS: defaultValues || false,
		},
		blitz: {
			BLITZ_MAIN: defaultValues || false,
			BLITZ_LOCAL: defaultValues || false,
			BLITZ_GLOBAL: defaultValues || false,
			BLITZ_SHARING: defaultValues || false,
		},
		other: {
			TAG: defaultValues || false,
			SIGNATURES: defaultValues || false,
		},
		vignettes: {
			VIGNETTES_RADIO_SCHEDULED: defaultValues || false,
			VIGNETTES_RADIO_CONTINUOUS: defaultValues || false,
			VIGNETTES_PROGRAM: defaultValues || false,
		},
	};
};
