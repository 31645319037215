import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import {
	PageHeader,
	Card,
	Button,
	Icon,
	Modal,
	Select,
	Tabs,
	Typography,
	Progress,
	Divider,
	message,
	Tag,
} from 'antd';

import Meta from '../../../components/Meta';
import Fallback from '../../../components/Fallback';
import { Container, FormContainer, OptionContent, SelectContainer } from './styles';

import ElementsAPI from '../../../services/sdks/elements';
import ProgramsAPI from '../../../services/sdks/programs';
import { useFilesValidator } from '../../../hooks';
import FilesUploader from '../../../components/FilesUploader';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Início' },
		{ breadcrumbName: 'Vinhetas' },
		{ breadcrumbName: 'Adicionar Vinhetas' },
	],
	style: { marginBottom: 12 },
};

const INITIAL_VIGNETTES = {
	programIntro: {
		label: 'Vinhetas de Introdução de Programa',
		type: 'VIGNETTE-PROGRAM-INTRO',
		files: [],
	},
	programOutro: {
		label: 'Vinhetas de Encerramento de Programa',
		type: 'VIGNETTE-PROGRAM-OUTRO',
		files: [],
	},
	blockIntro: {
		label: 'Vinhetas de Introdução de Bloco',
		type: 'VIGNETTE-BLOCK-INTRO',
		files: [],
	},
	blockOutro: {
		label: 'Vinhetas de Saída de Bloco',
		type: 'VIGNETTE-BLOCK-OUTRO',
		files: [],
	},
	break: {
		label: 'Vinhetas de Passagem',
		type: 'VIGNETTE-BREAK',
		files: [],
	},
};

const INITIAL_PROGRESS_AND_VALIDATION = {
	programIntro: null,
	programOutro: null,
	blockIntro: null,
	blockOutro: null,
	break: null,
};

const CreateVignettes = ({ user }) => {
	const { search } = useLocation();
	const { hasValidationError } = useFilesValidator();
	const [fallback, setFallback] = useState({ initialData: true });
	const [program, setProgram] = useState(undefined);
	const [programs, setPrograms] = useState([]);

	const [vignettes, setVignettes] = useState(INITIAL_VIGNETTES);
	const [progress, setProgress] = useState(INITIAL_PROGRESS_AND_VALIDATION);
	const [validations, setValidations] = useState(INITIAL_PROGRESS_AND_VALIDATION);

	const validateVignetes = useCallback(() => {
		for (const key in vignettes) {
			const { label, files } = vignettes[key];

			if (hasValidationError(files)) {
				message.warning(
					`Algumas ${label} selecionadas estão corrompidos. Por favor, substitua os arquivos inválidos.`
				);

				return false;
			}
		}

		return true;
	}, [hasValidationError, vignettes]);

	const createPromise = useCallback(
		async (key) => {
			const { type, files } = vignettes[key];

			if (!files.length) {
				return null;
			}

			const payload = new FormData();

			payload.append('type', type);
			payload.append('category', 'VIGNETTE');
			payload.append('userId', user?._id);
			payload.append('programId', program);
			files.forEach((file, i) => payload.append(`file-${i}`, file.data));

			await ElementsAPI.store({
				payload,
				onUploadProgress: ({ total, loaded }) => {
					setProgress((prev) => ({
						...prev,
						[key]: Math.floor((loaded * 100) / total),
					}));
				},
			});
		},
		[program, user, vignettes]
	);

	const handleUploadVignettes = useCallback(async () => {
		const isValid = validateVignetes();

		if (!isValid) {
			return;
		}

		try {
			setFallback((prev) => ({ ...prev, uploading: true }));

			await Promise.allSettled(
				Object.keys(vignettes)
					.map((key) => createPromise(key))
					.filter((p) => p !== null)
			);

			message.success('Uploads das vinhetas realizado com sucesso!', 3, () =>
				window.location.reload()
			);
		} catch (error) {
			setFallback((prev) => ({ ...prev, uploading: false }));
			message.error('Houve um erro, tente novamente');
		}
	}, [validateVignetes, vignettes, createPromise]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const { program: programId } = parse(search);

				if (programId) {
					const {
						data: { program },
					} = await ProgramsAPI.show(programId);

					setPrograms([program]);
					setProgram(program?._id);
					setFallback((prev) => ({ ...prev, initialData: false }));
				} else {
					const {
						data: { programs },
					} = await ProgramsAPI.index(`userId=${user?._id}&isDeleted=false`);

					setPrograms(
						programs.sort((x, y) => {
							return x?.isFavorited === y?.isFavorited ? 0 : x?.isFavorited ? -1 : 1;
						})
					);
					setFallback((prev) => ({ ...prev, initialData: false }));
				}
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os programas');
			}
		};

		fetchInitialData();
	}, [search, user]);

	const isUploadButtonDisabled = useMemo(() => {
		if (!program) {
			return true;
		}

		if (Object.values(vignettes).every((v) => v.files.length === 0)) {
			return true;
		}

		if (Object.values(validations).some((v) => !!v)) {
			return true;
		}

		return false;
	}, [program, validations, vignettes]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Adicionar vinhetas' />

			<PageHeader title='Adicionar Vinhetas' breadcrumb={breadcrumb}>
				<Typography.Text>
					{search
						? 'Para adicionar novas vinhetas, selecione os arquivos de áudio das categorias correspondentes'
						: 'Para adicionar novas vinhetas, informe o programa e em seguida selecione os arquivos de áudio das categorias correspondentes'}
				</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<FormContainer>
						<SelectContainer>
							<Select
								size='large'
								showSearch
								style={{ width: 450 }}
								value={program}
								disabled={!!search}
								onChange={(value) => setProgram(value)}
								placeholder='Informe o programa'
								filterOption={(input, { props: { _search } }) => {
									return _search.match(new RegExp(input, 'i'));
								}}>
								{programs.map((program) => (
									<Select.Option
										disabled={!program?.isActive}
										key={program?._id}
										value={program?._id}
										_search={program?.name}>
										<OptionContent>
											<Icon
												theme='filled'
												type='heart'
												style={{
													color: 'var(--danger)',
													marginRight: 8,
													opacity: program?.isFavorited ? 1 : 0,
													pointerEvents: 'none',
												}}
											/>
											<span>
												{program?.name}
												{!program?.isEditable ? (
													<Tag color='red'>Não editável</Tag>
												) : (
													!program?.isActive && <Tag color='red'>Inadimplente</Tag>
												)}
											</span>
										</OptionContent>
									</Select.Option>
								))}
							</Select>
						</SelectContainer>

						<Divider />

						<Tabs size='small' tabPosition='left'>
							{Object.entries(vignettes).map(([key, { files, label, type }]) => (
								<Tabs.TabPane key={key} tab={`${files.length} ${label}`}>
									<FilesUploader
										multiple
										validTypes={['.mp3', '.wav']}
										onStartValidation={() => setValidations((prev) => ({ ...prev, [key]: true }))}
										onEndValidation={() => setValidations((prev) => ({ ...prev, [key]: false }))}
										onChange={(files) =>
											setVignettes((prev) => ({ ...prev, [key]: { label, type, files } }))
										}
									/>
								</Tabs.TabPane>
							))}
						</Tabs>

						<Divider />

						<Button
							size='large'
							type='primary'
							icon='plus-circle'
							onClick={handleUploadVignettes}
							disabled={isUploadButtonDisabled}>
							Fazer upload das vinhetas
						</Button>
					</FormContainer>
				</Card>
			</Container>

			<Modal
				title={
					<>
						<Icon type='cloud-upload' style={{ marginRight: 8 }} />
						Fazendo upload
					</>
				}
				footer={null}
				visible={fallback?.uploading}
				closable={false}>
				{Object.entries(vignettes)
					.filter(([, { files }]) => files.length)
					.map(([key, { label }]) => (
						<React.Fragment key={key}>
							<Typography.Text>{label}...</Typography.Text>
							<Progress percent={progress[key] ?? 0} showInfo />
							<Divider />
						</React.Fragment>
					))}
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(CreateVignettes);
