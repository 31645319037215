import styled from 'styled-components';
import H5Player from 'react-h5-audio-player';

export const Container = styled.div`
	width: calc(100% - (48px));
	position: fixed;
	bottom: ${({ visibility }) => {
		switch (visibility) {
			case 'hidden':
				return '-100%';
			case 'full':
				return '24px';
			case 'minimized':
				return '-12%';
			default:
				return '0';
		}
	}};
	left: 24px;
	border-radius: var(--border-radius-base);
	transition: 300ms;
	background: #ffffff;
	padding: 0 24px 12px 24px;
	display: flex;
	border: 1px solid #e8e8e8;
	z-index: 1000000;
	box-shadow: 0px 0px 20px 6px #06187533;
`;

export const Meta = styled.div`
	display: flex;
	align-items: center;
	flex: 0.25;
	background: transparent;

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: center;

		& > p {
			font-size: 14px;
			margin: 0;
			color: rgba(0, 0, 0, 0.7);
		}

		& > span {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.55);
		}
	}

	& svg {
		margin-left: 24px;
		font-size: 18px;
		cursor: pointer;
		margin-bottom: -5px;
		color: rgba(0, 0, 0, 0.65);

		&:hover {
			color: var(--primary);
		}
	}
`;

export const HeaderMeta = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	background: transparent;

	& > p {
		font-size: 15px;
		margin: 0;
		color: rgba(0, 0, 0, 0.7);

		& > span {
			color: rgba(0, 0, 0, 0.55);
		}
	}
`;

export const StyledPlayer = styled(H5Player)`
	flex: 1;
	box-shadow: none !important;
	width: 100%;
	flex-direction: column-reverse !important;
	background: transparent !important;

	& .rhap_progress-section {
		padding: 0 20%;
	}

	.rhap_progress-indicator {
		width: 15px;
		height: 15px;
		top: -5px;
		visibility: hidden;
	}

	.rhap_progress-container:hover {
		.rhap_progress-indicator {
			visibility: visible;
			background: #8400bb;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.rhap_time {
		font-size: 13px;
		color: rgba(0, 0, 0, 0.55);
	}

	.rhap_volume-indicator {
		background: var(--primary);
	}

	.rhap_progress-bar {
		background: var(--primary-opacity-65);
	}

	.rhap_download-progress {
		background: var(--primary-opacity-10);
	}

	.rhap_progress-filled {
		background: var(--primary);
	}

	.rhap_volume-bar {
		background: var(--primary);
	}
`;

export const PlayerHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: ${({ visibility }) => (visibility === 'minimized' ? '0' : '8px')};
	height: ${({ visibility }) => (visibility === 'minimized' ? '40px' : 'auto')};
	background: transparent;

	& > div.actions {
		display: flex;

		& svg {
			font-size: 16px;
			cursor: pointer;
			color: rgba(0, 0, 0, 0.55);
			margin-left: 8px;
			cursor: pointer;

			&:hover {
				color: rgba(0, 0, 0, 0.85);
			}
		}
	}
`;
