import api from '../api';

const BASE_URL = '/api/v2/playlists';

const PlaylistsAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	generate: (payload) => api.post(`${BASE_URL}/generate-playlist`, payload),
	upload: (payload, options) => api.post(`${BASE_URL}/upload-playlist`, payload, { ...options }),
};

export default PlaylistsAPI;
