import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;

	& ul {
		margin-left: 32px;
	}

	& p.ps {
		font-size: 13px;
		font-style: italic;
		opacity: 0.8;
	}
`;

export const ImgContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 24px 0;

	& > img {
		width: 90%;
		border: 1px solid #dedede;
		border-radius: var(--border-radius-base);
	}
`;
