import api from '../api';

const BASE_URL = '/api/v2/radio-vignette-configs';

const RadioVignetteConfigsAPI = {
	list: (userId, pageNumber, pageSize) =>
		api.get(`${BASE_URL}?userId=${userId}&page=${pageNumber}&limit=${pageSize}`),
	create: (payload) => api.post(BASE_URL, { ...payload }),
	update: (configId, payload) => api.put(`${BASE_URL}/${configId}`, { ...payload }),
	delete: (ids) => api.delete(BASE_URL, { data: { ids } }),
};

export default RadioVignetteConfigsAPI;
