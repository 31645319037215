import api from '../api';

const BASE_URL = '/api/v2/networks';

const NetworksAPI = {
	list: (filters) => api.get(`${BASE_URL}?${filters}`),
	getOneById: (id) => api.get(`${BASE_URL}/${id}`),
	getOneByUserId: (userId) => api.get(`${BASE_URL}/user/${userId}`),
	updateOne: (id, data) => api.put(`${BASE_URL}/${id}`, data),
};

export default NetworksAPI;
