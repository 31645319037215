import api from '../api';

const BASE_URL = '/api/v2/episodes';

const EpisodesApi = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	show: ({ episodeId }) => api.get(`${BASE_URL}/${episodeId}`),
};

export default EpisodesApi;
