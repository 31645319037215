import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
	PageHeader,
	Table,
	Button,
	Dropdown,
	Menu,
	Typography,
	Modal,
	Icon,
	Card,
	message,
} from 'antd';

import Meta from '../../../../../components/Meta';
import Fallback from '../../../../../components/Fallback';
import { Container } from './styles';
import ScriptTemplatesAPI from '../../../../../services/sdks/scriptTemplates';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Templates' },
		{ breadcrumbName: 'Templates de Modelo' },
	],
	style: { marginBottom: 12 },
};

const ManageScriptTemplates = () => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [templates, setTemplates] = useState([]);
	const history = useHistory();
	const columns = [
		{
			key: 'id',
			title: 'ID',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					10
				)}...`}</Typography.Text>
			),
		},
		{
			key: 'name',
			title: 'Nome',
			render: ({ name }) => <strong>{name}</strong>,
		},
		{
			key: 'actions',
			title: 'Ações',
			align: 'center',
			render: (template) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									key='edit'
									onClick={() =>
										history.push(`/admin/templates/script-templates/${template._id}/update`)
									}
								>
									<Icon type='edit' /> Editar template
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item
									key='delete'
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: 'Excluir template?',
											icon: 'exclamation-circle',
											content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											okText: 'Excluir template',
											onOk: () => handleDeleteTemplate(template._id),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}
								>
									<Icon type='delete' /> Excluir template
								</Menu.Item>
							</Menu>
						}
					>
						<Icon style={{ cursor: 'pointer', fontSize: 20 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const handleDeleteTemplate = useCallback(async (templateId) => {
		try {
			await ScriptTemplatesAPI.delete({ id: templateId });
			setTemplates((templates) => templates.filter(({ _id }) => _id !== templateId));
			message.success('Template excluido com sucesso!');
		} catch (error) {
			console.error(error);
			message.error(error);
		}
	}, []);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { templates },
				} = await ScriptTemplatesAPI.getAll();

				setTemplates(templates);
				setFallback(false);
			} catch (error) {
				console.error(error);
				message.error('Erro ao buscar os templates');
			}
		};

		fetchInitialData();
	}, []);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Templates de Modelo' />

			<PageHeader
				title='Templates de Modelo'
				breadcrumb={breadcrumb}
				extra={[
					<Button
						key='1'
						type='ghost'
						size='large'
						icon='plus-circle'
						onClick={() => history.push('/admin/templates/script-templates/create')}
					>
						Criar novo template
					</Button>,
				]}
			>
				<Typography.Text>Templates dos modelos</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<Table
						pagination={{ pageSize: 20, size: 'large', hideOnSinglePage: true }}
						loading={fallback === 'fetching-tracks'}
						rowKey='_id'
						size='middle'
						style={{ border: 'none' }}
						columns={columns}
						dataSource={templates}
					/>
				</Card>
			</Container>
		</>
	);
};

export default ManageScriptTemplates;
