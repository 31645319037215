import React from 'react';
import CKConsent from 'react-cookie-consent';

import { containerStyles, buttonStyles } from './styles';

const CookieConsent = () => {
	return (
		<CKConsent
			location='bottom'
			buttonText='Aceitar todos o cookies'
			cookieName='cookie-consent'
			style={containerStyles}
			buttonStyle={buttonStyles}
			expires={150}
		>
			<span style={{ color: 'rgba(0, 0, 0, .85)' }}>
				Esse site utiliza cookies para melhorar sua experiência.
			</span>
		</CKConsent>
	);
};

export default CookieConsent;
