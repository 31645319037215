import { useCallback, useMemo } from 'react';

const useFormatter = ({ currency = 'BRL', style = 'current' }) => {
	const formatter = useMemo(
		() => new Intl.NumberFormat('pt-br', { style, currency }),
		[style, currency]
	);

	return useCallback((value) => formatter.format(value), [formatter]);
};

export default useFormatter;
	