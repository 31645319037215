import styled from 'styled-components';

export const Container = styled.footer`
	margin-top: 48px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0 16px;
`;

const Button = styled.button`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: var(--border-radius-base);
	background: #fff;
	cursor: pointer;

	& > svg {
		font-size: 22px;
		color: rgba(0, 0, 0, 0.8);
	}

	& > div.text {
		display: flex;
		flex-direction: column;
		justify-content: center;

		& span {
			font-size: 13px;
			color: rgba(0, 0, 0, 0.5);
		}

		& p {
			font-size: 17px;
			color: rgba(0, 0, 0, 0.9);
			font-weight: 500;
			margin: 0;
		}
	}

	&:hover {
		border-color: var(--primary);

		& > svg,
		div.text > p {
			color: var(--primary);
		}
	}
`;

export const PrevButton = styled(Button)`
	& > div.text {
		align-items: flex-end;
	}
`;

export const NextButton = styled(Button)`
	& > div.text {
		align-items: flex-start;
	}
`;

export const VoidButton = styled.div`
	visibility: hidden;
	pointer-events: none;
`;
