import React, { useCallback, useState } from 'react';
import { Modal, Checkbox, Typography, Divider } from 'antd';

import DecoratedText from '../DecoratedText';
import { Container } from './styles';

import UseTermAPI from '../../services/sdks/useTerms';

const Terms = ({ visible, onAccept, onClose, user, term }) => {
	const [fallback, setFallback] = useState({ accepting: false });
	const [accepted, setAccepted] = useState(false);

	const handleAcceptTerm = useCallback(async () => {
		try {
			setFallback((prev) => ({ ...prev, accepting: true }));

			await UseTermAPI.agree(user?._id);

			setAccepted(false);
			setFallback((prev) => ({ ...prev, accepting: false }));

			onAccept();
		} catch (error) {
			console.error(error);
		}
	}, [user, onAccept]);

	return (
		<Modal
			width={760}
			style={{ marginTop: 32 }}
			centered={true}
			visible={visible}
			okText='Aceito os termos'
			cancelText='Fechar'
			onOk={handleAcceptTerm}
			okButtonProps={{
				icon: 'check-circle',
				disabled: !accepted,
				loading: fallback.accepting,
			}}
			cancelButtonProps={{
				icon: 'close-circle',
				disabled: fallback.accepting,
			}}
			onCancel={() => {
				setAccepted(false);
				onClose();
			}}>
			<Container>
				<Typography.Title level={3} style={{ marginTop: 32 }}>
					Termos de uso
				</Typography.Title>

				<Divider />

				<DecoratedText text={term?.content} style={{ textAlign: 'justify' }} />

				<Divider />

				<Checkbox checked={accepted} onChange={({ target: { checked } }) => setAccepted(checked)}>
					Declaro que li, estou ciente e concordo com as condições acima especificadas, sendo que
					manifesto o meu consentimento por meio do Aceite eletrônico destes Termos.
				</Checkbox>
			</Container>
		</Modal>
	);
};

export default Terms;
