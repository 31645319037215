import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: #ffffff;

	& > span {
		border-radius: var(--border-radius-base);
	}

	& > p.title {
		font-size: 32px;
		color: #414141;
		font-weight: 500;
		margin-bottom: 0px;
	}

	& > p.message {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.45);
		margin-bottom: 24px;
	}

	/* & svg {
		color: #fbc02d;
		font-size: 80px;
		margin-bottom: 12px;
	}

	& > p {
		margin: 0;
		font-size: 24px;
		color: #8b8b8b;
	}

	& span > span {
		width: 8px;
		height: 82px;
	} */
`;
