import {
	HiOutlinePaperAirplane,
	HiOutlineUserCircle,
	HiOutlineUserGroup,
	HiOutlineWifi,
} from 'react-icons/hi';

export const talkRadioMenuItems = [
	{
		Icon: HiOutlineUserCircle,
		title: 'Cliente VIP',
		desc: 'Conte com nossa produtora para alavancar a sua rádio',
		href: 'https://talkradio.com.br/#entramos-em-contato',
		isExternal: true,
	},
	{
		Icon: HiOutlineWifi,
		title: 'Programas',
		desc: 'Conheça nossa programação',
		href: 'https://talkradio.com.br/#programas',
		isExternal: true,
	},
	{
		Icon: HiOutlineUserGroup,
		title: 'Parceiros',
		desc: 'Veja quem já utiliza os benefícios Talk Radio +',
		href: 'https://talkradio.com.br/#parceiros',
		isExternal: true,
	},
	{
		Icon: HiOutlinePaperAirplane,
		title: 'Contato',
		desc: 'Entramos em contato com você',
		href: 'https://talkradio.com.br/#entre-em-contato',
		isExternal: true,
	},
];
