import api from '../api';

const BASE_URL = '/api/v2/banners';

const BannersApi = {
	getAllBanners: (page) => api.get(`${BASE_URL}?page=${page}`),
	getUnreadBanners: () => api.get(`${BASE_URL}/unread`),
	createBanner: (payload) => api.post(BASE_URL, payload),
	updateBanner: (bannerId, payload) => api.put(`${BASE_URL}/${bannerId}`, payload),
	createBannerView: (bannerId) => api.post(`${BASE_URL}/${bannerId}/view`),
	deleteBanner: (bannerId) => api.delete(`${BASE_URL}/${bannerId}`),
};

export default BannersApi;
