import React from 'react';
import { connect } from 'react-redux';

import AuthStack from './Auth.routes';
import AdminStack from './Adm.routes';
import CommomStack from './Commom.routes';
import TalkUserStack from './TalkUser.routes';
import ElementsManagerStack from './ElementsManager.routes';
import SignaturesManagerStack from './SignaturesManager.routes';
import TracksDbManagerStack from './TracksDbManager.routes';
import SellerStack from './Seller.routes';
import CasterStack from './Caster.routes';
import { userTypes } from '../constants';

const Routes = ({ user }) => {
	if (!user) {
		return <AuthStack />;
	}

	switch (user?.type) {
		case userTypes.ADMIN:
			return <AdminStack />;
		case userTypes.TALK:
			return <TalkUserStack />;
		case userTypes.COMMON:
			return <CommomStack />;
		case userTypes.ELEMENTS_MANAGER:
			return <ElementsManagerStack />;
		case userTypes.SIGNATURES_MANAGER:
			return <SignaturesManagerStack />;
		case userTypes.TRACKS_MANAGER:
			return <TracksDbManagerStack />;
		case userTypes.SELLER_MANAGER:
			return <SellerStack />;
		case userTypes.CASTER:
			return <CasterStack />;
		default:
			return <AuthStack />;
	}
};

export default connect(({ user }) => ({ user }))(Routes);
