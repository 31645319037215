import React, { useCallback, useMemo, useState } from 'react';
import { Tabs, Typography, Divider, Affix, Input, Empty } from 'antd';
import { connect } from 'react-redux';

import { Container, Element, ElementList, RadioVignetteLabelContainer, TabContent } from './styles';
import { useElement, useScript } from '../../hooks';
import { listElements } from './listElements';
import {
	FiActivity,
	FiDollarSign,
	FiFastForward,
	FiGlobe,
	FiList,
	FiMic,
	FiMusic,
	FiPlay,
	FiRadio,
	FiStopCircle,
	FiUser,
	FiVoicemail,
	FiZap,
} from 'react-icons/fi';
import { checkResource } from '../../helpers/checkResource';
import resourcesKeys from '../../constants/resourcesKeys';
import { VignetteExecutionModesTranslation } from '../../enums/vignette-execution-modes';
import { VignetteSpeechModesTranslation } from '../../enums/vignette-speech-modes';
import { format, parseISO } from 'date-fns';
import { BsRobot } from 'react-icons/bs';
import { AiOutlineWhatsApp } from 'react-icons/ai';

const INITIAL_SEARCH = {
	common: '',
	programVignettes: '',
	radioVignettesContinuous: '',
	radioVignettesScheduled: '',
	customs: '',
	templateVignettes: '',
	ads: '',
	cashTracks: '',
	offsLocal: '',
	tkVox: '',
	tkPlay: '',
	iaLocal: '',
	iaGlobal: '',
	signatures: '',
	newsMain: '',
	newsLocal: '',
	newsGlobal: '',
	blitzMain: '',
	blitzLocal: '',
	interviewMain: '',
	interviewLocal: '',
	interviewGlobal: '',
	wppMain: '',
	wppLocal: '',
	wppGlobal: '',
};

const TabName = ({ icon: Icon, label }) => (
	<TabContent>
		<Icon />
		<small>{label}</small>
	</TabContent>
);

const ElementsTabs = ({ user, isAdmin, tkVoiceTypes, isBlockTemplate, isTemplate }) => {
	const [activeKey, setActiveKey] = useState('common');
	const [search, setSearch] = useState(INITIAL_SEARCH);
	const { elements, addElement, addMerchanElement, script } = useScript();
	const { categorizedTypes, parseElementName, getElementColor, getElementIcon, radioVignettesMap } =
		useElement({
			userId: user._id,
		});

	const parseExecutionMode = useCallback(
		(mode) => VignetteExecutionModesTranslation[mode] ?? mode,
		[]
	);

	const parseSpeechMode = useCallback((mode) => VignetteSpeechModesTranslation[mode] ?? mode, []);

	const adsTabElements = useMemo(() => {
		return elements.merchans.filter((e) => {
			if (e.type === 'SPONSOR') {
				return checkResource(user, resourcesKeys.SPONSOR);
			}

			if (e.type === 'OFFERING') {
				return checkResource(user, resourcesKeys.OFFERING);
			}

			return true;
		});
	}, [elements, user]);

	const handleChangeSearch = useCallback((e) => {
		e.persist && e.persist();
		setSearch((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	}, []);

	const commonElements = useMemo(() => {
		return categorizedTypes.defaultElements.filter((e) => {
			if (e === 'NEW-BLOCK') {
				return !isBlockTemplate;
			}

			if (e === 'TAG') {
				return checkResource(user, resourcesKeys.TAG);
			}

			if (e === 'OFF-MAIN') {
				return checkResource(user, resourcesKeys.OFFS_MAIN);
			}

			if (e === 'TRACK') {
				return checkResource(user, [resourcesKeys.TRACKS_LOCAL, resourcesKeys.TRACKS_GLOBAL], {
					strict: false,
				});
			}

			return true;
		});
	}, [categorizedTypes, isBlockTemplate, user]);

	const tabs = [
		{
			key: 'common',
			isHidden: () => !isAdmin && script.isShared,
			content: (
				<Tabs.TabPane
					key='Elementos Padrão'
					tab={<TabName icon={FiList} label='ELEMENTOS PADRÃO' />}>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.common}
							name='common'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>

						<ul>
							{listElements(search.common, commonElements, (type) =>
								parseElementName({ type })
							).map((type, index) => (
								<Element
									onClick={() => {
										addElement({
											index: null,
											element: {
												type,
												options:
													type === 'OFF-MAIN'
														? { withSoundtrack: true }
														: type === 'TAG'
														? { color: '#FFF', name: '' }
														: null,
											},
										});
									}}
									color={getElementColor({ type })}
									key={index}>
									{getElementIcon({ type })}
									<span>{parseElementName({ type })}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'programVignettes',
			isHidden: () => !isAdmin && !checkResource(user, resourcesKeys.VIGNETTES_PROGRAM),
			content: (
				<Tabs.TabPane
					tab={<TabName icon={FiActivity} label='VHT DO PROGRAMA' />}
					key='Vinhetas do Programa'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.programVignettes}
							name='programVignettes'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>

						<ul>
							{listElements(search.programVignettes, categorizedTypes.programVignettes, (type) =>
								parseElementName({ type })
							).map((type, index) => (
								<Element
									onClick={() => {
										addElement({
											index: null,
											element: {
												type,
												options: null,
											},
										});
									}}
									color={getElementColor({ type })}
									key={index}>
									{getElementIcon({ type })}
									<span>{parseElementName({ type })}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'radioVignettesContinuous',
			isHidden: () => !isAdmin && !checkResource(user, resourcesKeys.VIGNETTES_RADIO_CONTINUOUS),
			content: (
				<Tabs.TabPane
					tab={<TabName icon={FiRadio} label='VHT DA RÁDIO (CONTÍNUAS)' />}
					key='Vinhetas da Rádio (Contínuas)'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.radioVignettesContinuous}
							name='radioVignettesContinuous'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(
								search.radioVignettesContinuous,
								radioVignettesMap.staticTypes,
								(v) => v.label
							).map((v, index) => (
								<Element
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: v.value,
												options: {
													executionMode: v.executionMode,
													speechMode: v.speechMode,
												},
											},
										});
									}}
									color={v.color}
									key={index}>
									<FiRadio />
									<RadioVignetteLabelContainer>
										<span>{v.label}</span>

										<div>
											<small>
												Categoria: <span>{parseSpeechMode(v.speechMode)}</span>
											</small>
											<small>
												Velocidade: <span>{parseExecutionMode(v.executionMode)}</span>
											</small>
										</div>
									</RadioVignetteLabelContainer>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'radioVignettesScheduled',
			isHidden: () => !checkResource(user, resourcesKeys.VIGNETTES_RADIO_SCHEDULED),
			content: (
				<Tabs.TabPane
					tab={<TabName icon={FiRadio} label='VHT DA RÀDIO (CRONOGRAMADAS)' />}
					key='Vinhetas da Rádio (Cronogramadas)'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.radioVignettesScheduled}
							name='radioVignettesScheduled'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(
								search.radioVignettesScheduled,
								radioVignettesMap.scheduledTypes,
								(v) => v.label
							).map((v, index) => (
								<Element
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: 'RADIO-VIGNETTE-CONFIG',
												options: {
													configId: v.value,
													executionMode: v.executionMode,
													speechMode: v.speechMode,
												},
											},
										});
									}}
									color={v.color}
									key={index}>
									<FiRadio />
									<RadioVignetteLabelContainer>
										<span>{v.label}</span>

										<div>
											<small>
												Categoria: <span>{parseSpeechMode(v.speechMode)}</span>
											</small>
											<small>
												Velocidade: <span>{parseExecutionMode(v.executionMode)}</span>
											</small>
										</div>
									</RadioVignetteLabelContainer>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'customs',
			isHidden: () => (!isAdmin && script.isShared) || !checkResource(user, resourcesKeys.CUSTOMS),
			content: (
				<Tabs.TabPane
					tab={<TabName icon={FiStopCircle} label='CUSTOMIZADOS' />}
					key='Elementos Customizados'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.customs}
							name='customs'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{elements.customs.length ? (
								listElements(search.customs, elements.customs, (e) => e.name).map(
									(element, index) => (
										<Element
											color={element.color}
											key={index}
											onClick={() => {
												addElement({
													index: null,
													element: {
														type: 'CUSTOM',
														options: {
															color: element.color,
															elementId: element?._id,
															name: element?.name,
														},
													},
												});
											}}>
											{getElementIcon({ type: element.type })}
											<span>{element.name}</span>
										</Element>
									)
								)
							) : (
								<Empty description='Sem elementos customizados' />
							)}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'templateVignettes',
			isHidden: () => !isAdmin,
			content: (
				<Tabs.TabPane
					tab={<TabName icon={FiFastForward} label='VINHETAS DE TEMPLATE' />}
					key='Vinhetas de Template'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.templateVignettes}
							name='templateVignettes'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{elements.templateVignettes?.length ? (
								listElements(
									search.templateVignettes,
									elements.templateVignettes,

									(e) => e.name
								).map((element, index) => (
									<Element
										color={element.color}
										key={index}
										onClick={() => {
											addElement({
												index: null,
												element: {
													type: 'VIGNETTE-TEMPLATE',
													options: {
														color: element.color,
														elementId: element?._id,
														name: element?.name,
													},
												},
											});
										}}>
										{getElementIcon({ type: 'VIGNETTE-TEMPLATE' })}
										<span>{element.name}</span>
									</Element>
								))
							) : (
								<Empty description='Sem vinhetas cadastradas' />
							)}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'ads',
			isHidden: () => !adsTabElements.length,
			content: (
				<Tabs.TabPane tab={<TabName icon={FiDollarSign} label='PUBLICIDADES' />} key='Publicidades'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.ads}
							name='ads'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.ads, elements.merchans, (e) => e.name).map((element, index) => (
								<Element
									color={element.color ? element.color : getElementColor({ type: element.type })}
									key={index}
									onClick={() => {
										addMerchanElement({
											blockIndex: null,
											element: {
												type: element.type,
												options: {
													name: element?.name,
													color: element.color,
													elementId: element?._id,
												},
											},
										});
									}}>
									{getElementIcon({ type: element.type })}
									<span>{element.name}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'cashTracks',
			isHidden: () => !checkResource(user, resourcesKeys.CASHTRACK),
			content: (
				<Tabs.TabPane tab={<TabName icon={FiMusic} label='MÚSICAS CASH' />} key='Músicas-Cash'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.cashTracks}
							name='cashTracks'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						{elements.cashTracks.length ? (
							<ul>
								{listElements(search.cashTracks, elements.cashTracks, (e) => e.name).map(
									(element, index) => (
										<Element
											color={getElementColor({ type: 'CASHTRACK' })}
											key={index}
											onClick={() => {
												addMerchanElement({
													blockIndex: null,
													element: {
														type: 'CASHTRACK',
														options: {
															elementId: element?._id,
															expirationDate: format(
																parseISO(element?.expirationDate),
																'dd/MM/yyyy'
															),
															name: element?.name,
														},
													},
												});
											}}>
											{getElementIcon({ type: element.type })}
											<span>{element.name}</span>
										</Element>
									)
								)}
							</ul>
						) : (
							<Empty description='Sem músicas-cash' />
						)}
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'offsLocal',
			isHidden: () =>
				!isTemplate &&
				(script?.isShared || !isAdmin || !checkResource(user, resourcesKeys.OFFS_LOCAL)),
			content: (
				<Tabs.TabPane tab={<TabName icon={FiMic} label='OFFS LOCAIS' />} key='Offs Locais'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.offsLocal}
							name='offsLocal'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>

						<ul>
							{listElements(search.offsLocal, categorizedTypes.localOffs, (type) =>
								parseElementName({ type })
							).map((type, index) => (
								<Element
									color={getElementColor({ type })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type,
												options: { withSoundtrack: true },
											},
										});
									}}>
									{getElementIcon({ type })}
									<span>{parseElementName({ type })}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'tkVox',
			isHidden: () =>
				isTemplate ? false : script.isShared || !checkResource(user, resourcesKeys.TK_VOX),
			content: (
				<Tabs.TabPane tab={<TabName icon={FiVoicemail} label='TKVOX' />} key='TkVox'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.tkVox}
							name='tkVox'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.tkVox, categorizedTypes.recordedOffs, (type) =>
								parseElementName({ type })
							).map((type, index) => (
								<Element
									color={getElementColor({ type })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type,
												options: { withSoundtrack: true },
											},
										});
									}}>
									{getElementIcon({ type })}
									<span>{parseElementName({ type })}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'tkPlay',
			isHidden: () =>
				isTemplate ? false : script.isShared || !checkResource(user, resourcesKeys.TK_PLAY),
			content: (
				<Tabs.TabPane tab={<TabName icon={FiPlay} label='TKPLAY' />} key='TkPlay'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.tkPlay}
							name='tkPlay'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.tkPlay, categorizedTypes.tkPlay, (type) =>
								parseElementName({ type })
							).map((type, index) => (
								<Element
									color={getElementColor({ type })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type,
												options: { withSoundtrack: true },
											},
										});
									}}>
									{getElementIcon({ type })}
									<span>{parseElementName({ type })}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'iaLocal',
			isHidden: () => !checkResource(user, resourcesKeys.IA_LOCAL),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<BsRobot style={{ fontSize: 18 }} />
							<small>IA LOCAL</small>
						</TabContent>
					}
					key='IA Local'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.iaLocal}
							name='iaLocal'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.iaLocal, tkVoiceTypes.local, (type) =>
								parseElementName({ type })
							).map((type, index) => (
								<Element
									color={getElementColor({ type, isLocal: true })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type,
												options: { withSoundtrack: true, isLocal: true },
											},
										});
									}}>
									{getElementIcon({ type })}
									<span>IA LOCAL - {parseElementName({ type })}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'iaGlobal',
			isHidden: () => !checkResource(user, resourcesKeys.IA_GLOBAL),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<BsRobot style={{ fontSize: 18 }} />
							<small>IA GLOBAL</small>
						</TabContent>
					}
					key='IA Global'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.iaGlobal}
							name='iaGlobal'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.iaGlobal, tkVoiceTypes.caster, (type) =>
								parseElementName({ type })
							).map((type, index) => (
								<Element
									color={getElementColor({ type, isLocal: false })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type,
												options: { withSoundtrack: true, isLocal: false },
											},
										});
									}}>
									{getElementIcon({ type })}
									<span>IA GLOBAL - {parseElementName({ type })}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'signatures',
			isHidden: () =>
				(!isTemplate && script.isShared) || !checkResource(user, resourcesKeys.SIGNATURES),
			content: (
				<Tabs.TabPane tab={<TabName icon={FiActivity} label='ASSINATURAS' />} key='Assinaturas'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.signatures}
							name='signatures'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.signatures, categorizedTypes.signatures, (type) =>
								parseElementName({ type })
							).map((type, index) => (
								<Element
									color={getElementColor({ type })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type,
												options: null,
											},
										});
									}}>
									{getElementIcon({ type })}
									<span>{parseElementName({ type })}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'newsMain',
			isHidden: () =>
				(!isTemplate && script.isShared) || !checkResource(user, resourcesKeys.NEWS_MAIN),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<FiGlobe />
							<small>NOTÍCIAS REDE</small>
						</TabContent>
					}
					key='Notícias da rede'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.newsMain}
							name='newsMain'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.newsMain, categorizedTypes.news, (type) =>
								parseElementName({ type })
							).map((type, index) => (
								<Element
									color={getElementColor({ type: 'NEWS-MAIN' })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: 'NEWS-MAIN',
												options: {
													withSoundtrack: true,
													category: type,
												},
											},
										});
									}}>
									{getElementIcon({ type: 'NEWS-MAIN' })}
									<span>NOTÍCIA REDE - {parseElementName({ type })}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'newsLocal',
			isHidden: () => !isTemplate && !checkResource(user, resourcesKeys.NEWS_LOCAL),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<FiGlobe />
							<small>NOTÍCIAS LOCAIS</small>
						</TabContent>
					}
					key='Notícias locais'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.newsLocal}
							name='newsLocal'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.newsLocal, categorizedTypes.news, (type) =>
								parseElementName({ type })
							).map((type, index) => (
								<Element
									color={getElementColor({ type: 'NEWS-LOCAL' })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: 'NEWS-LOCAL',
												options: {
													withSoundtrack: true,
													category: type,
												},
											},
										});
									}}>
									{getElementIcon({ type: 'NEWS-LOCAL' })}
									<span>NOTÍCIA LOCAL - {parseElementName({ type })}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'newsGlobal',
			isHidden: () =>
				!isTemplate && (script.isShared || !checkResource(user, resourcesKeys.NEWS_GLOBAL)),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<FiGlobe />
							<small>NOTÍCIAS GLOBAIS</small>
						</TabContent>
					}
					key='Notícias Globais'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.newsGlobal}
							name='newsGlobal'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => setSearch('globalNews', e.target.value)}
						/>
						<ul>
							{listElements(search.newsGlobal, categorizedTypes.news, (type) =>
								parseElementName({ type })
							).map((type, index) => (
								<Element
									color={getElementColor({ type: 'NEWS-GLOBAL' })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: 'NEWS-GLOBAL',
												options: {
													withSoundtrack: true,
													category: type,
												},
											},
										});
									}}>
									{getElementIcon({ type: 'NEWS-GLOBAL' })}
									<span>NOTÍCIA GLOBAL - {parseElementName({ type })}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'blitzMain',
			isHidden: () =>
				(!isTemplate && script.isShared) || !checkResource(user, resourcesKeys.BLITZ_MAIN),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<FiZap />
							<small>BLITZ REDE</small>
						</TabContent>
					}
					key='Blitz rede'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.blitzMain}
							name='blitzMain'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.blitzMain, categorizedTypes.blitz, (type) =>
								type.split('-')[1].trim()
							).map((type, index) => (
								<Element
									color={getElementColor({ type: 'BLITZ-MAIN' })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: 'BLITZ-MAIN',
												options: {
													withSoundtrack: true,
													category: type,
												},
											},
										});
									}}>
									{getElementIcon({ type: 'BLITZ-MAIN' })}
									<span>BLITZ REDE - {type.split('-')[1].trim()}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'blitzLocal',
			isHidden: () => !isTemplate && !checkResource(user, resourcesKeys.BLITZ_LOCAL),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<FiZap />
							<small>BLITZ LOCAIS</small>
						</TabContent>
					}
					key='Blitz locais'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.blitzLocal}
							name='blitzLocal'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.blitzLocal, categorizedTypes.blitz, (type) =>
								type.split('-')[1].trim()
							).map((type, index) => (
								<Element
									color={getElementColor({ type: 'BLITZ-LOCAL' })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: 'BLITZ-LOCAL',
												options: {
													withSoundtrack: true,
													category: type,
												},
											},
										});
									}}>
									{getElementIcon({ type: 'BLITZ-LOCAL' })}
									<span>BLITZ LOCAL - {type.split('-')[1].trim()}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'interviewMain',
			isHidden: () =>
				(!isTemplate && script.isShared) || !checkResource(user, resourcesKeys.INTERVIEW_MAIN),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<FiUser />
							<small>ENTREVISTAS REDE</small>
						</TabContent>
					}
					key='Entrevistas rede'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.interviewMain}
							name='interviewMain'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.interviewMain, categorizedTypes.interviews, (type) =>
								type.split('-')[1].trim()
							).map((type, index) => (
								<Element
									color={getElementColor({ type: 'INTERVIEW-MAIN' })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: 'INTERVIEW-MAIN',
												options: {
													withSoundtrack: true,
													category: type,
												},
											},
										});
									}}>
									{getElementIcon({ type: 'INTERVIEW-MAIN' })}
									<span>ENTREVISTA REDE - {type.split('-')[1].trim()}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'interviewLocal',
			isHidden: () => !isTemplate && !checkResource(user, resourcesKeys.INTERVIEW_LOCAL),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<FiUser />
							<small>ENTREVISTAS LOCAIS</small>
						</TabContent>
					}
					key='Entrevistas locais'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.interviewLocal}
							name='interviewLocal'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.interviewLocal, categorizedTypes.interviews, (type) =>
								type.split('-')[1].trim()
							).map((type, index) => (
								<Element
									color={getElementColor({ type: 'INTERVIEW-LOCAL' })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: 'INTERVIEW-LOCAL',
												options: {
													withSoundtrack: true,
													category: type,
												},
											},
										});
									}}>
									{getElementIcon({ type: 'INTERVIEW-LOCAL' })}
									<span>ENTREVISTA LOCAL - {type.split('-')[1].trim()}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'interviewGlobal',
			isHidden: () =>
				!isTemplate && (script.isShared || !checkResource(user, resourcesKeys.INTERVIEW_GLOBAL)),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<FiUser />
							<small>ENTREVISTAS GLOBAIS</small>
						</TabContent>
					}
					key='Entrevistas Globais'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.interviewGlobal}
							name='interviewGlobal'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.interviewGlobal, categorizedTypes.interviews, (type) =>
								type.split('-')[1].trim()
							).map((type, index) => (
								<Element
									color={getElementColor({ type: 'INTERVIEW-GLOBAL' })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: 'INTERVIEW-GLOBAL',
												options: {
													withSoundtrack: true,
													category: type,
												},
											},
										});
									}}>
									{getElementIcon({ type: 'INTERVIEW-GLOBAL' })}
									<span>ENTREVISTA GLOBAL - {type.split('-')[1].trim()}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'wppMain',
			isHidden: () =>
				(!isTemplate && script.isShared) || !checkResource(user, resourcesKeys.WPP_MAIN),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<AiOutlineWhatsApp />
							<small>WPP's REDE</small>
						</TabContent>
					}
					key='Wpps rede'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.wppMain}
							name='wppMain'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.wppMain, categorizedTypes.wpps, (type) =>
								type.split('-')[1].trim()
							).map((type, index) => (
								<Element
									color={getElementColor({ type: 'WPP-MAIN' })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: 'WPP-MAIN',
												options: {
													withSoundtrack: true,
													category: type,
												},
											},
										});
									}}>
									{getElementIcon({ type: 'WPP-MAIN' })}
									<span>WPP REDE - {type.split('-')[1].trim()}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'wppLocal',
			isHidden: () => !isTemplate && !checkResource(user, resourcesKeys.WPP_LOCAL),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<AiOutlineWhatsApp />
							<small>WPP's LOCAIS</small>
						</TabContent>
					}
					key='Wpps locais'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.wppLocal}
							name='wppLocal'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.wppLocal, categorizedTypes.wpps, (type) =>
								type.split('-')[1].trim()
							).map((type, index) => (
								<Element
									color={getElementColor({ type: 'WPP-LOCAL' })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: 'WPP-LOCAL',
												options: {
													withSoundtrack: true,
													category: type,
												},
											},
										});
									}}>
									{getElementIcon({ type: 'WPP-LOCAL' })}
									<span>WPP LOCAL - {type.split('-')[1].trim()}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
		{
			key: 'wppGlobal',
			isHidden: () =>
				!isTemplate && (script.isShared || !checkResource(user, resourcesKeys.WPP_GLOBAL)),
			content: (
				<Tabs.TabPane
					tab={
						<TabContent>
							<AiOutlineWhatsApp />
							<small>WPP's GLOBAIS</small>
						</TabContent>
					}
					key='Wpps Globais'>
					<ElementList>
						<Input.Search
							allowClear
							defaultValue={search.wppGlobal}
							name='wppGlobal'
							size='large'
							placeholder='Pesquisar elementos'
							style={{ width: '100%' }}
							onChange={(e) => handleChangeSearch(e)}
						/>
						<ul>
							{listElements(search.wppGlobal, categorizedTypes.wpps, (type) =>
								type.split('-')[1].trim()
							).map((type, index) => (
								<Element
									color={getElementColor({ type: 'WPP-GLOBAL' })}
									key={index}
									onClick={() => {
										addElement({
											index: null,
											element: {
												type: 'WPP-GLOBAL',
												options: {
													withSoundtrack: true,
													category: type,
												},
											},
										});
									}}>
									{getElementIcon({ type: 'WPP-GLOBAL' })}
									<span>WPP GLOBAL - {type.split('-')[1].trim()}</span>
								</Element>
							))}
						</ul>
					</ElementList>
				</Tabs.TabPane>
			),
		},
	];

	return (
		<Affix offsetBottom={12}>
			<Container>
				<Typography.Title level={4}>{activeKey}</Typography.Title>

				<Divider style={{ margin: '8px 0' }} />

				<Tabs size='small' onChange={(key) => setActiveKey(key)}>
					{tabs.filter((tab) => !tab.isHidden()).map((tab) => tab.content)}
				</Tabs>
			</Container>
		</Affix>
	);
};

export default connect(({ user }) => ({ user }))(ElementsTabs);
