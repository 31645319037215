import React from 'react';
import { Container } from './styles';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Features from './components/Features';
import TecnologiadeIA from './components/TecnologiadeIA';
import Explication from './components/Explication';
import Tkplay from './components/Tkplay';
import ComponenFeatues from './components/ComponenFeatues';
import { ContactUsForm } from './components/ContactUsForm';
import SalveAudio from './components/SalveAudio';
import Foryou from './components/Foryou';
import Footer from './components/Footer';
import { MobleMenuProvider } from './contexts/MobileMenu';

const LandPage = () => {
	return (
		<Container>
			<MobleMenuProvider>
				<Header />

				<Hero />
				<Tkplay />
				<About />
				<Foryou />
				<SalveAudio />

				<Explication />

				<Features />
	
				<TecnologiadeIA />

				<ComponenFeatues />

				<ContactUsForm />

				<Footer />
			</MobleMenuProvider>
		</Container>
	);
};

export default LandPage;
