const modulesTranslations = {
	ads: 'Publicidades',
	tracks: 'Músicas',
	news: 'Notícias',
	wpps: 'Wpps',
	interviews: 'Entrevistas',
	offs: 'OFFS',
	blitz: 'BLITZ',
	vignettes: 'Vinhetas',
	other: 'Outros',

	SPONSOR: 'Patrocínios',
	OFFERING: 'Oferecimentos',
	CASHTRACK: 'Músicas Cash',
	TESTIMONIAL: 'Testemunhais',
	TRACK_AD: 'Prograpandas Curtas',
	TRACKS_LOCAL: 'Músicas Locais',
	TRACKS_GLOBAL: 'Músicas Globais',
	TRACKS_DOWNLOAD: 'Download de Músicas',
	NEWS_LOCAL: 'Notícias Locais',
	NEWS_GLOBAL: 'Notícias Globais',
	NEWS_SHARING: 'Compartilhamento de Notícias',
	WPP_LOCAL: 'Wpp Locais',
	WPP_GLOBAL: 'Wpp Globais',
	WPP_SHARING: 'Compartilhamento de Wpp',
	INTERVIEW_LOCAL: 'Entrevistas Locais',
	INTERVIEW_GLOBAL: 'Entrevistas Globais',
	INTERVIEW_SHARING: 'Compartilhamento de Entrevistas',
	OFFS_LOCAL: 'Offs Locais',
	TK_VOX: 'TKVOX',
	TK_PLAY: 'TKPLAY',
	IA_LOCAL: 'IA Local',
	IA_GLOBAL: 'IA Global',
	VIGNETTES_RADIO_SCHEDULED: 'Vinhetas da Rádio (Cronogramadas)',
	VIGNETTES_RADIO_CONTINUOUS: 'Vinhetas da Rádio (Contínuas)',
	VIGNETTES_PROGRAM: 'Vinhetas de Programa',

	BLITZ_LOCAL: 'Blitz Local',
	BLITZ_GLOBAL: 'Blitz Global',
	BLITZ_SHARING: 'Compartilhamento de Blitz',

	NEWS_MAIN: 'Notícias Rede',
	WPP_MAIN: 'Wpp Rede',
	INTERVIEW_MAIN: 'Entrevistas Rede',
	BLITZ_MAIN: 'Blitz Rede',
	OFFS_MAIN: 'Offs Rede',

	CUSTOMS: 'Customizados',
	TAG: 'Tags',
	SIGNATURES: 'Assinaturas',
	SOUNDTRACK: 'Trilhas Sonoras',
	PLAYLIST: 'Playlists',
};

export default modulesTranslations;
