import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import axios from 'axios';
import JSZip from 'jszip';
import * as Yup from 'yup';
import path from 'path';
import { connect } from 'react-redux';

import {
	Table,
	Menu,
	Dropdown,
	Button,
	Icon,
	Select,
	Modal,
	Typography,
	DatePicker,
	message,
	Checkbox,
	Tag,
} from 'antd';

import Fallback from '../Fallback';
import FileExt from '../FileExt';
import FileDuration from '../FileDuration';
import PageSizeHandler from '../PageSizeHandle';
import Progress from '../Progress';
import PlayCell from '../PlayCell';
import Form from '../Form';
import { FiltersContainer, TableActions } from './styles';
import WppsAPI from '../../services/sdks/wpps';
import { useElement, usePlayer, useDownload, useFilesValidator } from '../../hooks';
import { FiDownload, FiPlus, FiPlusCircle, FiTrash2, FiUpload, FiXCircle } from 'react-icons/fi';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';
import FilesUploader from '../FilesUploader';

const LocalWpps = ({ user, program }) => {
	const player = usePlayer();
	const download = useDownload();

	const {
		categorizedTypes: { wpps: categories },
	} = useElement();

	const [fallback, setFallback] = useState({ initialData: false });
	const [wpps, setWpps] = useState([]);
	const [selectedWpps, setSelectedWpps] = useState([]);
	const [visibleModals, setVisibleModals] = useState({ addWpps: false });
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });
	const [isRandom, setIsRandom] = useState(false);
	const [progress, setProgress] = useState(null);
	const [filters, setFilters] = useState({ category: undefined, releaseDate: null });
	const [isValidating, setIsValidating] = useState(false);
	const { hasValidationError } = useFilesValidator();
	const [newWpps, setNewWpps] = useState({
		category: undefined,
		releaseDate: null,
		files: [],
	});

	const columns = [
		{
			title: 'Categoria',
			key: 'category',
			render: (wpps) => (
				<PlayCell
					meta={{ primary: `WPP LOCAL - ${wpps.category.split('-')[1]}` }}
					isPlaying={player?.ref === wpps?._id && player?.isPlaying}
					onPause={player.resume}
					onPlay={() => {
						player.start({
							src: resolveFileSrc({ fileName: wpps?.filename }),
							ref: wpps?._id,
							meta: {
								name: `WPP LOCAL - ${wpps.category.split('-')[1]}`,
								artist: wpps?.program?.name,
							},
						});
					}}
				/>
			),
		},
		{
			title: 'Lançamento',
			key: 'date',
			render: (wpp) => (wpp.isRandom ? '-' : wpp.releaseDate),
		},
		{
			title: 'Wpp Randômico?',
			align: 'center',
			key: 'isRandom',
			render: (news) =>
				news?.isRandom ? (
					<Tag color='blue'>Randômico</Tag>
				) : (
					<Tag color='orange'>Não Randômico</Tag>
				),
		},
		{
			title: 'Duração',
			align: 'center',
			key: 'duration',
			render: (wpps) => <FileDuration src={resolveFileSrc({ fileName: wpps?.filename })} />,
		},
		{
			title: 'Formato',
			align: 'center',
			key: 'ext',
			render: (wpps) => <FileExt src={wpps?.filename} />,
		},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: ({ _id }) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					10
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'options',
			render: (wpp) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									key='play'
									onClick={() => {
										player.start({
											src: resolveFileSrc({ fileName: wpp?.filename }),
											ref: wpp?._id,
											meta: {
												artist: 'WPP LOCAL',
												name: wpp.category,
											},
										});
									}}>
									<Icon type='play-circle' /> Reproduzir
								</Menu.Item>
								<Menu.Item key='download' onClick={() => handleDownload({ wpp })}>
									<Icon type='download' /> Fazer download
								</Menu.Item>

								<Menu.Divider />

								<Menu.Item
									key='delete'
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: 'Excluir wpp?',
											icon: 'exclamation-circle',
											content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											okText: 'Excluir',
											onOk: () => handleDelete({ wppId: wpp?._id }),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}>
									<Icon type='delete' /> Excluir
								</Menu.Item>
							</Menu>
						}>
						<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const handleDownload = useCallback(
		async ({ wpp }) => {
			try {
				const name = `WPP LOCAL - ${wpp?.category}`;

				return download({ filename: wpp?.filename, name });
			} catch (error) {
				console.error(error);
				message.error('Não foi possível fazer o download.');
			}
		},
		[download]
	);

	const handleDownloadAsZIP = useCallback(async () => {
		setFallback((prev) => ({ ...prev, multiDownload: true }));

		const zip = new JSZip();

		for (let index = 0; index < selectedWpps.length; index++) {
			const wpp = selectedWpps[index];
			const filePath = resolveFileSrc({ fileName: wpp?.filename });
			const blob = await axios.get(filePath, { responseType: 'blob' });
			const name = `WPP LOCAL - ${wpp?.category}`;
			const ext = path.extname(wpp?.filename);
			const fName = `${wpp?.program?.name} - ${name}${ext}`;

			zip.file(fName, blob.data, { binary: true });
		}

		const zipContent = await zip.generateAsync({ type: 'blob' });

		let downloadName = `WPPS LOCAIS - ${program?.name}`;

		filters?.releaseDate && (downloadName = `${downloadName} - ${filters.releaseDate}`);

		saveAs(zipContent, downloadName);
		setFallback((prev) => ({ ...prev, multiDownload: false }));
	}, [program, selectedWpps, filters]);

	const handleDelete = useCallback(async ({ wppId }) => {
		try {
			setFallback((prev) => ({ ...prev, deleting: true }));

			await WppsAPI.destroy({ wppId });

			setWpps((wpps) => wpps.filter(({ _id }) => _id !== wppId));
		} catch (error) {
			console.error(error);
			message.error('Houve um erro ao deletar a wpps');
		} finally {
			setFallback((prev) => ({ ...prev, deleting: false }));
		}
	}, []);

	const handleMultiDelete = useCallback(async () => {
		for (let index = 0; index < selectedWpps.length; index++) {
			const { _id: wppId } = selectedWpps[index];
			await handleDelete({ wppId });
		}

		setSelectedWpps([]);
	}, [selectedWpps, handleDelete]);

	const handleCreateWpps = useCallback(async () => {
		try {
			const validationSchema = Yup.object().shape({
				category: Yup.string().required('Informe a categoria'),
				program: Yup.string().required('Informe o programa'),
				user: Yup.string().required('Usuário inválido'),
				releaseDate: isRandom
					? Yup.mixed().nullable(true)
					: Yup.string().required('Informe a data de lançamento'),
				files: Yup.array().required('Selecione no mínimo 1 arquivo'),
			});

			const { category, releaseDate, files } = newWpps;
			const data = {
				category,
				releaseDate: isRandom ? null : releaseDate,
				files,
				isLocal: true,
				isRandom,
				user: user?._id,
				program: program?._id,
			};

			await validationSchema.validate(data);

			if (hasValidationError(files)) {
				return message.warning(
					'Alguns arquivos selecionados estão corrompidos. Por favor, substitua-os.'
				);
			}

			setFallback((prev) => ({ ...prev, uploadingWpps: true }));

			const payload = new FormData();

			for (const key in data) {
				if (key === 'files') {
					data[key].forEach((file) => payload.append('file', file.data));
				} else {
					payload.append(key, data[key]);
				}
			}

			const {
				data: { wpps: createdWpps },
			} = await WppsAPI.store({
				payload,
				onUploadProgress: ({ total, loaded }) => {
					setProgress(Math.floor((loaded * 100) / total));
				},
			});

			setWpps((prev) => [
				...prev,
				...createdWpps.map((w) => ({ ...w, program: { name: program?.name } })),
			]);
			setIsRandom(false);
			setNewWpps({ category: undefined, releaseDate: null, files: [] });
			setVisibleModals((prev) => ({ ...prev, addWpps: false }));

			/** Aguarda 2 segundos para fechar a mensagem de sucesso do Progress */
			await new Promise((resolve) => setTimeout(() => resolve(), 2000));
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			console.error(error);
			message.error('Houve um erro, tente novamente');
		} finally {
			setProgress(null);
			setFallback((prev) => ({ ...prev, uploadingWpps: false }));
		}
	}, [user, newWpps, program, hasValidationError, isRandom]);

	const isUploadButtonDisabled = useMemo(() => {
		return (
			isValidating ||
			!newWpps.category ||
			(!isRandom && !newWpps.releaseDate) ||
			!newWpps.files.length
		);
	}, [isValidating, newWpps, isRandom]);

	useEffect(() => {
		const fetchWpps = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingWpps: true }));

				let query = `isLocal=true&page=${pagination?.current - 1}&limit=${
					pagination?.pageSize
				}&program=${program?._id}&user=${user?._id}&`;

				filters?.releaseDate && (query = `${query}releaseDate=${filters.releaseDate}&`);
				filters?.category && (query = `${query}category=${filters.category}&`);

				const {
					data: { wpps, total },
				} = await WppsAPI.index({ query });

				setWpps(wpps);
				setPagination((prev) => ({ ...prev, total }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os wpps');
			} finally {
				setFallback((prev) => ({ ...prev, fetchingWpps: false }));
			}
		};

		fetchWpps();
	}, [filters, pagination.current, pagination.pageSize, user]); //eslint-disable-line

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<FiltersContainer>
				<div className='filters'>
					<Select
						showSearch
						value={filters.category}
						placeholder='Categoria'
						style={{ width: '200px', marginRight: '12px' }}
						onChange={(category) => setFilters({ ...filters, category })}
						filterOption={(input, { props: { _search } }) => {
							return _search.match(new RegExp(input, 'i'));
						}}>
						<Select.Option key='all' value={undefined} _search='TODOS'>
							TODOS
						</Select.Option>

						{categories.map((category) => (
							<Select.Option value={category} key={category}>
								{category}
							</Select.Option>
						))}
					</Select>

					<DatePicker
						placeholder='Data de lançamento'
						format='DD/MM/yyyy'
						style={{ width: '200px' }}
						value={filters?.releaseDate && moment(filters.releaseDate, 'DD/MM/yyyy')}
						onChange={(_, releaseDate) => setFilters({ ...filters, releaseDate })}
					/>
				</div>

				<Button onClick={() => setVisibleModals({ ...visibleModals, addWpps: true })}>
					<FiPlus /> Novos wpps
				</Button>
			</FiltersContainer>

			<TableActions>
				<div className='actions'>
					<span>
						Quantidade: <strong>{pagination?.total}</strong>
					</span>
					<div>
						<Button
							size='small'
							disabled={!selectedWpps.length}
							type='ghost'
							onClick={handleDownloadAsZIP}
							loading={fallback?.multiDownload}>
							<FiDownload /> Baixar selecionados{' '}
							{selectedWpps.length !== 0 && `(${selectedWpps.length})`}
						</Button>

						<Button
							size='small'
							disabled={!selectedWpps.length}
							type='danger'
							onClick={() => {
								Modal.confirm({
									title: 'Deletar wpps selecionadas?',
									type: 'danger',
									content:
										'Todos as wpps selecionados serão excluídos e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
									okText: 'Deletar',
									onOk: handleMultiDelete,
									okButtonProps: {
										icon: 'delete',
										type: 'danger',
									},
									cancelText: 'Cancelar',
									cancelButtonProps: {
										icon: 'close-circle',
									},
								});
							}}>
							<FiTrash2 /> Deletar selecionados{' '}
							{selectedWpps.length !== 0 && `(${selectedWpps.length})`}
						</Button>
					</div>
				</div>

				<PageSizeHandler pagination={pagination} setPagination={setPagination} />
			</TableActions>

			<Table
				loading={fallback?.fetchingWpps}
				size='middle'
				rowKey='_id'
				columns={columns}
				dataSource={wpps}
				style={{ border: 'none' }}
				pagination={{
					...pagination,
					size: 'large',
					onChange: (current) => setPagination({ ...pagination, current }),
				}}
				rowSelection={{
					onChange: (_, selectedRows) => setSelectedWpps(selectedRows),
				}}
			/>

			<Modal
				destroyOnClose
				width={600}
				visible={visibleModals?.addWpps}
				onCancel={() => setVisibleModals({ ...visibleModals, addWpps: false })}
				onOk={handleCreateWpps}
				cancelButtonProps={{ disabled: fallback?.uploadingWpps }}
				okButtonProps={{
					loading: fallback?.uploadingWpps,
					disabled: isUploadButtonDisabled,
				}}
				okText={
					<>
						{!isValidating && <FiUpload />}
						{isValidating ? 'Validando Arquivos' : 'Fazer upload'}
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				title={
					<>
						<FiPlusCircle /> Adicionar wpps
					</>
				}>
				<Form.Container>
					<Form.Item
						style={{ marginBottom: 12 }}
						help='O WhatsApp será salvo no seu banco de vozes e inserido aleatoriamente no seu conteúdo.'>
						<Checkbox checked={isRandom} onChange={(e) => setIsRandom(e.target.checked)}>
							Wpp randômico
						</Checkbox>
					</Form.Item>

					<Form.Item label='Categoria'>
						<Select
							showSearch
							value={newWpps?.category}
							placeholder='Categoria'
							style={{ marginBottom: 8, width: '100%' }}
							onChange={(category) => setNewWpps({ ...newWpps, category })}
							filterOption={(input, { props: { _search } }) => {
								return _search.match(new RegExp(input, 'i'));
							}}>
							{categories.map((category) => (
								<Select.Option key={category} value={category} _search={category}>
									{category}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					{!isRandom && (
						<Form.Item label='Data de lançamento'>
							<DatePicker
								placeholder='Data de lançamento'
								format='DD/MM/yyyy'
								style={{ width: '100%' }}
								value={newWpps?.releaseDate && moment(newWpps.releaseDate, 'DD/MM/yyyy')}
								onChange={(_, releaseDate) => setNewWpps({ ...newWpps, releaseDate })}
							/>
						</Form.Item>
					)}

					<FilesUploader
						multiple
						onChange={(files) => setNewWpps((prev) => ({ ...prev, files }))}
						onStartValidation={() => setIsValidating(true)}
						onEndValidation={() => setIsValidating(false)}
						validTypes={['.mp3', '.wav']}
					/>
				</Form.Container>
			</Modal>

			<Progress
				progress={progress}
				succesTitle='Wpps enviadas com sucesso'
				title='Enviando wpps, por favor aguarde...'
			/>
		</>
	);
};

export default connect(({ user }) => ({ user }))(LocalWpps);
