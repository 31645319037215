import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
	PageHeader,
	Card,
	Select,
	Form,
	Spin,
	Typography,
	Icon,
	Tag,
	Divider,
	Slider,
	message,
	Button,
	Modal,
} from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import {
	ButtonContainer,
	Container,
	LoadTemplateButtonContainer,
	OptionContent,
	ScriptConfigContainer,
	SelectContainer,
} from './styles';
import { FiList } from 'react-icons/fi';
import ProgramsAPI from '../../../../services/sdks/programs';
import PlaylistScriptAPI from '../../../../services/sdks/playlistScripts';
import ScriptsAPI from '../../../../services/sdks/scripts';
import CategoriesAPI from '../../../../services/sdks/categories';
import PlaylistScriptTemplatesAPI from '../../../../services/sdks/playlistScriptTemplates';
import PlaylistScriptTabs from '../../../../components/PlaylistScriptTabs';

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Início' },
		{ breadcrumbName: 'Playlists' },
		{ breadcrumbName: 'Modelos Semanais de Playlists' },
	],
	style: { marginBottom: 12 },
};

const initialWeek = {
	dom: { key: 'dom', title: 'Domingo', active: undefined, blocks: [] },
	seg: { key: 'seg', title: 'Segunda-Feira', active: undefined, blocks: [] },
	ter: { key: 'ter', title: 'Terça-Feira', active: undefined, blocks: [] },
	qua: { key: 'qua', title: 'Quarta-Feira', active: undefined, blocks: [] },
	qui: { key: 'qui', title: 'Quinta-Feira', active: undefined, blocks: [] },
	sex: { key: 'sex', title: 'Sexta-Feira', active: undefined, blocks: [] },
	sab: { key: 'sab', title: 'Sábado', active: undefined, blocks: [] },
};

const WeeklyScripts = ({ user }) => {
	const [fallback, setFallback] = useState({ initialData: true });
	const [program, setProgram] = useState(undefined);
	const [programs, setPrograms] = useState([]);
	const [categories, setCategories] = useState([]);
	const [playlistScript, setPlaylistScript] = useState(null);
	const [sharingDayScript, setSharingDayScript] = useState(null);
	const [selectedDaysToShare, setSelectedDaysToShare] = useState([]);
	const [templates, setTemplates] = useState([]);
	const [template, setTemplate] = useState(null);
	const [showTemplateModal, setShowTemplatesModal] = useState(false);
	const [incidence, setIncidence] = useState(1);
	const [week, setWeek] = useState(initialWeek);

	const handleToggleDayActive = useCallback((day, active) => {
		setWeek((prev) => ({ ...prev, [day]: { ...prev[day], active } }));
	}, []);

	const handleAddBlock = useCallback((day) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: [...prev[day].blocks, []],
			},
		}));
	}, []);

	const handleRemoveBlock = useCallback((day, blockIndex) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.filter((_, i) => i !== blockIndex),
			},
		}));

		message.success(`O bloco #${blockIndex + 1} foi removido`);
	}, []);

	const handleClearBlock = useCallback((day, blockIndex) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.map((block, i) => (i === blockIndex ? [] : block)),
			},
		}));

		message.success(`As categorias do bloco #${blockIndex + 1} foram removidas`);
	}, []);

	const handleCloneBlock = useCallback(
		(day, blockIndex) => {
			const dayBlocks = week[day].blocks;
			const clone = dayBlocks[blockIndex];

			dayBlocks.splice(blockIndex + 1, 0, clone);

			setWeek((prev) => ({
				...prev,
				[day]: {
					...prev[day],
					blocks: dayBlocks,
				},
			}));

			message.success(`O bloco #${blockIndex + 1} foi cloando com sucesso`);
		},
		[week]
	);

	const handleAddCategories = useCallback((day, blockIndex, category, inserts) => {
		const newCategories = Array.from({ length: inserts }).fill(category);

		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.map((block, i) => {
					return i === blockIndex ? [...block, ...newCategories] : block;
				}),
			},
		}));

		message.success(`Aa categorias foram inseridas no bloco`);
	}, []);

	const handleReplaceCategory = useCallback((day, blockIndex, newCategory, categoryIndex) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.map((block, bI) => {
					return blockIndex === bI
						? block.map((category, i) => (i === categoryIndex ? newCategory : category))
						: block;
				}),
			},
		}));

		message.success(`A categoria foi substiruída`);
	}, []);

	const handleRemoveCategory = useCallback((day, blockIndex, categoryIndex) => {
		setWeek((prev) => ({
			...prev,
			[day]: {
				...prev[day],
				blocks: prev[day].blocks.map((block, bI) => {
					return blockIndex === bI ? block.filter((_, i) => i !== categoryIndex) : block;
				}),
			},
		}));

		message.success(`A categoria foi removida`);
	}, []);

	const handleClearDay = useCallback((day) => {
		setWeek((prev) => ({
			...prev,
			[day]: { ...prev[day], blocks: [] },
		}));

		message.success(`A grade do dia foi limpa`);
	}, []);

	const handleShareDay = useCallback(() => {
		const { active, blocks } = sharingDayScript;

		selectedDaysToShare.forEach((targetDay) => {
			setWeek((prev) => ({
				...prev,
				[targetDay]: { ...week[targetDay], active, blocks },
			}));
		});
	}, [sharingDayScript, selectedDaysToShare, week]);

	const calculateCategoryCount = useCallback(
		(categoryId) => {
			if (!categoryId) {
				return 'Categoria removida';
			}

			const category = categories.find(({ _id }) => _id === categoryId);
			const dbTracks = category.tracksCount;
			let scriptTracks = 0;

			/** Sem essa função, o eslint vai exibir um warning */
			const incrementScriptTracks = () => (scriptTracks += 1);

			for (const key in week) {
				if (Object.hasOwnProperty.call(week, key)) {
					const day = week[key];

					day.blocks.forEach((block) => {
						block.forEach((b) => {
							if (b?.id === categoryId) {
								incrementScriptTracks();
							}
						});
					});
				}
			}

			return { dbTracks, scriptTracks, isDanger: scriptTracks >= dbTracks };
		},
		[week, categories]
	);

	const vaidateWeekScript = useCallback(() => {
		try {
			for (const key in week) {
				const { blocks } = week[key];

				for (const block of blocks) {
					if (block.some((t) => t === null)) {
						throw new Error();
					}
				}
			}

			return true;
		} catch (error) {
			return false;
		}
	}, [week]);

	const handleUpsertPlaylistScript = useCallback(
		async (ignoreDanger = false) => {
			const isValidScript = vaidateWeekScript();

			if (!isValidScript) {
				return message.warn('É necessário selecionar todas as categorias no modelo');
			}

			try {
				let categoriesCount = 0;
				let isDanger = undefined;

				const modifyDangerousStatus = () => (isDanger = true);
				const incrementCategoriesCount = (increment) => (categoriesCount += increment);
				const data = { incidence, program };

				for (const key in week) {
					if (Object.hasOwnProperty.call(week, key)) {
						const day = week[key];

						if (day?.active) {
							data[key] = day.blocks.filter((block) => block.length >= 1);
							day.blocks.forEach((block) => {
								incrementCategoriesCount(block?.length);

								block.forEach((category) => {
									if (calculateCategoryCount(category?.id).isDanger) {
										modifyDangerousStatus();
									}
								});
							});
						} else {
							data[key] = 'unactive';
						}
					}
				}

				if (categoriesCount === 0) {
					return message.warn('Não é possível criar um modelo sem categorias');
				}

				setFallback((prev) => ({ ...prev, upserting: true }));

				if (isDanger && !ignoreDanger) {
					return Modal.confirm({
						title: `Atenção`,
						icon: 'exclamation-circle',
						width: 500,
						content: (
							<>
								Algumas categorias no seu modelo de playlist excedem a quandidade de músicas no
								banco músical.
								<br />
								Criar um modelo nessas condições, pode ocasionar em longos períodos de espera
								durante a geração das playlists e em repetições de algumas músicas durante a semana.
								<br />É aconselhado não prosseguir e reorganizar seu modelo.
							</>
						),
						okText: 'Continuar mesmo assim',
						onOk: () => handleUpsertPlaylistScript(true),
						okButtonProps: {
							icon: 'exclamation-circle',
						},
						cancelText: 'Cancelar',
						cancelButtonProps: {
							icon: 'close-circle',
						},
					});
				}

				await PlaylistScriptAPI.upsert(data);

				setProgram(undefined);
				setPlaylistScript(null);
				setSharingDayScript(null);
				setSelectedDaysToShare([]);
				setIncidence(1);
				setWeek(initialWeek);
				setFallback((prev) => ({ ...prev, upserting: false }));

				message.success(
					playlistScript ? 'Modelo atualizado com sucesso' : 'Modelo criado com Sucesso!'
				);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro, tente novamente');
				setFallback((prev) => ({ ...prev, upserting: false }));
			}
		},
		[week, vaidateWeekScript, program, incidence, calculateCategoryCount, playlistScript]
	);

	const loadTemplate = useCallback(() => {
		if (!template) {
			return;
		}

		const _week = {
			seg: { key: 'seg', title: 'Segunda-Feira' },
			ter: { key: 'ter', title: 'Terça-Feira' },
			qua: { key: 'qua', title: 'Quarta-Feira' },
			qui: { key: 'qui', title: 'Quinta-Feira' },
			sex: { key: 'sex', title: 'Sexta-Feira' },
			sab: { key: 'sab', title: 'Sábado' },
			dom: { key: 'dom', title: 'Domingo' },
		};

		for (const key in template.week) {
			if (Object.hasOwnProperty.call(template.week, key)) {
				const day = template.week[key];
				const blocks = [];

				if (day[0] === 'unactive') {
					_week[key] = { ..._week[key], active: false, blocks };
				} else {
					day.forEach(({ value: block }) => {
						blocks.push(block);
					});

					_week[key] = { ..._week[key], active: true, blocks };
				}
			}
		}

		setWeek(_week);
		setShowTemplatesModal(false);
		message.success('Template carregado com sucesso');
	}, [template]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const [
					{
						data: { categories },
					},
					{
						data: { programs },
					},
					{
						data: { templates },
					},
				] = await Promise.all([
					CategoriesAPI.index({ 'count-tracks': 'true' }),
					ProgramsAPI.index(`userId=${user?._id}&isDeleted=false`),
					PlaylistScriptTemplatesAPI.getAll(),
				]);

				setTemplates(templates);
				setCategories(
					categories.sort((x, y) => {
						return x?.name?.toUpperCase() >= y?.name.toUpperCase() ? 1 : -1;
					})
				);

				setPrograms(
					programs.sort((x, y) => {
						return x?.isFavorited === y?.isFavorited ? 0 : x?.isFavorited ? -1 : 1;
					})
				);

				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os dados');
			}
		};

		fetchInitialData();
	}, [user]);

	const intiWeeklyScript = useCallback((scripts) => {
		const data = {};
		const daysArray = [
			{ key: 'dom', title: 'Domingo', active: undefined, blocks: [] },
			{ key: 'seg', title: 'Segunda-Feira', active: undefined, blocks: [] },
			{ key: 'ter', title: 'Terça-Feira', active: undefined, blocks: [] },
			{ key: 'qua', title: 'Quarta-Feira', active: undefined, blocks: [] },
			{ key: 'qui', title: 'Quinta-Feira', active: undefined, blocks: [] },
			{ key: 'sex', title: 'Sexta-Feira', active: undefined, blocks: [] },
			{ key: 'sab', title: 'Sábado', active: undefined, blocks: [] },
		];

		for (const [index, item] of Object.entries(daysArray)) {
			const script = scripts.find((s) => s.weekDay === Number(index));

			const blocks = [];

			if (script) {
				for (const element of script.body) {
					if (element.type === 'NEW-BLOCK') {
						blocks.push([]);
					}

					if (element.type === 'TRACK') {
						blocks[blocks.length - 1].push(null);
					}
				}
			}

			data[item.key] = { ...item, blocks, active: blocks.length !== 0 };
		}

		setWeek(data);
	}, []);

	useEffect(() => {
		const loadWeeklyScript = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingScript: true }));

				const {
					data: { playlistScript },
				} = await PlaylistScriptAPI.show(program);

				if (!playlistScript) {
					const {
						data: { scripts },
					} = await ScriptsAPI.index({
						query: `program=${program}&userId=${user?._id}`,
					});

					intiWeeklyScript(scripts);

					throw new Error(
						'O programa ainda não possui um modelo semanal de playlist. Uma versão inicial foi foi carregada com base nos modelos do programa'
					);
				}

				const _week = {
					seg: { key: 'seg', title: 'Segunda-Feira' },
					ter: { key: 'ter', title: 'Terça-Feira' },
					qua: { key: 'qua', title: 'Quarta-Feira' },
					qui: { key: 'qui', title: 'Quinta-Feira' },
					sex: { key: 'sex', title: 'Sexta-Feira' },
					sab: { key: 'sab', title: 'Sábado' },
					dom: { key: 'dom', title: 'Domingo' },
				};

				for (const key in playlistScript.week) {
					if (Object.hasOwnProperty.call(playlistScript.week, key)) {
						const day = playlistScript.week[key];
						const blocks = [];

						if (day[0] === 'unactive') {
							_week[key] = { ..._week[key], active: false, blocks };
						} else {
							day.forEach(({ value: block }) => {
								blocks.push(block);
							});

							_week[key] = { ..._week[key], active: true, blocks };
						}
					}
				}

				setWeek(_week);
				setIncidence(playlistScript?.incidence);
				setPlaylistScript(playlistScript?._id);
				setFallback((prev) => ({ ...prev, fetchingScript: false }));
			} catch (error) {
				message.warn(String(error?.message), 5);
				setIncidence(1);
				setFallback((prev) => ({ ...prev, fetchingScript: false }));
			}
		};

		if (program) {
			loadWeeklyScript();
		}
	}, [program, user, intiWeeklyScript]);

	if (fallback.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Modelos semanais de playlists' />

			<PageHeader title='Modelos Semanais de Playlists' breadcrumb={breadcrumb}>
				<Typography.Paragraph>
					Selecione o programa para editar ou criar um novo modelo semanal de playlist. O modelo
					será utilizado para gerar as playlists do programa.
				</Typography.Paragraph>
			</PageHeader>

			<Container>
				<Spin
					spinning={fallback?.fetchingScript ? true : false}
					tip='Buscando modelo semanal, por favor aguarde...'>
					<Card>
						<SelectContainer>
							<Select
								showSearch
								size='large'
								placeholder='Selecione o programa'
								value={program}
								onChange={(value) => setProgram(value)}
								optionFilterProp='pname'
								filterOption={(input, option) => {
									return option.props.pname.indexOf(input.toLowerCase()) >= 0;
								}}>
								{programs.map((program) => (
									<Select.Option
										key={program?._id}
										disabled={!program?.isEditable || !program?.isActive}
										pname={program?.name.toLowerCase()}>
										<OptionContent>
											<Icon
												theme='filled'
												type='heart'
												style={{
													color: 'var(--danger)',
													marginRight: 8,
													opacity: program?.isFavorited ? 1 : 0,
													pointerEvents: 'none',
												}}
											/>
											<span>
												{program?.name}
												{!program?.isEditable ? (
													<Tag color='red'>Não editável</Tag>
												) : (
													!program?.isActive && <Tag color='red'>Inadimplente</Tag>
												)}
											</span>
										</OptionContent>
									</Select.Option>
								))}
							</Select>
						</SelectContainer>

						<Divider />

						<LoadTemplateButtonContainer>
							<Button onClick={() => setShowTemplatesModal(true)} disabled={!program}>
								<FiList /> Carregar Template
							</Button>
						</LoadTemplateButtonContainer>

						<ScriptConfigContainer disabled={!program}>
							<Form {...layout}>
								<Form.Item
									label='Configurar incidência das músicas'
									help='A incidência controla o intervalo de dias em que uma mesma música pode aparecer na semana'>
									<Slider
										min={1}
										max={7}
										disabled={!program}
										marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7 }}
										value={incidence}
										onChange={(value) => setIncidence(value)}
									/>
								</Form.Item>
							</Form>

							<Divider />

							<PlaylistScriptTabs
								week={week}
								categories={categories}
								actions={{
									handleToggleDayActive,
									handleAddBlock,
									handleRemoveBlock,
									handleClearBlock,
									handleCloneBlock,
									handleAddCategories,
									handleReplaceCategory,
									handleRemoveCategory,
									handleClearDay,
									handleShareDay,
									calculateCategoryCount,
								}}
								share={{
									sharingDayScript,
									setSharingDayScript,
									selectedDaysToShare,
									setSelectedDaysToShare,
								}}
							/>

							<Divider />

							<ButtonContainer>
								<Button
									type='primary'
									disabled={!program}
									onClick={() => handleUpsertPlaylistScript(false)}
									icon='check-circle'
									loading={fallback?.upserting}
									size='large'>
									{playlistScript ? 'Atualizar modelo semanal' : 'Criar modelo semanal'}
								</Button>
							</ButtonContainer>
						</ScriptConfigContainer>
					</Card>
				</Spin>
			</Container>

			<Modal
				visible={showTemplateModal}
				title='Carregar Template'
				onCancel={() => {
					setTemplate(null);
					setShowTemplatesModal(false);
				}}
				onOk={loadTemplate}
				okButtonProps={{ disabled: !template }}
				okText='Carregar Template'>
				<Select
					style={{ width: '100%' }}
					size='large'
					placeholder='Selecione o template'
					onChange={(value) => setTemplate(JSON.parse(value))}>
					{templates.map((template) => (
						<Select.Option key={template?._id} value={JSON.stringify(template)}>
							{template.name}
						</Select.Option>
					))}
				</Select>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(WeeklyScripts);
