import api from '../api';

const BASE_URL = '/api/v2/genres';

const GenresAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	show: (genreId) => api.get(`${BASE_URL}/${genreId}`),
	store: (payload) => api.post(BASE_URL, payload),
	update: (genreId, payload) => api.put(`${BASE_URL}/${genreId}`, payload),
	destroy: (genreId) => api.delete(`${BASE_URL}/${genreId}`),
};

export default GenresAPI;
