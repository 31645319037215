import api from '../api';

const BASE_URL = '/api/v2/statistics';

const StatisticsAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	destroy: ({ statisticId }) => api.delete(`${BASE_URL}/${statisticId}`),
};

export default StatisticsAPI;
