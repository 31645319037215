import React from 'react';
import { Modal, Progress as AntProgress, Result } from 'antd';

import { Container } from './styles';

const Progress = ({ progress, title, succesTitle }) => {
	return (
		<Modal
			centered
			visible={typeof progress === 'number'}
			closable={false}
			footer={null}
			width={440}
		>
			{progress >= 100 ? (
				<Result status='success' title={succesTitle} />
			) : (
				<Container>
					{title}
					{typeof progress === 'number' && <AntProgress percent={progress} />}
				</Container>
			)}
		</Modal>
	);
};

export default Progress;
