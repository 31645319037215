import React from 'react';
import { Typography, Divider } from 'antd';

import Meta from '../../../../components/Meta';
import HelpButtons from '../../../../components/HelpButtons';
import { Container, ImgContainer } from './styles';

import img01 from '../../../../assets/images/tutorials/programs/programs-management/img-01.png';
import img02 from '../../../../assets/images/tutorials/programs/programs-management/img-02.png';
import img03 from '../../../../assets/images/tutorials/programs/programs-management/img-03.png';

const { Title, Paragraph } = Typography;

const ProgramsManagement = () => (
	<Container>
		<Meta title='Ajuda - Gerenciamento de Programas' />

		<Title>Gerenciamento de Programas</Title>

		<Divider />

		<Paragraph>
			Para acessar a página de gerenciamento de programas através do menu lateral, vá em{' '}
			<i>Conteúdos</i> ➞ <i>Gerenciar Programas</i>
		</Paragraph>

		<ImgContainer>
			<img src={img01} alt='Gerenciar programas' />
		</ImgContainer>

		<Paragraph>
			Na parte superior da página, são exibidas as seguintes informações: de quantos programas você
			possui, quantos programas foram compartilhados com você(compartilhamentos serão abordados mais
			tarde), e a quantidade de programas que por motivo de inadimplência estão desativados.
		</Paragraph>

		<ul>
			<li>Quantidade de programas próprios;</li>
			<li>Quantidade de programas compartilhados com você;</li>
			<li>Quantidade de programas indisponíveis por inadimplência;</li>
		</ul>

		<ImgContainer>
			<img src={img02} alt='Gerenciar programas' />
		</ImgContainer>

		<Divider />

		<Paragraph>
			Na parte inferior são exibidas duas abas:{' '}
			<i>
				<strong>Programas próprios(Meus conteúdos)</strong>
			</i>{' '}
			e{' '}
			<i>
				<strong>Programas compartilhados com você</strong>
			</i>
			.
		</Paragraph>

		<ImgContainer>
			<img src={img03} alt='Gerenciar programas' />
		</ImgContainer>

		<Paragraph>É possível buscar o programa pelo nome.</Paragraph>

		<Paragraph>
			Para acessar os detalhes de um programa, basta clicar no seu nome ou em <i>Detalhes</i>, na
			parte inferior.
		</Paragraph>

		<HelpButtons
			prev={{ label: 'Criando um Programa', to: '/help/programs/creating-a-program' }}
			next={{ label: 'Detalhes do Programa', to: '/help/programs/program-details/own' }}
		/>

		<Divider />

		<Paragraph type='secondary'>Atualizado em: 06/12/2021</Paragraph>
	</Container>
);

export default ProgramsManagement;
