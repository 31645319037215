const VignetteSpeechModes = {
  SUNG: 'SUNG',
  SPOKEN: 'SPOKEN',
}

const VignetteSpeechModesTranslation = {
  SUNG: 'Cantada',
  SPOKEN: 'Falada',
}

module.exports = { VignetteSpeechModes, VignetteSpeechModesTranslation }