import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import * as UserActions from './store/actions/user';
import { bindActionCreators } from 'redux';
import UsersAPI from './services/sdks/user';
import Fallback from './components/Fallback';

const AppWrapper = ({ user, children }) => {
	const isLoggedIn = useMemo(() => !!user, [user]);
	const [isLoading, setIsLoading] = useState(() => (isLoggedIn ? true : false));

	const fetchUser = useCallback(async () => {
		setIsLoading(true);

		try {
			const response = await UsersAPI.getOne(user?._id);

			if (response.data.user) {
				localStorage.setItem('state', JSON.stringify({ user: response.data.user }));
			}
		} catch (error) {}

		setIsLoading(false);
	}, [user]);

	useEffect(() => {
		if (isLoggedIn) {
			fetchUser(true);
		}
	}, [fetchUser, isLoggedIn]);

	if (isLoading) {
		return <Fallback />;
	}

	return children;
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = (dispatch) => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
