import React from 'react';
import { Link } from 'react-router-dom';

import { Container, PrevButton, NextButton, VoidButton } from './styles';

import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

const HelpButtons = ({ prev, next }) => {
	return (
		<Container>
			{prev ? (
				<Link to={prev?.to}>
					<PrevButton>
						<FiArrowLeft />

						<div className='text'>
							<span>Anterior</span>
							<p>{prev?.label}</p>
						</div>
					</PrevButton>
				</Link>
			) : (
				<VoidButton />
			)}

			{next ? (
				<Link to={next?.to}>
					<NextButton>
						<div className='text'>
							<span>Próximo</span>
							<p>{next?.label}</p>
						</div>
						<FiArrowRight />
					</NextButton>
				</Link>
			) : (
				<VoidButton />
			)}
		</Container>
	);
};

export default HelpButtons;
