import React, { useCallback, useState } from 'react';
import { FiClock } from 'react-icons/fi';

const FileDuration = ({ src }) => {
	const [duration, setDuration] = useState(null);

	const parseNumberToTimestamp = useCallback((input) => {
		try {
			if (!input || typeof input !== 'number') {
				return '--:--';
			}

			let [minutes = '0', seconds = '0'] = String(input / 60).split('.');

			seconds = Number(seconds.length >= 2 ? `0.${seconds[0]}${seconds[1]}` : `0${seconds[0]}`);
			seconds = Math.floor(seconds * 60);

			if (Number(minutes) >= 0) {
				minutes = Math.floor(Number(minutes) % 60);
			}

			minutes = String(minutes).length === 1 ? `0${minutes}` : minutes;
			seconds = String(seconds).length === 1 ? `0${seconds}` : seconds;

			minutes = String(minutes).substr(0, 2);
			seconds = String(seconds).substr(0, 2);

			return `${minutes}:${seconds}`;
		} catch (error) {
			console.error(`Invalid input provided: ${input}`);

			return '--:--';
		}
	}, []);

	return (
		<>
			{duration ? (
				<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<FiClock style={{ color: 'rgba(0, 0, 0, .45)', fontSize: 13, marginRight: 6 }} />{' '}
					{duration}
				</span>
			) : (
				'--:--'
			)}

			<audio
				src={src}
				onLoadedMetadata={({ target: { duration } }) => {
					setDuration(parseNumberToTimestamp(duration));
				}}
			/>
		</>
	);
};

export default FileDuration;
