import React from 'react';
import { Container, Badge } from './styles';

const SelectOptWithBadge = ({ color, label, badgeHeight }) => {
	return (
		<Container>
			<Badge badgeHeight={badgeHeight} color={color} />
			{label}
		</Container>
	);
};

export default SelectOptWithBadge;
