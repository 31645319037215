import api from '../api';

const BASE_URL = '/api/v2/billets';

const BilletsApi = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	show: (contractId) => api.get(`${BASE_URL}/${contractId}`),
	store: (payload) => api.post(BASE_URL, payload),
};

export default BilletsApi;
