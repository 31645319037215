import {
	FiMic,
	FiCommand,
	FiCast,
	FiMusic,
	FiActivity,
	FiList,
	FiBarChart2,
	FiPlay,
	FiRadio,
} from 'react-icons/fi';

import resourcesKeys from '../resourcesKeys';
import resourceGroupsKeys from '../resourceGroupsKeys';

export const commonNavigationMap = [
	{
		type: 'MENU',
		label: 'Episódios',
		link: '/commom/episodes',
		icon: FiPlay,
		resources: null,
	},
	{
		type: 'SUB-MENU',
		label: 'Vinhetas da Rádio',
		icon: FiRadio,
		resourcesGroups: [resourceGroupsKeys.VIGNETTES],
		items: [
			{
				link: '/commom/radio-vignettes',
				label: 'Gerenciar Vinhetas',
				resources: [resourcesKeys.VIGNETTES_RADIO_CONTINUOUS],
			},
			{
				link: '/commom/radio-vignettes/configs',
				label: 'Configurar Cronogramas',
				resources: [resourcesKeys.VIGNETTES_RADIO_SCHEDULED],
			},
		],
	},
	{
		type: 'SUB-MENU',
		label: 'Conteúdos',
		icon: FiCast,
		resourcesGroups: null,
		items: [
			{
				link: '/commom/programs',
				label: 'Gerenciar Programas',
				resources: null,
			},
			{
				link: '/commom/elements/create',
				label: 'Adicionar Vinhetas',
				resources: null,
			},
		],
	},
	{
		type: 'MENU',
		label: 'Minha Rede',
		link: '/commom/network',
		icon: FiCommand,
		resources: [resourcesKeys.PROGRAMS_SHARING],
	},
	{
		type: 'MENU',
		label: 'Assinaturas',
		link: '/commom/signatures',
		icon: FiActivity,
		resources: [resourcesKeys.SIGNATURES],
	},
	{
		type: 'MENU',
		label: 'Trilhas Sonoras',
		link: '/commom/soundtracks',
		icon: FiBarChart2,
		resources: [resourcesKeys.SOUNDTRACK],
	},
	{
		type: 'SUB-MENU',
		label: 'Narrações Rede',
		icon: FiMic,
		resources: [
			resourcesKeys.OFFS_MAIN,
			resourcesKeys.NEWS_MAIN,
			resourcesKeys.BLITZ_MAIN,
			resourcesKeys.WPP_MAIN,
			resourcesKeys.INTERVIEW_MAIN,
		],
		options: { strict: false },
		items: [
			{
				link: '/commom/voices/offs',
				label: 'Offs Rede',
				resources: [resourcesKeys.OFFS_MAIN],
			},
			{
				link: '/commom/voices/news',
				label: 'Notícias Rede',
				resources: [resourcesKeys.NEWS_MAIN],
			},
			{
				link: '/commom/voices/blitz',
				label: 'Blitz Rede',
				resources: [resourcesKeys.BLITZ_MAIN],
			},
			{
				link: '/commom/voices/wpps',
				label: 'Wpps Rede',
				resources: [resourcesKeys.WPP_MAIN],
			},
			{
				link: '/commom/voices/interviews',
				label: 'Entrevistas Rede',
				resources: [resourcesKeys.INTERVIEW_MAIN],
			},
		],
	},
	{
		type: 'MENU',
		label: 'Músicas',
		icon: FiMusic,
		link: '/commom/tracks',
		options: { strict: false },
		resources: [resourcesKeys.TRACKS_GLOBAL, resourcesKeys.TRACKS_LOCAL, resourcesKeys.TRACKS_DOWNLOAD],
	},
	{
		type: 'SUB-MENU',
		label: 'Playlists',
		icon: FiList,
		resources: [resourcesKeys.PLAYLIST],
		items: [
			{
				link: '/commom/playlists',
				label: 'Gerenciar Playlists',
				resources: null,
			},
			{
				link: '/commom/playlists/weekly-scripts',
				label: 'Modelos Semanais',
				resources: null,
			},
		],
	},
];
