import React, { useState, useEffect, useCallback } from 'react';
import { PageHeader, Button, message, Card, Typography, Input, Select } from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import { FiArrowRight, FiPlusCircle } from 'react-icons/fi';
import { Container, NamesContainer, NamesConfigRow, ContentContainer } from './styles';

import TKVoiceConfigAPI from '../../../../services/sdks/tkVoiceConfig';
import { useElement } from '../../../../hooks';
import SelectOptWithBadge from '../../../../components/SelectOptWithBadge';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Geral' },
		{ breadcrumbName: 'Configurar IAs' },
	],
	style: { marginBottom: 12 },
};

const TkVoiceConfig = () => {
	const [fallback, setFallback] = useState({ initialData: false });
	const [config, setConfig] = useState({ names: [] });
	const { getElementColor } = useElement();

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				setFallback({ initialData: true });

				const {
					data: { tkVoiceConfig },
				} = await TKVoiceConfigAPI.show();

				setConfig(tkVoiceConfig);
				setFallback({ initialData: false });
			} catch (error) {
				message.error('Houve um erro, tente novamente.');
			}
		};

		fetchInitialData();
	}, []);

	const updateRow = useCallback(
		(value, prop, index) => {
			const names = config.names;
			names[index] = { ...names[index], [prop]: value };

			setConfig({ ...config, names });
		},
		[config]
	);

	const getNewRowKey = useCallback(() => {
		let index = config.names.length;
		let key = `TK-VOICE-${index}`;

		// eslint-disable-next-line no-loop-func
		while (config.names.some((c) => c.key === key)) {
			index = index + 1;
			key = `TK-VOICE-${index}`;
		}

		return key;
	}, [config]);

	const addRow = useCallback(() => {
		const newRow = {
			key: getNewRowKey(),
			label: getNewRowKey(),
			type: 'CASTER',
		};

		setConfig({ ...config, names: [...config.names, newRow] });
	}, [config, getNewRowKey]);

	const updateConfig = useCallback(async () => {
		setFallback((prev) => ({ ...prev, updating: true }));

		try {
			await TKVoiceConfigAPI.update(config);
			message.success('Alterações salvas com sucesso.');
		} catch (error) {
			message.error('Houve um erro, tente novamente.');
		}

		setFallback((prev) => ({ ...prev, updating: false }));
	}, [config]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Configurar IAs' />

			<PageHeader title='Configurar IAs' breadcrumb={breadcrumb}>
				<Typography>Configurar nomenclatura dos elementos de IA</Typography>
			</PageHeader>

			<Container>
				<Card title='Configurar Nomenclatura'>
					<div className='__body-content'>
						<ContentContainer>
							<NamesContainer>
								{config?.names?.map(({ key, type, label }, index) => (
									<NamesConfigRow key={key}>
										<div className='label'>
											<span>IA {String(index + 1).padStart(3, '0')}</span>
										</div>

										<div className='divider'>
											<FiArrowRight />
										</div>

										<div className='actions'>
											<Input
												style={{ textTransform: 'uppercase' }}
												defaultValue={label.toUpperCase()}
												onChange={(e) => updateRow(e.target.value.toUpperCase(), 'label', index)}
											/>

											<Select
												style={{ width: 150 }}
												placeholder='Tipo'
												value={type}
												onChange={(value) => updateRow(value, 'type', index)}>
												<Select.Option value='CASTER'>
													<SelectOptWithBadge
														color={getElementColor({ isLocal: false, type: 'TK-VOICE' })}
														label='GLOBAL'
													/>
												</Select.Option>
												<Select.Option value='PROGRAM'>
													<SelectOptWithBadge
														color={getElementColor({ isLocal: true, type: 'TK-VOICE' })}
														label='LOCAL'
													/>
												</Select.Option>
											</Select>
										</div>
									</NamesConfigRow>
								))}
							</NamesContainer>

							<Button onClick={addRow} type='dashed'>
								Novo Tipo <FiPlusCircle />
							</Button>
						</ContentContainer>
					</div>

					<footer className='__actions'>
						<Button type='primary' loading={fallback.updating} onClick={updateConfig}>
							Salvar Alterações
						</Button>
					</footer>
				</Card>
			</Container>
		</>
	);
};

export default TkVoiceConfig;
