import React from 'react';
import { Section, SectionTitle, Badge, Text } from './styles';

export default function Brands() {
	return (
		<Section>
			<SectionTitle>LOCUTORES</SectionTitle>
			<div className='flex justify-center space-x-2 mb-4'>
				<Badge variant='default' className='bg-[#9c27b0]'>
					OFF 01
				</Badge>
                <Badge variant='default' className='bg-[#9c27b0]'>
                OFF 02
				</Badge>
                <Badge variant='default' className='bg-[#9c27b0]'>
                OFF 03
				</Badge>
                
			</div>
			<Text>
            Revolucione o seu programa com a TalkPlay! Automatize sua locução e deixe o OFF por nossa conta. Nunca mais se preocupe em gravar novamente. Experimente a inovação!			</Text>
		</Section>
	);
}
