import styled, { css } from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const InputWrapper = styled.label`
	width: 100%;
	border: 1px dashed #ccc;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #ccc;
	padding: 24px 0;
	border-radius: var(--border-radius-base);
	background: #fafafa;

	& > span.__lb-1 {
		font-size: 16px;
		color: rgba(0, 0, 0, 0.65);
		text-align: center;
		height: 25px !important;
	}

	& > span.__lb-2 {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.45);
		text-align: center;
		height: 25px !important;
	}

	& i {
		font-size: 42px !important;
		margin-bottom: 16px;
		color: var(--primary);
	}

	&:hover {
		border-color: var(--primary);
	}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none !important;
			opacity: 0.85;

			& * {
				pointer-events: none !important;
			}
		`}
`;

export const Input = styled.input`
	display: none !important;
`;

export const UploadsList = styled.ul`
	margin-top: 8px;
	width: 100%;

	& > li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 4px 8px;
		list-style: none;
		border-bottom: 1px solid #dedede;

		& > div {
			display: flex;
			align-items: center;

			& > i {
				font-size: 14px;
				margin-right: 6px;
			}
		}

		& i.__remove {
			font-size: 14px;
			margin-right: 6px;

			&:hover {
				color: red;
			}
		}

		&:last-child {
			border-bottom: none;
		}
	}
`;

export const Amount = styled.span`
	font-size: 12px;
	margin-top: 12px;
	margin-right: 36px;
	color: rgba(0, 0, 0, 0.45);
	width: 100%;
	text-align: right;
`;
