import React, { useEffect, useState, useCallback, useMemo } from 'react';
import path from 'path';
import * as Yup from 'yup';
import {
	Table,
	Button,
	Modal,
	Input,
	Badge,
	Dropdown,
	Menu,
	Typography,
	Icon,
	message,
	Divider,
} from 'antd';

import FileExt from '../FileExt';
import FileDuration from '../FileDuration';
import Form from '../Form';
import { TabHeader } from './styles';

import ElementsAPI from '../../services/sdks/elements';
import { useDownload, useFilesValidator, usePlayer } from '../../hooks';
import { FiPlusCircle, FiXCircle, FiUpload, FiPlus } from 'react-icons/fi';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';
import FilesUploader from '../FilesUploader';

const CustomElements = ({ data, setData, program, user }) => {
	const player = usePlayer();
	const download = useDownload();
	const { hasValidationError } = useFilesValidator();
	const [fallback, setFallback] = useState(null);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [file, setFile] = useState(null);
	const [name, setName] = useState('');
	const [color, setColor] = useState('#000000');
	const [isValidating, setIsValidating] = useState(false);

	const isUploadButtonDisabled = useMemo(() => {
		if (!name || !file || !color || isValidating) {
			return true;
		}

		return false;
	}, [color, file, isValidating, name]);

	const isSharedProgram = useMemo(() => {
		if (!program || !user) {
			return true;
		}

		return program?.userId?._id !== user?._id;
	}, [program, user]);

	const columns = [
		{
			title: 'ID',
			dataIndex: '_id',
			key: 'id',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					8
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Nome',
			key: 'name',
			render: (element) => (
				<span>
					<Badge color={element.color} />
					{element.name}
				</span>
			),
		},
		{
			title: 'Duração',
			key: 'duration',
			render: (element) => <FileDuration src={resolveFileSrc({ fileName: element.filename })} />,
		},

		{
			title: 'Formato',
			key: 'format',
			render: (element) => <FileExt src={resolveFileSrc({ fileName: element.filename })} />,
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'actions',
			render: ({ _id: elementId, filename, name }) => (
				<Dropdown
					placement='bottomRight'
					overlay={
						<Menu>
							<Menu.Item
								key='play-off'
								onClick={() => {
									player.start({
										ref: elementId,
										src: resolveFileSrc({ fileName: filename }),
										meta: { name: name, artist: program?.name },
									});
								}}>
								<Icon type='play-circle' /> Reproduzir
							</Menu.Item>
							<Menu.Item
								key='download'
								disabled={!program?.isEditable || isSharedProgram}
								onClick={() => handleDownload({ filename, name })}>
								<Icon type='download' /> Fazer download
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								key='delete'
								className='ant-dropdown-menu-item-danger'
								disabled={!program?.isEditable || isSharedProgram}
								onClick={() => {
									Modal.confirm({
										title: 'Excluir elemento?',
										icon: 'exclamation-circle',
										content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
										okText: 'Excluir',
										onOk: () => handleDeleteCustomElement({ elementId }),
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<Icon type='delete' /> Excluir
							</Menu.Item>
						</Menu>
					}>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleDownload = useCallback(
		async ({ filename, name }) => {
			try {
				const ext = path.extname(filename);
				await download({ filename, name: `${name}${ext}` });
			} catch (error) {
				console.error(error);
			}
		},
		[download]
	);

	const handleCreateCustomElement = useCallback(async () => {
		try {
			if (hasValidationError([file])) {
				return message.warning('O arquivo selecionado está corrompidos. Por favor, substitua-o.');
			}

			setFallback('creating-element');

			const payload = new FormData();

			payload.append('elementName', name);
			payload.append('color', color);
			payload.append('file', file.data);
			payload.append('type', 'CUSTOM');
			payload.append('category', 'CUSTOM');
			payload.append('programId', program._id);
			payload.append('userId', user?._id);

			const {
				data: { element: newElement },
			} = await ElementsAPI.custom.store({ payload });

			setFile(null);
			setName('');
			setColor('#000000');
			setData((prev) => [...prev, newElement]);
			setShowUploadModal(false);
			setFallback(null);

			return message.success('Elemento criado com sucesso!');
		} catch (error) {
			setFallback(null);

			if (error instanceof Yup.ValidationError) {
				return message.error(error.message);
			}

			const errorMessage = error?.response?.data?.message;

			if (errorMessage) {
				message.error(errorMessage);
			}

			console.error(error);
		}
	}, [name, color, file, hasValidationError, program, user, setData]);

	const handleDeleteCustomElement = useCallback(
		async ({ elementId }) => {
			try {
				setFallback(`deleting-element-${elementId}`);

				await ElementsAPI.destroy({ elementId });

				setData(data.filter(({ _id }) => _id !== elementId));
				setFallback(null);

				return message.success('Elemento deletada com sucesso!');
			} catch (error) {
				console.error(error);
			}
		},
		[data, setData]
	);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const query = `program=${program?._id}&type=CUSTOM`;
				const {
					data: { elements },
				} = await ElementsAPI.custom.index({ query });

				setData(elements);
			} catch (error) {
				console.error(error);
				message.error(error);
			}
		};

		if (data === null) {
			fetchData();
		}
	}, [data, setData, program, user]);

	return (
		<>
			{program?.isEditable && program?.userId?._id === user?._id && (
				<TabHeader>
					<div>
						<span></span>
						<Button onClick={() => setShowUploadModal(true)}>
							<FiPlus /> Novo Elemento
						</Button>
					</div>
				</TabHeader>
			)}

			<Table
				rowKey='_id'
				pagination={{ size: 'large' }}
				style={{ border: 'none' }}
				loading={data === null}
				size='middle'
				columns={columns}
				dataSource={data}
				rowClassName='__row'
			/>

			<Modal
				destroyOnClose
				visible={showUploadModal}
				onOk={handleCreateCustomElement}
				okButtonProps={{
					loading: fallback === 'creating-element',
					disabled: isUploadButtonDisabled,
				}}
				cancelButtonProps={{ disabled: fallback === 'creating-element' }}
				okText={
					<>
						<FiUpload />
						Fazer upload
					</>
				}
				cancelText={
					<>
						<FiXCircle />
						Cancelar
					</>
				}
				title={
					<>
						<FiPlusCircle />
						Novo elemento customizado
					</>
				}
				onCancel={() => setShowUploadModal(false)}>
				<Form.Container>
					<FilesUploader
						multiple={false}
						onChange={(files) => setFile(files[0])}
						onStartValidation={() => setIsValidating(true)}
						onEndValidation={() => setIsValidating(false)}
						validTypes={['.mp3', '.wav']}
					/>

					<Divider />

					<Form.Item label='Nome'>
						<Input
							placeholder='Informe um nome para o elemento'
							value={name}
							onChange={({ target: { value } }) => setName(value)}
						/>
					</Form.Item>

					<Form.Item label='Cor do elemento'>
						<Input
							type='color'
							value={color}
							style={{ cursor: 'pointer' }}
							onChange={({ target: { value } }) => setColor(value)}
						/>
					</Form.Item>
				</Form.Container>
			</Modal>
		</>
	);
};

export default CustomElements;
