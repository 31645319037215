import styled from 'styled-components';

export const Container = styled.div`
	padding: 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const SearchContainer = styled.header`
	width: 100%;
	display: flex;
	align-items: center;
	padding-bottom: 16px;
	justify-content: center;
`;

export const FilterContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 12px;
`;

export const AvatarCell = styled.div`
	display: flex;
	align-items: center;

	& > .ant-typography {
		margin-left: 10px;
	}

	& .name {
		cursor: pointer;

		&:hover {
			color: var(--primary);
			text-decoration: underline;
		}
	}
`;

export const DetailsContainer = styled.div`
	width: 100%;

	& > div.desc {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	& > ul {
		width: 100%;

		& > li {
			padding: 16px 12px;
			border-bottom: 1px dashed #dedede;
			display: flex;
			align-items: center;
			justify-content: space-between;

			& > strong {
				font-size: 16px;
			}

			& > span.label {
				font-size: 15px;
				font-weight: 300;
			}
		}
	}
`;
