import styled, { css } from 'styled-components';

export const Container = styled.div`
	padding: 8px;
	border: 1px solid ${({ checked }) => (checked ? 'var(--primary)' : '#d9d9d9')};
	display: flex;
	border-radius: var(--border-radius-base);
	flex-direction: column;
	height: 100%;
	cursor: pointer;
	transition: 300ms;
	margin-bottom: 12px;

	&:hover {
		border-color: var(--primary);
	}

	${({ checked }) =>
		checked &&
		css`
			box-shadow: 0px 0px 0px 2px var(--primary);
		`}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.6;
			pointer-events: none !important;

			& * {
				pointer-events: none !important;
			}
		`}
`;

export const Header = styled.header`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Label = styled.span`
	font-weight: 600;
	font-size: 12px;
	display: flex;
	align-items: center;
	color: var(--primary);
	text-transform: uppercase;
	margin-bottom: 6px;

	& svg {
		margin-right: 8px;
		font-size: 16px;
	}
`;

export const Title = styled.p`
	font-weight: 600;
	font-size: 24px;
	margin: 16px 0 4px 0;
`;

export const Descriptions = styled.ul`
	width: 100%;
	margin: 0;

	& > li {
		list-style: none;
		font-size: 12px;
		opacity: 0.8;
	}
`;
