export const tkPlayTypes = [
	'TK-PLAY-1',
	'TK-PLAY-2',
	'TK-PLAY-3',
	'TK-PLAY-4',
	'TK-PLAY-5',
	'TK-PLAY-6',
	'TK-PLAY-7',
	'TK-PLAY-8',
	'TK-PLAY-9',
	'TK-PLAY-10',
	'TK-PLAY-11',
	'TK-PLAY-12',
	'TK-PLAY-13',
	'TK-PLAY-14',
	'TK-PLAY-15',
	'TK-PLAY-16',
	'TK-PLAY-17',
	'TK-PLAY-18',
	'TK-PLAY-19',
	'TK-PLAY-20',
	'TK-PLAY-21',
	'TK-PLAY-22',
	'TK-PLAY-23',
	'TK-PLAY-24',
	'TK-PLAY-25',
	'TK-PLAY-26',
	'TK-PLAY-27',
	'TK-PLAY-28',
	'TK-PLAY-29',
	'TK-PLAY-30',
	'TK-PLAY-31',
	'TK-PLAY-32',
	'TK-PLAY-33',
	'TK-PLAY-34',
	'TK-PLAY-35',
	'TK-PLAY-36',
	'TK-PLAY-37',
	'TK-PLAY-38',
	'TK-PLAY-39',
	'TK-PLAY-40',
	'TK-PLAY-41',
	'TK-PLAY-42',
	'TK-PLAY-43',
	'TK-PLAY-44',
	'TK-PLAY-45',
	'TK-PLAY-46',
	'TK-PLAY-47',
	'TK-PLAY-48',
	'TK-PLAY-49',
	'TK-PLAY-50',
	'TK-PLAY-51',
	'TK-PLAY-52',
	'TK-PLAY-53',
	'TK-PLAY-54',
	'TK-PLAY-55',
	'TK-PLAY-56',
	'TK-PLAY-57',
	'TK-PLAY-58',
	'TK-PLAY-59',
	'TK-PLAY-60',
	'TK-PLAY-61',
	'TK-PLAY-62',
	'TK-PLAY-63',
	'TK-PLAY-64',
	'TK-PLAY-65',
	'TK-PLAY-66',
	'TK-PLAY-67',
	'TK-PLAY-68',
	'TK-PLAY-69',
	'TK-PLAY-70',
	'TK-PLAY-71',
	'TK-PLAY-72',
	'TK-PLAY-73',
	'TK-PLAY-74',
	'TK-PLAY-75',
	'TK-PLAY-76',
	'TK-PLAY-77',
	'TK-PLAY-78',
	'TK-PLAY-79',
	'TK-PLAY-80',
	'TK-PLAY-81',
	'TK-PLAY-82',
	'TK-PLAY-83',
	'TK-PLAY-84',
	'TK-PLAY-85',
	'TK-PLAY-86',
	'TK-PLAY-87',
	'TK-PLAY-88',
	'TK-PLAY-89',
	'TK-PLAY-90',
	'TK-PLAY-91',
	'TK-PLAY-92',
	'TK-PLAY-93',
	'TK-PLAY-94',
	'TK-PLAY-95',
	'TK-PLAY-96',
	'TK-PLAY-97',
	'TK-PLAY-98',
	'TK-PLAY-99',
	'TK-PLAY-100',
	'TK-PLAY-101',
	'TK-PLAY-102',
	'TK-PLAY-103',
	'TK-PLAY-104',
	'TK-PLAY-105',
	'TK-PLAY-106',
	'TK-PLAY-107',
	'TK-PLAY-108',
	'TK-PLAY-109',
	'TK-PLAY-110',
	'TK-PLAY-111',
	'TK-PLAY-112',
	'TK-PLAY-113',
	'TK-PLAY-114',
	'TK-PLAY-115',
	'TK-PLAY-116',
	'TK-PLAY-117',
	'TK-PLAY-118',
	'TK-PLAY-119',
	'TK-PLAY-120',
	'TK-PLAY-121',
	'TK-PLAY-122',
	'TK-PLAY-123',
	'TK-PLAY-124',
	'TK-PLAY-125',
	'TK-PLAY-126',
	'TK-PLAY-127',
	'TK-PLAY-128',
	'TK-PLAY-129',
	'TK-PLAY-130',
	'TK-PLAY-131',
	'TK-PLAY-132',
	'TK-PLAY-133',
	'TK-PLAY-134',
	'TK-PLAY-135',
	'TK-PLAY-136',
	'TK-PLAY-137',
	'TK-PLAY-138',
	'TK-PLAY-139',
	'TK-PLAY-140',
	'TK-PLAY-141',
	'TK-PLAY-142',
	'TK-PLAY-143',
	'TK-PLAY-144',
	'TK-PLAY-145',
	'TK-PLAY-146',
	'TK-PLAY-147',
	'TK-PLAY-148',
	'TK-PLAY-149',
	'TK-PLAY-150',
	'TK-PLAY-151',
	'TK-PLAY-152',
	'TK-PLAY-153',
	'TK-PLAY-154',
	'TK-PLAY-155',
	'TK-PLAY-156',
	'TK-PLAY-157',
	'TK-PLAY-158',
	'TK-PLAY-159',
	'TK-PLAY-160',
	'TK-PLAY-161',
	'TK-PLAY-162',
	'TK-PLAY-163',
	'TK-PLAY-164',
	'TK-PLAY-165',
	'TK-PLAY-166',
	'TK-PLAY-167',
	'TK-PLAY-168',
	'TK-PLAY-169',
	'TK-PLAY-170',
	'TK-PLAY-171',
	'TK-PLAY-172',
	'TK-PLAY-173',
	'TK-PLAY-174',
	'TK-PLAY-175',
	'TK-PLAY-176',
	'TK-PLAY-177',
	'TK-PLAY-178',
	'TK-PLAY-179',
	'TK-PLAY-180',
	'TK-PLAY-181',
	'TK-PLAY-182',
	'TK-PLAY-183',
	'TK-PLAY-184',
	'TK-PLAY-185',
	'TK-PLAY-186',
	'TK-PLAY-187',
	'TK-PLAY-188',
	'TK-PLAY-189',
	'TK-PLAY-190',
	'TK-PLAY-191',
	'TK-PLAY-192',
	'TK-PLAY-193',
	'TK-PLAY-194',
	'TK-PLAY-195',
	'TK-PLAY-196',
	'TK-PLAY-197',
	'TK-PLAY-198',
	'TK-PLAY-199',
	'TK-PLAY-200',
	'TK-PLAY-201',
	'TK-PLAY-202',
	'TK-PLAY-203',
	'TK-PLAY-204',
	'TK-PLAY-205',
	'TK-PLAY-206',
	'TK-PLAY-207',
	'TK-PLAY-208',
	'TK-PLAY-209',
	'TK-PLAY-210',
	'TK-PLAY-211',
	'TK-PLAY-212',
	'TK-PLAY-213',
	'TK-PLAY-214',
	'TK-PLAY-215',
	'TK-PLAY-216',
	'TK-PLAY-217',
	'TK-PLAY-218',
	'TK-PLAY-219',
	'TK-PLAY-220',
	'TK-PLAY-221',
	'TK-PLAY-222',
	'TK-PLAY-223',
	'TK-PLAY-224',
	'TK-PLAY-225',
	'TK-PLAY-226',
	'TK-PLAY-227',
	'TK-PLAY-228',
	'TK-PLAY-229',
	'TK-PLAY-230',
	'TK-PLAY-231',
	'TK-PLAY-232',
	'TK-PLAY-233',
	'TK-PLAY-234',
	'TK-PLAY-235',
	'TK-PLAY-236',
	'TK-PLAY-237',
	'TK-PLAY-238',
	'TK-PLAY-239',
	'TK-PLAY-240',
	'TK-PLAY-241',
	'TK-PLAY-242',
	'TK-PLAY-243',
	'TK-PLAY-244',
	'TK-PLAY-245',
	'TK-PLAY-246',
	'TK-PLAY-247',
	'TK-PLAY-248',
	'TK-PLAY-249',
	'TK-PLAY-250',
	'TK-PLAY-251',
	'TK-PLAY-252',
	'TK-PLAY-253',
	'TK-PLAY-254',
	'TK-PLAY-255',
	'TK-PLAY-256',
	'TK-PLAY-257',
	'TK-PLAY-258',
	'TK-PLAY-259',
	'TK-PLAY-260',
	'TK-PLAY-261',
	'TK-PLAY-262',
	'TK-PLAY-263',
	'TK-PLAY-264',
	'TK-PLAY-265',
	'TK-PLAY-266',
	'TK-PLAY-267',
	'TK-PLAY-268',
	'TK-PLAY-269',
	'TK-PLAY-270',
	'TK-PLAY-271',
	'TK-PLAY-272',
	'TK-PLAY-273',
	'TK-PLAY-274',
	'TK-PLAY-275',
	'TK-PLAY-276',
	'TK-PLAY-277',
	'TK-PLAY-278',
	'TK-PLAY-279',
	'TK-PLAY-280',
	'TK-PLAY-281',
	'TK-PLAY-282',
	'TK-PLAY-283',
	'TK-PLAY-284',
	'TK-PLAY-285',
	'TK-PLAY-286',
	'TK-PLAY-287',
	'TK-PLAY-288',
	'TK-PLAY-289',
	'TK-PLAY-290',
	'TK-PLAY-291',
	'TK-PLAY-292',
	'TK-PLAY-293',
	'TK-PLAY-294',
	'TK-PLAY-295',
	'TK-PLAY-296',
	'TK-PLAY-297',
	'TK-PLAY-298',
	'TK-PLAY-299',
	'TK-PLAY-300',
];
