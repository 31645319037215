import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Input, message, Typography } from 'antd';

import Meta from '../../../components/Meta';
import Fallback from '../../../components/Fallback';
import { Container, Cover, CoverOverlay, FormContainer, Copyright } from './styles';

import ForgotAPI from '../../../services/sdks/forgot';

import overlaySrc from '../../../assets/images/login/cover.jpg';

const ForgotPassword = () => {
	const { token } = useParams();
	const history = useHistory();

	const [fallback, setFallback] = useState({ verifying: true, redefiningPassword: false });
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');

	const handleSubmit = useCallback(
		async (event) => {
			try {
				event.preventDefault();

				if (!password) return message.error('Por favor, informe sua senha');
				if (!passwordConfirmation) return message.error('Por favor, confirme sua senha');
				if (password.includes(' ')) return message.error('A senha não pode conter espaços');
				if (password !== passwordConfirmation) {
					return message.error('As senhas digitadas não são iguais!');
				}

				setFallback((prev) => ({ ...prev, redefiningPassword: true }));

				await ForgotAPI.redefine(token, password);

				setFallback((prev) => ({ ...prev, redefiningPassword: false }));

				history.push('/login');
				message.success('Sua senha foi alterada com sucesso', 5);
			} catch (error) {
				console.error(error);

				if (error?.response?.status === 404) {
					return message.error('Esse email não foi encontrado');
				}

				message.error('Houve um erro, tente novamente');
			} finally {
				setFallback((prev) => ({ ...prev, redefiningPassword: false }));
			}
		},
		[password, passwordConfirmation, history, token]
	);

	useEffect(() => {
		const verifyToken = async () => {
			try {
				await ForgotAPI.verify(token);

				return setFallback((prev) => ({ ...prev, verifying: false }));
			} catch (error) {
				console.error(error);

				if (error?.response?.status === 401) {
					return history.push('/login');
				}
			}
		};

		verifyToken();
	}, [token, history]);

	if (fallback?.verifying) {
		return <Fallback message='Por favor aguarde...' />;
	}

	return (
		<Container>
			<Meta title='Redefinir senha' />

			<Cover>
				<CoverOverlay src={overlaySrc} />
			</Cover>

			<FormContainer>
				<div className='title-container'>
					<Typography.Title level={2}>Redefinir senha</Typography.Title>
					<Typography.Text level={2}>Informe sua nova senha!</Typography.Text>
				</div>

				<form onSubmit={handleSubmit}>
					<Input.Password
						size='large'
						value={password}
						onChange={({ target: { value } }) => setPassword(value)}
						style={{ width: '100%' }}
						placeholder='Informe sua nova senha'
					/>
					<Input.Password
						size='large'
						value={passwordConfirmation}
						onChange={({ target: { value } }) => setPasswordConfirmation(value)}
						style={{ width: '100%' }}
						placeholder='Confirme a senha'
					/>
					<Button
						size='large'
						type='primary'
						icon='check-circle'
						disabled={!password || !passwordConfirmation}
						loading={fallback?.redefiningPassword}
						onClick={handleSubmit}
					>
						Alterar senha
					</Button>
				</form>

				<Copyright>
					Copyright &trade; {new Date().getFullYear()}{' '}
					<a
						className='__link'
						href='https://talkradio.com.br'
						target='_blank'
						rel='noopener noreferrer'
					>
						TalkRadio
					</a>
				</Copyright>
			</FormContainer>
		</Container>
	);
};

export default ForgotPassword;
