import React from 'react';
import { Icon, Tag, Typography } from 'antd';

import { Container, InputWrapper, Input, UploadsList, Amount } from './styles';

const UploadZone = ({
	id,
	icon,
	uploadIcon,
	label,
	secondaryLabel,
	secondaryLabelProps,
	onChange,
	uploads,
	inputProps,
	iconProps,
	labelProps,
	style,
	onRemoveItem,
	disabled,
	multiple,
}) => {
	return (
		<Container style={style}>
			<InputWrapper htmlFor={id} disabled={disabled}>
				<Input
					id={id}
					name={id}
					onChange={onChange}
					type='file'
					disabled={disabled}
					multiple={multiple}
					{...inputProps}
				/>

				{icon && <Icon className='__icon' type={icon} {...iconProps} />}
				{label && (
					<span className='__lb-1' {...labelProps}>
						{label}
					</span>
				)}
				{secondaryLabel && (
					<span className='__lb-2' {...secondaryLabelProps}>
						{secondaryLabel}
					</span>
				)}
			</InputWrapper>

			{uploads?.length > 1 && (
				<Amount>
					<strong>{uploads?.length}</strong> arquivos selecionados
				</Amount>
			)}

			{uploads?.length > 0 && (
				<UploadsList>
					{[...uploads].map((upload, index) => (
						<li key={index}>
							<div>
								{uploadIcon && <Icon type={uploadIcon} />}
								<Typography.Text delete={!!upload.isDisabled}>{upload?.name}</Typography.Text>
							</div>

							<div>
								{upload.suffix && (
									<Tag color={upload.suffix.color || 'red'}>{upload.suffix.text}</Tag>
								)}

								{typeof onRemoveItem === 'function' && (
									<Icon
										type='close-circle'
										className='__remove'
										onClick={() => onRemoveItem(index)}
									/>
								)}
							</div>
						</li>
					))}
				</UploadsList>
			)}
		</Container>
	);
};

export default UploadZone;
