import React, { useCallback, useState } from 'react';
import { Button, Icon, Input, message, Typography } from 'antd';

import Meta from '../../../components/Meta';
import {
	Container,
	Cover,
	CoverOverlay,
	FormContainer,
	Copyright,
	MailSentContainer,
} from './styles';

import ForgotAPI from '../../../services/sdks/forgot';

import overlaySrc from '../../../assets/images/login/cover.jpg';

const ForgotPassword = () => {
	const [fallback, setFallback] = useState({ sendingEmail: false });
	const [email, setEmail] = useState('');
	const [emailSent, setEmailSent] = useState(false);

	const handleSubmit = useCallback(
		async (event) => {
			try {
				event.preventDefault();

				setFallback((prev) => ({ ...prev, sendingEmail: true }));

				await ForgotAPI.request(email);

				setEmailSent(true);
				message.success('Um email foi enviado');
			} catch (error) {
				console.error(error);

				if (error?.response?.status === 404) {
					return message.error('Esse email não foi encontrado');
				}

				message.error('Houve um erro, tente novamente');
			} finally {
				setFallback((prev) => ({ ...prev, sendingEmail: false }));
			}
		},
		[email]
	);

	return (
		<Container>
			<Meta title='Recuperar senha' />

			<Cover>
				<CoverOverlay src={overlaySrc} />
			</Cover>

			{emailSent ? (
				<MailSentContainer>
					<Icon
						type='check-circle'
						style={{
							color: 'var(--primary)',
							marginRight: 12,
							fontSize: 60,
							marginBottom: 24,
						}}
					/>

					<Typography.Title level={2}>Email enviado com sucesso</Typography.Title>
					<Typography.Text level={2}>
						<p>Verifique sua caixa postal!</p>
						<br />
						<span>
							Caso não encontre a mensagem na caixa de entrada principal, <br />
							verfique as outras abas e também o spam
						</span>
					</Typography.Text>
				</MailSentContainer>
			) : (
				<FormContainer>
					<div className='title-container'>
						<Typography.Title level={2}>Esqueceu a senha?</Typography.Title>
						<Typography.Text level={2}>
							Sem problemas! Informe seu email abaixo, após isso será enviado um link para recuperar
							suas credenciais
						</Typography.Text>
					</div>

					<form onSubmit={handleSubmit}>
						<Input
							size='large'
							value={email}
							onChange={({ target: { value } }) => setEmail(value)}
							style={{ width: '100%' }}
							placeholder='Informe seu email de cadastro'
						/>
						<Button
							size='large'
							type='primary'
							icon='mail'
							disabled={!email}
							loading={fallback?.sendingEmail}
							onClick={handleSubmit}
						>
							Enviar email
						</Button>
					</form>

					<Copyright>
						Copyright &trade; {new Date().getFullYear()}{' '}
						<a
							className='__link'
							href='https://talkradio.com.br'
							target='_blank'
							rel='noopener noreferrer'
						>
							TalkRadio
						</a>
					</Copyright>
				</FormContainer>
			)}
		</Container>
	);
};

export default ForgotPassword;
