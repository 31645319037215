import api from '../api';

const BASE_URL = '/api/v2/tkplay-configs';

const TKPlayConfigAPI = {
	show: () => api.get(`${BASE_URL}`),
	update: (payload) => api.put(`${BASE_URL}`, payload),
};

export default TKPlayConfigAPI;
