import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import TutorialsRoutes from './Tutorials.routes';
import TrackPresentations from '../pages/Commom/TrackPresentations/ManageTrackPresentations';
import CreateTrackPresentations from '../pages/Commom/TrackPresentations/CreateTrackPresentations';
import NotFound from '../pages/Exceptions/_404';

import AppLayout from '../components/Layout';
import TutorialsLayout from '../components/TutorialsLayout';
import { CasterNavigation } from '../components/Navigation';

const CasterStack = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path={['/', '/login', '/commom']}>
				<Redirect to='/commom/track-presentations' />
			</Route>

			<Route exact path='/commom/track-presentations'>
				<AppLayout navigation={<CasterNavigation />}>
					<TrackPresentations />
				</AppLayout>
			</Route>

			<Route exact path='/commom/track-presentations/create'>
				<AppLayout navigation={<CasterNavigation />}>
					<CreateTrackPresentations />
				</AppLayout>
			</Route>

			<TutorialsLayout>
				<TutorialsRoutes />
			</TutorialsLayout>

			<Route exact path='*'>
				<NotFound />
			</Route>
		</Switch>
	</BrowserRouter>
);

export default CasterStack;
