import React from 'react';
import { GetStartedText, Section, Title, ListItem, IconWrapper,SecundaryButton,TercearyButton,QuartryButton,QuintryButton, GetStartedTitle } from './styles';
import { UploadIcon } from './icons';

export default function UploadTeachSection() {
	return (
		<Section>
			<Title style={{ color: '#7F10EE' }}>1. Upload &amp; Ensinamento</Title>
			<ul>
            
            <GetStartedTitle>TREINAMENTO</GetStartedTitle>
				<ListItem>
					<IconWrapper>
						<UploadIcon className='w-5 h-5 mr-2 inline-block' />
					</IconWrapper>
                    <GetStartedText>
					Na fase de configuração, você envia todos os arquivos necessários de reconhecimento de sua estação e programa, ensinando
					o algoritmo a reconhecer os elementos, como:
                    </GetStartedText>
                </ListItem>
				<ListItem>
				
                    <SecundaryButton>Vinhetas</SecundaryButton>
					<p></p>
                    <TercearyButton>Trilhas</TercearyButton>
					<p></p>
					<QuintryButton>Temas  </QuintryButton>
					<p></p>
					<QuartryButton> Músicas </QuartryButton>
				
				</ListItem>
			</ul>
		</Section>
	);
}
