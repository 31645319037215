import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	height: 100%;

	& > div {
		margin-left: 6px;
		display: flex;
		flex-direction: column;

		& > .name {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.65);
		}

		& > .email {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.55);
		}
	}
`;
