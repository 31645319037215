import React, { useCallback, useMemo } from 'react';
import { Button, Checkbox } from 'antd';

import { ModulesContainer, ModuleItem, ModuleSubitems, ButtonsContainer } from '../styles';
import { modulesTranslations } from '../../../../../constants';
import { FiArrowLeft, FiArrowRight, FiSave } from 'react-icons/fi';
import { useUpsertUser } from '../../../../../contexts/UserUpsert';

const Modules = ({ setCurrentStep, submitFn, action, isLoading }) => {
	const {
		permissions,
		modules,
		getModuleState,
		onModuleCheckboxClick,
		onModuleSubitemCheckboxClick,
	} = useUpsertUser();

	const modulesMap = useMemo(() => {
		return Object.entries(modules).map(([key, subitems]) => {
			return {
				key,
				label: modulesTranslations[key],
				subitems: Object.entries(subitems).map(([key, checked]) => ({
					key,
					checked,
					label: modulesTranslations[key],
				})),
				...getModuleState(subitems),
			};
		});
	}, [modules, getModuleState]);

	const handleSubmit = useCallback(async () => {
		await submitFn();
	}, [submitFn]);

	return (
		<>
			<ModulesContainer>
				{modulesMap.map((module) => (
					<React.Fragment key={module.key}>
						<ModuleItem>
							<Checkbox
								indeterminate={module.indeterminate}
								checked={module.checked}
								onClick={() => onModuleCheckboxClick(module.key)}>
								{module.label}
							</Checkbox>

							<ModuleSubitems>
								{module.subitems.map((item) => (
									<Checkbox
										key={item.key}
										checked={item.checked}
										onClick={() => onModuleSubitemCheckboxClick(module.key, item.key)}>
										{item.label}
									</Checkbox>
								))}
							</ModuleSubitems>
						</ModuleItem>
					</React.Fragment>
				))}
			</ModulesContainer>

			{action === 'CREATE' && (
				<ButtonsContainer>
					<Button
						type='default'
						size='large'
						onClick={() => setCurrentStep((prev) => prev - 1)}
						disabled={isLoading}>
						<FiArrowLeft />
						Voltar
					</Button>
					{permissions.PROGRAMS_SHARING ? (
						<Button type='primary' size='large' onClick={() => setCurrentStep((prev) => prev + 1)}>
							<FiArrowRight />
							Continuar
						</Button>
					) : (
						<Button type='primary' size='large' onClick={handleSubmit} loading={isLoading}>
							<FiSave />
							Finalizar Cadastro
						</Button>
					)}
				</ButtonsContainer>
			)}
		</>
	);
};

export { Modules };
