import React, { useState, useEffect } from 'react';
import {
	Section,
	Video,
	Container,
	Heading,
	HighlightedText,
	Paragraph,
	ButtonContainer,
	PrimaryButton,
	SecondaryButton,
} from './styles';


const phrases = [
	'do produtor de áudio',
	'do editor de áudio',
	'do programador musical',
	'do assistente pessoal',
	'do solucionador de problemas',
	'da locutora Avatar',
	'do jornalista Avatar',
	'do locutor local',
	'da publicidade nos programas',
];
const scrollToSection = (id) => {
	document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
};
const Hero = () => {
	const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
		}, 2000); // Troca de frase a cada 2 segundos

		return () => clearInterval(interval);
	}, []);

	return (
		<Section>
			<Video autoPlay loop muted>
				<source src='/radio-studio-background.mp4' type='video/mp4' />
			</Video>
			<Container id='hero'>
				<Heading>Revolucione a sua rádio com a Inteligência Artificial colaborativa </Heading>
				<Heading>
					<HighlightedText>{phrases[currentPhraseIndex]}</HighlightedText>
				</Heading>
				<Paragraph>
					Descubra como a plataforma TalkPlay pode transformar a sua estação de rádio com recursos
					avançados de IA.
				</Paragraph>
				<ButtonContainer>
					<PrimaryButton onClick={() => scrollToSection('salve-audio')}>Saiba Mais</PrimaryButton>
					<SecondaryButton onClick={() => scrollToSection('contato')}>
						Agende uma Demonstração
					</SecondaryButton>
				</ButtonContainer>
			</Container>
		</Section>
	);
};

export default Hero;
