import React, { createContext, useCallback, useContext, useState } from 'react';

export const MobileMenuContext = createContext({
	isOpen: false,
});

export const MobleMenuProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = useCallback(() => {
		setIsOpen((isOpen) => !isOpen);
	}, []);

	return (
		<MobileMenuContext.Provider value={{ isOpen, toggle }}>{children}</MobileMenuContext.Provider>
	);
};

export const useMobileMenu = () => {
	return useContext(MobileMenuContext);
};
