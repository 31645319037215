import {
	Avatar,
	Button,
	Card,
	Dropdown,
	Icon,
	Input,
	Menu,
	message,
	Modal,
	PageHeader,
	Table,
	Typography,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiPlusCircle, FiUploadCloud, FiXCircle } from 'react-icons/fi';
import Fallback from '../../../../components/Fallback';
import Form from '../../../../components/Form';
import UploadZone from '../../../../components/UploadZone';
import DemosAPI from '../../../../services/sdks/demos';
import { Container, NameContainer } from './styles';
import { resolveFileSrc } from '../../../../helpers/fileSrcResolver';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel Administrativo' },
		{ breadcrumbName: 'Geral' },
		{ breadcrumbName: 'Demos IA' },
	],
	style: { marginBottom: 12 },
};

const INITIAL_DEMO = {
	title: '',
	image: null,
	audio: null,
};

const CasterDemos = () => {
	const [demos, setDemos] = useState([]);
	const [fallback, setFallback] = useState({ initialData: true });
	const [showAddDemosModal, setShowAddDemosModal] = useState(false);
	const [newDemo, setNewDemo] = useState(INITIAL_DEMO);

	const invalidNewDemo = useMemo(() => {
		return !newDemo.title || !newDemo.audio || !newDemo.image;
	}, [newDemo]);

	const columns = [
		{
			title: 'Nome',
			key: 'title',
			render: (demo) => (
				<NameContainer>
					<Avatar src={resolveFileSrc({ fileName: demo.image })} />
					<strong>{demo.title}</strong>
				</NameContainer>
			),
		},
		{
			key: 'id',
			title: 'ID',
			align: 'center',
			render: (demo) => (
				<Typography.Text title={demo?._id} copyable={{ text: demo?._id }}>{`${demo?._id.slice(
					0,
					12
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'options',
			render: (demo) => (
				<>
					<Dropdown
						placement='bottomRight'
						overlay={
							<Menu>
								<Menu.Item
									key='delete'
									className='ant-dropdown-menu-item-danger'
									onClick={() => {
										Modal.confirm({
											title: 'Excluir demo?',
											icon: 'exclamation-circle',
											content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
											okText: 'Excluir',
											onOk: () => handleDelete(demo?._id),
											okButtonProps: {
												icon: 'delete',
												type: 'danger',
											},
											cancelText: 'Cancelar',
											cancelButtonProps: {
												icon: 'close-circle',
											},
										});
									}}
								>
									<Icon type='delete' /> Excluir
								</Menu.Item>
							</Menu>
						}
					>
						<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
					</Dropdown>
				</>
			),
		},
	];

	const handleDelete = useCallback(
		async (demoId) => {
			try {
				await DemosAPI.destroy({ demoId });
				setDemos(demos.filter((d) => d._id !== demoId));
				setNewDemo(INITIAL_DEMO);
			} catch (error) {
				console.error(error);
			}
		},
		[demos]
	);

	const fetchDemos = useCallback(async () => {
		try {
			const { data } = await DemosAPI.index('CASTER');
			setDemos(data.demos);
		} catch (error) {
			console.error(error);
		}

		setFallback(false);
	}, []);

	const handleCreateDemo = useCallback(async () => {
		setFallback((f) => ({ ...f, creatingDemo: true }));

		try {
			const payload = new FormData();

			payload.append('type', 'CASTER');
			payload.append('title', newDemo.title);
			payload.append('audio', newDemo.audio);
			payload.append('image', newDemo.image);

			const { data } = await DemosAPI.store({ payload });

			setDemos((d) => [...d, data.demo]);
			message.success('Demo criada com sucesso');
		} catch (error) {
			message.error('Erro ao criar a demo');
			console.error(error);
		}

		setFallback((f) => ({ ...f, creatingDemo: false }));
		setShowAddDemosModal(false);
	}, [newDemo]);

	useEffect(() => {
		fetchDemos();
	}, [fetchDemos]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<PageHeader
				title='Demos IA'
				breadcrumb={breadcrumb}
				extra={[
					<Button
						key='create-demo'
						type='ghost'
						size='large'
						icon='plus-circle'
						onClick={() => {
							return setShowAddDemosModal(true);
						}}
					>
						Adicionar demo
					</Button>,
				]}
			/>

			<Container>
				<Card>
					<Table
						pagination={{ pageSize: 20, size: 'large', hideOnSinglePage: true }}
						rowKey='_id'
						size='middle'
						style={{ border: 'none' }}
						columns={columns}
						dataSource={demos}
					/>
				</Card>
			</Container>

			<Modal
				width={600}
				destroyOnClose
				visible={showAddDemosModal}
				onCancel={() => {
					setNewDemo(INITIAL_DEMO);
					setShowAddDemosModal(false);
				}}
				onOk={handleCreateDemo}
				okButtonProps={{
					loading: fallback?.creatingDemo,
					disabled: invalidNewDemo,
				}}
				cancelButtonProps={{ disabled: fallback?.creatingDemo }}
				okText={
					<>
						<FiUploadCloud /> Enviar demo
					</>
				}
				cancelText={
					<>
						<FiXCircle /> Cancelar
					</>
				}
				title={
					<>
						<FiPlusCircle /> Adicionar demo
					</>
				}
			>
				<Form.Container>
					<Form.Item label='Título'>
						<Input
							value={newDemo?.title}
							placeholder='Informe o nome do locutor'
							onChange={(e) => setNewDemo({ ...newDemo, title: e.target.value })}
						/>
					</Form.Item>

					<UploadZone
						id='audio'
						disabled={false}
						multiple={false}
						inputProps={{ accept: 'audio/mp3, audio/wav', multiple: false }}
						label='Clique para selecionar o arquivo de áudio'
						icon='paper-clip'
						secondaryLabel={
							<p>
								Selecione um arquivo com a extensão <strong>MP3</strong> ou <strong>WAV</strong>
							</p>
						}
						uploads={newDemo.audio ? [newDemo.audio] : []}
						onChange={({ target: { files } }) => setNewDemo({ ...newDemo, audio: files[0] })}
						onRemoveItem={() => setNewDemo({ ...newDemo, audio: null })}
					/>

					<UploadZone
						style={{ marginTop: 25 }}
						id='image'
						disabled={false}
						multiple={false}
						inputProps={{ accept: 'image/png', multiple: false }}
						label='Clique para selecionar a imagem'
						icon='paper-clip'
						secondaryLabel={
							<p>
								Selecione um arquivo com a extensão <strong>PNG</strong>
							</p>
						}
						uploads={newDemo.image ? [newDemo.image] : []}
						onChange={({ target: { files } }) => setNewDemo({ ...newDemo, image: files[0] })}
						onRemoveItem={() => setNewDemo({ ...newDemo, image: null })}
					/>
				</Form.Container>
			</Modal>
		</>
	);
};

export default CasterDemos;
