import React, { useState, useCallback, useEffect, useMemo } from 'react';
import path from 'path';
import { Link } from 'react-router-dom';
import {
	Table,
	Button,
	Modal,
	Dropdown,
	Menu,
	Icon,
	Typography,
	Badge,
	Select,
	message,
} from 'antd';

import FileExt from '../FileExt';
import FileDuration from '../FileDuration';
import PageSizeHandler from '../PageSizeHandle';
import { FiltersContainer, TableHeader } from './styles';

import ElementsAPI from '../../services/sdks/elements';

import { useDownload, useElement, usePlayer, useZip } from '../../hooks';
import { FiPlus, FiDownload, FiTrash2 } from 'react-icons/fi';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';
import userTypes from '../../constants/userTypes';

const Vignettes = ({ data, setData, program, user }) => {
	const zip = useZip();
	const player = usePlayer();
	const download = useDownload();
	const { categorizedTypes, parseElementName, getElementColor } = useElement();

	const [selectedElements, setSelectedElements] = useState([]);
	const [fallback, setFallback] = useState({ fetchingData: true });
	const [filters, setFilters] = useState({ type: undefined });
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: null });

	const isSharedProgram = useMemo(() => {
		if (!program || !user) {
			return true;
		}

		return program?.userId?._id !== user?._id;
	}, [program, user]);

	const columns = [
		{
			title: 'Nome',
			key: 'name',
			render: (element) => (
				<span>
					<Badge color={element.color} />
					{element.name}
				</span>
			),
		},
		{
			title: 'Duração',
			key: 'duration',
			align: 'center',
			render: (element) => <FileDuration src={resolveFileSrc({ fileName: element.filename })} />,
		},
		{
			title: 'Formato',
			key: 'ext',
			align: 'center',
			render: (element) => <FileExt src={resolveFileSrc({ fileName: element.filename })} />,
		},
		{
			title: 'ID',
			dataIndex: '_id',
			align: 'center',
			key: 'id',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					8
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Ações',
			align: 'right',
			key: 'actions',
			render: ({ _id, filename, name }) => (
				<Dropdown
					placement='bottomRight'
					overlay={
						<Menu>
							<Menu.Item
								key='play-off'
								onClick={() => {
									player.start({
										ref: _id,
										src: resolveFileSrc({ fileName: filename }),
										meta: { name: name, artist: program?.name },
									});
								}}>
								<Icon type='play-circle' /> Reproduzir
							</Menu.Item>
							<Menu.Item
								key='download'
								disabled={user?.type !== userTypes.ADMIN || isSharedProgram}
								onClick={() => handleDownload(filename, name)}>
								<Icon type='download' /> Fazer download
							</Menu.Item>

							<Menu.Divider />

							<Menu.Item
								key='delete'
								className='ant-dropdown-menu-item-danger'
								disabled={user?.type !== userTypes.ADMIN || !program?.isEditable || isSharedProgram}
								onClick={() => {
									Modal.confirm({
										title: 'Excluir elemento?',
										icon: 'exclamation-circle',
										content: 'Essa ação não poderá ser revertida, deseja continuar mesmo assim?',
										okText: 'Excluir',
										onOk: () => handleDelete({ elementId: _id }),
										okButtonProps: {
											icon: 'delete',
											type: 'danger',
										},
										cancelText: 'Cancelar',
										cancelButtonProps: {
											icon: 'close-circle',
										},
									});
								}}>
								<Icon type='delete' /> Excluir
							</Menu.Item>
						</Menu>
					}>
					<Icon style={{ cursor: 'pointer', fontSize: 20, marginRight: 16 }} type='more' />
				</Dropdown>
			),
		},
	];

	const handleDownload = useCallback(
		async (filename, name) => {
			try {
				const ext = path.extname(filename);

				await download({ filename, name: `${name}${ext}` });
			} catch (error) {
				console.error(error);
			}
		},
		[download]
	);

	const handleDelete = useCallback(
		async ({ elementId }) => {
			try {
				await ElementsAPI.destroy({ elementId });

				setData(data.filter(({ _id }) => _id !== elementId));

				return message.success('Vinheta deletada com sucesso!');
			} catch (error) {
				console.error(error);
				message.error('Houve um erro, tente novamente');
			}
		},
		[data, setData]
	);

	const handleMultiDelete = useCallback(async () => {
		for (let index = 0; index < selectedElements.length; index++) {
			const { _id: elementId } = selectedElements[index];
			await handleDelete({ elementId });
		}

		setSelectedElements([]);
	}, [selectedElements, handleDelete]);

	const handleDownloadAsZIP = useCallback(async () => {
		setFallback((prev) => ({ ...prev, multiDownload: true }));

		const zipName = `VINHETAS - ${program?.name}`;
		const files = selectedElements.map((element) => ({
			url: resolveFileSrc({ fileName: element.filename }),
			name: parseElementName({ type: element?.type }),
		}));

		try {
			await zip.download(files, zipName);
		} catch (error) {
			message.error('Houve um erro ao baixar os arquivos');
		}

		setFallback((prev) => ({ ...prev, multiDownload: false }));
	}, [program.name, selectedElements, zip, parseElementName]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingData: true }));

				const orderedElements = [];
				const groupedElements = {
					'VIGNETTE-PROGRAM-INTRO': [],
					'VIGNETTE-BREAK': [],
					'VIGNETTE-PROGRAM-OUTRO': [],
					'VIGNETTE-BLOCK-INTRO': [],
					'VIGNETTE-BLOCK-OUTRO': [],
				};

				const paginationQuery = `page=${pagination?.current - 1}&limit=${pagination?.pageSize}&`;
				const query = filters?.type
					? `program=${program?._id}&category=VIGNETTE&type=${filters?.type}&${paginationQuery}`
					: `program=${program?._id}&category=VIGNETTE&${paginationQuery}`;

				const {
					data: { elements, total },
				} = await ElementsAPI.index({ query });

				elements.forEach((element) => {
					const color = getElementColor({ type: element.type });
					const elementName = parseElementName({ type: element.type });
					const elementNumber = groupedElements[element.type].length + 1;
					const name = `${elementName} #${elementNumber}`;

					groupedElements[element.type].push({ ...element, name, color });
				});

				for (const key in groupedElements) {
					if (Object.hasOwnProperty.call(groupedElements, key)) {
						const elementsGroup = groupedElements[key];

						orderedElements.push(...elementsGroup);
					}
				}

				setData(orderedElements);
				setPagination((prev) => ({ ...prev, total }));
			} catch (error) {
				console.error(error);
				message.error(error);
			} finally {
				setFallback((prev) => ({ ...prev, fetchingData: false }));
			}
		};

		fetchData();
		// eslint-disable-next-line
	}, [
		filters,
		setData,
		program,
		pagination.current,
		pagination.pageSize,
		parseElementName,
		getElementColor,
	]);

	useEffect(() => {
		setPagination((prev) => ({ ...prev, current: 1 }));
	}, [filters, pagination.pageSize]);

	return (
		<>
			<FiltersContainer>
				<div className='filters'>
					<Select
						placeholder='Filtrar por tipo'
						value={filters?.type}
						onChange={(value) => setFilters({ ...filters, type: value })}
						style={{ width: 300 }}>
						<Select.Option value={undefined} key='ALL'>
							TODOS
						</Select.Option>

						{categorizedTypes.programVignettes.map((type) => (
							<Select.Option value={type} key={type}>
								{parseElementName({ type })}
							</Select.Option>
						))}
					</Select>
				</div>
				{!program?.isEditable || isSharedProgram ? (
					<Button disabled>
						<FiPlus /> Nova Vinheta
					</Button>
				) : (
					<Link to={`/commom/elements/create?program=${program._id}`}>
						<Button>
							<FiPlus /> Nova Vinheta
						</Button>
					</Link>
				)}
			</FiltersContainer>

			<TableHeader>
				<div className='actions'>
					<span>
						Quantidade: <strong>{pagination?.total}</strong>
					</span>
					<div>
						<Button
							size='small'
							disabled={!selectedElements.length}
							type='ghost'
							onClick={handleDownloadAsZIP}
							loading={fallback?.multiDownload}>
							<FiDownload /> Baixar selecionados
							{selectedElements.length !== 0 && `(${selectedElements.length})`}
						</Button>

						<Button
							size='small'
							disabled={!selectedElements.length}
							type='danger'
							onClick={() => {
								Modal.confirm({
									title: 'Deletar vinhetas selecionadas?',
									type: 'danger',
									content:
										'Todos as vinhetas selecionados serão excluídos e essa ação não poderá ser revertida, deseja continuar mesmo assim?',
									okText: 'Deletar',
									onOk: handleMultiDelete,
									okButtonProps: {
										icon: 'delete',
										type: 'danger',
									},
									cancelText: 'Cancelar',
									cancelButtonProps: {
										icon: 'close-circle',
									},
								});
							}}>
							<FiTrash2 /> Deletar selecionados
							{selectedElements.length !== 0 && `(${selectedElements.length})`}
						</Button>
					</div>
				</div>

				<PageSizeHandler pagination={pagination} setPagination={setPagination} />
			</TableHeader>

			<Table
				rowKey='_id'
				style={{ border: 'none' }}
				loading={fallback?.fetchingData}
				size='middle'
				columns={columns}
				dataSource={data}
				rowClassName='__row'
				pagination={{
					...pagination,
					size: 'large',
					onChange: (current) => setPagination({ ...pagination, current }),
				}}
				rowSelection={
					!isSharedProgram &&
					user?.type === userTypes.ADMIN &&
					program?.isEditable && {
						onChange: (_, selectedRows) => setSelectedElements(selectedRows),
					}
				}
			/>
		</>
	);
};

export default Vignettes;
