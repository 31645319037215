import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
`;

export const Blocks = styled.ul`
	width: 100%;
	border-radius: var(--border-radius-base);
`;

export const Block = styled.li`
	margin: 8px 0;
	border: 1px solid var(--primary);
	border-radius: var(--border-radius-base);
	list-style: none;

	& > header {
		border-radius: 6px 6px 0 0;
		padding: 8px 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--primary);
		background: var(--primary);

		& > p {
			color: #fff;
			font-size: 15px;
			margin: 0;
			font-weight: 500;
		}

		& > span {
			color: #f6e0ff;
			font-size: 13px;
			font-style: italic;
		}
	}
`;

export const Tracks = styled.table`
	width: 100%;
`;

export const Track = styled.tr`
	border-bottom: 1px solid var(--primary-opacity-25);

	& > td {
		padding: 4px 8px;

		& > p {
			margin: 0;
			font-size: 14px;
		}

		& > strong {
			font-size: 14px;
		}

		& > span {
			font-size: 13px;
			font-style: italic;
			opacity: 0.8;
		}

		& > span.index {
			font-size: 13px;
			font-style: italic;
			font-weight: 500;
		}
	}

	&:nth-child(even) {
		background: #fbf6ff;
	}

	&:nth-child(odd) {
		background: #f8eeff;
	}

	&:last-child {
		border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);

		& td:first-child {
			border-radius: 0 0 0 9px;
		}

		& td:last-child {
			border-radius: 0 0 9px 0;
		}
	}
`;
