import styled from 'styled-components';

export const Container = styled.div`
	padding: 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const FormContainer = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;

	& > button {
		width: 100%;
	}
`;

export const SelectContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	& > * {
		width: 450px;
		max-width: 100%;
	}
`;

export const OptionContent = styled.span`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > span {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`;
