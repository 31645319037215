export function setUser(data) {
	return {
		type: 'SET_USER',
		data,
	};
}

export function updateUser(state, data) {
	return {
		type: 'UPDATE_USER',
		state,
		data,
	};
}

export function logout() {
	return {
		type: 'LOGOUT',
	};
}
