import api from '../api';

const BASE_URL = '/api/v2/observations';

const ObservationsApi = {
	show: (query) => api.get(`${BASE_URL}?${query}`),
	store: (payload) => api.post(BASE_URL, payload),
	update: (dailyRecordInfoId, payload) => api.put(`${BASE_URL}/${dailyRecordInfoId}`, payload),
};

export default ObservationsApi;
