import styled from 'styled-components';

export const Container = styled.div`
	padding: 16px;

	& > .ant-card {
		margin: 12px 0;
	}
`;

export const TableHeader = styled.header`
	width: 100%;
	display: flex;
	align-items: center;
	padding-bottom: 16px;
	justify-content: center;

	& > * {
		width: 350px !important;
	}
`;

export const highlightStyle = {
	background: '#ed71ff',
	color: '#3a3a3a',
};
