import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageHeader, Card, Steps, Typography, message } from 'antd';
import { useHistory } from 'react-router-dom';

import Meta from '../../../../components/Meta';
import { Container, StepContentContainer, StepsContainer } from './styles';

import CastersApi from '../../../../services/sdks/caster';
import UsersAPI from '../../../../services/sdks/user';
import Fallback from '../../../../components/Fallback';
import { PersonalInfos } from './Steps/PersonalInfos';
import { AccountInfos } from './Steps/AccountInfos';
import { Permissions } from './Steps/Permissions';
import { Modules } from './Steps/Modules';
import { Network } from './Steps/Network';
import { useUpsertUser } from '../../../../contexts/UserUpsert';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Usuários' },
		{ breadcrumbName: 'Novo Usuário' },
	],
	style: { marginBottom: 12 },
};

const NewUser = () => {
	const history = useHistory();
	const [currentStep, setCurrentStep] = useState(0);
	const [fallback, setFallback] = useState({ initialData: true });
	const [casters, setCasters] = useState([]);
	const [users, setUsers] = useState([]);
	const [isEmailTaken, setIsEmailTaken] = useState(false);
	const { permissions, getData } = useUpsertUser();

	const handleSubmit = useCallback(async () => {
		setIsEmailTaken(false);
		setFallback((prev) => ({ ...prev, creatingUser: true }));

		try {
			const response = await UsersAPI.create(getData());
			const userId = response.data.user._id;
			message.success('Usuário criado com sucesso', 3, () =>
				history.push(`/admin/users/${userId}/details`)
			);
		} catch (error) {
			setFallback((prev) => ({ ...prev, creatingUser: false }));

			if (error?.status === 409) {
				setIsEmailTaken(true);
				setCurrentStep(0);
				message.error('O email informado já está em uso');
			} else {
				message.error('Ocorreu um erro, tente novamente');
			}
		}
	}, [getData, history]);

	useEffect(() => {
		const fetchInitialData = async () => {
			setFallback((prev) => ({ ...prev, initialData: true }));

			try {
				const {
					data: { casters },
				} = await CastersApi.index();

				const {
					data: { users },
				} = await UsersAPI.index(`active=true`);

				setCasters(casters);
				setUsers(users);
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os dados, tente novamente');
			}

			setFallback((prev) => ({ ...prev, initialData: false }));
		};

		fetchInitialData();
	}, []);

	const stepContents = useMemo(() => {
		return {
			0: (
				<PersonalInfos
					action='CREATE'
					isEmailTaken={isEmailTaken}
					setCurrentStep={setCurrentStep}
				/>
			),
			1: <AccountInfos action='CREATE' casters={casters} setCurrentStep={setCurrentStep} />,
			2: <Permissions action='CREATE' setCurrentStep={setCurrentStep} />,
			3: (
				<Modules
					action='CREATE'
					setCurrentStep={setCurrentStep}
					submitFn={handleSubmit}
					isLoading={fallback.creatingUser}
				/>
			),
			4: permissions.PROGRAMS_SHARING ? (
				<Network
					action='CREATE'
					users={users}
					setCurrentStep={setCurrentStep}
					submitFn={handleSubmit}
					isLoading={fallback.creatingUser}
				/>
			) : null,
		};
	}, [casters, permissions, isEmailTaken, fallback, users, handleSubmit]);

	const steps = useMemo(
		() => [
			{ title: 'Dados Pessoais', description: 'Dados pessoais do usuário' },
			{ title: 'Dados da Conta', description: 'Dados referentes à conta' },
			{ title: 'Permissões', description: 'Permissões do usuário' },
			{ title: 'Módulos', description: 'Funcionalidades disponíveis' },
			permissions.PROGRAMS_SHARING
				? { title: 'Rede', description: 'Rádios na rede do usuário' }
				: null,
		],
		[permissions]
	);

	if (fallback.initialData) {
		return <Fallback title='Carregando' message='Por favor, aguarde...' />;
	}

	return (
		<>
			<Meta title='Novo usuário' />

			<PageHeader title='Novo Usuário' breadcrumb={breadcrumb}>
				<Typography.Text>Cadastrar novo usuário na TalkPlay</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<StepsContainer>
						<Steps direction='vertical' current={currentStep}>
							{steps.map((step) =>
								step ? (
									<Steps.Step key={step.title} title={step.title} description={step.description} />
								) : null
							)}
						</Steps>
						<StepContentContainer>{stepContents[currentStep]}</StepContentContainer>
					</StepsContainer>
				</Card>
			</Container>
		</>
	);
};

export default NewUser;
