import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import * as Yup from 'yup';
import TextArea from 'antd/lib/input/TextArea';
import {
	PageHeader,
	Table,
	Card,
	Icon,
	Select,
	Modal,
	Typography,
	Input,
	Divider,
	Spin,
	message,
	Dropdown,
	Menu,
	Avatar,
	Tag,
	Tabs,
	DatePicker,
} from 'antd';

import Meta from '../../../components/Meta';
import Fallback from '../../../components/Fallback';
import { Container, SearchContainer, CasterCell, DetailsContainer } from './styles';

import ContractsAPI from '../../../services/sdks/contracts';
import BilletsAPI from '../../../services/sdks/billets';
import ObservationsAPI from '../../../services/sdks/observations';

import { resolveFileSrc } from '../../../helpers/fileSrcResolver';
import { useFormatter } from '../../../hooks';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Início' },
		{ breadcrumbName: 'Contratos' },
		{ breadcrumbName: 'Gerenciar Contratos' },
	],
	style: { marginBottom: 12 },
};

const ManageContracts = ({ user }) => {
	const toCurrencyFormat = useFormatter({ style: 'currency', currency: 'BRL' });

	const [fallback, setFallback] = useState({ initialData: true });
	const [contracts, setContracts] = useState([]);
	const [filteredContracts, setFilteredContracts] = useState([]);
	const [contract, setContract] = useState(null);
	const [billets, setBillets] = useState(null);
	const [showModals, setShowModals] = useState({ restore: false, observation: false });
	const [restore, setRestore] = useState({ duration: 6, contract: null });
	const [observation, setObservation] = useState({
		date: null,
		content: '',
		contract: null,
		isUpdate: false,
		storedObservationId: null,
	});

	const columns = [
		{
			title: 'ID',
			dataIndex: '_id',
			key: 'id',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					5
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Locutor contratado',
			dataIndex: 'caster',
			key: 'caster',
			render: (caster) => (
				<CasterCell>
					<Avatar
						style={{ background: 'var(--primary)' }}
						src={resolveFileSrc({ fileName: caster.profilePic })}
					>
						{caster?.name[0] || '-'} {caster?.surname[0] || '-'}
					</Avatar>
					<div className='caster-name'>
						<Typography.Text className='name'>
							{caster?.name} {caster?.surname}
						</Typography.Text>
						<Typography.Text className='email'>{caster?.email}</Typography.Text>
					</div>
				</CasterCell>
			),
		},
		{
			title: 'Programa',
			dataIndex: 'program',
			key: 'program',
			render: (program) => program?.name,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			align: 'center',
			render: (status) => {
				return [0, 3].includes(status) ? (
					<Tag color='gold'>
						<Icon type='clock-circle' /> Aguardando aprovação
					</Tag>
				) : status === 1 ? (
					<Tag color='green'>
						<Icon type='check-circle' /> Vigente
					</Tag>
				) : (
					<Tag color='red'>
						<Icon type='stop' /> Encerrado
					</Tag>
				);
			},
		},
		{
			title: 'Ações',
			align: 'center',
			key: 'options',
			render: (contract) => (
				<Dropdown
					placement='bottomLeft'
					overlay={
						<Menu>
							<Menu.Item onClick={() => setContract(contract)}>
								<Icon type='eye' /> Mais detalhes
							</Menu.Item>
							<Menu.Item
								disabled={contract?.status !== 2}
								onClick={() => {
									setShowModals({ ...showModals, restore: true });
									setRestore({ ...restore, contract: contract?._id });
								}}
							>
								<Icon type='retweet' /> Requisitar renovação
							</Menu.Item>
							<Menu.Item
								disabled={contract?.status !== 1}
								onClick={() => {
									setShowModals({ ...showModals, observation: true });
									setObservation({ ...observation, contract: contract?._id });
								}}
							>
								<Icon type='file' /> Enviar observação
							</Menu.Item>
						</Menu>
					}
				>
					<Icon style={{ cursor: 'pointer', fontSize: 20 }} type='more' />
				</Dropdown>
			),
		},
	];

	const billetsColumns = [
		{
			title: 'ID',
			dataIndex: '_id',
			key: 'id',
			render: (_id) => (
				<Typography.Text title={_id} copyable={{ text: _id }}>{`${_id.slice(
					0,
					5
				)}...`}</Typography.Text>
			),
		},
		{
			title: 'Mês',
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: 'Vencimento',
			dataIndex: 'expireAt',
			key: 'expireAt',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			align: 'center',
			render: (status) => {
				return status === 'waiting' ? (
					<Tag color='gold'>
						<Icon type='clock-circle' /> Pagamento Pendente
					</Tag>
				) : status === 'paid' ? (
					<Tag color='green'>
						<Icon type='check-circle' /> Pagamento Confirmado
					</Tag>
				) : (
					<Tag color='red'>
						<Icon type='stop' /> Pagamento Não Confirmado
					</Tag>
				);
			},
		},
		{
			title: 'Código de Barras',
			dataIndex: 'barcode',
			key: 'barcode',
			render: (barcode) => (
				<Typography.Text title={barcode} copyable={{ text: barcode }}>{`${barcode.slice(
					0,
					10
				)}...`}</Typography.Text>
			),
		},
	];

	const handleRestoreContract = useCallback(async () => {
		try {
			setFallback((prev) => ({ ...prev, restoringContract: true }));

			const payload = { duration: restore?.duration };
			const headers = { action: 'request-restore' };

			await ContractsAPI.modify(restore.contract, payload, headers);

			setContracts((prev) =>
				prev.map((c) => {
					if (c?._id === restore?.contract) {
						return { ...c, status: 3 };
					}

					return c;
				})
			);

			setFilteredContracts((prev) =>
				prev.map((c) => {
					if (c?._id === restore?.contract) {
						return { ...c, status: 3 };
					}

					return c;
				})
			);

			setFallback((prev) => ({ ...prev, restoringContract: false }));
			setShowModals((prev) => ({ ...prev, restore: false }));

			message.success('O contrato foi enviado para que o locutor aceite');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, [restore]);

	const handleSendObservation = useCallback(async () => {
		try {
			setFallback((prev) => ({ ...prev, sendingObservation: true }));

			const validationSchema = Yup.object().shape({
				date: Yup.mixed().required('Informe a data'),
				content: Yup.mixed().required('Informe um texto'),
			});

			await validationSchema.validate(observation, { abortEarly: false });

			if (observation?.isUpdate) {
				const payload = { content: observation?.content };

				await ObservationsAPI.update(observation?.storedObservationId, payload);
			} else {
				const { date, content, contract } = observation;
				const payload = { date, content, contract };

				await ObservationsAPI.store(payload);
			}

			setFallback((prev) => ({ ...prev, sendingObservation: false }));
			setShowModals((prev) => ({ ...prev, observation: false }));
			setObservation({
				date: null,
				content: '',
				contract: null,
				isUpdate: false,
				storedObservationId: null,
			});

			message.success('Observação enviada');
		} catch (error) {
			console.error(error);
			message.error('Houve um erro, tente novamente');
		}
	}, [observation]);

	const fetchStoredObservation = useCallback(
		async (date) => {
			try {
				setFallback((prev) => ({ ...prev, fetchingObservations: true }));

				const query = `contract=${observation?.contract}&date=${date}`;
				const { data } = await ObservationsAPI.show(query);

				if (data?.observations?.content) {
					setObservation((prev) => ({
						...prev,
						isUpdate: true,
						content: data?.observations?.content,
						storedObservationId: data?.observations?._id,
					}));
				}

				setFallback((prev) => ({ ...prev, fetchingObservations: false }));
			} catch (error) {
				console.error(error);
			}
		},
		[observation]
	);

	const handleSearch = useCallback(
		(search) => {
			if (!search) {
				return setFilteredContracts(contracts);
			}

			setFilteredContracts(
				contracts.filter((contract) => {
					const { caster, program } = contract;
					const casterName = `${caster?.name} ${caster?.surname}`.toLocaleLowerCase();

					const casterMatch = casterName.includes(search.toLocaleLowerCase());
					const programMatch = program?.name
						.toLocaleLowerCase()
						.includes(search.toLocaleLowerCase());

					return casterMatch || programMatch;
				})
			);
		},
		[contracts]
	);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const {
					data: { contracts },
				} = await ContractsAPI.index({ query: `hirer=${user?._id}` });

				setContracts(contracts);
				setFilteredContracts(contracts);
				setFallback((prev) => ({ ...prev, initialData: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os contratos, tente novamente');
			}
		};

		fetchInitialData();
	}, [user]);

	useEffect(() => {
		const fetchContractBillets = async () => {
			try {
				setFallback((prev) => ({ ...prev, fetchingBillets: true }));

				const months = [
					'Janeiro',
					'Fevereiro',
					'Março',
					'Abril',
					'Maio',
					'Junho',
					'Julho',
					'Agosto',
					'Setembro',
					'Outubro',
					'Novembro',
					'Dezembro',
				];

				let {
					data: { billets },
				} = await BilletsAPI.show(contract?._id);

				billets = billets.map((b) => ({
					_id: b?._id,
					date: `${months[Number(b.date.month) - 1]} de ${b.date.year}`,
					expireAt: b.payment.banking_billet.expire_at.split('-').reverse().join('/'),
					link: b.payment.banking_billet.pdf.charge,
					status: b.status,
					barcode: b.payment.banking_billet.barcode,
				}));

				setBillets(billets);
				setFallback((prev) => ({ ...prev, fetchingBillets: false }));
			} catch (error) {
				console.error(error);
				message.error('Houve um erro ao buscar os boletos');
			}
		};

		if (contract) {
			fetchContractBillets();
		}
	}, [contract]);

	if (fallback?.initialData) {
		return <Fallback title='Carregando' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Gerenciar contratos' />

			<PageHeader title='Gerenciar Contratos' breadcrumb={breadcrumb}>
				<Typography.Text>
					Esses são seus contratos para gravações de offs junto aos locutores
				</Typography.Text>
			</PageHeader>

			<Container>
				<Card>
					<SearchContainer>
						<Input.Search
							allowClear
							size='large'
							onSearch={handleSearch}
							style={{ width: '300px' }}
							placeholder='Buscar por programa ou locutor'
						/>
					</SearchContainer>

					<Table
						size='middle'
						rowKey='_id'
						columns={columns}
						dataSource={filteredContracts}
						pagination={{ size: 'large', pageSize: 10, hideOnSinglePage: true }}
						style={{ border: 'none' }}
					/>
				</Card>
			</Container>

			<Modal
				visible={!!contract}
				footer={null}
				width={800}
				onCancel={() => {
					setContract(null);
					setBillets(null);
				}}
			>
				<Tabs size='small'>
					<Tabs.TabPane
						key='details'
						tab={
							<>
								<Icon type='file' /> Detalhes
							</>
						}
					>
						<DetailsContainer>
							<ul>
								<li>
									<span className='label'>Status</span>
									{[0, 3].includes(contract?.status) ? (
										<Tag color='gold'>
											<Icon type='clock-circle' /> Aguardando aprovação
										</Tag>
									) : contract?.status === 1 ? (
										<Tag color='green'>
											<Icon type='check-circle' /> Vigente
										</Tag>
									) : (
										<Tag color='red'>
											<Icon type='stop' /> Encerrado
										</Tag>
									)}
								</li>
								<li>
									<span className='label'>Programa</span>
									<strong>{contract?.program?.name}</strong>
								</li>
								<li>
									<span className='label'>Locutor</span>
									<CasterCell>
										<Avatar
											style={{ background: 'var(--primary)' }}
											src={resolveFileSrc({
												fileName: contract?.caster?.profilePic,
											})}
										>
											{contract?.caster?.name[0] || '-'} {contract?.caster?.surname[0] || '-'}
										</Avatar>
										<div className='caster-name'>
											<Typography.Text className='name'>
												{contract?.caster?.name} {contract?.caster?.surname}
											</Typography.Text>
											<Typography.Text className='email'>{contract?.caster?.email}</Typography.Text>
										</div>
									</CasterCell>
								</li>
								<li>
									<span className='label'>Valor mensal</span>
									<strong>{toCurrencyFormat(contract?.value / 100)}</strong>
								</li>
								<li>
									<span className='label'>Data de assinatura</span>
									<strong>
										{contract?.signatureDate
											? format(parseISO(contract?.signatureDate), "dd 'de' MMMM 'de' yyyy", {
													locale: ptBR,
												})
											: '-'}
									</strong>
								</li>
								<li>
									<span className='label'>Data de encerramento</span>
									<strong>
										{contract?.endingDate
											? format(parseISO(contract?.endingDate), "dd 'de' MMMM 'de' yyyy", {
													locale: ptBR,
												})
											: '-'}
									</strong>
								</li>
								<li>
									<span className='label'>Duração</span>
									<strong>{contract?.duration} meses</strong>
								</li>
							</ul>
						</DetailsContainer>
					</Tabs.TabPane>

					<Tabs.TabPane
						key='billets'
						tab={
							<>
								<Icon type='dollar' /> Boletos
							</>
						}
					>
						<Table
							size='middle'
							rowKey='_id'
							loading={fallback?.fetchingBillets}
							columns={billetsColumns}
							dataSource={billets}
							pagination={{ size: 'large', pageSize: 10, hideOnSinglePage: true }}
							style={{ border: 'none' }}
						/>
					</Tabs.TabPane>
				</Tabs>
			</Modal>

			<Modal
				destroyOnClose
				title={
					<>
						<Icon type='retweet' style={{ marginRight: 8 }} /> Renovar contrato
					</>
				}
				visible={showModals?.restore}
				okButtonProps={{ loading: fallback?.restoringContract, icon: 'check-circle' }}
				cancelButtonProps={{ disabled: fallback?.restoringContract, icon: 'close-circle' }}
				okText='Enviar'
				onCancel={() => setShowModals({ ...showModals, restore: false })}
				onOk={handleRestoreContract}
			>
				<Typography.Paragraph>
					Após requisitar a renovação do contrato, o mesmo será enviado para que o locutor o aceite
					e só então ele entrará em vigência novamente.
				</Typography.Paragraph>
				<Typography.Paragraph>Informe a duração abaixo:</Typography.Paragraph>

				<Divider />

				<Select
					style={{ width: '100%' }}
					placeholder='Selecione a duração'
					onChange={(value) => setRestore({ ...restore, duration: value })}
					value={restore?.duration}
				>
					<Select.Option value={6}>6 meses</Select.Option>
					<Select.Option value={12}>12 meses</Select.Option>
					<Select.Option value={18}>18 meses</Select.Option>
					<Select.Option value={24}>24 meses</Select.Option>
				</Select>
			</Modal>

			<Modal
				destroyOnClose
				title={
					<>
						<Icon type='file' style={{ marginRight: 8 }} /> Enviar Observação
					</>
				}
				visible={showModals?.observation}
				okButtonProps={{ loading: fallback?.sendingObservation, icon: 'check-circle' }}
				cancelButtonProps={{ disabled: fallback?.sendingObservation, icon: 'close-circle' }}
				okText='Enviar'
				onCancel={() => setShowModals({ ...showModals, observation: false })}
				onOk={handleSendObservation}
			>
				<Typography.Text>
					Envie um texto ou observação para que o locutor utilize como base durante a gravação dos
					offs.
				</Typography.Text>

				<Divider />

				<Spin spinning={fallback?.fetchingObservations ? true : false}>
					<DatePicker
						style={{ width: '100%', marginBottom: 16 }}
						format={['DD/MM/YYYY', 'DD/MM/YY']}
						onChange={(_, date) => {
							fetchStoredObservation(date);
							setObservation({ ...observation, date });
						}}
					/>

					<TextArea
						rows={3}
						style={{ width: '100%' }}
						value={observation?.content}
						disabled={!observation?.date}
						placeholder={
							observation?.date ? 'Informe o texto ou observação' : 'Informe a data primeiramente'
						}
						onChange={({ target: { value } }) => {
							setObservation({ ...observation, content: value });
						}}
					/>
				</Spin>
			</Modal>
		</>
	);
};

export default connect(({ user }) => ({ user }))(ManageContracts);
