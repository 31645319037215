import Button from '../Button';
import { Input, MaskedInput } from '../Input';
import React, { useCallback, useState } from 'react';
import { Container, Form, FormContainer, Image } from './styles';

import img from '../../../../assets/landpage/contact-us-form.png';
import { message } from 'antd';
import ContactRequestApi from '../../../../services/sdks/contactRequest';


export const ContactUsForm = () => {
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({ phone: false, email: false });

	const validateForm = useCallback(() => {
		const errors = {
			phone: phone.length !== 11,
			email: !email || !email.includes('@'),
		};

		return errors.email || errors.phone ? errors : null;
	}, [email, phone]);

	const handleSubmit = useCallback(async () => {
		const errors = validateForm();

		setErrors(errors);

		if (errors) {
			return;
		}

		setLoading(true);

		const data = { phone, email, origin: 'TALK-PLAY' };

		try {
			await ContactRequestApi.store(data);

			message.success('Recebemos seus dados, em breve nossa equipe entrará em contato');

			await new Promise((res) => {
				setTimeout(() => {
					return res(null);
				}, 3000);
			});

			window.location.reload();
		} catch (error) {
			if (error.status === 409) {
				message.info(
					'Parece que você já nos enviou seus dados, em breve nossa equipe entrará em contato',
					5
				);
			} else {
				message.error('Houve um erro, tente novamente');
			}
		}

		setLoading(false);
	}, [validateForm, phone, email]);

	const handleChangePhone = useCallback((value) => {
		setPhone(value.replaceAll(/[_ ()]/g, '').replaceAll('-', ''));
	}, []);

	return (
		<Container id='contato'>
			<FormContainer>
				<h3>Entre Em Contato Com a TalkPLay.</h3>
				<Form>
					<MaskedInput
						mask='(99) 9 9999-9999'
						label={<span style={{ color: '#111010' }}>Whatsapp</span>} // Defina a cor do label aqui
						placeholder='DDD + Telefone'
						onChange={(value) => handleChangePhone(value)}
						style={{ marginBottom: '36px' }}
						error={errors?.phone ? 'Informe seu whatsapp' : undefined}
					/>

					<Input
						label={<span style={{ color: '#111010' }}>Seu melhor email</span>} // Defina a cor do label aqui
						placeholder='Seu email'
						onChange={(value) => setEmail(value)}
						style={{ marginBottom: '36px' }}
						error={errors?.email ? 'Informe seu email corretamente' : undefined}
					/>

					<Button
						type='button'
						content='Enviar'
						variant='bluemed'
						onClick={handleSubmit}
						loading={loading}
					/>
				</Form>
			</FormContainer>

			<Image className='img' src={img} alt='talk-radio' />
		</Container>
	);
};
