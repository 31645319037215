import api from '../api';

const BASE_URL = '/api/v2/signatures';

const SignaturesAPI = {
	index: ({ query = '' } = {}) => api.get(`${BASE_URL}?${query}`),
	store: ({ payload, onUploadProgress }) => api.post(BASE_URL, payload, { onUploadProgress }),
	modify: ({ signatureId, payload }) => api.patch(`${BASE_URL}/${signatureId}`, payload),
	destroy: ({ signatureId }) => api.delete(`${BASE_URL}/${signatureId}`),
};

export default SignaturesAPI;
