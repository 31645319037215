import React, { useState, useEffect, useCallback } from 'react';
import { PageHeader, Button, message, Card, Typography, Input } from 'antd';

import Meta from '../../../../components/Meta';
import Fallback from '../../../../components/Fallback';
import { FiArrowRight } from 'react-icons/fi';
import { Container, NamesContainer, NamesConfigRow } from './styles';

import TKPlayConfigAPI from '../../../../services/sdks/tkPlayConfig';

const breadcrumb = {
	routes: [
		{ breadcrumbName: 'Painel administrativo' },
		{ breadcrumbName: 'Geral' },
		{ breadcrumbName: 'Configurar TkPlays' },
	],
	style: { marginBottom: 12 },
};

const TkPlayConfig = () => {
	const [fallback, setFallback] = useState({ initialData: false });
	const [config, setConfig] = useState({ names: [] });

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				setFallback({ initialData: true });

				const {
					data: { tkPlayConfig },
				} = await TKPlayConfigAPI.show();

				setConfig(tkPlayConfig);
				setFallback({ initialData: false });
			} catch (error) {
				message.error('Houve um erro, tente novamente.');
			}
		};

		fetchInitialData();
	}, []);

	const updateLabel = useCallback(
		(label, index) => {
			const names = config.names;

			names[index] = { ...names[index], label };

			setConfig({ ...config, names });
		},
		[config]
	);

	const updateConfig = useCallback(async () => {
		setFallback((prev) => ({ ...prev, updating: true }));

		try {
			await TKPlayConfigAPI.update(config);
			message.success('Alterações salvas com sucesso.');
		} catch (error) {
			message.error('Houve um erro, tente novamente.');
		}

		setFallback((prev) => ({ ...prev, updating: false }));
	}, [config]);

	const renderKey = useCallback((rawKey) => {
		const [, , number] = rawKey.split('-');
		return `TKPLAY ID - NEURAL #${number}`;
	}, []);

	if (fallback?.initialData) {
		return <Fallback title='Carregando...' message='Por favor aguarde...' />;
	}

	return (
		<>
			<Meta title='Configurar TkPlays' />

			<PageHeader title='Configurar TkPlays' breadcrumb={breadcrumb}>
				<Typography>Configurar nomenclatura dos elementos TKPLAY</Typography>
			</PageHeader>

			<Container>
				<Card title='Configurar Nomenclatura'>
					<div className='__body-content'>
						<NamesContainer>
							{config?.names?.map(({ key, label }, index) => (
								<NamesConfigRow key={key}>
									<div className='label'>
										<span>{renderKey(key)}</span>
									</div>

									<div className='divider'>
										<FiArrowRight />
									</div>

									<Input
										defaultValue={label}
										onChange={({ target: { value } }) => updateLabel(value, index)}
									/>
								</NamesConfigRow>
							))}
						</NamesContainer>
					</div>

					<footer className='__actions'>
						<Button type='primary' loading={fallback.updating} onClick={updateConfig}>
							Salvar Alterações
						</Button>
					</footer>
				</Card>
			</Container>
		</>
	);
};

export default TkPlayConfig;
